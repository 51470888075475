import { add, getMaskedMoneyValue } from "common/functions";
import styled from "styled-components";
import {
  EndOfDayTransactionListItem,
  GLTransaction,
  PayoutCash,
  Receive,
} from "types";
import SummaryRowGLTransaction from "pages/cashDrawer/components/SummaryRowGLTransaction";
import SummaryRowPayoutCash from "pages/cashDrawer/components/SummaryRowPayoutCash";
import SummaryRowReceive from "pages/cashDrawer/components/SummaryRowReceive";

export type SummaryProps = {
  transactionsList: EndOfDayTransactionListItem[];
};

export default function SummaryDetails(props: SummaryProps) {
  let cashInTxns = props.transactionsList.filter(
    (t) =>
      t.transaction.transactionType === "RECEIVE" ||
      (t.transaction.transactionType === "CASH_TRANSFER" &&
        (t.transaction as GLTransaction).to === "cashdrawer")
  );

  let totalCashIn = cashInTxns
    .map((t) =>
      t.transaction.transactionType === "RECEIVE"
        ? (t.transaction as Receive).cash?.cashCADValue ?? 0
        : t.transaction.amount ?? 0
    )
    .reduce(add, 0);

  let cashOutTxns = props.transactionsList.filter(
    (t) =>
      t.transaction.transactionType === "PAYOUT_CASH" ||
      (t.transaction.transactionType === "CASH_TRANSFER" &&
        (t.transaction as GLTransaction).from === "cashdrawer")
  );

  let totalCashOut = cashOutTxns
    .map((t) => t.transaction.amount ?? 0)
    .reduce(add, 0);

  return (
    <div>
      <TopSection>
        <TwoColumnDisplay
          label="Cash In Total"
          value={getMaskedMoneyValue(totalCashIn) ?? ""}
        />
        <TwoColumnDisplay
          label="Cash Out Total"
          value={getMaskedMoneyValue(totalCashOut) ?? ""}
        />
      </TopSection>
      <Header />
      <div>
        {props.transactionsList
          //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((tl, index) => {
            return <Row transactionListItem={tl} key={index} />;
          })}
      </div>

      {/* {!!sortedTxns && (
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={!!sortedTxns ? sortedTxns.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => {
            setPage(p);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
          }}
          sx={{ marginTop: "2em" }}
        />
      )} */}
    </div>
  );
}

function Header() {
  return (
    <div>
      <SummaryHeader>
        <Column>Date</Column>
        <Column>Client</Column>
        <Column>Transaction</Column>
        <Column>Cash In</Column>
        <Column>Cash Out</Column>
        <Column>Details</Column>
      </SummaryHeader>
    </div>
  );
}

function Row(props: { transactionListItem: EndOfDayTransactionListItem }) {
  if (props.transactionListItem.transaction.transactionType === "RECEIVE") {
    return (
      <SummaryRowReceive
        clientName={props.transactionListItem.clientName}
        transaction={props.transactionListItem.transaction as Receive}
      />
    );
  }

  if (props.transactionListItem.transaction.transactionType === "PAYOUT_CASH") {
    let payoutTransaction: PayoutCash = props.transactionListItem
      .transaction as PayoutCash;
    return (
      <SummaryRowPayoutCash
        clientName={props.transactionListItem.clientName}
        transaction={payoutTransaction}
      />
    );
  }
  if (
    props.transactionListItem.transaction.transactionType === "CASH_TRANSFER"
  ) {
    let GlTransaction: GLTransaction = props.transactionListItem
      .transaction as GLTransaction;
    return (
      <SummaryRowGLTransaction
        clientName={props.transactionListItem.clientName}
        transaction={GlTransaction}
      />
    );
  }

  return <></>;
}

function TwoColumnDisplay(props: { label: string; value: string }) {
  return (
    <RowContainer>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </RowContainer>
  );
}

const TopSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: right;
  align-items: center;
  margin-bottom: 2em;
`;

const Label = styled.label`
  color: #0000008a;
  padding: 0 10px;
  font-size: 16px;
`;
const Value = styled.div`
  font-size: 16px;
  padding: 0 10px 0 5px;
`;
const RowContainer = styled.div`
  display: flex;
  margin-right: 1.5em;
  min-width: 220px;
`;

const SummaryHeader = styled.div`
  display: flex;
  padding: 1em 0;
  font-weight: 500;
  padding-left: 18px;
  padding-right: 40px;
`;

const Column = styled.div`
  flex: 1;
  padding: 0 1em 0 1.75em;
`;
