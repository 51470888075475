import { DateTimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import moment from "moment";
import { FieldError } from "types";
import { FieldContainer } from "./commonFieldStyles";

type Props = {
  id: string;
  label: string;
  value: string | null;
  onChange(value?: string): void;
  required: boolean | undefined;
  errObj?: FieldError | undefined;
  disabled: boolean | undefined;
  customStyle?: React.CSSProperties;
};

export default function DateTimeFieldNonFieldEditor(props: Props) {
  return (
    <DateTimePicker
      label={props.label}
      value={props.value ? moment(props.value).toDate() : null}
      disabled={props.disabled}
      onChange={(newValue) => {
        if (!newValue) props.onChange(undefined);
        else props.onChange(moment(newValue).format("MM/DD/YYYY hh:mm A"));
      }}
      renderInput={(params) => (
        <FieldContainer>
          <TextField
            {...params}
            id={props.id}
            variant="filled"
            fullWidth
            required={props.required}
            error={!!props.errObj}
            helperText={!!props.errObj && props.errObj.error}
            size="small"
            style={props.customStyle}
          />
        </FieldContainer>
      )}
    />
  );
}
