import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { PersonalClient } from "types";

//uncomment if you need msal auth
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "personalClientInformation";
const baseURL = `${process.env.REACT_APP_API_BASE}/client`;

type State = {
  savePersonalClient: ReducerState<SavePersonalClientReturn>;
  saveInformationInstructorFeedback: ReducerState<SaveInstructorFeedbackReturn>;
  saveCreditAppInstructorFeedback: ReducerState<SaveInstructorFeedbackReturn>;
};

type SavePersonalClientReturn = {
  clientId: number;
};

let savePersonalClient = createAsyncAction<
  SavePersonalClientReturn, //return type
  {
    client: PersonalClient;
    lessonId: number;
    onComplete?(retValue: SavePersonalClientReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "savePersonalClient",
  url: baseURL + "/savePersonalClient",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.savePersonalClient = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.savePersonalClient.isLoading = false;
    state.savePersonalClient.data = action.payload;
  },
  rejected: (state, action) => {
    state.savePersonalClient.isLoading = false;
    state.savePersonalClient.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as SavePersonalClientReturn);
  },
});

type SaveInstructorFeedbackReturn = {
  success: boolean;
  message: string;
};

let saveInformationInstructorFeedback = createAsyncAction<
  SaveInstructorFeedbackReturn, //return type
  {
    studentId: string;
    lessonId: number;
    clientId: string;
    feedback?: string;
    onComplete?(retValue: SaveInstructorFeedbackReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "saveInformationInstructorFeedback",
  url: baseURL + "/saveInformationFeedbackPersonalClient",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.saveInformationInstructorFeedback = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveInformationInstructorFeedback.isLoading = false;
    state.saveInformationInstructorFeedback.data = action.payload;
  },
  rejected: (state, action) => {
    state.saveInformationInstructorFeedback.isLoading = false;
    state.saveInformationInstructorFeedback.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as SaveInstructorFeedbackReturn);
  },
});

let saveCreditAppInstructorFeedback = createAsyncAction<
  SaveInstructorFeedbackReturn, //return type
  {
    studentId: string;
    lessonId: number;
    clientId: string;
    feedback?: string;
    onComplete?(retValue: SaveInstructorFeedbackReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "saveCreditAppInstructorFeedback",
  url: baseURL + "/saveCreditAppFeedbackPersonalClient",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.saveCreditAppInstructorFeedback = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveCreditAppInstructorFeedback.isLoading = false;
    state.saveCreditAppInstructorFeedback.data = action.payload;
  },
  rejected: (state, action) => {
    state.saveCreditAppInstructorFeedback.isLoading = false;
    state.saveCreditAppInstructorFeedback.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as SaveInstructorFeedbackReturn);
  },
});

let slice = createSlice({
  name: controlName,
  initialState: {
    savePersonalClient: { isLoading: false },
    saveInformationInstructorFeedback: { isLoading: false },
    saveCreditAppInstructorFeedback: { isLoading: false },
    getNewClientId: { isLoading: false },
    getClient: { isLoading: false },
  } as State,
  reducers: {},
  extraReducers: {
    ...savePersonalClient.reducer,
    ...saveInformationInstructorFeedback.reducer,
    ...saveCreditAppInstructorFeedback.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      savePersonalClient: savePersonalClient.action,
      saveInformationInstructorFeedback:
        saveInformationInstructorFeedback.action,
      saveCreditAppInstructorFeedback: saveCreditAppInstructorFeedback.action,
    },
  },
};

export default ret;
