import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  Account,
  CreditCardTransaction,
  Deposit,
  GeneralLedger,
  GLAccount,
  GLEntry,
  PersonalClient,
  Transaction,
  TransactionSession,
  Transfer,
  Withdrawal,
} from "types";
import { useActions, useReduxState } from "store";
import { useHistory } from "react-router-dom";
import {
  PersonalClientAccountHistoryRoute,
  PersonalClientSummaryRoute,
  PersonalClientTransactionsRoute,
  UnauthorizedRoute,
} from "components/paths";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import {
  add,
  getOpenAccountOptionsForAccountHistory,
  getOpenAccounts,
  getPersonalClientName,
  getNewDepositTransaction,
  getNewTransactionSession,
  subtract,
  getClosedAccountOptionsForAccountHistory,
  getNewWithdrawalTransaction,
  checkIfUnauthorized,
} from "common/functions";
import AccountDetails from "pages/accountHistory/components/AccountDetails";
import { Button } from "@mui/material";
import ClientInfoTemplate from "components/clientInfoTemplate/ClientInfoTemplate";
import EditTransactionDialog from "pages/accountHistory/components/EditTransactionDialog";
import { openSnackbar } from "components/snackbar";
import { assetsAccountsTypes } from "common/helpers";
import { getPersonalClientById, getGeneralLedger } from "pages/lessons/helpers";
import { v4 } from "uuid";
import moment from "moment";

export type Props = {
  clientId: string;
  accountId: string;
  lessonId: string;
  studentId?: string;
};

export default function PersonalClientAccountHistory(props: Props) {
  const [state, setState] = useState<PersonalClient>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactionInEdit, setTransactionInEdit] = useState<
    Transaction | undefined
  >(undefined);

  const {
    getLessonNameAndData,
    lessonId,
    getDataforViewAsStudent,
  } = useReduxState((e) => e.lesson);
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const lessonActions = useActions().lesson;
  const accountHistoryActions = useActions().accountHistory;

  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }

  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            if (props.clientId && props.accountId) {
              let client: PersonalClient = getPersonalClientById(
                props.clientId,
                payload.lessonData
              );

              if (!!client.identification?.clientId) setState(client);
              else history.push(UnauthorizedRoute);
              setGLState(getGeneralLedger(payload.lessonData));
            }
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId && props.accountId) {
          setState(
            getPersonalClientById(
              props.clientId,
              getLessonNameAndData.data?.lessonData
            )
          );
          setGLState(getGeneralLedger(getLessonNameAndData.data?.lessonData));
        }
        setIsLoading(false);
      }
    }
    //for student view
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            if (props.clientId && props.accountId) {
              setState(
                getPersonalClientById(props.clientId, payload.lessonData)
              );
              setGLState(getGeneralLedger(payload.lessonData));
            }
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId && props.accountId) {
          setState(
            getPersonalClientById(
              props.clientId,
              getDataforViewAsStudent.data?.lessonData
            )
          );
          setGLState(
            getGeneralLedger(getDataforViewAsStudent.data?.lessonData)
          );
        }
        setIsLoading(false);
      }
    }
  }, []);

  const clientName = getPersonalClientName(state);

  function savePersonalClient(updatedClient: PersonalClient) {
    accountHistoryActions.savePersonalClient({
      client: updatedClient,
      lessonId,
      onComplete: (payload) => {
        openSnackbar({
          severity: "success",
          message: `Personal Client '${clientName}' saved successfully`,
        });
        lessonActions.savePersonalClient(updatedClient);
      },
    });
  }

  function savePersonalClientAndGL(
    updatedClient: PersonalClient,
    updatedGL: GeneralLedger
  ) {
    accountHistoryActions.savePersonalClientAndGL({
      client: updatedClient,
      gl: updatedGL,
      lessonId,
    });
    lessonActions.savePersonalClientAndGL({
      updatedClient: updatedClient,
      updatedGL: updatedGL,
    });
  }

  function updateClientAndGLOnSaveTransaction(
    updatedClient: PersonalClient,
    updatedTransaction: GLEntry,
    amountDifference: number,
    isDuplicateTransaction: boolean
  ) {
    let newGLState: GeneralLedger = {
      ...glState,
      glAccounts: glState.glAccounts?.map((gla) => {
        if (updatedTransaction.glAccount === gla.glAccountId) {
          let txnIds = gla.transactionIds ?? [];
          let updatedGLAccount: GLAccount = {
            ...gla,
            balance:
              updatedTransaction.glType === "credit"
                ? add(gla.balance ?? 0, amountDifference)
                : subtract(gla.balance ?? 0, amountDifference),
            transactionIds: isDuplicateTransaction
              ? [...txnIds, updatedTransaction.transactionId]
              : txnIds,
          };
          return updatedGLAccount;
        } else return gla;
      }),
    };
    setGLState(newGLState);
    savePersonalClientAndGL(updatedClient, newGLState);
  }

  function updateClientAndGLOnDeleteTransaction(
    updatedClient: PersonalClient,
    transaction: GLEntry
  ) {
    let newGLState: GeneralLedger = {
      ...glState,
      glAccounts: glState.glAccounts?.map((gla) => {
        if (transaction.glAccount === gla.glAccountId) {
          let currentGlaTransactionIds = gla.transactionIds ?? [];
          let updatedGLAccount: GLAccount = {
            ...gla,
            balance:
              transaction.glType === "debit"
                ? add(gla.balance ?? 0, transaction.amount ?? 0)
                : subtract(gla.balance ?? 0, transaction.amount ?? 0),
            transactionIds: currentGlaTransactionIds.filter(
              (tId) => tId !== transaction.transactionId
            ),
          };
          return updatedGLAccount;
        } else return gla;
      }),
    };
    setGLState(newGLState);
    savePersonalClientAndGL(updatedClient, newGLState);
  }

  function onDeleteTransaction(transaction: Transaction) {
    const selectedAccount = getOpenAccounts(state).find(
      (a) => a.accountId === props.accountId
    );

    let txnSessions = state.transactionSessions ?? [];
    let updatedTxnSessions: TransactionSession[] = [];

    for (let i = 0; i < txnSessions.length; i++) {
      let updatedTxnSession: TransactionSession = {
        ...txnSessions[i],
        transactions: txnSessions[i].transactions?.filter(
          (t) => t.transactionId !== transaction.transactionId
        ),
      };
      updatedTxnSessions.push(updatedTxnSession);
    }

    let updatedClient: PersonalClient = {
      ...state,
      accounts: state.accounts?.map((a) => {
        if (
          a.accountId === selectedAccount?.accountId ||
          (transaction.transactionType === "TRANSFER" &&
            ((transaction as Transfer).to === a.accountId ||
              (transaction as Transfer).from === a.accountId))
        ) {
          let updatedAccount: Account = {
            ...a,
            balance: assetsAccountsTypes.includes(a.accountType)
              ? transaction.transactionType === "DEPOSIT" ||
                (transaction.transactionType === "TRANSFER" &&
                  (transaction as Transfer).to === a.accountId) ||
                (transaction.transactionType === "GL_ENTRY" &&
                  (transaction as GLEntry).glType === "debit" &&
                  (transaction as GLEntry).clientAccount === a.accountId)
                ? subtract(a.balance ?? 0, transaction.amount ?? 0)
                : add(a.balance ?? 0, transaction.amount ?? 0)
              : transaction.transactionType === "DEPOSIT" ||
                (transaction.transactionType === "CREDIT_CARD" &&
                  (transaction as CreditCardTransaction).type === "payment") ||
                (transaction.transactionType === "TRANSFER" &&
                  (transaction as Transfer).to === a.accountId)
              ? add(a.balance ?? 0, transaction.amount ?? 0)
              : subtract(a.balance ?? 0, transaction.amount ?? 0),
            transactionIds: a.transactionIds?.filter(
              (tId) => tId !== transaction.transactionId
            ),
          };
          return updatedAccount;
        } else return a;
      }),
      transactionSessions: updatedTxnSessions,
    };

    if (transaction.transactionType === "GL_ENTRY") {
      updateClientAndGLOnDeleteTransaction(updatedClient, transaction);
    } else {
      savePersonalClient(updatedClient);
    }
    setState(updatedClient);
  }

  function onSaveTransaction(
    transaction: Transaction,
    isDuplicateTransaction?: boolean
  ) {
    const selectedAccount = getOpenAccounts(state).find(
      (a) => a.accountId === props.accountId
    );

    let amountDifference = 0;
    let isNewTxn: boolean = true;
    let txnSessions = state.transactionSessions ?? [];
    for (let i = 0; i < txnSessions.length; i++) {
      let originalTxn = txnSessions[i].transactions?.find(
        (t) => t.transactionId === transaction.transactionId
      );
      if (!!originalTxn) {
        amountDifference = subtract(
          transaction.amount ?? 0,
          originalTxn.amount ?? 0
        );
        isNewTxn = false;
        break;
      }
    }
    let updatedTxnSessions: TransactionSession[] = [];

    if (isNewTxn) {
      amountDifference = transaction.amount ?? 0;
      let newTxnSession: TransactionSession = {
        ...getNewTransactionSession(),
        transactions: [transaction],
      };
      updatedTxnSessions = [...txnSessions, newTxnSession];
    } else {
      for (let i = 0; i < txnSessions.length; i++) {
        let updatedTxnSession: TransactionSession = {
          ...txnSessions[i],
          transactions: txnSessions[i].transactions?.map((t) =>
            t.transactionId === transaction.transactionId ? transaction : t
          ),
        };

        updatedTxnSessions.push(updatedTxnSession);
      }
    }

    let updatedClient: PersonalClient = {
      ...state,
      accounts: state.accounts?.map((a) => {
        if (
          a.accountId === selectedAccount?.accountId ||
          (transaction.transactionType === "TRANSFER" &&
            ((transaction as Transfer).to === a.accountId ||
              (transaction as Transfer).from === a.accountId))
        ) {
          let txnIds = a.transactionIds ?? [];
          let updatedAccount: Account = {
            ...a,

            balance: assetsAccountsTypes.includes(a.accountType)
              ? transaction.transactionType === "DEPOSIT" ||
                (transaction.transactionType === "TRANSFER" &&
                  (transaction as Transfer).to === a.accountId) ||
                (transaction.transactionType === "GL_ENTRY" &&
                  (transaction as GLEntry).glType === "debit" &&
                  (transaction as GLEntry).clientAccount === a.accountId)
                ? add(a.balance ?? 0, amountDifference)
                : transaction.transactionType === "WITHDRAWAL" ||
                  (transaction.transactionType === "TRANSFER" &&
                    (transaction as Transfer).from === a.accountId) ||
                  (transaction.transactionType === "GL_ENTRY" &&
                    (transaction as GLEntry).glType === "credit" &&
                    (transaction as GLEntry).clientAccount === a.accountId)
                ? subtract(a.balance ?? 0, amountDifference)
                : a.balance
              : transaction.transactionType === "DEPOSIT" ||
                (transaction.transactionType === "CREDIT_CARD" &&
                  (transaction as CreditCardTransaction).type === "payment") ||
                (transaction.transactionType === "TRANSFER" &&
                  (transaction as Transfer).to === a.accountId)
              ? subtract(a.balance ?? 0, amountDifference)
              : transaction.transactionType === "WITHDRAWAL" ||
                (transaction.transactionType === "CREDIT_CARD" &&
                  (transaction as CreditCardTransaction).type ===
                    "cashadvance") ||
                (transaction.transactionType === "TRANSFER" &&
                  (transaction as Transfer).from === a.accountId)
              ? add(a.balance ?? 0, amountDifference)
              : a.balance,

            transactionIds: isNewTxn
              ? [...txnIds, transaction.transactionId]
              : txnIds,
          };
          return updatedAccount;
        } else return a;
      }),

      transactionSessions: updatedTxnSessions,
    };
    if (transaction.transactionType === "GL_ENTRY") {
      updateClientAndGLOnSaveTransaction(
        updatedClient,
        transaction,
        amountDifference,
        isDuplicateTransaction ?? false
      );
    } else {
      savePersonalClient(updatedClient);
    }
    setState(updatedClient);
    setTransactionInEdit(undefined);
  }

  function onSaveRecurringTransaction(
    transaction: Transaction,
    recurringMonths: number
  ) {
    const selectedAccount = getOpenAccounts(state).find(
      (a) => a.accountId === props.accountId
    );

    let recurringTransactions: Transaction[] = [transaction];
    for (let i = 1; i < recurringMonths; i++) {
      let newTransaction: Transaction = {
        ...transaction,
        transactionId: v4(),
        transactionDateTime: moment(transaction.transactionDateTime)
          .add(i, "months")
          .format(),
      };
      recurringTransactions.push(newTransaction);
    }

    let txnSessions = state.transactionSessions ?? [];

    let updatedTxnSessions: TransactionSession[] = [];

    let amountDifference = transaction.amount
      ? transaction.amount * recurringMonths
      : 0;
    let newTxnSession: TransactionSession = {
      ...getNewTransactionSession(),
      transactions: recurringTransactions,
    };
    updatedTxnSessions = [...txnSessions, newTxnSession];

    let updatedClient: PersonalClient = {
      ...state,
      accounts: state.accounts?.map((a) => {
        if (a.accountId === selectedAccount?.accountId) {
          let txnIds = a.transactionIds ?? [];
          let updatedAccount: Account = {
            ...a,

            balance: assetsAccountsTypes.includes(a.accountType)
              ? transaction.transactionType === "DEPOSIT"
                ? add(a.balance ?? 0, amountDifference)
                : transaction.transactionType === "WITHDRAWAL"
                ? subtract(a.balance ?? 0, amountDifference)
                : a.balance
              : transaction.transactionType === "DEPOSIT"
              ? subtract(a.balance ?? 0, amountDifference)
              : transaction.transactionType === "WITHDRAWAL"
              ? add(a.balance ?? 0, amountDifference)
              : a.balance,

            transactionIds: [
              ...txnIds,
              ...recurringTransactions.map((t) => t.transactionId),
            ],
          };
          return updatedAccount;
        } else return a;
      }),

      transactionSessions: updatedTxnSessions,
    };
    savePersonalClient(updatedClient);
    setState(updatedClient);
    setTransactionInEdit(undefined);
  }

  function onEditClick(transaction: Transaction) {
    setTransactionInEdit(transaction);
  }

  function addDeposit() {
    const newDepositTransaction: Deposit = {
      ...getNewDepositTransaction(),
      transactionDescription: "",
      transactionDateTime: "",
      to: props.accountId,
    };
    setTransactionInEdit(newDepositTransaction);
  }

  function addWithdrawal() {
    const newWithdrawalTransaction: Withdrawal = {
      ...getNewWithdrawalTransaction(),
      transactionDescription: "",
      transactionDateTime: "",
      from: props.accountId,
    };
    setTransactionInEdit(newWithdrawalTransaction);
  }

  return (
    <ClientInfoTemplate
      client={state}
      title={
        state.information?.legalFirstName
          ? `Account History — ${clientName}`
          : "Account History"
      }
      isLoading={isLoading}
      studentId={props.studentId}
      lessonId={props.lessonId}
      cashDrawerBalance={glState.cashDrawer?.balance ?? 0}
    >
      <Column>
        <FlexDiv>
          <ColumnHeading>Account History</ColumnHeading>
          <Button
            onClick={() => {
              !!props.studentId
                ? history.push(
                    `/${lessonId}${PersonalClientSummaryRoute}/${state.identification?.clientId}/${props.studentId}`
                  )
                : history.push(
                    `/${lessonId}${PersonalClientSummaryRoute}/${state.identification?.clientId}`
                  );
            }}
          >
            <PersonIcon style={{ marginRight: 5 }} />
            Portfolio
          </Button>
          <Button
            onClick={() => {
              !!props.studentId
                ? history.push(
                    `/${lessonId}${PersonalClientTransactionsRoute}/${state.identification?.clientId}/${props.studentId}`
                  )
                : history.push(
                    `/${lessonId}${PersonalClientTransactionsRoute}/${state.identification?.clientId}`
                  );
            }}
          >
            <ReceiptLongIcon style={{ marginRight: 5 }} />
            Transactions
          </Button>
        </FlexDiv>
        <AccountDetails
          accounts={state.accounts ?? []}
          transactionSessions={state.transactionSessions ?? []}
          openAccountOptions={getOpenAccountOptionsForAccountHistory(state)}
          closedAccountOptions={getClosedAccountOptionsForAccountHistory(state)}
          selectedAccountId={props.accountId}
          onAccountOptionChange={(selectedAccountId) => {
            !!props.studentId
              ? history.push(
                  `/${lessonId}${PersonalClientAccountHistoryRoute}/${state.identification?.clientId}/${selectedAccountId}/${props.studentId}`
                )
              : history.push(
                  `/${lessonId}${PersonalClientAccountHistoryRoute}/${state.identification?.clientId}/${selectedAccountId}`
                );
          }}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteTransaction}
          onDuplicateClick={(transaction) => {
            onSaveTransaction(transaction, true);
          }}
          onAddDeposit={addDeposit}
          onAddWithdrawal={addWithdrawal}
          isReadOnlyView={!!props.studentId}
        />
      </Column>
      {!!transactionInEdit && (
        <EditTransactionDialog
          transaction={transactionInEdit}
          onSave={(transaction, recurringMonths) => {
            !!recurringMonths
              ? onSaveRecurringTransaction(transaction, recurringMonths)
              : onSaveTransaction(transaction);
          }}
          onCancel={() => {
            setTransactionInEdit(undefined);
          }}
          account={state.accounts?.find((a) => a.accountId === props.accountId)}
        />
      )}
    </ClientInfoTemplate>
  );
}

const Column = styled.div`
  min-width: 550px;
  margin: 0.5em;
  flex: 1;
`;
const ColumnHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
