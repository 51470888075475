import { Divider, IconButton } from "@mui/material";
import { SellDraft } from "types";
import SummaryRowTemplate from "./SummaryRowTemplate";
import { openConfirm } from "components/confirmBox";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import { getMaskedMoneyValue } from "common/functions";
import { useReduxState } from "store";

type SummaryRowSellDraftProps = {
  transaction: SellDraft;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

export default function SummaryRowSellDraft({
  transaction,
  onDeleteClick,
  isReadOnlyView,
}: SummaryRowSellDraftProps) {
  const currencyType = transaction.currencyType ?? "";
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
  let transactionAmount = getMaskedMoneyValue(transaction.amount ?? 0);
  let draftAmount = getMaskedMoneyValue(transaction.draftAmount ?? 0);
  let fee = getMaskedMoneyValue(transaction.fee ?? 0);

  let isTransactionRequireOverride: boolean =
    !!transaction.draftAmountCADValue &&
    transaction.draftAmountCADValue >=
      (globalSettings.draftOverrideDenomination ?? 0) &&
    transaction.isApprovalPending
      ? true
      : false;

  let isTransactionRejected =
    !!transaction.draftAmountCADValue &&
    transaction.draftAmountCADValue >=
      (globalSettings.draftOverrideDenomination ?? 0) &&
    transaction.isApprovalPending === false &&
    transaction.isApproved === false
      ? true
      : false;

  return (
    <SummaryRowTemplate
      transaction="Sell Draft"
      credit={transactionAmount}
      details={
        transaction.draftAmountCADValue &&
        transaction.draftAmountCADValue >=
          (globalSettings.draftOverrideDenomination ?? 0) &&
        transaction.isApproved === true
          ? "Override Approved"
          : ""
      }
      isTransactionRequireOverride={isTransactionRequireOverride}
      isTransactionRejected={isTransactionRejected}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection>Serial#</TextSection>
        <TextSection>Payee</TextSection>
        <TextSection>Amount</TextSection>
        <TextSection>Fee</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>{transaction.serialNumber}</TextSection>
        <TextSection>{transaction.payee}</TextSection>
        <TextSection>
          {`${draftAmount} ${currencyType.toUpperCase()}`}
        </TextSection>
        <TextSection>{`${fee} CAD`}</TextSection>
      </DetailsRow>

      <DetailsRow isBold={true}>
        <TextSection style={{ textAlign: "left" }}>Total</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${transactionAmount} CAD`}
        </TextSection>
      </DetailsRow>

      <DetailsRow>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          <IconButton
            style={{ margin: "-1em 0" }}
            aria-label="delete"
            onClick={() => {
              openConfirm({
                title: "Delete Transaction?",
                description: `Sell Draft ${draftAmount} ${currencyType.toUpperCase()}`,
                callback: () => {
                  onDeleteClick();
                },
              });
            }}
            disabled={isReadOnlyView}
          >
            <DeleteIcon />
          </IconButton>
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
  text-align: center;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
