import { Divider, Grid, Typography } from "@mui/material";
import { getPersonalLOCTypeLabel } from "common/functions";
import FieldDisplay from "components/FieldDisplay";
import AddressFieldDisplay from "components/fields/AddressFieldDisplay";
import DisplayFieldTemplate from "components/fields/DisplayFieldTemplate";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppLOCFieldDefinitions";
import { CreditAppLOC } from "types";
import AccountDisplayTemplate from "./AccountDisplayTemplate";
export type Props = {
  account: CreditAppLOC;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

function CreditAppLoanDisplay({
  account,
  onEditClick,
  onDeleteClick,
  isReadOnlyView,
}: Props) {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`LOC_${account.accountId}_header`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      accountType="Line Of Credit"
      accountId={account.accountId || ""}
      type={getPersonalLOCTypeLabel(account.type ?? "")}
      requestedAmount={account.requestedAmount || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DisplayFieldTemplate label="Account Type">
            Line Of Credit
          </DisplayFieldTemplate>
          <FieldDisplay<CreditAppLOC>
            field={fields.locRequestedAmount}
            {...commonProps}
          />
          <FieldDisplay<CreditAppLOC>
            field={fields.locMinimumPayment}
            {...commonProps}
          />
          {commonProps.state.type === "heloc" && (
            <>
              <FieldDisplay<CreditAppLOC>
                field={fields.locType}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locHasJointApplication}
                {...commonProps}
              />
            </>
          )}

          {commonProps.state.type !== "heloc" && (
            <>
              <FieldDisplay<CreditAppLOC>
                field={fields.locPrimeRate}
                {...commonProps}
              />

              <FieldDisplay<CreditAppLOC>
                field={fields.locInterestRate}
                {...commonProps}
              />

              <FieldDisplay<CreditAppLOC>
                field={fields.locTotalInterestRate}
                {...commonProps}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {commonProps.state.type !== "heloc" && (
            <FieldDisplay<CreditAppLOC>
              field={fields.locType}
              {...commonProps}
            />
          )}
          {commonProps.state.type === "sloc" && (
            <FieldDisplay<CreditAppLOC>
              field={fields.locSecurity}
              {...commonProps}
            />
          )}
          {commonProps.state.type === "studentloc" && (
            <>
              <FieldDisplay<CreditAppLOC>
                field={fields.locSchool}
                {...commonProps}
              />

              <FieldDisplay<CreditAppLOC>
                field={fields.locProgram}
                {...commonProps}
              />

              <FieldDisplay<CreditAppLOC>
                field={fields.locStudentId}
                {...commonProps}
              />

              <FieldDisplay<CreditAppLOC>
                field={fields.locExpectedGradDate}
                {...commonProps}
              />
            </>
          )}

          {commonProps.state.type !== "heloc" && (
            <FieldDisplay<CreditAppLOC>
              field={fields.locHasJointApplication}
              {...commonProps}
            />
          )}
          {commonProps.state.type === "heloc" && (
            <>
              <FieldDisplay<CreditAppLOC>
                field={fields.locInterestType}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locPrimeRate}
                {...commonProps}
              />

              <FieldDisplay<CreditAppLOC>
                field={fields.locInterestRate}
                {...commonProps}
              />

              <FieldDisplay<CreditAppLOC>
                field={fields.locTotalInterestRate}
                {...commonProps}
              />
            </>
          )}
        </Grid>
      </Grid>
      {commonProps.state.type === "heloc" && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "2em" }}>
            HELOC Calculations - Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CreditAppLOC>
                field={fields.locAppraisedValue}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locExistingBalance}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CreditAppLOC>
                field={fields.locEquityAmount}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locPaymentAmount}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
      {commonProps.state.type === "heloc" && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "2em" }}>
            HELOC Calculations - Property Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <AddressFieldDisplay<CreditAppLOC>
                field={fields.locPropertyAddress}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locMarketValue}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locPropertyTax}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locIncludedInMortgage}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locMuncipality}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locLegalDescription}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CreditAppLOC>
                field={fields.locPropertyType}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locBuildingType}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locPropertyStyle}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locNoOfBedrooms}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locNoOfBathrooms}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locPropertyAge}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locParkingType}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locSquareFootage}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locLotSize}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
      {commonProps.state.hasJointApplication && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "2em" }}>
            Joint Application Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CreditAppLOC>
                field={fields.locJointAppName}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locJointAppEmployerName}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locJointAppJobTitle}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CreditAppLOC>
                field={fields.locJointAppEmploymentStartDate}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLOC>
                field={fields.locJointAppMonthlyIncome}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
    </AccountDisplayTemplate>
  );
}

export default CreditAppLoanDisplay;
