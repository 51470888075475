import { getBusinessClientOperatingAs } from "common/functions";
import { BusinessClientSummaryRoute } from "components/paths";
import { useHistory } from "react-router-dom";
import { useReduxState } from "store";
import { BusinessClient } from "types";
import ClientRowTemplate from "pages/clientSearch/components/ClientRowTemplate";

export default function BusinessClientRow(props: {
  client: BusinessClient;
  studentId?: string;
  onDeleteBusinessClient(): void;
  isInstructor: boolean;
}) {
  const history = useHistory();
  const { lessonId } = useReduxState((e) => e.lesson);
  const clientId = props.client.information?.clientId;
  const clientName = getBusinessClientOperatingAs(props.client);
  return (
    <>
      <ClientRowTemplate
        clientId={clientId ?? ""}
        clientName={clientName}
        accountType={props.client.information?.accountType ?? ""}
        phoneNumber={props.client.information?.phoneNumber ?? ""}
        isInstructor={props.isInstructor}
        onDelete={props.onDeleteBusinessClient}
        itemToDelete="Business Client"
        onPortfolioClick={() => {
          !!props.studentId
            ? history.push(
                `/${lessonId}${BusinessClientSummaryRoute}/${clientId}/${props.studentId}`
              )
            : history.push(
                `/${lessonId}${BusinessClientSummaryRoute}/${clientId}`
              );
        }}
        studentId={props.studentId}
      />
    </>
  );
}
