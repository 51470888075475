import { add, getBusinessOverdraftTypeOptions } from "common/functions";
import { FIELD_TYPE, TextFieldType,DateField, MASK_TYPE, BusinessChequing, BoolField, PickListField } from "types";
import { v4 } from "uuid"



export const businessChequingOpenDate: DateField<BusinessChequing> = {
  id: `businessChequingOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: BusinessChequing) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
checkIfDisabled: (account: BusinessChequing) => {   
  return account.isAccountClosed ?? false
}
};

export const businessChequingCloseDate: DateField<BusinessChequing> = {
  id: `businessChequingCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: BusinessChequing) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const businessChequingAccounNumber: TextFieldType<BusinessChequing> = {
  id: `businessChequingAccounNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: BusinessChequing) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true
};

export const businessChequingOverdraftProtection: BoolField<BusinessChequing> = {
  id: `businessChequingOverdraftProtection_${v4()}`,
  label: "Overdraft Protection",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (account: BusinessChequing) => account.overdraftProtection,
  setValue: (value, account) => ({
    ...account,
    overdraftProtection: value,
    overdraftType: value ? account.overdraftType : undefined, 
  }),
  checkIfDisabled: (account: BusinessChequing) => {   
    return account.isAccountClosed ?? false
  }
};

export const businessChequingOverdraftType: PickListField<BusinessChequing> = {
  id: `businessChequingOverdraftType_${v4()}`,
  label: "Overdraft Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: BusinessChequing) => account.overdraftType,
  setValue: (value, account) => ({
    ...account,
    overdraftType: value,
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: getBusinessOverdraftTypeOptions(),
  checkIfDisabled: (account: BusinessChequing) => {   
    return account.isAccountClosed ?? false
  },
};

export const businessChequingODPLimit: TextFieldType<BusinessChequing> = {
  id: `businessChequingODPLimit_${v4()}`,
  label: "ODP Limit",
  type: FIELD_TYPE.TEXT,
  getValue: (account: BusinessChequing) => account.overdraftProtection? account.overdraftLimit?.toString() : "0",
  setValue: (value, account) => ({
    ...account,
    overdraftLimit: value as unknown as number,
  }),
  mask: MASK_TYPE.MONEY,
  checkIfRequired: (account: BusinessChequing) => {   
    return account.overdraftProtection?? false
  },
  checkIfDisabled: (account: BusinessChequing) => {   
    return !account.overdraftProtection || (account.isAccountClosed ?? false)
  }
};

export const businessChequingBalance: TextFieldType<BusinessChequing> = {
  id: `businessChequingBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: BusinessChequing) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    balance: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
};


export const businessChequingAvailableFunds: TextFieldType<BusinessChequing> = {
  id: `businessChequingAvailableFunds_${v4()}`,
  label: "Available Funds",
  type: FIELD_TYPE.TEXT,
  getValue: (account: BusinessChequing) => add(account.overdraftLimit?? 0, account.balance ?? 0).toString(),
  setValue: (value, account) => ({
    ...account,
    availableFunds: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
};

