import { Grid } from "@mui/material";
import { getBusinessLOCTypeLabel } from "common/functions";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessLineOfCreditFieldDefinitions";
import { LineOfCredit } from "types";
import AccountDisplayTemplate from "pages/clientPortfolio/businessClient/businessClientAccounts/components/AccountDisplayTemplate";
export type Props = {
  account: LineOfCredit;
  onEditClick(): void;
  onDeleteClick(): void;
  onAccountHistoryClick(): void;
  isReadOnlyView: boolean;
};

function LineOfCreditDisplay({
  account,
  onEditClick,
  onDeleteClick,
  onAccountHistoryClick,
  isReadOnlyView,
}: Props) {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`LineOfCredit_${account.accountId}_Display`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      onAccountHistoryClick={onAccountHistoryClick}
      accountType="Line Of Credit"
      accountNumber={account.accountNumber || ""}
      type={getBusinessLOCTypeLabel(account.type ?? "")}
      balance={account.balance || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditOpenDate}
            {...commonProps}
          />
          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditAccountNumber}
            {...commonProps}
          />

          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditBalance}
            {...commonProps}
          />

          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditMinimumPayment}
            {...commonProps}
          />
          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditType}
            {...commonProps}
          />

          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditSecurity}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditCloseDate}
            {...commonProps}
          />
          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditLimit}
            {...commonProps}
          />

          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditPrimeRate}
            {...commonProps}
          />

          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditInterestRate}
            {...commonProps}
          />

          <FieldDisplay<LineOfCredit>
            field={fields.lineOfCreditTotalInterestRate}
            {...commonProps}
          />
        </Grid>
      </Grid>
    </AccountDisplayTemplate>
  );
}

export default LineOfCreditDisplay;
