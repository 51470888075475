import { useEffect, useState } from "react";
import { FieldError, BillPayment } from "types";
import styled from "styled-components";
import { Button } from "@mui/material";
import * as fields from "pages/transactions/billPaymentFieldDefinitions";
import { requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";
export type Props = {
  transaction: BillPayment;
  onSaveClick(transaction: BillPayment): void;
};

export default function BillPaymentForm({ transaction, onSaveClick }: Props) {
  const [state, setState] = useState<BillPayment>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: BillPayment) => {
      setState(state);
    },
  };

  function saveBillPayment(transaction: BillPayment) {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      onSaveClick(transaction);
    }
  }

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.billPaymentPayee, state),
      ...requiredTextField(fields.billPaymentPayeeAccountNumber, state),
      ...requiredTextField(fields.billPaymentAmount, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  return (
    <div style={{ padding: "10px" }}>
      <FlexDiv>
        <TransactionHeading>Bill Payment</TransactionHeading>
      </FlexDiv>

      <Container>
        <FieldEditor<BillPayment>
          field={fields.billPaymentPayee}
          {...commonProps}
        />
        <FieldEditor<BillPayment>
          field={fields.billPaymentPayeeAccountNumber}
          {...commonProps}
        />
        <FieldEditor<BillPayment>
          field={fields.billPaymentAmount}
          {...commonProps}
        />
      </Container>
      <SaveButtonConatiner>
        <Button
          disabled={!(state.amount && state.amount > 0)}
          onClick={() => {
            saveBillPayment(state);
          }}
        >
          Save
        </Button>
      </SaveButtonConatiner>
    </div>
  );
}

const TransactionHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const Container = styled.div`
  width: 300px;
  margin: auto;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SaveButtonConatiner = styled.div`
  text-align: right;
  padding: 1em;
`;
