import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import FieldEditor from "components/FieldEditor";
import { FieldError, PersonalClient } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientFieldDefinitions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};

const IdentificationSection = (commonProps: CommonProps) => {
  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="identificationSection-header"
        >
          <Typography variant={"h6"}>Identification</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<PersonalClient>
                field={fields.clientId}
                {...commonProps}
              />
              <FieldEditor<PersonalClient>
                field={fields.sin}
                {...commonProps}
              />
              <FieldEditor<PersonalClient>
                field={fields.primaryIdentification}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldEditor
                field={fields.secondaryIdentification}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  margin-top: 2em;
`;

export default IdentificationSection;
