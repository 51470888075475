import { Entity, FIELD_TYPE, CommonFieldProps, IdName } from "types";
import AddressEditor from "components/fields/AddressEditor";
import ChequesArrayEditor from "components/fields/ChequesArrayEditor";
import CheckboxEditor from "components/fields/CheckboxEditor";
import DateFieldEditor from "components/fields/DateFieldEditor";
import IdentificationEditor from "components/fields/IdentificationEditor";
import PickListFieldEditor from "components/fields/PickListFieldEditor";
import RadioGroupEditor from "components/fields/RadioGroupEditor";
import TextFieldEditor from "components/fields/TextFieldEditor";
import AccountsPickListFieldEditor from "components/fields/AccountsPickListFieldEditor";
import DateTimeFieldEditor from "./fields/DateTimeFieldEditor";

export default function FieldEditor<T extends Entity>(
  props: CommonFieldProps<T> & {
    isDependentlyRequired?: boolean;
    isDependentlyDisabled?: boolean;
    accountOptions?: IdName[];
    menuMaxHeight?: number;
    menuWidth?: number;
    customStyle?: React.CSSProperties;
  }
) {
  if (props.field.type === FIELD_TYPE.DATE) {
    return <DateFieldEditor<T> {...props} />;
  }
  if (props.field.type === FIELD_TYPE.DATETIME) {
    return <DateTimeFieldEditor<T> {...props} />;
  }
  if (props.field.type === FIELD_TYPE.PICKLIST) {
    return <PickListFieldEditor<T> {...props} />;
  }
  if (props.field.type === FIELD_TYPE.IDENTIFICATION) {
    return <IdentificationEditor<T> {...props} />;
  }

  if (props.field.type === FIELD_TYPE.ADDRESS) {
    return <AddressEditor<T> {...props} />;
  }

  if (props.field.type === FIELD_TYPE.TEXT) {
    return <TextFieldEditor<T> {...props} />;
  }

  if (props.field.type === FIELD_TYPE.CHECKBOX) {
    return <CheckboxEditor<T> {...props} />;
  }

  if (props.field.type === FIELD_TYPE.RADIOGROUP) {
    return <RadioGroupEditor<T> {...props} />;
  }
  if (props.field.type === FIELD_TYPE.CHEQUESARRAY) {
    return <ChequesArrayEditor<T> {...props} />;
  }
  if (props.field.type === FIELD_TYPE.ACCOUNTSPICKLIST) {
    return <AccountsPickListFieldEditor<T> {...props} />;
  }

  return <div>unknown type: {props.field.type}</div>;
}
