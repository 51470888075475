import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TextFieldNonFieldEditor from "components/fields/TextFieldNonFieldEditor";
import { useState } from "react";
import { useActions } from "store";
import styled from "styled-components";
import { FieldError, GLEntry, INPUT_TYPE, SellDraft, Transaction } from "types";
import SupervisorOverrideRowGLEntry from "pages/transactions/components/SupervisorOverrideRowGLEntry";
import SupervisorOverrideRowSellDraft from "pages/transactions/components/SupervisorOverrideRowSellDraft";

export type Props = {
  transactions: (SellDraft | GLEntry)[];
  onSubmit(updatedTransactions: SellDraft[] | GLEntry[]): void;
  onCancel(): void;
};

export default function SupervisorOverrideDialog({
  transactions,
  onSubmit,
  onCancel,
}: Props) {
  const [state, setState] = useState<(SellDraft | GLEntry)[]>(transactions);
  const [supervisorCode, setSupervisorCode] = useState<string>("");
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const transactionsActions = useActions().transactions;

  function checkErrors() {
    let errArray: FieldError[] = [];

    if (!supervisorCode) {
      let err: FieldError = {
        id: `overrideCode`,
        label: `Instructor Override Code`,
        error: "Is Required.",
      };
      errArray.push(err);
    }

    setErrors(errArray);
    return errArray;
  }

  function validateSupervisorCode() {
    setIsValidating(true);
    transactionsActions.validateSupervisorOverride({
      overrideCode: supervisorCode,
      onComplete: (payload) => {
        if (payload.valid) {
          setIsValidating(false);
          onSubmit(state);
        } else {
          let errArray: FieldError[] = [];
          let err: FieldError = {
            id: `overrideCode`,
            label: `Instructor Override Code`,
            error: "Incorrect Code.",
          };
          errArray.push(err);
          setErrors(errArray);
          setIsValidating(false);
        }
      },
    });
  }

  function onSubmitClick() {
    let errorArray = checkErrors();

    if (errorArray.length === 0) {
      validateSupervisorCode();
    }
  }

  function onApproveReject(updatedTransaction: Transaction) {
    let updatedTxns = state.map((t) => {
      if (t.transactionId === updatedTransaction.transactionId)
        return updatedTransaction;
      else return t;
    });
    setState(updatedTxns);
  }

  if (state.length > 0)
    return (
      <Dialog maxWidth="md" fullWidth open id="supervisorOverride_Transactions">
        <DialogTitle>
          <Typography>Supervisor Override</Typography>
        </DialogTitle>
        <DialogContent>
          <Table>
            <Header />
            <TableBody>
              {state.map((t, index) => {
                return (
                  <Row
                    transaction={t}
                    key={index}
                    onApproveReject={onApproveReject}
                  />
                );
              })}
            </TableBody>
          </Table>
          <div style={{ width: 300, marginTop: "2em" }}>
            <TextFieldNonFieldEditor
              id={`overrideCode`}
              label={`Supervisor Override Code`}
              value={supervisorCode}
              onChange={(v) => setSupervisorCode(v)}
              required={true}
              multiline={false}
              disabled={false}
              inputType={INPUT_TYPE.PASSWORD}
              errObj={errors.find((e) => e.id === "overrideCode")}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={
              isValidating ||
              (state.filter((t) => t.isApprovalPending === false).length > 0
                ? false
                : true)
            }
            variant="contained"
            onClick={() => {
              onSubmitClick();
            }}
          >
            {isValidating ? "Submitting" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  return (
    <Dialog maxWidth="lg" fullWidth open id="supervisorOverride_Transactions">
      <DialogTitle>
        <Typography>Supervisor Override</Typography>
      </DialogTitle>
      <DialogContent>
        <FlexCenter>No Transactions to override</FlexCenter>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell>Transaction</Cell>
        <Cell>Debit</Cell>
        <Cell>Credit</Cell>
        <Cell>Details</Cell>
        <Cell>Reason</Cell>
        <Cell>Status</Cell>
        <Cell>Action</Cell>
      </TableRow>
    </TableHead>
  );
}

function Row(props: {
  transaction: Transaction;
  onApproveReject(updatedTransaction: Transaction): void;
}) {
  if (props.transaction.transactionType === "SELL_DRAFT") {
    return (
      <SupervisorOverrideRowSellDraft
        transaction={props.transaction as SellDraft}
        onApproveReject={props.onApproveReject}
      />
    );
  }
  if (props.transaction.transactionType === "GL_ENTRY") {
    return (
      <SupervisorOverrideRowGLEntry
        transaction={props.transaction as GLEntry}
        onApproveReject={props.onApproveReject}
      />
    );
  }

  return <></>;
}

const FlexCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2em;
`;

const Cell = styled(TableCell)`
  font-size: 16px !important;
  text-align: center !important;
`;
