import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import MaskedValue from "common/MaskedValue";
import { openConfirm } from "components/confirmBox";
import { ReactNode } from "react";
import styled from "styled-components";
import { MASK_TYPE } from "types";
import AccountActionsButton from "pages/clientPortfolio/businessClient/businessClientAccounts/components/AccountActionsButton";

export type Props = {
  id: string;
  accountType: string;
  mask?: MASK_TYPE;
  accountNumber: string;
  type?: string;
  balance: number;
  onEditClick(): void;
  onDeleteClick(): void;
  onAccountHistoryClick?(): void;
  isReadOnlyView: boolean;
  children: ReactNode;
};

export default function AccountDisplayTemplate({
  id,
  accountType,
  mask,
  accountNumber,
  type,
  balance,
  onEditClick,
  onDeleteClick,
  onAccountHistoryClick,
  isReadOnlyView,
  children,
}: Props) {
  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={id}
      >
        <SummaryItem width="wide">{accountType}</SummaryItem>
        <SummaryItem width="wide" isgrey={"true"}>
          {MaskedValue(mask, accountNumber)}
        </SummaryItem>

        <SummaryItem width="med" isgrey={"true"}>
          {type}
        </SummaryItem>

        <SummaryItem width="small" isrightaligned={"true"}>
          {getMaskedMoneyValue(balance)}
        </SummaryItem>
        <Spacer />
      </AccordionSummary>
      <AccordionDetails>
        <Content>{children}</Content>
        <ButtonDiv>
          <AccountActionsContainer>
            <AccountActionsButton
              onEdit={onEditClick}
              onDelete={() => {
                openConfirm({
                  title: "Delete Account?",
                  description: `${accountType} - ${accountNumber} `,
                  okBtnText: "Delete",
                  callback: () => {
                    onDeleteClick();
                  },
                });
              }}
              accountHistoryValid={
                ["Guaranteed Investment Certificate", "Mutual Funds"].includes(
                  accountType
                )
                  ? false
                  : true
              }
              onAccountHistoryClick={onAccountHistoryClick}
              isReadOnlyView={isReadOnlyView}
            />
          </AccountActionsContainer>
        </ButtonDiv>
      </AccordionDetails>
    </Accordion>
  );
}
const ButtonDiv = styled.div`
  display: flex;
  padding-right: 32px;
`;

const Content = styled.div`
  padding: 0 8px;
`;
const Spacer = styled.div`
  margin: 0 0.75em;
`;

const AccountActionsContainer = styled.div`
  text-align: right;
  flex: 1;
`;

const SummaryItem = styled(Typography)<{
  width: "small" | "med" | "wide";
  isrightaligned?: string;
  isgrey?: string;
}>`
  width: ${(p) =>
    p.width === "med" ? "150px" : p.width === "wide" ? "200px" : "100px"};
  color: ${(p) => (p.isgrey === "true" ? "#0000008A" : "")};
  align-self: "center";
  text-align: ${(p) => (p.isrightaligned === "true" ? "right" : "left")};
`;
