import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import MaskedValue from "common/MaskedValue";
import { ReactNode } from "react";
import { MASK_TYPE } from "types";

export type Props = {
  id: string;
  type: string;
  mask?: MASK_TYPE;
  accountNumber: string;
  balance: number;
  column1Fields: ReactNode;
  column2Fields: ReactNode;
  isSaving: boolean;
  onSaveClick(): void;
  onCancelClick(): void;
};

export default function AccountEditTemplate({
  id,
  type,
  mask,
  accountNumber,
  balance,
  column1Fields,
  column2Fields,
  isSaving,
  onSaveClick,
  onCancelClick,
}: Props) {
  return (
    <Dialog maxWidth="md" fullWidth open id={id}>
      <DialogTitle style={{ display: "flex" }}>
        <Typography sx={{ width: "33%", flex: 5 }}>{type}</Typography>
        <Typography sx={{ width: "33%", flex: 4, color: "#0000008A" }}>
          {MaskedValue(mask, accountNumber)}
        </Typography>
        <Typography sx={{ width: "15%", textAlign: "right" }}>
          {getMaskedMoneyValue(balance)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {column1Fields}
          </Grid>
          <Grid item xs={12} md={6}>
            {column2Fields}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button variant="contained" disabled={isSaving} onClick={onSaveClick}>
          {isSaving ? "Saving" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
