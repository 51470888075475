import { Button } from "@mui/material";
import {
  getAccountOptionsForSessionHistory,
  getPersonalClientName,
  getCreditCardOptionsForSessionHistory,
  getGLEntryClientAccountOptionsForSessionHistory,
  getMaskedMoneyValue,
  getTotalCreditAmount,
  getTotalDebitAmount,
  getBusinessClientOperatingAs,
  getClientId,
} from "common/functions";
import DateFieldNonFieldEditor from "components/fields/DateFieldNonFieldEditor";
import {
  BusinessClientSummaryRoute,
  PersonalClientSummaryRoute,
} from "components/paths";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { BusinessClient, IdName, PersonalClient, Transaction } from "types";
import SummaryDetails from "pages/sessionHistory/components/SummaryDetails";
export type Props = {
  client?: PersonalClient | BusinessClient;
  lessonId: string;
  studentId?: string;
};

export default function TransactionsSummary(props: Props) {
  useEffect(() => {
    let txns = getAllTxns();
    setAllTxns(txns);
    setFilteredTxns(txns);
  }, [props.client]);

  let txnSessions = props.client?.transactionSessions
    ? props.client?.transactionSessions.filter(
        (ts) => ts.sessionId !== props.client?.activeSessionId
      )
    : [];

  const depositWithdrawOptions: IdName[] = getAccountOptionsForSessionHistory(
    props.client ?? {}
  ).filter(
    (op: IdName) =>
      op.name.slice(0, 8) === "Chequing" ||
      op.name.slice(0, 7) === "Savings" ||
      op.name.slice(0, 14) === "Line Of Credit" ||
      op.name.slice(0, 4) === "Loan" ||
      op.name.slice(0, 8) === "Mortgage" ||
      //following is for credit card
      op.name.slice(0, 8) === "Cashback" ||
      op.name.slice(0, 7) === "Student" ||
      op.name.slice(0, 6) === "Travel" ||
      op.name.slice(0, 6) === "No Fee" ||
      op.name.slice(0, 8) === "Business" ||
      op.name.slice(0, 7) === "Rewards"
  );

  function getAllTxns() {
    let allTxns: Transaction[] = [];
    for (let i = 0; i < txnSessions.length; i++) {
      let ts = txnSessions[i];
      let sessionTxns = ts.transactions ?? [];
      for (let j = 0; j < sessionTxns.length; j++) {
        allTxns.push(sessionTxns[j]);
      }
    }
    return allTxns;
  }

  const [selectedTxnDate, setSelectedTxnDate] = useState<string>("");
  const [allTxns, setAllTxns] = useState<Transaction[]>([]);
  const [filteredTxns, setFilteredTxns] = useState<Transaction[]>([]);
  let clientName =
    props.client?.information?.accountType === "Personal"
      ? getPersonalClientName(props.client as PersonalClient)
      : getBusinessClientOperatingAs(props.client as BusinessClient);

  return (
    <Content>
      <TopSection>
        <DateFieldNonFieldEditor
          id="sessionhistory_datefield"
          label="Transaction Date"
          disabled={allTxns.length === 0}
          value={selectedTxnDate}
          onChange={(newValue) => {
            setSelectedTxnDate(newValue);
            let updatedTxns =
              newValue !== "" &&
              newValue.toLowerCase() !== "invalid date" &&
              !!allTxns
                ? allTxns.filter(
                    (t) =>
                      new Date(
                        t.transactionDateTime ?? ""
                      ).toLocaleDateString() ===
                      new Date(newValue).toLocaleDateString()
                  )
                : allTxns;
            setFilteredTxns(updatedTxns);
          }}
          required={false}
        />
        <Spacer />
        <TextDisplay>
          <TwoColumnDisplay label="Client Name" value={clientName} />
        </TextDisplay>
        <TextDisplay>
          <TwoColumnDisplay
            label="Debit"
            value={getMaskedMoneyValue(getTotalDebitAmount(filteredTxns))}
          />
          <TwoColumnDisplay
            label="Credit"
            value={getMaskedMoneyValue(getTotalCreditAmount(filteredTxns))}
          />
        </TextDisplay>
        <Button
          disabled={!getClientId(props.client)}
          href={
            props.client?.information?.accountType === "Personal"
              ? !!props.studentId
                ? `/${
                    props.lessonId
                  }${PersonalClientSummaryRoute}/${getClientId(props.client)}/${
                    props.studentId
                  }`
                : `/${
                    props.lessonId
                  }${PersonalClientSummaryRoute}/${getClientId(props.client)}`
              : !!props.studentId
              ? `/${props.lessonId}${BusinessClientSummaryRoute}/${getClientId(
                  props.client
                )}/${props.studentId}`
              : `/${props.lessonId}${BusinessClientSummaryRoute}/${getClientId(
                  props.client
                )}`
          }
        >
          Portfolio
        </Button>
      </TopSection>
      {filteredTxns.length === 0 && (
        <NoTxnDiv>
          <div>There are no transactions</div>
        </NoTxnDiv>
      )}
      {filteredTxns.length > 0 && (
        <SummaryDetails
          transactions={filteredTxns}
          depositWithdrawTransferOptions={depositWithdrawOptions}
          creditCardOptions={getCreditCardOptionsForSessionHistory(
            props.client ?? {}
          )}
          glEntryClientAccountOptions={getGLEntryClientAccountOptionsForSessionHistory(
            props.client ?? {}
          )}
        />
      )}
    </Content>
  );
}

function TwoColumnDisplay(props: { label: string; value: string }) {
  return (
    <RowContainer>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </RowContainer>
  );
}

const Content = styled.div`
  min-height: 300px;
  width: inherit;
`;

const NoTxnDiv = styled.div`
  margin: 3em;
  display: flex;
  justify-content: center;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 1.75em 1em;
`;

const Spacer = styled.div`
  margin: 0em 1.5em;
`;

const Label = styled.label`
  color: #0000008a;
  padding: 0 10px;
  font-size: 16px;
`;
const Value = styled.div`
  font-size: 16px;
  padding: 0 10px 0 5px;
`;
const RowContainer = styled.div`
  display: flex;
  margin-right: 6em;
`;

const TextDisplay = styled.div`
  display: flex;
  flex: 1;
  justify-content: left;
`;
