import { FIELD_TYPE, CustomStudent, TextFieldType } from "types";

export const studentId: TextFieldType<CustomStudent> = {
  id: "studentId",
  label: "Student Id",
  type: FIELD_TYPE.TEXT,
  getValue: (student: CustomStudent) => student.studentId ?? "",
  setValue: (value, student) => ({
    ...student,
    studentId: value,
  }),
  isRequired: true,
};

export const fullName: TextFieldType<CustomStudent> = {
  id: "fullName",
  label: "Student Name",
  type: FIELD_TYPE.TEXT,
  getValue: (student: CustomStudent) => student.fullName ?? "",
  setValue: (value, student) => ({
    ...student,
    fullName: value,
  }),
  isRequired: true,
};

export const username: TextFieldType<CustomStudent> = {
  id: "username",
  label: "Username",
  type: FIELD_TYPE.TEXT,
  getValue: (student: CustomStudent) => student.userName ?? "",
  setValue: (value, student) => ({
    ...student,
    userName: value,
  }),
  isRequired: true,
};