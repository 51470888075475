import { add, getBusinessLOCTypeOptions, getPrimeRate } from "common/functions";
import { FIELD_TYPE, TextFieldType,DateField, MASK_TYPE, PickListField, LineOfCredit } from "types";
import { v4 } from "uuid"


export const lineOfCreditOpenDate: DateField<LineOfCredit> = {
  id: `lineOfCreditOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: LineOfCredit) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: LineOfCredit) => {   
  return account.isAccountClosed ?? false
}
};

export const lineOfCreditCloseDate: DateField<LineOfCredit> = {
  id: `lineOfCreditCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: LineOfCredit) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const lineOfCreditAccountNumber: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditAccountNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true,
};



export const lineOfCreditLimit: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditLimit_${v4()}`,
  label: "Limit",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.limit?.toString(),
  setValue: (value, account) => ({
    ...account,
    limit: value as unknown as number,
  }),
  mask: MASK_TYPE.MONEY,
 isRequired: true,
 checkIfDisabled: (account: LineOfCredit) => {   
  return account.isAccountClosed ?? false
}
};

export const lineOfCreditBalance: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    balance: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true
};

export const lineOfCreditInterestRate: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditInterestRate_${v4()}`,
  label: "Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.interestRate,
  setValue: (value, account) => ({
    ...account,
    interestRate: value,
  }),
  isRequired: true,
  mask: MASK_TYPE.PERCENTAGE,
  checkIfDisabled: (account: LineOfCredit) => {   
    return account.isAccountClosed ?? false
  },
  checkIfAllowNegative: (account : LineOfCredit) =>{
    return account.interestType === "Fixed"? false : true
  }
};



function getPercentValue(percent: number, balance: number){
  const val = (percent * balance)/100
  return val
}

export const lineOfCreditMinimumPayment: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditMinimumPayment_${v4()}`,
  label: "Minimum Payment",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => {
    let minPaymentValue = getPercentValue(3, account.balance ?? 0);
    if(minPaymentValue > 0)
      return minPaymentValue.toString()
    else 
      return "0"
    },
  setValue: (value, account) => ({
    ...account,
    minimumPayment: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
};


export const lineOfCreditType: PickListField<LineOfCredit> = {
  id: `lineOfCreditType_${v4()}`,
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: LineOfCredit) => "bloc",
  setValue: (value, account) => ({
    ...account,
    type: value,
    interestType: "Variable",
  }),
  options: getBusinessLOCTypeOptions(),
  disabled: true,
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: LineOfCredit) => {   
  return account.isAccountClosed ?? false
}
};

export const lineOfCreditSecurity: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditSecurity_${v4()}`,
  label: "Security",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.security,
  setValue: (value, account) => ({
    ...account,
    security: value,
  }),
 isRequired: true,
  checkIfDisabled: (account: LineOfCredit) => {   
    return account.isAccountClosed ?? false
  }
};


export const lineOfCreditPrimeRate: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditPrimeRate_${v4()}`,
  label: "Prime Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => getPrimeRate(),
  setValue: (value, account) => ({
    ...account,
    primeRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE
};


export const lineOfCreditTotalInterestRate: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditTotalInterestRate_${v4()}`,
  label: "Total Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => {
    let tir = add((account.interestRate?? 0) as unknown as number, (getPrimeRate() ?? 0) as unknown as number);
    if(tir > 0)
      return tir.toString();
    else
      return "0"
  } ,
  setValue: (value, account) => ({
    ...account,
    totalInterestRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE
};

