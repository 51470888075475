import { Grid } from "@mui/material";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessGICFieldDefinitions";
import { GIC } from "types";
import AccountDisplayTemplate from "pages/clientPortfolio/businessClient/businessClientAccounts/components/AccountDisplayTemplate";
export type Props = {
  account: GIC;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

function GICDisplay({
  account,
  onEditClick,
  onDeleteClick,
  isReadOnlyView,
}: Props) {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`GIC_${account.accountId}_Display`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      accountType="Guaranteed Investment Certificate"
      accountNumber={account.accountNumber || ""}
      type={account.type || ""}
      balance={account.balance || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldDisplay<GIC> field={fields.gicOpenDate} {...commonProps} />
          <FieldDisplay<GIC> field={fields.gicAccountNumber} {...commonProps} />
          <FieldDisplay<GIC> field={fields.gicBalance} {...commonProps} />
          <FieldDisplay<GIC> field={fields.gicType} {...commonProps} />
          <FieldDisplay<GIC>
            field={fields.gicInvestmentRate}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<GIC> field={fields.gicCloseDate} {...commonProps} />
          <FieldDisplay<GIC> field={fields.gicTermLength} {...commonProps} />
          <FieldDisplay<GIC> field={fields.gicMaturityDate} {...commonProps} />
          <FieldDisplay<GIC>
            field={fields.gicInvestmentCategory}
            {...commonProps}
          />
        </Grid>
      </Grid>
    </AccountDisplayTemplate>
  );
}

export default GICDisplay;
