import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import { useState } from "react";
import styled from "styled-components";
import { CustomLiability } from "types";
import { v4 } from "uuid";
import CustomLiabilitiesList from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CustomLiabilitiesList";
import CustomLiabilityEditor from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CustomLiabilityEditor";

export type Props = {
  customLiabilities: CustomLiability[];
  naitlabLiabilitiesTotal: number;
  creditAppLiabilitiesTotal: number;
  open: boolean;
  onClose(): void;
  onAddEditCustomLiability(updatedCustomLiability: CustomLiability): void;
  onDeleteCustomLiability(customLiability: CustomLiability): void;
  isReadOnlyView: boolean;
};

export default function ManageLiabilitiesDialog({
  customLiabilities,
  naitlabLiabilitiesTotal,
  creditAppLiabilitiesTotal,
  open,
  onClose,
  onAddEditCustomLiability,
  onDeleteCustomLiability,
  isReadOnlyView,
}: Props) {
  function addCustomLiability() {
    let customLiability: CustomLiability = {
      id: v4(),
      hasTDS: true,
    };
    setCustomLiabilityInEdit(customLiability);
  }

  const [customLiabilityInEdit, setCustomLiabilityInEdit] = useState<
    CustomLiability | undefined
  >(undefined);

  return (
    <Dialog maxWidth={"md"} open={open}>
      <DialogContent>
        <Header>
          <Typography style={{ flexGrow: 1 }} variant={"h6"}>
            Manage Liabilities
          </Typography>
          <Button
            variant="contained"
            onClick={addCustomLiability}
            disabled={isReadOnlyView}
          >
            Add
          </Button>
        </Header>

        <ContentContainer>
          <CustomLiabilitiesList
            customLiabilities={customLiabilities ?? []}
            onEditCustomLiability={(customLiability) => {
              setCustomLiabilityInEdit(customLiability);
            }}
            onDeleteCustomLiability={(customLiability) => {
              onDeleteCustomLiability(customLiability);
            }}
            isReadOnlyView={isReadOnlyView}
          />
        </ContentContainer>
        <Divider style={{ marginTop: "3em" }} />
        <TotalSection>
          <LeftSection>
            <LightBold>NAITLAB Liabilities</LightBold>
            {getMaskedMoneyValue(naitlabLiabilitiesTotal)}
            <Spacer />
            CAD
          </LeftSection>

          <RightSection>
            <LightBold>Total Liabilities </LightBold>{" "}
            {getMaskedMoneyValue(creditAppLiabilitiesTotal)}
            <Spacer />
            CAD
          </RightSection>
        </TotalSection>
        <Divider />
        <ButtonContainer>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </ButtonContainer>
        {!!customLiabilityInEdit && (
          <CustomLiabilityEditor
            customLiability={customLiabilityInEdit}
            onAddorUpdateClick={(updatedCustomLiability) => {
              onAddEditCustomLiability(updatedCustomLiability);
              setCustomLiabilityInEdit(undefined);
            }}
            onCancel={() => {
              setCustomLiabilityInEdit(undefined);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

const Header = styled.div`
  display: flex;
  padding: 1em 0.25em;
`;

const ContentContainer = styled.div`
  min-width: 600px;
  justify-content: stretch;
  display: flex;
`;

const LightBold = styled.div`
  font-weight: 500;
  margin-right: 10px;
`;
const LeftSection = styled.div`
  flex: 1;
  display: flex;
`;
const RightSection = styled.div`
  text-align: right;
  display: flex;
`;
const Spacer = styled.div`
  margin-right: 5px;
`;
const TotalSection = styled.div`
  margin: 1em;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 1em auto 0em auto;
  justify-content: center;
`;
