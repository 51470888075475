import { Divider } from "@mui/material";
import { Deposit, IdName } from "types";
import SummaryRowTemplate from "pages/sessionHistory/components/SummaryRowTemplate";
import styled from "styled-components";
import * as fields from "pages/transactions/depositFieldDefinitions";
import { getMaskedMoneyValue } from "common/functions";
import moment from "moment";

type SummaryRowDepositProps = {
  transaction: Deposit;
  depositToOptions: IdName[];
};

export default function SummaryRowDeposit(props: SummaryRowDepositProps) {
  const currencyType = props.transaction.currencyType ?? "";
  const toLabel = fields.depositTo.getOptionLabel
    ? fields.depositTo.getOptionLabel(
        props.transaction.to ?? "",
        props.depositToOptions
      )
    : "";
  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  return (
    <SummaryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transaction="Deposit"
      credit={transactionAmount}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection style={{ textAlign: "center" }}>From</TextSection>
        <TextSection style={{ textAlign: "center" }}> To</TextSection>
        <TextSection style={{ textAlign: "center" }}>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>Session Balance</TextSection>
        <TextSection>
          {toLabel === "" ? "Deleted Account" : toLabel}
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${transactionAmount} ${currencyType.toUpperCase()}`}
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;
