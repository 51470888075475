
import { DateField, MASK_TYPE } from "types";
import { FIELD_TYPE, BusinessSavings, TextFieldType } from "types";
import { v4 } from "uuid"

export const businessSavingsOpenDate: DateField<BusinessSavings> = {
  id: `businessSavingsOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: BusinessSavings) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: BusinessSavings ) => {   
  return account.isAccountClosed ?? false
}
};

export const businessSavingsCloseDate: DateField<BusinessSavings> = {
  id: `businessSavingsCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: BusinessSavings) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const businessSavingsAccounNumber: TextFieldType<BusinessSavings> = {
  id: `businessSavingsAccounNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: BusinessSavings) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true
};

export const businessSavingsBalance: TextFieldType<BusinessSavings> = {
  id: `businessSavingsBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: BusinessSavings) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    accounts: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
};

