import { add, getPrimeRate } from "common/functions";
import { FIELD_TYPE, TextFieldType,DateField, MASK_TYPE, PickListField, LineOfCredit, RadioGroupField } from "types";
import { v4 } from "uuid"

export const lineOfCreditOpenDate: DateField<LineOfCredit> = {
  id: `lineOfCreditOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: LineOfCredit) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: LineOfCredit) => {   
  return account.isAccountClosed ?? false
}
};




export const lineOfCreditCloseDate: DateField<LineOfCredit> = {
  id: `lineOfCreditCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: LineOfCredit) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const lineOfCreditAccountNumber: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditAccountNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true,
};



export const lineOfCreditLimit: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditLimit_${v4()}`,
  label: "Limit",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.limit?.toString(),
  setValue: (value, account) => ({
    ...account,
    limit: value as unknown as number,
  }),
  mask: MASK_TYPE.MONEY,
 isRequired: true,
 checkIfDisabled: (account: LineOfCredit) => {   
  return account.isAccountClosed ?? false
}
};

export const lineOfCreditBalance: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    balance: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true
};

export const lineOfCreditInterestRate: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditInterestRate_${v4()}`,
  label: "Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.interestRate,
  setValue: (value, account) => ({
    ...account,
    interestRate: value,
  }),
  isRequired: true,
  mask: MASK_TYPE.PERCENTAGE,
  checkIfDisabled: (account: LineOfCredit) => {   
    return account.isAccountClosed ?? false
  },
  checkIfAllowNegative: (account : LineOfCredit) =>{
    return account.interestType === "Fixed"? false : true
  }
};



function getPercentValue(percent: number, balance: number){
  const val = (percent * balance)/100
  return val
}

function getMinimumPayment(account: LineOfCredit)
{

  if(account.type === "uloc")
  {
    let minPayment = getPercentValue(3, account.balance ?? 0);
    if (minPayment < 50)
      return 50;
    else
      return minPayment;
      
  }
  else if (["heloc","sloc","studentloc"].includes(account.type?.toLowerCase() ?? ""))
  {
    let tir = getTotalInterestRate(account);
    let minPayment = (getPercentValue(tir, account.balance ?? 0))/12; //dividing by 12 to get monthly payment
    return minPayment;
  }
  else
    return 0;
  
}

export const lineOfCreditMinimumPayment: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditMinimumPayment_${v4()}`,
  label: "Minimum Payment",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => getMinimumPayment(account).toString(),
  setValue: (value, account) => ({
    ...account,
    minimumPayment: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
};


export const lineOfCreditType: PickListField<LineOfCredit> = {
  id: `lineOfCreditType_${v4()}`,
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: LineOfCredit) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
    interestType: "Variable",
    security: "",
  }),
  options: [
    { id: "uloc", name: "ULOC" },
    { id: "heloc", name: "HELOC" },
    { id: "sloc", name: "SLOC" },
    { id: "studentloc", name: "Student LOC" },
  ],
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: LineOfCredit) => {   
  return account.isAccountClosed ?? false
}
};

export const lineOfCreditSecurity: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditSecurity_${v4()}`,
  label: "Security",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => account.security,
  setValue: (value, account) => ({
    ...account,
    security: value,
  }),
  checkIfRequired: (account: LineOfCredit) => {   
    return account.type === "sloc"?? false
  },
  checkIfDisabled: (account: LineOfCredit) => {   
    return account.isAccountClosed ?? false
  }
};


export const lineOfCreditInterestType: RadioGroupField<LineOfCredit> = {
  id: `lineOfCreditInterestType_${v4()}`,
  label: "Interest Type",
  trueLabel: "Fixed",
  falseLabel: "Variable",
  defaultValue: "Variable",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: LineOfCredit) => account.interestType,
  setValue: (value, account) => ({
    ...account,
    interestType: value,
  }),
 isRequired: true,
 disabled: true,
};

export const lineOfCreditPrimeRate: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditPrimeRate_${v4()}`,
  label: "Prime Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => getPrimeRate(),
  setValue: (value, account) => ({
    ...account,
    primeRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE
};

function getTotalInterestRate(account: LineOfCredit)
{
  let tir = add((account.interestRate?? 0) as unknown as number, (getPrimeRate() ?? 0) as unknown as number);
  if(tir > 0)
      return tir;
    else
      return 0
}


export const lineOfCreditTotalInterestRate: TextFieldType<LineOfCredit> = {
  id: `lineOfCreditTotalInterestRate_${v4()}`,
  label: "Total Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: LineOfCredit) => getTotalInterestRate(account).toString(),
  setValue: (value, account) => ({
    ...account,
    totalInterestRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE
};

