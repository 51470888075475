import { useEffect, useState } from "react";
import { FieldError, Deposit, IdName } from "types";
import styled from "styled-components";
import { Button } from "@mui/material";
import * as fields from "pages/transactions/depositFieldDefinitions";
import { requiredPickListField, requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";
import CurrencyToggleMenu from "pages/transactions/components/CurrencyToggleMenu";
export type Props = {
  transaction: Deposit;
  depositToOptions: IdName[];
  onSaveClick(transaction: Deposit): void;
};

export default function DepositForm({
  transaction,
  depositToOptions,
  onSaveClick,
}: Props) {
  const [state, setState] = useState<Deposit>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: Deposit) => {
      setState(state);
    },
  };

  function saveDeposit(transaction: Deposit) {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      onSaveClick(transaction);
    }
  }

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.depositAmount, state),
      ...requiredPickListField(fields.depositTo, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  return (
    <div style={{ padding: "10px" }}>
      <FlexDiv>
        <TransactionHeading>Deposit</TransactionHeading>
        <CurrencyToggleMenu onToggle={() => {}} isDisabled={true} />
      </FlexDiv>
      {depositToOptions.length === 0 && (
        <Container>No Active Deposit Accounts</Container>
      )}
      {depositToOptions.length > 0 && (
        <>
          <Container>
            <FieldEditor<Deposit> field={fields.depositFrom} {...commonProps} />
            <FieldEditor<Deposit>
              field={fields.depositTo}
              {...commonProps}
              accountOptions={depositToOptions}
            />
            <FieldEditor<Deposit>
              field={fields.depositAmount}
              {...commonProps}
            />
          </Container>
          <SaveButtonConatiner>
            <Button
              disabled={!(state.amount && state.amount > 0)}
              onClick={() => {
                saveDeposit(state);
              }}
            >
              Save
            </Button>
          </SaveButtonConatiner>
        </>
      )}
    </div>
  );
}

const TransactionHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const Container = styled.div`
  width: 300px;
  margin: auto;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SaveButtonConatiner = styled.div`
  text-align: right;
  padding: 1em;
`;
