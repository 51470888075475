import {
  getGLEntryReasonLabel,
  getGLEntryReasonOptions,
  getMaskedMoneyValue,
} from "common/functions";
import moment from "moment";
import { GLEntry, GLTransaction, SellDraft } from "types";
import GLAccountHistoryRowTemplate from "pages/generalLedger/components/GLAccountHistoryRowTemplate";

type GLAccountHistoryRowCreditProps = {
  balance: number;
  transaction: GLEntry | GLTransaction;
};

export default function GLAccountHistoryRowCredit(
  props: GLAccountHistoryRowCreditProps
) {
  const transactionAmount =
    props.transaction.transactionType === "SELL_DRAFT"
      ? getMaskedMoneyValue((props.transaction as SellDraft).fee ?? 0)
      : getMaskedMoneyValue(props.transaction.amount ?? 0);

  let reason =
    props.transaction.transactionType === "CASH_TRANSFER"
      ? "Cash Drawer"
      : props.transaction.transactionType === "SELL_DRAFT"
      ? "Draft Fee"
      : getGLEntryReasonOptions()
          .map((o) => o.id)
          .includes((props.transaction as GLEntry).reason ?? "")
      ? getGLEntryReasonLabel((props.transaction as GLEntry).reason ?? "")
      : (props.transaction as GLEntry).reason;

  return (
    <GLAccountHistoryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      reason={reason}
      transactionDescription={props.transaction.transactionDescription}
      credit={transactionAmount}
      balance={getMaskedMoneyValue(props.balance)}
    />
  );
}
