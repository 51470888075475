import FieldEditor from "components/FieldEditor";
import { FieldError, LineOfCredit } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientAccounts/personalLineOfCreditFieldDefinitions";
import { useEffect, useState } from "react";
import {
  requiredDateField,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import AccountEditTemplate from "pages/clientPortfolio/personalClient/personalClientAccounts/components/AccountEditTemplate";
import { openConfirm } from "components/confirmBox";
export type Props = {
  account: LineOfCredit;
  isSaving: boolean;
  onCancel(): void;
  onSave(account: LineOfCredit): void;
};

function LineOfCreditForm({ account, onCancel, onSave, isSaving }: Props) {
  const [state, setState] = useState<LineOfCredit>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: LineOfCredit) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredDateField(fields.lineOfCreditOpenDate, state),
      ...requiredTextField(fields.lineOfCreditLimit, state),
      ...requiredTextField(fields.lineOfCreditInterestRate, state),
      ...requiredPickListField(fields.lineOfCreditType, state),
      ...requiredTextField(fields.lineOfCreditSecurity, state),
    ];

    if (
      state.closeDate &&
      state.openDate &&
      state.closeDate.toLowerCase() !== "invalid date" &&
      new Date(state.closeDate) < new Date(state.openDate)
    ) {
      let invalidCloseDateErr: FieldError = {
        id: `${fields.lineOfCreditCloseDate.id}`,
        label: `${fields.lineOfCreditCloseDate.label}`,
        error: "Close Date should be after the Open Date",
      };
      errorsArray.push(invalidCloseDateErr);
    }
    setErrors(errorsArray);
    return errorsArray;
  }

  function onSaveClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (
        state.closeDate &&
        state.closeDate.toLowerCase() !== "invalid date" &&
        state.balance &&
        state.balance !== 0
      ) {
        openConfirm({
          title: "Error",
          description: `Account Balance should be $0.00 to close the account`,
          isAlert: true,
        });
      } else {
        onSave({
          ...state,
          isAccountClosed:
            state.closeDate && state.closeDate.toLowerCase() !== "invalid date"
              ? true
              : false,
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`LineOfCredit_${account.accountId}_form`}
      onSaveClick={onSaveClick}
      onCancelClick={onCancel}
      isSaving={isSaving}
      type="Line Of Credit"
      accountNumber={account.accountNumber || ""}
      balance={account.balance || 0}
      column1Fields={
        <>
          <FieldEditor<LineOfCredit>
            field={fields.lineOfCreditOpenDate}
            {...commonProps}
          />
          <FieldEditor<LineOfCredit>
            field={fields.lineOfCreditAccountNumber}
            {...commonProps}
          />

          <FieldEditor<LineOfCredit>
            field={fields.lineOfCreditBalance}
            {...commonProps}
          />

          <FieldEditor<LineOfCredit>
            field={fields.lineOfCreditMinimumPayment}
            {...commonProps}
          />

          <FieldEditor<LineOfCredit>
            field={fields.lineOfCreditType}
            {...commonProps}
          />
          {state.type === "sloc" && (
            <FieldEditor<LineOfCredit>
              field={fields.lineOfCreditSecurity}
              {...commonProps}
            />
          )}
          <FieldEditor<LineOfCredit>
            field={fields.lineOfCreditLimit}
            {...commonProps}
          />
        </>
      }
      column2Fields={
        <>
          <FieldEditor<LineOfCredit>
            field={fields.lineOfCreditCloseDate}
            {...commonProps}
          />

          {state.type === "heloc" && (
            <FieldEditor<LineOfCredit>
              field={fields.lineOfCreditInterestType}
              {...commonProps}
            />
          )}
          {state.interestType === "Variable" && (
            <FieldEditor<LineOfCredit>
              field={fields.lineOfCreditPrimeRate}
              {...commonProps}
            />
          )}
          <FieldEditor<LineOfCredit>
            field={fields.lineOfCreditInterestRate}
            {...commonProps}
          />
          {state.interestType === "Variable" && (
            <FieldEditor<LineOfCredit>
              field={fields.lineOfCreditTotalInterestRate}
              {...commonProps}
            />
          )}
        </>
      }
    />
  );
}

export default LineOfCreditForm;
