import styled from "styled-components";
import { FieldError, PersonalClient } from "types";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/clientPortfolio/personalClient/personalClientInvesting/investingFieldDefinitions";
import moment from "moment";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};
export default function ClientAuthorizationSection(props: CommonProps) {
  return (
    <>
      <Disclaimer>
        By entering the client's name below, customer gives authorization to
        complete the investor profile and advisor has met all compliance
        requirements:
      </Disclaimer>
      <AuthorizationContainer>
        <FieldEditor<PersonalClient>
          field={fields.clientAuthorizationName}
          {...props}
        />
      </AuthorizationContainer>

      {props.state.investing?.lastCompletedDate && (
        <LastCompletedDiv>
          Last Completed:{" "}
          {moment(props.state.investing?.lastCompletedDate)
            .format("MM/DD/YYYY")
            .toString()}
        </LastCompletedDiv>
      )}
    </>
  );
}

const AuthorizationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding: 0em 2em;
`;
const Disclaimer = styled.div`
  padding: 0.5em 1em 0em 1em;
`;

const LastCompletedDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding: 0em 2em;
`;
