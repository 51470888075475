import styled from "styled-components";
import {
  CreditAppAccount,
  CreditAppCreditCard,
  CreditAppLoan,
  CreditAppLOC,
  CreditAppMortgage,
} from "types";
import CreditAppCreditCardDisplay from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppCreditCardDisplay";
import CreditAppLoanDisplay from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppLoanDisplay";
import CreditAppLOCDisplay from "./CreditAppLOCDisplay";
import CreditAppMortgageDisplay from "./CreditAppMortgageDisplay";

export type AccountDetailsProps = {
  creditApps: CreditAppAccount[];
  onEdit(account: CreditAppAccount): void;
  onDelete(account: CreditAppAccount): void;
  isReadOnlyView: boolean;
};

export default function AccountDetails(props: AccountDetailsProps) {
  if (props.creditApps === undefined || props.creditApps.length < 1)
    return (
      <Message>
        No Active Account. Click&nbsp;<b>Add</b>&nbsp;to Add an Account
      </Message>
    );
  return (
    <div>
      {props.creditApps.map((cApp, index) => {
        return (
          <Row
            creditAppAccount={cApp}
            key={index}
            onEditClick={() => {
              props.onEdit(cApp);
            }}
            onDeleteClick={() => {
              props.onDelete(cApp);
            }}
            isReadOnlyView={props.isReadOnlyView}
          />
        );
      })}
    </div>
  );
}

function Row(props: {
  creditAppAccount: CreditAppAccount;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
}) {
  if (props.creditAppAccount.accountType === "CREDIT_CARD") {
    let creditAppCreditCard = props.creditAppAccount as CreditAppCreditCard;
    return (
      <CreditAppCreditCardDisplay
        account={creditAppCreditCard}
        isReadOnlyView={props.isReadOnlyView}
        onEditClick={props.onEditClick}
        onDeleteClick={props.onDeleteClick}
      />
    );
  }
  if (props.creditAppAccount.accountType === "LINE_OF_CREDIT") {
    let creditAppLOC = props.creditAppAccount as CreditAppLOC;
    return (
      <CreditAppLOCDisplay
        account={creditAppLOC}
        isReadOnlyView={props.isReadOnlyView}
        onEditClick={props.onEditClick}
        onDeleteClick={props.onDeleteClick}
      />
    );
  }
  if (props.creditAppAccount.accountType === "LOAN") {
    let creditAppLoan = props.creditAppAccount as CreditAppLoan;
    return (
      <CreditAppLoanDisplay
        account={creditAppLoan}
        isReadOnlyView={props.isReadOnlyView}
        onEditClick={props.onEditClick}
        onDeleteClick={props.onDeleteClick}
      />
    );
  }
  if (props.creditAppAccount.accountType === "MORTGAGE") {
    let creditAppMortgage = props.creditAppAccount as CreditAppMortgage;
    return (
      <CreditAppMortgageDisplay
        account={creditAppMortgage}
        isReadOnlyView={props.isReadOnlyView}
        onEditClick={props.onEditClick}
        onDeleteClick={props.onDeleteClick}
      />
    );
  }

  return <></>;
}

const Message = styled.div`
  display: flex;
  justify-content: center;
  margin: 4em auto 6em auto;
`;
