import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import isEmpty from "lodash/isEmpty";
import { InputBase } from "@mui/material";

export type Props = {
  label?: string;
  value: string;
  onChange?: (text: string) => void;
  disabled?: boolean;
};

const SearchTextField = ({ label, onChange, value, disabled }: Props) => {
  const isFull = !isEmpty(value);
  const handleTextChange = (s: string) => onChange?.(s);

  return (
    <Container mode={isFull ? "full" : "empty"}>
      <SearchIcon />
      <StyledInputBase
        placeholder={label || "Search"}
        inputProps={{ "aria-label": "search google maps" }}
        value={value}
        onChange={(e) => handleTextChange(e.target.value)}
        disabled={disabled}
      />
      {isFull && (
        <MKIconButton aria-label="cancel" onClick={(e) => handleTextChange("")}>
          <CancelIcon />
        </MKIconButton>
      )}
    </Container>
  );
};

export default SearchTextField;

type StyledPaperProps = {
  mode: "full" | "empty";
};

const BACKGROUND_COLOR_PLACEHOLDER = "#e6e6e6";
const BACKGROUND_COLOR_HIGHLIGHT = "#e8f0fe";
const FONT_COLOR = "rgba(0,0,0,0.95)";
const FONT_COLOR_PLACEHOLDER = "rgba(0,0,0,0.55)";
const CONTROL_COLOR_PRIMARY = "#1a73e8";
const FONT_COLOR_HIGHLIGHT = "#1967d2";

const Container = styled.div<StyledPaperProps>`
  padding: 0.125em 0.25em;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.mode === "full"
      ? BACKGROUND_COLOR_HIGHLIGHT
      : BACKGROUND_COLOR_PLACEHOLDER};
  border: 2px solid
    ${(props) =>
      props.mode === "full"
        ? BACKGROUND_COLOR_HIGHLIGHT
        : BACKGROUND_COLOR_PLACEHOLDER};
  color: ${(props) =>
    props.mode === "full" ? CONTROL_COLOR_PRIMARY : FONT_COLOR_PLACEHOLDER};
  &:focus-within {
    border: 2px solid ${CONTROL_COLOR_PRIMARY};
    background-color: var(--mmc-white);
  }
  border-radius: 8px;
  & > div > input {
    color: ${(props) =>
      props.mode === "full" ? FONT_COLOR_HIGHLIGHT : FONT_COLOR};
  }
`;

const StyledInputBase = styled(InputBase)`
  margin-left: 1em;
  flex: 1;
  & > input {
    font-size: 0.875rem;
    font-weight: 500;
  }

  & > input:focus {
    font-weight: 400;
    color: ${FONT_COLOR};
  }
  & > input:disabled {
    cursor: not-allowed;
  }
`;

const MKIconButton = styled.button`
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  overflow: visible;
  padding: 0.15em;
  font-size: 1.125rem;
`;
