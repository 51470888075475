import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";

import React from "react";
import { useActions, useReduxState } from "store";
import { ConfigProps } from "./Snackbar.duck";

let openSnackbarMethod: (props: ConfigProps) => void;

const SnackBar = () => {
  const snackBar = useReduxState((e) => e.snackbar);
  const actions = useActions().snackbar;
  const { isOpen, config } = snackBar;

  React.useEffect(() => {
    if (!openSnackbarMethod) {
      openSnackbarMethod = (props: ConfigProps) => {
        actions.openSnackbar(props);
      };
    }
  }, []);

  if (!snackBar || !isOpen) {
    return <div />;
  }

  let severityType = !config.severity ? "success" : config.severity;
  return (
    isOpen && (
      <Snackbar
        open
        sx={{ zIndex: 4000 }}
        autoHideDuration={6000}
        onClose={actions.closeSnackbar}
      >
        <Alert
          onClose={actions.closeSnackbar}
          severity={severityType}
          sx={{ width: "100%" }}
        >
          {config.message}
        </Alert>
      </Snackbar>
    )
  );
};

export const openSnackbar = (props: ConfigProps) => {
  if (openSnackbarMethod) {
    openSnackbarMethod(props);
  }
};

export type Props = ConfigProps;

export default SnackBar;
