import { Divider } from "@mui/material";
import { IdName, Transfer } from "types";
import SummaryRowTemplate from "pages/sessionHistory/components/SummaryRowTemplate";
import styled from "styled-components";
import * as fields from "pages/transactions/transferFieldDefinitions";
import { getMaskedMoneyValue } from "common/functions";
import moment from "moment";

type SummaryRowTransferProps = {
  transaction: Transfer;
  transferOptions: IdName[];
};

export default function SummaryRowTransfer(props: SummaryRowTransferProps) {
  const currencyType = props.transaction.currencyType ?? "";
  const fromLabel = fields.transferFrom.getOptionLabel
    ? fields.transferFrom.getOptionLabel(
        props.transaction.from ?? "",
        props.transferOptions
      )
    : "";
  const toLabel = fields.transferTo.getOptionLabel
    ? fields.transferTo.getOptionLabel(
        props.transaction.to ?? "",
        props.transferOptions
      )
    : "";

  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);
  return (
    <SummaryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transaction="Transfer"
      details={transactionAmount}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection style={{ textAlign: "center" }}>From</TextSection>
        <TextSection style={{ textAlign: "center" }}> To</TextSection>
        <TextSection style={{ textAlign: "center" }}>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>
          {fromLabel === "" ? "Deleted Account" : fromLabel}
        </TextSection>
        {/* <TextSection>{props.transaction.to}</TextSection> */}
        <TextSection>
          {toLabel === "" ? "Deleted Account" : toLabel}
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${transactionAmount} ${currencyType.toUpperCase()}`}
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;
