import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { GeneralLedger } from "types";

//uncomment if you need msal auth
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "generalLedger";
const baseURL = `${process.env.REACT_APP_API_BASE}/generalledger`;

type State = {
  saveGeneralLedger: ReducerState<SaveGeneralLedgerReturn>;
  getGeneralLedger: ReducerState<GeneralLedger>;
 
};

type SaveGeneralLedgerReturn = {
  success: boolean;
  message: string;
};

let saveGeneralLedger = createAsyncAction<
SaveGeneralLedgerReturn, //return type
{ generalLedger: GeneralLedger, lessonId: number, onComplete?(retValue: SaveGeneralLedgerReturn): void  }, //params
  
  State
>({
  actionPrefix: controlName,
  actionName: "saveGeneralLedger",
  url: baseURL + "/saveGeneralLedger",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.saveGeneralLedger = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveGeneralLedger.isLoading = false;
    state.saveGeneralLedger.data = action.payload;
    
  },
  rejected: (state, action) => {
    state.saveGeneralLedger.isLoading = false;
    state.saveGeneralLedger.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as SaveGeneralLedgerReturn))
},
});


let slice = createSlice({
  name: controlName,
  initialState: {
      saveGeneralLedger: {isLoading:false},
      getGeneralLedger: { isLoading: false },
  } as State,
  reducers: {},
  extraReducers:{
    ...saveGeneralLedger.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
        ...slice.actions,
        saveGeneralLedger: saveGeneralLedger.action,
    },
  },
};

export default ret;