import { Divider, List, ListItem, ListItemText } from "@mui/material";
import { OutstandingOverridesTransaction } from "types";
import styled from "styled-components";
import _orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import { highlightBackground, unhighlightBackground } from "common/functions";

export type Props = {
  overrideTransactionlist: OutstandingOverridesTransaction[];
  onTransactionSelect(
    outstandingOverrideTransaction: OutstandingOverridesTransaction
  ): void;
};

export default function OutstandingOverridesTransactionList(props: Props) {
  useEffect(() => {
    setSelectedTransaction(
      _orderBy(props.overrideTransactionlist, (l) => l.fullName)[0]
    );
  }, [props.overrideTransactionlist]);

  let sortedTransactionList = _orderBy(
    props.overrideTransactionlist,
    (l) => l.fullName
  );
  const [
    selectedTransaction,
    setSelectedTransaction,
  ] = useState<OutstandingOverridesTransaction>();

  return (
    <div style={{ marginTop: "1em" }}>
      {/* <div style={{ minHeight: 400 }}> */}
      {(!sortedTransactionList || sortedTransactionList.length === 0) && (
        <NoOverrides>
          <div>No transactions with outstanding overrides.</div>
        </NoOverrides>
      )}
      {sortedTransactionList && sortedTransactionList.length > 0 && (
        <StyledList>
          {sortedTransactionList.map((l, index) => {
            return (
              <div
                key={index}
                id={"Outstanding_Override_Txn_div" + l.transactionId}
              >
                <ListItem
                  key={index}
                  onClick={() => {
                    setSelectedTransaction(l);
                    props.onTransactionSelect(l);
                  }}
                  sx={{
                    backgroundColor:
                      l.transactionId === selectedTransaction?.transactionId
                        ? "rgba(25, 118, 210, 0.40)"
                        : "",
                  }}
                  onMouseOver={() => {
                    highlightBackground(
                      "Outstanding_Override_Txn_div" + l.transactionId
                    );
                  }}
                  onMouseOut={() => {
                    unhighlightBackground(
                      "Outstanding_Override_Txn_div" + l.transactionId
                    );
                  }}
                >
                  <OverrideTransaction overrideTxnListItem={l} key={index} />
                </ListItem>
                <Divider component="li" />
              </div>
            );
          })}
        </StyledList>
      )}
    </div>
  );
}

function getTransactionTypeLabel(transactionType: string) {
  let txntypeLabel =
    transactionType === "GL_ENTRY"
      ? "GL Entry"
      : transactionType === "SELL_DRAFT"
      ? "Sell Draft"
      : transactionType === "END_OF_DAY"
      ? "End of Day"
      : "Unknown";
  return txntypeLabel;
}

function OverrideTransaction(props: {
  overrideTxnListItem: OutstandingOverridesTransaction;
}) {
  return (
    <ListItemText>
      <Row label="Student Id" value={props.overrideTxnListItem.studentId} />
      <Row label="Student Name" value={props.overrideTxnListItem.fullName} />
      <Row label="Client Name" value={props.overrideTxnListItem.clientName} />
      <Row label="Account Type" value={props.overrideTxnListItem.accountType} />
      <Row
        label="Transaction"
        value={getTransactionTypeLabel(
          props.overrideTxnListItem.transactionType
        )}
      />
    </ListItemText>
  );
}

function Row(props: { label: string; value: string }) {
  return (
    <RowContainer>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </RowContainer>
  );
}

const Label = styled.label`
  color: #0000008a;
  width: 120px;
  padding: 0 0.5em;
  font-size: 16px;
`;
const Value = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  width: 130px;
  padding: 0 0.75em;
`;
const RowContainer = styled.div`
  display: flex;
`;

const NoOverrides = styled.div`
  font-size: 16px !important;
  margin: 4em 2em;
`;

const StyledList = styled(List)`
  max-height: 800px;
  overflow: auto;
  background-color: white;
`;
