import { Grid } from "@mui/material";
import styled from "styled-components";
import { PersonalClient, SigningAuthority } from "types";
import { openConfirm } from "components/confirmBox";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/businessClient/businessClientInformation/components/signingAuthorityFieldDefinitions";
import SigningAuthorityActionsButton from "pages/clientPortfolio/businessClient/businessClientInformation/components/SigningAuthorityActionsButton";
import {
  getPersonalClientName,
  getSigningAuthorityBusinessAffiliationLabel,
} from "common/functions";
import { useReduxState } from "store";
import { getBusinessClientById } from "pages/lessons/helpers";
export type Props = {
  businessClientId: string;
  signingAuthority: SigningAuthority;
  personalClient: PersonalClient;
  onEditClick(): void;
  onDeleteClick(): void;
  onPortfolioClick(): void;
  isReadOnlyView: boolean;
  isNewClient: boolean;
};

export default function SigningAuthorityDisplayTemplate(props: Props) {
  const { signingAuthority, personalClient } = props;
  const { getLessonNameAndData, getDataforViewAsStudent } = useReduxState(
    (e) => e.lesson
  );
  let deleteDescription = props.signingAuthority.percentOfOwnership
    ? `${getPersonalClientName(
        props.personalClient
      )} - ${getSigningAuthorityBusinessAffiliationLabel(
        props.signingAuthority.businessAffiliation ?? ""
      )} - ${props.signingAuthority.percentOfOwnership}%`
    : `${getPersonalClientName(
        props.personalClient
      )} - ${getSigningAuthorityBusinessAffiliationLabel(
        props.signingAuthority.businessAffiliation ?? ""
      )}`;

  let reduxBusinessCLient =
    getBusinessClientById(
      props.businessClientId,
      props.isReadOnlyView
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    ) ?? {};
  let reduxSigningAuth = reduxBusinessCLient.signingAuthorities?.find(
    (sAuth) =>
      sAuth.signingAuthorityId === props.signingAuthority.signingAuthorityId
  );
  let isAttachedClientinEditMode: boolean =
    props.personalClient.identification?.clientId !==
    reduxSigningAuth?.personalClientId;

  return (
    <SigningAuthorityContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldDisplay<SigningAuthority>
            field={fields.businessAffiliation}
            state={signingAuthority}
          />
          <FieldDisplay<SigningAuthority>
            field={fields.percentOfOwnership}
            state={signingAuthority}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<PersonalClient>
            field={fields.name}
            state={personalClient}
          />
          <TwoFieldsRow>
            <Column1MarginRight>
              <FieldDisplay<PersonalClient>
                field={fields.phoneNumber}
                state={personalClient}
              />
            </Column1MarginRight>
            <Column2>
              <FieldDisplay<PersonalClient>
                field={fields.phoneNumberType}
                state={personalClient}
              />
            </Column2>
          </TwoFieldsRow>
        </Grid>
      </Grid>
      <ButtonDiv>
        <SigningAuthorityActionsContainer>
          <SigningAuthorityActionsButton
            onEdit={props.onEditClick}
            onDelete={() => {
              openConfirm({
                title: "Delete Signing Authority?",
                description: deleteDescription,
                okBtnText: "Delete",
                callback: () => {
                  props.onDeleteClick();
                },
              });
            }}
            portfolioValid={
              !props.isNewClient &&
              !!fields.name.getValue(props.personalClient) &&
              !isAttachedClientinEditMode
            }
            onPortfolioClick={props.onPortfolioClick}
            isReadOnlyView={props.isReadOnlyView}
          />
        </SigningAuthorityActionsContainer>
      </ButtonDiv>
    </SigningAuthorityContainer>
  );
}

const SigningAuthorityContainer = styled.div`
  min-width: 580px;
`;

const TwoFieldsRow = styled.div`
  display: flex;
`;
const Column1MarginRight = styled.div`
  flex: 3;
  margin-right: -7em;
`;
const Column2 = styled.div`
  flex: 2;
`;

const ButtonDiv = styled.div`
  display: flex;
  padding-right: 32px;
  margin-top: 1em;
`;

const SigningAuthorityActionsContainer = styled.div`
  text-align: right;
  flex: 1;
`;
