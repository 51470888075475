import { Button, Divider, Grid } from "@mui/material";
import styled from "styled-components";
import MaskedValue from "common/MaskedValue";
import * as fields from "pages/transactions/recieveFieldDefinitions";
import { FieldError, MASK_TYPE, Receive } from "types";
import FieldEditor from "components/FieldEditor";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CurrencyToggleMenu from "pages/transactions/components/CurrencyToggleMenu";
import { useReduxState } from "store";
import { add } from "common/functions";

type CommonProps = {
  state: Receive;
  setState(state: Receive): void;
  errors: FieldError[];
};

export default function ReceiveChequeSection(commonProps: CommonProps) {
  const chequesArrayField = fields.chequesArray;

  const nums = chequesArrayField.getValue(commonProps.state);

  const holdAmount: number =
    ((fields.chequeHoldAmount.getValue(
      commonProps.state
    ) as unknown) as number) || 0;

  function addCheque() {
    commonProps.setState(
      chequesArrayField.setValue([...nums, null], commonProps.state)
    );
  }

  function removeCheque() {
    commonProps.setState(
      chequesArrayField.setValue(
        [...nums.slice(0, nums.length - 1)],
        commonProps.state
      )
    );
  }

  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};

  let totalChequeAmount =
    commonProps.state.cheque?.chequesArray?.reduce(add, 0) ?? 0;

  let isHoldRequired =
    (totalChequeAmount ?? 0) >=
    (globalSettings.chequeWarningMessageDenomination ?? 0);

  return (
    <>
      <FlexDiv>
        <SectionHeading>Cheque</SectionHeading>
        <CurrencyToggleMenu
          onToggle={(selectedButton) => {
            commonProps.setState({
              ...commonProps.state,
              cheque: {
                ...commonProps.state.cheque,
                chequeCurrencyType: selectedButton === 0 ? "cad" : "usd",
              },
            });
          }}
        />
      </FlexDiv>

      <Button onClick={() => addCheque()}>
        <AddIcon /> Add Cheque
      </Button>

      <Button disabled={nums.length < 1} onClick={() => removeCheque()}>
        <RemoveIcon />
        Remove Cheque
      </Button>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldEditor<Receive> field={fields.chequesArray} {...commonProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldEditor<Receive>
            field={fields.chequeHoldAmount}
            isDependentlyRequired={isHoldRequired}
            {...commonProps}
          />
          {holdAmount > 0 && (
            <FieldEditor<Receive>
              field={fields.chequeHoldDuration}
              {...commonProps}
            />
          )}
          {!!holdAmount && holdAmount >= 0 && (
            <FieldEditor<Receive>
              field={fields.chequeHoldReason}
              {...commonProps}
            />
          )}
        </Grid>
      </Grid>
      <Divider />
      <DisplayTotal transaction={commonProps.state} />
    </>
  );
}

function DisplayTotal(props: { transaction: Receive }) {
  const totalChequeAmount: number = (fields.totalChequeAmount.getValue(
    props.transaction
  ) ?? (0 as unknown)) as number;

  const cadValue = fields.chequeCADValue.getValue(props.transaction) ?? 0;

  return (
    <TotalSection>
      <LeftSection>
        <LightBold>Cheque Total</LightBold>
        {MaskedValue(MASK_TYPE.MONEY, totalChequeAmount.toString() ?? "0")}
        <Spacer />
        {props.transaction.cheque?.chequeCurrencyType?.toUpperCase()}
      </LeftSection>
      {props.transaction.cheque?.chequeCurrencyType === "usd" && (
        <RightSection>
          ( <LightBold>CAD Value </LightBold>{" "}
          {MaskedValue(MASK_TYPE.MONEY, cadValue.toString())}
          <Spacer />
          CAD)
        </RightSection>
      )}
    </TotalSection>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const LightBold = styled.div`
  font-weight: 500;
  margin-right: 10px;
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
`;
const RightSection = styled.div`
  text-align: right;
  display: flex;
`;
const Spacer = styled.div`
  margin-right: 5px;
`;

const TotalSection = styled.div`
  margin: 1em;
  display: flex;
  align-items: center;
`;
const SectionHeading = styled.div`
  font-weight: 500;
  font-size: 18px;
  flex: 1;
  align-self: center;
`;
