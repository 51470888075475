import FieldEditor from "components/FieldEditor";
import { Account, Deposit, FieldError, IdName } from "types";
import * as fields from "pages/transactions/depositFieldDefinitions";
import { useEffect, useState } from "react";
import { requiredDateField, requiredTextField } from "common/functions";
import TransactionEditorTemplate from "pages/accountHistory/components/TransactionEditorTemplate";
import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";

export type Props = {
  transaction: Deposit;
  account: Account | undefined;
  onCancel(): void;
  onSave(transaction: Deposit, recurringMonths?: number): void;
};

export default function DepositForm({
  transaction,
  account,
  onCancel,
  onSave,
}: Props) {
  const [state, setState] = useState<Deposit>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [
    selectedRecuringMonths,
    setSelectedRecuringMonths,
  ] = useState<string>();
  const recurringOptions: IdName[] = [
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "6", name: "6" },
    { id: "7", name: "7" },
    { id: "8", name: "8" },
    { id: "9", name: "9" },
    { id: "10", name: "10" },
    { id: "11", name: "11" },
    { id: "12", name: "12" },
    { id: "24", name: "24" },
    { id: "36", name: "36" },
  ];

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: Deposit) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!transaction) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredDateField(fields.depositTransactionDateTime, state),
      ...requiredTextField(fields.depositAmount, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  return (
    <TransactionEditorTemplate
      id={`Deposit_${transaction.transactionId}_form`}
      onSaveClick={() => {
        const err = checkErrors();
        if (err?.length === 0) {
          onSave(
            state,
            selectedRecuringMonths
              ? parseInt(selectedRecuringMonths)
              : undefined
          );
        }
      }}
      onCancelClick={onCancel}
      type={
        transaction.transactionType === "TRANSFER"
          ? "Transfer"
          : transaction.transactionType === "GL_ENTRY"
          ? "GL Entry"
          : "Deposit"
      }
      column1Fields={
        <>
          <FieldEditor<Deposit>
            field={fields.depositTransactionDateTime}
            {...commonProps}
          />
          <FieldEditor<Deposit>
            field={fields.depositDescription}
            {...commonProps}
          />
        </>
      }
      column2Fields={
        <>
          <FieldEditor<Deposit> field={fields.depositAmount} {...commonProps} />
          {!transaction.transactionDateTime && (
            <PickListNonFieldEditor
              id="depositReccuring"
              label="Recurring For Months"
              value={selectedRecuringMonths || ""}
              options={recurringOptions}
              onChange={(value) => {
                setSelectedRecuringMonths(value);
              }}
            />
          )}
        </>
      }
    />
  );
}
