import { IconButton, TableCell, TableRow } from "@mui/material";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { AccountType } from "types";
import { getLoginInfo } from "common/azureAD";
type AccountHistoryRowRTemplateProps = {
  date?: string;
  transactionType?: string;
  transactionDescription?: string;
  debit?: JSX.Element | string;
  credit?: JSX.Element | string;
  balance?: JSX.Element | string;
  onEditClick?(): void;
  onDeleteClick?(): void;
  onDuplicateClick(): void;
  isSelectedAccountClosed: boolean;
  accountType: AccountType | undefined;
  isReadOnlyView: boolean;
};

export default function AccountHistoryRowTemplate(
  props: AccountHistoryRowRTemplateProps
) {
  let isSelectedAccountGICOrMutualFund: boolean =
    props.accountType && ["GIC", "MUTUAL_FUNDS"].includes(props.accountType)
      ? true
      : false;
  const loginInfo = getLoginInfo();
  return (
    <TableRow>
      <Cell>{props.date}</Cell>
      <Cell>{props.transactionType}</Cell>
      <Cell>{props.transactionDescription}</Cell>
      <Cell>{props.debit}</Cell>
      <Cell>{props.credit}</Cell>
      <Cell>{props.balance}</Cell>
      <Cell>
        {!props.isSelectedAccountClosed &&
          !isSelectedAccountGICOrMutualFund &&
          loginInfo?.isInstructor && (
            <>
              <IconButton
                style={{ margin: "-1em 0" }}
                aria-label="edit"
                onClick={props.onEditClick}
                disabled={props.isReadOnlyView}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                style={{ margin: "-1em 0" }}
                aria-label="delete"
                onClick={props.onDeleteClick}
                disabled={props.isReadOnlyView}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                style={{ margin: "-1em 0" }}
                aria-label="duplicate"
                onClick={props.onDuplicateClick}
                disabled={props.isReadOnlyView}
              >
                <FileCopyIcon />
              </IconButton>
            </>
          )}
      </Cell>
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  text-align: center !important;
`;
