import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import FieldEditor from "components/FieldEditor";
import { Employer, FieldError } from "types";
import * as fields from "pages/createEmployer/employerFieldDefinitions";
import { useEffect, useState } from "react";
import { addressErrors, requiredTextField } from "common/functions";

type EmployerEditorProps = {
  employer: Employer;
  saveClick(employer: Employer): void;
  cancelClick(): void;
};

export default function EmployerEditor(props: EmployerEditorProps) {
  const [state, setState] = useState<Employer>(props.employer);
  const [errors, setErrors] = useState<FieldError[]>([]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: Employer) => {
      setState(state);
    },
  };

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.employerId, state),
      ...requiredTextField(fields.employerName, state),
      ...requiredTextField(fields.phoneNumber, state),
      ...addressErrors(fields.employerAddress, state),
    ];
    //following code checks for invalid postal code. Adds error if length is less than 7
    if (
      state.employerAddress?.postalCode &&
      state.employerAddress.postalCode?.length < 7
    ) {
      let invalidPostalCodeErr: FieldError = {
        id: `${fields.employerAddress.id}_postalCode`,
        label: `${fields.employerAddress.label}`,
        error: "Invalid Postal Code.",
      };
      errorsArray.push(invalidPostalCodeErr);
    }
    //following code checks for invalid phone number. Adds error if length is less than 10
    if (state.phoneNumber && state.phoneNumber.length < 10) {
      let invalidPhoneNumberErr: FieldError = {
        id: `${fields.phoneNumber.id}`,
        label: `${fields.phoneNumber.label}`,
        error: "Invalid Phone Number.",
      };
      errorsArray.push(invalidPhoneNumberErr);
    }
    setErrors(errorsArray);
    return errorsArray;
  }

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogTitle>
        {/* <Typography variant={"h6"}> */}
        <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
          {props.employer.employerName === undefined
            ? "New Employer"
            : "Edit Employer"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FieldEditor<Employer> field={fields.employerId} {...commonProps} />
            <FieldEditor<Employer>
              field={fields.employerName}
              {...commonProps}
            />
            <FieldEditor<Employer>
              field={fields.phoneNumber}
              {...commonProps}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldEditor<Employer>
              field={fields.employerAddress}
              {...commonProps}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancelClick}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            const errArray = checkErrors();
            if (errArray.length === 0) {
              props.saveClick(state);
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
