import { v4 } from "uuid";
import {  
  FIELD_TYPE,  
  MASK_TYPE,
  PickListField,
  TextFieldType,
  DateField,
  Withdrawal,
} from "types";

export const withdrawalTransactionDateTime: DateField<Withdrawal> = {
  id: `withdrawalTransactionDateTime${v4()}`,
  label: "Date",
  type: FIELD_TYPE.DATE,
  getValue: (withdrawal: Withdrawal) => withdrawal.transactionDateTime,
  setValue: (value, withdrawal) => ({
    ...withdrawal,
    transactionDateTime: value,
  }),
 isRequired: true
};


export const withdrawalFrom: PickListField<Withdrawal> = {
  id: "withdrawalFrom",
  label: "From",
  isRequired: true,
  type: FIELD_TYPE.ACCOUNTSPICKLIST,
  getValue: (withdrawal: Withdrawal) =>  withdrawal.from,
  setValue: (value, withdrawal) => ({...withdrawal,from: value }),
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};


export const withdrawalTo: PickListField<Withdrawal> = {
  id: "withdrawalTo",
  label: "To",
  defaultValue: "sessionbalance",
  type: FIELD_TYPE.PICKLIST,
  getValue: (withdrawal: Withdrawal) =>  withdrawal.to,
  setValue: (value, withdrawal) => ({...withdrawal,to: value }),
  options: [
    { id: "sessionbalance", name: "Session Balance" },
  ],
  disabled: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const withdrawalDescription: TextFieldType<Withdrawal> = {
  id: "withdrawalDescription",
  label: "Description",
  type: FIELD_TYPE.TEXT,
  getValue: (withdrawal: Withdrawal) => withdrawal.transactionDescription,
  setValue: (value, withdrawal) => ({
    ...withdrawal,
    transactionDescription: value,
  }),
  maxLength: 50
};

export const withdrawalAmount: TextFieldType<Withdrawal> = {
  id: "withdrawalAmount",
  label: "Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (withdrawal: Withdrawal) => withdrawal.amount ? withdrawal.amount.toString() : "",
  setValue: (value, withdrawal) => ({
    ...withdrawal,
    amount: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
};