import FieldEditor from "components/FieldEditor";
import { CreditAppLOC, FieldError } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppLOCFieldDefinitions";
import { useEffect, useState } from "react";
import {
  creditAppPropertyAddressErrors,
  requiredDateField,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import { Divider, Grid, Typography } from "@mui/material";
import AccountEditTemplate from "./AccountEditTemplate";
import WarningControl from "components/warningControl";
export type Props = {
  account: CreditAppLOC;
  isAnotherAccountExists: boolean;
  onCancel(): void;
  onAddUpdate(account: CreditAppLOC): void;
};

const CreditAppLOCForm = ({
  account,
  isAnotherAccountExists,
  onCancel,
  onAddUpdate,
}: Props) => {
  const [state, setState] = useState<CreditAppLOC>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  const [openWarningControl, setOpenWarningControl] = useState(false);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: CreditAppLOC) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.locRequestedAmount, state),
      ...requiredTextField(fields.locMinimumPayment, state),
      ...requiredTextField(fields.locInterestRate, state),
      ...requiredPickListField(fields.locType, state),
      ...requiredTextField(fields.locSecurity, state),
      ...requiredTextField(fields.locSchool, state),
      ...requiredTextField(fields.locProgram, state),
      ...requiredTextField(fields.locStudentId, state),
      ...requiredDateField(fields.locExpectedGradDate, state),
      ...requiredTextField(fields.locAppraisedValue, state),
      ...requiredTextField(fields.locExistingBalance, state),
      ...requiredTextField(fields.locEquityAmount, state),
      ...requiredTextField(fields.locPaymentAmount, state),
      ...creditAppPropertyAddressErrors(fields.locPropertyAddress, state),
      ...requiredTextField(fields.locMarketValue, state),
      ...requiredTextField(fields.locPropertyTax, state),
      ...requiredTextField(fields.locMuncipality, state),
      ...requiredTextField(fields.locLegalDescription, state),
      ...requiredPickListField(fields.locPropertyType, state),
      ...requiredPickListField(fields.locBuildingType, state),
      ...requiredPickListField(fields.locPropertyStyle, state),
      ...requiredPickListField(fields.locNoOfBedrooms, state),
      ...requiredPickListField(fields.locNoOfBathrooms, state),
      ...requiredTextField(fields.locPropertyAge, state),
      ...requiredPickListField(fields.locParkingType, state),
      ...requiredTextField(fields.locSquareFootage, state),
      ...requiredTextField(fields.locLotSize, state),
      ...requiredTextField(fields.locJointAppName, state),
      ...requiredTextField(fields.locJointAppEmployerName, state),
      ...requiredTextField(fields.locJointAppJobTitle, state),
      ...requiredDateField(fields.locJointAppEmploymentStartDate, state),
      ...requiredTextField(fields.locJointAppMonthlyIncome, state),
    ];

    //Invalid postal code check: following code checks for invalid postal code. Adds error if length is less than 7
    if (
      state.type === "heloc" &&
      state.propertyAddress?.postalCode &&
      state.propertyAddress?.postalCode?.length < 7
    ) {
      let invalidPostalCodeErr: FieldError = {
        id: `${fields.locPropertyAddress.id}_postalCode`,
        label: "Postal Code",
        error: "Invalid Postal Code.",
      };
      errorsArray.push(invalidPostalCodeErr);
    }

    //Invalid expected graduation date check: following code checks for invalid expected grad date. Adds error if date is in past

    if (
      state.type === "studentloc" &&
      state.expectedGraduationDate &&
      state.expectedGraduationDate.toLowerCase() !== "invalid date" &&
      new Date(state.expectedGraduationDate).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0)
    ) {
      let invalidDateErr: FieldError = {
        id: `${fields.locExpectedGradDate.id}`,
        label: "Expected Graduation Date",
        error: "Invalid Date. Expected graduation date cannot be in past.",
      };
      errorsArray.push(invalidDateErr);
    }

    setErrors(errorsArray);
    return errorsArray;
  }

  function onAddUpdateClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (isAnotherAccountExists) {
        setOpenWarningControl(true);
      } else {
        onAddUpdate({
          ...state,
          propertyAddress: {
            ...state.propertyAddress,
            country: "Canada",
          },
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`CREDIT_APP_LOC_${account.accountId}_form`}
      isNew={account.requestedAmount ? false : true}
      isAnotherAccountExists={isAnotherAccountExists}
      type="Line Of Credit"
      requestedAmount={state.requestedAmount ?? 0}
      onAddUpdateClick={onAddUpdateClick}
      onCancelClick={onCancel}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppLOC>
            field={fields.locRequestedAmount}
            {...commonProps}
          />
          <FieldEditor<CreditAppLOC>
            field={fields.locMinimumPayment}
            {...commonProps}
          />

          {state.type === "heloc" && (
            <FieldEditor<CreditAppLOC>
              field={fields.locInterestType}
              {...commonProps}
            />
          )}
          <FieldEditor<CreditAppLOC>
            field={fields.locPrimeRate}
            {...commonProps}
          />

          <FieldEditor<CreditAppLOC>
            field={fields.locInterestRate}
            {...commonProps}
          />

          <FieldEditor<CreditAppLOC>
            field={fields.locTotalInterestRate}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppLOC> field={fields.locType} {...commonProps} />
          {state.type === "sloc" && (
            <FieldEditor<CreditAppLOC>
              field={fields.locSecurity}
              {...commonProps}
            />
          )}
          {state.type === "studentloc" && (
            <>
              <FieldEditor<CreditAppLOC>
                field={fields.locSchool}
                {...commonProps}
              />

              <FieldEditor<CreditAppLOC>
                field={fields.locProgram}
                {...commonProps}
              />

              <FieldEditor<CreditAppLOC>
                field={fields.locStudentId}
                {...commonProps}
              />

              <FieldEditor<CreditAppLOC>
                field={fields.locExpectedGradDate}
                {...commonProps}
              />
            </>
          )}

          <FieldEditor<CreditAppLOC>
            field={fields.locHasJointApplication}
            {...commonProps}
          />
        </Grid>
      </Grid>
      {state.type === "heloc" && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "1em" }}>
            HELOC Calculations - Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppLOC>
                field={fields.locAppraisedValue}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locExistingBalance}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppLOC>
                field={fields.locEquityAmount}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locPaymentAmount}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
      {state.type === "heloc" && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "1em" }}>
            HELOC Calculations - Property Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppLOC>
                field={fields.locPropertyAddress}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locMarketValue}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locPropertyTax}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locIncludedInMortgage}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locMuncipality}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locLegalDescription}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppLOC>
                field={fields.locPropertyType}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locBuildingType}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locPropertyStyle}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locNoOfBedrooms}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locNoOfBathrooms}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locPropertyAge}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locParkingType}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locSquareFootage}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locLotSize}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
      {state.hasJointApplication && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "1em" }}>
            Joint Application Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppLOC>
                field={fields.locJointAppName}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locJointAppEmployerName}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locJointAppJobTitle}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppLOC>
                field={fields.locJointAppEmploymentStartDate}
                {...commonProps}
              />
              <FieldEditor<CreditAppLOC>
                field={fields.locJointAppMonthlyIncome}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
      {openWarningControl && (
        <WarningControl
          onCancel={() => {
            setOpenWarningControl(false);
            onCancel();
          }}
          onProceed={() => {
            onAddUpdate({
              ...state,
            });
          }}
          warningMessage={"This action will override the existing account"}
        />
      )}
    </AccountEditTemplate>
  );
};

export default CreditAppLOCForm;
