import FieldEditor from "components/FieldEditor";
import { CreditAppMortgage, FieldError } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppMortgageFieldDefinitions";
import { useEffect, useState } from "react";
import {
  creditAppPropertyAddressErrors,
  requiredDateField,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import { Divider, Grid, Typography } from "@mui/material";
import AccountEditTemplate from "./AccountEditTemplate";
import WarningControl from "components/warningControl";
export type Props = {
  account: CreditAppMortgage;
  isAnotherAccountExists: boolean;
  onCancel(): void;
  onAddUpdate(account: CreditAppMortgage): void;
};

const CreditAppMortgageForm = ({
  account,
  isAnotherAccountExists,
  onCancel,
  onAddUpdate,
}: Props) => {
  const [state, setState] = useState<CreditAppMortgage>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  const [openWarningControl, setOpenWarningControl] = useState(false);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: CreditAppMortgage) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.mortgageAppraisedValue, state),
      ...requiredTextField(fields.mortgageRequestedAmount, state),
      ...requiredTextField(fields.mortgageExistingBalance, state),
      ...requiredTextField(fields.mortgageEquityAmount, state),
      ...requiredTextField(fields.mortgageDownPayment, state),
      ...requiredPickListField(fields.mortgageTermLength, state),
      ...requiredDateField(fields.mortgagePurchaseDate, state),
      ...requiredTextField(fields.mortgagePaymentAmount, state),
      ...requiredPickListField(fields.mortgageAppType, state),
      ...requiredTextField(fields.mortgageSourceOfDownPayment, state),
      ...requiredTextField(fields.mortgageType, state),
      ...requiredTextField(fields.mortgageInterestRate, state),
      ...requiredPickListField(fields.mortgageAmortization, state),
      ...requiredTextField(fields.mortgageCMHCFee, state),
      ...creditAppPropertyAddressErrors(fields.mortgagePropertyAddress, state),
      ...requiredTextField(fields.mortgagePurchasePrice, state),
      ...requiredTextField(fields.mortgageMarketValue, state),
      ...requiredTextField(fields.mortgagePropertyTax, state),
      ...requiredTextField(fields.mortgageMuncipality, state),
      ...requiredTextField(fields.mortgageLegalDescription, state),
      ...requiredPickListField(fields.mortgagePropertyType, state),
      ...requiredPickListField(fields.mortgageBuildingType, state),
      ...requiredPickListField(fields.mortgagePropertyStyle, state),
      ...requiredPickListField(fields.mortgageNoOfBedrooms, state),
      ...requiredPickListField(fields.mortgageNoOfBathrooms, state),
      ...requiredTextField(fields.mortgagePropertyAge, state),
      ...requiredPickListField(fields.mortgageParkingType, state),
      ...requiredTextField(fields.mortgageSquareFootage, state),
      ...requiredTextField(fields.mortgageLotSize, state),
      ...requiredTextField(fields.mortgageJointAppName, state),
      ...requiredTextField(fields.mortgageJointAppEmployerName, state),
      ...requiredTextField(fields.mortgageJointAppJobTitle, state),
      ...requiredDateField(fields.mortgageJointAppEmploymentStartDate, state),
      ...requiredTextField(fields.mortgageJointAppMonthlyIncome, state),
    ];

    //Invalid postal code check: following code checks for invalid postal code. Adds error if length is less than 7
    if (
      state.propertyAddress?.postalCode &&
      state.propertyAddress?.postalCode?.length < 7
    ) {
      let invalidPostalCodeErr: FieldError = {
        id: `${fields.mortgagePropertyAddress.id}_postalCode`,
        label: "Postal Code",
        error: "Invalid Postal Code.",
      };
      errorsArray.push(invalidPostalCodeErr);
    }

    setErrors(errorsArray);
    return errorsArray;
  }

  function onAddUpdateClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (isAnotherAccountExists) {
        setOpenWarningControl(true);
      } else {
        onAddUpdate({
          ...state,
          propertyAddress: {
            ...state.propertyAddress,
            country: "Canada",
          },
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`CREDIT_APP_MORTGAGE_${account.accountId}_form`}
      isNew={account.requestedAmount ? false : true}
      isAnotherAccountExists={isAnotherAccountExists}
      type="Mortgage"
      requestedAmount={state.requestedAmount ?? 0}
      onAddUpdateClick={onAddUpdateClick}
      onCancelClick={onCancel}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageAppraisedValue}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageRequestedAmount}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageAppType}
            {...commonProps}
          />

          {state.type === "purchasing" && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgageDownPayment}
              {...commonProps}
            />
          )}
          {state.type === "refinance" && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgageExistingBalance}
              {...commonProps}
            />
          )}
          {state.type === "refinance" && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgageEquityAmount}
              {...commonProps}
            />
          )}
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageTermLength}
            {...commonProps}
          />
          {state.type === "purchasing" && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgagePurchaseDate}
              {...commonProps}
            />
          )}

          <FieldEditor<CreditAppMortgage>
            field={fields.mortgagePaymentAmount}
            {...commonProps}
          />
          {state.type === "purchasing" && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgageSourceOfDownPayment}
              {...commonProps}
            />
          )}
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageAmortization}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageType}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageInterestType}
            {...commonProps}
          />

          {state.interestType === "Variable" && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgagePrimeRate}
              {...commonProps}
            />
          )}

          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageInterestRate}
            {...commonProps}
          />

          {state.interestType === "Variable" && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgageTotalInterestRate}
              {...commonProps}
            />
          )}

          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageCMHC}
            {...commonProps}
          />
          {state.cmhc && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgageCMHCFee}
              {...commonProps}
            />
          )}

          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageHasJointApplication}
            {...commonProps}
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
      <Typography style={{ marginBottom: "1em" }}>
        Property Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgagePropertyAddress}
            {...commonProps}
          />
          {state.type === "purchasing" && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgagePurchasePrice}
              {...commonProps}
            />
          )}
          {state.type === "refinance" && (
            <FieldEditor<CreditAppMortgage>
              field={fields.mortgageMarketValue}
              {...commonProps}
            />
          )}
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgagePropertyTax}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageIncludedInMortgage}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageMuncipality}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageLegalDescription}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgagePropertyType}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageBuildingType}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgagePropertyStyle}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageNoOfBedrooms}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageNoOfBathrooms}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgagePropertyAge}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageParkingType}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageSquareFootage}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageLotSize}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageTitle}
            {...commonProps}
          />
          <FieldEditor<CreditAppMortgage>
            field={fields.mortgageRestricted}
            {...commonProps}
          />
        </Grid>
      </Grid>

      {state.hasJointApplication && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "1em" }}>
            Joint Application Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppMortgage>
                field={fields.mortgageJointAppName}
                {...commonProps}
              />
              <FieldEditor<CreditAppMortgage>
                field={fields.mortgageJointAppEmployerName}
                {...commonProps}
              />
              <FieldEditor<CreditAppMortgage>
                field={fields.mortgageJointAppJobTitle}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppMortgage>
                field={fields.mortgageJointAppEmploymentStartDate}
                {...commonProps}
              />
              <FieldEditor<CreditAppMortgage>
                field={fields.mortgageJointAppMonthlyIncome}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
      {openWarningControl && (
        <WarningControl
          onCancel={() => {
            setOpenWarningControl(false);
            onCancel();
          }}
          onProceed={() => {
            onAddUpdate({
              ...state,
            });
          }}
          warningMessage={"This action will override the existing account"}
        />
      )}
    </AccountEditTemplate>
  );
};

export default CreditAppMortgageForm;
