import { Dialog, DialogContent } from "@mui/material";
import {
  CreditAppAccount,
  CreditAppCreditCard,
  CreditAppLoan,
  CreditAppLOC,
  CreditAppMortgage,
} from "types";
import CreditAppCreditCardForm from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppCreditCardForm";
import CreditAppLoanForm from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppLoanForm";
import CreditAppLOCForm from "./CreditAppLOCForm";
import CreditAppMortgageForm from "./CreditAppMortgageForm";

export type Props = {
  account?: CreditAppAccount;
  isAnotherAccountExists: boolean;
  onSave(account: CreditAppAccount): void;
  onCancel(): void;
};

export default function EditCreditAppAccountDialog({
  account,
  isAnotherAccountExists,
  onSave,
  onCancel,
}: Props) {
  if (!account) {
    return <></>;
  }

  if (account.accountType === "CREDIT_CARD") {
    let creditCardAcc = account as CreditAppCreditCard;
    return (
      <CreditAppCreditCardForm
        account={creditCardAcc}
        isAnotherAccountExists={isAnotherAccountExists}
        onCancel={onCancel}
        onAddUpdate={onSave}
      />
    );
  }

  if (account.accountType === "LOAN") {
    let loanAcc = account as CreditAppLoan;
    return (
      <CreditAppLoanForm
        account={loanAcc}
        isAnotherAccountExists={isAnotherAccountExists}
        onCancel={onCancel}
        onAddUpdate={onSave}
      />
    );
  }

  if (account.accountType === "LINE_OF_CREDIT") {
    let locAcc = account as CreditAppLOC;
    return (
      <CreditAppLOCForm
        account={locAcc}
        isAnotherAccountExists={isAnotherAccountExists}
        onCancel={onCancel}
        onAddUpdate={onSave}
      />
    );
  }

  if (account.accountType === "MORTGAGE") {
    let mortgageAcc = account as CreditAppMortgage;
    return (
      <CreditAppMortgageForm
        account={mortgageAcc}
        isAnotherAccountExists={isAnotherAccountExists}
        onCancel={onCancel}
        onAddUpdate={onSave}
      />
    );
  }

  return (
    <Dialog open onClose={() => {}}>
      <DialogContent>Unknown Account Type</DialogContent>
    </Dialog>
  );
}
