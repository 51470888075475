import {
  getPersonalCreditCardTypeLabel,
  getPersonalLOCTypeLabel,
} from "common/functions";
import MaskedValue from "common/MaskedValue";
import React from "react";
import styled from "styled-components";
import {
  Account,
  CreditCard,
  LineOfCredit,
  Loan,
  MASK_TYPE,
  Mortgage,
} from "types";

export type Props = {
  accounts: Account[];
};

export default function LiabilitiesDisplay(props: Props) {
  const loanAccounts: Loan[] = props.accounts.filter(
    (a) => a.accountType === "LOAN"
  );

  const creditCardAccounts: CreditCard[] = props.accounts.filter(
    (a) => a.accountType === "CREDIT_CARD"
  );

  const mortgageAccounts: Mortgage[] = props.accounts.filter(
    (a) => a.accountType === "MORTGAGE"
  );

  const iineOfCreditAccounts: LineOfCredit[] = props.accounts.filter(
    (a) => a.accountType === "LINE_OF_CREDIT"
  );

  return (
    <>
      {loanAccounts.length > 0 && (
        <AccountContainer>
          <LightBold>Loan</LightBold>{" "}
          <div>
            {loanAccounts.map((a, index) => {
              return (
                <AccountRowDisplay
                  key={index}
                  number={a.accountNumber || ""}
                  type={a.type}
                  balance={a.balance?.toString() || "0"}
                />
              );
            })}
          </div>
        </AccountContainer>
      )}
      {creditCardAccounts.length > 0 && (
        <AccountContainer>
          <LightBold>Credit Card</LightBold>
          <div>
            {creditCardAccounts.map((a, index) => {
              return (
                <AccountRow key={index}>
                  <NumberContainer>
                    {MaskedValue(
                      MASK_TYPE.CREDIT_CARD_NUMBER,
                      a.cardNumber ?? ""
                    )}
                  </NumberContainer>
                  <TypeContainer>
                    {getPersonalCreditCardTypeLabel(a.type ?? "")}
                  </TypeContainer>
                  <BalanceContainer>
                    {MaskedValue(MASK_TYPE.MONEY, a.balance?.toString() ?? "")}
                  </BalanceContainer>
                </AccountRow>
              );
            })}
          </div>
        </AccountContainer>
      )}
      {mortgageAccounts.length > 0 && (
        <AccountContainer>
          <LightBold>Mortgage</LightBold>
          <div>
            {mortgageAccounts.map((a, index) => {
              return (
                <AccountRowDisplay
                  key={index}
                  number={a.accountNumber || ""}
                  type={a.type}
                  balance={a.balance?.toString() || "0"}
                />
              );
            })}
          </div>
        </AccountContainer>
      )}
      {iineOfCreditAccounts.length > 0 && (
        <AccountContainer>
          <LightBold>Line Of Credit</LightBold>
          <div>
            {iineOfCreditAccounts.map((a, index) => {
              return (
                <AccountRowDisplay
                  key={index}
                  number={a.accountNumber || ""}
                  type={getPersonalLOCTypeLabel(a.type ?? "")}
                  balance={a.balance?.toString() || "0"}
                />
              );
            })}
          </div>
        </AccountContainer>
      )}
    </>
  );
}

function AccountRowDisplay(props: {
  number: string;
  type?: string;
  balance: string;
}) {
  return (
    <AccountRow>
      <NumberContainer>{props.number}</NumberContainer>
      <TypeContainer>{props.type}</TypeContainer>
      <BalanceContainer>
        {MaskedValue(MASK_TYPE.MONEY, props.balance)}
      </BalanceContainer>
    </AccountRow>
  );
}
const LightBold = styled.div`
  font-weight: 500;
  width: 200px;
  word-wrap: break-word;
  padding-bottom: 6px;
`;

const AccountContainer = styled.div`
  padding-bottom: 1.5em;
`;

const AccountRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
`;

const NumberContainer = styled.div`
  width: 200px;
`;

const BalanceContainer = styled.div`
  width: 100px;
  text-align: right;
`;

const TypeContainer = styled.div`
  width: 150px;
  color: #aaa;
`;
