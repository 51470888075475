import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getMaskedMoneyValue, getTotalCashAmount } from "common/functions";
import styled from "styled-components";
import { CashDrawer } from "types";

export type Props = {
  cashDrawer: CashDrawer;
};

export default function EndOfDayDetails(props: Props) {
  return (
    <div>
      <Table>
        <Header />
        <TableBody>
          <Row cashDrawer={props.cashDrawer} />
        </TableBody>
      </Table>
    </div>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell>Transaction</Cell>
        <Cell>Cash Drawer</Cell>
        <Cell>Calculated Total</Cell>
        <Cell>Starting Total</Cell>
      </TableRow>
    </TableHead>
  );
}

function Row(props: { cashDrawer: CashDrawer }) {
  let calculatedTotal = getTotalCashAmount(props.cashDrawer.cash ?? {});
  return (
    <TableRow>
      <Cell>End Of Day</Cell>
      <Cell>{getMaskedMoneyValue(props.cashDrawer.balance ?? 0)}</Cell>
      <Cell>{getMaskedMoneyValue(calculatedTotal)}</Cell>
      <Cell>{getMaskedMoneyValue(props.cashDrawer.startingTotal ?? 0)}</Cell>
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  padding: 10px 16px 10px 16px !important;
  text-align: center !important;
`;
