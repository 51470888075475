import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import FieldEditor from "components/FieldEditor";
import {
  CustomAsset,
  CustomLiability,
  Employer,
  Employment,
  FieldError,
  PersonalClient,
} from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/creditAppFinancialFieldDefinitions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import FinancialProfileActionsButton from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/FinancialProfileActionsButton";
import { useState } from "react";
import ManageAssetsDialog from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/ManageAssetsDialog";
import ManageLiabilitiesDialog from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/ManageLiabilitiesDialog";
import {
  creditAppHasJointApplicant,
  getCreditAppTotalAssets,
  getCreditAppTotalLiabilities,
  getNAITLABTotalAssets,
  getNAITLABTotalLiabilities,
} from "common/functions";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};

const FinancialProfileSection = (
  props: CommonProps & {
    employers: Employer[];
    isReadOnlyView: boolean;
    onAddEditCustomAsset(updatedCustomAsset: CustomAsset): void;
    onDeleteCustomAsset(customAsset: CustomAsset): void;
    onAddEditCustomLiability(updatedCustomLiability: CustomLiability): void;
    onDeleteCustomLiability(customLiability: CustomLiability): void;
  }
) => {
  const [openAssetsDialog, setOpenAssetsDialog] = useState<boolean>(false);
  const [openLiabilitiesDialog, setOpenLiabilitiesDialog] = useState<boolean>(
    false
  );

  let employment: Employment = props.state.employment
    ? props.state.employment[0]
    : {};

  let employer: Employer =
    props.employers.find((e) => e.employerId === employment?.employerId) ?? {};

  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="financialprofile-header"
        >
          <Typography variant={"h6"}>Financial Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<Employment>
                field={fields.employmentType}
                state={employment}
                setState={(state) => {}}
                errors={[]}
              />

              {employer.employerName && (
                <FieldEditor<Employer>
                  field={fields.employerName}
                  state={employer}
                  setState={(state) => {}}
                  errors={[]}
                />
              )}

              {employment.jobTitle && (
                <FieldEditor<Employment>
                  field={fields.jobTitle}
                  state={employment}
                  setState={(state) => {}}
                  errors={[]}
                />
              )}
              {employment.employmentStartDate && (
                <FieldEditor<Employment>
                  field={fields.employmentStartDate}
                  state={employment}
                  setState={(state) => {}}
                  errors={[]}
                />
              )}
              <FieldEditor<PersonalClient>
                field={fields.totalAssets}
                {...props}
              />
              <FieldEditor<PersonalClient>
                field={fields.totalLiabilities}
                {...props}
              />
              <FieldEditor<PersonalClient> field={fields.netWorth} {...props} />
              <FieldEditor<PersonalClient> field={fields.tds} {...props} />
              <FieldEditor<PersonalClient> field={fields.gds} {...props} />
              {creditAppHasJointApplicant(props.state) && (
                <FieldEditor<PersonalClient>
                  field={fields.jointAppMonthlyIncome}
                  {...props}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldEditor<PersonalClient>
                field={fields.monthlyIncome}
                {...props}
              />

              <FieldEditor<PersonalClient>
                field={fields.residentialStatus}
                {...props}
              />

              <FieldEditor<PersonalClient>
                field={fields.monthlyMortgageRent}
                {...props}
              />
              {props.state.financialProfile?.residentialStatus === "own" && (
                <FieldEditor<PersonalClient>
                  field={fields.monthlyPropertyTax}
                  {...props}
                />
              )}
              {props.state.financialProfile?.residentialStatus === "own" && (
                <FieldEditor<PersonalClient>
                  field={fields.monthlyHeatingCost}
                  {...props}
                />
              )}
              {props.state.financialProfile?.residentialStatus === "own" && (
                <FieldEditor<PersonalClient>
                  field={fields.monthlyCondoFee}
                  {...props}
                />
              )}
              <FieldEditor<PersonalClient>
                field={fields.monthlyConsumerDebt}
                {...props}
              />
              {props.state.financialProfile?.residentialStatus === "other" && (
                <FieldEditor<PersonalClient>
                  field={fields.comment}
                  {...props}
                />
              )}
            </Grid>
          </Grid>
          <ActionsContainer>
            <FinancialProfileActionsButton
              onManageAssetsClick={() => {
                setOpenAssetsDialog(true);
              }}
              onManageLiabilitiesClick={() => {
                setOpenLiabilitiesDialog(true);
              }}
            />
          </ActionsContainer>
        </AccordionDetails>
      </Accordion>
      {openAssetsDialog && (
        <ManageAssetsDialog
          open={openAssetsDialog}
          onClose={() => {
            setOpenAssetsDialog(false);
          }}
          customAssets={props.state.financialProfile?.customAssets ?? []}
          naitlabAssetsTotal={getNAITLABTotalAssets(props.state)}
          creditAppAssetsTotal={getCreditAppTotalAssets(props.state)}
          isReadOnlyView={props.isReadOnlyView}
          onAddEditCustomAsset={(updatedCustomAsset) => {
            props.onAddEditCustomAsset(updatedCustomAsset);
          }}
          onDeleteCustomAsset={(customAsset) => {
            props.onDeleteCustomAsset(customAsset);
          }}
        />
      )}
      {openLiabilitiesDialog && (
        <ManageLiabilitiesDialog
          open={openLiabilitiesDialog}
          onClose={() => {
            setOpenLiabilitiesDialog(false);
          }}
          customLiabilities={
            props.state.financialProfile?.customLiabilities ?? []
          }
          naitlabLiabilitiesTotal={getNAITLABTotalLiabilities(props.state)}
          creditAppLiabilitiesTotal={getCreditAppTotalLiabilities(props.state)}
          onAddEditCustomLiability={(updatedCustomLiability) => {
            props.onAddEditCustomLiability(updatedCustomLiability);
          }}
          onDeleteCustomLiability={(customLiability) => {
            props.onDeleteCustomLiability(customLiability);
          }}
          isReadOnlyView={props.isReadOnlyView}
        />
      )}
    </SectionContainer>
  );
};

export default FinancialProfileSection;

const SectionContainer = styled.div`
  margin-top: 2em;
`;

const ActionsContainer = styled.div`
  text-align: right;
  flex: 1;
  margin-top: 1em;
`;
