import { Divider, IconButton } from "@mui/material";
import { openConfirm } from "components/confirmBox";
import styled from "styled-components";
import { CustomAsset } from "types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomAssetDisplay from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CustomAssetDisplay";
import { getMaskedMoneyValue } from "common/functions";

type Props = {
  customAssets: CustomAsset[];
  isReadOnlyView: boolean;
  onDeleteCustomasset(customasset: CustomAsset): void;
  onEditCustomAsset(customasset: CustomAsset): void;
};

export default function CustomAssetsList({
  customAssets,
  isReadOnlyView,
  onDeleteCustomasset,
  onEditCustomAsset,
}: Props) {
  return (
    <>
      {(!customAssets || (customAssets && customAssets?.length === 0)) && (
        <Message>
          No Assets available. Click <b>Add</b> to Add an Asset
        </Message>
      )}

      {customAssets && customAssets.length > 0 && (
        <div style={{ marginTop: "1.5em" }}>
          {customAssets.map((ca, index) => {
            return (
              <div
                style={{ marginTop: "0.25em", padding: "0 1em" }}
                key={index}
              >
                {index !== 0 && <Divider style={{ marginTop: "0.25em" }} />}
                <CustomAssetRow>
                  <CustomAssetDisplay key={index} customAsset={ca} />
                  <IconContainer>
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        onEditCustomAsset(ca);
                      }}
                      disabled={isReadOnlyView}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        openConfirm({
                          title: "Delete Asset",
                          description: `${ca.name} - ${getMaskedMoneyValue(
                            ca.balance ?? 0
                          )}. Continue?`,
                          callback: () => {
                            onDeleteCustomasset(ca);
                          },
                        });
                      }}
                      disabled={isReadOnlyView}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </IconContainer>
                </CustomAssetRow>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

const Message = styled.div`
  margin: 3em auto 1em auto;
`;

const CustomAssetRow = styled.div`
  display: flex;
  min-width: 550px;
  padding: 0 1em;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: right;
`;
