import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { BusinessClient, GeneralLedger, PersonalClient } from "types";

//uncomment if you need msal auth
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "accountHistory";
const baseURL = `${process.env.REACT_APP_API_BASE}/client`;

type State = {
  //getClient: ReducerState<PersonalClient>;
  savePersonalClient: ReducerState<SavePersonalClientReturn>;
  savePersonalClientAndGL: ReducerState<SavePersonalClientAndGLReturn>;
  saveBusinessClient: ReducerState<SaveBusinessClientReturn>;
  saveBusinessClientAndGL: ReducerState<SaveBusinessClientAndGLReturn>;
};

type SavePersonalClientReturn = {
  clientId: number;
};

let savePersonalClient = createAsyncAction<
  SavePersonalClientReturn, //return type
  { client: PersonalClient, lessonId: number, onComplete?(retValue: SavePersonalClientReturn): void  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "savePersonalClient",
  url: baseURL + "/savePersonalClient",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.savePersonalClient = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.savePersonalClient.isLoading = false;
    state.savePersonalClient.data = action.payload;
    
  },
  rejected: (state, action) => {
    state.savePersonalClient.isLoading = false;
    state.savePersonalClient.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as SavePersonalClientReturn))
},

});

type SavePersonalClientAndGLReturn = {
  success: boolean;
};

let savePersonalClientAndGL = createAsyncAction<
SavePersonalClientAndGLReturn, //return type
  { client: PersonalClient, gl: GeneralLedger, lessonId: number, onComplete?(retValue: SavePersonalClientAndGLReturn): void  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "savePersonalClientAndGL",
  url: baseURL + "/savePersonalClientAndGL",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.savePersonalClientAndGL = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.savePersonalClientAndGL.isLoading = false;
    state.savePersonalClientAndGL.data = action.payload;
    
  },
  rejected: (state, action) => {
    state.savePersonalClientAndGL.isLoading = false;
    state.savePersonalClientAndGL.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as SavePersonalClientAndGLReturn))
},

});

type SaveBusinessClientReturn = {
  clientId: number;
};

let saveBusinessClient = createAsyncAction<
  SaveBusinessClientReturn, //return type
  { client: BusinessClient, lessonId: number, onComplete?(retValue: SaveBusinessClientReturn): void  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "saveBusinessClient",
  url: baseURL + "/saveBusinessClient",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.saveBusinessClient = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveBusinessClient.isLoading = false;
    state.saveBusinessClient.data = action.payload;
    
  },
  rejected: (state, action) => {
    state.saveBusinessClient.isLoading = false;
    state.saveBusinessClient.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as SaveBusinessClientReturn))
},
  
});


type SaveBusinessClientAndGLReturn = {
  success: boolean;
};

let saveBusinessClientAndGL = createAsyncAction<
SavePersonalClientAndGLReturn, //return type
  { client: BusinessClient, gl: GeneralLedger, lessonId: number, onComplete?(retValue: SaveBusinessClientAndGLReturn): void  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "saveBusinessClientAndGL",
  url: baseURL + "/saveBusinessClientAndGL",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.saveBusinessClientAndGL = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveBusinessClientAndGL.isLoading = false;
    state.saveBusinessClientAndGL.data = action.payload;
    
  },
  rejected: (state, action) => {
    state.saveBusinessClientAndGL.isLoading = false;
    state.saveBusinessClientAndGL.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as SaveBusinessClientAndGLReturn))
},

});

let slice = createSlice({
  name: controlName,
  initialState: {
    getClient: { isLoading: false },
    savePersonalClient: {isLoading: false},
    savePersonalClientAndGL: {isLoading: false},
    saveBusinessClient: {isLoading: false},
    saveBusinessClientAndGL: {isLoading: false},
  } as State,
  reducers: {},
  extraReducers: {
    //...getClient.reducer,
    ...savePersonalClient.reducer,
    ...savePersonalClientAndGL.reducer,
    ...saveBusinessClient.reducer,
    ...saveBusinessClientAndGL.reducer,

  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      //getClient: getClient.action,
      savePersonalClient: savePersonalClient.action,
      savePersonalClientAndGL: savePersonalClientAndGL.action,
      saveBusinessClient: saveBusinessClient.action,
      saveBusinessClientAndGL: saveBusinessClientAndGL.action,
    },
  },
};

export default ret;
