import { provinceOptions } from "common/helpers";
import {
  AddressField,
  DateField,
  FIELD_TYPE,
  IdentificationField,
  MASK_TYPE,
  PersonalClient,
  PickListField,
  TextFieldType,
} from "types";

export const accountType: PickListField<PersonalClient> = {
  id: "accountType",
  label: "Account Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.information?.accountType,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, accountType: "Personal" },
  }),
  isRequired: true,
  disabled: true,
  options: [{ id: "Personal", name: "Personal" }],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const legalFirstName: TextFieldType<PersonalClient> = {
  id: "legalFirstName",
  label: "Legal First Name",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.legalFirstName,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, legalFirstName: value },
  }),
  isRequired: true,
};
export const legalLastName: TextFieldType<PersonalClient> = {
  id: "legalLastName",
  label: "Legal Last Name",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.legalLastName,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, legalLastName: value },
  }),
  isRequired: true,
};
export const preferredFirstName: TextFieldType<PersonalClient> = {
  id: "preferredFirstName",
  label: "Pref. First Name",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.preferredFirstName,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, preferredFirstName: value },
  }),
};

export const dateOfBirth: DateField<PersonalClient> = {
  id: "dateOfBirth",
  label: "Date Of Birth",
  type: FIELD_TYPE.DATE,
  getValue: (client: PersonalClient) => client.information?.dateOfBirth,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, dateOfBirth: value },
  }),
  isRequired: true,
};

export const maritalStatus: PickListField<PersonalClient> = {
  id: "maritalStatus",
  label: "Marital Status",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.information?.maritalStatus,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, maritalStatus: value },
  }),
  options: [
    { id: "single", name: "Single" },
    { id: "married", name: "Married" },
    { id: "commonLaw", name: "Common Law" },
    { id: "divorced", name: "Divorced" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};
export const preferredPronouns: PickListField<PersonalClient> = {
  id: "preferredPronouns",
  label: "Preferred Pronouns",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.information?.preferredPronouns,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, preferredPronouns: value },
  }),
  options: [
    { id: "heHim", name: "He/Him" },
    { id: "sheHer", name: "She/Her" },
    { id: "theyThem", name: "They/Them" },
    { id: "heThey", name: "He/They" },
    { id: "sheThey", name: "She/They" },
    { id: "another", name: "Another" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
  isRequired: true,
};

export const comment: TextFieldType<PersonalClient> = {
  id: "comment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.comment,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, comment: value },
  }),
  multiline: true,
};

export const informationFeedback: TextFieldType<PersonalClient> = {
  id: "informationFeedback",
  label: "Information Feedback",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.informationInstructorFeedback,
  setValue: (value, client) => ({
    ...client,
    informationInstructorFeedback: value,
  }),
  multiline: true,
};

export const creditAppFeedback: TextFieldType<PersonalClient> = {
  id: "creditAppFeedback",
  label: "Credit App Feedback",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.informationInstructorFeedback,
  setValue: (value, client) => ({
    ...client,
    creditAppInstructorFeedback: value,
  }),
  multiline: true,
};

export const clientCurrentAddress: AddressField<PersonalClient> = {
  id: "clientCurrentAddress",
  label: "Client Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (client: PersonalClient) =>
    client.information?.clientCurrentAddress,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, clientCurrentAddress: value },
  }),
  disabled: true,
  isDated: true,
  options: provinceOptions,
  isRequired: true,
};

export const clientNewAddress: AddressField<PersonalClient> = {
  id: "clientNewAddress",
  label: "Client Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (client: PersonalClient) => client.information?.clientNewAddress,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, clientNewAddress: value },
  }),
  isDated: true,
  options: provinceOptions,
  isRequired: true,
  multiColumnDisplay: true,
};

export const email: TextFieldType<PersonalClient> = {
  id: "email",
  label: "Email Address",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.email,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, email: value },
  }),
  isRequired: true,
  maxLength: 50,
};

export const phoneNumber: TextFieldType<PersonalClient> = {
  id: "phoneNumber",
  label: "Phone Number",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.phoneNumber,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, phoneNumber: value },
  }),
  mask: MASK_TYPE.PHONE_NUMBER,
  isRequired: true,
};

export const preferredContact: PickListField<PersonalClient> = {
  id: "preferredContact",
  label: "Preferred Contact",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.information?.preferredContact,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, preferredContact: value },
  }),
  isRequired: true,
  options: [
    { id: "email", name: "Email" },
    { id: "phone", name: "Phone" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const phoneNumberType: PickListField<PersonalClient> = {
  id: "phoneNumberType",
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.information?.phoneNumberType,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, phoneNumberType: value },
  }),
  options: [
    { id: "home", name: "Home" },
    { id: "mobile", name: "Mobile" },
    { id: "work", name: "Work" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
  isRequired: true,
};

export const primaryIdentification: IdentificationField<PersonalClient> = {
  id: "primaryIdentification",
  label: "Primary ID",
  type: FIELD_TYPE.IDENTIFICATION,
  options: [
    { id: "driversLicense", name: "Driver's License" },
    { id: "healthCare", name: "Health Care #" },
    { id: "passport", name: "Passport" },
    { id: "prCard", name: "Permanent Resident Card" },
    { id: "creditCard", name: "Credit Card" },
    { id: "other", name: "Other" },
  ],
  getValue: (client: PersonalClient) =>
    client.identification?.primaryIdentification,
  setValue: (value, client) => ({
    ...client,
    identification: { ...client.identification, primaryIdentification: value },
  }),
  isRequired: true,
};

export const secondaryIdentification: IdentificationField<PersonalClient> = {
  id: "secondaryIdentification",
  label: "Secondary ID",
  type: FIELD_TYPE.IDENTIFICATION,
  options: [
    { id: "driversLicense", name: "Driver's License" },
    { id: "healthCare", name: "Health Care #" },
    { id: "passport", name: "Passport" },
    { id: "prCard", name: "Permanent Resident Card" },
    { id: "creditCard", name: "Credit Card" },
    { id: "other", name: "Other" },
  ],
  getValue: (client: PersonalClient) =>
    client.identification?.secondaryIdentification,
  setValue: (value, client) => ({
    ...client,
    identification: {
      ...client.identification,
      secondaryIdentification: value,
    },
  }),
  isRequired: true,
};

export const clientId: TextFieldType<PersonalClient> = {
  id: "clientId",
  label: "Client ID",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.identification?.clientId,
  setValue: (value, client) => ({
    ...client,
    identification: { ...client.identification, clientId: value },
  }),
  disabled: true,
};

export const sin: TextFieldType<PersonalClient> = {
  id: "sin",
  label: "SIN",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.identification?.sin,
  setValue: (value, client) => ({
    ...client,
    identification: { ...client.identification, sin: value },
  }),
  isRequired: true,
  mask: MASK_TYPE.SOCIAL_SECURITY_NUMBER,
};
