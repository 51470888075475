import { Button } from "@mui/material";
import styled from "styled-components";
type appBarButtonsProps = {
  onSaveClick: () => void;
  isSaving?: boolean;
  isReadOnlyView: boolean;
};
export default function AppBarButtons(props: appBarButtonsProps) {
  return (
    <>
      <div style={{ backgroundColor: "white", borderRadius: 4 }}>
        <Button
          onClick={props.onSaveClick}
          disabled={props.isSaving || props.isReadOnlyView}
          variant="contained"
        >
          {props.isSaving ? "saving" : "save"}
        </Button>
      </div>
      <Spacer />
    </>
  );
}

const Spacer = styled.div`
  margin: 2em;
`;
