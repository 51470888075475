import { getMaturityDate } from "common/functions";
import { FIELD_TYPE, TextFieldType,DateField, MASK_TYPE, PickListField, GIC, RadioGroupField } from "types";
import { v4 } from "uuid"
export const gicOpenDate: DateField<GIC> = {
  id: `gicOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: GIC) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: GIC) => {   
  return account.isAccountClosed ?? false
}
};

export const gicCloseDate: DateField<GIC> = {
  id: `gicCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: GIC) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const gicAccountNumber: TextFieldType<GIC> = {
  id: `gicAccountNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: GIC) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true
};

export const gicBalance: TextFieldType<GIC> = {
  id: `gicBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: GIC) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    balance: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
  checkIfDisabled: (account: GIC) => {   
    return account.isAccountClosed ?? false
  }
};

export const gicType: PickListField<GIC> = {
  id: `gicType_${v4()}`,
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: GIC) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
  }),
  options: [
    { id: "rsp", name: "RSP" },
    { id: "nonrsp", name: "Non-RSP" },
    { id: "resp", name: "RESP" },
    { id: "tfsa", name: "TFSA" },
    { id: "rif", name: "RIF" },
  ],
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: GIC) => {   
  return account.isAccountClosed ?? false
}
};



export const gicInvestmentCategory: RadioGroupField<GIC> = {
  id: `gicInvestmentCategory_${v4()}`,
  label: "Investment Category",
  trueLabel: "Cashable",
  falseLabel: "Non-Cashable",
  defaultValue: "Cashable",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: GIC) => account.investmentCategory,
  setValue: (value, account) => ({
    ...account,
    investmentCategory: value,
  }),
  isRequired: true,
  checkIfDisabled: (account: GIC) => {   
    return account.isAccountClosed ?? false
  }
};

export const gicTermLength: PickListField<GIC> = {
  id: `gicTermLength_${v4()}`,
  label: "Term Length (years)",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: GIC) => account.termLength,
  setValue: (value, account) => ({
    ...account,
    termLength: value,
  }),
  options: [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
  ],
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: GIC) => {   
  return account.isAccountClosed ?? false
}
};




export const gicMaturityDate: DateField<GIC> = {
  id: `gicMaturityDate_${v4()}`,
  label: "Maturity Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: GIC) => account.openDate && account.termLength? getMaturityDate(account.openDate, account.termLength as unknown as number).toString() : "",
  setValue: (value, account) => ({
    ...account,
    maturityDate:value,
  }),
  disabled: true
};

export const gicInvestmentRate: TextFieldType<GIC> = {
  id: `gicInvestmentRate_${v4()}`,
  label: "Investment Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: GIC) => account.investmentRate,
  setValue: (value, account) => ({
    ...account,
    investmentRate: value,
  }),
  isRequired: true,
  mask: MASK_TYPE.PERCENTAGE,
  checkIfDisabled: (account: GIC) => {   
    return account.isAccountClosed ?? false
  }
};


