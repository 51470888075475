import { Divider, Grid, Typography } from "@mui/material";
import { getPersonalCreditCardTypeLabel } from "common/functions";
import FieldDisplay from "components/FieldDisplay";
import DisplayFieldTemplate from "components/fields/DisplayFieldTemplate";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppLoanFieldDefinitions";
import { CreditAppLoan } from "types";
import AccountDisplayTemplate from "./AccountDisplayTemplate";
export type Props = {
  account: CreditAppLoan;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

function CreditAppLoanDisplay({
  account,
  onEditClick,
  onDeleteClick,
  isReadOnlyView,
}: Props) {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`LOAN_${account.accountId}_header`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      accountType="Loan"
      accountId={account.accountId || ""}
      type={getPersonalCreditCardTypeLabel(account.type ?? "")}
      requestedAmount={account.requestedAmount || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DisplayFieldTemplate label="Account Type">Loan</DisplayFieldTemplate>
          <FieldDisplay<CreditAppLoan>
            field={fields.requestedAmount}
            {...commonProps}
          />
          <FieldDisplay<CreditAppLoan>
            field={fields.paymentAmount}
            {...commonProps}
          />

          <FieldDisplay<CreditAppLoan>
            field={fields.loanTermLength}
            {...commonProps}
          />
          {/* <FieldEditor<CreditAppLoan>
              field={fields.loanType}
              {...commonProps}
            />
            {state.type === "Secured" && (
              <FieldEditor<CreditAppLoan>
                field={fields.loanSecurity}
                {...commonProps}
              />
            )} */}

          <FieldDisplay<CreditAppLoan> field={fields.reason} {...commonProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<CreditAppLoan>
            field={fields.loanType}
            {...commonProps}
          />
          {commonProps.state.type === "Secured" && (
            <FieldDisplay<CreditAppLoan>
              field={fields.loanSecurity}
              {...commonProps}
            />
          )}
          <FieldDisplay<CreditAppLoan>
            field={fields.loanInterestType}
            {...commonProps}
          />
          {commonProps.state.interestType === "Variable" && (
            <FieldDisplay<CreditAppLoan>
              field={fields.loanPrimeRate}
              {...commonProps}
            />
          )}
          <FieldDisplay<CreditAppLoan>
            field={fields.loanInterestRate}
            {...commonProps}
          />
          {commonProps.state.interestType === "Variable" && (
            <FieldDisplay<CreditAppLoan>
              field={fields.loanTotalInterestRate}
              {...commonProps}
            />
          )}
          <FieldDisplay<CreditAppLoan>
            field={fields.hasJointApplication}
            {...commonProps}
          />
        </Grid>
      </Grid>
      {commonProps.state.hasJointApplication && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "2em" }}>
            Joint Application Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CreditAppLoan>
                field={fields.loanJointAppName}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLoan>
                field={fields.loanJointAppEmployerName}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLoan>
                field={fields.loanJointAppJobTitle}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CreditAppLoan>
                field={fields.loanJointAppEmploymentStartDate}
                {...commonProps}
              />
              <FieldDisplay<CreditAppLoan>
                field={fields.loanJointAppMonthlyIncome}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
    </AccountDisplayTemplate>
  );
}

export default CreditAppLoanDisplay;
