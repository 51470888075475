import { getMaskedMoneyValue } from "common/functions";
import moment from "moment";
import { Deposit } from "types";
import AccountHistoryRowTemplate from "pages/transactions/components/AccountHistoryRowTemplate";

type AccountHostoryRowDepositProps = {
  transaction: Deposit;
  balance: number;
};

export default function AccountHistoryRowDeposit(
  props: AccountHostoryRowDepositProps
) {
  return (
    <AccountHistoryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      credit={getMaskedMoneyValue(props.transaction.amount ?? 0)}
      balance={getMaskedMoneyValue(props.balance)}
    />
  );
}
