import { Paper, Table, TableBody } from "@mui/material";
import { TableCell, TableHead, TableRow } from "@mui/material";
import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";
import { useState } from "react";
import styled from "styled-components";
import {
  BusinessClient,
  GLAccount,
  GLEntry,
  GLTransaction,
  PersonalClient,
  SellDraft,
  Transaction,
} from "types";

import {
  add,
  subtract,
  getMaskedMoneyValue,
  getGLAccountOptions,
} from "common/functions";
import GLAccountHistoryRowDebit from "./GLAccountHistoryRowDebit";
import GLAccountHistoryRowCredit from "./GLAccountHistoryRowCredit";

export type Props = {
  glAccounts: GLAccount[];
  glTransactions: GLTransaction[];
  clients: (PersonalClient | BusinessClient)[];
};

export default function GLAccountDetails(props: Props) {
  const [accountOption, setAccountOption] = useState<string>("");
  const selectedAccount = props.glAccounts.find(
    (a) => a.glAccountId === accountOption
  );

  let balance = selectedAccount?.balance;
  let txnAmount = 0;

  const selectedAccountBalance = getMaskedMoneyValue(
    selectedAccount?.balance ?? 0
  );

  const transactionIds: string[] = selectedAccount?.transactionIds ?? [];

  let clientTransactions: Transaction[] = [];

  for (let i = 0; i < props.clients.length; i++) {
    let tempTxnSessions = props.clients[i].transactionSessions ?? [];

    for (let j = 0; j < tempTxnSessions.length; j++) {
      let tempTxns =
        tempTxnSessions[j].transactions?.filter((t) =>
          transactionIds.includes(t.transactionId)
        ) ?? [];

      clientTransactions = [...clientTransactions, ...tempTxns];
    }
  }

  let tempGLTxns = props.glTransactions.filter((gltxn) =>
    transactionIds.includes(gltxn.transactionId)
  );

  let allTxns = [...clientTransactions, ...tempGLTxns];

  let sortedTxns = allTxns.sort(
    (a, b) =>
      new Date(b.transactionDateTime ?? 0).getTime() -
      new Date(a.transactionDateTime ?? 0).getTime()
  );

  return (
    <ColumnContent>
      <TopSection>
        <PicklistContainer>
          <PickListNonFieldEditor
            id="glaccounts"
            label="GL Account"
            value={accountOption}
            onChange={(v) => {
              setAccountOption(v);
            }}
            options={getGLAccountOptions()}
          />
        </PicklistContainer>
        {selectedAccount && (
          <>
            <BalanceSection>
              <TwoColumnDisplay
                label="Balance"
                value={selectedAccountBalance}
              />
            </BalanceSection>
            <Sapcer />
          </>
        )}
      </TopSection>
      {accountOption === "" && (
        <FlexCenter>Select GL Account to see the GL account history</FlexCenter>
      )}
      {!!accountOption &&
        (sortedTxns === undefined || sortedTxns.length === 0) && (
          <FlexCenter>No Transactions yet</FlexCenter>
        )}
      {!!accountOption && sortedTxns.length > 0 && (
        <>
          <Table>
            <Header />
            <TableBody>
              <>
                {sortedTxns.map((t, index) => {
                  balance =
                    index === 0
                      ? balance
                      : (sortedTxns[index - 1].transactionType === "GL_ENTRY" &&
                          (sortedTxns[index - 1] as GLEntry).glType ===
                            "debit") ||
                        (sortedTxns[index - 1].transactionType ===
                          "CASH_TRANSFER" &&
                          (sortedTxns[index - 1] as GLTransaction).from ===
                            selectedAccount?.glAccountId)
                      ? add(balance ?? 0, txnAmount)
                      : subtract(balance ?? 0, txnAmount);

                  txnAmount =
                    t.transactionType === "SELL_DRAFT"
                      ? (t as SellDraft).fee ?? 0
                      : t.amount ?? 0;
                  if (
                    (t.transactionType === "GL_ENTRY" &&
                      (t as GLEntry).glType === "debit") ||
                    (t.transactionType === "CASH_TRANSFER" &&
                      (t as GLTransaction).from ===
                        selectedAccount?.glAccountId)
                  ) {
                    return (
                      <GLAccountHistoryRowDebit
                        key={index}
                        transaction={t}
                        balance={balance ?? 0}
                      />
                    );
                  }
                  if (
                    (t.transactionType === "GL_ENTRY" &&
                      (t as GLEntry).glType === "credit") ||
                    (t.transactionType === "CASH_TRANSFER" &&
                      (t as GLTransaction).to ===
                        selectedAccount?.glAccountId) ||
                    t.transactionType === "SELL_DRAFT"
                  ) {
                    return (
                      <GLAccountHistoryRowCredit
                        key={index}
                        transaction={t}
                        balance={balance ?? 0}
                      />
                    );
                  }
                  return <TableRow key={index}></TableRow>;
                })}
              </>
            </TableBody>
          </Table>
        </>
      )}
    </ColumnContent>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell>Date</Cell>
        <Cell>Description</Cell>
        <Cell>Reason</Cell>
        <Cell>Debit</Cell>
        <Cell>Credit</Cell>
        <Cell>Balance</Cell>
        {/* <Cell></Cell> */}
      </TableRow>
    </TableHead>
  );
}

function TwoColumnDisplay(props: { label: string; value: string }) {
  return (
    <RowContainer>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </RowContainer>
  );
}
const Label = styled.label`
  color: #0000008a;
  padding-right: 15px;
  font-size: 16px;
`;
const Value = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  padding-right: 15px;
`;
const RowContainer = styled.div`
  padding-left: 2.5em;
  display: flex;
`;

const ColumnContent = styled(Paper)`
  min-height: 600px;
  min-width: 800px;
`;

const FlexCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2em;
`;

const TopSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
`;

const BalanceSection = styled.div`
  display: flex;
  justify-content: right;
`;

const Sapcer = styled.div`
  margin-right: 10em;
`;

const PicklistContainer = styled.div`
  width: 300px;
`;

const Cell = styled(TableCell)`
  font-size: 16px !important;
  text-align: center !important;
`;
