import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LessonListItem } from "types";
import styled from "styled-components";
import _orderBy from "lodash/orderBy";
import moment from "moment";
import ResetLesson from "pages/lessons/components/ResetLesson";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import LaunchIcon from "@mui/icons-material/Launch";
import { useHistory } from "react-router-dom";
import { StudentSessionsRoute } from "components/paths";
import ActionMenu from "pages/lessons/components/ActionMenu";
import DeleteControl from "components/deleteControl";
import EditLessonName from "pages/lessons/components/EditLessonName";
import AdvanceMonthsDialog from "./AdvanceMonthsDialog";
import { convertUTCToLocal } from "common/functions";

export type Props = {
  lessonList?: LessonListItem[];
  onOpenClick(id: number): void;
  onAddClick(): void;
  isInstructor: boolean;
  onResetLesson(lessonId: number): void;
  onDelete(lessonId: number): void;
  onEditSave(lessonId: number, updatedLessonName: string): void;
  onAdvanceMonths(lessonId: number, numberofMonths: number): void;
  isLoading: boolean;
};

export default function LessonList(props: Props) {
  //setting page to 0(1st page) if the filtered list is changed
  //to have correct filtering user experience
  useEffect(() => {
    setPage(0);
  }, [props.lessonList]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [lessonToReset, setLessonToReset] = useState<LessonListItem>();

  let sortedLessonList = _orderBy(
    props.lessonList,
    (l) => moment(l.lastModified),
    "desc"
  );
  const history = useHistory();

  const isAnyLessonWithTimeBasedAccess = props.lessonList?.some(
    (lesson) => lesson.startDateTime && lesson.endDateTime
  );

  return (
    <div style={{ minWidth: 800 }}>
      <FlexDiv>
        <ListingHeading>Lessons</ListingHeading>
        {props.isInstructor && (
          <>
            <Button
              onClick={() => {
                history.push(StudentSessionsRoute);
              }}
            >
              Administration
            </Button>
            <Spacer />
            <Button variant="contained" onClick={props.onAddClick}>
              Add
            </Button>
          </>
        )}
      </FlexDiv>
      {(!sortedLessonList || sortedLessonList.length === 0) && (
        <Content>
          <div>
            {props.isLoading ? (
              <div style={{ fontStyle: "italic" }}>
                Getting lesson's list...
              </div>
            ) : (
              <div>There are no lessons.</div>
            )}
          </div>
        </Content>
      )}
      {sortedLessonList && sortedLessonList.length > 0 && (
        <Paper component={Table}>
          <Header
            isInstructor={props.isInstructor}
            isAnyLessonWithTimeBasedAccess={
              isAnyLessonWithTimeBasedAccess ?? false
            }
          />
          <TableBody>
            {sortedLessonList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((l, index) => {
                return (
                  <Row
                    lessonListItem={l}
                    key={index}
                    onOpenClick={props.onOpenClick}
                    onResetClick={() => {
                      setLessonToReset(l);
                    }}
                    onEditSave={(updatedLessonName) => {
                      props.onEditSave(l.id, updatedLessonName);
                    }}
                    onDelete={() => {
                      props.onDelete(l.id);
                    }}
                    onAdvanceMonths={(numberofMonths) => {
                      props.onAdvanceMonths(l.id, numberofMonths);
                    }}
                    isInstructor={props.isInstructor}
                    lessonList={props.lessonList ?? []}
                    isLoading={props.isLoading}
                    isAnyLessonWithTimeBasedAccess={
                      isAnyLessonWithTimeBasedAccess ?? false
                    }
                  />
                );
              })}
          </TableBody>
        </Paper>
      )}
      {props.lessonList && props.lessonList.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={props.lessonList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => {
            setPage(p);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
          }}
        />
      )}
      {!!lessonToReset && (
        <ResetLesson
          lessonItem={lessonToReset}
          onReset={() => {
            props.onResetLesson(lessonToReset.id);
            setLessonToReset(undefined);
          }}
          onCancel={() => {
            setLessonToReset(undefined);
          }}
        />
      )}
    </div>
  );
}

function Header(props: {
  isInstructor: boolean;
  isAnyLessonWithTimeBasedAccess: boolean;
}) {
  return (
    <TableHead>
      <TableRow>
        <Cell>Lesson Name</Cell>
        <Cell>Clients</Cell>
        <Cell>Employers</Cell>
        <Cell>Last Modified</Cell>
        {props.isAnyLessonWithTimeBasedAccess && <Cell>Start Date Time</Cell>}
        {props.isAnyLessonWithTimeBasedAccess && <Cell>End Date Time</Cell>}
        <Cell></Cell>
        {props.isInstructor && <Cell></Cell>}
      </TableRow>
    </TableHead>
  );
}

function Row(props: {
  lessonListItem: LessonListItem;
  lessonList: LessonListItem[];
  onOpenClick(id: number): void;
  onResetClick(): void;
  onDelete(): void;
  onEditSave(updatedLessonName: string): void;
  onAdvanceMonths(numberofMonths: number): void;
  isInstructor: boolean;
  isLoading: boolean;
  isAnyLessonWithTimeBasedAccess: boolean;
}) {
  const [openDeleteControl, setOpenDeleteControl] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openAdvanceMonthsDialog, setOpenAdvanceMonthsDialog] = useState(false);
  let noOfStudents = props.lessonListItem.numberOfStudentsHavingAccess;
  let variableWarningMessage =
    noOfStudents === 0
      ? `is no student that has`
      : noOfStudents === 1
      ? `is 1 student that has`
      : `are ${noOfStudents} students that have`;

  return (
    <>
      <TableRow>
        <Cell>{props.lessonListItem.lessonName}</Cell>
        <Cell>{props.lessonListItem.numberOfClients}</Cell>
        <Cell>{props.lessonListItem.numberOfEmployers}</Cell>
        <Cell>
          {props.lessonListItem.lastModified === "0001-01-01 12:00:00 AM"
            ? "N/A"
            : moment
                .utc(props.lessonListItem.lastModified)
                .local()
                .format("MM/DD/YYYY hh:mm:ss A")
                .toString()}
        </Cell>
        {/* <Cell>{getTimedAccessInfo(props.lessonListItem)}</Cell> */}
        {props.isAnyLessonWithTimeBasedAccess && (
          <Cell>
            {props.lessonListItem.startDateTime
              ? convertUTCToLocal(props.lessonListItem.startDateTime)
              : "N/A"}
          </Cell>
        )}
        {props.isAnyLessonWithTimeBasedAccess && (
          <Cell>
            {props.lessonListItem.endDateTime
              ? convertUTCToLocal(props.lessonListItem.endDateTime)
              : "N/A"}
          </Cell>
        )}
        <Cell>
          <Button
            onClick={() => {
              props.onOpenClick(props.lessonListItem.id);
            }}
          >
            <LaunchIcon style={{ marginRight: 5 }} />
            Open
          </Button>

          {!props.isInstructor && (
            <Button
              onClick={props.onResetClick}
              disabled={
                !props.lessonListItem.isDataModifiedByStudent ||
                props.lessonListItem.isReadOnly
              }
            >
              <RotateLeftIcon style={{ marginRight: 5 }} />
              Reset
            </Button>
          )}
        </Cell>
        {props.isInstructor && (
          <Cell>
            <ActionMenu
              onEdit={() => {
                setOpenEditDialog(true);
              }}
              onDelete={() => {
                setOpenDeleteControl(true);
              }}
              onAdvanceMonths={() => {
                setOpenAdvanceMonthsDialog(true);
              }}
              isLoading={props.isLoading}
            />
          </Cell>
        )}
      </TableRow>
      {openDeleteControl && (
        <DeleteControl
          itemToDelete="Lesson"
          deleteItemName={props.lessonListItem.lessonName}
          onCancel={() => {
            setOpenDeleteControl(false);
          }}
          onDelete={() => {
            props.onDelete();
            setOpenDeleteControl(false);
          }}
          warningMessage={`Doing so will permanently delete this lesson template and any student data associated with this lesson will no longer be accessible.`}
          customMessage={`There ${variableWarningMessage} access to this lesson.`}
        />
      )}
      {openEditDialog && (
        <EditLessonName
          selectedLesson={props.lessonListItem}
          onCancel={() => {
            setOpenEditDialog(false);
          }}
          lessonList={props.lessonList}
          onSave={(updatedLessonName) => {
            props.onEditSave(updatedLessonName);
            setOpenEditDialog(false);
          }}
        />
      )}
      {openAdvanceMonthsDialog && (
        <AdvanceMonthsDialog
          lessonName={props.lessonListItem.lessonName}
          onCancelClick={() => setOpenAdvanceMonthsDialog(false)}
          onSubmitClick={(numberofMonths) => {
            setOpenAdvanceMonthsDialog(false);
            props.onAdvanceMonths(numberofMonths);
          }}
        />
      )}
    </>
  );
}

// function getTimedAccessInfo(lessonListItem: LessonListItem) {
//   if (lessonListItem.startDateTime && lessonListItem.endDateTime) {
//     var startDateTime = new Date(lessonListItem.startDateTime);
//     var endDateTime = new Date(lessonListItem.endDateTime);
//     var startDate = startDateTime.toLocaleDateString();
//     var endDate = endDateTime.toLocaleDateString();
//     if (startDate === endDate) {
//       var startTime = startDateTime.toLocaleTimeString();
//       var endTime = endDateTime.toLocaleTimeString();
//       return (
//         <>
//           <div>{startDate}</div>
//           <div>
//             {startTime} - {endTime}
//           </div>
//         </>
//       );
//     } else
//       return (
//         <>
//           <div>{convertUTCToLocal(lessonListItem.startDateTime)}</div>
//           <div>- {convertUTCToLocal(lessonListItem.endDateTime)}</div>
//         </>
//       );
//   } else {
//     return "No";
//   }
// }

const Cell = styled(TableCell)`
  font-size: 16px !important;
  padding: 10px 16px 10px 16px !important;
  text-align: center !important;
`;

const ListingHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const FlexDiv = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

const Content = styled.div`
  margin-top: 3em;
  display: flex;
  justify-content: center;
`;

const Spacer = styled.div`
  margin: 0.5em;
`;
