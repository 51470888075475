import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import { Employer, Employment, FieldError, PersonalClient } from "types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useState } from "react";
import EmploymentDisplayTemplate from "pages/clientPortfolio/personalClient/personalClientInformation/components/EmploymentDisplayTemplate";
import EmploymentEditor from "pages/clientPortfolio/personalClient/personalClientInformation/components/EmploymentEditor";
import { defaultEmployment } from "common/helpers";
import { v4 } from "uuid";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};
const EmploymentSection = (
  props: CommonProps & {
    employers: Employer[];
    onEditEmployment(updatedEmployment: Employment): void;
    onDeleteEmployment(employment: Employment): void;
    isReadOnlyView: boolean;
  }
) => {
  const [employmentInEdit, setEmploymentInEdit] = useState<
    Employment | undefined
  >(undefined);

  const employment = props.state.employment;

  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="employmentSection-header"
        >
          <Typography variant={"h6"}>Employment</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {(!employment || employment.length === 0) && (
            <div>No Current Employment Record available.</div>
          )}
          {employment &&
            employment.length > 0 &&
            employment.map((e, index) => {
              return (
                <div key={index}>
                  <EmploymentDisplayTemplate
                    employment={e}
                    employer={
                      props.employers.find(
                        (emp) => emp.employerId === e.employerId
                      ) ?? {}
                    }
                    onEditClick={() => {
                      setEmploymentInEdit(e);
                    }}
                    onDeleteClick={() => {
                      props.onDeleteEmployment(e);
                    }}
                    isReadOnlyView={props.isReadOnlyView}
                  />
                  <Divider style={{ margin: "1.25em 0" }} />
                </div>
              );
            })}
          <ButtonContainer>
            <Button
              disabled={props.isReadOnlyView}
              onClick={() => {
                setEmploymentInEdit({
                  ...defaultEmployment,
                  employmentId: v4(),
                });
              }}
            >
              Add New
            </Button>
          </ButtonContainer>
        </AccordionDetails>
      </Accordion>
      {!!employmentInEdit && (
        <EmploymentEditor
          employment={employmentInEdit}
          employer={
            !!employmentInEdit.employerId
              ? props.employers.find(
                  (emp) => emp.employerId === employmentInEdit.employerId
                )
              : {}
          }
          onSave={(newEmployment) => {
            props.onEditEmployment(newEmployment);
            setEmploymentInEdit(undefined);
          }}
          onCancel={() => {
            setEmploymentInEdit(undefined);
          }}
        />
      )}
    </SectionContainer>
  );
};

export default EmploymentSection;

const SectionContainer = styled.div`
  margin-top: 2em;
`;

const ButtonContainer = styled.div`
  margin-top: 2em;
  text-align: right;
`;
