import { v4 } from "uuid";
import {  
  FIELD_TYPE,  
  MASK_TYPE,
  PickListField,
  TextFieldType,
  Deposit,
  DateField,
} from "types";

export const depositTransactionDateTime: DateField<Deposit> = {
  id: `depositTransactionDateTime${v4()}`,
  label: "Date",
  type: FIELD_TYPE.DATE,
  getValue: (deposit: Deposit) => deposit.transactionDateTime,
  setValue: (value, deposit) => ({
    ...deposit,
    transactionDateTime: value,
  }),
 isRequired: true
};

export const depositFrom: PickListField<Deposit> = {
  id: "depositFrom",
  label: "From",
  defaultValue: "sessionbalance",
  type: FIELD_TYPE.PICKLIST,
  getValue: (deposit: Deposit) =>  deposit.from,
  setValue: (value, deposit) => ({...deposit,from: value }),
  options: [
    { id: "sessionbalance", name: "Session Balance" },
  ],
  disabled: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};


export const depositTo: PickListField<Deposit> = {
  id: "depositTo",
  label: "To",
  type: FIELD_TYPE.ACCOUNTSPICKLIST,
  isRequired: true,
  getValue: (deposit: Deposit) =>  deposit.to,
  setValue: (value, deposit) => ({...deposit,to: value }),
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const depositDescription: TextFieldType<Deposit> = {
  id: "depositDescription",
  label: "Description",
  type: FIELD_TYPE.TEXT,
  getValue: (deposit: Deposit) => deposit.transactionDescription,
  setValue: (value, deposit) => ({
    ...deposit,
    transactionDescription: value,
  }),
  maxLength: 50
};

export const depositAmount: TextFieldType<Deposit> = {
  id: "depositAmount",
  label: "Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (deposit: Deposit) => deposit.amount ? deposit.amount.toString() : "",
  setValue: (value, deposit) => ({
    ...deposit,
    amount: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
};