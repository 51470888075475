import styled from "styled-components";
import { FieldError, PersonalClient } from "types";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/clientPortfolio/personalClient/personalClientInvesting/investingFieldDefinitions";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};
export default function TimeHorizonSection(props: CommonProps) {
  return (
    <SectionContainer>
      <QuestionDiv>
        When do you expect to need to withdraw a significant portion (1/3 or
        more) of the money in your investment portfolio?
      </QuestionDiv>
      <FieldDiv>
        <FieldEditor<PersonalClient> field={fields.timeHorizon} {...props} />
      </FieldDiv>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionDiv = styled.div`
  width: 625px;
  margin: 0px 10px;
  align-items: center;
`;

const FieldDiv = styled.div`
  width: 250px;
  margin: 0px 10px;
  align-items: center;
`;
