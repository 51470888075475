import {
  BoolField,
  CustomLiability,
  FIELD_TYPE,
  MASK_TYPE,
  TextFieldType,
} from "types";

export const name: TextFieldType<CustomLiability> = {
  id: `name`,
  label: "Name",
  type: FIELD_TYPE.TEXT,
  getValue: (customLiability: CustomLiability) => customLiability.name,
  setValue: (value, customLiability) => ({
    ...customLiability,
    name: value,
  }),
  isRequired: true,
};

export const limit: TextFieldType<CustomLiability> = {
  id: `limit`,
  label: "Limit",
  type: FIELD_TYPE.TEXT,
  getValue: (customLiability: CustomLiability) =>
    customLiability.limit?.toString(),
  setValue: (value, customLiability) => ({
    ...customLiability,
    limit: !!value && value !== "" ? ((value as unknown) as number) : undefined,
  }),
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const balance: TextFieldType<CustomLiability> = {
  id: `balance`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (customLiability: CustomLiability) =>
    customLiability.balance?.toString(),
  setValue: (value, customLiability) => ({
    ...customLiability,
    balance:
      !!value && value !== "" ? ((value as unknown) as number) : undefined,
  }),
  mask: MASK_TYPE.MONEY,
  isRequired: true,
  allowNegative: false,
};

export const monthlyPayment: TextFieldType<CustomLiability> = {
  id: `monthlyPayment`,
  label: "Monthly Payment",
  type: FIELD_TYPE.TEXT,
  getValue: (customLiability: CustomLiability) =>
    customLiability.monthlyPayment?.toString(),
  setValue: (value, customLiability) => ({
    ...customLiability,
    monthlyPayment:
      !!value && value !== "" ? ((value as unknown) as number) : undefined,
  }),
  mask: MASK_TYPE.MONEY,
  isRequired: true,
  allowNegative: false,
};

export const tds: BoolField<CustomLiability> = {
  id: `tds`,
  label: "TDS",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (customLiability: CustomLiability) => customLiability.hasTDS,
  setValue: (value, customLiability) => ({
    ...customLiability,
    hasTDS: value,
  }),
  //labelPlacement: "end",
};

export const alreadyIncludedInFinancialProfile: BoolField<CustomLiability> = {
  id: `alreadyIncludedInFinancialProfile`,
  label: "Included In Financial Profile",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (customLiability: CustomLiability) =>
    customLiability.alreadyIncludedInFinancialProfile,
  setValue: (value, customLiability) => ({
    ...customLiability,
    alreadyIncludedInFinancialProfile: value,
  }),
};
