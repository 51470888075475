import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import { ReactNode } from "react";

export type Props = {
  id: string;
  type: string;
  isNew: boolean;
  isAnotherAccountExists: boolean;
  requestedAmount: number;
  children: ReactNode;
  onAddUpdateClick(): void;
  onCancelClick(): void;
};

export default function AccountEditTemplate({
  id,
  type,
  isNew,
  requestedAmount,
  children,
  onAddUpdateClick,
  onCancelClick,
}: Props) {
  return (
    <>
      <Dialog maxWidth="md" fullWidth open id={id}>
        <DialogTitle style={{ display: "flex" }}>
          <Typography sx={{ width: "33%", flex: 5 }}>{type}</Typography>
          <Typography sx={{ width: "15%", textAlign: "right" }}>
            {getMaskedMoneyValue(requestedAmount)}
          </Typography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick}>Cancel</Button>
          <Button variant="contained" onClick={onAddUpdateClick}>
            {isNew ? "Add" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
