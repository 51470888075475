export const HomeRoute = "/";
export const PersonalClientSummaryRoute =
  "/clientportfolio/personalclient/clientsummary";
export const PersonalClientInformationRoute =
  "/clientportfolio/personalclient/clientinformation";
export const PersonalClientAccountsRoute =
  "/clientportfolio/personalclient/clientaccounts";
export const PersonalClientCreditAppRoute =
  "/clientportfolio/personalclient/creditapp";
export const PersonalClientInvestingRoute =
  "/clientportfolio/personalclient/investing";
export const BusinessClientSummaryRoute =
  "/clientportfolio/businessclient/clientsummary";
export const BusinessClientInformationRoute =
  "/clientportfolio/businessclient/clientinformation";
export const BusinessClientAccountsRoute =
  "/clientportfolio/businessclient/clientaccounts";
export const CreateEmployerRoute = "/employers";
export const SearchClientsRoute = "/searchclient";
export const PersonalClientTransactionsRoute = "/personalclient/transactions";
export const BusinessClientTransactionsRoute = "/businessclient/transactions";
export const PersonalClientAccountHistoryRoute =
  "/personalclient/accounthistory";
export const BusinessClientAccountHistoryRoute =
  "/businessclient/accounthistory";
export const GeneralLedgerRoute = "/generalledger";
export const LessonsRoute = "/lessons";
export const GlobalSettingsRoute = "/globalsettings";
export const StudentSessionsRoute = "/studentsessions";
export const OutstandingOverrideRoute = "/outstandingoverrides";
export const UnauthorizedRoute = "/unauthorized";
export const SessionHistoryRoute = "/sessionhistory";
export const CashDrawerRoute = "/cashdrawer";
export const NoContentRoute = "/nocontent";
