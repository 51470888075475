import FieldEditor from "components/FieldEditor";
import { FieldError, Mortgage } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientAccounts/personalMortgageFieldDefinitions";
import { useEffect, useState } from "react";
import {
  requiredDateField,
  requiredPickListField,
  requiredRadioGroupField,
  requiredTextField,
} from "common/functions";
import AccountEditTemplate from "pages/clientPortfolio/personalClient/personalClientAccounts/components/AccountEditTemplate";
import { openConfirm } from "components/confirmBox";
export type Props = {
  account: Mortgage;
  isSaving: boolean;
  onCancel(): void;
  onSave(account: Mortgage): void;
};

function MortgageForm({ account, onCancel, onSave, isSaving }: Props) {
  const [state, setState] = useState<Mortgage>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: Mortgage) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredDateField(fields.mortgageOpenDate, state),
      ...requiredPickListField(fields.mortgageAmortization, state),
      ...requiredTextField(fields.mortgagePaymentAmount, state),
      ...requiredPickListField(fields.mortgagePaymentFrequency, state),
      ...requiredRadioGroupField(fields.mortgageType, state),
      ...requiredRadioGroupField(fields.mortgageInterestType, state),
      ...requiredTextField(fields.mortgageInterestRate, state),
      ...requiredDateField(fields.mortgageTermStartDate, state),
      ...requiredPickListField(fields.mortgageTermLength, state),
    ];
    if (
      state.closeDate &&
      state.openDate &&
      state.closeDate.toLowerCase() !== "invalid date" &&
      new Date(state.closeDate) < new Date(state.openDate)
    ) {
      let invalidCloseDateErr: FieldError = {
        id: `${fields.mortgageCloseDate.id}`,
        label: `${fields.mortgageCloseDate.label}`,
        error: "Close Date should be after the Open Date",
      };
      errorsArray.push(invalidCloseDateErr);
    }
    if (
      state.termStartDate &&
      state.openDate &&
      state.termStartDate.toLowerCase() !== "invalid date" &&
      new Date(state.termStartDate) < new Date(state.openDate)
    ) {
      let invalidTermStartDateErr: FieldError = {
        id: `${fields.mortgageTermStartDate.id}`,
        label: `${fields.mortgageTermStartDate.label}`,
        error: "Term Start Date should be after the Open Date",
      };
      errorsArray.push(invalidTermStartDateErr);
    }
    setErrors(errorsArray);
    return errorsArray;
  }

  function onSaveClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (
        state.closeDate &&
        state.closeDate.toLowerCase() !== "invalid date" &&
        state.balance &&
        state.balance !== 0
      ) {
        openConfirm({
          title: "Error",
          description: `Account Balance should be $0.00 to close the account`,
          isAlert: true,
        });
      } else {
        onSave({
          ...state,
          isAccountClosed:
            state.closeDate && state.closeDate.toLowerCase() !== "invalid date"
              ? true
              : false,
          interestRate:
            state.interestType === "Fixed" &&
            ((state.interestRate as unknown) as number) < 0
              ? (((state.interestRate as unknown) as number) * -1).toString()
              : state.interestRate,
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`LineOfCredit_${account.accountId}_form`}
      onSaveClick={onSaveClick}
      onCancelClick={onCancel}
      isSaving={isSaving}
      type="Mortgage"
      accountNumber={account.accountNumber || ""}
      balance={state.balance || 0}
      column1Fields={
        <>
          <FieldEditor<Mortgage>
            field={fields.mortgageOpenDate}
            {...commonProps}
          />
          <FieldEditor<Mortgage>
            field={fields.mortgageAccountNumber}
            {...commonProps}
          />

          <FieldEditor<Mortgage>
            field={fields.mortgageBalance}
            {...commonProps}
          />
          <FieldEditor<Mortgage> field={fields.mortgageType} {...commonProps} />

          <FieldEditor<Mortgage>
            field={fields.mortgageAmortization}
            {...commonProps}
          />
          <FieldEditor<Mortgage>
            field={fields.mortgageRemainingAmortization}
            {...commonProps}
          />
          <FieldEditor<Mortgage>
            field={fields.mortgageMaturityDate}
            {...commonProps}
          />
          <FieldEditor<Mortgage>
            field={fields.mortgagePaymentAmount}
            {...commonProps}
          />
          <FieldEditor<Mortgage>
            field={fields.mortgagePaymentFrequency}
            {...commonProps}
          />
        </>
      }
      column2Fields={
        <>
          <FieldEditor<Mortgage>
            field={fields.mortgageCloseDate}
            {...commonProps}
          />

          <FieldEditor<Mortgage>
            field={fields.mortgageInterestType}
            {...commonProps}
          />
          {state.interestType === "Variable" && (
            <FieldEditor<Mortgage>
              field={fields.mortgagePrimeRate}
              {...commonProps}
            />
          )}
          <FieldEditor<Mortgage>
            field={fields.mortgageInterestRate}
            {...commonProps}
          />
          {state.interestType === "Variable" && (
            <FieldEditor<Mortgage>
              field={fields.mortgageTotalInterestRate}
              {...commonProps}
            />
          )}
          <FieldEditor<Mortgage>
            field={fields.mortgageTermStartDate}
            {...commonProps}
          />
          <FieldEditor<Mortgage>
            field={fields.mortgageTermLength}
            {...commonProps}
          />
          <FieldEditor<Mortgage>
            field={fields.mortgageRemainingTermLength}
            {...commonProps}
          />
        </>
      }
    />
  );
}

export default MortgageForm;
