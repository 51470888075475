import "./App.css";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import { LocalizationProvider } from "@mui/lab";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store from "store";
import { Provider } from "react-redux";
import PersonalClientInformation from "pages/clientPortfolio/personalClient/personalClientInformation";
import CreateEmployer from "pages/createEmployer/Employers";
import PersonalClientSummary from "pages/clientPortfolio/personalClient/personalClientSummary";
import PersonalClientAccounts from "pages/clientPortfolio/personalClient/personalClientAccounts";
import ClientSearch from "pages/clientSearch";
import ConfirmBox from "components/confirmBox";
import Snack from "components/snackbar";
import PersonalClientTransactions from "pages/transactions/personalClientTransactions/PersonalClientTransactions";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useEffect } from "react";
import GeneralLedgerPage from "pages/generalLedger";
import PersonalClientAccountHistory from "pages/accountHistory/personalAccountHistory";
import Lessons from "pages/lessons";
import Unauthorized from "pages/unauthorized";
import NoContent from "pages/noContent/NoContent";
import GlobalSettingsPage from "pages/admin/globalSettings";
import StudentSessionsPage from "pages/admin/studentSessions";
import OutstandingOverrides from "pages/admin/outstandingOverrides/OutstandingOverrides";
import SessionHistory from "pages/sessionHistory";
import CashDrawer from "pages/cashDrawer";
import BusinessClientInformation from "pages/clientPortfolio/businessClient/businessClientInformation";
import BusinessClientSummary from "pages/clientPortfolio/businessClient/businessClientSummary";
import BusinessClientAccounts from "pages/clientPortfolio/businessClient/businessClientAccounts";
import BusinessClientTransactions from "pages/transactions/businessClientTransactions";
import BusinessClientAccountHistory from "pages/accountHistory/businessAccountHistory";
import PersonalClientCreditApp from "pages/clientPortfolio/personalClient/personalClientCreditApp";
import PersonalClientInvesting from "pages/clientPortfolio/personalClient/personalClientInvesting";

const AuthApp = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect();
    }
  }, [isAuthenticated, inProgress, instance]);

  return !isAuthenticated ? (
    <></>
  ) : (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <Switch>
          <Route path="/lessons">
            <Lessons />
          </Route>
          <Route path="/globalsettings">
            <GlobalSettingsPage />
          </Route>
          <Route
            path="/studentsessions/:lessonId"
            render={({ match }) => (
              <StudentSessionsPage lessonId={match.params.lessonId} />
            )}
          />
          <Route path="/studentsessions">
            <StudentSessionsPage />
          </Route>
          <Route
            path="/outstandingoverrides/:lessonId"
            render={({ match }) => (
              <OutstandingOverrides lessonId={match.params.lessonId} />
            )}
          />
          <Route path="/outstandingoverrides">
            <OutstandingOverrides />
          </Route>
          <Route
            path="/:lessonId/clientportfolio/personalclient/clientsummary/:clientId/:studentId"
            render={({ match }) => (
              <PersonalClientSummary
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/personalclient/clientsummary/:clientId"
            render={({ match }) => (
              <PersonalClientSummary
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/personalclient/clientinformation/:clientId/:studentId"
            render={({ match }) => (
              <PersonalClientInformation
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/personalclient/clientinformation/:clientId"
            render={({ match }) => (
              <PersonalClientInformation
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/personalclient/clientinformation"
            render={({ match }) => (
              <PersonalClientInformation lessonId={match.params.lessonId} />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/personalclient/clientaccounts/:clientId/:studentId"
            render={({ match }) => (
              <PersonalClientAccounts
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />

          <Route
            path="/:lessonId/clientportfolio/personalclient/clientaccounts/:clientId"
            render={({ match }) => (
              <PersonalClientAccounts
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />

          <Route
            path="/:lessonId/clientportfolio/personalclient/creditapp/:clientId/:studentId"
            render={({ match }) => (
              <PersonalClientCreditApp
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/personalclient/creditapp/:clientId"
            render={({ match }) => (
              <PersonalClientCreditApp
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/personalclient/investing/:clientId/:studentId"
            render={({ match }) => (
              <PersonalClientInvesting
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/personalclient/investing/:clientId"
            render={({ match }) => (
              <PersonalClientInvesting
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/businessclient/clientsummary/:clientId/:studentId"
            render={({ match }) => (
              <BusinessClientSummary
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/businessclient/clientsummary/:clientId"
            render={({ match }) => (
              <BusinessClientSummary
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/businessclient/clientinformation/:clientId/:studentId"
            render={({ match }) => (
              <BusinessClientInformation
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/businessclient/clientinformation/:clientId"
            render={({ match }) => (
              <BusinessClientInformation
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/businessclient/clientinformation"
            render={({ match }) => (
              <BusinessClientInformation lessonId={match.params.lessonId} />
            )}
          />
          <Route
            path="/:lessonId/clientportfolio/businessclient/clientaccounts/:clientId/:studentId"
            render={({ match }) => (
              <BusinessClientAccounts
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />

          <Route
            path="/:lessonId/clientportfolio/businessclient/clientaccounts/:clientId"
            render={({ match }) => (
              <BusinessClientAccounts
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />

          <Route
            path="/:lessonId/employers/:studentId"
            render={({ match }) => (
              <CreateEmployer
                lessonId={match.params.lessonId}
                studentId={match.params.studentId}
              />
            )}
          />

          <Route
            path="/:lessonId/employers"
            render={({ match }) => (
              <CreateEmployer lessonId={match.params.lessonId} />
            )}
          />
          <Route
            path="/:lessonId/searchclient/:studentId"
            render={({ match }) => (
              <ClientSearch
                lessonId={match.params.lessonId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/searchclient"
            render={({ match }) => (
              <ClientSearch lessonId={match.params.lessonId} />
            )}
          />

          <Route
            path="/:lessonId/sessionhistory/:studentId"
            render={({ match }) => (
              <SessionHistory
                lessonId={match.params.lessonId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/sessionhistory"
            render={({ match }) => (
              <SessionHistory lessonId={match.params.lessonId} />
            )}
          />
          <Route
            path="/:lessonId/cashdrawer/:studentId"
            render={({ match }) => (
              <CashDrawer
                lessonId={match.params.lessonId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/cashdrawer"
            render={({ match }) => (
              <CashDrawer lessonId={match.params.lessonId} />
            )}
          />

          <Route
            path="/:lessonId/personalclient/transactions/:clientId/:studentId"
            render={({ match }) => (
              <PersonalClientTransactions
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/personalclient/transactions/:clientId"
            render={({ match }) => (
              <PersonalClientTransactions
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />
          <Route
            path="/:lessonId/businessclient/transactions/:clientId/:studentId"
            render={({ match }) => (
              <BusinessClientTransactions
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/businessclient/transactions/:clientId"
            render={({ match }) => (
              <BusinessClientTransactions
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
              />
            )}
          />
          <Route
            path="/:lessonId/personalclient/accounthistory/:clientId/:accountId/:studentId"
            render={({ match }) => (
              <PersonalClientAccountHistory
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                accountId={match.params.accountId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/personalclient/accounthistory/:clientId/:accountId"
            render={({ match }) => (
              <PersonalClientAccountHistory
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                accountId={match.params.accountId}
              />
            )}
          />
          <Route
            path="/:lessonId/businessclient/accounthistory/:clientId/:accountId/:studentId"
            render={({ match }) => (
              <BusinessClientAccountHistory
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                accountId={match.params.accountId}
                studentId={match.params.studentId}
              />
            )}
          />
          <Route
            path="/:lessonId/businessclient/accounthistory/:clientId/:accountId"
            render={({ match }) => (
              <BusinessClientAccountHistory
                lessonId={match.params.lessonId}
                clientId={match.params.clientId}
                accountId={match.params.accountId}
              />
            )}
          />

          <Route
            path="/:lessonId/generalledger/:studentId"
            render={({ match }) => (
              <GeneralLedgerPage
                lessonId={match.params.lessonId}
                studentId={match.params.studentId}
              />
            )}
          />

          <Route
            path="/:lessonId/generalledger"
            render={({ match }) => (
              <GeneralLedgerPage lessonId={match.params.lessonId} />
            )}
          />
          <Route path="/unauthorized">
            <Unauthorized />
          </Route>
          <Route path="/nocontent">
            <NoContent />
          </Route>
          <Route path="/">
            <Lessons />
          </Route>
        </Switch>
      </Router>
      <ConfirmBox />
      <Snack />
    </LocalizationProvider>
  );
}

export default AuthApp;
