import { Button, Paper } from "@mui/material";
import {
  getBusinessClientOperatingAs,
  getPersonalClientName,
  isPersonalClient,
} from "common/functions";
import {
  BusinessClientInformationRoute,
  PersonalClientInformationRoute,
} from "components/paths";
import styled from "styled-components";
import { BusinessClient, PersonalClient } from "types";
import AccountInfoSection from "components/clientInfoTemplate/AccountInfoSection";

export type Props = {
  client: PersonalClient | BusinessClient;
  studentId?: string;
  lessonId: string;
};

export default function ClientInfoSection(props: Props) {
  const clientName = isPersonalClient(props.client)
    ? getPersonalClientName(props.client as PersonalClient)
    : getBusinessClientOperatingAs(props.client as BusinessClient);

  const clientId = isPersonalClient(props.client)
    ? (props.client as PersonalClient).identification?.clientId
    : (props.client as BusinessClient).information?.clientId;

  const activeAccounts = props.client.accounts?.filter(
    (a) => !a.closeDate || a.closeDate.toLowerCase() === "invalid date"
  );
  return (
    <>
      <ColumnHeading>Client Information</ColumnHeading>
      <ColumnContent>
        <TwoColumnDisplay label="Name" value={clientName} />
        <TwoColumnDisplay label="Client ID" value={clientId ?? ""} />
        <TwoColumnDisplay
          label="Type"
          value={props.client.information?.accountType ?? ""}
        />
        {clientId && (
          <DetailedInfo>
            <Button
              onClick={() => {
                isPersonalClient(props.client)
                  ? !!props.studentId
                    ? window.open(
                        `/${props.lessonId}${PersonalClientInformationRoute}/${clientId}/${props.studentId}`
                      )
                    : window.open(
                        `/${props.lessonId}${PersonalClientInformationRoute}/${clientId}`
                      )
                  : !!props.studentId
                  ? window.open(
                      `/${props.lessonId}${BusinessClientInformationRoute}/${clientId}/${props.studentId}`
                    )
                  : window.open(
                      `/${props.lessonId}${BusinessClientInformationRoute}/${clientId}`
                    );
              }}
            >
              Detailed Information
            </Button>
          </DetailedInfo>
        )}
      </ColumnContent>
      <ColumnHeading style={{ marginTop: "25px" }}>
        Account Information
      </ColumnHeading>
      <ColumnContent>
        <AccountInfoSection
          activeAccounts={activeAccounts ?? []}
          clientType={props.client.information?.accountType ?? ""}
          clientId={clientId ?? ""}
          lessonId={props.lessonId}
          studentId={props.studentId}
        />
      </ColumnContent>
    </>
  );
}

function TwoColumnDisplay(props: { label: string; value: string }) {
  return (
    <RowContainer>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </RowContainer>
  );
}
const Label = styled.label`
  color: #0000008a;
  width: 100px;
  padding: 0 1em;
  font-size: 14px;
`;
const Value = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  width: 100px;
  padding: 0 1em;
`;
const RowContainer = styled.div`
  padding: 6px 3px 0px 3px;
  display: flex;
  min-width: 250px;
`;

const ColumnHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;
const DetailedInfo = styled.div`
  text-align: right;
  margin-top: 1em;
`;
const ColumnContent = styled(Paper)`
  padding-top: 0.5em;
`;
