import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import styled from "styled-components";
import React from "react";
import RangeOutcomeImage from "./images/RangeOutcomes.png";

export default function RangeOutcomeChart() {
  return (
    <CustomTooltip
      title={
        <>
          <img
            src={RangeOutcomeImage}
            alt="Graph showing range of possible outcomes"
            width="600px"
            height="303px"
          />
          <p>
            Reference:
            https://mfda.ca/wp-content/uploads/2022/08/Range-Outcomes.png
          </p>
        </>
      }
    >
      <InfoIcon sx={{ color: "#1976d2" }} />
    </CustomTooltip>
  );
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "black",
    maxWidth: 700,
  },
}));
