import styled from "styled-components";
import { FieldError, PersonalClient } from "types";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/clientPortfolio/personalClient/personalClientInvesting/investingFieldDefinitions";
import NotSetDisplay from "components/fields/NotSetDisplay";
import { ReactNode } from "react";
import { getMaskedMoneyValue } from "common/functions";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};
export default function GoalsSection(props: CommonProps) {
  function getSavingsPerMonthBasedOnProjectionDate(
    projectionDate: string,
    amount: number
  ) {
    const date = new Date(projectionDate);
    const months =
      (date.getFullYear() - new Date().getFullYear()) * 12 +
      (date.getMonth() - new Date().getMonth());
    return months > 0 ? amount / months : amount;
  }

  function getProjectionDateBasedOnSavingPerMonth(
    savingsPerMonth: number,
    amount: number
  ) {
    if (!!savingsPerMonth && !!amount && savingsPerMonth > 0 && amount > 0) {
      const months = amount / savingsPerMonth;
      const date = new Date();
      date.setMonth(date.getMonth() + months);
      return date.toString() === "Invalid Date"
        ? undefined
        : date.toLocaleDateString();
    }
    return undefined;
  }

  return (
    <Container>
      <Header>
        <SectionTitle>Goal</SectionTitle>
      </Header>
      <SectionContainer>
        <Column>
          <FieldEditor<PersonalClient> field={fields.overallGoal} {...props} />

          <FieldEditor<PersonalClient> field={fields.amount} {...props} />
        </Column>
        <Column>
          <FieldEditor<PersonalClient>
            field={fields.estimateBy}
            customStyle={{ margin: "-0.25em 0" }}
            {...props}
          />

          {props.state.investing?.estimateBy === "Date" && (
            <EstimateContainer>
              <FieldEditor<PersonalClient>
                field={fields.projectionDate}
                {...props}
                customStyle={{ width: "250px" }}
              />

              <TextDisplay
                label="Savings Per Month"
                value={
                  !!props.state.investing?.amount &&
                  props.state.investing?.amount > 0 &&
                  !!props.state.investing.projectionDate &&
                  props.state.investing.projectionDate.toLowerCase() !==
                    "invalid date" ? (
                    getMaskedMoneyValue(
                      getSavingsPerMonthBasedOnProjectionDate(
                        props.state.investing.projectionDate ?? "",
                        props.state.investing.amount ?? 0
                      )
                    )
                  ) : (
                    <NotSetDisplay text="N/A" />
                  )
                }
              />
            </EstimateContainer>
          )}

          {props.state.investing?.estimateBy === "Savings" && (
            <EstimateContainer>
              <FieldEditor<PersonalClient>
                field={fields.savingsPerMonth}
                {...props}
                customStyle={{ width: "250px" }}
              />
              <TextDisplay
                label="Projection Date"
                value={
                  getProjectionDateBasedOnSavingPerMonth(
                    props.state.investing.savingsPerMonth ?? 0,
                    props.state.investing.amount ?? 0
                  ) ?? <NotSetDisplay text="N/A" />
                }
              />
            </EstimateContainer>
          )}
        </Column>
      </SectionContainer>
    </Container>
  );
}

function TextDisplay(props: {
  label: string;
  value: string | number | ReactNode;
}) {
  return (
    <TextContainer>
      <Label>{props.label}</Label>
      <Value>{props.value}</Value>
    </TextContainer>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0em 0em 1em;
`;

const SectionTitle = styled.div`
  margin: 16px 0;
  font-size: 20px;
  font-weight: 500;
  color: #333;
  flex: 1;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  margin: 1em 1em 1em 1em;
  width: inherit;
`;

const Column = styled.div`
  min-width: 250px;
  margin: 10px;
`;

const Label = styled.label`
  color: #0000008a;
  font-size: 14px;
`;
const Value = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TextContainer = styled.div`
  margin-left: 20px;
  margin-top: 10px;
  min-width: 150px;
`;

const EstimateContainer = styled.div`
  display: flex;
  margin: -1.26em 0em;
`;
