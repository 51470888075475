import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AddressType, BusinessClient } from "types";
import styled from "styled-components";
import EditAddressHistoryItem from "pages/clientPortfolio/businessClient/businessClientInformation/components/EditAddressHistoryItem";
import AddAddressHstoryItem from "pages/clientPortfolio/businessClient/businessClientInformation/components/AddAddressHistoryItem";
import AddressHistoryList from "pages/clientPortfolio/businessClient/businessClientInformation/components/AddressHistoryList";

type ManageAddressProps = {
  client: BusinessClient;
  isReadOnlyView: boolean;
  onEditAddress(oldAddress: AddressType, updatedAddress: AddressType): void;
  onClose(): void;
  onAddAddress(newAddress: AddressType): void;
  onDeleteAddress(address: AddressType): void;
};

export default function ManageAddress(props: ManageAddressProps) {
  const [state, setState] = useState<BusinessClient>(props.client);

  type AddressDialogProps = {
    openNewAddressDialog: boolean;
    openEditAddressDialog: boolean;
    addressHistoryItem: AddressType;
  };

  const [
    addressDialogProps,
    setAddressDialogProps,
  ] = useState<AddressDialogProps>({
    openNewAddressDialog: false,
    openEditAddressDialog: false,
    addressHistoryItem: {},
  });

  const addHistory = state.information?.businessAddressHistory;

  useEffect(() => {
    setState(props.client);
  }, [props.client]);

  return (
    <div>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant={"h6"}>
          Manage Address
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            setAddressDialogProps({
              ...addressDialogProps,
              openNewAddressDialog: true,
            });
          }}
          disabled={props.isReadOnlyView}
        >
          Add
        </Button>
      </Header>

      <ContentContainer>
        <AddressHistoryList
          addressHistory={addHistory ?? []}
          isReadOnlyView={props.isReadOnlyView}
          onEdit={(addressHistoryItem) => {
            setAddressDialogProps({
              ...addressDialogProps,
              openEditAddressDialog: true,
              addressHistoryItem: addressHistoryItem,
            });
          }}
          onDeleteAddress={(address) => {
            props.onDeleteAddress(address);
          }}
        />
      </ContentContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            props.onClose();
          }}
        >
          Close
        </Button>
      </ButtonContainer>
      {addressDialogProps.openNewAddressDialog && (
        <AddAddressHstoryItem
          onCancel={() => {
            setAddressDialogProps({
              ...addressDialogProps,
              openNewAddressDialog: false,
            });
          }}
          onAdd={(businessNewAddress) => {
            props.onAddAddress(businessNewAddress);
            setAddressDialogProps({
              ...addressDialogProps,
              openNewAddressDialog: false,
            });
          }}
        />
      )}
      {addressDialogProps.openEditAddressDialog && (
        <EditAddressHistoryItem
          addressHistoryItem={addressDialogProps.addressHistoryItem}
          onEditAddressHistoryItem={(oldAddress, updatedAddress) => {
            props.onEditAddress(oldAddress, updatedAddress);
            setAddressDialogProps({
              ...addressDialogProps,
              openEditAddressDialog: false,
            });
          }}
          onCancel={() => {
            setAddressDialogProps({
              ...addressDialogProps,
              openEditAddressDialog: false,
            });
          }}
        />
      )}
    </div>
  );
}

const Header = styled.div`
  display: flex;
  padding: 1em 0.25em;
`;

const ContentContainer = styled.div`
  min-height: 200px;
  min-width: 600px;
  justify-content: stretch;
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 2em auto 0em auto;
  justify-content: center;
`;
