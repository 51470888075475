import { Typography } from "@mui/material";
import { AppBar } from "@nait-aits/ui";
import { getLoginInfo } from "common/azureAD";
import UserInfo from "components/user/UserInfo";
import { useEffect, useState } from "react";
import styled from "styled-components";
import SearchTextField from "components/searchTextField/SearchTextField";
import { useActions, useReduxState } from "store";
import { LessonListItem } from "types";
import LessonList from "pages/lessons/components/LessonList";
import { useHistory } from "react-router";
import AddLesson from "pages/lessons/components/AddLesson";
import { openSnackbar } from "components/snackbar";

export type Props = {};

type State = {
  searchText: string;
};

export default function Lessons(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state, setState] = useState<State>({ searchText: "" });
  const [lessonLIst, setLessonList] = useState<LessonListItem[]>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const loginInfo = getLoginInfo();
  const lessonActions = useActions().lesson;
  const globalSettingsActions = useActions().globalsettings;
  const lessonState = useReduxState((e) => e.lesson);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);

    lessonActions.getLessonList({
      onComplete: (list) => {
        setLessonList(list);
        setIsLoading(false);
      },
    });

    globalSettingsActions.getGlobalSettings({});
  }, []);

  function openLesson(LessonId: number) {
    lessonActions.clearReduxStateLessonNameAndData();
    history.push(`/${LessonId}/searchclient`);
    //setIsLoading(true);
    // lessonActions.getLessonNameAndData({
    //   id: LessonId,
    //   onComplete: (payload) => {
    //     setIsLoading(false);
    //     history.push(`/${LessonId}/searchclient`);
    //   },
    // });
  }

  useEffect(() => {
    setLessonList(lessonState.getLessonList.data);
  }, [lessonState.getLessonList]);

  function addLesson(lessonName: string, copyFromLessonId: number) {
    setIsLoading(true);
    lessonActions.addLesson({
      lessonName: lessonName,
      id: copyFromLessonId,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.getLessonList({
            onComplete: (list) => {
              setLessonList(list);
              openSnackbar({
                severity: "success",
                message: `Lesson added successfully`,
              });
              setIsLoading(false);
            },
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Lesson not added. Please try again!`,
          });
          setIsLoading(false);
        }
      },
    });
  }

  function resetLesson(lessonId: number) {
    setIsLoading(true);
    lessonActions.resetLesson({
      id: lessonId,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.getLessonList({
            onComplete: (list) => {
              setLessonList(list);
              openSnackbar({
                severity: "success",
                message: `Lesson reset successfully`,
              });
              setIsLoading(false);
            },
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error resetting the lesson. Please refresh the page and try again!`,
          });
          setIsLoading(false);
        }
      },
    });
  }

  function deleteLesson(lessonId: number) {
    setIsLoading(true);
    lessonActions.deleteLesson({
      id: lessonId,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.removeLessonFromReduxLessonList(lessonId);
          openSnackbar({
            severity: "success",
            message: `Lesson deleted successfully`,
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error deleting the lesson. Please try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }

  function editLessonName(lessonId: number, updatedLessonName: string) {
    setIsLoading(true);
    lessonActions.editLessonName({
      updatedLessonName: updatedLessonName,
      id: lessonId,
      onComplete: (payload) => {
        if (payload.success) {
          setLessonList(payload.lessonList);
          lessonActions.updateReduxLessonList(payload.lessonList);
          openSnackbar({
            severity: "success",
            message: `Lesson name updated successfully`,
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error updating lesson name. Please try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }
  function updateTransactionsMonths(lessonId: number, numberofMonths: number) {
    setIsLoading(true);
    lessonActions.updateTransactionsMonths({
      id: lessonId,
      numberOfMonths: numberofMonths,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.updateLastModifiedReduxLessonList(lessonId);
          openSnackbar({
            severity: "success",
            message: `Transactions updated successfully`,
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error updating transactions. Please try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }

  const filteredList =
    lessonLIst && lessonLIst?.length > 0
      ? lessonLIst.filter((c) =>
          c.lessonName?.toLowerCase().includes(state.searchText.toLowerCase())
        )
      : [];

  return (
    <OuterContainer>
      <AppBar
        headingText="NAITLAB Financial"
        rightContent={<UserInfo {...loginInfo} />}
        isLoading={isLoading}
      />
      <SectionContainer>
        <div style={{ marginTop: "10em", padding: "0em 2em" }}>
          <Typography variant="h4">Welcome, {loginInfo?.fullName}!</Typography>

          <ListContainer>
            <SearchFieldContainer>
              <SearchTextField
                value={state.searchText}
                label="Search lesson name"
                onChange={(text) => setState({ ...state, searchText: text })}
              />
            </SearchFieldContainer>
            <LessonList
              lessonList={filteredList}
              onOpenClick={(id) => {
                openLesson(id);
              }}
              onAddClick={() => {
                setOpenDialog(true);
              }}
              isInstructor={loginInfo?.isInstructor ?? false}
              onResetLesson={(lessonId) => {
                resetLesson(lessonId);
              }}
              onDelete={(lessonId) => {
                deleteLesson(lessonId);
              }}
              onEditSave={(lessonId, updatedLessonName) => {
                editLessonName(lessonId, updatedLessonName);
              }}
              onAdvanceMonths={(lessonId, numberOfMonths) => {
                updateTransactionsMonths(lessonId, numberOfMonths);
              }}
              isLoading={isLoading}
            />
          </ListContainer>
        </div>

        {openDialog && loginInfo?.isInstructor && (
          <AddLesson
            lessonList={lessonLIst ?? []}
            onSave={(lessonName, copyFromLessonId) => {
              addLesson(lessonName, copyFromLessonId);
              setOpenDialog(false);
            }}
            onCancel={() => {
              setOpenDialog(false);
            }}
          />
        )}
      </SectionContainer>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  background-color: #f3f6f9;
  min-height: 100vh;
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2em;
  min-height: 600px;
`;

const SearchFieldContainer = styled.div`
  padding: 1em 0.25em;
  width: 500px;
  margin: 2em auto;
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
