import { Divider, IconButton } from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import { openConfirm } from "components/confirmBox";
import { BillPayment } from "types";
import DeleteIcon from "@mui/icons-material/Delete";
import SummaryRowTemplate from "pages/transactions/components/SummaryRowTemplate";
import styled from "styled-components";

type SummaryRowBillPaymentProps = {
  transaction: BillPayment;
  onDelete(): void;
  isReadOnlyView: boolean;
};

export default function SummaryRowBillPayment(
  props: SummaryRowBillPaymentProps
) {
  const totalAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  return (
    <SummaryRowTemplate transaction="Bill Payment" credit={`${totalAmount}`}>
      <Divider />
      <DetailsRow isBold>
        <TextSection style={{ textAlign: "center" }}>Payee</TextSection>
        <TextSection style={{ textAlign: "center" }}>
          Account Number
        </TextSection>
        <TextSection style={{ textAlign: "center" }}>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection
          style={{ width: 150, overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {props.transaction.payee}
        </TextSection>
        <TextSection>{props.transaction.payeeAccountNumber}</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${totalAmount} CAD`}
        </TextSection>
      </DetailsRow>
      <DetailsRow isBold={true}>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          <IconButton
            style={{ margin: "-1em 0" }}
            aria-label="delete"
            onClick={() => {
              openConfirm({
                title: "Delete Transaction?",
                description: `Bill Payment of ${totalAmount} to ${props.transaction.payee}`,
                callback: () => {
                  props.onDelete();
                },
              });
            }}
            disabled={props.isReadOnlyView}
          >
            <DeleteIcon />
          </IconButton>
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
