import { DateField, MASK_TYPE, PickListField, RadioGroupField } from "types";
import { FIELD_TYPE, PersonalClient, TextFieldType } from "types";
export const overallGoal: PickListField<PersonalClient> = {
  id: `overallGoal`,
  label: "Overall Goal",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.overallGoal,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, overallGoal: value},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "homePurchase", name: "Home Purchase" },
    { id: "education", name: "Education" },
    { id: "vehicle", name: "Vehicle" },
    { id: "vacation", name: "Vacation" },
    { id: "wedding", name: "Wedding" },
    { id: "giftOrDonation", name: "Gift or Donation" },
    { id: "meJustMe", name: "Me. Just Me" },
    { id: "retirement", name: "Retirement" },
    { id: "other", name: "Other" },
  ],
 isRequired: true,
};

export const amount: TextFieldType<PersonalClient> = {
  id: `amount`,
  label: "Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.investing?.amount?.toString(),
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, amount: value as unknown as number,
  },
  }),
  mask: MASK_TYPE.MONEY,
  isRequired: true,
};

export const estimateBy: RadioGroupField<PersonalClient> = {
  id: `estimateBy`,
  label: "Estimate By",
  trueLabel: "Date",
  falseLabel: "Savings",
  defaultValue: "Date",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (client: PersonalClient) => client.investing?.estimateBy,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, estimateBy: value,
    projectionDate: undefined,
    savingsPerMonth: undefined,},
  }),
 
};



export const savingsPerMonth: TextFieldType<PersonalClient> = {
  id: `savingsPerMonth`,
  label: "Savings Per Month",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.investing?.savingsPerMonth?.toString(),
  setValue: (value, client) => ( {
    ...client,
    investing: {...client.investing, 
      savingsPerMonth: value as unknown as number,
    },
  }
  ),

 mask: MASK_TYPE.MONEY,
 checkIfRequired: (client: PersonalClient) => {   
  return client.investing?.estimateBy === "Savings"?? false
},
};



export const projectionDate: DateField<PersonalClient> = {
  id: `projectionDate`,
  label: "Projection Date",
  type: FIELD_TYPE.DATE,
  getValue: (client: PersonalClient) => client.investing?.projectionDate,
  setValue: (value, client) => ( {
    ...client,
    investing: {...client.investing, 
      projectionDate: value,
    },
  }),
  disablePastDates: true,
  checkIfRequired: (client: PersonalClient) => {   
    return client.investing?.estimateBy === "Date"?? false
  },
};

// Investment Profile field definitions

// section 0

export const accountType: PickListField<PersonalClient> = {
  id: `accountType`,
  label: "Account Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.accountType,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, accountType: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "rsp", name: "RSP" },
    { id: "nonrsp", name: "Non-RSP" },
    { id: "tfsa", name: "TFSA" },
    { id: "resp", name: "RESP" },
    { id: "rif", name: "RIF" },
  ],
  isRequired: true,
};

export const investmentKnowledge: PickListField<PersonalClient> = {
  id: `investmentKnowledge`,
  label: "Investment Knowledge",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.investmentKnowledge,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, investmentKnowledge: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "verylittle", name: "Very Little" },
    { id: "moderate", name: "Moderate" },
    { id: "extensive", name: "Extensive" },
  ],
  isRequired: true,
};

export const initialDeposit: TextFieldType<PersonalClient> = {
  id: `initialDeposit`,
  label: "Initial Deposit",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.initialDeposit?.toString(),
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, initialDeposit: value as unknown as number}},
  }),
  mask: MASK_TYPE.MONEY,
 isRequired: true,
};

export const investmentObjective: PickListField<PersonalClient> = {
  id: `investmentObjective`,
  label: "Investment Objective",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.investmentObjective,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, investmentObjective: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "safety", name: "Safety" },
    { id: "income", name: "Income" },
    { id: "balanced", name: "Balanced" },
    { id: "growth", name: "Growth" },
  ],
  isRequired: true,
};

// section 1 - Time Horizon

export const timeHorizon: PickListField<PersonalClient> = {
  id: `timeHorizon`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.timeHorizon,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, timeHorizon: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "lessthan1year", name: "Less than 1 year" },
    { id: "1-3years", name: "1-3 years" },
    { id: "4-6years", name: "4-6 years" },
    { id: "7-9years", name: "7-9 years" },
    { id: "10yearsOrMore", name: "10 years or more" },
  ],
  isRequired: true,
  hideLabel: true,
};


// section 2 - Risk Capacity

export const annualIncome: PickListField<PersonalClient> = {
  id: `annualIncome`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.annualIncome,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, annualIncome: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "0", name: "Less than $25,000" },
    { id: "2", name: "$25,000 - $49,999" },
    { id: "4", name: "$50,000 - $74,999" },
    { id: "5", name: "$75,000 - $99,999" },
    { id: "7", name: "$100,000 - $199,999" },
    { id: "10", name: "$200,000 or more" },
  ],
  isRequired: true,
  hideLabel: true,
};




export const incomeSourcesStability: PickListField<PersonalClient> = {
  id: `incomeSourcesStability`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.incomeSourcesStability,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, incomeSourcesStability: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "8", name: "Stable" },
    { id: "4", name: "Somewhat stable" },
    { id: "1", name: "Unstable" },
  ],
  isRequired: true,
  hideLabel: true,
};


export const overallFinancialSituation: PickListField<PersonalClient> = {
  id: `overallFinancialSituation`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.overallFinancialSituation,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, overallFinancialSituation: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "0", name: "No savings and significant debt" },
    { id: "2", name: "Little savings and a fair amount of debt" },
    { id: "5", name: "Some savings and some debt" },
    { id: "7", name: "Some savings and little or no debt" },
    { id: "10", name: "Significant savings and little or no debt" },
  ],
  isRequired: true,
  hideLabel: true,
  autoWidth: true,
};

export const estimatedNetWorth: PickListField<PersonalClient> = {
  id: `estimatedNetWorth`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.estimatedNetWorth,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, estimatedNetWorth: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "0", name: "Less than $50,000" },
    { id: "2", name: "$50,000 - $99,999" },
    { id: "4", name: "$100,000 - $249,999" },
    { id: "6", name: "$250,000 - $499,999" },
    { id: "8", name: "$500,000 - $999,999" },
    { id: "10", name: "$1,000,000 or more" },
  ],
  isRequired: true,
  hideLabel: true,
};

export const investmentAccountPercentage: PickListField<PersonalClient> = {
  id: `investmentAccountPercentage`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.investmentAccountPercentage,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, investmentAccountPercentage: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "10", name: "Less than 25%" },
    { id: "5", name: "25% - 50%" },
    { id: "4", name: "51% - 75%" },
    { id: "2", name: "More than 75%" },
  ],
  isRequired: true,
  hideLabel: true,
};


export const ageGroup: PickListField<PersonalClient> = {
  id: `ageGroup`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.ageGroup,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, ageGroup: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "20", name: "Under 35" },
    { id: "8", name: "35 - 54" },
    { id: "3", name: "55 - 64" },
    { id: "1", name: "65 or older" },
  ],
  isRequired: true,
  hideLabel: true,
};

// section 3 - Risk Attitude

export const personType: PickListField<PersonalClient> = {
  id: `personType`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.personType,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, personType: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "0", name: "Very conservative and try to minimize risk and avoid the possibility of any loss" },
    { id: "4", name: "Conservative but willing to accept a small amount of risk" },
    { id: "6", name: "Willing to accept a moderate level of risk and tolerate losses to achieve potentially higher returns" },
    { id: "10", name: "Aggressive and typically take on significant risk and are willing to tolerate large losses for the potential of achieving higher returns" },
    
  ],
  isRequired: true,
  hideLabel: true,
  autoWidth: true,
};




export const tolerateDecline: PickListField<PersonalClient> = {
  id: `tolerateDecline`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.tolerateDecline,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, tolerateDecline: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "0", name: "I could not tolerate any loss" },
    { id: "3", name: "$300 (3%)" },
    { id: "6", name: "$1,000 (10%)" },
    { id: "8", name: "$2,000 (20%)" },
    { id: "10", name: "More than $2,000 (more than 20%)" },
  ],
  isRequired: true,
  hideLabel: true,
  autoWidth: true,
};


export const concernedForLossesGains: PickListField<PersonalClient> = {
  id: `concernedForLossesGains`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.concernedForLossesGains,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, concernedForLossesGains: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "0", name: "Always the possible losses" },
    { id: "3", name: "Usually the possible losses" },
    { id: "6", name: "Usually the possible gains" },
    { id: "10", name: "Always the possible gains" },
  ],
  isRequired: true,
  hideLabel: true,
};

export const investmentExample: PickListField<PersonalClient> = {
  id: `investmentExample`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.investmentExample,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, investmentExample: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "0", name: "EITHER a loss of $0 OR a gain of $200" },
    { id: "3", name: "EITHER a loss of $200 OR a gain of $500" },
    { id: "6", name: "EITHER a loss of $800 OR a gain of $1,200" },
    { id: "10", name: "EITHER a loss of $2,000 OR a gain of $2,500" },
  ],
  isRequired: true,
  hideLabel: true,
  autoWidth: true,
};

export const lossInInvestmentAction: PickListField<PersonalClient> = {
  id: `lossInInvestmentAction`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.lossInInvestmentAction,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, lossInInvestmentAction: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "0", name: "Sell all of the remaining investment to avoid further losses" },
    { id: "3", name: "Sell a portion of the remaining investment to protect some of your capital" },
    { id: "5", name: "Hold onto the investment and not sell any of the investment in the hopes of higher future returns" },
    { id: "10", name: "Buy more of the investment now that prices are lower" },
  ],
  isRequired: true,
  hideLabel: true,
  autoWidth: true,
};


export const investmentPortfolioExample: PickListField<PersonalClient> = {
  id: `investmentPortfolioExample`,
  label: "",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.investing?.investmentProfile?.investmentPortfolioExample,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, investmentProfile: {...client.investing?.investmentProfile, investmentPortfolioExample: value}},
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: [
    { id: "0", name: "Portfolio A" },
    { id: "4", name: "Portfolio B" },
    { id: "6", name: "Portfolio C" },
    { id: "10", name: "Portfolio D" },
  ],
  isRequired: true,
  hideLabel: true,
};

export const clientAuthorizationName: TextFieldType<PersonalClient> = {
  id: `clientAuthorizationName`,
  label: "Name",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.investing?.clientAuthorizationName,
  setValue: (value, client) => ({
    ...client,
    investing: {...client.investing, clientAuthorizationName: value},
  }),
  isRequired: true,
  
};