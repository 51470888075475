import { CustomAsset, FIELD_TYPE, MASK_TYPE, TextFieldType } from "types";

export const name: TextFieldType<CustomAsset> = {
    id: `name`,
    label: "Name",
    type: FIELD_TYPE.TEXT,
    getValue: (customAsset: CustomAsset) => customAsset.name,
    setValue: (value, customAsset) => ({
      ...customAsset,
      name: value,
    }),
    isRequired: true,
  };

  export const balance: TextFieldType<CustomAsset> = {
    id: `balance`,
    label: "Balance",
    type: FIELD_TYPE.TEXT,
    getValue: (customAsset: CustomAsset) => customAsset.balance?.toString(),
    setValue: (value, customAsset) => ({
      ...customAsset,
      balance:!!value && value !== ""? value  as unknown as number : undefined ,
    }),
    mask: MASK_TYPE.MONEY,
    isRequired: true,
    allowNegative: false,
  };