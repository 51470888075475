import {  provinceOptions } from "common/helpers";
import {
  AddressField, DateField,
  FIELD_TYPE, MASK_TYPE, BusinessClient, PickListField, TextFieldType
} from "types";

export const accountType: PickListField<BusinessClient> = {
  id: "accountType",
  label: "Account Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: BusinessClient) => client.information?.accountType,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, accountType: "Business" },
  }),
  disabled: true,
  options: [
    { id: "Business", name: "Business" },
  ],
  getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  
};

export const clientId: TextFieldType<BusinessClient> = {
  id: "clientId",
  label: "Client ID",
  type: FIELD_TYPE.TEXT,
  getValue: (client: BusinessClient) => client.information?.clientId,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, clientId: value },
  }),
  disabled: true,
};

export const bussinessType: PickListField<BusinessClient> = {
  id: "businessType",
  label: "Business Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: BusinessClient) => client.information?.businessType,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, businessType: value },
  }),
  options: [
    { id: "sole proprietorship", name: "Sole Proprietorship" },
    { id: "corporation", name: "Corporation" },
    { id: "partnership", name: "Partnership" },
  ],
  getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  isRequired: true,
};

export const businessNumber: TextFieldType<BusinessClient> = {
  id: "businessNumber",
  label: "Business Number",
  type: FIELD_TYPE.TEXT,
  getValue: (client: BusinessClient) => client.information?.businessNumber,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, businessNumber: value },
  }),  
};

export const legalBusinessName: TextFieldType<BusinessClient> = {
  id: "legalBusinessName",
  label: "Legal Business Name",
  type: FIELD_TYPE.TEXT,
  getValue: (client: BusinessClient) => client.information?.legalBusinessName,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, legalBusinessName: value },
  }),
};

export const incorporationRegistrationNumber: TextFieldType<BusinessClient> = {
  id: "incorporationRegistrationNumber",
  label: "Incorporation/Registration #",
  type: FIELD_TYPE.TEXT,
  getValue: (client: BusinessClient) => client.information?.incorporationRegistrationNumber,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, incorporationRegistrationNumber: value },
  }),
  isRequired: true,
};

export const operatingAs: TextFieldType<BusinessClient> = {
  id: "operatingAs",
  label: "Operating As",
  type: FIELD_TYPE.TEXT,
  getValue: (client: BusinessClient) => client.information?.operatingAs,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, operatingAs: value },
  }),
  isRequired: true,
};

export const dateEstablished: DateField<BusinessClient> = {
  id: "dateEstablished",
  label: "Date Established",
  type: FIELD_TYPE.DATE,
  getValue: (client: BusinessClient) => client.information?.dateEstablished,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, dateEstablished: value },
  }),
};

export const phoneNumber: TextFieldType<BusinessClient> = {
  id: "phoneNumber",
  label: "Phone Number",
  type: FIELD_TYPE.TEXT,
  getValue: (client: BusinessClient) => client.information?.phoneNumber,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, phoneNumber: value },
  }),
  mask: MASK_TYPE.PHONE_NUMBER,
  isRequired: true,
};



export const phoneNumberType: PickListField<BusinessClient> = {
  id: "phoneNumberType",
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: BusinessClient) => client.information?.phoneNumberType,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, phoneNumberType: value },
  }),
  options: [
    { id: "home", name: "Home" },
    { id: "mobile", name: "Mobile" },
    { id: "work", name: "Work" },
  ],
  getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  isRequired: true
};

export const businessNewAddress: AddressField<BusinessClient> = {
  id: "businessNewAddress",
  label: "Business Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (client: BusinessClient) => client.information?.businessNewAddress,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, businessNewAddress: value },
  }),
  isDated: true,
  options: provinceOptions,
  isRequired: true,
  multiColumnDisplay: true
};

export const businessCurrentAddress: AddressField<BusinessClient> = {
  id: "businessCurrentAddress",
  label: "Business Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (client: BusinessClient) => client.information?.businessCurrentAddress,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, businessCurrentAddress: value },
  }),
  isDated: true,
  isRequired: true,
  disabled: true,
  options: provinceOptions  
};

export const email: TextFieldType<BusinessClient> = {
  id: "email",
  label: "Email Address",
  type: FIELD_TYPE.TEXT,
  getValue: (client: BusinessClient) => client.information?.email,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, email: value },
  }),
  isRequired: true,
  maxLength: 50,
};



export const comment: TextFieldType<BusinessClient> = {
  id: "comment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (client: BusinessClient) => client.information?.comment,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, comment: value },
  }),
  multiline: true,
};
