import SearchTextField from "components/searchTextField/SearchTextField";
import { useState } from "react";
import styled from "styled-components";
import { Employer } from "types";
import EmployerList from "pages/createEmployer/components/EmployerList";

export type EmployerSearchListProps = {
  employers: Employer[];
  onAddClick(): void;
  onEditClick(employer: Employer): void;
  onDeleteClick(employerId: string): void;
  isReadOnlyView: boolean;
};

const EmployerSearchList = (props: EmployerSearchListProps) => {
  type ExistingEmployersState = {
    searchText: string;
  };
  const [
    existingEmployersState,
    setExistingEmployersState,
  ] = useState<ExistingEmployersState>({ searchText: "" });

  const filteredList = props.employers.filter(
    (e) =>
      e.employerName
        ?.toLowerCase()
        .includes(existingEmployersState.searchText.toLowerCase()) ||
      e.phoneNumber
        ?.toLowerCase()
        .includes(existingEmployersState.searchText.toLowerCase())
  );

  return (
    <SectionContainer>
      <SearchFieldContainer>
        <SearchTextField
          value={existingEmployersState.searchText}
          label="Search employer name or phone number"
          onChange={(text) =>
            setExistingEmployersState({
              ...existingEmployersState,
              searchText: text,
            })
          }
        />
      </SearchFieldContainer>
      <EmployerList {...props} employers={filteredList} />
    </SectionContainer>
  );
};

export default EmployerSearchList;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2em;
  min-height: 600px;
`;

const SearchFieldContainer = styled.div`
  padding: 1em 0.25em;
  width: 500px;
  margin: 2em 0em;
`;
