import { CommonFieldProps, Entity, DateTimeField } from "types";
import DateTimeFieldNonFieldEditor from "./DateTimeFieldNonFieldEditor";

export default function DateTimeFieldEditor<T extends Entity>({
  field,
  state,
  setState,
  errors,
  customStyle,
}: CommonFieldProps<T> & {
  customStyle?: React.CSSProperties;
}) {
  const dateTimeField = field as DateTimeField<T>;
  const errObj = errors.find((e) => e.id === field.id);

  return (
    <DateTimeFieldNonFieldEditor
      id={field.id}
      label={field.label}
      value={dateTimeField.getValue(state) ?? null}
      disabled={
        field.disabled ||
        (field.checkIfDisabled && field.checkIfDisabled(state))
      }
      onChange={(newValue) => {
        setState(dateTimeField.setValue(newValue, state));
      }}
      required={
        field.isRequired ||
        (field.checkIfRequired && field.checkIfRequired(state))
      }
      errObj={errObj}
      customStyle={customStyle}
    />
  );
}
