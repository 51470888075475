import styled from "styled-components";
import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";
import { useState } from "react";
import { v4 } from "uuid";
import { TableCell, TableRow } from "@mui/material";

type SupervisorOverrideRowTemplateProps = {
  transaction?: JSX.Element | string;
  debit?: JSX.Element | string;
  credit?: JSX.Element | string;
  details?: JSX.Element | string;
  reason?: JSX.Element | string;
  status?: JSX.Element | string;
  onApprove(): void;
  onReject(): void;
};

export default function SupervisorOverrideRowTemplate(
  props: SupervisorOverrideRowTemplateProps
) {
  const [overrideValue, setOverrideValue] = useState<string>("");
  return (
    <TableRow>
      <Cell>{props.transaction}</Cell>
      <Cell>{props.debit}</Cell>
      <Cell>{props.credit}</Cell>
      <Cell>{props.details}</Cell>
      <Cell>{props.reason}</Cell>
      <Cell>{props.status}</Cell>

      <Cell style={{ width: 110, paddingTop: "0em", paddingBottom: "0em" }}>
        {/* <div style={{ width: 110 }}> */}
        <PickListNonFieldEditor
          id={`Approve_Reject_Picklist_${v4()}`}
          label={`Override`}
          options={[
            { id: "approve", name: "Approve" },
            { id: "reject", name: "Reject" },
          ]}
          value={overrideValue}
          onChange={(value) => {
            setOverrideValue(value);
            if (value === "approve") {
              props.onApprove();
            } else if (value === "reject") {
              props.onReject();
            }
          }}
          menuWidth={100}
        />
        {/* </div> */}
      </Cell>
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  text-align: center !important;
`;
