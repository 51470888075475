import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { FieldError, IdName } from "types";
import { FieldContainer } from "./commonFieldStyles";

type Props = {
  id: string;
  label: string;
  value: string;
  onChange(value: string): void;
  options: IdName[];
  defaultValue?: string;
  required?: boolean | undefined;
  errObj?: FieldError | undefined;
  disabled?: boolean | undefined;
  optionsWithCategory?: boolean;
  primaryOptionCategoryLabel?: string;
  secondaryOptionCategoryLabel?: string;
  secondaryOptions?: IdName[];
  menuMaxHeight?: number;
  menuWidth?: number;
  autoWidth?: boolean;
  variant?: "filled" | "standard" | "outlined";
  hideLabel?: boolean;
};

export default function PickListNonFieldEditor(props: Props) {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: props.menuMaxHeight ?? 250,
        width: props.autoWidth ? "inherit" : props.menuWidth ?? 250,
      },
    },
  };

  return (
    <FieldContainer>
      <FormControl
        fullWidth
        variant={props.variant ?? "filled"}
        required={props.required}
        disabled={props.disabled}
        size="small"
      >
        {!props.hideLabel && (
          <InputLabel
            id="demo-simple-select-label"
            style={!!props.errObj ? { color: "#d32f2f" } : {}}
          >
            {props.label}
          </InputLabel>
        )}
        <Select
          labelId={`${props.id}_label`}
          id={props.id}
          defaultValue={props.defaultValue}
          value={props.value}
          label={props.label}
          fullWidth={props.autoWidth ? false : true}
          autoWidth={props.autoWidth}
          error={!!props.errObj}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          MenuProps={MenuProps}
        >
          {(props.optionsWithCategory === undefined ||
            props.optionsWithCategory === false) &&
            props.options?.map((mi, n) => (
              <MenuItem key={`option_${n}`} value={mi.id}>
                {mi.name}
              </MenuItem>
            ))}
          {!!props.optionsWithCategory && (
            <ListSubheader>{props.primaryOptionCategoryLabel}</ListSubheader>
          )}

          {!!props.optionsWithCategory &&
            props.options?.map((mi, n) => (
              <MenuItem key={`option_${n}`} value={mi.id}>
                {mi.name}
              </MenuItem>
            ))}

          {!!props.optionsWithCategory && (
            <ListSubheader>{props.secondaryOptionCategoryLabel}</ListSubheader>
          )}

          {!!props.optionsWithCategory &&
            props.secondaryOptions?.map((mi, n) => (
              <MenuItem key={`option_${n}`} value={mi.id}>
                {mi.name}
              </MenuItem>
            ))}
        </Select>
        {!!props.errObj && (
          <FormHelperText style={{ color: "#d32f2f" }}>
            {props.errObj.error}
          </FormHelperText>
        )}
      </FormControl>
    </FieldContainer>
  );
}
