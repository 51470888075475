import { Divider } from "@mui/material";
import { SellDraft } from "types";
import SummaryRowTemplate from "pages/sessionHistory/components/SummaryRowTemplate";
import styled from "styled-components";
import { getMaskedMoneyValue } from "common/functions";
import { useReduxState } from "store";
import moment from "moment";

type SummaryRowSellDraftProps = {
  transaction: SellDraft;
};

export default function SummaryRowSellDraft({
  transaction,
}: SummaryRowSellDraftProps) {
  const currencyType = transaction.currencyType ?? "";
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
  let transactionAmount = getMaskedMoneyValue(transaction.amount ?? 0);
  let draftAmount = getMaskedMoneyValue(transaction.draftAmount ?? 0);
  let fee = getMaskedMoneyValue(transaction.fee ?? 0);

  return (
    <SummaryRowTemplate
      date={moment(transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transaction="Sell Draft"
      credit={transactionAmount}
      details={
        transaction.draftAmountCADValue &&
        transaction.draftAmountCADValue >=
          (globalSettings.draftOverrideDenomination ?? 0) &&
        transaction.isApproved === true
          ? "Override Approved"
          : ""
      }
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection>Serial#</TextSection>
        <TextSection>Payee</TextSection>
        <TextSection>Amount</TextSection>
        <TextSection>Fee</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>{transaction.serialNumber}</TextSection>
        <TextSection>{transaction.payee}</TextSection>
        <TextSection>
          {`${draftAmount} ${currencyType.toUpperCase()}`}
        </TextSection>
        <TextSection>{`${fee} CAD`}</TextSection>
      </DetailsRow>

      <DetailsRow isBold={true}>
        <TextSection style={{ textAlign: "left" }}>Total</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${transactionAmount} CAD`}
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
  text-align: center;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;
