import { add, getMaturityDate, getPrimeRate, getRemainingTimePeriod } from "common/functions";
import { FIELD_TYPE, TextFieldType,DateField, MASK_TYPE, PickListField, RadioGroupField, Mortgage } from "types";
import { v4 } from "uuid"

export const mortgageOpenDate: DateField<Mortgage> = {
  id: `mortgageOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: Mortgage) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: Mortgage) => {   
  return account.isAccountClosed ?? false
}
};




export const mortgageCloseDate: DateField<Mortgage> = {
  id: `mortgageCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: Mortgage) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const mortgageAccountNumber: TextFieldType<Mortgage> = {
  id: `mortgageAccountNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Mortgage) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true,
};


export const mortgageBalance: TextFieldType<Mortgage> = {
  id: `mortgageBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Mortgage) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    balance: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true
};

export const mortgageInterestType: RadioGroupField<Mortgage> = {
  id: `mortgageInterestType_${v4()}`,
  label: "Interest Type",
  trueLabel: "Fixed",
  falseLabel: "Variable",
  defaultValue: "Fixed",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: Mortgage) => account.interestType,
  setValue: (value, account) => ({
    ...account,
    interestType: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: Mortgage) => {   
  return account.isAccountClosed ?? false
}
}; 


export const mortgagePrimeRate: TextFieldType<Mortgage> = {
  id: `mortgagePrimeRate_${v4()}`,
  label: "Prime Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Mortgage) => getPrimeRate(),
  setValue: (value, account) => ({
    ...account,
    primeRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE
};

export const mortgageInterestRate: TextFieldType<Mortgage> = {
  id: `mortgageInterestRate_${v4()}`,
  label: "Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Mortgage) => account.interestRate,
  setValue: (value, account) => ({
    ...account,
    interestRate: value,
  }),
  isRequired: true,
  mask: MASK_TYPE.PERCENTAGE,
  checkIfDisabled: (account: Mortgage) => {   
    return account.isAccountClosed ?? false
  },
  checkIfAllowNegative: (account : Mortgage) =>{
    return account.interestType === "Fixed"? false : true
  }
};


export const mortgageTotalInterestRate: TextFieldType<Mortgage> = {
  id: `mortgageTotalInterestRate_${v4()}`,
  label: "Total Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Mortgage) =>{
    let tir = add((account.interestRate?? 0) as unknown as number, (getPrimeRate() ?? 0) as unknown as number);
    if(tir > 0)
      return tir.toString();
    else
      return "0"
  } ,
  setValue: (value, account) => ({
    ...account,
    totalInterestRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE
};

export const mortgageType: RadioGroupField<Mortgage> = {
  id: `mortgageType_${v4()}`,
  label: "Mortgage Type",
  trueLabel: "Open",
  falseLabel: "Closed",
  defaultValue: "Open",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: Mortgage) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: Mortgage) => {   
  return account.isAccountClosed ?? false
}
}; 

function mortgageAmortizationOptions() {
  let a = [];
  for (let i = 1; i <= 25; i++) {
    a.push({ id: i.toString(), name: i.toString() });
  }
  return a;
}

export const mortgageAmortization: PickListField<Mortgage> = {
  id: `mortgageAmortization_${v4()}`,
  label: "Amortization (years)",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: Mortgage) => account.amortization,
  setValue: (value, account) => ({
    ...account,
    amortization: value,
  }),
  options: mortgageAmortizationOptions(),
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: Mortgage) => {   
  return account.isAccountClosed ?? false
}
};


export const mortgageMaturityDate: DateField<Mortgage> = {
  id: `mortgageMaturityDate_${v4()}`,
  label: "Expected payout date", //As per business request in project check-in meeting dated Oct 14,2022, changing the label to "Expected payout date" from "Maturity Date"
  type: FIELD_TYPE.DATE,
  getValue: (account: Mortgage) => account.openDate && account.amortization? getMaturityDate(account.openDate, account.amortization as unknown as number).toString() : "",
  setValue: (value, account) => ({
    ...account,
    maturityDate:value,
  }),
  disabled: true
};

export const mortgageRemainingAmortization: TextFieldType<Mortgage> = {
  id: `mortgageRemainingAmortization_${v4()}`,
  label: "Remaining Amortization (years)",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Mortgage) => account.openDate && account.amortization? getRemainingTimePeriod(account.openDate, account.amortization as unknown as number).toFixed(1) : "",
  setValue: (value, account) => ({
    ...account,
    remainingAmortization: value,
  }),
  disabled: true,
};

export const mortgageTermStartDate: DateField<Mortgage> = {
  id: `mortgageTermStartDate_${v4()}`,
  label: "Term Start Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: Mortgage) => account.termStartDate,
  setValue: (value, account) => ({
    ...account,
    termStartDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: Mortgage) => {   
  return account.isAccountClosed ?? false
}
};


export const mortgageTermLength: PickListField<Mortgage> = {
  id: `mortgageTermLength_${v4()}`,
  label: "Term Length (years)",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: Mortgage) => account.termLength,
  setValue: (value, account) => ({
    ...account,
    termLength: value,
  }),
  options: [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
  ],
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: Mortgage) => {   
  return account.isAccountClosed ?? false
}
};

export const mortgageRemainingTermLength: TextFieldType<Mortgage> = {
  id: `mortgageRemainingTermLength_${v4()}`,
  label: "Remaining Term Length (years)",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Mortgage) => account.termStartDate && account.termStartDate.toLowerCase() !== "invalid date" && account.termLength? getRemainingTimePeriod(account.termStartDate, account.termLength as unknown as number).toString() : "",
  setValue: (value, account) => ({
    ...account,
    remainingTermLength: value,
  }),
  disabled: true,
};



export const mortgagePaymentAmount: TextFieldType<Mortgage> = {
  id: `mortgagePaymentAmount_${v4()}`,
  label: "Payment Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Mortgage) => account.paymentAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    paymentAmount: value as unknown as number,
  }),
  mask: MASK_TYPE.MONEY,
  isRequired: true,
  checkIfDisabled: (account: Mortgage) => {   
    return account.isAccountClosed ?? false
  }
};

export const mortgagePaymentFrequency: PickListField<Mortgage> = {
  id: `mortgagePaymentFrequency_${v4()}`,
  label: "Payment Frequency",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: Mortgage) => account.paymentFrequency,
  setValue: (value, account) => ({
    ...account,
    paymentFrequency: value,
  }),
  options: [
    { id: "monthly", name: "Monthly" },
    { id: "semimonthly", name: "Semi-Monthly" },
    { id: "biweekly", name: "Bi-Weekly" },
    { id: "weekly", name: "Weekly" }
  ],
  isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: Mortgage) => {   
  return account.isAccountClosed ?? false
}
};

