import { Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FieldError, LessonSettings } from "types";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/admin/studentSessions/lessonSettingsFieldDefinitions";
import { requiredDateTimeField } from "common/functions";
import moment from "moment";
export type Props = {
  selectedLessonName: string;
  isLoading: boolean;
  lessonSettings?: LessonSettings;
  onSave: (lessonSettings: LessonSettings) => void;
};

const LessonSettingsSection = (props: Props) => {
  const [state, setState] = useState<LessonSettings>(
    props.lessonSettings ?? { isTimeBasedAccess: false }
  );
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    setState(props.lessonSettings ?? { isTimeBasedAccess: false });
  }, [props.lessonSettings]);
  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: LessonSettings) => {
      setState(state);
    },
  };

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredDateTimeField(fields.startDateTime, state),
      ...requiredDateTimeField(fields.endDateTime, state),
    ];

    if (
      state.endDateTime &&
      state.startDateTime &&
      (moment(state.endDateTime).isBefore(moment(state.startDateTime)) ||
        moment(state.endDateTime).isSame(moment(state.startDateTime)))
    ) {
      let err: FieldError = {
        id: fields.endDateTime.id,
        label: fields.endDateTime.label,
        error: "Must be after Start Date Time",
      };
      errorsArray.push(err);
    }

    setErrors(errorsArray);
    return errorsArray;
  }

  function onSave() {
    const err = checkErrors();
    if (err?.length === 0) {
      props.onSave(state);
    }
  }

  return (
    <Container>
      <Header>
        <SectionTitle> Lesson Settings</SectionTitle>
        <Button
          onClick={onSave}
          disabled={
            props.selectedLessonName === undefined ||
            props.selectedLessonName === "this lesson" ||
            props.isLoading
          }
        >
          Save
        </Button>
      </Header>
      <FieldsContainer>
        <FieldEditor<LessonSettings>
          field={fields.isTimeBasedAccess}
          {...commonProps}
          isDependentlyDisabled={
            props.selectedLessonName === "this lesson" ||
            props.selectedLessonName === undefined ||
            props.isLoading
          }
        />
        <DateTimeFieldsContainer>
          <FieldEditor<LessonSettings>
            field={fields.startDateTime}
            {...commonProps}
          />
          <FieldEditor<LessonSettings>
            field={fields.endDateTime}
            {...commonProps}
          />
        </DateTimeFieldsContainer>
        {state.isTimeBasedAccess && (
          <div style={{ marginLeft: "40px", fontSize: "14px", color: "gray" }}>
            Start and End Date Times are in Local Timezone
          </div>
        )}
      </FieldsContainer>
    </Container>
  );
};

export default LessonSettingsSection;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
`;

const SectionTitle = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5em;
`;

const DateTimeFieldsContainer = styled.div`
  width: 300px;
  padding-left: 2.5em;
  margin-top: -1em;
`;

const Container = styled(Paper)`
  margin-top: 2em;
  min-height: 350px;
  min-width: inherit;
`;
