import { convertLocalToUTC, convertUTCToLocal } from "common/functions";
import { FIELD_TYPE, LessonSettings, BoolField, DateTimeField } from "types";

export const isTimeBasedAccess: BoolField<LessonSettings> = {
  id: "isTimeBasedAccess",
  label: "Time Based Lesson Access",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (settings: LessonSettings) => settings.isTimeBasedAccess ?? false,
  setValue: (value, settings) => ({
    ...settings,
    isTimeBasedAccess: value,
    startDateTime: value ? settings.startDateTime : undefined,
    endDateTime: value ? settings.endDateTime : undefined,
  }),
  labelPlacement: "end",
};

export const startDateTime: DateTimeField<LessonSettings> = {
  id: "startDateTime",
  label: "Start Date Time",
  type: FIELD_TYPE.DATETIME,
  getValue: (settings: LessonSettings) =>
    settings.startDateTime
      ? convertUTCToLocal(settings.startDateTime)
      : undefined,
  setValue: (value, settings) => ({
    ...settings,
    startDateTime: value ? convertLocalToUTC(value) : undefined,
    endDateTime: settings.endDateTime
      ? settings.endDateTime.endsWith("Z")
        ? settings.endDateTime
        : `${settings.endDateTime}Z`
      : settings.endDateTime,
  }),
  checkIfRequired: (settings: LessonSettings) => settings.isTimeBasedAccess,
  checkIfDisabled: (settings: LessonSettings) => !settings.isTimeBasedAccess,
};

export const endDateTime: DateTimeField<LessonSettings> = {
  id: "endDateTime",
  label: "End Date Time",
  type: FIELD_TYPE.DATETIME,
  getValue: (settings: LessonSettings) =>
    settings.endDateTime ? convertUTCToLocal(settings.endDateTime) : undefined,
  setValue: (value, settings) => ({
    ...settings,
    endDateTime: value ? convertLocalToUTC(value) : undefined,
    startDateTime: settings.startDateTime
      ? settings.startDateTime.endsWith("Z")
        ? settings.startDateTime
        : `${settings.startDateTime}Z`
      : settings.endDateTime,
  }),
  checkIfRequired: (settings: LessonSettings) => settings.isTimeBasedAccess,
  checkIfDisabled: (settings: LessonSettings) => !settings.isTimeBasedAccess,
};
