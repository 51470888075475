import { Divider, Typography } from "@mui/material";
import styled from "styled-components";
import LiabilitiesDisplay from "components/clientInfoTemplate/LiabilitiesDisplay";
import { Account, MASK_TYPE } from "types";
import MaskedValue from "common/MaskedValue";

export default function LiabilitiesSection(props: {
  liabilitiesAccounts: Account[];
  liabilitiesTotal: number;
}) {
  return (
    <SectionContainer>
      <SectionHeading>
        <Typography variant={"h6"}>Liabilities</Typography>
      </SectionHeading>

      {props.liabilitiesAccounts.length === 0 && (
        <MessageContainer>No Liability Accounts</MessageContainer>
      )}
      {props.liabilitiesAccounts.length > 0 && (
        <>
          <LiabilitiesDisplay accounts={props.liabilitiesAccounts} />
          <Divider style={{ margin: "0.15em 0em 0.5em 0em" }} />
          <TotalBalanceRow>
            <LeftContent>Total: </LeftContent>{" "}
            <RightContent>
              {MaskedValue(MASK_TYPE.MONEY, props.liabilitiesTotal.toString())}
            </RightContent>
          </TotalBalanceRow>
        </>
      )}
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  padding: 0em 0.5em 0em 0.5em;
`;

const SectionHeading = styled.div`
  padding-bottom: 0.5em;
`;

const MessageContainer = styled.div`
  padding-bottom: 1em;
`;

const LeftContent = styled.div`
  font-weight: 500;
  width: 300px;
  flex: 1;
`;
const TotalBalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
`;

const RightContent = styled.div`
  text-align: right;
  font-weight: 500;
`;
