import { FIELD_TYPE, TextFieldType,DateField, MASK_TYPE, PickListField, CreditCard } from "types";
import { v4 } from "uuid"

export const creditCardOpenDate: DateField<CreditCard> = {
  id: `creditCardOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: CreditCard) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: CreditCard) => {   
  return account.isAccountClosed ?? false
}
};

export const creditCardCloseDate: DateField<CreditCard> = {
  id: `creditCardCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: CreditCard) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const creditCardNumber: TextFieldType<CreditCard> = {
  id: `creditCardNumber_${v4()}`,
  label: "Card Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditCard) => account.cardNumber,
  setValue: (value, account) => ({
    ...account,
    cardNumber: value,
  }),
  isRequired: true,
  mask: MASK_TYPE.CREDIT_CARD_NUMBER,
  disabled: true,
};

function creditCardExpiryMonthOptions() {
  let a = [];
  for (let i = 1; i <= 12; i++) {
    a.push({ id: i.toString(), name: i.toString() });
  }
  return a;
}

export const creditCardExpiryMonth: PickListField<CreditCard> = {
  id: `creditCardExpiryMonth_${v4()}`,
  label: "Expiry Month",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditCard) => account.expiryMonth,
  setValue: (value, account) => ({
    ...account,
    expiryMonth: value,
  }),
 isRequired: true,
 options: creditCardExpiryMonthOptions(),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: CreditCard) => {   
  return account.isAccountClosed ?? false
}
};

function creditCardExpiryYearOptions() {
  let a = [];
  for (let i = 2022; i <= 2042; i++) {
    a.push({ id: i.toString(), name: i.toString() });
  }
  return a;
}

export const creditCardExpiryYear: PickListField<CreditCard> = {
  id: `creditCardExpiryYear_${v4()}`,
  label: "Expiry Year",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditCard) => account.expiryYear,
  setValue: (value, account) => ({
    ...account,
    expiryYear: value,
  }),
 isRequired: true,
 options: creditCardExpiryYearOptions(),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: CreditCard) => {   
  return account.isAccountClosed ?? false
}
};


export const creditCardLimit: TextFieldType<CreditCard> = {
  id: `creditCardLimit_${v4()}`,
  label: "Limit",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditCard) => account.limit?.toString(),
  setValue: (value, account) => ({
    ...account,
    limit: value as unknown as number,
  }),
  mask: MASK_TYPE.MONEY,
 isRequired: true,
 checkIfDisabled: (account: CreditCard) => {   
  return account.isAccountClosed ?? false
}
};

export const creditCardBalance: TextFieldType<CreditCard> = {
  id: `creditCardBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditCard) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    balance: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
  checkIfDisabled: (account: CreditCard) => {   
    return account.isAccountClosed ?? false
  }
};

export const creditCardType: PickListField<CreditCard> = {
  id: `creditCardType_${v4()}`,
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditCard) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
  }),
  options: [
    { id: "cashback", name: "Cashback" },
    { id: "student", name: "Student" },
    { id: "travel", name: "Travel" },
    { id: "nofee", name: "No Fee" },
  ],
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: CreditCard) => {   
  return account.isAccountClosed ?? false
}
};

function getPercentValue(percent: number, balance: number){
  const val = (percent * balance)/100
  return val
}

export const creditCardMinimumPayment: TextFieldType<CreditCard> = {
  id: `creditCardMinimumPayment_${v4()}`,
  label: "Minimum Payment",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditCard) => {let minPaymentValue = getPercentValue(5, account.balance ?? 0);
  if(minPaymentValue > 0)
  return minPaymentValue.toString()
  else 
  return "0"
  },
  setValue: (value, account) => ({
    ...account,
    minimumPayment: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  
};


