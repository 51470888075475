import { FetchError, getAuthBearerToken } from "@nait-aits/redux";

//You will need to setup these files as per MSAL instructions
import { msalInstance } from "index";
import { loginRequest } from "authConfig";

async function getToken() {
  var ret = await getAuthBearerToken(msalInstance, loginRequest);
  //if token is no longer valid, clear session
  if (ret instanceof FetchError) {

    if (ret.errorCode === "invalid_grant" || ret.errorCode === "interaction_required" ) {

      localStorage.removeItem("auth_token");

      msalInstance.logoutPopup();

    }
    return ret;

  } else {

    return ret;
  }

 // return getAuthBearerToken(msalInstance, loginRequest);
};

export default getToken;

export { getToken as getAuthBearerToken };
