import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StudentListItem } from "types";
import styled from "styled-components";
import _orderBy from "lodash/orderBy";
import { openConfirm } from "components/confirmBox";

export type Props = {
  studentList: StudentListItem[];
  onViewClick(studentId: string, studentName: string): void;
  onCheckboxClick(updatedStudent: StudentListItem): void;
  onSelectAllClick(selectedStudentList: StudentListItem[]): void;
  onDeselectAllClick(selectedStudentList: StudentListItem[]): void;
  onReadOnlySelectAllClick(selectedStudentList: StudentListItem[]): void;
  onReadOnlyDeselectAllClick(selectedStudentList: StudentListItem[]): void;
  onResetClick(studentId: string, studentName: string): void;
  isAllAccessCheckboxClicked: boolean;
  lessonName: string;
  isTimeBasedAccess: boolean;
};

export default function StudentList(props: Props) {
  //setting page to 0(1st page) if the filtered list is changed
  //to have correct filtering user experience
  useEffect(() => {
    if (!props.isAllAccessCheckboxClicked) {
      setPage(0);
    }
  }, [props.studentList]);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  let sortedStudentList = _orderBy(props.studentList, (l) => l.sectionId);

  let slicedSortedStudentList = sortedStudentList.slice(
    rowsPerPage !== -1 ? page * rowsPerPage : 0,
    rowsPerPage !== -1
      ? page * rowsPerPage + rowsPerPage
      : sortedStudentList.length
  );

  function onSelectAllClick() {
    let updatedStudentList = slicedSortedStudentList.map((s) => {
      let updatedStudent: StudentListItem = {
        ...s,
        haveAccess: true,
        isReadOnlyAccess: false,
      };
      return updatedStudent;
    });
    props.onSelectAllClick(updatedStudentList);
  }

  function onDeselectAllClick() {
    let updatedStudentList = slicedSortedStudentList.map((s) => {
      let updatedStudent: StudentListItem = { ...s, haveAccess: false };
      return updatedStudent;
    });
    props.onDeselectAllClick(updatedStudentList);
  }

  function onReadOnlySelectAllClick() {
    let updatedStudentList = slicedSortedStudentList.map((s) => {
      let updatedStudent: StudentListItem = {
        ...s,
        isReadOnlyAccess: true,
        haveAccess: false,
      };
      return updatedStudent;
    });
    props.onReadOnlySelectAllClick(updatedStudentList);
  }

  function onReadOnlyDeselectAllClick() {
    let updatedStudentList = slicedSortedStudentList.map((s) => {
      let updatedStudent: StudentListItem = { ...s, isReadOnlyAccess: false };
      return updatedStudent;
    });
    props.onReadOnlyDeselectAllClick(updatedStudentList);
  }

  return (
    <div style={{ width: 950 }}>
      {/* <div style={{ minHeight: 400 }}> */}
      {(!sortedStudentList || sortedStudentList.length === 0) && (
        <Content>
          <div>There are no students.</div>
        </Content>
      )}
      {/* {slicedSortedStudentList && slicedSortedStudentList.length > 0 && (
        <div style={{ textAlign: "right" }}>
          <Button onClick={onSelectAllClick}>Select All</Button>
          <Button onClick={onDeselectAllClick}>Deselect All</Button>
        </div>
      )} */}
      {sortedStudentList && sortedStudentList.length > 0 && (
        <Table>
          <Header
            slicedSortedStudentList={slicedSortedStudentList}
            onSelectAll={onSelectAllClick}
            onDeselectAll={onDeselectAllClick}
            onReadOnlySelectAll={onReadOnlySelectAllClick}
            onReadOnlyDeselectAll={onReadOnlyDeselectAllClick}
            isTimeBasedAccess={props.isTimeBasedAccess}
          />
          <TableBody>
            {slicedSortedStudentList.map((l, index) => {
              return (
                <Row
                  studentListItem={l}
                  key={index}
                  isTimeBasedAccess={props.isTimeBasedAccess}
                  onViewClick={() => {
                    props.onViewClick(l.studentId, l.fullName);
                  }}
                  onResetClick={() => {
                    openConfirm({
                      title: "Reset Lesson",
                      description: (
                        <div>
                          Are you sure you want to reset {props.lessonName} for{" "}
                          {l.fullName}?
                          <br />
                          <div style={{ marginTop: "1em" }}>
                            Note: This action cannot be reversed once submitted,
                            all the student's progress will be lost in the
                            lesson, if any.
                          </div>
                        </div>
                      ),
                      okBtnText: "Reset",
                      callback: () => {
                        props.onResetClick(l.studentId, l.fullName);
                      },
                    });
                  }}
                  onCheckboxClick={(updatedStudent) =>
                    props.onCheckboxClick(updatedStudent)
                  }
                />
              );
            })}
          </TableBody>
        </Table>
      )}
      {props.studentList && props.studentList.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 20, { value: -1, label: "All" }]}
          component="div"
          count={props.studentList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => {
            setPage(p);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
            setPage(0);
          }}
        />
      )}
    </div>
  );
}

function Header(props: {
  slicedSortedStudentList: StudentListItem[];
  isTimeBasedAccess: boolean;
  onSelectAll(): void;
  onDeselectAll(): void;
  onReadOnlySelectAll(): void;
  onReadOnlyDeselectAll(): void;
}) {
  let isAnyStudentWithAccessOff = props.slicedSortedStudentList.some(
    (s) => s.haveAccess === false
  );

  let isAnyStudentWithReadOnlyAccessOff = props.slicedSortedStudentList.some(
    (s) => s.isReadOnlyAccess === false
  );
  return (
    <TableHead>
      <TableRow>
        <Cell>Student Id</Cell>
        <Cell>Course Code</Cell>
        <Cell>Course Term</Cell>
        <Cell>Class Section</Cell>
        <Cell>Student Name</Cell>
        <Cell>Username</Cell>
        <Cell>View As</Cell>
        <Cell>Reset Lesson</Cell>
        {!props.isTimeBasedAccess && (
          <Cell>
            Edit Access
            <br />
            <Checkbox
              id={`allAccessCheckbox`}
              value={isAnyStudentWithAccessOff ? false : true}
              checked={isAnyStudentWithAccessOff ? false : true}
              onChange={() => {
                isAnyStudentWithAccessOff
                  ? props.onSelectAll()
                  : props.onDeselectAll();
              }}
              size="small"
              style={{ padding: 0 }}
            />
          </Cell>
        )}
        <Cell>
          View Only
          <br />
          <Checkbox
            id={`allReadOnlyAccessCheckbox`}
            value={isAnyStudentWithReadOnlyAccessOff ? false : true}
            checked={isAnyStudentWithReadOnlyAccessOff ? false : true}
            onChange={() => {
              isAnyStudentWithReadOnlyAccessOff
                ? props.onReadOnlySelectAll()
                : props.onReadOnlyDeselectAll();
            }}
            size="small"
            style={{ padding: 0 }}
          />
        </Cell>
      </TableRow>
    </TableHead>
  );
}

function Row(props: {
  studentListItem: StudentListItem;
  isTimeBasedAccess: boolean;
  onViewClick(): void;
  onResetClick(): void;
  onCheckboxClick(updatedStudent: StudentListItem): void;
}) {
  return (
    <TableRow>
      <Cell>{props.studentListItem.studentId}</Cell>
      <Cell>{props.studentListItem.courseCode}</Cell>
      <Cell>{props.studentListItem.term}</Cell>
      <Cell>{props.studentListItem.section}</Cell>
      <Cell
        style={{ maxWidth: 125, overflow: "hidden", textOverflow: "ellipsis" }}
        title={props.studentListItem.fullName}
      >
        {props.studentListItem.fullName}
      </Cell>
      <Cell
        style={{ maxWidth: 125, overflow: "hidden", textOverflow: "ellipsis" }}
        title={props.studentListItem.userName}
      >
        {props.studentListItem.userName}
      </Cell>

      <Cell>
        <Button onClick={props.onViewClick}>View</Button>
      </Cell>
      <Cell>
        <Button onClick={props.onResetClick}>Reset</Button>
      </Cell>
      {!props.isTimeBasedAccess && (
        <Cell>
          <Checkbox
            id={`${props.studentListItem.sectionId}_accessCheckbox`}
            value={props.studentListItem.haveAccess ?? false}
            checked={props.studentListItem.haveAccess ?? false}
            onChange={() => {
              let updatedStudent: StudentListItem = {
                ...props.studentListItem,
                haveAccess: props.studentListItem.haveAccess
                  ? !props.studentListItem.haveAccess
                  : true,
                isReadOnlyAccess:
                  props.studentListItem.haveAccess === false ||
                  !props.studentListItem.haveAccess
                    ? false
                    : props.studentListItem.isReadOnlyAccess,
              };
              props.onCheckboxClick(updatedStudent);
            }}
            size="small"
          />
        </Cell>
      )}
      <Cell>
        <Checkbox
          id={`${props.studentListItem.sectionId}_readOnlyAccessCheckbox`}
          value={props.studentListItem.isReadOnlyAccess ?? false}
          checked={props.studentListItem.isReadOnlyAccess ?? false}
          onChange={() => {
            let updatedStudent: StudentListItem = {
              ...props.studentListItem,
              isReadOnlyAccess: props.studentListItem.isReadOnlyAccess
                ? !props.studentListItem.isReadOnlyAccess
                : true,
              haveAccess:
                props.studentListItem.isReadOnlyAccess === false ||
                !props.studentListItem.isReadOnlyAccess
                  ? false
                  : props.studentListItem.haveAccess,
            };
            props.onCheckboxClick(updatedStudent);
          }}
          size="small"
        />
      </Cell>
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  padding: 10px 8px !important;
  text-align: center !important;
`;

const Content = styled.div`
  margin-top: 3em;
  display: flex;
  justify-content: center;
`;
