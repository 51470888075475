
import {  
  FIELD_TYPE,  
  MASK_TYPE,
   TextFieldType,
    CreditCardTransaction,
    
    PickListField,
    
} from "types";

export const creditCard: PickListField<CreditCardTransaction> = {
  id: "creditCard",
  label: "Credit Card",
  type: FIELD_TYPE.ACCOUNTSPICKLIST,
  isRequired: true,
  getValue: (transaction: CreditCardTransaction) =>  transaction.creditCard,
  setValue: (value, transaction) => ({...transaction,creditCard: value }),
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const creditCardTransactiontype: PickListField<CreditCardTransaction> = {
  id: "creditCardTransactiontype",
  label: "Transaction Type",
  type: FIELD_TYPE.PICKLIST,
  isRequired: true,
  getValue: (transaction: CreditCardTransaction) =>  transaction.type,
  setValue: (value, transaction) => ({...transaction,type: value }),
  options: [
    { id: "payment", name: "Payment" },
    { id: "cashadvance", name: "Cash Advance" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};


export const creditCardAmount: TextFieldType<CreditCardTransaction> = {
  id: "creditCardAmount",
  label: "Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: CreditCardTransaction) => transaction.amount ? transaction.amount.toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    amount: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
};