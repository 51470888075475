import styled from "styled-components";
import { FieldError, PersonalClient } from "types";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/clientPortfolio/personalClient/personalClientInvesting/investingFieldDefinitions";
import { ReactNode } from "react";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};
export default function RiskCapacitySection(props: CommonProps) {
  return (
    <>
      <QuestionRow
        question="What is your annual income (from all sources)?"
        field={
          <FieldEditor<PersonalClient> field={fields.annualIncome} {...props} />
        }
      />
      <QuestionRow
        question="Your current and future income sources are:"
        field={
          <FieldEditor<PersonalClient>
            field={fields.incomeSourcesStability}
            {...props}
          />
        }
      />
      <QuestionRow
        question="How would you classify your overall financial situation?"
        field={
          <FieldEditor<PersonalClient>
            field={fields.overallFinancialSituation}
            {...props}
          />
        }
      />
      <QuestionRow
        question="What is your estimated net worth (investments, cash, home and other real estate less mortgage loans and all other debts)?"
        field={
          <FieldEditor<PersonalClient>
            field={fields.estimatedNetWorth}
            {...props}
          />
        }
      />
      <QuestionRow
        question="This investment account represents approximately what percentage of your total savings and investments.  (Total savings and investments include all the money you have in cash savings, GICs, savings bonds, mutual funds, stocks and bonds)?"
        field={
          <FieldEditor<PersonalClient>
            field={fields.investmentAccountPercentage}
            {...props}
          />
        }
      />
      <QuestionRow
        question="What is your age group?"
        field={
          <FieldEditor<PersonalClient> field={fields.ageGroup} {...props} />
        }
      />
    </>
  );
}

function QuestionRow(props: { question: string; field: ReactNode }) {
  return (
    <QuestionRowContainer>
      <QuestionDiv>{props.question}</QuestionDiv>
      <FieldDiv>{props.field}</FieldDiv>
    </QuestionRowContainer>
  );
}

const QuestionRowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionDiv = styled.div`
  width: 625px;
  margin: 0px 10px;
  align-items: center;
`;

const FieldDiv = styled.div`
  width: 250px;
  margin: 0px 10px;
  align-items: center;
`;
