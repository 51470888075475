import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import styled from "styled-components";

export type Props = {
  warningMessage: string;
  onProceed(): void;
  onCancel(): void;
};

export default function WarningControl(props: Props) {
  return (
    <Dialog maxWidth="sm" fullWidth open id={`add_custom_student`}>
      <DialogTitle sx={{ backgroundColor: "#f2fa9b", color: "#000000" }}>
        <DialogTitleHeading>
          <WarningIcon sx={{ paddingTop: "3px" }} />
          <Spacer /> <div>Warning</div>
        </DialogTitleHeading>
      </DialogTitle>
      <DialogContent sx={{ marginTop: "2em" }}>
        <Typography>{props.warningMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button variant="contained" onClick={props.onProceed}>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const DialogTitleHeading = styled.div`
  display: flex;
  font-size: 20px !important;
  margin-bottom: 0.5em;
`;
const Spacer = styled.div`
  margin: 0.5em;
`;
