import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BusinessClient, PersonalClient } from "types";
import styled from "styled-components";
import { getLoginInfo } from "common/azureAD";
import PersonalClientRow from "pages/clientSearch/components/PersonalClientRow";
import BusinessClientRow from "pages/clientSearch/components/BusinessClientRow";

export type Props = {
  clients?: (PersonalClient | BusinessClient)[];
  studentId?: string;
  onDeletePersonalClient(clientId: string): void;
  onDeleteBusinessClient(clientId: string): void;
};

export default function ClientList(props: Props) {
  //setting page to 0(1st page) if the filtered list is changed
  //to have correct filtering user experience
  useEffect(() => {
    setPage(0);
  }, [props.clients]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const loginInfo = getLoginInfo();
  if (!props.clients || props.clients.length === 0) {
    return <div>There are no clients.</div>;
  }
  return (
    <div>
      <Paper component={Table}>
        <Header isInstructor={loginInfo?.isInstructor ?? false} />
        <TableBody>
          {props.clients
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((c, index) => {
              if (c.information?.accountType === "Personal") {
                return (
                  <PersonalClientRow
                    client={c as PersonalClient}
                    key={index}
                    studentId={props.studentId}
                    onDeletePersonalCLient={() => {
                      props.onDeletePersonalClient(
                        (c as PersonalClient).identification?.clientId ?? ""
                      );
                    }}
                    isInstructor={loginInfo?.isInstructor ?? false}
                  />
                );
              } else if (c.information?.accountType === "Business") {
                return (
                  <BusinessClientRow
                    client={c as BusinessClient}
                    key={index}
                    studentId={props.studentId}
                    onDeleteBusinessClient={() => {
                      props.onDeleteBusinessClient(
                        (c as BusinessClient).information?.clientId ?? ""
                      );
                    }}
                    isInstructor={loginInfo?.isInstructor ?? false}
                  />
                );
              } else {
                return <div>Unkown Client</div>;
              }
            })}
        </TableBody>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[10, 20]}
        component="div"
        count={props.clients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => {
          setPage(p);
        }}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(+e.target.value);
        }}
      />
    </div>
  );
}

function Header(props: { isInstructor: boolean }) {
  return (
    <TableHead>
      <TableRow>
        <Cell>Client ID</Cell>
        <Cell>Client Name</Cell>
        <Cell>Phone Number</Cell>
        <Cell>Account Type</Cell>
        <Cell></Cell>
        {props.isInstructor && <Cell></Cell>}
      </TableRow>
    </TableHead>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
`;
