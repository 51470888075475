import styled from "styled-components";
import { MASK_TYPE } from "types";
import MaskedValue from "common/MaskedValue";

export default function NetWorthSection(props: { netWorth: number }) {
  return (
    <div>
      <TotalBalanceRow>
        <LeftContent>Net Worth </LeftContent>
        <RightContent>
          {MaskedValue(MASK_TYPE.MONEY, props.netWorth.toString())}
        </RightContent>
      </TotalBalanceRow>
    </div>
  );
}

const LeftContent = styled.div`
  font-weight: 500;
  font-size: 18px;
  width: 300px;
  flex: 1;
`;
const TotalBalanceRow = styled.div`
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
`;

const RightContent = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 18px;
`;
