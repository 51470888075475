import { openConfirm } from "components/confirmBox";
import { ReactNode } from "react";
import styled from "styled-components";
import EditAccountActionsButton from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/EditAccountActionsButton";

export type Props = {
  id: string;
  accountType: string;
  accountId: string;
  type?: string;
  requestedAmount: number;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
  children: ReactNode;
};

export default function AccountDisplayTemplate({
  id,
  accountType,
  accountId,
  type,
  requestedAmount: balance,
  onEditClick,
  onDeleteClick,
  isReadOnlyView,
  children,
}: Props) {
  return (
    <>
      <Content>{children}</Content>
      <ButtonDiv>
        <AccountActionsContainer>
          <EditAccountActionsButton
            onEdit={onEditClick}
            onDelete={() => {
              openConfirm({
                title: "Delete Account?",
                description: `${accountType} - ${type} `,
                okBtnText: "Delete",
                callback: () => {
                  onDeleteClick();
                },
              });
            }}
            isReadOnlyView={isReadOnlyView}
          />
        </AccountActionsContainer>
      </ButtonDiv>
    </>
  );
}
const ButtonDiv = styled.div`
  display: flex;
  padding-right: 32px;
`;
const Content = styled.div`
  padding: 0 8px;
`;
const AccountActionsContainer = styled.div`
  text-align: right;
  flex: 1;
`;
