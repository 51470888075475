import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import TextFieldNonFieldEditor from "components/fields/TextFieldNonFieldEditor";
import { useEffect, useState } from "react";
import { FieldError, LessonListItem } from "types";

export type Props = {
  onSave(updatedLessonName: string): void;
  onCancel(): void;
  selectedLesson: LessonListItem;
  lessonList: LessonListItem[];
};

const EditLessonName = (props: Props) => {
  const [lessonName, setLessonName] = useState<string>(
    props.selectedLesson.lessonName
  );

  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [lessonName]);

  function checkErrors() {
    let errArray: FieldError[] = [];

    if (!lessonName) {
      let err: FieldError = {
        id: `lessonName`,
        label: `Lesson Name`,
        error: "Is Required.",
      };
      errArray.push(err);
    } else if (
      lessonName.trim().toLocaleLowerCase() !==
        props.selectedLesson.lessonName.toLocaleLowerCase() &&
      props.lessonList.some(
        (l) =>
          l.lessonName.toLocaleLowerCase() ===
          lessonName.trim().toLocaleLowerCase()
      )
    ) {
      let err: FieldError = {
        id: `lessonName`,
        label: `Lesson Name`,
        error: "Lesson Name already exists.",
      };
      errArray.push(err);
    }

    setErrors(errArray);
    return errArray;
  }

  function onSaveClick() {
    let errorArray = checkErrors();

    if (errorArray.length === 0) {
      props.onSave(lessonName);
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open id="addLesson">
      <DialogTitle>
        <Typography>Edit Lesson - {props.selectedLesson.lessonName}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextFieldNonFieldEditor
              id={`lessonName`}
              label={`Lesson Name`}
              value={lessonName}
              onChange={(v) => setLessonName(v)}
              required={true}
              multiline={false}
              disabled={false}
              errObj={errors.find((e) => e.id === "lessonName")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            onSaveClick();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLessonName;
