import { Divider, IconButton } from "@mui/material";
import { IdName, Withdrawal } from "types";
import SummaryRowTemplate from "pages/transactions/components/SummaryRowTemplate";
import { openConfirm } from "components/confirmBox";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";

import * as fields from "pages/transactions/withdrawalFieldDefinitions";
import { getMaskedMoneyValue } from "common/functions";

type SummaryRowWithdrawalProps = {
  transaction: Withdrawal;
  onDeleteClick(): void;
  withdrawFromOptions: IdName[];
  isTxnForAccWithOverLimitBal: boolean;
  isReadOnlyView: boolean;
};

export default function SummaryRowWithdrawal(props: SummaryRowWithdrawalProps) {
  const currencyType = props.transaction.currencyType ?? "";
  const fromLabel = fields.withdrawalFrom.getOptionLabel
    ? fields.withdrawalFrom.getOptionLabel(
        props.transaction.from ?? "",
        props.withdrawFromOptions
      )
    : "";
  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);
  return (
    <SummaryRowTemplate
      transaction="Withdrawal"
      debit={transactionAmount}
      isTxnForAccWithOverLimitBal={props.isTxnForAccWithOverLimitBal}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection>From</TextSection>
        <TextSection> To</TextSection>
        <TextSection>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>{fromLabel}</TextSection>
        {/* <TextSection>{props.transaction.to}</TextSection> */}
        <TextSection>Session Balance</TextSection>
        <TextSection>
          {`${transactionAmount} ${currencyType.toUpperCase()}`}
        </TextSection>
      </DetailsRow>
      <DetailsRow isBold={true}>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          <IconButton
            style={{ margin: "-1em 0" }}
            aria-label="delete"
            onClick={() => {
              openConfirm({
                title: "Delete Transaction?",
                description: `Withdrawal ${transactionAmount} from ${fromLabel}`,
                callback: () => {
                  props.onDeleteClick();
                },
              });
            }}
            disabled={props.isReadOnlyView}
          >
            <DeleteIcon />
          </IconButton>
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
  text-align: center;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
