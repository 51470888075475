import { CSSProperties } from "@mui/material/styles/createTypography";
import styled from "styled-components";
import { AddressType } from "types";

export type Props = {
  address: AddressType;
  displayAddressLabel?: boolean;
  style?: CSSProperties;
};

const AddressDisplay = (props: Props) => {
  return (
    <div style={{ display: "flex" }}>
      {props.displayAddressLabel && <BoldLabel>Address : </BoldLabel>}
      <Content style={props.style}>
        <div>{props.address.address}</div>
        <div>
          {props.address.city}
          {props.address.city && ", "}
          {props.address.province}
          {props.address.province && ", "}
          {props.address.country}
        </div>
        <div>{props.address.postalCode}</div>
      </Content>
    </div>
  );
};

export default AddressDisplay;

const BoldLabel = styled.div`
  font-weight: bold;
  margin-left: 1em;
`;

const Content = styled.div`
  margin-left: 1em;
  margin-right: 2em;
`;
