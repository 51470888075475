import { useEffect, useState } from "react";
import { FieldError, IdName, Withdrawal } from "types";
import styled from "styled-components";
import { Button } from "@mui/material";
import * as fields from "pages/transactions/withdrawalFieldDefinitions";
import { requiredPickListField, requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";
import CurrencyToggleMenu from "pages/transactions/components/CurrencyToggleMenu";
export type Props = {
  transaction: Withdrawal;
  withdrawalFromOptions: IdName[];
  onSaveClick(transaction: Withdrawal): void;
};

export default function WithdrawalForm({
  transaction,
  withdrawalFromOptions,
  onSaveClick,
}: Props) {
  const [state, setState] = useState<Withdrawal>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: Withdrawal) => {
      setState(state);
    },
  };

  function saveWithdrawal(transaction: Withdrawal) {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      onSaveClick(transaction);
    }
  }

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.withdrawalAmount, state),
      ...requiredPickListField(fields.withdrawalFrom, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  return (
    <div style={{ padding: "10px" }}>
      <FlexDiv>
        <TransactionHeading>Withdrawal</TransactionHeading>
        <CurrencyToggleMenu onToggle={() => {}} isDisabled={true} />
      </FlexDiv>
      {withdrawalFromOptions.length === 0 && (
        <Container>No Active Withdrawal Accounts</Container>
      )}
      {withdrawalFromOptions.length > 0 && (
        <>
          <Container>
            <FieldEditor<Withdrawal>
              field={fields.withdrawalFrom}
              {...commonProps}
              accountOptions={withdrawalFromOptions}
            />
            <FieldEditor<Withdrawal>
              field={fields.withdrawalTo}
              {...commonProps}
            />
            <FieldEditor<Withdrawal>
              field={fields.withdrawalAmount}
              {...commonProps}
            />
          </Container>
          <SaveButtonConatiner>
            <Button
              disabled={!(state.amount && state.amount > 0)}
              onClick={() => {
                saveWithdrawal(state);
              }}
            >
              Save
            </Button>
          </SaveButtonConatiner>
        </>
      )}
    </div>
  );
}

const TransactionHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const Container = styled.div`
  width: 300px;
  margin: auto;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SaveButtonConatiner = styled.div`
  text-align: right;
  padding: 1em;
`;
