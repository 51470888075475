import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  BusinessClientAccountsRoute,
  BusinessClientInformationRoute,
  BusinessClientSummaryRoute,
  BusinessClientTransactionsRoute,
  PersonalClientAccountsRoute,
  PersonalClientCreditAppRoute,
  PersonalClientInformationRoute,
  PersonalClientInvestingRoute,
  PersonalClientSummaryRoute,
  PersonalClientTransactionsRoute,
} from "components/paths";
import { ReactNode, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useReduxState } from "store";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import FeedbackIcon from "@mui/icons-material/Feedback";
import styled from "styled-components";
import InstructorFeedbackDialog from "./components/InstructorFeedbackDialog";
import { getLoginInfo } from "common/azureAD";
import {
  getBusinessClientInformationInstructorFeedbackFromRedux,
  getPersonalClientCreditAppInstructorFeedbackFromRedux,
  getPersonalClientInformationInstructorFeedbackFromRedux,
} from "pages/lessons/helpers";

type ClientPortfolioTemplateProps = {
  children: ReactNode;
  selectedButton: number;
  isNewClient?: boolean;
  studentId?: string;
  isLoading?: boolean;
  onSaveFeedback?: (feedback?: string) => void;
};
export default function ClientPortfolioTemplate({
  children,
  selectedButton,
  isNewClient,
  studentId,
  isLoading,
  onSaveFeedback,
}: ClientPortfolioTemplateProps) {
  const location = useLocation();
  // const [clientId, setClientId] = useState<string>("");
  //const [isBusinessClient, setIsBusinessClient] = useState<boolean>();
  const {
    getLessonNameAndData,
    getDataforViewAsStudent,
    lessonId,
  } = useReduxState((e) => e.lesson);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);

  const loginInfo = getLoginInfo();

  const isInstructor = loginInfo?.isInstructor ?? false;

  useEffect(() => {
    // let cId = location.pathname.split("/")[5];
    // setClientId(cId);
    clientId = location.pathname.split("/")[5];
    isBusinessClient = location.pathname.includes("businessclient");
    //setIsBusinessClient(isBusinessClient);
  }, [location]);

  const history = useHistory();

  var clientId = location.pathname.split("/")[5];

  var isBusinessClient = location.pathname.includes("businessclient");

  const lessonData = !!studentId
    ? getDataforViewAsStudent.data?.lessonData
    : getLessonNameAndData.data?.lessonData;

  const informationInstructorFeedback = isBusinessClient
    ? getBusinessClientInformationInstructorFeedbackFromRedux(
        clientId,
        lessonData
      )
    : getPersonalClientInformationInstructorFeedbackFromRedux(
        clientId,
        lessonData
      );

  const creditAppInstructorFeedback = getPersonalClientCreditAppInstructorFeedbackFromRedux(
    clientId,
    lessonData
  );
  const feedback =
    selectedButton === 1
      ? informationInstructorFeedback
      : creditAppInstructorFeedback;
  return (
    <div>
      <FlexDiv>
        <ToggleContainer>
          <ToggleButtonGroup
            value={selectedButton}
            color="primary"
            exclusive
            style={{
              background: "white",
              borderRadius: "15px",
              marginLeft: "2em",
            }}
          >
            <ToggleButton
              value={0}
              disabled={isNewClient}
              component={Link}
              to={
                isBusinessClient
                  ? !!studentId
                    ? `/${lessonId}${BusinessClientSummaryRoute}/${clientId}/${studentId}`
                    : clientId
                    ? `/${lessonId}${BusinessClientSummaryRoute}/${clientId}`
                    : `/${lessonId}${BusinessClientInformationRoute}`
                  : !!studentId
                  ? `/${lessonId}${PersonalClientSummaryRoute}/${clientId}/${studentId}`
                  : clientId
                  ? `/${lessonId}${PersonalClientSummaryRoute}/${clientId}`
                  : `/${lessonId}${PersonalClientInformationRoute}`
              }
              style={{ borderRadius: "15px 0px 0px 15px" }}
            >
              Summary
            </ToggleButton>

            <ToggleButton
              value={1}
              component={Link}
              to={
                isBusinessClient
                  ? !!studentId
                    ? `/${lessonId}${BusinessClientInformationRoute}/${clientId}/${studentId}`
                    : clientId
                    ? `/${lessonId}${BusinessClientInformationRoute}/${clientId}`
                    : `/${lessonId}${BusinessClientInformationRoute}`
                  : !!studentId
                  ? `/${lessonId}${PersonalClientInformationRoute}/${clientId}/${studentId}`
                  : clientId
                  ? `/${lessonId}${PersonalClientInformationRoute}/${clientId}`
                  : `/${lessonId}${PersonalClientInformationRoute}`
              }
            >
              Information
              {!!informationInstructorFeedback && (
                <FeedbackIcon
                  style={{ marginLeft: 3, width: 15, height: 15 }}
                  titleAccess="Instructor Feedback"
                />
              )}
            </ToggleButton>
            <ToggleButton
              disabled={isNewClient}
              component={Link}
              to={
                isBusinessClient
                  ? !!studentId
                    ? `/${lessonId}${BusinessClientAccountsRoute}/${clientId}/${studentId}`
                    : clientId
                    ? `/${lessonId}${BusinessClientAccountsRoute}/${clientId}`
                    : `/${lessonId}${BusinessClientInformationRoute}`
                  : !!studentId
                  ? `/${lessonId}${PersonalClientAccountsRoute}/${clientId}/${studentId}`
                  : clientId
                  ? `/${lessonId}${PersonalClientAccountsRoute}/${clientId}`
                  : `/${lessonId}${PersonalClientInformationRoute}`
              }
              value={2}
              style={{
                borderRadius: isBusinessClient ? "0px 15px 15px 0px" : "0px",
              }}
            >
              Accounts
            </ToggleButton>
            {!isBusinessClient && (
              <ToggleButton
                disabled={isNewClient}
                component={Link}
                to={
                  !!studentId
                    ? `/${lessonId}${PersonalClientCreditAppRoute}/${clientId}/${studentId}`
                    : `/${lessonId}${PersonalClientCreditAppRoute}/${clientId}`
                }
                value={3}
              >
                Credit App
                {!!creditAppInstructorFeedback && (
                  <FeedbackIcon
                    style={{ marginLeft: 3, width: 15, height: 15 }}
                    titleAccess="Instructor Feedback"
                  />
                )}
              </ToggleButton>
            )}
            {!isBusinessClient && (
              <ToggleButton
                disabled={isNewClient}
                component={Link}
                to={
                  !!studentId
                    ? `/${lessonId}${PersonalClientInvestingRoute}/${clientId}/${studentId}`
                    : `/${lessonId}${PersonalClientInvestingRoute}/${clientId}`
                }
                value={4}
                style={{ borderRadius: "0px 15px 15px 0px" }}
              >
                Investing
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </ToggleContainer>
        {!isNewClient &&
          !isLoading &&
          (selectedButton === 1 || selectedButton === 3) &&
          ((isInstructor && !!studentId) || (!isInstructor && !!feedback)) && (
            <Button
              sx={{ marginRight: "2em" }}
              onClick={() => setShowFeedbackDialog(true)}
            >
              <FeedbackIcon style={{ marginRight: 5 }} />
              {isInstructor
                ? !!feedback
                  ? "Manage Feedback"
                  : "Provide Feedback"
                : "View Feedback"}
            </Button>
          )}
        {!isNewClient && (
          <Button
            sx={{ marginRight: "2em" }}
            onClick={() => {
              isBusinessClient
                ? !!studentId
                  ? history.push(
                      `/${lessonId}${BusinessClientTransactionsRoute}/${clientId}/${studentId}`
                    )
                  : history.push(
                      `/${lessonId}${BusinessClientTransactionsRoute}/${clientId}`
                    )
                : !!studentId
                ? history.push(
                    `/${lessonId}${PersonalClientTransactionsRoute}/${clientId}/${studentId}`
                  )
                : history.push(
                    `/${lessonId}${PersonalClientTransactionsRoute}/${clientId}`
                  );
            }}
          >
            <ReceiptLongIcon style={{ marginRight: 5 }} />
            Transactions
          </Button>
        )}
      </FlexDiv>
      {showFeedbackDialog && (
        <InstructorFeedbackDialog
          feedback={
            selectedButton === 1
              ? informationInstructorFeedback
              : creditAppInstructorFeedback
          }
          isStudent={!isInstructor}
          onClose={() => setShowFeedbackDialog(false)}
          onSave={(feedback) => {
            onSaveFeedback?.(feedback);
            setShowFeedbackDialog(false);
          }}
        />
      )}
      {children}
    </div>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleContainer = styled.div`
  flex: 1;
`;
