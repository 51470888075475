import { useEffect, useState } from "react";
import { FieldError, CreditCardTransaction, IdName } from "types";
import styled from "styled-components";
import { Button } from "@mui/material";
import * as fields from "pages/transactions/creditCardTransactionFieldDefinitions";
import { requiredPickListField, requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";

export type Props = {
  transaction: CreditCardTransaction;
  creditCardOptions: IdName[];
  onSaveClick(transaction: CreditCardTransaction): void;
};

export default function CreditCardTransactionForm({
  transaction,
  creditCardOptions,
  onSaveClick,
}: Props) {
  const [state, setState] = useState<CreditCardTransaction>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: CreditCardTransaction) => {
      setState(state);
    },
  };

  function saveDeposit(transaction: CreditCardTransaction) {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      onSaveClick(transaction);
    }
  }

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredPickListField(fields.creditCard, state),
      ...requiredPickListField(fields.creditCardTransactiontype, state),
      ...requiredTextField(fields.creditCardAmount, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  return (
    <div style={{ padding: "10px" }}>
      <FlexDiv>
        <TransactionHeading>Credit Card</TransactionHeading>
      </FlexDiv>
      {creditCardOptions.length === 0 && (
        <Container>No Active Credit Card Accounts</Container>
      )}
      {creditCardOptions.length > 0 && (
        <>
          <Container>
            <FieldEditor<CreditCardTransaction>
              field={fields.creditCard}
              {...commonProps}
              accountOptions={creditCardOptions}
            />
            <FieldEditor<CreditCardTransaction>
              field={fields.creditCardTransactiontype}
              {...commonProps}
            />
            <FieldEditor<CreditCardTransaction>
              field={fields.creditCardAmount}
              {...commonProps}
            />
          </Container>
          <SaveButtonConatiner>
            <Button
              disabled={!(state.amount && state.amount > 0)}
              onClick={() => {
                saveDeposit(state);
              }}
            >
              Save
            </Button>
          </SaveButtonConatiner>
        </>
      )}
    </div>
  );
}

const TransactionHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const Container = styled.div`
  width: 300px;
  margin: auto;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SaveButtonConatiner = styled.div`
  text-align: right;
  padding: 1em;
`;
