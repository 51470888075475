import { Divider } from "@mui/material";
import { CreditCardTransaction, IdName } from "types";
import SummaryRowTemplate from "pages/sessionHistory/components/SummaryRowTemplate";
import styled from "styled-components";
import * as fields from "pages/transactions/creditCardTransactionFieldDefinitions";
import { getMaskedMoneyValue } from "common/functions";
import moment from "moment";

type SummaryRowCreditCardProps = {
  transaction: CreditCardTransaction;
  creditCardOptions: IdName[];
};

export default function SummaryRowCreditCard(props: SummaryRowCreditCardProps) {
  const fromToLabel = fields.creditCard.getOptionLabel
    ? fields.creditCard.getOptionLabel(
        props.transaction.creditCard ?? "",
        props.creditCardOptions
      )
    : "";
  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  return (
    <SummaryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transaction={
        props.transaction.type === "payment"
          ? "Credit Card - Payment"
          : "Credit Card - Cash Advance"
      }
      credit={props.transaction.type === "payment" ? transactionAmount : ""}
      debit={props.transaction.type === "cashadvance" ? transactionAmount : ""}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection style={{ textAlign: "center" }}>From</TextSection>
        <TextSection style={{ textAlign: "center" }}> To</TextSection>
        <TextSection style={{ textAlign: "center" }}>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>
          {props.transaction.type === "payment"
            ? "Session Balance"
            : fromToLabel === ""
            ? "Deleted Account"
            : fromToLabel}
        </TextSection>
        <TextSection>
          {props.transaction.type === "payment"
            ? fromToLabel === ""
              ? "Deleted Account"
              : fromToLabel
            : "Session Balance"}
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${transactionAmount} CAD`}
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;
