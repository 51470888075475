import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import { Employer, Employment, FieldError, PersonalClient } from "types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import EmploymentDisplayTemplate from "pages/clientPortfolio/personalClient/personalClientInformation/components/EmploymentDisplayTemplate";
import EmploymentEditor from "pages/clientPortfolio/personalClient/personalClientInformation/components/EmploymentEditor";
import { useState } from "react";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};

const EmploymentHistorySection = (
  props: CommonProps & {
    employers: Employer[];
    onEditEmployment(updatedEmployment: Employment): void;
    onDeleteEmployment(employment: Employment): void;
    isReadOnlyView: boolean;
  }
) => {
  const [employmentInEdit, setEmploymentInEdit] = useState<
    Employment | undefined
  >(undefined);

  const employmentHistory = props.state.employmentHistory;
  return (
    <SectionContainer>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="employmentHistorySection-header"
        >
          <Typography variant={"h6"}>Employment History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {(!employmentHistory || employmentHistory?.length === 0) && (
            <div>No Employment History Record available.</div>
          )}
          {employmentHistory && employmentHistory.length > 0 && (
            <div>
              {employmentHistory.map((e, index) => {
                return (
                  <div key={index}>
                    <EmploymentDisplayTemplate
                      employment={e}
                      employer={
                        props.employers.find(
                          (emp) => emp.employerId === e.employerId
                        ) ?? {}
                      }
                      onEditClick={() => {
                        setEmploymentInEdit(e);
                      }}
                      onDeleteClick={() => {
                        props.onDeleteEmployment(e);
                      }}
                      isReadOnlyView={props.isReadOnlyView}
                    />
                    <Divider style={{ margin: "1.25em 0" }} />
                  </div>
                );
              })}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      {!!employmentInEdit && (
        <EmploymentEditor
          employment={employmentInEdit}
          employer={
            !!employmentInEdit.employerId
              ? props.employers.find(
                  (emp) => emp.employerId === employmentInEdit.employerId
                )
              : {}
          }
          onSave={(newEmployment) => {
            props.onEditEmployment(newEmployment);
            setEmploymentInEdit(undefined);
          }}
          onCancel={() => {
            setEmploymentInEdit(undefined);
          }}
        />
      )}
    </SectionContainer>
  );
};

export default EmploymentHistorySection;

const SectionContainer = styled.div`
  margin-top: 2em;
`;
