import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FieldError } from "types";
import WarningIcon from "@mui/icons-material/Warning";
import styled from "styled-components";
import TextFieldNonFieldEditor from "components/fields/TextFieldNonFieldEditor";

export type Props = {
  itemToDelete: string;
  deleteItemName: string;
  warningMessage: string;
  customMessage?: string;
  onDelete(): void;
  onCancel(): void;
};

export default function DeleteControl(props: Props) {
  const [deleteText, setDeleteText] = useState<string>("");
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [deleteText]);

  function checkErrors() {
    const errorsArray: FieldError[] = [];
    if (!deleteText) {
      let err: FieldError = {
        id: `deleteText`,
        label: `Delete Text`,
        error: "Is Required.",
      };
      errorsArray.push(err);
    } else if (deleteText !== props.deleteItemName) {
      let err: FieldError = {
        id: `deleteText`,
        label: `Delete Text`,
        error: "Incorrect Name.",
      };
      errorsArray.push(err);
    }

    setErrors(errorsArray);
    return errorsArray;
  }

  function onDeleteClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      props.onDelete();
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open id={`add_custom_student`}>
      <DialogTitle sx={{ backgroundColor: "#fdd1cc", color: "#d32f2f" }}>
        <DialogTitleHeading>
          <WarningIcon sx={{ paddingTop: "3px" }} />
          <Spacer /> <div>Delete this {props.itemToDelete}?</div>
        </DialogTitleHeading>
        <Typography>{props.warningMessage}</Typography>
      </DialogTitle>

      <DialogContent sx={{ marginTop: "2em" }}>
        {props.customMessage && (
          <Typography sx={{ marginBottom: "2em" }}>
            {props.customMessage}
          </Typography>
        )}

        <Typography>
          Confirm you want to delete this {props.itemToDelete.toLowerCase()} by
          typing its name: <b>{props.deleteItemName}</b>
        </Typography>
        <div style={{ width: 300, marginTop: "1em" }}>
          <TextFieldNonFieldEditor
            id={`${props.itemToDelete}_deleteTextField`}
            label=""
            placeHolder={props.deleteItemName}
            value={deleteText}
            onChange={(v) => setDeleteText(v)}
            required={true}
            multiline={false}
            disabled={false}
            errObj={errors.find((e) => e.id === "deleteText")}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button
          variant="contained"
          disabled={deleteText !== props.deleteItemName}
          onClick={onDeleteClick}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const DialogTitleHeading = styled.div`
  display: flex;
  font-size: 20px !important;
  margin-bottom: 1em;
`;
const Spacer = styled.div`
  margin: 0.5em;
`;
