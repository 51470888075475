import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  List,
  TablePagination,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Employer, MASK_TYPE } from "types";
import styled from "styled-components";
import { EmployerSearchListProps } from "./EmployerSearchList";
import { useEffect, useState } from "react";
import AddressDisplayer from "components/fields/AddressDisplay";
import MaskedValue from "common/MaskedValue";
import { openConfirm } from "components/confirmBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const EmployerList = (props: EmployerSearchListProps) => {
  //setting page to 0(1st page) if the filtered list is changed
  //to have correct filtering user experience
  useEffect(() => {
    setPage(0);
  }, [props.employers]);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  return (
    <div>
      <FlexDiv>
        <ListingHeading>Employers</ListingHeading>
        <Button
          variant="contained"
          onClick={props.onAddClick}
          disabled={props.isReadOnlyView}
        >
          Add
        </Button>
      </FlexDiv>
      {(!props.employers || props.employers.length === 0) && (
        <Content>
          <div>There are no employers.</div>
        </Content>
      )}
      {props.employers.length > 0 && (
        <List>
          {props.employers
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((e, index) => {
              return (
                <PrimaryText
                  employer={e}
                  key={index}
                  onEditClick={(e) => {
                    props.onEditClick(e);
                  }}
                  onDeleteClick={(e) => {
                    props.onDeleteClick(e.employerId ?? "");
                  }}
                  isReadOnlyView={props.isReadOnlyView}
                />
              );
            })}
        </List>
      )}
      {props.employers.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={props.employers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => {
            setPage(p);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
          }}
        />
      )}
    </div>
  );
};

const PrimaryText = (props: {
  employer: Employer;
  onEditClick(employer: Employer): void;
  onDeleteClick(employer: Employer): void;
  isReadOnlyView: boolean;
}) => {
  return (
    <Accordion sx={{ width: 630 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        style={{ marginRight: "0.5em" }}
        id={props.employer.employerId + " - header"}
      >
        <PrimarySection>
          <NameSection>{props.employer.employerName}</NameSection>
          <IdSection>{props.employer.employerId} </IdSection>

          <PhoneNumberSection>
            {MaskedValue(
              MASK_TYPE.PHONE_NUMBER,
              props.employer.phoneNumber ?? ""
            )}
          </PhoneNumberSection>
        </PrimarySection>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", justifyContent: "right" }}>
        <div style={{ flex: 1 }}>
          <AddressDisplayer
            address={props.employer.employerAddress ?? {}}
            displayAddressLabel={true}
          />
        </div>
        <IconContainer>
          <IconButton
            disabled={props.isReadOnlyView}
            aria-label="edit"
            onClick={() => {
              props.onEditClick(props.employer);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            disabled={props.isReadOnlyView}
            aria-label="delete"
            onClick={() => {
              openConfirm({
                title: "Delete Employer",
                description: `${props.employer.employerName}. Continue?`,
                callback: () => {
                  props.onDeleteClick(props.employer);
                },
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </IconContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default EmployerList;

const NameSection = styled(Typography)`
  width: 250px;
  flex: 6;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const IdSection = styled(Typography)`
  width: 150px;
  flex: 2;
  color: #0000008a;
`;
const PhoneNumberSection = styled(Typography)`
  width: 150px;
  text-align: right;
`;
const PrimarySection = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  margin-top: 3em;
  display: flex;
  justify-content: center;
`;

const ListingHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const FlexDiv = styled.div`
  display: flex;
  width: 630px;
  margin-bottom: 5px;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: right;
  align-self: flex-start;
`;
