import { Divider } from "@mui/material";
import { IdName, Withdrawal } from "types";
import SummaryRowTemplate from "pages/sessionHistory/components/SummaryRowTemplate";
import styled from "styled-components";
import * as fields from "pages/transactions/withdrawalFieldDefinitions";
import { getMaskedMoneyValue } from "common/functions";
import moment from "moment";

type SummaryRowWithdrawalProps = {
  transaction: Withdrawal;
  withdrawFromOptions: IdName[];
};

export default function SummaryRowWithdrawal(props: SummaryRowWithdrawalProps) {
  const currencyType = props.transaction.currencyType ?? "";
  const fromLabel = fields.withdrawalFrom.getOptionLabel
    ? fields.withdrawalFrom.getOptionLabel(
        props.transaction.from ?? "",
        props.withdrawFromOptions
      )
    : "";
  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);
  return (
    <SummaryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transaction="Withdrawal"
      debit={transactionAmount}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection>From</TextSection>
        <TextSection> To</TextSection>
        <TextSection>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>
          {fromLabel === "" ? "Deleted Account" : fromLabel}
        </TextSection>
        {/* <TextSection>{props.transaction.to}</TextSection> */}
        <TextSection>Session Balance</TextSection>
        <TextSection>
          {`${transactionAmount} ${currencyType.toUpperCase()}`}
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
  text-align: center;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;
