import { add, getPrimeRate } from "common/functions";
import {
  BoolField,
  CreditAppLoan,
  DateField,
  FIELD_TYPE,
  MASK_TYPE,
  PickListField,
  RadioGroupField,
  TextFieldType,
} from "types";
import { v4 } from "uuid";

export const requestedAmount: TextFieldType<CreditAppLoan> = {
  id: `requestedAmount_${v4()}`,
  label: "Requested Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => account.requestedAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    requestedAmount: (value as unknown) as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const paymentAmount: TextFieldType<CreditAppLoan> = {
  id: `paymentAmount_${v4()}`,
  label: "Payment Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => account.paymentAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    paymentAmount: (value as unknown) as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const loanTermLength: PickListField<CreditAppLoan> = {
  id: `loanTermLength_${v4()}`,
  label: "Term Length (years)",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppLoan) => account.termLength,
  setValue: (value, account) => ({
    ...account,
    termLength: value,
  }),
  options: [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const reason: TextFieldType<CreditAppLoan> = {
  id: "reason",
  label: "Reason",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => account.reason,
  setValue: (value, account) => ({ ...account, reason: value }),
  multiline: true,
  isRequired: true,
};

export const loanType: RadioGroupField<CreditAppLoan> = {
  id: `loanType_${v4()}`,
  label: "Loan Type",
  trueLabel: "Secured",
  falseLabel: "Unsecured",
  defaultValue: "Secured",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: CreditAppLoan) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
    security: "",
  }),
  isRequired: true,
};

export const loanSecurity: TextFieldType<CreditAppLoan> = {
  id: `loanSecurity_${v4()}`,
  label: "Security",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => account.security,
  setValue: (value, account) => ({
    ...account,
    security: value,
  }),
  checkIfRequired: (account: CreditAppLoan) => {
    return account.type === "Secured" ?? false;
  },
};

export const loanInterestType: RadioGroupField<CreditAppLoan> = {
  id: `loanInterestType_${v4()}`,
  label: "Interest Type",
  trueLabel: "Fixed",
  falseLabel: "Variable",
  defaultValue: "Fixed",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: CreditAppLoan) => account.interestType,
  setValue: (value, account) => ({
    ...account,
    interestType: value,
  }),
  isRequired: true,
};

export const loanPrimeRate: TextFieldType<CreditAppLoan> = {
  id: `loanPrimeRate_${v4()}`,
  label: "Prime Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => getPrimeRate(),
  setValue: (value, account) => ({
    ...account, // just returning account as prime rate is read only field and value is fetched from globalSettings
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE,
};

export const loanInterestRate: TextFieldType<CreditAppLoan> = {
  id: `loanInterestRate_${v4()}`,
  label: "Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => account.interestRate,
  setValue: (value, account) => ({
    ...account,
    interestRate: value,
  }),
  isRequired: true,
  mask: MASK_TYPE.PERCENTAGE,
  checkIfAllowNegative: (account: CreditAppLoan) => {
    return account.interestType === "Fixed" ? false : true;
  },
};

export const loanTotalInterestRate: TextFieldType<CreditAppLoan> = {
  id: `loanTotalInterestRate_${v4()}`,
  label: "Total Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => {
    let tir = add(
      ((account.interestRate ?? 0) as unknown) as number,
      ((getPrimeRate() ?? 0) as unknown) as number
    );
    if (tir > 0) return tir.toString();
    else return "0";
  },
  setValue: (value, account) => ({
    ...account,
    totalInterestRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE,
};

export const hasJointApplication: BoolField<CreditAppLoan> = {
  id: `hasJointApplication`,
  label: "Joint Application",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (account: CreditAppLoan) => account.hasJointApplication,
  setValue: (value, account) => ({
    ...account,
    hasJointApplication: value,
    jointApplication: value ? account.jointApplication : undefined,
  }),
};

export const loanJointAppName: TextFieldType<CreditAppLoan> = {
  id: `loanJointAppName_${v4()}`,
  label: "Name",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => account.jointApplication?.name,
  setValue: (value, account) => ({
    ...account,
    jointApplication: { ...account.jointApplication, name: value },
  }),
  checkIfRequired: (account: CreditAppLoan) => {
    return account.hasJointApplication ?? false;
  },
};

export const loanJointAppEmployerName: TextFieldType<CreditAppLoan> = {
  id: `loanJointAppEmployerName_${v4()}`,
  label: "Employer Name",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => account.jointApplication?.employerName,
  setValue: (value, account) => ({
    ...account,
    jointApplication: { ...account.jointApplication, employerName: value },
  }),
  checkIfRequired: (account: CreditAppLoan) => {
    return account.hasJointApplication ?? false;
  },
};

export const loanJointAppJobTitle: TextFieldType<CreditAppLoan> = {
  id: `loanJointAppJobTitle_${v4()}`,
  label: "Job Title",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) => account.jointApplication?.jobTitle,
  setValue: (value, account) => ({
    ...account,
    jointApplication: { ...account.jointApplication, jobTitle: value },
  }),
  checkIfRequired: (account: CreditAppLoan) => {
    return account.hasJointApplication ?? false;
  },
};

export const loanJointAppEmploymentStartDate: DateField<CreditAppLoan> = {
  id: "loanJointAppEmploymentStartDate",
  label: "Employment Start Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: CreditAppLoan) =>
    account.jointApplication?.employmentStartDate,
  setValue: (value, account) => ({
    ...account,
    jointApplication: {
      ...account.jointApplication,
      employmentStartDate: value,
    },
  }),
  checkIfRequired: (account: CreditAppLoan) => {
    return account.hasJointApplication ?? false;
  },
};

export const loanJointAppMonthlyIncome: TextFieldType<CreditAppLoan> = {
  id: `loanJointAppMonthlyIncome_${v4()}`,
  label: "Monthly Income",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLoan) =>
    account.jointApplication?.monthlyIncome?.toString(),
  setValue: (value, account) => ({
    ...account,
    jointApplication: {
      ...account.jointApplication,
      monthlyIncome: (value as unknown) as number,
    },
  }),
  checkIfRequired: (account: CreditAppLoan) => {
    return account.hasJointApplication ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};
