import {
  getAccountTypeLabel,
  getPersonalGICTypeLabel,
  getPersonalMutualFundsTypeLabel,
  getSavingsTypeLabel,
} from "common/functions";
import MaskedValue from "common/MaskedValue";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  Account,
  AccountAdditionalInfo,
  BusinessChequing,
  GIC,
  MASK_TYPE,
  MutualFunds,
  PersonalChequing,
  PersonalSavings,
} from "types";
import AdditionalInformation from "components/clientInfoTemplate/AdditionalInformation";

export type Props = {
  accounts: Account[];
};

function AssetsDisplay(props: Props) {
  const savingsAccounts: PersonalSavings[] = props.accounts.filter(
    (a) =>
      a.accountType === "PERSONAL_SAVINGS" ||
      a.accountType === "BUSINESS_SAVINGS"
  );

  const chequingAccounts: PersonalChequing[] = props.accounts.filter(
    (a) =>
      a.accountType === "PERSONAL_CHEQUING" ||
      a.accountType === "BUSINESS_CHEQUING"
  );

  const mutualfundsAccounts: MutualFunds[] = props.accounts.filter(
    (a) => a.accountType === "MUTUAL_FUNDS"
  );

  const gicAccounts: GIC[] = props.accounts.filter(
    (a) => a.accountType === "GIC"
  );

  const location = useLocation();
  let isBusinessClient = location.pathname.includes("businessclient");
  return (
    <>
      {props.accounts.length > 0 && (
        <AccountContainer>
          {chequingAccounts.map((a, index) => {
            return (
              <AccountRowDisplay
                key={index}
                accountType={a.accountType}
                number={a.accountNumber || ""}
                balance={a.balance?.toString() || "0"}
                //isBalanceInRed={add(a.overdraftLimit ?? 0, a.balance ?? 0) < 0}
                // as per discussion with business team in project check-in meeting dated May 27, 2022
                // chequing balance should not go below overdraft limit. Therefore, displaying balance
                // in red when negative but under overdraft limit
                isBalanceInRed={(a.balance || 0) < 0}
                accountAdditionalInfo={
                  a.accountType === "BUSINESS_CHEQUING"
                    ? {
                        hasOverdraftProtection: a.overdraftProtection,
                        overdraftType: (a as BusinessChequing).overdraftType,
                        overdraftLimit: a.overdraftLimit,
                      }
                    : {
                        hasOverdraftProtection: a.overdraftProtection,
                        overdraftLimit: a.overdraftLimit,
                      }
                }
              />
            );
          })}
          {savingsAccounts.map((a, index) => {
            return (
              <AccountRowDisplay
                key={index}
                accountType={a.accountType}
                number={a.accountNumber || ""}
                balance={a.balance?.toString() || "0"}
                isBalanceInRed={(a.balance || 0) < 0}
                accountAdditionalInfo={{
                  type: getSavingsTypeLabel(a.type ?? ""),
                }}
              />
            );
          })}
          {gicAccounts.map((a, index) => {
            return (
              <AccountRowDisplay
                key={index}
                accountType={a.accountType}
                number={a.accountNumber || ""}
                balance={a.balance?.toString() || "0"}
                isBalanceInRed={(a.balance || 0) < 0}
                accountAdditionalInfo={{
                  type: isBusinessClient
                    ? "Non-RSP"
                    : getPersonalGICTypeLabel(a.type ?? ""),
                }}
              />
            );
          })}
          {mutualfundsAccounts.map((a, index) => {
            return (
              <AccountRowDisplay
                key={index}
                accountType={a.accountType}
                number={a.accountNumber || ""}
                balance={a.balance?.toString() || "0"}
                isBalanceInRed={(a.balance || 0) < 0}
                accountAdditionalInfo={{
                  type: isBusinessClient
                    ? "Non-RSP"
                    : getPersonalMutualFundsTypeLabel(a.type ?? ""),
                }}
              />
            );
          })}
        </AccountContainer>
      )}
    </>
  );
}

export default AssetsDisplay;

function AccountRowDisplay(props: {
  accountType: string;
  number: string;
  balance: string;
  isBalanceInRed: boolean;
  accountAdditionalInfo?: AccountAdditionalInfo;
}) {
  return (
    <AccountRow>
      <AccountType>
        {props.accountType === "GIC"
          ? "GIC"
          : getAccountTypeLabel(props.accountType)}

        <AdditionalInformation
          accountType={props.accountType}
          additionalInfo={props.accountAdditionalInfo ?? {}}
        />
      </AccountType>

      <AccountNumber>{props.number}</AccountNumber>
      <Balance isBalanceInRed={props.isBalanceInRed}>
        {MaskedValue(MASK_TYPE.MONEY, props.balance)}
      </Balance>
    </AccountRow>
  );
}

const AccountType = styled.div`
  font-weight: 500;
  width: 120px;
  word-wrap: break-word;
  font-size: 14px;
  padding-bottom: 6px;
  padding-right: 15px;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const AccountContainer = styled.div`
  padding-bottom: 0.5em;
`;

const AccountRow = styled.div`
  display: flex;
  justify-content: right;
  padding: 6px 3px 0px 3px;
`;
const AccountNumber = styled.div`
  width: 80px;
  font-size: 14px;
  color: #0000008a;
`;

const Balance = styled.div<{ isBalanceInRed: boolean }>`
  width: 100px;
  font-size: 14px;
  text-align: right;
  color: ${(p) => (p.isBalanceInRed ? "#ee1d1d;" : "#000000de;")};
`;
