import { ForeignExchangeConverter } from "types";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import * as fields from "pages/transactions/foreignExchangeConverterFieldDefinitions";
import { getExchangeRate } from "common/functions";
import FieldEditor from "components/FieldEditor";
import { useState } from "react";
import { useReduxState } from "store";
export type Props = {
  onClose?(): void;
};

export default function ForeignExchangeCurrencyConverter(props: Props) {
  const [state, setState] = useState<ForeignExchangeConverter>({});

  const commonProps = {
    state: state,
    errors: [],
    setState: (state: ForeignExchangeConverter) => {
      setState(state);
    },
  };

  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open
        id={"foreignexchangecurrencyconvereter"}
      >
        <DialogTitle>
          <Typography>Foreign Exchange Currency Converter</Typography>
        </DialogTitle>
        <DialogContent>
          <Container>
            <FieldEditor<ForeignExchangeConverter>
              field={fields.currencyExchanging}
              {...commonProps}
            />
            <FieldEditor<ForeignExchangeConverter>
              field={fields.transactionType}
              {...commonProps}
            />
          </Container>

          <FlexDiv style={{ marginTop: "-1em" }}>
            <Container>
              <FieldEditor<ForeignExchangeConverter>
                field={fields.convertCurrency}
                {...commonProps}
              />
            </Container>
            <CurrencyDiv>USD</CurrencyDiv>
            <Spacer />
            {!!state.transactionType && (
              <TwoColumnDisplay
                label="Rate"
                value={getExchangeRate(state, globalSettings).toFixed(5)}
              />
            )}
          </FlexDiv>
          <FlexDiv style={{ marginTop: "-1em" }}>
            <Container>
              <FieldEditor<ForeignExchangeConverter>
                field={fields.intoCurrency}
                {...commonProps}
              />
            </Container>
            <CurrencyDiv>CAD</CurrencyDiv>
            <Spacer />
            {!!state.transactionType && (
              <TwoColumnDisplay
                label="Rate"
                value={(1 / getExchangeRate(state, globalSettings)).toFixed(5)}
              />
            )}
          </FlexDiv>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function TwoColumnDisplay(props: { label: string; value: string }) {
  return (
    <FlexDiv style={{ marginTop: "8px" }}>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </FlexDiv>
  );
}

const Label = styled.label`
  color: #0000008a;
  padding-right: 10px;
  margin-top: -0.5em;
`;
const Value = styled.div`
  padding-left: 5px;
  margin-top: -0.5em;
`;
const Container = styled.div`
  width: 300px;
`;
const CurrencyDiv = styled.div`
  margin-left: 5px;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
const Spacer = styled.div`
  margin: 0 1em;
`;
