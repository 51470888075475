import { getMaskedMoneyValue } from "common/functions";
import MaskedValue from "common/MaskedValue";
import { openConfirm } from "components/confirmBox";
import moment from "moment";
import { AccountType, MASK_TYPE, Withdrawal } from "types";
import AccountHistoryRowTemplate from "pages/accountHistory/components/AccountHistoryRowTemplate";

type AccountHostoryRowWithdrawalProps = {
  isSelectedAccountClosed: boolean;
  accountType: AccountType | undefined;
  balance: number;
  transaction: Withdrawal;
  onEditClick(): void;
  onDeleteClick(): void;
  onDuplicateClick(): void;
  isReadOnlyView: boolean;
};

export default function AccountHistoryRowWithdrawal(
  props: AccountHostoryRowWithdrawalProps
) {
  const transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  return (
    <AccountHistoryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transactionType={
        props.transaction.transactionType === "TRANSFER"
          ? "Transfer"
          : props.transaction.transactionType === "GL_ENTRY"
          ? "GL Entry"
          : "Withdrawal"
      }
      transactionDescription={props.transaction.transactionDescription}
      debit={transactionAmount}
      balance={MaskedValue(MASK_TYPE.MONEY, props.balance.toString())}
      isSelectedAccountClosed={props.isSelectedAccountClosed}
      accountType={props.accountType}
      onEditClick={props.onEditClick}
      onDeleteClick={() => {
        openConfirm({
          title: "Delete Transaction?",
          description:
            props.transaction.transactionType === "TRANSFER"
              ? `Transfer - ${transactionAmount}`
              : props.transaction.transactionType === "GL_ENTRY"
              ? `GL Entry - ${transactionAmount}`
              : `Withdrawal - ${transactionAmount}`,
          okBtnText: "Delete",
          callback: () => {
            props.onDeleteClick();
          },
        });
      }}
      onDuplicateClick={() => {
        openConfirm({
          title: "Duplicate Transaction?",
          description:
            props.transaction.transactionType === "TRANSFER"
              ? `Transfer - ${transactionAmount}`
              : props.transaction.transactionType === "GL_ENTRY"
              ? `GL Entry - ${transactionAmount}`
              : `Withdrawal - ${transactionAmount}`,
          okBtnText: "Duplicate",
          callback: () => {
            props.onDuplicateClick();
          },
        });
      }}
      isReadOnlyView={props.isReadOnlyView}
    />
  );
}
