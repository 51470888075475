import {
  GlobalSettingsRoute,
  LessonsRoute,
  OutstandingOverrideRoute,
  StudentSessionsRoute,
} from "components/paths";
import { useHistory } from "react-router";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import UserInfo from "components/user/UserInfo";
import { getLoginInfo } from "common/azureAD";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import SideMenu from "components/sidemenu/SideMenu";
import { Button, Typography } from "@mui/material";
import AppBarWithMenuTemplate from "components/mainTemplate/AppBarWithMenuTemplate";
import { getSize } from "common/functions";
import useEventListener from "components/hooks/useEventListener";
import { desktopViewThreshold } from "common/helpers";

type Props = {
  title?: string;
  children: ReactNode;
  appBarRight?: ReactNode;
  isLoading: boolean;
  noLessonExists?: boolean;
};

export default function AdminTemplate(props: Props) {
  const history = useHistory();
  const loginInfo = getLoginInfo();

  const [windowSize, setWindowSize] = useState(getSize());
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  useEventListener("resize", () => setWindowSize(getSize()));
  useEventListener("scroll", () => {
    setIsScrolled(window.scrollY > 0);
  });
  const adminMenuGroups = [
    {
      label: "",
      items: [
        {
          label: "Student Session",
          icon: <GroupIcon />,
          onClick: () => {
            history.push(StudentSessionsRoute);
          },
        },
        {
          label: "Outstanding Overrides",
          icon: <ReceiptLongIcon />,
          onClick: () => {
            history.push(OutstandingOverrideRoute);
          },
        },

        {
          label: "Global Settings",
          icon: <SettingsIcon />,
          onClick: () => {
            history.push(GlobalSettingsRoute);
          },
        },
      ],
    },
  ];

  return (
    <OuterContainer>
      <AppBarWithMenuTemplate
        menu={
          <SideMenu
            hideTitleBar={false}
            label={"NAITLab Financial"}
            menuGroups={adminMenuGroups}
            subTitleLabel="Administration"
            onClickSubtitle={() => {
              history.push(LessonsRoute);
            }}
          />
        }
        appBarTitle={props.title ?? ""}
        appBarRightContent={
          <>
            {props.appBarRight}
            <UserInfo {...loginInfo} />
          </>
        }
        contentBackgroundColor={"#F3F6F9"}
        isLoading={props.isLoading}
        isDesktopView={windowSize.width >= desktopViewThreshold}
        isScrolled={isScrolled}
      >
        {!!props.noLessonExists && (
          <NoLessonContainer>
            <Typography sx={{ marginTop: "4em" }} variant="h4">
              There are no Lessons. Please add a Lesson and try again!
            </Typography>

            <div style={{ marginTop: "5em" }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  history.push(LessonsRoute);
                }}
              >
                <HomeIcon style={{ marginRight: 5 }} />
                Go To Home
              </Button>
            </div>
          </NoLessonContainer>
        )}
        {!props.noLessonExists && <div>{props.children}</div>}
      </AppBarWithMenuTemplate>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  background-color: #f3f6f9;
  min-height: 100vh;
`;

const NoLessonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 2em auto;
  min-width: 1000px;
`;
