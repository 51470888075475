
import {  
  FIELD_TYPE,  
  MASK_TYPE,
   TextFieldType,
    BillPayment,
} from "types";

export const billPaymentPayee: TextFieldType<BillPayment> = {
  id: "billPaymentPayee",
  label: "Payee",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: BillPayment) => transaction.payee,
  setValue: (value, transaction) => ({
    ...transaction,
    payee: value,
  }),
  isRequired: true,
  maxLength: 50
};

export const billPaymentPayeeAccountNumber: TextFieldType<BillPayment> = {
  id: "billPaymentPayeeAccountNumber",
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: BillPayment) => transaction.payeeAccountNumber,
  setValue: (value, transaction) => ({
    ...transaction,
    payeeAccountNumber: value,
  }),
  
  isRequired: true
};

export const billPaymentAmount: TextFieldType<BillPayment> = {
  id: "billPaymentAmount",
  label: "Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: BillPayment) => transaction.amount ? transaction.amount.toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    amount: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
};