import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { Icons } from "@nait-aits/ui";
import HomeIcon from "@mui/icons-material/Home";
import { IconButton } from "@mui/material";

export type Props = {
  label?: string;
  logo?: ReactElement;
  menuGroups: MenuGroup[];
  hideTitleBar?: boolean;
  hideLogo?: boolean;
  subTitleLabel?: string | JSX.Element;
  onClickSubtitle?(): void;
};

type MenuItem = {
  label: string;
  label2?: string | ReactNode;
  label2Color?: string;
  icon?: ReactNode;
  onClick?(): void;
  selected?: boolean;
};

type MenuGroup = {
  label: string;
  items: MenuItem[];
};

const SideMenu = ({
  logo = <Icons.NAIT />,
  label,
  menuGroups,
  hideTitleBar,
  hideLogo,
  subTitleLabel,
  onClickSubtitle,
}: Props) => {
  return (
    <Nav
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Container>
        {!hideTitleBar && (
          <Title>
            {!hideLogo ? logo : <svg className="spacer" />}
            <span>{label}</span>
          </Title>
        )}
        <SubTitle>
          <div style={{ width: 200 }}>{subTitleLabel}</div>
          <div style={{ textAlign: "right", flex: 1 }}>
            <IconButton style={{ color: "white" }} onClick={onClickSubtitle}>
              <HomeIcon />
            </IconButton>
          </div>
        </SubTitle>
        {menuGroups.map((section, isection) => (
          <Section key={`section_${isection}`}>
            <h3>{section.label}</h3>
            {section.items.map((item, iitem) => (
              <MenuItemDiv
                key={`menu_${iitem}`}
                className={item.selected ? "selected" : ""}
                onClick={() => {
                  item.onClick?.();
                }}
                noIcon={!item.icon}
              >
                {item.icon}
                <span>{item.label}</span>
                <span style={{ color: item.label2Color, fontWeight: "bolder" }}>
                  {item.label2}
                </span>
              </MenuItemDiv>
            ))}
          </Section>
        ))}
      </Container>
    </Nav>
  );
};

const Nav = styled.div`
  color: white;
  font-size: 1rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
`;
const Container = styled.div`
  height: 100vh;
  background-color: #1b3451;
`;
const Title = styled.div`
  background-color: #051e34;
  text-transform: uppercase;
  padding: 0.5em 0.25em;
  font-weight: 500;

  & > svg {
    height: 3rem !important;
    width: 3rem !important;
    vertical-align: middle;
    &.spacer {
      width: 0 !important;
    }
  }
  & > span {
    padding-left: 0.5em;
  }
`;
const SubTitle = styled.div`
  background-color: #051e34;
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  font-weight: 400;
  border-bottom: 1px solid #666;
  border-top: 1px solid #666;
`;
const Section = styled.div`
  border-bottom: 1px solid #666;
  padding: 1em 0;
  & > h3 {
    margin: 0.5em 1em;
    font-weight: 500;
    font-size: 1rem;
  }
`;
const MenuItemDiv = styled.div<{ noIcon?: boolean }>`
  padding: 0.5em 1em;
  cursor: pointer;
  display: flex;
  opacity: 0.8;
  font-size: 0.875rem;

  & > span {
    ${(props) => !props.noIcon && "padding-left: 1em;"}
    line-height: 1.5em;
    font-weight: 500;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.selected {
    color: #669df6;
    cursor: default;
  }
`;

export default SideMenu;
