import { CommonFieldProps, ChequesArrayField, Entity, MASK_TYPE } from "types";
import TextFieldNonFieldEditor from "./TextFieldNonFieldEditor";

export default function ChequesEditor<T extends Entity>({
  field,
  state,
  setState,
  errors,
}: CommonFieldProps<T>) {
  const chequesArrayField = field as ChequesArrayField<T>;
  const nums = chequesArrayField.getValue(state);

  function onValueChange(ordinal: number, value: number) {
    const newNums = [...nums];
    newNums[ordinal] = value;
    setState(chequesArrayField.setValue(newNums, state));
  }

  return (
    <>
      {nums.map((n, i) => (
        <div key={`cheque_${i}`}>
          <TextFieldNonFieldEditor
            id={`cheque_${i}`}
            label={`Cheque ${i + 1}`}
            value={n ? n.toString() : ""}
            onChange={(v) => onValueChange(i, (v as unknown) as number)}
            required={false}
            multiline={false}
            mask={MASK_TYPE.MONEY}
            disabled={false}
          />
        </div>
      ))}
    </>
  );
}
