import MaskedValue from "common/MaskedValue";
import styled from "styled-components";
import { MASK_TYPE, PayoutCash } from "types";
import * as fields from "pages/transactions/payoutCashFieldDefinitions";

export default function PayoutCashTotal(props: { transaction: PayoutCash }) {
  const totalCashAmount: number = (fields.totalCashAmount.getValue(
    props.transaction
  ) as unknown) as number;

  const cadValue = fields.cashCADValue.getValue(props.transaction) ?? 0;

  return (
    <TotalSection>
      <LeftSection>
        <LightBold>Cash Total</LightBold>
        {MaskedValue(MASK_TYPE.MONEY, totalCashAmount.toString() ?? "0")}
        <Spacer />
        {props.transaction.cash?.cashCurrencyType?.toUpperCase()}
      </LeftSection>
      {props.transaction.cash?.cashCurrencyType === "usd" && (
        <RightSection>
          ( <LightBold>CAD Value </LightBold>{" "}
          {MaskedValue(MASK_TYPE.MONEY, cadValue.toString())}
          <Spacer />
          CAD)
        </RightSection>
      )}
    </TotalSection>
  );
}

const LightBold = styled.div`
  font-weight: 500;
  margin-right: 10px;
`;
const LeftSection = styled.div`
  flex: 1;
  display: flex;
`;
const RightSection = styled.div`
  text-align: right;
  display: flex;
`;
const Spacer = styled.div`
  margin-right: 5px;
`;
const TotalSection = styled.div`
  margin: 1em;
  display: flex;
  align-items: center;
`;
