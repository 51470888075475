import { provinceOptions } from "common/helpers";
import { AddressField, DateField, FIELD_TYPE, MASK_TYPE, PersonalClient, PickListField, TextFieldType } from "types";


export const clientId: TextFieldType<PersonalClient> = {
  id: "clientId",
  label: "Client ID",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.identification?.clientId,
  setValue: (value, client) => ({
    ...client,
    identification: { ...client.identification, clientId: value },
  }),
  disabled: true,
};


export const legalFirstName: TextFieldType<PersonalClient> = {
  id: "legalFirstName",
  label: "Legal First Name",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.legalFirstName,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, legalFirstName: value },
  }),
  disabled: true,  
  
};
export const legalLastName: TextFieldType<PersonalClient> = {
  id: "legalLastName",
  label: "Legal Last Name",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.legalLastName,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, legalLastName: value },
  }),
  disabled: true,  
};

export const dateOfBirth: DateField<PersonalClient> = {
  id: "dateOfBirth",
  label: "Date Of Birth",
  type: FIELD_TYPE.DATE,
  getValue: (client: PersonalClient) => client.information?.dateOfBirth,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, dateOfBirth: value },
  }),
  disabled: true,  
};

export const maritalStatus: PickListField<PersonalClient> = {
  id: "maritalStatus",
  label: "Marital Status",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.information?.maritalStatus,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, maritalStatus: value },
  }),
  options: [
    { id: "single", name: "Single" },
    { id: "married", name: "Married" },
    { id: "commonLaw", name: "Common Law" },
    { id: "divorced", name: "Divorced" },
  ],
  getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  disabled: true,
};

export const phoneNumber: TextFieldType<PersonalClient> = {
  id: "phoneNumber",
  label: "Phone Number",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.phoneNumber,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, phoneNumber: value },
  }),
  mask: MASK_TYPE.PHONE_NUMBER,
  disabled: true,
};

export const clientCurrentAddress: AddressField<PersonalClient> = {
  id: "clientCurrentAddress",
  label: "Client Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (client: PersonalClient) => client.information?.clientCurrentAddress,
  setValue: (value, client) => ({
    ...client,
    information: { ...client.information, clientCurrentAddress: value },
  }),
  disabled: true,
  isDated: true,
  options: provinceOptions,
  isRequired: true,
};



