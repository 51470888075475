import { useEffect, useState } from "react";
import { FieldError, SellDraft } from "types";
import styled from "styled-components";
import { Button, Divider } from "@mui/material";
import * as fields from "pages/transactions/sellDraftFieldDefinitions";
import FieldEditor from "components/FieldEditor";
import { add, getMaskedMoneyValue, requiredTextField } from "common/functions";
import SellDraftFeeSection from "pages/transactions/components/SellDraftFeeSection";
import CurrencyToggleMenu from "pages/transactions/components/CurrencyToggleMenu";
import ForeignExchangeCurrencyConverter from "pages/transactions/components/ForeignExchangeCurrencyConverter";
import { useReduxState } from "store";

export type Props = {
  transaction: SellDraft;
  onSaveClick(transaction: SellDraft): void;
};

export default function SellDraftForm({ transaction, onSaveClick }: Props) {
  const [state, setState] = useState<SellDraft>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [openConverterDialog, setOpenConverterDialog] = useState<boolean>(
    false
  );

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: SellDraft) => {
      setState(state);
    },
  };

  function saveSellDraft(transaction: SellDraft) {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      onSaveClick(transaction);
    }
  }

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.draftPayee, state),
      ...requiredTextField(fields.draftAmount, state),
      ...requiredTextField(fields.draftSerialNumber, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  function handleCurrencyToggle(selectedButton: number) {
    commonProps.setState({
      ...commonProps.state,
      currencyType: selectedButton === 0 ? "cad" : "usd",
    });
  }

  return (
    <div style={{ padding: "10px" }}>
      <FlexDiv>
        <TransactionHeading>Sell Draft</TransactionHeading>
        <Button
          onClick={() => {
            setOpenConverterDialog(true);
          }}
        >
          Converter
        </Button>
      </FlexDiv>

      <CurrencyToggleContainer>
        <CurrencyToggleMenu
          onToggle={(selectedButton) => {
            handleCurrencyToggle(selectedButton);
          }}
        />
      </CurrencyToggleContainer>
      <Container>
        <FieldEditor<SellDraft> field={fields.draftPayee} {...commonProps} />
        <FieldEditor<SellDraft> field={fields.draftAmount} {...commonProps} />
        <FieldEditor<SellDraft>
          field={fields.draftSerialNumber}
          {...commonProps}
        />
        <FieldEditor<SellDraft> field={fields.draftWaiveFee} {...commonProps} />
      </Container>

      <Divider />
      <SellDraftFeeSection transaction={commonProps.state} />
      <Divider />
      <TotalAmountSection
        transaction={state}
        onSaveClick={(updatedTransaction) => {
          saveSellDraft(updatedTransaction);
        }}
      />
      {openConverterDialog && (
        <ForeignExchangeCurrencyConverter
          onClose={() => {
            setOpenConverterDialog(false);
          }}
        />
      )}
    </div>
  );
}

function TotalAmountSection(props: Props) {
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
  const draftCADValue =
    ((fields.draftAmountCADValue.getValue(
      props.transaction
    ) as unknown) as number) ?? 0;

  const totalAmount = add(
    draftCADValue ?? 0,
    props.transaction.waiveFee === true ? 0 : 10
  );

  const updatedSellDraftTransaction: SellDraft = {
    ...props.transaction,
    draftAmountCADValue: draftCADValue,
    fee: props.transaction.waiveFee === true ? 0 : 10,
    isApprovalPending:
      draftCADValue >= (globalSettings.draftOverrideDenomination ?? 0)
        ? true
        : false,
    amount: totalAmount,
  };

  return (
    <TotalSection>
      <LeftSection>
        <LightBold>Total Amount</LightBold>
        {`${getMaskedMoneyValue(totalAmount ?? 0)} CAD`}
      </LeftSection>
      <RightSection>
        <Button
          disabled={draftCADValue <= 0}
          onClick={() => props.onSaveClick(updatedSellDraftTransaction)}
        >
          Save
        </Button>
      </RightSection>
    </TotalSection>
  );
}

const TransactionHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const CurrencyToggleContainer = styled.div`
  display: flex;
  justify-content: right;
  margin: -1em 0.5em;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  width: 300px;
  margin: auto;
`;

const LightBold = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-right: 20px;
`;
const TotalSection = styled.div`
  margin: 1em;
  display: flex;
  align-items: center;
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
`;
const RightSection = styled.div`
  text-align: right;
`;
