import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { BusinessClient } from "types";

//uncomment if you need msal auth
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "businessClientInformation";
const baseURL = `${process.env.REACT_APP_API_BASE}/client`;

type State = {
  saveBusinessClient: ReducerState<SaveBusinessClientReturn>;
  saveInformationInstructorFeedback: ReducerState<SaveInstructorFeedbackReturn>;
};

type SaveBusinessClientReturn = {
  clientId: number;
};

let saveBusinessClient = createAsyncAction<
  SaveBusinessClientReturn, //return type
  {
    client: BusinessClient;
    lessonId: number;
    onComplete?(retValue: SaveBusinessClientReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "saveBusinessClient",
  url: baseURL + "/saveBusinessClient",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.saveBusinessClient = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveBusinessClient.isLoading = false;
    state.saveBusinessClient.data = action.payload;
  },
  rejected: (state, action) => {
    state.saveBusinessClient.isLoading = false;
    state.saveBusinessClient.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as SaveBusinessClientReturn);
  },
  //   transformQueryParams: (params) => {
  //     return {
  //         client: JSON.stringify(params.client)
  //     }
  //   }
});

type SaveInstructorFeedbackReturn = {
  success: boolean;
  message: string;
};

let saveInformationInstructorFeedback = createAsyncAction<
  SaveInstructorFeedbackReturn, //return type
  {
    studentId: string;
    lessonId: number;
    clientId: string;
    feedback?: string;
    onComplete?(retValue: SaveInstructorFeedbackReturn): void;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "saveInformationInstructorFeedback",
  url: baseURL + "/saveInformationFeedbackBusinessClient",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.saveInformationInstructorFeedback = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveInformationInstructorFeedback.isLoading = false;
    state.saveInformationInstructorFeedback.data = action.payload;
  },
  rejected: (state, action) => {
    state.saveInformationInstructorFeedback.isLoading = false;
    state.saveInformationInstructorFeedback.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete &&
      payload.params.onComplete(payload.data as SaveInstructorFeedbackReturn);
  },
});

let slice = createSlice({
  name: controlName,
  initialState: {
    saveBusinessClient: { isLoading: false },
    saveInformationInstructorFeedback: { isLoading: false },
  } as State,
  reducers: {},
  extraReducers: {
    ...saveBusinessClient.reducer,
    ...saveInformationInstructorFeedback.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      saveBusinessClient: saveBusinessClient.action,
      saveInformationInstructorFeedback:
        saveInformationInstructorFeedback.action,
    },
  },
};

export default ret;
