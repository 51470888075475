//The commented items show how to include ducks
import confirmBox from "components/confirmBox/ConfirmBox.duck";
import snackbar from "components/snackbar/Snackbar.duck";

const index = {
  reducer: {
    ...confirmBox.reducer,
    ...snackbar.reducer,
  },
  actions: {
    ...confirmBox.actions,
    ...snackbar.actions,
  },
};

export default index;
