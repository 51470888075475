import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

export type Props = {
  feedback?: string;
  onClose: () => void;
  onSave: (feedback?: string) => void;
  isStudent?: boolean;
};

export default function InstructorFeedbackDialog(props: Props) {
  const [feedback, setFeedback] = useState<string>(props.feedback ?? "");

  return (
    <Dialog open style={{ zIndex: 10000 }} fullWidth>
      <DialogTitle>Instructor Feedback</DialogTitle>
      <DialogContent style={{ paddingTop: 5, paddingBottom: 20 }}>
        <textarea
          disabled={props.isStudent}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          style={{
            width: "99%",
            height: 200,
            fontSize: 16,
            resize: "vertical",
          }}
        />
      </DialogContent>
      <DialogActions>
        {!props.isStudent && (
          <Button
            variant="text"
            color="primary"
            //onClick={() => actions.cancelConfirm()}
            onClick={props.onClose}
          >
            Cancel
          </Button>
        )}

        {!props.isStudent && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.onSave(feedback)}
            autoFocus
          >
            {!!props.feedback ? "Update" : "Save"}
          </Button>
        )}

        {props.isStudent && (
          <Button
            variant="contained"
            color="primary"
            onClick={props.onClose}
            autoFocus
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
