import { Dialog, DialogContent } from "@mui/material";
import { Account } from "types";
import CreditCardForm from "pages/clientPortfolio/businessClient/businessClientAccounts/components/CreditCardForm";
import GICForm from "pages/clientPortfolio/businessClient/businessClientAccounts/components/GICForm";
import LineOfCreditForm from "pages/clientPortfolio/businessClient/businessClientAccounts/components/LineOfCreditForm";
import LoanForm from "pages/clientPortfolio/businessClient/businessClientAccounts/components/LoanForm";
import MortgageForm from "pages/clientPortfolio/businessClient/businessClientAccounts/components/MortgageForm";
import MutualFundsForm from "pages/clientPortfolio/businessClient/businessClientAccounts/components/MutualFundsForm";
import BusinessChequingForm from "pages/clientPortfolio/businessClient/businessClientAccounts/components/BusinessChequingForm";
import BusinessSavingsForm from "pages/clientPortfolio/businessClient/businessClientAccounts/components/BusinessSavingsForm";

export type Props = {
  account?: Account;
  isSaving: boolean;
  onSave(account: Account): void;
  onCancel(): void;
};

export default function EditAccountDialog({
  account,
  isSaving,
  onSave,
  onCancel,
}: Props) {
  if (!account) {
    return <></>;
  }

  if (account.accountType === "BUSINESS_CHEQUING") {
    return (
      <BusinessChequingForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "BUSINESS_SAVINGS") {
    return (
      <BusinessSavingsForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "GIC") {
    return (
      <GICForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "MUTUAL_FUNDS") {
    return (
      <MutualFundsForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "CREDIT_CARD") {
    return (
      <CreditCardForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "LOAN") {
    return (
      <LoanForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "LINE_OF_CREDIT") {
    return (
      <LineOfCreditForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "MORTGAGE") {
    return (
      <MortgageForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  return (
    <Dialog open onClose={() => {}}>
      <DialogContent>Unknown Account Type</DialogContent>
    </Dialog>
  );
}
