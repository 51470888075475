import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import styled from "styled-components";
import { CustomLiability } from "types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ManageLiabilityActionsButton from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/ManageLiabilityActionsButton";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CustomLiabilityFieldDefinitions";
import { openConfirm } from "components/confirmBox";

export type Props = {
  customLiability: CustomLiability;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

const CustomLiabilityDisplay = ({
  customLiability,
  onEditClick,
  onDeleteClick,
  isReadOnlyView,
}: Props) => {
  const commonProps = {
    state: customLiability,
  };

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={customLiability.id}
      >
        <SummaryItem width="wide">{customLiability.name}</SummaryItem>
        <SummaryItem width="small" isgrey={"true"} iscenteraligned={"true"}>
          {customLiability.hasTDS &&
          !customLiability.alreadyIncludedInFinancialProfile
            ? "TDS"
            : ""}
        </SummaryItem>

        <SummaryItem width="med" isrightaligned={"true"}>
          {getMaskedMoneyValue(customLiability.balance ?? 0)}
        </SummaryItem>
        <Spacer />
      </AccordionSummary>
      <AccordionDetails>
        <Content>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CustomLiability>
                field={fields.name}
                {...commonProps}
              />
              <FieldDisplay<CustomLiability>
                field={fields.limit}
                {...commonProps}
              />

              <FieldDisplay<CustomLiability>
                field={fields.tds}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CustomLiability>
                field={fields.balance}
                {...commonProps}
              />
              <FieldDisplay<CustomLiability>
                field={fields.monthlyPayment}
                {...commonProps}
              />
              <FieldDisplay<CustomLiability>
                field={fields.alreadyIncludedInFinancialProfile}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </Content>
        <ButtonDiv>
          <AccountActionsContainer>
            <ManageLiabilityActionsButton
              onEditClick={onEditClick}
              onDeleteClick={() => {
                openConfirm({
                  title: "Delete Liability?",
                  description: `${customLiability.name} - ${customLiability.balance} `,
                  okBtnText: "Delete",
                  callback: () => {
                    onDeleteClick();
                  },
                });
              }}
              isReadOnlyView={isReadOnlyView}
            />
          </AccountActionsContainer>
        </ButtonDiv>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomLiabilityDisplay;

const ButtonDiv = styled.div`
  display: flex;
  padding-right: 32px;
`;

const Content = styled.div`
  padding: 0 8px;
`;
const Spacer = styled.div`
  margin: 0 0.75em;
`;

const AccountActionsContainer = styled.div`
  text-align: right;
  flex: 1;
`;

const SummaryItem = styled(Typography)<{
  width: "small" | "med" | "wide";
  isrightaligned?: string;
  iscenteraligned?: string;
  isgrey?: string;
}>`
  width: ${(p) =>
    p.width === "med" ? "150px" : p.width === "wide" ? "250px" : "100px"};
  color: ${(p) => (p.isgrey === "true" ? "#0000008A" : "")};
  align-self: "center";
  text-align: ${(p) =>
    p.isrightaligned === "true"
      ? "right"
      : p.iscenteraligned === "true"
      ? "center"
      : "left"};
`;
