import { useEffect, useState } from "react";
import { FieldError, IdName, Transfer } from "types";
import styled from "styled-components";
import { Button } from "@mui/material";
import * as fields from "pages/transactions/transferFieldDefinitions";
import { requiredPickListField, requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";
import CurrencyToggleMenu from "pages/transactions/components/CurrencyToggleMenu";
import { openConfirm } from "components/confirmBox";
export type Props = {
  transaction: Transfer;
  transferOptions: IdName[];
  onSaveClick(transaction: Transfer): void;
};

export default function TransferForm({
  transaction,
  transferOptions,
  onSaveClick,
}: Props) {
  const [state, setState] = useState<Transfer>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: Transfer) => {
      setState(state);
    },
  };

  function saveWithdrawal(transaction: Transfer) {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      onSaveClick(transaction);
    }
  }

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.transferAmount, state),
      ...requiredPickListField(fields.transferFrom, state),
      ...requiredPickListField(fields.transferTo, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  return (
    <div style={{ padding: "10px" }}>
      <FlexDiv>
        <TransactionHeading>Transfer</TransactionHeading>
        <CurrencyToggleMenu onToggle={() => {}} isDisabled={true} />
      </FlexDiv>
      {transferOptions.length === 0 && (
        <Container>No Active Transfer Accounts</Container>
      )}
      {transferOptions.length > 0 && (
        <>
          <Container>
            <FieldEditor<Transfer>
              field={fields.transferFrom}
              {...commonProps}
              accountOptions={transferOptions}
            />
            <FieldEditor<Transfer>
              field={fields.transferTo}
              {...commonProps}
              accountOptions={transferOptions}
            />
            <FieldEditor<Transfer>
              field={fields.transferAmount}
              {...commonProps}
            />
          </Container>
          <SaveButtonConatiner>
            <Button
              disabled={!(state.amount && state.amount > 0)}
              onClick={() => {
                if (!!state.from && !!state.to && state.from === state.to) {
                  openConfirm({
                    title: "Error",
                    description: `Transfer transaction cannot be processed for same account.`,
                    isAlert: true,
                  });
                } else {
                  saveWithdrawal(state);
                }
              }}
            >
              Save
            </Button>
          </SaveButtonConatiner>
        </>
      )}
    </div>
  );
}

const TransactionHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const Container = styled.div`
  width: 300px;
  margin: auto;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SaveButtonConatiner = styled.div`
  text-align: right;
  padding: 1em;
`;
