import { employerNotExistsForType, employmentTypeOptions } from "common/helpers";
import {BoolField, DateField,Employment,FIELD_TYPE, PickListField, TextFieldType} from "types";



export const employmentType: PickListField<Employment> = {
  id: "employmentType",
  label: "Employment Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (employment: Employment) => employment.employmentType,
  setValue: (value, employment) => ({
    ...employment,
    employmentType: value,
    employerId: employerNotExistsForType.includes(value) ? undefined : employment.employerId,
  }),
  isRequired: true,
  options: employmentTypeOptions,
};



export const employmentStartDate: DateField<Employment> = {
  id: "employmentStartDate",
  label: "Employment Start Date",
  type: FIELD_TYPE.DATE,
  getValue: (employment: Employment) => employment.employmentStartDate,
  setValue: (value, employment) => ({
    ...employment,
    employmentStartDate: value,
  }),
  checkIfRequired: (employment: Employment) => {
    const type = employment.employmentType;
    return !!type && !employerNotExistsForType.includes(type)
  }
};


export const jobTitle: TextFieldType<Employment> = {
  id: "jobTitle",
  label: "Job Title",
  type: FIELD_TYPE.TEXT,
  getValue: (employment: Employment) => employment.jobTitle,
  setValue: (value, employment) => ({
    ...employment,
    jobTitle: value,
  }),
  checkIfRequired: (employment: Employment) => {
    const type = employment.employmentType;
    return !!type && !employerNotExistsForType.includes(type)
  }
};

export const isCurrent: BoolField<Employment> = {
  id: `isCurrent`,
  label: "Is Current?",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (employment: Employment) => employment.isCurrent,
  setValue: (value, employment) => ({
    ...employment,
    isCurrent: value,
  }),
  checkIfRequired: (employment: Employment) => {
    const type = employment.employmentType;
    return !!type && !employerNotExistsForType.includes(type)
  }
  
};