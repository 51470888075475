import { Paper } from "@mui/material";
import { getLoginInfo } from "common/azureAD";
import AdminTemplate from "components/adminTemplate/AdminTemplate";
import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";
import { OutstandingOverrideRoute, UnauthorizedRoute } from "components/paths";
import { openSnackbar } from "components/snackbar";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useActions, useReduxState } from "store";
import styled from "styled-components";
import { IdName, OutstandingOverridesTransaction } from "types";
import OutstandingOverridesTransactionList from "pages/admin/outstandingOverrides/components/OutstandingOverridesTransactionList";
import TransactionDetails from "pages/admin/outstandingOverrides/components/TransactionDetails";
import TextFieldNonFieldEditor from "components/fields/TextFieldNonFieldEditor";

export type Props = {
  lessonId?: string;
};

export default function OutstandingOverrides(props: Props) {
  const loginInfo = getLoginInfo();
  const history = useHistory();
  const location = useLocation();
  if (!loginInfo?.isInstructor) {
    history.push(UnauthorizedRoute);
  }

  useEffect(() => {
    if (!getLessonList.data) {
      setIsLoading(true);
      lessonActions.getLessonList({
        onComplete: (payload) => {
          setLessonOptions(
            payload.map((l) => {
              return { id: l.id.toString(), name: l.lessonName };
            })
          );
          setIsLoading(false);
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!!props.lessonId) {
      setSelectedLessonOption(props.lessonId);
      setIsLoading(true);
      lessonActions.getStudentSessionLessonInfo({
        id: (props.lessonId as unknown) as number,
        onComplete: (payload) => {
          lessonOptions &&
            setOutstandingOverrideTxnList(
              payload.lessonInfo.outstandingOverrideTransactionList
            );
          lessonOptions &&
            setSelectedTransaction(
              payload.lessonInfo.outstandingOverrideTransactionList[0] ??
                undefined
            );
          setIsLoading(false);
        },
      });
    }
    if (props.lessonId === undefined) {
      setSelectedLessonOption("");
      setOutstandingOverrideTxnList([]);
      setSelectedTransaction(undefined);
    }
  }, [location]);

  const lessonActions = useActions().lesson;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getLessonList, getStudentSessionLessonInfo } = useReduxState(
    (e) => e.lesson
  );
  const [selectedLessonOption, setSelectedLessonOption] = useState<string>("");
  const [lessonOptions, setLessonOptions] = useState<IdName[]>(
    getLessonList.data?.map((l) => {
      return { id: l.id.toString(), name: l.lessonName };
    }) ?? []
  );

  const [outstandingOverrideTxnList, setOutstandingOverrideTxnList] = useState<
    OutstandingOverridesTransaction[]
  >(
    selectedLessonOption !== ""
      ? getStudentSessionLessonInfo.data?.lessonInfo
          .outstandingOverrideTransactionList ?? []
      : []
  );

  const [
    selectedTransaction,
    setSelectedTransaction,
  ] = useState<OutstandingOverridesTransaction>();

  const [searchText, setSearchText] = useState<string>("");

  function approveOverride(overrideTxn: OutstandingOverridesTransaction) {
    setIsLoading(true);
    lessonActions.approveOverride({
      id: (selectedLessonOption as unknown) as number,
      overrideTransaction: overrideTxn,
      onComplete: (payload) => {
        if (payload.success) {
          let newOverrideList = outstandingOverrideTxnList.filter(
            (oot) => oot.transactionId !== overrideTxn.transactionId
          );
          setOutstandingOverrideTxnList(newOverrideList);
          setSelectedTransaction(newOverrideList[0]);
          openSnackbar({
            severity: "success",
            message: `Approved transaction override`,
          });
          setIsLoading(false);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error approving transaction override. Please try again!`,
          });
          setIsLoading(false);
        }
      },
    });
  }
  function rejectOverride(overrideTxn: OutstandingOverridesTransaction) {
    setIsLoading(true);
    lessonActions.rejectOverride({
      id: (selectedLessonOption as unknown) as number,
      overrideTransaction: overrideTxn,
      onComplete: (payload) => {
        if (payload.success) {
          let newOverrideList = outstandingOverrideTxnList.filter(
            (oot) => oot.transactionId !== overrideTxn.transactionId
          );
          setOutstandingOverrideTxnList(newOverrideList);
          setSelectedTransaction(newOverrideList[0]);
          openSnackbar({
            severity: "success",
            message: `Rejected transaction override`,
          });
          setIsLoading(false);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error rejecting transaction override. Please try again!`,
          });
          setIsLoading(false);
        }
      },
    });
  }

  var filteredList = outstandingOverrideTxnList.filter((l) => {
    return (
      l.studentId.toLowerCase().includes(searchText.toLowerCase()) ||
      l.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
      l.clientName.toLowerCase().includes(searchText.toLowerCase()) ||
      l.accountType.toLowerCase().includes(searchText.toLowerCase()) ||
      l.transactionType.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  return (
    <AdminTemplate
      title="Administration - Outstanding Overrides"
      isLoading={isLoading}
      noLessonExists={getLessonList.data && getLessonList.data.length === 0}
    >
      <Content>
        <OverridesColumn>
          <PicklistContainer>
            <PickListNonFieldEditor
              id="lessonlist"
              label="Lesson"
              value={selectedLessonOption}
              onChange={(v) => {
                history.push(`${OutstandingOverrideRoute}/${v}`);
              }}
              options={lessonOptions?.sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
            />
          </PicklistContainer>
          {selectedLessonOption !== "" &&
            outstandingOverrideTxnList.length > 0 && (
              <SearchFieldContainer>
                <TextFieldNonFieldEditor
                  id="transactionlist_search"
                  label="Search"
                  value={searchText}
                  onChange={(v) => {
                    setSearchText(v);
                  }}
                  required={false}
                  multiline={false}
                  disabled={false}
                />
              </SearchFieldContainer>
            )}
          <OutstandingOverridesTransactionList
            overrideTransactionlist={filteredList}
            onTransactionSelect={(outstandingOverrideTxn) => {
              setSelectedTransaction(outstandingOverrideTxn);
            }}
          />
        </OverridesColumn>
        <TransactionDetailsColumn>
          <TransactionDetails
            overrideTransaction={selectedTransaction}
            lessonId={selectedLessonOption}
            approveOverride={() => {
              selectedTransaction && approveOverride(selectedTransaction);
            }}
            rejectOverride={() => {
              selectedTransaction && rejectOverride(selectedTransaction);
            }}
          />
        </TransactionDetailsColumn>
      </Content>
    </AdminTemplate>
  );
}

const Content = styled.div`
  display: flex;
`;
const OverridesColumn = styled.div`
  background-color: #efefef;
  min-height: 100vh;
  width: 370px;
`;

const TransactionDetailsColumn = styled(Paper)`
  height: 500px;
  flex: 1;
  padding-right: 20;
`;

const PicklistContainer = styled.div`
  width: 250px;
  margin-left: 16px;
  margin-top: 50px;
`;

const SearchFieldContainer = styled.div`
  width: 250px;
  margin-left: 16px;
  margin-top: 20px;
`;
