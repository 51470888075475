import _orderBy from "lodash/orderBy";
import _max from "lodash/max";
import { BusinessClient, LessonData, PersonalClient } from "types";

export const getEmployerList = (data?: LessonData) =>
  _orderBy(data?.employers ?? [], (e) => e.employerName?.toLowerCase());

export const getNewEmployerId = (data?: LessonData) =>
  (_max(data?.employers?.map((e) => +(e.employerId ?? ""))) ?? 1000000) + 1;

export const getPersonalClientList = (data?: LessonData) =>
  _orderBy(data?.personalClients ?? [], (e) =>
    `${e.information?.legalFirstName} ${e.information?.legalLastName}`.toLowerCase()
  );

export const getBusinessClientList = (data?: LessonData) =>
  _orderBy(data?.businessClients ?? [], (e) =>
    `${e.information?.operatingAs}`.toLowerCase()
  );

export const getLessonClientList = (data?: LessonData) =>
  _orderBy(
    [...(data?.personalClients ?? []), ...(data?.businessClients ?? [])],
    (e) =>
      (e.information?.accountType === "Personal"
        ? `${(e as PersonalClient).information?.legalFirstName} ${
            (e as PersonalClient).information?.legalLastName
          }`
        : `${(e as BusinessClient).information?.operatingAs}`
      ).toLowerCase()
  );

export const getNewPersonalClientId = (data?: LessonData) =>
  (_max(
    data?.personalClients?.map((e) => +(e.identification?.clientId ?? ""))
  ) ?? getRandomNumber(7)) + 1;

export const getNewBusinessClientId = (data?: LessonData) =>
  (_max(data?.businessClients?.map((e) => +(e.information?.clientId ?? ""))) ??
    getRandomNumber(7)) + 1;

export const getPersonalClientById = (id?: string, data?: LessonData) =>
  data?.personalClients?.find((c) => c.identification?.clientId === id) ?? {};

export const getBusinessClientById = (id?: string, data?: LessonData) =>
  data?.businessClients?.find((c) => c.information?.clientId === id) ?? {};

export const getGeneralLedger = (data?: LessonData) =>
  data?.generalLedger ?? {};

function getRandomNumber(noOfDigits: number) {
  let n = Math.pow(10, noOfDigits - 1);
  let randomNumber = Math.floor(n + Math.random() * 9 * n);
  return randomNumber;
}

export function getPersonalClientInformationInstructorFeedbackFromRedux(
  clientId?: string,
  lesson?: LessonData
) {
  if (!lesson) return undefined;
  const client = getPersonalClientById(clientId, lesson);
  return client.informationInstructorFeedback;
}

export function getPersonalClientCreditAppInstructorFeedbackFromRedux(
  clientId?: string,
  lesson?: LessonData
) {
  if (!lesson) return undefined;
  const client = getPersonalClientById(clientId, lesson);
  return client.creditAppInstructorFeedback;
}

export function getBusinessClientInformationInstructorFeedbackFromRedux(
  clientId?: string,
  lesson?: LessonData
) {
  if (!lesson) return undefined;
  const client = getBusinessClientById(clientId, lesson);
  return client.informationInstructorFeedback;
}
