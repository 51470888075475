import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import {
  FieldError,
  BusinessClient,
  SigningAuthority,
  PersonalClient,
} from "types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useState } from "react";
import { defaultSigningAuthority } from "common/helpers";
import { v4 } from "uuid";
import SigningAuthorityDisplayTemplate from "pages/clientPortfolio/businessClient/businessClientInformation/components/SigningAuthorityDisplayTemplate";
import SigningAuthorityEditor from "pages/clientPortfolio/businessClient/businessClientInformation/components/SigningAuthorityEditor";

type CommonProps = {
  state: BusinessClient;
  setState(state: BusinessClient): void;
  errors: FieldError[];
};
const SigningAuthoritySection = (
  props: CommonProps & {
    personalClientList: PersonalClient[];
    onEditSigningAuthority(updatedSigningAuthority: SigningAuthority): void;
    onDeleteSigningAuthority(signingAuthority: SigningAuthority): void;
    onPortfolioClick(personalClientId: string): void;
    isReadOnlyView: boolean;
    isNewClient: boolean;
  }
) => {
  const [signingAuthorityInEdit, setSigningAuthorityInEdit] = useState<
    SigningAuthority | undefined
  >(undefined);

  const signingAuthorities = props.state.signingAuthorities;

  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="signingAuthoritySection-header"
        >
          <Typography variant={"h6"}>Signing Authority</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {(!signingAuthorities || signingAuthorities.length === 0) && (
            <div>No Signing Authority.</div>
          )}
          {signingAuthorities &&
            signingAuthorities.length > 0 &&
            signingAuthorities.map((sAuth, index) => {
              return (
                <div key={index}>
                  <SigningAuthorityDisplayTemplate
                    businessClientId={props.state.information?.clientId ?? ""}
                    signingAuthority={sAuth}
                    personalClient={
                      props.personalClientList.find(
                        (c) =>
                          c.identification?.clientId === sAuth.personalClientId
                      ) ?? {}
                    }
                    onEditClick={() => {
                      setSigningAuthorityInEdit(sAuth);
                    }}
                    onDeleteClick={() => {
                      props.onDeleteSigningAuthority(sAuth);
                    }}
                    onPortfolioClick={() => {
                      props.onPortfolioClick(sAuth.personalClientId ?? "");
                    }}
                    isReadOnlyView={props.isReadOnlyView}
                    isNewClient={props.isNewClient}
                  />
                  <Divider style={{ margin: "1.25em 0" }} />
                </div>
              );
            })}
          <ButtonContainer>
            <Button
              disabled={props.isReadOnlyView}
              onClick={() => {
                setSigningAuthorityInEdit({
                  ...defaultSigningAuthority,
                  signingAuthorityId: v4(),
                });
              }}
            >
              Add New
            </Button>
          </ButtonContainer>
        </AccordionDetails>
      </Accordion>
      {!!signingAuthorityInEdit && (
        <SigningAuthorityEditor
          signingAuthority={signingAuthorityInEdit}
          personalClient={
            !!signingAuthorityInEdit.personalClientId
              ? props.personalClientList.find(
                  (pc) =>
                    pc.identification?.clientId ===
                    signingAuthorityInEdit.personalClientId
                )
              : {}
          }
          onAdd={(newSigningAuthority) => {
            props.onEditSigningAuthority(newSigningAuthority);
            setSigningAuthorityInEdit(undefined);
          }}
          onCancel={() => {
            setSigningAuthorityInEdit(undefined);
          }}
        />
      )}
    </SectionContainer>
  );
};

export default SigningAuthoritySection;

const SectionContainer = styled.div`
  margin-top: 2em;
`;

const ButtonContainer = styled.div`
  margin-top: 2em;
  text-align: right;
`;
