import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { CommonFieldProps, Entity, IdentificationField } from "types";
import { FieldContainer } from "components/fields/commonFieldStyles";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";
import moment from "moment";

export default function IdentificationEditor<T extends Entity>(
  props: CommonFieldProps<T>
) {
  const { field, state, setState, errors } = props;
  const idField = field as IdentificationField<T>;
  const typeErrObj = errors.find((e) => e.id === `${field.id}_name`);
  const numberErrObj = errors.find((e) => e.id === `${field.id}_number`);
  const otherTypeErrObj = errors.find((e) => e.id === `${field.id}_otherType`);
  const myState = idField.getValue(state);

  return (
    <FieldContainer>
      <FormControl
        fullWidth
        variant="filled"
        required={
          field.isRequired ||
          (field.checkIfRequired && field.checkIfRequired(state))
        }
        size="small"
      >
        <InputLabel
          id="demo-simple-select-label"
          style={!!typeErrObj ? { color: "#d32f2f" } : {}}
        >
          {field.label} Type
        </InputLabel>
        <Select
          labelId={`${field.id}_label`}
          id={`${field.id}_name`}
          value={myState?.type ?? ""}
          label={field.label}
          error={!!typeErrObj}
          onChange={(e) => {
            setState(
              idField.setValue({ ...myState, type: e.target.value }, state)
            );
          }}
        >
          {idField.options?.map((mi, n) => (
            <MenuItem key={`option_${n}`} value={mi.id}>
              {mi.name}
            </MenuItem>
          ))}
        </Select>
        {!!typeErrObj && (
          <FormHelperText style={{ color: "#d32f2f" }}>
            {typeErrObj.error}
          </FormHelperText>
        )}
      </FormControl>
      {myState?.type === "other" && (
        <TextField
          id={`${field.id}_otherType`}
          style={{ marginTop: "1em" }}
          label={`${field.label} Type - Other`}
          variant="filled"
          value={myState?.otherType ?? ""}
          error={!!otherTypeErrObj}
          helperText={!!otherTypeErrObj && otherTypeErrObj.error}
          onChange={(e) => {
            const myState = idField.getValue(state);
            setState(
              idField.setValue({ ...myState, otherType: e.target.value }, state)
            );
          }}
          required={field.isRequired}
          fullWidth
          size="small"
        />
      )}
      <TextField
        id={`${field.id}_number`}
        style={{ marginTop: "1em" }}
        label={`${field.label} Number`}
        variant="filled"
        error={!!numberErrObj}
        helperText={!!numberErrObj && numberErrObj.error}
        value={myState?.number ?? ""}
        onChange={(e) => {
          const myState = idField.getValue(state);
          setState(
            idField.setValue({ ...myState, number: e.target.value }, state)
          );
        }}
        required={
          field.isRequired ||
          (field.checkIfRequired && field.checkIfRequired(state))
        }
        fullWidth
        size="small"
      />
      <DatePicker
        label={`${field.label} Expiry`}
        value={myState?.expiry ?? null}
        onChange={(e) => {
          setState(
            idField.setValue(
              { ...myState, expiry: moment(e).format("MM/DD/YYYY").toString() },
              state
            )
          );
        }}
        renderInput={(params) => (
          <FieldContainer>
            <TextField size="small" {...params} variant="filled" fullWidth />
          </FieldContainer>
        )}
      />
    </FieldContainer>
  );
}
