import { getMaskedMoneyValue } from "common/functions";
import { openConfirm } from "components/confirmBox";
import moment from "moment";
import { AccountType, Deposit } from "types";
import AccountHistoryRowTemplate from "pages/accountHistory/components/AccountHistoryRowTemplate";

type AccountHostoryRowDepositProps = {
  isSelectedAccountClosed: boolean;
  accountType: AccountType | undefined;
  balance: number;
  transaction: Deposit;
  onEditClick(): void;
  onDeleteClick(): void;
  onDuplicateClick(): void;
  isReadOnlyView: boolean;
};

export default function AccountHistoryRowDeposit(
  props: AccountHostoryRowDepositProps
) {
  const transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  return (
    <AccountHistoryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transactionType={
        props.transaction.transactionType === "TRANSFER"
          ? "Transfer"
          : props.transaction.transactionType === "GL_ENTRY"
          ? "GL Entry"
          : "Deposit"
      }
      transactionDescription={props.transaction.transactionDescription}
      credit={transactionAmount}
      balance={getMaskedMoneyValue(props.balance)}
      isSelectedAccountClosed={props.isSelectedAccountClosed}
      accountType={props.accountType}
      onEditClick={props.onEditClick}
      onDeleteClick={() => {
        openConfirm({
          title: "Delete Transaction?",
          description:
            props.transaction.transactionType === "TRANSFER"
              ? `Transfer - ${transactionAmount}`
              : props.transaction.transactionType === "GL_ENTRY"
              ? `GL Entry - ${transactionAmount}`
              : `Deposit - ${transactionAmount}`,
          okBtnText: "Delete",
          callback: () => {
            props.onDeleteClick();
          },
        });
      }}
      onDuplicateClick={() => {
        openConfirm({
          title: "Duplicate Transaction?",
          description:
            props.transaction.transactionType === "TRANSFER"
              ? `Transfer - ${transactionAmount}`
              : props.transaction.transactionType === "GL_ENTRY"
              ? `GL Entry - ${transactionAmount}`
              : `Deposit - ${transactionAmount}`,
          okBtnText: "Duplicate",
          callback: () => {
            props.onDuplicateClick();
          },
        });
      }}
      isReadOnlyView={props.isReadOnlyView}
    />
  );
}
