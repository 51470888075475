import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";
import { useState } from "react";
import styled from "styled-components";
import { FieldError, IdName } from "types";

export type Props = {
  lessonName: string;
  onSubmitClick(numberofMonths: number): void;
  onCancelClick(): void;
};

export default function AdvanceMonthsDialog({
  lessonName,
  onSubmitClick,
  onCancelClick,
}: Props) {
  const advanceMonthsOptions: IdName[] = [
    { id: "6", name: "6" },
    { id: "12", name: "12" },
    { id: "18", name: "18" },
    { id: "24", name: "24" },
    { id: "30", name: "30" },
    { id: "36", name: "36" },
    { id: "42", name: "42" },
    { id: "48", name: "48" },
    { id: "54", name: "54" },
    { id: "60", name: "60" },
  ];
  const [selectedNumberOfMonths, setSelectedNumberOfMonths] = useState<string>(
    ""
  );
  const [error, setError] = useState<FieldError>();

  return (
    <Dialog maxWidth="xs" fullWidth open id={"advanceMonths"}>
      <DialogTitle>
        <Typography>Advance Months - {lessonName}</Typography>
      </DialogTitle>
      <DialogContent>
        <PickListNonFieldEditor
          id="advanceMonths"
          label="Number of Months"
          options={advanceMonthsOptions}
          value={selectedNumberOfMonths}
          onChange={(value) => {
            if (error) setError(undefined);
            setSelectedNumberOfMonths(value);
          }}
          required
          errObj={error}
        />
        {selectedNumberOfMonths && (
          <Message>
            Submitting this will advance every transaction date in this lesson
            by {selectedNumberOfMonths} months.
          </Message>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            if (selectedNumberOfMonths === "") {
              setError({
                id: "advanceMonthsError",
                label: "Advance Months",
                error: "Is Required.",
              });
              return;
            }
            onSubmitClick((selectedNumberOfMonths as unknown) as number);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Message = styled.div`
  margin: -10px 5px 0 5px;
  font-size: 14px;
  color: #666;
`;
