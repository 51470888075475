import moment from "moment";
import { CommonFieldProps, DateField, Entity } from "types";
import DateFieldNonFieldEditor from "./DateFieldNonFieldEditor";

export default function DateFieldEditor<T extends Entity>({
  field,
  state,
  setState,
  errors,
  customStyle,
}: CommonFieldProps<T> & {
  customStyle?: React.CSSProperties;
}) {
  const dateField = field as DateField<T>;
  const errObj = errors.find((e) => e.id === field.id);
  return (
    <DateFieldNonFieldEditor
      id={field.id}
      label={field.label}
      value={dateField.getValue(state) ?? null}
      disabled={
        field.disabled ||
        (field.checkIfDisabled && field.checkIfDisabled(state))
      }
      onChange={(newValue) =>
        setState(
          dateField.setValue(
            moment(newValue).format("MM/DD/YYYY").toString(),
            state
          )
        )
      }
      required={
        field.isRequired ||
        (field.checkIfRequired && field.checkIfRequired(state))
      }
      errObj={errObj}
      disableFutureDates={dateField.disableFutureDates}
      disablePastDates={dateField.disablePastDates}
      minDate={dateField.minDate}
      customStyle={customStyle}
    />
  );
}
