import React from "react";
import { DisplayFieldProps, Entity, FIELD_TYPE } from "types";
import DateFieldDisplay from "components/fields/DateFieldDisplay";
import TextFieldDisplay from "components/fields/TextFieldDisplay";
import DisplayFieldTemplate from "components/fields/DisplayFieldTemplate";
import CheckboxDisplay from "components/fields/CheckboxDisplay";
import PickListDisplay from "components/fields/PickListDisplay";
import RadioGroupDisplay from "components/fields/RadioGroupDisplay";
export type Props = {};

export default function FieldDisplay<T extends Entity>(
  props: DisplayFieldProps<T>
) {
  if (props.field.type === FIELD_TYPE.DATE) {
    return <DateFieldDisplay<T> {...props} />;
  }
  if (props.field.type === FIELD_TYPE.PICKLIST) {
    return <PickListDisplay<T> {...props} />;
  }

  if (props.field.type === FIELD_TYPE.TEXT) {
    return <TextFieldDisplay<T> {...props} />;
  }

  if (props.field.type === FIELD_TYPE.CHECKBOX) {
    return <CheckboxDisplay<T> {...props} />;
  }

  if (props.field.type === FIELD_TYPE.RADIOGROUP) {
    return <RadioGroupDisplay<T> {...props} />;
  }

  return (
    <DisplayFieldTemplate label={props.field.label}>
      Unknown Type: {props.field.type}
    </DisplayFieldTemplate>
  );
}
