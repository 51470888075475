import {  
  FIELD_TYPE,  
  INPUT_TYPE,
  MASK_TYPE,
  PickListField,
  Receive,
  TextFieldType,
  ChequesArrayField,
  BoolField,
} from "types";

import { add, getTotalCashAmount } from "common/functions";
import { useReduxState } from "store";

export const cashCurrencyType: PickListField<Receive> = {
  id: "cashCurrencyType",
  label: "Currency Type",
  defaultValue: "cad",
  type: FIELD_TYPE.PICKLIST,
  getValue: (transaction: Receive) =>
    transaction.cash ? transaction.cash.cashCurrencyType : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, currencyType: value },
  }),
  isRequired: true,
  options: [
    { id: "cad", name: "Canadian Dollars - CAD" },
    { id: "usd", name: "US Dollars - USD" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const dollar5Bills: TextFieldType<Receive> = {
  id: "dollar5Bills",
  label: "$5 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash ? (transaction.cash.dollar5Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar5Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const dollar10Bills: TextFieldType<Receive> = {
  id: "dollar10Bills",
  label: "$10 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash ? (transaction.cash.dollar10Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar10Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const dollar20Bills: TextFieldType<Receive> = {
  id: "dollar20Bills",
  label: "$20 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash ? (transaction.cash.dollar20Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar20Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const dollar50Bills: TextFieldType<Receive> = {
  id: "dollar50Bills",
  label: "$50 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash ? (transaction.cash.dollar50Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar50Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const dollar100Bills: TextFieldType<Receive> = {
  id: "dollar100Bills",
  label: "$100 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash ? (transaction.cash.dollar100Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar100Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const cent5Coins: TextFieldType<Receive> = {
  id: "cent5Coins",
  label: "$0.05 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.cent5Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, cent5Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: Receive) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const cent10Coins: TextFieldType<Receive> = {
  id: "cent10Coins",
  label: "$0.10 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.cent10Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, cent10Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: Receive) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const cent25Coins: TextFieldType<Receive> = {
  id: "cent25Coins",
  label: "$0.25 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.cent25Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, cent25Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: Receive) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const dollar1Coins: TextFieldType<Receive> = {
  id: "dollar1Coins",
  label: "$1.00 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.dollar1Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar1Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: Receive) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const dollar2Coins: TextFieldType<Receive> = {
  id: "dollar2Coins",
  label: "$2.00 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.dollar2Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar2Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: Receive) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const utr: BoolField<Receive> = {
  id: `utr`,
  label: "UTR",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (transaction: Receive) => transaction.utr,
  setValue: (value, transaction ) => ({
    ...transaction,
    utr: value,
  }),  
};

export const lctr: BoolField<Receive> = {
  id: `lctr`,
  label: "LCTR",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (transaction: Receive) => transaction.lctr,
  setValue: (value, transaction ) => ({
    ...transaction,
    lctr: value,
  }),  
};

export const totalCashAmount: TextFieldType<Receive> = {
  id: "totalCashAmount",
  label: "Total",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    getTotalCashAmount(transaction.cash ?? {}).toString(),
  setValue: (value, transaction) => ({
    ...transaction,
    cash: {
      ...transaction.cash,
      totalCashAmount: (value as unknown) as number,
    },
  }),
  mask: MASK_TYPE.MONEY,
};

export const cashCADValue: TextFieldType<Receive> = {
  id: "cashCADValue",
  label: "CAD Value",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) => getCashCadValue(transaction),
  setValue: (value, transaction) => ({
    ...transaction,
    cash: {
      ...transaction.cash,
      cashCADValue: (value as unknown) as number,
    },
  }),
  mask: MASK_TYPE.MONEY,
};

function getCashCadValue(transaction: Receive)
{
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
let ret = transaction.cash?.cashCurrencyType === "usd" 
?  (getTotalCashAmount(transaction.cash ?? {}) * (globalSettings.usCashBuyRate ?? 1)).toFixed(2)
: getTotalCashAmount(transaction.cash ?? {}).toFixed(2)

return ret
}

export const chequeCurrencyType: PickListField<Receive> = {
  id: "chequeCurrencyType",
  label: "Currency Type",
  defaultValue: "cad",
  type: FIELD_TYPE.PICKLIST,
  getValue: (transaction: Receive) =>
    transaction.cheque ? transaction.cheque.chequeCurrencyType : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cheque: { ...transaction.cheque, currencyType: value },
  }),
  isRequired: true,
  options: [
    { id: "cad", name: "Canadian Dollars - CAD" },
    { id: "usd", name: "US Dollars - USD" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const chequeHoldAmount: TextFieldType<Receive> = {
  id: "chequeHoldAmount",
  label: "Hold Amount ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cheque ? (transaction.cheque.holdAmount || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cheque: { ...transaction.cheque, holdAmount: (value as unknown) as number },
  }),
  mask: MASK_TYPE.MONEY,
  
};

export const chequeHoldDuration: PickListField<Receive> = {
  id: "chequeHoldDuration",
  label: "Duration (days)",
  type: FIELD_TYPE.PICKLIST,
  getValue: (transaction: Receive) =>
    transaction.cheque ? transaction.cheque.duration : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cheque: { ...transaction.cheque, duration: value },
  }),
  checkIfRequired: (transaction: Receive) => {
    const holdAmount = transaction.cheque?.holdAmount;
    return !!holdAmount && (holdAmount > 0);
  },
  options: [
    { id: "5", name: "5" },
    { id: "10", name: "10" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};
export const chequeHoldReason: TextFieldType<Receive> = {
  id: "chequeHoldReason",
  label: "Reason",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cheque ? transaction.cheque.reason : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cheque: { ...transaction.cheque, reason: value },
  }),
  checkIfRequired: (transaction: Receive) => {
    const holdAmount = transaction.cheque?.holdAmount;
    return !!holdAmount && (holdAmount >= 0);
  },
  multiline: true
};

export const totalChequeAmount: TextFieldType<Receive> = {
  id: "totalChequeAmount",
  label: "Total",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) => transaction.cheque ? transaction.cheque.chequesArray?.reduce(add,0)?.toFixed(2) : "0",
  setValue: (value, transaction) => ({
    ...transaction,
    cheque: {
      ...transaction.cheque,
      totalChequeAmount: (value as unknown) as number,
    },
  }),
  mask: MASK_TYPE.MONEY,
};

export const chequeCADValue: TextFieldType<Receive> = {
  id: "chequeCADValue",
  label: "CAD Value",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) => getChequeCADValue(transaction),
  
  setValue: (value, transaction) => ({
    ...transaction,
    cheque: {
      ...transaction.cheque,
      chequeCADValue: (value as unknown) as number,
    },
  }),
  mask: MASK_TYPE.MONEY,
};

function getChequeCADValue(transaction: Receive)
{
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
let ret = transaction.cheque ? transaction.cheque?.chequeCurrencyType === "usd" 
?  ((transaction.cheque.chequesArray?.reduce(add,0) || 0) * (globalSettings.usChequeBuyRate ?? 1)).toFixed(2)
: transaction.cheque.chequesArray?.reduce(add,0)?.toFixed(2) 
: "0"

return ret
}

export const totalReceiveAmount: TextFieldType<Receive> = {
  id: "totalReceiveAmount",
  label: "Total",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) =>
    transaction.cheque && transaction.cash
      ? add(
          transaction.cash.cashCADValue ?? 0,
          transaction.cheque.chequeCADValue ?? 0
        ).toFixed(2)
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    amount: (value as unknown) as number,
  }),
  mask: MASK_TYPE.MONEY,
}; 

export const receiveDescription: TextFieldType<Receive> = {
  id: "receiveDescription",
  label: "Description",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Receive) => transaction.transactionDescription,
  setValue: (value, transaction) => ({
    ...transaction,
    transactionDescription: value,
  }),
};

export const chequesArray: ChequesArrayField<Receive> = {
  id: "chequesArray",
  label: "chequesArray",
  type: FIELD_TYPE.CHEQUESARRAY,
  getValue: (transaction: Receive) =>
    transaction.cheque?.chequesArray || [null],
  setValue: (value, transaction) => ({
    ...transaction,
    cheque: { ...transaction.cheque, chequesArray: value },
  }),
};
