import { Button, Paper } from "@mui/material";
import { add, subtract } from "common/functions";
import MaskedValue from "common/MaskedValue";
import styled from "styled-components";
import {
  BillPayment,
  CreditCardTransaction,
  Deposit,
  GLEntry,
  IdName,
  MASK_TYPE,
  PayoutCash,
  Receive,
  SellDraft,
  Transaction,
  TransactionSession,
  Transfer,
  Withdrawal,
} from "types";
import SummaryRowBillPayment from "pages/transactions/components/SummaryRowBillPayment";
import SummaryRowCreditCard from "pages/transactions/components/SummaryRowCreditCard";
import SummaryRowDeposit from "pages/transactions/components/SummaryRowDeposit";
import SummaryRowGLEntry from "pages/transactions/components/SummaryRowGLEntry";
import SummaryRowPayoutCash from "pages/transactions/components/SummaryRowPayoutCash";
import SummaryRowReceive from "pages/transactions/components/SummaryRowReceive";
import SummaryRowSellDraft from "pages/transactions/components/SummaryRowSellDraft";
import SummaryRowTransfer from "pages/transactions/components/SummaryRowTransfer";
import SummaryRowWithdrawal from "pages/transactions/components/SummaryRowWithdrawal";

export type SummaryProps = {
  activeTransactionSession: TransactionSession | undefined;
  accountIdsWithOverLimitBal: string[];
  updateTransactionSession(updatedSession: TransactionSession): void;
  onPostClick(): void;
  depositWithdrawTransferOptions: IdName[];
  glEntryClientAccountOptions: IdName[];
  creditCardOptions: IdName[];
  isSupervisorOverrideRequired: boolean;
  isSupervisorOverrideRejected: boolean;
  isAnyTxnForAccWithOverLimitBal: boolean;
  isReadOnlyView: boolean;
};

export default function Summary(props: SummaryProps) {
  //   const [page, setPage] = useState<number>(0);
  //   const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  let clientOwes =
    !!props.activeTransactionSession &&
    props.activeTransactionSession.sessionBalance &&
    props.activeTransactionSession.sessionBalance < 0
      ? props.activeTransactionSession.sessionBalance * -1
      : 0;

  let payoutCash =
    !!props.activeTransactionSession &&
    props.activeTransactionSession.sessionBalance &&
    props.activeTransactionSession.sessionBalance > 0
      ? props.activeTransactionSession.sessionBalance
      : 0;

  //following function updates the active transaction session
  //when any transaction is updated or deleted
  function handleDeleteTransaction(
    updatedTransaction: Transaction,
    deleteAmount: number
  ) {
    if (updatedTransaction.transactionType === "RECEIVE") {
      let updatedReceiveTransaction = updatedTransaction as Receive;
      let currentTransactions = props.activeTransactionSession?.transactions;

      let updatedTransactions: Transaction[] | undefined =
        (updatedReceiveTransaction.cash?.cashCADValue &&
          updatedReceiveTransaction.cash?.cashCADValue > 0) ||
        (updatedReceiveTransaction.cheque?.chequeCADValue &&
          updatedReceiveTransaction.cheque?.chequeCADValue > 0)
          ? currentTransactions?.map((a) =>
              a.transactionId === updatedTransaction.transactionId
                ? updatedTransaction
                : a
            )
          : currentTransactions?.filter(
              (a) => a.transactionId !== updatedTransaction.transactionId
            );

      let updatedSession: TransactionSession = {
        ...props.activeTransactionSession,
        transactions: updatedTransactions,
        sessionBalance: subtract(
          props.activeTransactionSession?.sessionBalance ?? 0,
          deleteAmount ?? 0
        ),
      };
      props.updateTransactionSession(updatedSession);
    }

    if (
      [
        "DEPOSIT",
        "WITHDRAWAL",
        "PAYOUT_CASH",
        "BILL_PAYMENT",
        "CREDIT_CARD",
        "TRANSFER",
        "SELL_DRAFT",
        "GL_ENTRY",
      ].includes(updatedTransaction.transactionType)
    ) {
      let currentTransactions = props.activeTransactionSession?.transactions;

      let updatedTransactions:
        | Transaction[]
        | undefined = currentTransactions?.filter(
        (t) => t.transactionId !== updatedTransaction.transactionId
      );
      let updatedSession: TransactionSession = {
        ...props.activeTransactionSession,
        transactions: updatedTransactions,
        sessionBalance:
          ["DEPOSIT", "PAYOUT_CASH", "BILL_PAYMENT", "SELL_DRAFT"].includes(
            updatedTransaction.transactionType
          ) ||
          (updatedTransaction.transactionType === "CREDIT_CARD" &&
            (updatedTransaction as CreditCardTransaction).type === "payment") ||
          (updatedTransaction.transactionType === "GL_ENTRY" &&
            (updatedTransaction as GLEntry).glType === "credit" &&
            (updatedTransaction as GLEntry).clientAccount === "sessionbalance")
            ? add(
                props.activeTransactionSession?.sessionBalance ?? 0,
                deleteAmount ?? 0
              )
            : updatedTransaction.transactionType === "WITHDRAWAL" ||
              (updatedTransaction.transactionType === "CREDIT_CARD" &&
                (updatedTransaction as CreditCardTransaction).type ===
                  "cashadvance") ||
              (updatedTransaction.transactionType === "GL_ENTRY" &&
                (updatedTransaction as GLEntry).glType === "debit" &&
                (updatedTransaction as GLEntry).clientAccount ===
                  "sessionbalance")
            ? subtract(
                props.activeTransactionSession?.sessionBalance ?? 0,
                deleteAmount ?? 0
              )
            : props.activeTransactionSession?.sessionBalance,
      };
      props.updateTransactionSession(updatedSession);
    }
  }

  if (
    props.activeTransactionSession?.transactions === undefined ||
    props.activeTransactionSession.transactions.length < 1
  )
    return (
      <ColumnContent>
        <FlexCenter>No Transactions Summary</FlexCenter>
      </ColumnContent>
    );

  return (
    <ColumnContent>
      <div>
        <Header />

        <div>
          {props.activeTransactionSession?.transactions
            //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((t, index) => {
              return (
                <Row
                  transaction={t}
                  key={index}
                  onDelete={(updatedTransaction, deleteAmount) => {
                    handleDeleteTransaction(updatedTransaction, deleteAmount);
                  }}
                  depositWithdarwTransferOptions={
                    props.depositWithdrawTransferOptions
                  }
                  creditCardOptions={props.creditCardOptions}
                  glEntryClientAccountOptions={
                    props.glEntryClientAccountOptions
                  }
                  isTxnForAccWithOverLimitBal={
                    ((t.transactionType === "WITHDRAWAL" ||
                      t.transactionType === "TRANSFER") &&
                      props.accountIdsWithOverLimitBal.includes(
                        (t as Withdrawal | Transfer).from ?? ""
                      )) ||
                    (t.transactionType === "GL_ENTRY" &&
                      props.accountIdsWithOverLimitBal.includes(
                        (t as GLEntry).clientAccount ?? ""
                      ) &&
                      (t as GLEntry).glType === "credit") ||
                    (t.transactionType === "CREDIT_CARD" &&
                      props.accountIdsWithOverLimitBal.includes(
                        (t as CreditCardTransaction).creditCard ?? ""
                      ) &&
                      (t as CreditCardTransaction).type === "cashadvance")
                      ? true
                      : false
                  }
                  isReadOnlyView={props.isReadOnlyView}
                />
              );
            })}
        </div>
      </div>
      <SessionBalance>
        <LeftContent>Session Balance</LeftContent>
        <RightContent>
          <Flexdiv>
            <div>Debit</div>
            <div style={{ flex: 2 }}>
              {MaskedValue(MASK_TYPE.MONEY, clientOwes.toString())} {"CAD"}
            </div>
          </Flexdiv>

          <Flexdiv>
            <div>Credit</div>
            <div style={{ flex: 2 }}>
              {MaskedValue(MASK_TYPE.MONEY, payoutCash.toString())} {"CAD"}
            </div>
          </Flexdiv>
        </RightContent>
      </SessionBalance>
      {/* {!!props.activeTransactionSession?.transactions && (
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={
            !!props.activeTransactionSession?.transactions
              ? props.activeTransactionSession?.transactions?.length
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => {
            setPage(p);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
          }}
        />
      )} */}
      <PostSection>
        <div style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            disabled={
              !(clientOwes === 0 && payoutCash === 0) ||
              props.isSupervisorOverrideRequired ||
              props.isSupervisorOverrideRejected ||
              props.isAnyTxnForAccWithOverLimitBal ||
              props.isReadOnlyView
            }
            onClick={() => {
              props.onPostClick();
            }}
          >
            Post
          </Button>
        </div>
      </PostSection>
    </ColumnContent>
  );
}

function Header() {
  return (
    <div>
      <SummaryHeader>
        <Column>Transaction</Column>
        <Column>Debit</Column>
        <Column>Credit</Column>
        <Column>Details</Column>
      </SummaryHeader>
    </div>
  );
}

function Row(props: {
  transaction: Transaction;
  onDelete(updatedTransaction: Transaction, deleteAmount: number): void;
  depositWithdarwTransferOptions: IdName[];
  creditCardOptions: IdName[];
  glEntryClientAccountOptions: IdName[];
  isTxnForAccWithOverLimitBal: boolean;
  isReadOnlyView: boolean;
}) {
  if (props.transaction.transactionType === "RECEIVE") {
    return (
      <SummaryRowReceive
        transaction={props.transaction as Receive}
        onDelete={(updatedTransaction, deleteAmount) => {
          props.onDelete(updatedTransaction, deleteAmount);
        }}
        isReadOnlyView={props.isReadOnlyView}
      />
    );
  }
  if (props.transaction.transactionType === "DEPOSIT") {
    let depositTransaction: Deposit = props.transaction as Deposit;
    return (
      <SummaryRowDeposit
        transaction={depositTransaction}
        onDeleteClick={() => {
          props.onDelete(depositTransaction, depositTransaction.amount ?? 0);
        }}
        depositToOptions={props.depositWithdarwTransferOptions}
        isReadOnlyView={props.isReadOnlyView}
      />
    );
  }
  if (props.transaction.transactionType === "WITHDRAWAL") {
    let withdrawalTransaction: Withdrawal = props.transaction as Withdrawal;
    return (
      <SummaryRowWithdrawal
        transaction={withdrawalTransaction}
        onDeleteClick={() => {
          props.onDelete(
            withdrawalTransaction,
            withdrawalTransaction.amount ?? 0
          );
        }}
        withdrawFromOptions={props.depositWithdarwTransferOptions}
        isTxnForAccWithOverLimitBal={props.isTxnForAccWithOverLimitBal}
        isReadOnlyView={props.isReadOnlyView}
      />
    );
  }
  if (props.transaction.transactionType === "PAYOUT_CASH") {
    let payoutTransaction: PayoutCash = props.transaction as PayoutCash;
    return (
      <SummaryRowPayoutCash
        transaction={payoutTransaction}
        onDelete={() => {
          props.onDelete(payoutTransaction, payoutTransaction.amount ?? 0);
        }}
        isReadOnlyView={props.isReadOnlyView}
      />
    );
  }
  if (props.transaction.transactionType === "BILL_PAYMENT") {
    let billPaymentTransaction: BillPayment = props.transaction as BillPayment;
    return (
      <SummaryRowBillPayment
        transaction={billPaymentTransaction}
        onDelete={() => {
          props.onDelete(
            billPaymentTransaction,
            billPaymentTransaction.amount ?? 0
          );
        }}
        isReadOnlyView={props.isReadOnlyView}
      />
    );
  }

  if (props.transaction.transactionType === "CREDIT_CARD") {
    let creditCardTransaction: CreditCardTransaction = props.transaction as CreditCardTransaction;
    return (
      <SummaryRowCreditCard
        transaction={creditCardTransaction}
        onDeleteClick={() => {
          props.onDelete(
            creditCardTransaction,
            creditCardTransaction.amount ?? 0
          );
        }}
        isTxnForAccWithOverLimitBal={props.isTxnForAccWithOverLimitBal}
        creditCardOptions={props.creditCardOptions}
        isReadOnlyView={props.isReadOnlyView}
      />
    );
  }

  if (props.transaction.transactionType === "TRANSFER") {
    let transferTransaction: Transfer = props.transaction as Transfer;
    return (
      <SummaryRowTransfer
        transaction={transferTransaction}
        onDeleteClick={() => {
          props.onDelete(transferTransaction, transferTransaction.amount ?? 0);
        }}
        transferOptions={props.depositWithdarwTransferOptions}
        isTxnForAccWithOverLimitBal={props.isTxnForAccWithOverLimitBal}
        isReadOnlyView={props.isReadOnlyView}
      />
    );
  }
  if (props.transaction.transactionType === "SELL_DRAFT") {
    let sellDraftTransaction: SellDraft = props.transaction as SellDraft;
    return (
      <SummaryRowSellDraft
        transaction={sellDraftTransaction}
        onDeleteClick={() => {
          props.onDelete(
            sellDraftTransaction,
            sellDraftTransaction.amount ?? 0
          );
        }}
        isReadOnlyView={props.isReadOnlyView}
      />
    );
  }

  if (props.transaction.transactionType === "GL_ENTRY") {
    let glEntryTransaction: GLEntry = props.transaction as GLEntry;
    return (
      <SummaryRowGLEntry
        transaction={glEntryTransaction}
        glEntryClientAccountOptions={props.glEntryClientAccountOptions}
        onDeleteClick={() => {
          props.onDelete(glEntryTransaction, glEntryTransaction.amount ?? 0);
        }}
        isTxnForAccWithOverLimitBal={props.isTxnForAccWithOverLimitBal}
        isReadOnlyView={props.isReadOnlyView}
      />
    );
  }

  return <></>;
}

const ColumnContent = styled(Paper)`
  min-height: 600px;
`;

const FlexCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2em;
`;

const SummaryHeader = styled.div`
  display: flex;
  padding: 1em 0;
  font-weight: 500;
  padding-left: 18px;
  padding-right: 40px;
`;
const SessionBalance = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  font-weight: 500;
`;

const Column = styled.div`
  flex: 1;
  padding: 0 1em 0 1.75em;
`;

const LeftContent = styled.div`
  text-align: left;
`;

const RightContent = styled.div`
  text-align: right;
  width: 300px;
`;
const Flexdiv = styled.div`
  display: flex;
`;

const PostSection = styled.div`
  display: flex;
  justify-content: right;
  padding: 1em;
`;
