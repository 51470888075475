import { Button, Paper } from "@mui/material";
import SearchTextField from "components/searchTextField";
import React, { useState } from "react";
import styled from "styled-components";
import { SectionListItem } from "types";
import AddSectionsDialog from "pages/admin/studentSessions/components/AddSectionsDialog";

import SectionList from "pages/admin/studentSessions/components/SectionList";

export type Props = {
  selectedLessonName: string;
  sectionList: SectionListItem[];
  onRemoveClick(sectionId: string): void;
  onAddSections(selectedSections: SectionListItem[]): void;
};

type State = {
  searchText: string;
};

const Sections = (props: Props) => {
  const [state, setState] = useState<State>({ searchText: "" });
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const filteredList =
    props.sectionList && props.sectionList?.length > 0
      ? props.sectionList.filter(
          (s) =>
            s.courseCode
              .toLowerCase()
              .includes(state.searchText.toLowerCase()) ||
            s.section.toLowerCase().includes(state.searchText.toLowerCase()) ||
            s.term.toLowerCase().includes(state.searchText.toLowerCase())
        )
      : [];
  return (
    <Container>
      <Header>
        <SectionTitle> Sections</SectionTitle>
        <Button
          onClick={() => {
            setOpenDialog(true);
          }}
          disabled={
            props.selectedLessonName === undefined ||
            props.selectedLessonName === "this lesson"
          }
        >
          Add Section
        </Button>
      </Header>
      <ListContainer>
        <SearchFieldContainer>
          <SearchTextField
            value={state.searchText}
            label="Search section"
            onChange={(text) => setState({ ...state, searchText: text })}
          />
        </SearchFieldContainer>
        <SectionList
          selectedLessonName={props.selectedLessonName}
          sectionList={filteredList}
          onRemoveClick={(sectionId) => {
            props.onRemoveClick(sectionId);
          }}
        />
      </ListContainer>
      {openDialog && (
        <AddSectionsDialog
          onAdd={(selectedSections) => {
            props.onAddSections(selectedSections);
            setOpenDialog(false);
          }}
          onCancel={() => {
            setOpenDialog(false);
          }}
        />
      )}
    </Container>
  );
};

export default Sections;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
`;

const SectionTitle = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SearchFieldContainer = styled.div`
  padding: 1em 0.25em;
  width: 500px;
  margin: 2em auto;
`;

const Container = styled(Paper)`
  margin-top: 2em;
  min-height: 600px;
  min-width: inherit;
`;
