import { FormHelperText, TextField } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";
import { MASK_TYPE, INPUT_TYPE, FieldError } from "types";
import { FieldContainer } from "./commonFieldStyles";

type Props = {
  id: string;
  label: string;
  value: string;
  onChange(value: string): void;
  required: boolean | undefined;
  multiline: boolean | undefined;
  placeHolder?: string;
  allowNegative?: boolean;
  inputType?: INPUT_TYPE;
  mask?: MASK_TYPE;
  errObj?: FieldError | undefined;
  disabled: boolean | undefined;
  maxLength?: number;
  customStyle?: React.CSSProperties;
};

export default function TextFieldNonFieldEditor(props: Props) {
  let textFieldMaxLength = props.maxLength
    ? props.maxLength
    : props.multiline
    ? 250
    : 25;
  return (
    <FieldContainer>
      <TextField
        id={props.id}
        label={props.label}
        placeholder={props.placeHolder}
        variant="filled"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        required={props.required}
        fullWidth
        multiline={props.multiline}
        rows={props.multiline ? 4 : 1}
        type={props.inputType}
        inputProps={
          props.inputType === INPUT_TYPE.NUMBER
            ? { min: 0, max: 1000000000000000 }
            : { maxLength: textFieldMaxLength }
        }
        InputProps={getMask(props.mask, props.allowNegative)}
        error={!!props.errObj}
        helperText={!!props.errObj && props.errObj.error}
        disabled={props.disabled}
        size="small"
        style={props.customStyle}
      />
      {props.multiline && !props.errObj && (
        <FormHelperText style={{ textAlign: "right" }}>
          {`${props.value.length}/${textFieldMaxLength}`}
        </FormHelperText>
      )}
    </FieldContainer>
  );
}

function getMask(
  mask: MASK_TYPE | undefined,
  allowNegative: boolean | undefined
) {
  if (!mask) {
    return undefined;
  }
  if (mask === MASK_TYPE.SOCIAL_SECURITY_NUMBER) {
    return {
      inputComponent: SINFormat as any,
    };
  }
  if (mask === MASK_TYPE.PHONE_NUMBER) {
    return {
      inputComponent: PhoneFormat as any,
    };
  }

  if (mask === MASK_TYPE.MONEY) {
    if (allowNegative)
      return {
        inputComponent: MoneyFormatWithNegativeValues as any,
      };
    else
      return {
        inputComponent: MoneyFormatWithPositiveValuesOnly as any,
      };
  }

  if (mask === MASK_TYPE.CREDIT_CARD_NUMBER) {
    return {
      inputComponent: CreditCardNumberFormat as any,
    };
  }

  if (mask === MASK_TYPE.PERCENTAGE) {
    if (allowNegative)
      return {
        inputComponent: PercentageFormatWithNegativeValues as any,
      };
    else
      return {
        inputComponent: PercentageFormatWithPositiveValuesOnly as any,
      };
  }

  return undefined;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const SINFormat = React.forwardRef<NumberFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        format="### ### ###"
      />
    );
  }
);

const PhoneFormat = React.forwardRef<NumberFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
      />
    );
  }
);

const CreditCardNumberFormat = React.forwardRef<NumberFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        format="#### #### #### ####"
      />
    );
  }
);

const MoneyFormatWithPositiveValuesOnly = React.forwardRef<
  NumberFormat,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix="$"
      thousandSeparator={true}
      allowNegative={false}
      decimalSeparator="."
      thousandsGroupStyle="thousand"
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
});

const MoneyFormatWithNegativeValues = React.forwardRef<
  NumberFormat,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix="$"
      thousandSeparator={true}
      allowNegative={true}
      decimalSeparator="."
      thousandsGroupStyle="thousand"
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
});

const PercentageFormatWithPositiveValuesOnly = React.forwardRef<
  NumberFormat,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={false}
    />
  );
});

const PercentageFormatWithNegativeValues = React.forwardRef<
  NumberFormat,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={true}
    />
  );
});

//might be used in future to show -ve balance in red

// const StyledTextField = styled(TextField)<{
//   isMoneyField: boolean;
//   isNegativeValue: boolean;
// }>`
//   .Mui-disabled {
//     color: ${(props) =>
//       props.isMoneyField && props.isNegativeValue ? "red" : "black"} !important;
//   }
// `;
