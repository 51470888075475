import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { SectionListItem } from "types";
import styled from "styled-components";
import _orderBy from "lodash/orderBy";

export type Props = {
  sectionList: SectionListItem[];
  onCheckboxClick(updatedSectionListItem: SectionListItem): void;
  isLoading: boolean;
};

export default function SectionList(props: Props) {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  let sortedSsectionList = _orderBy(props.sectionList, (l) => l.section);

  return (
    <div style={{ marginTop: "1em", minWidth: 500, minHeight: 300 }}>
      {(!sortedSsectionList || sortedSsectionList.length === 0) &&
        !props.isLoading && (
          <>
            <Table>
              <Header />
            </Table>
            <Content>
              <div>There are no sections</div>
            </Content>
          </>
        )}
      {props.isLoading && (
        <>
          <Table>
            <Header />
          </Table>
          <Content>
            <CircularProgress />
          </Content>
        </>
      )}

      {sortedSsectionList && sortedSsectionList.length > 0 && !props.isLoading && (
        <Table>
          <Header />
          <TableBody>
            {sortedSsectionList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((l, index) => {
                return (
                  <Row
                    sectionListItem={l}
                    key={index}
                    onCheckboxClick={(updatedSectionListItem) => {
                      props.onCheckboxClick(updatedSectionListItem);
                    }}
                  />
                );
              })}
          </TableBody>
        </Table>
      )}
      {props.sectionList && props.sectionList.length > 0 && !props.isLoading && (
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={props.sectionList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => {
            setPage(p);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
          }}
        />
      )}
    </div>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell></Cell>
        <Cell>Course Code</Cell>
        <Cell>Class Section</Cell>
        <Cell>Course Term</Cell>
      </TableRow>
    </TableHead>
  );
}

function Row(props: {
  sectionListItem: SectionListItem;
  onCheckboxClick(secListItem: SectionListItem): void;
}) {
  return (
    <TableRow>
      <Cell>
        <Checkbox
          id={`${props.sectionListItem.sectionId}_addCheckbox`}
          value={props.sectionListItem.isAdded ?? false}
          checked={props.sectionListItem.isAdded ?? false}
          onChange={() => {
            let secListItem: SectionListItem = {
              ...props.sectionListItem,
              isAdded: props.sectionListItem.isAdded
                ? !props.sectionListItem.isAdded
                : true,
            };
            props.onCheckboxClick(secListItem);
          }}
          size="small"
        />
      </Cell>
      <Cell>{props.sectionListItem.courseCode}</Cell>
      <Cell>{props.sectionListItem.section}</Cell>
      <Cell>{props.sectionListItem.term}</Cell>
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  padding: 10px 16px 10px 16px !important;
  text-align: center !important;
`;

const Content = styled.div`
  margin-top: 3em;
  display: flex;
  justify-content: center;
`;
