import { Grid } from "@mui/material";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessLoanFieldDefinitions";
import { Loan } from "types";
import AccountDisplayTemplate from "pages/clientPortfolio/businessClient/businessClientAccounts/components/AccountDisplayTemplate";
export type Props = {
  account: Loan;
  onEditClick(): void;
  onDeleteClick(): void;
  onAccountHistoryClick(): void;
  isReadOnlyView: boolean;
};

function LoanDisplay({
  account,
  onEditClick,
  onDeleteClick,
  onAccountHistoryClick,
  isReadOnlyView,
}: Props) {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`Loan_${account.accountId}_Display`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      onAccountHistoryClick={onAccountHistoryClick}
      accountType="Loan"
      accountNumber={account.accountNumber || ""}
      type={account.type}
      balance={account.balance || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldDisplay<Loan> field={fields.loanOpenDate} {...commonProps} />
          <FieldDisplay<Loan>
            field={fields.loanAccountNumber}
            {...commonProps}
          />

          <FieldDisplay<Loan> field={fields.loanBalance} {...commonProps} />

          <FieldDisplay<Loan>
            field={fields.loanPaymentAmount}
            {...commonProps}
          />

          <FieldDisplay<Loan>
            field={fields.loanPaymentFrequency}
            {...commonProps}
          />
          <FieldDisplay<Loan>
            field={fields.loanTermStartDate}
            {...commonProps}
          />
          <FieldDisplay<Loan> field={fields.loanTermLength} {...commonProps} />
          <FieldDisplay<Loan>
            field={fields.loanRemainingTermLength}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<Loan> field={fields.loanCloseDate} {...commonProps} />
          <FieldDisplay<Loan>
            field={fields.loanInterestType}
            {...commonProps}
          />
          {account.interestType === "Variable" && (
            <FieldDisplay<Loan> field={fields.loanPrimeRate} {...commonProps} />
          )}
          <FieldDisplay<Loan>
            field={fields.loanInterestRate}
            {...commonProps}
          />
          {account.interestType === "Variable" && (
            <FieldDisplay<Loan>
              field={fields.loanTotalInterestRate}
              {...commonProps}
            />
          )}
          <FieldDisplay<Loan> field={fields.loanType} {...commonProps} />
          {account.type === "Secured" && (
            <FieldDisplay<Loan> field={fields.loanSecurity} {...commonProps} />
          )}
        </Grid>
      </Grid>
    </AccountDisplayTemplate>
  );
}

export default LoanDisplay;
