import { GLEntry } from "types";
import { getMaskedMoneyValue } from "common/functions";
import SupervisorOverrideRowTemplate from "pages/transactions/components/SupervisorOverrideRowTemplate";
import { useReduxState } from "store";

type SupervisorOverrideRowGLEntryProps = {
  transaction: GLEntry;
  onApproveReject(updatedTransaction: GLEntry): void;
};

export default function SupervisorOverrideRowGLEntry(
  props: SupervisorOverrideRowGLEntryProps
) {
  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};

  return (
    <SupervisorOverrideRowTemplate
      transaction="GL Entry"
      debit={
        props.transaction.glType === "debit" &&
        props.transaction.clientAccount === "sessionbalance"
          ? transactionAmount
          : ""
      }
      credit={
        props.transaction.glType === "credit" &&
        props.transaction.clientAccount === "sessionbalance"
          ? transactionAmount
          : ""
      }
      details={
        props.transaction.clientAccount !== "sessionbalance"
          ? transactionAmount
          : ""
      }
      reason={`GL Entry ${getMaskedMoneyValue(
        globalSettings.generalLedgerOverrideDenomination ?? 0
      )} or over`}
      status={
        props.transaction.isApprovalPending === true
          ? "Pending"
          : props.transaction.isApproved === true
          ? "Approved"
          : "Rejected"
      }
      onApprove={() => {
        let updatedTxn: GLEntry = {
          ...props.transaction,
          isApprovalPending: false,
          isApproved: true,
        };

        props.onApproveReject(updatedTxn);
      }}
      onReject={() => {
        let updatedTxn: GLEntry = {
          ...props.transaction,
          isApprovalPending: false,
          isApproved: false,
        };
        props.onApproveReject(updatedTxn);
      }}
    />
  );
}
