import MainTemplate from "components/mainTemplate/MainTemplate";
import ClientPortfolioTemplate from "components/clientPortfolioTemplate/ClientPortfolioTemplate";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { GeneralLedger, BusinessClient } from "types";
import { useActions, useReduxState } from "store";
import { getBusinessClientById, getGeneralLedger } from "pages/lessons/helpers";
import { useHistory } from "react-router-dom";
import { UnauthorizedRoute } from "components/paths";
import {
  checkIfUnauthorized,
  getBusinessClientOperatingAs,
} from "common/functions";
import InformationSection from "pages/clientPortfolio/businessClient/businessClientSummary/components/InformationSection";
import AccountInfoSection from "pages/clientPortfolio/businessClient/businessClientSummary/components/AccountInfoSection";

export type Props = {
  clientId: string;
  lessonId: string;
  studentId?: string;
};

export default function BusinessClientSummary(props: Props) {
  const { getLessonNameAndData, getDataforViewAsStudent } = useReduxState(
    (e) => e.lesson
  );
  const [state, setState] = useState<BusinessClient>(
    getBusinessClientById(
      props.clientId,
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const lessonActions = useActions().lesson;
  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }
  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            if (props.clientId) {
              let client: BusinessClient = getBusinessClientById(
                props.clientId,
                payload.lessonData
              );

              if (!!client.information?.clientId) setState(client);
              else history.push(UnauthorizedRoute);
            }
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getBusinessClientById(
              props.clientId,
              getLessonNameAndData.data.lessonData
            )
          );
          setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
          setIsLoading(false);
        }
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            if (props.clientId) {
              setState(
                getBusinessClientById(props.clientId, payload.lessonData)
              );
            }
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getBusinessClientById(
              props.clientId,
              getDataforViewAsStudent.data.lessonData
            )
          );
          setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
          setIsLoading(false);
        }
      }
    }
  }, []);

  const clientName = getBusinessClientOperatingAs(state);

  const activeAccounts = state.accounts?.filter(
    (a) => !a.closeDate || a.closeDate.toLowerCase() === "invalid date"
  );

  return (
    <MainTemplate
      title={
        isLoading ? "Client Portfolio" : `Client Portfolio — ${clientName}`
      }
      isLoading={isLoading}
      studentId={props.studentId}
      cashDrawerBalance={glState.cashDrawer?.balance}
    >
      <ClientPortfolioTemplate selectedButton={0} studentId={props.studentId}>
        {!isLoading && (
          <Content>
            <Column>
              <InformationSection client={state} />
            </Column>
            <Column>
              {/* Business Client Account Information here */}
              <AccountInfoSection activeAccounts={activeAccounts ?? []} />
            </Column>
          </Content>
        )}
      </ClientPortfolioTemplate>
    </MainTemplate>
  );
}

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Column = styled.div`
  width: 600px;
  margin: 0.5em;
`;
