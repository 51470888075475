import { Grid } from "@mui/material";
import { getPersonalCreditCardTypeLabel } from "common/functions";
import FieldDisplay from "components/FieldDisplay";
import DisplayFieldTemplate from "components/fields/DisplayFieldTemplate";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/creditAppCreditCardFieldDefinitions";
import { CreditAppCreditCard } from "types";
import AccountDisplayTemplate from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/AccountDisplayTemplate";
export type Props = {
  account: CreditAppCreditCard;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

function CreditAppCreditCardDisplay({
  account,
  onEditClick,
  onDeleteClick,
  isReadOnlyView,
}: Props) {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`CREDIT_CARD_${account.accountId}_header`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      accountType="Credit Card"
      accountId={account.accountId || ""}
      type={getPersonalCreditCardTypeLabel(account.type ?? "")}
      requestedAmount={account.requestedAmount || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DisplayFieldTemplate label="Account Type">
            Credit Card
          </DisplayFieldTemplate>
          <FieldDisplay<CreditAppCreditCard>
            field={fields.requestedAmount}
            {...commonProps}
          />
          <FieldDisplay<CreditAppCreditCard>
            field={fields.minimumPayment}
            {...commonProps}
          />

          <FieldDisplay<CreditAppCreditCard>
            field={fields.type}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<CreditAppCreditCard>
            field={fields.hasSecurity}
            {...commonProps}
          />
          <FieldDisplay<CreditAppCreditCard>
            field={fields.security}
            {...commonProps}
          />
        </Grid>
      </Grid>
    </AccountDisplayTemplate>
  );
}

export default CreditAppCreditCardDisplay;
