import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";

type AddDetailsMenuProps = {
  onAddCreditCard(): void;
  onAddLOC(): void;
  onAddLoan(): void;
  onAddMortgage(): void;
  isReadOnlyView: boolean;
};

export default function AddAccountsMenu(props: AddDetailsMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuContainer>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        disabled={props.isReadOnlyView}
      >
        Add
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            props.onAddCreditCard();
            handleClose();
          }}
        >
          Credit Card
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddLOC();
            handleClose();
          }}
        >
          Line Of Credit
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddLoan();
            handleClose();
          }}
        >
          Loan
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddMortgage();
            handleClose();
          }}
        >
          Mortgage
        </MenuItem>
      </Menu>
    </MenuContainer>
  );
}

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
`;
