import CashInputControl from "components/cashInputControl/CashInputControl";
import styled from "styled-components";
import { FieldError, PayoutCash, Receive } from "types";
import CurrencyToggleMenu from "pages/transactions/components/CurrencyToggleMenu";

type CommonProps = {
  state: PayoutCash | Receive;
  setState(state: PayoutCash | Receive): void;
  errors: FieldError[];
};

export default function CashSection(commonProps: CommonProps) {
  let currencyType = commonProps.state.cash?.cashCurrencyType;

  function handleCurrencyToggle(selectedButton: number) {
    commonProps.setState({
      ...commonProps.state,
      cash: {
        ...commonProps.state.cash,
        cashCurrencyType: selectedButton === 0 ? "cad" : "usd",
        dollar1Bills:
          selectedButton === 0 ? 0 : commonProps.state.cash?.dollar1Bills,
        dollar2Bills:
          selectedButton === 0 ? 0 : commonProps.state.cash?.dollar2Bills,
        dollar2Coins:
          selectedButton === 0 ? commonProps.state.cash?.dollar2Coins : 0,
        dollar1Coins:
          selectedButton === 0 ? commonProps.state.cash?.dollar1Coins : 0,
        cent25Coins:
          selectedButton === 0 ? commonProps.state.cash?.cent25Coins : 0,
        cent10Coins:
          selectedButton === 0 ? commonProps.state.cash?.cent10Coins : 0,
        cent5Coins:
          selectedButton === 0 ? commonProps.state.cash?.cent5Coins : 0,
      },
    });
  }
  return (
    <>
      <TopSection>
        <SectionHeading>Cash</SectionHeading>
        <CurrencyToggleMenu
          onToggle={(selectedButton) => {
            handleCurrencyToggle(selectedButton);
          }}
        />
      </TopSection>
      <FlexDiv>
        <Column>
          <CashInputControl
            billType={currencyType === "cad" ? "CAD100Dollar" : "USD100Dollar"}
            initialQuantity={0}
            onValueChange={(value) => {
              commonProps.setState({
                ...commonProps.state,
                cash: { ...commonProps.state.cash, dollar100Bills: value },
              });
            }}
          />
          <CashInputControl
            billType={currencyType === "cad" ? "CAD50Dollar" : "USD50Dollar"}
            initialQuantity={0}
            onValueChange={(value) => {
              commonProps.setState({
                ...commonProps.state,
                cash: { ...commonProps.state.cash, dollar50Bills: value },
              });
            }}
          />
          <CashInputControl
            billType={currencyType === "cad" ? "CAD20Dollar" : "USD20Dollar"}
            initialQuantity={0}
            onValueChange={(value) => {
              commonProps.setState({
                ...commonProps.state,
                cash: { ...commonProps.state.cash, dollar20Bills: value },
              });
            }}
          />
          <CashInputControl
            billType={currencyType === "cad" ? "CAD10Dollar" : "USD10Dollar"}
            initialQuantity={0}
            onValueChange={(value) => {
              commonProps.setState({
                ...commonProps.state,
                cash: { ...commonProps.state.cash, dollar10Bills: value },
              });
            }}
          />
          <CashInputControl
            billType={currencyType === "cad" ? "CAD5Dollar" : "USD5Dollar"}
            initialQuantity={0}
            onValueChange={(value) => {
              commonProps.setState({
                ...commonProps.state,
                cash: { ...commonProps.state.cash, dollar5Bills: value },
              });
            }}
          />
          {currencyType === "usd" && (
            <CashInputControl
              billType={"USD2Dollar"}
              initialQuantity={0}
              onValueChange={(value) => {
                commonProps.setState({
                  ...commonProps.state,
                  cash: { ...commonProps.state.cash, dollar2Bills: value },
                });
              }}
            />
          )}
          {currencyType === "usd" && (
            <CashInputControl
              billType={"USD1Dollar"}
              initialQuantity={0}
              onValueChange={(value) => {
                commonProps.setState({
                  ...commonProps.state,
                  cash: { ...commonProps.state.cash, dollar1Bills: value },
                });
              }}
            />
          )}
        </Column>
        {currencyType === "cad" && (
          <Column>
            <CashInputControl
              billType="CAD2Coin"
              initialQuantity={0}
              onValueChange={(value) => {
                commonProps.setState({
                  ...commonProps.state,
                  cash: { ...commonProps.state.cash, dollar2Coins: value },
                });
              }}
            />
            <CashInputControl
              billType="CAD1Coin"
              initialQuantity={0}
              onValueChange={(value) => {
                commonProps.setState({
                  ...commonProps.state,
                  cash: { ...commonProps.state.cash, dollar1Coins: value },
                });
              }}
            />
            <CashInputControl
              billType="CAD25Coin"
              initialQuantity={0}
              onValueChange={(value) => {
                commonProps.setState({
                  ...commonProps.state,
                  cash: { ...commonProps.state.cash, cent25Coins: value },
                });
              }}
            />
            <CashInputControl
              billType="CAD10Coin"
              initialQuantity={0}
              onValueChange={(value) => {
                commonProps.setState({
                  ...commonProps.state,
                  cash: { ...commonProps.state.cash, cent10Coins: value },
                });
              }}
            />
            <CashInputControl
              billType="CAD5Coin"
              initialQuantity={0}
              onValueChange={(value) => {
                commonProps.setState({
                  ...commonProps.state,
                  cash: { ...commonProps.state.cash, cent5Coins: value },
                });
              }}
            />
          </Column>
        )}
        <SpacerColumn />
      </FlexDiv>
    </>
  );
}

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -1em;
`;
const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Column = styled.div`
  min-width: 250px;
  margin: 10px;
`;

const SpacerColumn = styled.div`
  flex: 1;
`;

const SectionHeading = styled.div`
  font-weight: 500;
  font-size: 18px;
  flex: 1;
  align-self: center;
`;
