import { Button, Paper } from "@mui/material";
import SearchTextField from "components/searchTextField/SearchTextField";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomStudent, StudentListItem } from "types";
import AddStudentDialog from "pages/admin/studentSessions/components/AddStudentDialog";
import StudentList from "pages/admin/studentSessions/components/LessonStudentList";

export type Props = {
  studentList: StudentListItem[];
  onCheckboxClick(updatedStudent: StudentListItem): void;
  onSyncClick(): void;
  onUpdateAccessClick(): void;
  onViewClick(studentId: string, studentName: string): void;
  onAddStudent(customStudent: CustomStudent): void;
  onSelectAllClick(selectedStudentList: StudentListItem[]): void;
  onDeselectAllClick(selectedStudentList: StudentListItem[]): void;
  onReadOnlySelectAllClick(selectedStudentList: StudentListItem[]): void;
  onReadOnlyDeselectAllClick(selectedStudentList: StudentListItem[]): void;
  onResetClick(studentId: string, studentName: string): void;
  lessonName: string;
  isAccessUpdated: boolean;
  isSyncDisabled: boolean;
  isAddStudentDisabled: boolean;
  isTimeBasedAccess: boolean;
};

export default function Students(props: Props) {
  const [searchText, setSearchText] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [
    isAllAccessCheckboxClicked,
    setIsAllAccessCheckboxClicked,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIsAllAccessCheckboxClicked(false);
  }, [searchText]);

  const filteredList =
    props.studentList && props.studentList?.length > 0
      ? props.studentList.filter(
          (s) =>
            s.studentId.toLowerCase().includes(searchText.toLowerCase()) ||
            s.courseCode.toLowerCase().includes(searchText.toLowerCase()) ||
            s.section.toLowerCase().includes(searchText.toLowerCase()) ||
            s.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
            s.userName.toLowerCase().includes(searchText.toLowerCase())
        )
      : [];

  return (
    <Container>
      <Header>
        <SectionTitle> Students</SectionTitle>
        <Button disabled={props.isSyncDisabled} onClick={props.onSyncClick}>
          Sync
        </Button>
        <Spacer />
        <Button
          disabled={props.isAddStudentDisabled}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Add Student
        </Button>
        <Spacer />

        <Button
          disabled={!props.isAccessUpdated}
          variant="contained"
          onClick={props.onUpdateAccessClick}
        >
          Update Access
        </Button>
      </Header>
      <ListContainer>
        <SearchFieldContainer>
          <SearchTextField
            value={searchText}
            label="Search student"
            onChange={(text) => setSearchText(text)}
          />
        </SearchFieldContainer>
        <StudentList
          studentList={filteredList}
          lessonName={props.lessonName}
          onViewClick={(studentId, studentName) => {
            props.onViewClick(studentId, studentName);
          }}
          onCheckboxClick={(updatedStudent) => {
            props.onCheckboxClick(updatedStudent);
          }}
          onSelectAllClick={(selectedStudentList) => {
            props.onSelectAllClick(selectedStudentList);
            setIsAllAccessCheckboxClicked(true);
          }}
          onDeselectAllClick={(selectedStudentList) => {
            props.onDeselectAllClick(selectedStudentList);
            setIsAllAccessCheckboxClicked(true);
          }}
          onReadOnlySelectAllClick={props.onReadOnlySelectAllClick}
          onReadOnlyDeselectAllClick={props.onReadOnlyDeselectAllClick}
          onResetClick={(studentId, studentName) => {
            props.onResetClick(studentId, studentName);
          }}
          isAllAccessCheckboxClicked={isAllAccessCheckboxClicked}
          isTimeBasedAccess={props.isTimeBasedAccess}
        />
      </ListContainer>
      {openDialog && (
        <AddStudentDialog
          studentList={props.studentList}
          onAdd={(newCustomStudent) => {
            props.onAddStudent(newCustomStudent);
            setOpenDialog(false);
          }}
          onCancel={() => {
            setOpenDialog(false);
          }}
        />
      )}
    </Container>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
`;

const SectionTitle = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SearchFieldContainer = styled.div`
  padding: 1em 0.25em;
  width: 500px;
  margin: 2em auto;
`;

const Container = styled(Paper)`
  margin-top: 2em;
  min-height: 600px;
  min-width: inherit;
`;

const Spacer = styled.div`
  margin: 0 0.75em;
`;
