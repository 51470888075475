import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  BusinessClientInformationRoute,
  PersonalClientInformationRoute,
} from "components/paths";
import { Link } from "react-router-dom";
import { useReduxState } from "store";
import styled from "styled-components";

type ClientTypeTemplateProps = {
  selectedButton: number;
  studentId?: string;
};
export default function ClientTypeTemplate({
  selectedButton,
}: ClientTypeTemplateProps) {
  const { lessonId } = useReduxState((e) => e.lesson);

  return (
    <ToggleContainer>
      <ToggleButtonGroup
        value={selectedButton}
        color="primary"
        exclusive
        style={{
          background: "white",
          borderRadius: "15px",
        }}
      >
        <ToggleButton
          value={0}
          component={Link}
          to={`/${lessonId}${PersonalClientInformationRoute}`}
          style={{ borderRadius: "15px 0px 0px 15px" }}
        >
          Personal
        </ToggleButton>

        <ToggleButton
          component={Link}
          to={`/${lessonId}${BusinessClientInformationRoute}`}
          value={1}
          style={{ borderRadius: "0px 15px 15px 0px" }}
        >
          Business
        </ToggleButton>
      </ToggleButtonGroup>
    </ToggleContainer>
  );
}

const ToggleContainer = styled.div`
  text-align: center;
`;
