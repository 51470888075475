import {
  getAccountTypeLabel,
  getBusinessCreditCardTypeLabel,
  getPersonalCreditCardTypeLabel,
  getPersonalLOCTypeLabel,
} from "common/functions";
import MaskedValue from "common/MaskedValue";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  Account,
  AccountAdditionalInfo,
  CreditCard,
  LineOfCredit,
  Loan,
  MASK_TYPE,
  Mortgage,
} from "types";
import AdditionalInformation from "components/clientInfoTemplate/AdditionalInformation";

export type Props = {
  accounts: Account[];
};

export default function LiabilitiesDisplay(props: Props) {
  const loanAccounts: Loan[] = props.accounts.filter(
    (a) => a.accountType === "LOAN"
  );

  const creditCardAccounts: CreditCard[] = props.accounts.filter(
    (a) => a.accountType === "CREDIT_CARD"
  );

  const mortgageAccounts: Mortgage[] = props.accounts.filter(
    (a) => a.accountType === "MORTGAGE"
  );

  const iineOfCreditAccounts: LineOfCredit[] = props.accounts.filter(
    (a) => a.accountType === "LINE_OF_CREDIT"
  );

  const location = useLocation();
  let isBusinessClient = location.pathname.includes("businessclient");
  return (
    <>
      {props.accounts.length > 0 && (
        <AccountContainer>
          {loanAccounts.map((a, index) => {
            return (
              <AccountRowDisplay
                key={index}
                accountType={a.accountType}
                number={a.accountNumber || ""}
                balance={a.balance?.toString() || "0"}
                accountAdditionalInfo={{
                  type: a.type,
                }}
              />
            );
          })}
          {mortgageAccounts.map((a, index) => {
            return (
              <AccountRowDisplay
                key={index}
                accountType={a.accountType}
                number={a.accountNumber || ""}
                balance={a.balance?.toString() || "0"}
                accountAdditionalInfo={{
                  type: a.type,
                }}
              />
            );
          })}
          {creditCardAccounts.map((a, index) => {
            return (
              <AccountRow key={index}>
                <AccountType>
                  {getAccountTypeLabel(a.accountType)}
                  <AdditionalInformation
                    accountType={a.accountType}
                    additionalInfo={{
                      type: isBusinessClient
                        ? getBusinessCreditCardTypeLabel(a.type ?? "")
                        : getPersonalCreditCardTypeLabel(a.type ?? ""),
                    }}
                  />
                </AccountType>
                <AccountNumber>
                  {MaskedValue(
                    MASK_TYPE.CREDIT_CARD_NUMBER,
                    a.cardNumber ?? ""
                  )}
                </AccountNumber>
                <Balance
                  isBalanceMoreThanLimit={(a.balance ?? 0) > (a.limit ?? 0)}
                >
                  {MaskedValue(MASK_TYPE.MONEY, a.balance?.toString() || "0")}
                </Balance>
              </AccountRow>
            );
          })}
          {iineOfCreditAccounts.map((a, index) => {
            return (
              <AccountRowDisplay
                key={index}
                accountType={a.accountType}
                number={a.accountNumber || ""}
                balance={a.balance?.toString() || "0"}
                isBalanceMoreThanLimit={(a.balance ?? 0) > (a.limit ?? 0)}
                accountAdditionalInfo={{
                  type: isBusinessClient
                    ? "BLOC"
                    : getPersonalLOCTypeLabel(a.type ?? ""),
                }}
              />
            );
          })}
        </AccountContainer>
      )}
    </>
  );
}

function AccountRowDisplay(props: {
  accountType: string;
  number: string;
  balance: string;
  isBalanceMoreThanLimit?: boolean;
  accountAdditionalInfo?: AccountAdditionalInfo;
}) {
  return (
    <AccountRow>
      <AccountType>
        {getAccountTypeLabel(props.accountType)}
        <AdditionalInformation
          accountType={props.accountType}
          additionalInfo={props.accountAdditionalInfo ?? {}}
        />
      </AccountType>
      <AccountNumber>{props.number}</AccountNumber>
      <Balance isBalanceMoreThanLimit={props.isBalanceMoreThanLimit}>
        {MaskedValue(MASK_TYPE.MONEY, props.balance)}
      </Balance>
    </AccountRow>
  );
}
const AccountType = styled.div`
  font-weight: 500;
  width: 120px;
  word-wrap: break-word;
  font-size: 14px;
  padding-bottom: 6px;
  padding-right: 15px;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const AccountContainer = styled.div`
  padding-bottom: 0.5em;
`;

const AccountRow = styled.div`
  display: flex;
  justify-content: right;
  padding: 6px 3px 0px 3px;
`;
const AccountNumber = styled.div`
  width: 80px;
  font-size: 14px;
  color: #0000008a;
`;

const Balance = styled.div<{ isBalanceMoreThanLimit?: boolean }>`
  width: 100px;
  font-size: 14px;
  text-align: right;
  color: ${(p) =>
    p.isBalanceMoreThanLimit === true ? "#d32f2f;" : "#000000de;"};
`;
