import {  BoolField,  FIELD_TYPE,  PersonalClient, TextFieldType } from "types";

export const characterCheckbox: BoolField<PersonalClient> = {
  id: `characterCheckbox`,
  label: "Character (Will they pay?)",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (client: PersonalClient) => client.comments?.characterCheckbox ?? false,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, characterCheckbox: value},
  }),
  labelPlacement: "end",
};

export const characterComment: TextFieldType<PersonalClient> = {
  id: "characterComment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.comments?.characterComment,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, characterComment: value} ,
  }),
  multiline: true,
  maxLength: 1000,
};

export const capitalCheckbox: BoolField<PersonalClient> = {
  id: `capitalCheckbox`,
  label: "Capital (What if they can't pay?)",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (client: PersonalClient) => client.comments?.capitalCheckbox ?? false,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, capitalCheckbox: value},
  }), 
  labelPlacement: "end",
};

export const capitalComment: TextFieldType<PersonalClient> = {
  id: "capitalComment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.comments?.capitalComment,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, capitalComment: value} ,
  }),
  multiline: true,
  maxLength: 1000,
};
export const capacityCheckbox: BoolField<PersonalClient> = {
  id: `capacityCheckbox`,
  label: "Capacity (Can they pay?)",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (client: PersonalClient) => client.comments?.capacityCheckbox ?? false,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, capacityCheckbox: value},
  }), 
  labelPlacement: "end",
};

export const capacityComment: TextFieldType<PersonalClient> = {
  id: "capacityComment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.comments?.capacityComment,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, capacityComment: value} ,
  }),
  multiline: true,
  maxLength: 1000,
};
export const creditHistoryCheckbox: BoolField<PersonalClient> = {
  id: `creditHistoryCheckbox`,
  label: "Credit History (Have they paid?)",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (client: PersonalClient) => client.comments?.creditHistoryCheckbox ?? false,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, creditHistoryCheckbox: value},
  }), 
  labelPlacement: "end",
};

export const creditHistoryComment: TextFieldType<PersonalClient> = {
  id: "creditHistoryComment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.comments?.creditHistoryComment,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, creditHistoryComment: value} ,
  }),
  multiline: true,
  maxLength: 1000,
};
export const collateralCheckbox: BoolField<PersonalClient> = {
  id: `collateralCheckbox`,
  label: "Collateral (What if they don't pay?)",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (client: PersonalClient) => client.comments?.collateralCheckbox ?? false,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, collateralCheckbox: value},
  }), 
  labelPlacement: "end",
};

export const collateralComment: TextFieldType<PersonalClient> = {
  id: "collateralComment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.comments?.collateralComment,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, collateralComment: value} ,
  }),
  multiline: true,
  maxLength: 1000,
};
export const submissionComment: TextFieldType<PersonalClient> = {
  id: "submissionComment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.comments?.submissionComment,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, submissionComment: value} ,
  }),
  multiline: true,
  maxLength: 2500,
};

export const customerAuthorizationName: TextFieldType<PersonalClient> = {
  id: `customerAuthorizationName`,
  label: "Name",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.comments?.customerAuthorizationName,
  setValue: (value, client) => ({
    ...client,
    comments: {...client.comments, customerAuthorizationName: value},
  }),
  
};