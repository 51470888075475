import { Paper, Table, TableBody } from "@mui/material";
import { TableCell, TableHead, TableRow } from "@mui/material";
import AccountHistoryRowDeposit from "pages/accountHistory/components/AccountHistoryRowDeposit";
import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Account,
  CreditCardTransaction,
  GLEntry,
  IdName,
  Transaction,
  TransactionSession,
  Transfer,
} from "types";
import AddTransactionsMenu from "pages/accountHistory/components/AddTransactionsMenu";
import { add, subtract, getMaskedMoneyValue } from "common/functions";
import AccountHistoryRowWithdrawal from "pages/accountHistory/components/AccountHistoryRowWithdrawal";
import { assetsAccountsTypes } from "common/helpers";
import { getLoginInfo } from "common/azureAD";
import { v4 } from "uuid";

export type Props = {
  accounts: Account[];
  openAccountOptions: IdName[];
  closedAccountOptions: IdName[];
  transactionSessions: TransactionSession[];
  selectedAccountId: string;
  onAccountOptionChange(selectedAccountId: string): void;
  onEditClick(transaction: Transaction): void;
  onDeleteClick(transaction: Transaction): void;
  onDuplicateClick(transaction: Transaction): void;
  onAddDeposit(): void;
  onAddWithdrawal(): void;
  isReadOnlyView: boolean;
};

export default function AccountDetails(props: Props) {
  useEffect(() => {
    setAccountOption(props.selectedAccountId);
  }, [props.selectedAccountId]);
  const [accountOption, setAccountOption] = useState<string>(
    props.selectedAccountId ?? ""
  );

  const selectedAccount = props.accounts.find(
    (a) => a.accountId === accountOption
  );

  let balance = selectedAccount?.balance;
  let txnAmount = 0;
  let isSelectedAccountClosed: boolean = selectedAccount
    ? !!selectedAccount.closeDate &&
      selectedAccount.closeDate.toLowerCase() !== "invalid date"
    : true;
  let isSelectedAccountGICOrMutualFund: boolean =
    selectedAccount &&
    ["GIC", "MUTUAL_FUNDS"].includes(selectedAccount.accountType)
      ? true
      : false;

  const selectedAccountBalance = getMaskedMoneyValue(
    selectedAccount?.balance ?? 0
  );

  const transactionIds: string[] = selectedAccount?.transactionIds ?? [];

  let transactions: Transaction[] = [];

  for (let i = 0; i < props.transactionSessions.length; i++) {
    let tempTxns =
      props.transactionSessions[i].transactions?.filter((t) =>
        transactionIds.includes(t.transactionId)
      ) ?? [];

    transactions = [...transactions, ...tempTxns];
  }

  let sortedTxns = transactions.sort(
    (a, b) =>
      new Date(b.transactionDateTime ?? 0).getTime() -
      new Date(a.transactionDateTime ?? 0).getTime()
  );
  const loginInfo = getLoginInfo();
  if (props.accounts === undefined || props.accounts.length < 1)
    return (
      <ColumnContent>
        <FlexCenter>There are no Active Accounts</FlexCenter>
      </ColumnContent>
    );
  return (
    <ColumnContent>
      <TopSection>
        <PicklistContainer>
          <PickListNonFieldEditor
            id="accounts"
            label="Account"
            value={accountOption}
            onChange={(v) => {
              props.onAccountOptionChange(v);
            }}
            options={props.openAccountOptions}
            optionsWithCategory={
              props.closedAccountOptions.length > 0 ? true : false
            }
            primaryOptionCategoryLabel="Open"
            secondaryOptionCategoryLabel="Closed"
            secondaryOptions={props.closedAccountOptions}
          />
        </PicklistContainer>
        {selectedAccount && (
          <>
            <DateBalanceSection>
              <TwoColumnDisplay
                label="Open Date"
                value={selectedAccount.openDate ?? ""}
              />
              <TwoColumnDisplay
                label="Balance"
                value={selectedAccountBalance}
              />
              {isSelectedAccountClosed && (
                <TwoColumnDisplay
                  label="Close Date"
                  value={selectedAccount.closeDate ?? "Not Set"}
                />
              )}
            </DateBalanceSection>
            {!isSelectedAccountClosed &&
              !isSelectedAccountGICOrMutualFund &&
              loginInfo?.isInstructor && (
                <AddTransactionsMenu
                  onAddDeposit={props.onAddDeposit}
                  onAddWithdrawal={props.onAddWithdrawal}
                  isReadOnlyView={props.isReadOnlyView}
                />
              )}
          </>
        )}
      </TopSection>
      {accountOption === "" && (
        <FlexCenter>Select Account to see the account history</FlexCenter>
      )}
      {!!accountOption &&
        (sortedTxns === undefined || sortedTxns.length === 0) && (
          <FlexCenter>No Transactions yet</FlexCenter>
        )}
      {!!accountOption && sortedTxns.length > 0 && (
        <>
          <Table>
            <Header />
            <TableBody>
              <>
                {sortedTxns.map((t, index) => {
                  balance =
                    index === 0
                      ? balance
                      : assetsAccountsTypes.includes(
                          selectedAccount?.accountType ?? ""
                        )
                      ? sortedTxns[index - 1].transactionType === "DEPOSIT" ||
                        (sortedTxns[index - 1].transactionType === "TRANSFER" &&
                          (sortedTxns[index - 1] as Transfer).to ===
                            selectedAccount?.accountId) ||
                        (sortedTxns[index - 1].transactionType === "GL_ENTRY" &&
                          (sortedTxns[index - 1] as GLEntry).glType ===
                            "debit" &&
                          (sortedTxns[index - 1] as GLEntry).clientAccount ===
                            selectedAccount?.accountId)
                        ? subtract(balance ?? 0, txnAmount)
                        : sortedTxns[index - 1].transactionType ===
                            "WITHDRAWAL" ||
                          (sortedTxns[index - 1].transactionType ===
                            "TRANSFER" &&
                            (sortedTxns[index - 1] as Transfer).from ===
                              selectedAccount?.accountId) ||
                          (sortedTxns[index - 1].transactionType ===
                            "GL_ENTRY" &&
                            (sortedTxns[index - 1] as GLEntry).glType ===
                              "credit" &&
                            (sortedTxns[index - 1] as GLEntry).clientAccount ===
                              selectedAccount?.accountId)
                        ? add(balance ?? 0, txnAmount)
                        : balance
                      : sortedTxns[index - 1].transactionType === "DEPOSIT" ||
                        (sortedTxns[index - 1].transactionType ===
                          "CREDIT_CARD" &&
                          (sortedTxns[index - 1] as CreditCardTransaction)
                            .type === "payment") ||
                        (sortedTxns[index - 1].transactionType === "TRANSFER" &&
                          (sortedTxns[index - 1] as Transfer).to ===
                            selectedAccount?.accountId)
                      ? add(balance ?? 0, txnAmount)
                      : sortedTxns[index - 1].transactionType ===
                          "WITHDRAWAL" ||
                        (sortedTxns[index - 1].transactionType ===
                          "CREDIT_CARD" &&
                          (sortedTxns[index - 1] as CreditCardTransaction)
                            .type === "cashadvance") ||
                        (sortedTxns[index - 1].transactionType === "TRANSFER" &&
                          (sortedTxns[index - 1] as Transfer).from ===
                            selectedAccount?.accountId)
                      ? subtract(balance ?? 0, txnAmount)
                      : balance;
                  txnAmount = t.amount ?? 0;
                  if (
                    t.transactionType === "DEPOSIT" ||
                    (t.transactionType === "CREDIT_CARD" &&
                      (t as CreditCardTransaction).type === "payment") ||
                    (t.transactionType === "TRANSFER" &&
                      (t as Transfer).to === selectedAccount?.accountId) ||
                    (t.transactionType === "GL_ENTRY" &&
                      (t as GLEntry).glType === "debit" &&
                      (t as GLEntry).clientAccount ===
                        selectedAccount?.accountId)
                  ) {
                    return (
                      <AccountHistoryRowDeposit
                        key={index}
                        transaction={t}
                        balance={balance ?? 0}
                        isSelectedAccountClosed={isSelectedAccountClosed}
                        accountType={selectedAccount?.accountType}
                        onEditClick={() => {
                          props.onEditClick(t);
                        }}
                        onDeleteClick={() => {
                          props.onDeleteClick(t);
                        }}
                        onDuplicateClick={() => {
                          props.onDuplicateClick({ ...t, transactionId: v4() });
                        }}
                        isReadOnlyView={props.isReadOnlyView}
                      />
                    );
                  }
                  if (
                    t.transactionType === "WITHDRAWAL" ||
                    (t.transactionType === "CREDIT_CARD" &&
                      (t as CreditCardTransaction).type === "cashadvance") ||
                    (t.transactionType === "TRANSFER" &&
                      (t as Transfer).from === selectedAccount?.accountId) ||
                    (t.transactionType === "GL_ENTRY" &&
                      (t as GLEntry).glType === "credit" &&
                      (t as GLEntry).clientAccount ===
                        selectedAccount?.accountId)
                  ) {
                    return (
                      <AccountHistoryRowWithdrawal
                        key={index}
                        transaction={t}
                        balance={balance ?? 0}
                        isSelectedAccountClosed={isSelectedAccountClosed}
                        accountType={selectedAccount?.accountType}
                        onEditClick={() => {
                          props.onEditClick(t);
                        }}
                        onDeleteClick={() => {
                          props.onDeleteClick(t);
                        }}
                        onDuplicateClick={() => {
                          props.onDuplicateClick({ ...t, transactionId: v4() });
                        }}
                        isReadOnlyView={props.isReadOnlyView}
                      />
                    );
                  }
                  return <></>;
                })}
              </>
            </TableBody>
          </Table>
        </>
      )}
    </ColumnContent>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell>Date</Cell>
        <Cell>Type</Cell>
        <Cell>Description</Cell>
        <Cell>Debit</Cell>
        <Cell>Credit</Cell>
        <Cell>Balance</Cell>
        <Cell></Cell>
      </TableRow>
    </TableHead>
  );
}

function TwoColumnDisplay(props: { label: string; value: string }) {
  return (
    <RowContainer>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </RowContainer>
  );
}
const Label = styled.label`
  color: #0000008a;
  padding-right: 15px;
  font-size: 16px;
`;
const Value = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  padding-right: 15px;
`;
const RowContainer = styled.div`
  padding-left: 2.5em;
  display: flex;
`;

const ColumnContent = styled(Paper)`
  min-height: 600px;
  min-width: 800px;
`;

const FlexCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2em;
`;

const TopSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
`;

const DateBalanceSection = styled.div`
  display: flex;
  justify-content: right;
`;

const PicklistContainer = styled.div`
  width: 300px;
`;

const Cell = styled(TableCell)`
  font-size: 16px !important;
  text-align: center !important;
`;
