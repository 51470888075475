import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import { useState } from "react";
import styled from "styled-components";
import { CustomAsset } from "types";
import { v4 } from "uuid";
import CustomAssetsList from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CustomAssetsList";
import CustomAssetEditor from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CustomAssetEditor";

export type Props = {
  customAssets: CustomAsset[];
  naitlabAssetsTotal: number;
  creditAppAssetsTotal: number;
  isReadOnlyView: boolean;
  open: boolean;
  onClose(): void;
  onAddEditCustomAsset(updatedCustomAsset: CustomAsset): void;
  onDeleteCustomAsset(customAsset: CustomAsset): void;
};

export default function ManageAssetsDialog({
  customAssets,
  naitlabAssetsTotal,
  creditAppAssetsTotal,
  isReadOnlyView,
  open,
  onClose,
  onAddEditCustomAsset,
  onDeleteCustomAsset: onDeletCustomAsset,
}: Props) {
  function addCustomAsset() {
    let customAsset: CustomAsset = {
      id: v4(),
    };
    setCustomAssetInEdit(customAsset);
  }

  const [customAssetInEdit, setCustomAssetInEdit] = useState<
    CustomAsset | undefined
  >(undefined);

  return (
    <Dialog maxWidth={"md"} open={open}>
      <DialogContent>
        <Header>
          <Typography style={{ flexGrow: 1 }} variant={"h6"}>
            Manage Assets
          </Typography>
          <Button
            variant="contained"
            onClick={addCustomAsset}
            disabled={isReadOnlyView}
          >
            Add
          </Button>
        </Header>

        <ContentContainer>
          <CustomAssetsList
            customAssets={customAssets ?? []}
            onEditCustomAsset={(customAsset) => {
              setCustomAssetInEdit(customAsset);
            }}
            onDeleteCustomasset={(customAsset) => {
              onDeletCustomAsset(customAsset);
            }}
            isReadOnlyView={isReadOnlyView}
          />
        </ContentContainer>
        <Divider style={{ marginTop: "3em" }} />
        <TotalSection>
          <LeftSection>
            <LightBold>NAITLAB Assets</LightBold>
            {getMaskedMoneyValue(naitlabAssetsTotal)}
            <Spacer />
            CAD
          </LeftSection>

          <RightSection>
            <LightBold>Total Assets </LightBold>{" "}
            {getMaskedMoneyValue(creditAppAssetsTotal)}
            <Spacer />
            CAD
          </RightSection>
        </TotalSection>
        <Divider />
        <ButtonContainer>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </ButtonContainer>
        {!!customAssetInEdit && (
          <CustomAssetEditor
            customAsset={customAssetInEdit}
            onAddorUpdateClick={(updatedCustomAsset) => {
              onAddEditCustomAsset(updatedCustomAsset);
              setCustomAssetInEdit(undefined);
            }}
            onCancel={() => {
              setCustomAssetInEdit(undefined);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

const Header = styled.div`
  display: flex;
  padding: 1em 0.25em;
`;

const ContentContainer = styled.div`
  min-width: 600px;
  justify-content: stretch;
  display: flex;
`;

const LightBold = styled.div`
  font-weight: 500;
  margin-right: 10px;
`;
const LeftSection = styled.div`
  flex: 1;
  display: flex;
`;
const RightSection = styled.div`
  text-align: right;
  display: flex;
`;
const Spacer = styled.div`
  margin-right: 5px;
`;
const TotalSection = styled.div`
  margin: 1em;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 1em auto 0em auto;
  justify-content: center;
`;
