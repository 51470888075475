import { useState } from "react";
import styled from "styled-components";
import { createSvgIcon } from "@nait-aits/ui";
import PersonIcon from "@mui/icons-material/Person";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useMsal } from "@azure/msal-react";
import type { LoginInfo } from "common/azureAD";
import { IconButton } from "@mui/material";

export type Props = Partial<LoginInfo>;

const InstructorIcon = createSvgIcon({
  defaultProps: {
    viewBox: "0 0 640 512",
  },
  content: (
    <path
      fill="currentColor"
      d="M208 352c-2.39 0-4.78.35-7.06 1.09C187.98 357.3 174.35 360 160 360c-14.35 0-27.98-2.7-40.95-6.91-2.28-.74-4.66-1.09-7.05-1.09C49.94 352-.33 402.48 0 464.62.14 490.88 21.73 512 48 512h224c26.27 0 47.86-21.12 48-47.38.33-62.14-49.94-112.62-112-112.62zm-48-32c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zM592 0H208c-26.47 0-48 22.25-48 49.59V96c23.42 0 45.1 6.78 64 17.8V64h352v288h-64v-64H384v64h-76.24c19.1 16.69 33.12 38.73 39.69 64H592c26.47 0 48-22.25 48-49.59V49.59C640 22.25 618.47 0 592 0z"
    ></path>
  ),
});

const UserInfo = ({ isInstructor, userName, fullName }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const { instance } = useMsal();

  return (
    <Container>
      {isInstructor !== undefined && (
        <>
          <IconBtn onClick={(e) => setAnchorEl(e.currentTarget)}>
            {isInstructor ? <InstructorIcon /> : <PersonIcon />}
          </IconBtn>
          <Popover
            open={anchorEl !== null}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }}>
              <InfoBox>
                <div>
                  <b>Role:</b> {isInstructor ? "Instructor" : "Student"}
                </div>
                <div>
                  <b>User Name:</b> {userName}
                </div>
                <div>
                  <b>Full Name:</b> {fullName}
                </div>
                <Button
                  variant="contained"
                  onClick={() =>
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "https://www.nait.ca",
                    })
                  }
                >
                  Log out
                </Button>
              </InfoBox>
            </Typography>
          </Popover>
        </>
      )}
    </Container>
  );
};

export default UserInfo;

const Container = styled.div`
  width: 2rem;
  cursor: pointer;
  & > svg {
    font-size: 2rem;
  }
`;
const InfoBox = styled.div`
  width: 300px;
  & button {
    margin-top: 0.5em;
    text-align: right;
  }
`;
const IconBtn = styled(IconButton)`
  border: 1px solid white !important;
  color: inherit !important;
  & svg {
    color: white;
  }
`;
