import { Grid } from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/personalClient/personalClientAccounts/personalChequingFieldDefinitions";
import { PersonalChequing } from "types";
import AccountDisplayTemplate from "pages/clientPortfolio/personalClient/personalClientAccounts/components/AccountDisplayTemplate";
export type Props = {
  account: PersonalChequing;
  onEditClick(): void;
  onDeleteClick(): void;
  onAccountHistoryClick(): void;
  isReadOnlyView: boolean;
};

const PersonalChequingDisplay = ({
  account,
  onEditClick,
  onDeleteClick,
  onAccountHistoryClick,
  isReadOnlyView,
}: Props) => {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`PERSONAL_CHEQUING_${account.accountId}_header`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      onAccountHistoryClick={onAccountHistoryClick}
      accountType="Chequing"
      accountNumber={account.accountNumber || ""}
      type={
        account.overdraftProtection
          ? `ODP - ${getMaskedMoneyValue(account.overdraftLimit ?? 0)}`
          : ""
      }
      balance={account.balance || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldDisplay<PersonalChequing>
            field={fields.personalChequingAccounNumber}
            {...commonProps}
          />
          <FieldDisplay<PersonalChequing>
            field={fields.personalChequingBalance}
            {...commonProps}
          />

          <FieldDisplay<PersonalChequing>
            field={fields.personalChequingAvailableFunds}
            {...commonProps}
          />
          <FieldDisplay<PersonalChequing>
            field={fields.personalChequingOverdraftProtection}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<PersonalChequing>
            field={fields.personalChequingOpenDate}
            {...commonProps}
          />
          <FieldDisplay<PersonalChequing>
            field={fields.personalChequingCloseDate}
            {...commonProps}
          />
          <FieldDisplay<PersonalChequing>
            field={fields.personalChequingODPLimit}
            {...commonProps}
          />
        </Grid>
      </Grid>
    </AccountDisplayTemplate>
  );
};

export default PersonalChequingDisplay;
