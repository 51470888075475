import FieldEditor from "components/FieldEditor";
import { FieldError, MutualFunds } from "types";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessMutualFundsFieldDefinitions";
import { useEffect, useState } from "react";
import {
  requiredDateField,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import AccountEditTemplate from "pages/clientPortfolio/businessClient/businessClientAccounts/components/AccountEditTemplate";
import { openConfirm } from "components/confirmBox";
export type Props = {
  account: MutualFunds;
  isSaving: boolean;
  onCancel(): void;
  onSave(account: MutualFunds): void;
};

function MutualFundsForm({ account, onCancel, onSave, isSaving }: Props) {
  const [state, setState] = useState<MutualFunds>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: MutualFunds) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredDateField(fields.mutualFundsOpenDate, state),
      ...requiredTextField(fields.mutualFundsPreAuthPaymentAmount, state),
      ...requiredTextField(fields.mutualFundsBalance, state),
      ...requiredTextField(fields.mutualFundsType, state),
      ...requiredPickListField(
        fields.mutualFundsPreAuthPaymentFrequency,
        state
      ),
      ...requiredPickListField(fields.mutualFundsInvestmentCategory, state),
    ];
    if (
      state.closeDate &&
      state.openDate &&
      state.closeDate.toLowerCase() !== "invalid date" &&
      new Date(state.closeDate) < new Date(state.openDate)
    ) {
      let invalidCloseDateErr: FieldError = {
        id: `${fields.mutualFundsCloseDate.id}`,
        label: `${fields.mutualFundsCloseDate.label}`,
        error: "Close Date should be after the Open Date",
      };
      errorsArray.push(invalidCloseDateErr);
    }
    setErrors(errorsArray);
    return errorsArray;
  }

  function onSaveClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (
        state.closeDate &&
        state.closeDate.toLowerCase() !== "invalid date" &&
        state.balance &&
        parseFloat(state.balance.toString()) !== parseFloat("0")
      ) {
        openConfirm({
          title: "Error",
          description: `Account Balance should be $0.00 to close the account`,
          isAlert: true,
        });
      } else {
        onSave({
          ...state,
          type: "Non-RSP",
          isAccountClosed:
            state.closeDate && state.closeDate.toLowerCase() !== "invalid date"
              ? true
              : false,
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`MUTUAL_FUNDS_${account.accountId}_form`}
      onSaveClick={onSaveClick}
      onCancelClick={onCancel}
      isSaving={isSaving}
      type="Mutual Funds"
      accountNumber={account.accountNumber || ""}
      balance={account.balance || 0}
      column1Fields={
        <>
          <FieldEditor<MutualFunds>
            field={fields.mutualFundsOpenDate}
            {...commonProps}
          />
          <FieldEditor<MutualFunds>
            field={fields.mutualFundsAccountNumber}
            {...commonProps}
          />

          <FieldEditor<MutualFunds>
            field={fields.mutualFundsBalance}
            {...commonProps}
          />

          <FieldEditor<MutualFunds>
            field={fields.mutualFundsType}
            {...commonProps}
          />

          <FieldEditor<MutualFunds>
            field={fields.mutualFundsInvestmentCategory}
            {...commonProps}
          />
        </>
      }
      column2Fields={
        <>
          <FieldEditor<MutualFunds>
            field={fields.mutualFundsCloseDate}
            {...commonProps}
          />
          <FieldEditor<MutualFunds>
            field={fields.mutualFundsAutoDeposit}
            {...commonProps}
          />
          <FieldEditor<MutualFunds>
            field={fields.mutualFundsPreAuthPaymentFrequency}
            {...commonProps}
          />
          <FieldEditor<MutualFunds>
            field={fields.mutualFundsPreAuthPaymentAmount}
            {...commonProps}
          />
        </>
      }
    />
  );
}

export default MutualFundsForm;
