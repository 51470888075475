import { Typography } from "@mui/material";
import SearchTextField from "components/searchTextField/SearchTextField";
import React, { useState } from "react";
import styled from "styled-components";
import { Employer } from "types";
import EmployerList from "pages/clientPortfolio/personalClient/personalClientInformation/components/EmployerList";

export type EmployerSearchListProps = {
  employers: Employer[];
  onEmployerSelected?(e: Employer): void;
};

const EmployerSearchList = (props: EmployerSearchListProps) => {
  type ExistingEmployersState = {
    searchText: string;
  };
  const [
    existingEmployersState,
    setExistingEmployersState,
  ] = useState<ExistingEmployersState>({ searchText: "" });

  const filteredList = props.employers.filter(
    (e) =>
      e.employerName
        ?.toLowerCase()
        .includes(existingEmployersState.searchText.toLowerCase()) ||
      e.employerId
        ?.toLowerCase()
        .includes(existingEmployersState.searchText.toLowerCase()) ||
      e.phoneNumber
        ?.toLowerCase()
        .includes(existingEmployersState.searchText.toLowerCase())
  );

  return (
    <SectionContainer>
      <Typography variant={"h6"}>Existing Employers</Typography>
      <div style={{ padding: "1em 0.25em" }}>
        <SearchTextField
          value={existingEmployersState.searchText}
          label="Search employer Id, name or phone number"
          onChange={(text) =>
            setExistingEmployersState({
              ...existingEmployersState,
              searchText: text,
            })
          }
        />
      </div>
      <ListHeader>
        <HeaderItem width={"small"}>Emp. Id</HeaderItem>
        <HeaderItem width={"wide"}>Employer Name</HeaderItem>
        <HeaderItem width={"med"}>Phone Number</HeaderItem>
      </ListHeader>
      <EmployerList
        employers={filteredList}
        onEmployerSelected={props.onEmployerSelected}
      />
    </SectionContainer>
  );
};

export default EmployerSearchList;

const SectionContainer = styled.div`
  margin-top: 2em;
  min-height: 600px;
`;

const ListHeader = styled.div`
  font-weight: bold;
  display: flex;
  padding-top: 10px;
  padding-left: 30px;
`;

const HeaderItem = styled.div<{ width: "small" | "med" | "wide" }>`
  width: ${(p) =>
    p.width === "med" ? "125px" : p.width === "wide" ? "160px" : "100px"};
  padding: 0px 10px;
  word-wrap: break-word;
`;
