import {  
  FIELD_TYPE,  
  MASK_TYPE,
  PickListField,
  TextFieldType,
  Transfer,
} from "types";

export const transferFrom: PickListField<Transfer> = {
  id: "transferFrom",
  label: "From",
  isRequired: true,
  type: FIELD_TYPE.ACCOUNTSPICKLIST,
  getValue: (transaction: Transfer) =>  transaction.from,
  setValue: (value, transaction) => ({...transaction,from: value }),
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};


export const transferTo: PickListField<Transfer> = {
  id: "transferTo",
  label: "To",
  isRequired: true,
  type: FIELD_TYPE.ACCOUNTSPICKLIST,
  getValue: (transaction: Transfer) =>  transaction.to,
  setValue: (value, transaction) => ({...transaction,to: value }),
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};



export const transferAmount: TextFieldType<Transfer> = {
  id: "transferAmount",
  label: "Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: Transfer) => transaction.amount ? transaction.amount.toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    amount: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
};