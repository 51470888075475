import CashInputControl from "components/cashInputControl/CashInputControl";
import styled from "styled-components";
import { CashDrawer } from "types";

type CommonProps = {
  state: CashDrawer;
  setState(state: CashDrawer): void;
};

export default function CashSection(
  props: CommonProps & { isReadOnlyView: boolean }
) {
  let cash = props.state.cash;

  const isDependentlyDisabled =
    props.state.overrideStatus?.toLowerCase() === "under review" ||
    props.isReadOnlyView;
  return (
    <FlexDiv>
      <Column>
        <CashInputControl
          billType="CAD100Dollar"
          initialQuantity={props.state.cash?.dollar100Bills ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, dollar100Bills: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
        <CashInputControl
          billType="CAD50Dollar"
          initialQuantity={cash?.dollar50Bills ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, dollar50Bills: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
        <CashInputControl
          billType="CAD20Dollar"
          initialQuantity={cash?.dollar20Bills ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, dollar20Bills: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
        <CashInputControl
          billType="CAD10Dollar"
          initialQuantity={cash?.dollar10Bills ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, dollar10Bills: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
        <CashInputControl
          billType="CAD5Dollar"
          initialQuantity={cash?.dollar5Bills ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, dollar5Bills: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
      </Column>
      <Column>
        <CashInputControl
          billType="CAD2Coin"
          initialQuantity={cash?.dollar2Coins ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, dollar2Coins: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
        <CashInputControl
          billType="CAD1Coin"
          initialQuantity={cash?.dollar1Coins ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, dollar1Coins: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
        <CashInputControl
          billType="CAD25Coin"
          initialQuantity={cash?.cent25Coins ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, cent25Coins: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
        <CashInputControl
          billType="CAD10Coin"
          initialQuantity={cash?.cent10Coins ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, cent10Coins: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
        <CashInputControl
          billType="CAD5Coin"
          initialQuantity={cash?.cent5Coins ?? 0}
          onValueChange={(value) => {
            props.setState({
              ...props.state,
              cash: { ...props.state.cash, cent5Coins: value },
            });
          }}
          isDependentlyDisabled={isDependentlyDisabled}
        />
      </Column>
    </FlexDiv>
  );
}

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Column = styled.div`
  min-width: 250px;
  margin: 10px;
`;
