import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import FieldDisplay from "components/FieldDisplay";
import styled from "styled-components";
import { BusinessClient } from "types";
import * as fields from "pages/clientPortfolio/businessClient/businessClientFieldDefinitions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DisplayFieldTemplate from "components/fields/DisplayFieldTemplate";
import { useReduxState } from "store";
import { getPersonalClientName } from "common/functions";

export default function InformationSection(props: { client: BusinessClient }) {
  const commonProps = {
    state: props.client,
  };
  const { getLessonNameAndData } = useReduxState((e) => e.lesson);
  let pClients = getLessonNameAndData.data?.lessonData.personalClients ?? [];

  let signingAuthoritiesNames: string =
    props.client.signingAuthorities &&
    props.client.signingAuthorities.length > 0
      ? props.client.signingAuthorities
          .map((sAuth) =>
            getPersonalClientName(
              pClients.find(
                (pClient) =>
                  pClient.identification?.clientId === sAuth.personalClientId
              ) ?? {}
            )
          )
          .join(", ")
          .replace("New Client", "Not Set")
      : "";

  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="informationSection-header"
        >
          <Typography variant={"h6"}>Client Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldDisplay<BusinessClient>
                field={fields.clientId}
                {...commonProps}
              />
              <FieldDisplay<BusinessClient>
                field={fields.bussinessType}
                {...commonProps}
              />
              <FieldDisplay<BusinessClient>
                field={fields.legalBusinessName}
                {...commonProps}
              />
              <FieldDisplay<BusinessClient>
                field={fields.operatingAs}
                {...commonProps}
              />
              <DisplayFieldTemplate label="Signing Authorities">
                {signingAuthoritiesNames}
              </DisplayFieldTemplate>
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldDisplay<BusinessClient>
                field={fields.accountType}
                {...commonProps}
              />
              <FieldDisplay<BusinessClient>
                field={fields.dateEstablished}
                {...commonProps}
              />
              <FieldDisplay<BusinessClient>
                field={fields.comment}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  margin-top: 2em;
`;
