import MainTemplate from "components/mainTemplate/MainTemplate";
import { getGeneralLedger, getLessonClientList } from "pages/lessons/helpers";
import { useEffect, useState } from "react";
import { useActions, useReduxState } from "store";
import styled from "styled-components";
import {
  BusinessClient,
  CashDrawer,
  EndOfDayTransactionListItem,
  GeneralLedger,
  PersonalClient,
} from "types";
import Transactions from "pages/cashDrawer/components/TransactionsSummary";
import EndofDayBalancing from "pages/cashDrawer/components/EndofDayBalancing";
import { Divider } from "@mui/material";
import {
  checkIfUnauthorized,
  getBusinessClientOperatingAs,
  getClientFirstNameLastName,
  isPersonalClient,
} from "common/functions";
import { openSnackbar } from "components/snackbar";
import { useHistory } from "react-router-dom";
import { UnauthorizedRoute } from "components/paths";

export type Props = {
  lessonId: string;
  studentId?: string;
};

export default function CashDrawerPage(props: Props) {
  const { getLessonNameAndData, getDataforViewAsStudent } = useReduxState(
    (e) => e.lesson
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [clients, setClients] = useState<(PersonalClient | BusinessClient)[]>(
    getLessonClientList(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const lessonActions = useActions().lesson;
  const glActions = useActions().generalLedger;
  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }
  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            setClients(getLessonClientList(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
        setClients(getLessonClientList(getLessonNameAndData.data.lessonData));
        setIsLoading(false);
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            setClients(getLessonClientList(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
        setClients(
          getLessonClientList(getDataforViewAsStudent.data.lessonData)
        );
        setIsLoading(false);
      }
    }
  }, []);

  let endOfDayTxnIds = glState.cashDrawer?.endOfDayTransactionIds;

  function getClientTxnListItems() {
    let clientTxnListItems: EndOfDayTransactionListItem[] = [];

    for (let i = 0; i < clients.length; i++) {
      let txnSessions = clients[i].transactionSessions ?? [];
      for (let j = 0; j < txnSessions.length; j++) {
        let ts = txnSessions[j];
        let sessionTxns = ts.transactions ?? [];
        for (let k = 0; k < sessionTxns.length; k++) {
          if (endOfDayTxnIds?.includes(sessionTxns[k].transactionId)) {
            clientTxnListItems.push({
              clientName: isPersonalClient(clients[i])
                ? getClientFirstNameLastName(clients[i] as PersonalClient)
                : getBusinessClientOperatingAs(clients[i] as BusinessClient),
              transaction: sessionTxns[k],
            });
          }
        }
      }
    }

    return clientTxnListItems;
  }

  function getGLTxnListItems() {
    let glTxnListItems: EndOfDayTransactionListItem[] = [];

    let glTxns = glState.glTransactions ?? [];

    for (let i = 0; i < glTxns.length; i++) {
      if (endOfDayTxnIds?.includes(glTxns[i].transactionId)) {
        glTxnListItems.push({
          clientName: "General Ledger",
          transaction: glTxns[i],
        });
      }
    }

    return glTxnListItems;
  }

  let sortedendOfDayTxnList: EndOfDayTransactionListItem[] = [
    ...getClientTxnListItems(),
    ...getGLTxnListItems(),
  ].sort(
    (a, b) =>
      new Date(b.transaction.transactionDateTime ?? 0).getTime() -
      new Date(a.transaction.transactionDateTime ?? 0).getTime()
  );

  function submitEndOfDay(updatedCashDrawer: CashDrawer) {
    setIsLoading(true);
    let updatedGL = { ...glState, cashDrawer: updatedCashDrawer };
    glActions.saveGeneralLedger({
      generalLedger: updatedGL,
      lessonId: (props.lessonId as unknown) as number,
      onComplete: (retValue) => {
        setIsLoading(false);
        if (retValue.success) {
          lessonActions.saveGeneralLedger(updatedGL);
          setGLState(updatedGL);

          openSnackbar({
            severity: "success",
            message: `End of Day submitted successfully for review`,
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error submitting End of Day. Please try again!`,
          });
        }
      },
    });
  }

  function submitSupervisorOverride(updatedCashDrawer: CashDrawer) {
    setIsLoading(true);
    let updatedGL = { ...glState, cashDrawer: updatedCashDrawer };
    glActions.saveGeneralLedger({
      generalLedger: updatedGL,
      lessonId: (props.lessonId as unknown) as number,
      onComplete: (retValue) => {
        setIsLoading(false);
        if (retValue.success) {
          lessonActions.saveGeneralLedger(updatedGL);
          setGLState(updatedGL);

          openSnackbar({
            severity: "success",
            message:
              updatedGL.cashDrawer.overrideStatus?.toLowerCase() ===
              "not submitted"
                ? `End of Day approved successfully`
                : `End of Day rejected successfully`,
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error submitting supervisor override. Please try again!`,
          });
        }
      },
    });
  }
  const isReadOnlyStudentAccess =
    getLessonNameAndData.data?.isReadOnly ?? false;
  return (
    <MainTemplate
      title="Cash Drawer"
      isLoading={isLoading}
      studentId={props.studentId}
      cashDrawerBalance={glState.cashDrawer?.balance}
    >
      <Content>
        <EndofDayBalancing
          cashDrawer={glState.cashDrawer ?? {}}
          studentId={props.studentId}
          isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
          onEndOfDay={(updatedCashDrawer) => {
            submitEndOfDay(updatedCashDrawer);
          }}
          onSupervisorOverrideSubmit={(updatedCashDrawer) => {
            submitSupervisorOverride(updatedCashDrawer);
          }}
        />
        <Divider style={{ marginTop: "16px" }} />
        <Transactions transactionsList={sortedendOfDayTxnList} />
      </Content>
    </MainTemplate>
  );
}

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 2em auto;
  min-width: 1000px;
`;
