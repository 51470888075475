import { Paper } from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import styled from "styled-components";
import { GeneralLedger } from "types";

export type Props = {
  generalLedger: GeneralLedger;
};

export default function GLInfoSection(props: Props) {
  return (
    <>
      <ColumnHeading>Account Information</ColumnHeading>
      <ColumnContent>
        <TwoColumnDisplay
          label="Treasury"
          balance={
            props.generalLedger.glAccounts?.find(
              (gla) => gla.glAccountType === "TREASURY"
            )?.balance ?? 0
          }
        />
        <TwoColumnDisplay
          label="CSR Outage"
          balance={
            props.generalLedger.glAccounts?.find(
              (gla) => gla.glAccountType === "CSR_OUTAGE"
            )?.balance ?? 0
          }
        />
        <TwoColumnDisplay
          label="Service Charges"
          balance={
            props.generalLedger.glAccounts?.find(
              (gla) => gla.glAccountType === "SERVICE_CHARGES"
            )?.balance ?? 0
          }
        />
        <TwoColumnDisplay
          label="Drafts"
          balance={
            props.generalLedger.glAccounts?.find(
              (gla) => gla.glAccountType === "DRAFTS"
            )?.balance ?? 0
          }
        />
        <TwoColumnDisplay
          label="Client Transfer"
          balance={
            props.generalLedger.glAccounts?.find(
              (gla) => gla.glAccountType === "CLIENT_TRANSFER"
            )?.balance ?? 0
          }
        />
      </ColumnContent>
      <ColumnHeading>Cash Drawer</ColumnHeading>
      <ColumnContent>
        <TwoColumnDisplay
          label="Balance"
          balance={props.generalLedger.cashDrawer?.balance ?? 0}
        />
      </ColumnContent>
    </>
  );
}

function TwoColumnDisplay(props: { label: string; balance: number }) {
  return (
    <AccountRow>
      <Label>{props.label}</Label>
      <Balance balance={props.balance}>
        {" "}
        {getMaskedMoneyValue(props.balance)}
      </Balance>
    </AccountRow>
  );
}

const ColumnHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const ColumnContent = styled(Paper)`
  padding: 0.5em;
`;

const Label = styled.div`
  font-weight: 500;
  width: 100px;
  word-wrap: break-word;
  font-size: 14px;
  padding-bottom: 6px;
  padding-right: 15px;
  flex: 1;
`;

const AccountRow = styled.div`
  display: flex;
  justify-content: right;
  padding: 6px 3px 0px 3px;
`;

const Balance = styled.div<{ balance: number }>`
  width: 100px;
  font-size: 14px;
  text-align: right;
  color: ${(p) => (p.balance < 0 ? "#ee1d1d;" : "#000000de;")};
`;
