import {
  getMaskedMoneyValue,
  getPersonalGICTypeLabel,
  getPersonalMutualFundsTypeLabel,
  getSavingsTypeLabel,
} from "common/functions";
import MaskedValue from "common/MaskedValue";
import React from "react";
import styled from "styled-components";
import {
  Account,
  GIC,
  MASK_TYPE,
  MutualFunds,
  PersonalChequing,
  PersonalSavings,
} from "types";

export type Props = {
  accounts: Account[];
};

function AssetsDisplay(props: Props) {
  const savingsAccounts: PersonalSavings[] = props.accounts.filter(
    (a) => a.accountType === "PERSONAL_SAVINGS"
  );

  const chequingAccounts: PersonalChequing[] = props.accounts.filter(
    (a) => a.accountType === "PERSONAL_CHEQUING"
  );

  const mutualfundsAccounts: MutualFunds[] = props.accounts.filter(
    (a) => a.accountType === "MUTUAL_FUNDS"
  );

  const gicAccounts: GIC[] = props.accounts.filter(
    (a) => a.accountType === "GIC"
  );

  return (
    <>
      {chequingAccounts.length > 0 && (
        <AccountContainer>
          <LightBold>Chequing</LightBold>{" "}
          <div>
            {chequingAccounts.map((a, index) => {
              return (
                <AccountRowDisplay
                  key={index}
                  number={a.accountNumber || ""}
                  type={
                    a.overdraftProtection
                      ? `ODP - ${getMaskedMoneyValue(a.overdraftLimit ?? 0)}`
                      : ""
                  }
                  balance={a.balance?.toString() || "0"}
                />
              );
            })}
          </div>
        </AccountContainer>
      )}
      {savingsAccounts.length > 0 && (
        <AccountContainer>
          <LightBold>Savings</LightBold>
          <div>
            {savingsAccounts.map((a, index) => {
              return (
                <AccountRowDisplay
                  key={index}
                  number={a.accountNumber || ""}
                  type={getSavingsTypeLabel(a.type ?? "")}
                  balance={a.balance?.toString() || "0"}
                />
              );
            })}
          </div>
        </AccountContainer>
      )}
      {mutualfundsAccounts.length > 0 && (
        <AccountContainer>
          <LightBold>Mutual Funds</LightBold>
          <div>
            {mutualfundsAccounts.map((a, index) => {
              return (
                <AccountRowDisplay
                  key={index}
                  number={a.accountNumber || ""}
                  type={getPersonalMutualFundsTypeLabel(a.type ?? "")}
                  balance={a.balance?.toString() || "0"}
                />
              );
            })}
          </div>
        </AccountContainer>
      )}
      {gicAccounts.length > 0 && (
        <AccountContainer>
          <LightBold>Guaranteed Investment Certificate (GIC)</LightBold>
          <div>
            {gicAccounts.map((a, index) => {
              return (
                <AccountRowDisplay
                  key={index}
                  number={a.accountNumber || ""}
                  type={getPersonalGICTypeLabel(a.type ?? "")}
                  balance={a.balance?.toString() || "0"}
                />
              );
            })}
          </div>
        </AccountContainer>
      )}
    </>
  );
}

export default AssetsDisplay;

function AccountRowDisplay(props: {
  number: string;
  type?: string;
  balance: string;
}) {
  return (
    <AccountRow>
      <NumberContainer>{props.number}</NumberContainer>
      <TypeContainer>{props.type}</TypeContainer>
      <BalanceContainer>
        {MaskedValue(MASK_TYPE.MONEY, props.balance)}
      </BalanceContainer>
    </AccountRow>
  );
}

const LightBold = styled.div`
  font-weight: 500;
  width: 200px;
  word-wrap: break-word;
  padding-bottom: 6px;
`;

const AccountContainer = styled.div`
  padding-bottom: 1.5em;
`;

const AccountRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
`;
const NumberContainer = styled.div`
  width: 200px;
`;

const BalanceContainer = styled.div`
  width: 100px;
  text-align: right;
`;

const TypeContainer = styled.div`
  width: 150px;
  color: #aaa;
`;
