import { useState } from "react";
import { PayoutCash } from "types";
import styled from "styled-components";
import { Button, Divider } from "@mui/material";
import PayoutCashSection from "pages/transactions/components/CashSection";
import * as fields from "pages/transactions/payoutCashFieldDefinitions";
import FieldEditor from "components/FieldEditor";
import PayoutCashTotal from "pages/transactions/components/PayoutCashTotal";
import { getMaskedMoneyValue } from "common/functions";
import ForeignExchangeCurrencyConverter from "pages/transactions/components/ForeignExchangeCurrencyConverter";
export type Props = {
  transaction: PayoutCash;
  onSaveClick(transaction: PayoutCash): void;
};

function PayoutCashForm({ transaction, onSaveClick }: Props) {
  const [state, setState] = useState<PayoutCash>(transaction);
  const [openConverterDialog, setOpenConverterDialog] = useState<boolean>(
    false
  );

  const commonProps = {
    state: state,
    errors: [],
    setState: (state: PayoutCash) => {
      setState(state);
    },
  };

  return (
    <div style={{ padding: "10px" }}>
      <FlexDiv>
        <TransactionHeading>Payout</TransactionHeading>
        <Button
          onClick={() => {
            setOpenConverterDialog(true);
          }}
        >
          Converter
        </Button>
      </FlexDiv>
      <PayoutCashSection {...commonProps} />
      <CheckboxConatiner>
        <FieldEditor<PayoutCash> field={fields.utr} {...commonProps} />
        {/* <Spacer />
        <FieldEditor<PayoutCash> field={fields.lctr} {...commonProps} /> */}
      </CheckboxConatiner>
      <Divider />
      <PayoutCashTotal transaction={commonProps.state} />
      <Divider />
      <TotalPayoutSection
        transaction={state}
        onSaveClick={(updatedTransaction) => {
          onSaveClick(updatedTransaction);
        }}
      />
      {openConverterDialog && (
        <ForeignExchangeCurrencyConverter
          onClose={() => {
            setOpenConverterDialog(false);
          }}
        />
      )}
    </div>
  );
}

export default PayoutCashForm;

function TotalPayoutSection(props: Props) {
  const cashCADValue =
    ((fields.cashCADValue.getValue(props.transaction) as unknown) as number) ??
    0;

  const updatedPayoutCashTransaction: PayoutCash = {
    ...props.transaction,
    cash: {
      ...props.transaction.cash,
      cashCADValue: cashCADValue,
      totalCashAmount: (fields.totalCashAmount.getValue(
        props.transaction
      ) as unknown) as number,
    },

    amount: cashCADValue,
  };

  return (
    <TotalSection>
      <LeftSection>
        <LightBold>Total Payout Cash</LightBold>
        {`${getMaskedMoneyValue(cashCADValue ?? 0)} CAD`}
      </LeftSection>
      <RightSection>
        <Button
          disabled={cashCADValue <= 0}
          onClick={() => props.onSaveClick(updatedPayoutCashTransaction)}
        >
          Save
        </Button>
      </RightSection>
    </TotalSection>
  );
}

const TransactionHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
`;
const RightSection = styled.div`
  text-align: right;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxConatiner = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1em;
  justify-content: right;
`;

const LightBold = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-right: 20px;
`;
const TotalSection = styled.div`
  margin: 1em;
  display: flex;
  align-items: center;
`;
