import { Divider } from "@mui/material";
import { GLTransaction } from "types";
import SummaryRowTemplate from "pages/cashDrawer/components/SummaryRowTemplate";
import styled from "styled-components";
import {
  getGLTransactionFromToLabel,
  getMaskedMoneyValue,
} from "common/functions";
import moment from "moment";

type SummaryRowGLTransactionProps = {
  transaction: GLTransaction;
  clientName: string;
};

export default function SummaryRowGLTransaction(
  props: SummaryRowGLTransactionProps
) {
  const fromLabel = getGLTransactionFromToLabel(props.transaction.from ?? "");
  const toLabel = getGLTransactionFromToLabel(props.transaction.to ?? "");

  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);
  return (
    <SummaryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      client="General Ledger"
      transaction="Cash Transfer"
      cashIn={props.transaction.to === "cashdrawer" ? transactionAmount : ""}
      cashOut={props.transaction.from === "cashdrawer" ? transactionAmount : ""}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection>From</TextSection>
        <TextSection> To</TextSection>
        <TextSection>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>{fromLabel}</TextSection>
        <TextSection>{toLabel}</TextSection>
        <TextSection>{`${transactionAmount}`}</TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
  text-align: center;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;
