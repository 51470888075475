import styled from "styled-components";
import { FieldError, PersonalClient } from "types";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/clientPortfolio/personalClient/personalClientInvesting/investingFieldDefinitions";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};
export default function InvestmentSection(props: CommonProps) {
  return (
    <SectionContainer>
      <Column>
        <FieldEditor<PersonalClient> field={fields.accountType} {...props} />

        <FieldEditor<PersonalClient>
          field={fields.investmentKnowledge}
          {...props}
        />
      </Column>
      <Column>
        <FieldEditor<PersonalClient> field={fields.initialDeposit} {...props} />

        <FieldEditor<PersonalClient>
          field={fields.investmentObjective}
          {...props}
        />
      </Column>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Column = styled.div`
  min-width: 250px;
  margin: 10px;
`;
