import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import styled from "styled-components";
import { GLEntry } from "types";

export type Props = {
  transaction: GLEntry;
};

export default function GLEntryDetails(props: Props) {
  return (
    <div>
      <Table>
        <Header />
        <TableBody>
          <Row transaction={props.transaction} />
        </TableBody>
      </Table>
    </div>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell>Transaction</Cell>
        <Cell>From</Cell>
        <Cell>To</Cell>
        <Cell>Amount</Cell>
        <Cell>Reason</Cell>
        <Cell>Comment</Cell>
      </TableRow>
    </TableHead>
  );
}

function Row(props: { transaction: GLEntry }) {
  return (
    <TableRow>
      <Cell>GL Entry</Cell>
      <Cell>{props.transaction.from}</Cell>
      <Cell>{props.transaction.to}</Cell>
      <Cell>{`${getMaskedMoneyValue(props.transaction.amount ?? 0)} CAD`}</Cell>
      <Cell>{props.transaction.reason}</Cell>

      <Cell>{props.transaction.comment}</Cell>
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  padding: 10px 16px 10px 16px !important;
  text-align: center !important;
`;
