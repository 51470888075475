import NumberFormat from "react-number-format";
import { MASK_TYPE } from "types";

export default function MaskedValue(
  mask: MASK_TYPE | undefined,
  value: string
) {
  if (!mask) {
    return value;
  }
  if (!value) {
    return value;
  }
  if (mask === MASK_TYPE.SOCIAL_SECURITY_NUMBER) {
    return SINFormat(value);
  }
  if (mask === MASK_TYPE.PHONE_NUMBER) {
    return PhoneFormat(value);
  }

  if (mask === MASK_TYPE.MONEY) {
    return MoneyFormat(value);
  }

  if (mask === MASK_TYPE.CREDIT_CARD_NUMBER) {
    return CreditCardNumberFormat(value);
  }

  if (mask === MASK_TYPE.PERCENTAGE) {
    return PercentageFormat(value);
  }

  return undefined;
}

const SINFormat = (value: string) => {
  return <NumberFormat value={value} displayType="text" format="### ### ###" />;
};

const PhoneFormat = (value: string) => {
  return (
    <NumberFormat value={value} displayType="text" format="(###) ###-####" />
  );
};

const CreditCardNumberFormat = (value: string) => {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      format="#### #### #### ####"
    />
  );
};

const MoneyFormat = (value: string) => {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      prefix="$"
      thousandSeparator={true}
      allowNegative={true}
      decimalSeparator="."
      thousandsGroupStyle="thousand"
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};
const PercentageFormat = (value: string) => {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      suffix="%"
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};
