import { BoolField, DisplayFieldProps, Entity } from "types";
import DisplayFieldTemplate from "./DisplayFieldTemplate";
import NotSetDisplay from "./NotSetDisplay";
export default function CheckboxDisplay<T extends Entity>({
  field,
  state,
}: DisplayFieldProps<T>) {
  const checkboxField = field as BoolField<T>;

  const valueLabel = checkboxField.getValue(state) ? "Yes" : "No";

  return (
    <DisplayFieldTemplate label={checkboxField.label}>
      {valueLabel || <NotSetDisplay />}
    </DisplayFieldTemplate>
  );
}
