import { DisplayFieldProps, Entity, RadioGroupField } from "types";
import DisplayFieldTemplate from "./DisplayFieldTemplate";
import NotSetDisplay from "./NotSetDisplay";
export default function RadioGroupDisplay<T extends Entity>({
  field,
  state,
}: DisplayFieldProps<T>) {
  const radioButton = field as RadioGroupField<T>;

  return (
    <DisplayFieldTemplate label={radioButton.label}>
      {radioButton.getValue(state) || <NotSetDisplay />}
    </DisplayFieldTemplate>
  );
}
