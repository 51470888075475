import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";

export type Props = {
  onToggle(selectedButton: number): void;
  isDisabled?: boolean;
};

export default function CurrencyToggleMenu(props: Props) {
  const [selectedButton, setSelectedButton] = useState<number>(0);

  return (
    <ToggleButtonGroup
      value={selectedButton}
      color="primary"
      exclusive
      style={{
        background: "white",
        borderRadius: "15px",
        margin: "2em 0 1em 0",
      }}
    >
      <ToggleButton
        value={0}
        onClick={() => {
          setSelectedButton(0);
          props.onToggle(0);
        }}
        size="small"
        style={{ borderRadius: "15px 0px 0px 15px" }}
      >
        CAD
      </ToggleButton>

      <ToggleButton
        onClick={() => {
          setSelectedButton(1);
          props.onToggle(1);
        }}
        disabled={props.isDisabled}
        value={1}
        size="small"
        style={{ borderRadius: "0px 15px 15px 0px" }}
      >
        USD
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
