import styled from "styled-components";
import { Account } from "types";
import AssetsSection from "components/clientInfoTemplate/AssetsSection";
import { assetsAccountsTypes, liabilitiesAccountsTypes } from "common/helpers";
import { add } from "common/functions";
import LiabilitiesSection from "components/clientInfoTemplate/LiabilitiesSection";
import NetWorthSection from "components/clientInfoTemplate/NetWorthSection";
import { Button } from "@mui/material";
import {
  BusinessClientAccountsRoute,
  PersonalClientAccountsRoute,
} from "components/paths";

export default function AccountInfoSection(props: {
  activeAccounts: Account[];
  clientType: string;
  clientId: string;
  studentId?: string;
  lessonId: string;
}) {
  const assetsAccounts = props.activeAccounts?.filter(
    (e) =>
      assetsAccountsTypes.includes(e.accountType) &&
      (!e.closeDate || e.closeDate.toLowerCase() === "invalid date")
  );

  const liabilitiesAccounts = props.activeAccounts?.filter(
    (e) =>
      liabilitiesAccountsTypes.includes(e.accountType) &&
      (!e.closeDate || e.closeDate.toLowerCase() === "invalid date")
  );

  const liabilitiesBalances: number[] = liabilitiesAccounts
    ? liabilitiesAccounts.map((a) => {
        if (a.balance === undefined) return 0;
        else return a.balance;
      })
    : [];
  let liabilitiesTotal: number = liabilitiesBalances.reduce(add, 0);

  const assetsBalances: number[] = assetsAccounts
    ? assetsAccounts.map((a) => {
        if (a.balance === undefined) return 0;
        else return a.balance;
      })
    : [];
  let assetsTotal: number = assetsBalances.reduce(add, 0);
  return (
    <div>
      {props.activeAccounts.length === 0 && (
        <div style={{ padding: "1em" }}>No Accounts Information Available</div>
      )}
      {props.activeAccounts.length > 0 && (
        <>
          <AssetsSection
            assetsAccounts={assetsAccounts ?? []}
            assetsTotal={assetsTotal}
          />
          <LiabilitiesSection
            liabilitiesAccounts={liabilitiesAccounts ?? []}
            liabilitiesTotal={liabilitiesTotal}
          />
          <NetWorthSection netWorth={assetsTotal - liabilitiesTotal} />
          <DetailedInfo>
            <Button
              onClick={() => {
                props.clientType === "Personal"
                  ? !!props.studentId
                    ? window.open(
                        `/${props.lessonId}${PersonalClientAccountsRoute}/${props.clientId}/${props.studentId}`
                      )
                    : window.open(
                        `/${props.lessonId}${PersonalClientAccountsRoute}/${props.clientId}`
                      )
                  : !!props.studentId
                  ? window.open(
                      `/${props.lessonId}${BusinessClientAccountsRoute}/${props.clientId}/${props.studentId}`
                    )
                  : window.open(
                      `/${props.lessonId}${BusinessClientAccountsRoute}/${props.clientId}`
                    );
              }}
            >
              Detailed Information
            </Button>
          </DetailedInfo>
        </>
      )}
    </div>
  );
}

const DetailedInfo = styled.div`
  text-align: right;
  margin-top: 1.5em;
`;
