import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import FieldEditor from "components/FieldEditor";
import { FieldError, PersonalClient } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/creditAppCommentsFieldDefinitions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};

const CommentsSection = (commonProps: CommonProps) => {
  let comments = commonProps.state.comments ?? {};
  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="identificationSection-header"
        >
          <Typography variant={"h6"}>Comments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} md={12}>
            <FieldEditor<PersonalClient>
              field={fields.characterCheckbox}
              {...commonProps}
            />
            {comments.characterCheckbox && (
              <FieldEditor<PersonalClient>
                field={fields.characterComment}
                {...commonProps}
              />
            )}
            <FieldEditor<PersonalClient>
              field={fields.capitalCheckbox}
              {...commonProps}
            />
            {comments.capitalCheckbox && (
              <FieldEditor<PersonalClient>
                field={fields.capitalComment}
                {...commonProps}
              />
            )}
            <FieldEditor<PersonalClient>
              field={fields.capacityCheckbox}
              {...commonProps}
            />
            {comments.capacityCheckbox && (
              <FieldEditor<PersonalClient>
                field={fields.capacityComment}
                {...commonProps}
              />
            )}
            <FieldEditor<PersonalClient>
              field={fields.creditHistoryCheckbox}
              {...commonProps}
            />
            {comments.creditHistoryCheckbox && (
              <FieldEditor<PersonalClient>
                field={fields.creditHistoryComment}
                {...commonProps}
              />
            )}
            <FieldEditor<PersonalClient>
              field={fields.collateralCheckbox}
              {...commonProps}
            />
            {comments.collateralCheckbox && (
              <FieldEditor<PersonalClient>
                field={fields.collateralComment}
                {...commonProps}
              />
            )}
            <div>Submission : </div>
            <FieldEditor<PersonalClient>
              field={fields.submissionComment}
              {...commonProps}
            />

            <div>
              By entering the client's name below, customer gives authorization
              to complete the credit application:
            </div>
            <AuthorizationContainer>
              <FieldEditor<PersonalClient>
                field={fields.customerAuthorizationName}
                {...commonProps}
              />
            </AuthorizationContainer>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  margin-top: 2em;
`;

const AuthorizationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;
export default CommentsSection;
