import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";

export type Props = {
  onToggle(selectedButton: number): void;
};

const ToggleMenu = (props: Props) => {
  const [selectedButton, setSelectedButton] = useState<number>(0);

  return (
    <ToggleButtonGroup
      value={selectedButton}
      color="primary"
      exclusive
      size="small"
      style={{
        background: "white",
        borderRadius: "10px",
      }}
    >
      <ToggleButton
        value={0}
        onClick={() => {
          setSelectedButton(0);
          props.onToggle(0);
        }}
        style={{ borderRadius: "10px 0px 0px 10px" }}
      >
        Summary
      </ToggleButton>

      <ToggleButton
        onClick={() => {
          setSelectedButton(1);
          props.onToggle(1);
        }}
        value={1}
        style={{ borderRadius: "0px 10px 10px 0px" }}
      >
        History
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleMenu;
