import styled from "styled-components";
import { FieldError, PersonalClient } from "types";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/clientPortfolio/personalClient/personalClientInvesting/investingFieldDefinitions";
import { ReactNode } from "react";
import RangeOutcomeChart from "./RangeOutcomeChart";
import PortfolioExamplesChart from "./PortfolioExamplesChart";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};
export default function RiskAttitudeSection(props: CommonProps) {
  return (
    <>
      <QuestionRow
        question="In making financial and investment decisions you are:"
        field={
          <FieldEditor<PersonalClient> field={fields.personType} {...props} />
        }
      />
      <QuestionRow
        question="The value of an investment portfolio will generally go up and down over time.  Assuming that you have invested $10,000, how much of a decline in your investment portfolio could you tolerate in a 12 month period?"
        field={
          <FieldEditor<PersonalClient>
            field={fields.tolerateDecline}
            {...props}
          />
        }
      />
      <QuestionRow
        question="When you are faced with a major financial decision, are you more concerned about the possible losses or the possible gains?"
        field={
          <FieldEditor<PersonalClient>
            field={fields.concernedForLossesGains}
            {...props}
          />
        }
      />
      <QuestionRow
        question="The chart (hover on the 'i' icon) shows the greatest one year loss and the highest one year gain on four different investments of $10,000.  Given the potential gain or loss in any one year, which investment would you likely invest your money in:"
        chartIcon={<RangeOutcomeChart />}
        field={
          <FieldEditor<PersonalClient>
            field={fields.investmentExample}
            {...props}
          />
        }
      />
      <QuestionRow
        question="From September 2008 through November 2008, North American stock markets lost over 30%.  If you currently owned an investment that lost over 30% in 3 months you would:"
        field={
          <FieldEditor<PersonalClient>
            field={fields.lossInInvestmentAction}
            {...props}
          />
        }
      />
      <QuestionRow
        question="Investments with higher returns typically involve greater risk.  The charts (hover on the 'i' icon) show hypothetical annual returns (annual gains and losses) for four different investment portfolios over a 10 year period.  Keeping in mind how the returns fluctuate, which investment portfolio would you be most comfortable holding?"
        chartIcon={<PortfolioExamplesChart />}
        field={
          <FieldEditor<PersonalClient>
            field={fields.investmentPortfolioExample}
            {...props}
          />
        }
      />
    </>
  );
}

function QuestionRow(props: {
  question: string;
  field: ReactNode;
  chartIcon?: JSX.Element;
}) {
  return (
    <QuestionRowContainer>
      <QuestionDiv>
        {props.question} {props.chartIcon}
      </QuestionDiv>
      <FieldDiv>{props.field}</FieldDiv>
    </QuestionRowContainer>
  );
}

const QuestionRowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionDiv = styled.div`
  width: 625px;
  margin: 0px 10px;
  align-items: center;
`;

const FieldDiv = styled.div`
  width: 250px;
  margin: 0px 10px;
  align-items: center;
`;
