import styled from "styled-components";
import { ReactNode } from "react";
export default function DisplayFieldTemplate({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) {
  return (
    <Container>
      <Label>{label}</Label>
      <Value>{children}</Value>
    </Container>
  );
}

const Label = styled.label`
  color: #0000008a;
  font-size: 14px;
`;
const Value = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Container = styled.div`
  margin-bottom: 20px;
  min-width: 250px;
`;
