import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CustomAssetFieldDefinitions";
import { useEffect, useState } from "react";
import { CustomAsset, FieldError } from "types";

type Props = {
  customAsset: CustomAsset;
  onAddorUpdateClick(updatedCustomAsset: CustomAsset): void;
  onCancel(): void;
};

export default function CustomAssetEditor({
  customAsset,
  onAddorUpdateClick,
  onCancel,
}: Props) {
  const [state, setState] = useState<CustomAsset>(customAsset);
  const [errors, setErrors] = useState<FieldError[]>([]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: CustomAsset) => {
      setState(state);
    },
  };

  function checkNewAssetErrors() {
    const assetErrorsArray: FieldError[] = [
      ...requiredTextField(fields.name, state),
      ...requiredTextField(fields.balance, state),
    ];
    setErrors(assetErrorsArray);
    return assetErrorsArray;
  }

  useEffect(() => {
    errors.length > 0 && checkNewAssetErrors();
  }, [state]);

  return (
    <Dialog maxWidth={"md"} open>
      <DialogTitle>
        {!!customAsset.name ? "Edit Asset" : "Add Asset"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FieldEditor<CustomAsset> field={fields.name} {...commonProps} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldEditor<CustomAsset> field={fields.balance} {...commonProps} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            const newAssetErrors = checkNewAssetErrors();
            if (newAssetErrors.length === 0) {
              onAddorUpdateClick({ ...state } || {});
            }
          }}
        >
          {!!customAsset.name ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
