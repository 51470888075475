import { useEffect, useState } from "react";
import { Receive, FieldError, MASK_TYPE } from "types";
import styled from "styled-components";
import { Button, Divider } from "@mui/material";
import ReceiveCashSection from "pages/transactions/components/CashSection";
import ReceiveChequeSection from "pages/transactions/components/ReceiveChequeSection";
import MaskedValue from "common/MaskedValue";
import * as fields from "pages/transactions/recieveFieldDefinitions";
import { add, requiredTextField } from "common/functions";
import ReceiveCashTotal from "pages/transactions/components/ReceiveCashTotal";
import ForeignExchangeCurrencyConverter from "pages/transactions/components/ForeignExchangeCurrencyConverter";
import { useReduxState } from "store";
import FieldEditor from "components/FieldEditor";
export type Props = {
  transaction: Receive;
  onSaveClick(transaction: Receive): void;
};

function ReceiveForm({ transaction, onSaveClick }: Props) {
  const [state, setState] = useState<Receive>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [openConverterDialog, setOpenConverterDialog] = useState<boolean>(
    false
  );
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: Receive) => {
      setState(state);
    },
  };

  function saveReceive(transaction: Receive) {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      onSaveClick(transaction);
    }
  }

  function checkErrors() {
    let totalChequeAmount = state.cheque?.chequesArray?.reduce(add, 0) ?? 0;

    let isHoldRequired =
      (totalChequeAmount ?? 0) >=
      (globalSettings.chequeWarningMessageDenomination ?? 0);

    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.chequeHoldDuration, state),
      ...requiredTextField(fields.chequeHoldReason, state),
      ...requiredTextField(fields.chequeHoldAmount, state, isHoldRequired),
    ];

    let holdAmount = state.cheque?.holdAmount ?? 0;
    if (totalChequeAmount < holdAmount) {
      let err: FieldError = {
        id: fields.chequeHoldAmount.id,
        label: fields.chequeHoldAmount.label,
        error: "Should be less than Total Cheque Amount ",
      };
      errorsArray.push(err);
    }
    setErrors(errorsArray);
    return errorsArray;
  }

  return (
    <div style={{ padding: "10px" }}>
      <FlexDiv>
        <TransactionHeading>Receive</TransactionHeading>
        <Button
          onClick={() => {
            setOpenConverterDialog(true);
          }}
        >
          Converter
        </Button>
      </FlexDiv>
      <ReceiveCashSection {...commonProps} />
      <CheckboxConatiner>
        <FieldEditor<Receive> field={fields.utr} {...commonProps} />
        <Spacer />
        <FieldEditor<Receive> field={fields.lctr} {...commonProps} />
      </CheckboxConatiner>
      <Divider style={{ marginTop: "16px" }} />
      <ReceiveCashTotal transaction={commonProps.state} />
      <Divider />
      <ReceiveChequeSection {...commonProps} />
      <Divider />
      <TotalReceiveAmount
        transaction={state}
        onSaveClick={(updatedTransaction) => {
          saveReceive(updatedTransaction);
        }}
      />
      {openConverterDialog && (
        <ForeignExchangeCurrencyConverter
          onClose={() => {
            setOpenConverterDialog(false);
          }}
        />
      )}
    </div>
  );
}

export default ReceiveForm;

function TotalReceiveAmount(props: Props) {
  const cashCADValue =
    ((fields.cashCADValue.getValue(props.transaction) as unknown) as number) ??
    0;
  const chequeCADValue =
    ((fields.chequeCADValue.getValue(
      props.transaction
    ) as unknown) as number) ?? 0;

  const totalReceivedAmount = add(cashCADValue, chequeCADValue).toFixed(2);
  const updatedReceiveTransaction: Receive = {
    ...props.transaction,
    cash: {
      ...props.transaction.cash,
      cashCADValue: cashCADValue,
      totalCashAmount: (fields.totalCashAmount.getValue(
        props.transaction
      ) as unknown) as number,
    },
    cheque: {
      ...props.transaction.cheque,
      chequeCADValue: chequeCADValue,
      totalChequeAmount: (fields.totalChequeAmount.getValue(
        props.transaction
      ) as unknown) as number,
    },
    amount: (totalReceivedAmount as unknown) as number,
  };

  return (
    <TotalSection>
      <LeftSection>
        <LightBold>Total Amount Received</LightBold>
        <div>
          {MaskedValue(MASK_TYPE.MONEY, totalReceivedAmount ?? "0")}
          {" CAD"}
        </div>
      </LeftSection>
      <RightSection>
        <Button
          disabled={((totalReceivedAmount as unknown) as number) <= 0}
          onClick={() => {
            props.onSaveClick(updatedReceiveTransaction);
          }}
        >
          Save
        </Button>
      </RightSection>
    </TotalSection>
  );
}

const TransactionHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
`;
const RightSection = styled.div`
  text-align: right;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const LightBold = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-right: 20px;
`;
const TotalSection = styled.div`
  margin: 1em;
  display: flex;
  align-items: center;
`;

const CheckboxConatiner = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1em;
  justify-content: right;
`;
const Spacer = styled.div`
  margin: 0 1em;
`;
