import { Receive } from "types";
import SummaryRowReceiveCash from "pages/cashDrawer/components/SummaryRowReceiveCash";

type SummaryRowReceiveProps = {
  clientName: string;

  transaction: Receive;
};

export default function SummaryRowReceive(props: SummaryRowReceiveProps) {
  return (
    <>
      {!!props.transaction.cash?.cashCADValue &&
        props.transaction.cash?.cashCADValue > 0 && (
          <SummaryRowReceiveCash
            clientName={props.clientName}
            cash={props.transaction.cash ?? {}}
            lctr={props.transaction.lctr}
            utr={props.transaction.utr}
            transactionDateTime={props.transaction.transactionDateTime ?? ""}
          />
        )}
    </>
  );
}
