import { useEffect, useState } from "react";
import { FieldError, GLEntry, IdName } from "types";
import styled from "styled-components";
import { Button } from "@mui/material";
import * as fields from "pages/transactions/glEntryFieldDefinitions";
import { requiredPickListField, requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";
import { useHistory } from "react-router-dom";
import { GeneralLedgerRoute } from "components/paths";
import { useReduxState } from "store";
export type Props = {
  transaction: GLEntry;
  glEntryClientAccountOptions: IdName[];
  onSaveClick(transaction: GLEntry): void;
};

export default function GLEntryForm({
  transaction,
  glEntryClientAccountOptions,
  onSaveClick,
}: Props) {
  const [state, setState] = useState<GLEntry>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);

  const { lessonId } = useReduxState((e) => e.lesson);
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};

  const history = useHistory();

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: GLEntry) => {
      setState(state);
    },
  };

  function saveGLEntry(transaction: GLEntry) {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      onSaveClick(transaction);
    }
  }

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.glAmount, state),
      ...requiredPickListField(fields.glType, state),
      ...requiredPickListField(fields.glAccount, state),
      ...requiredPickListField(fields.clientAccount, state),
      ...requiredPickListField(fields.reason, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  return (
    <div style={{ padding: "10px" }}>
      <FlexDiv>
        <TransactionHeading>General Ledger Entry</TransactionHeading>
        <Button
          onClick={() => {
            history.push(`/${lessonId}${GeneralLedgerRoute}`);
          }}
        >
          History
        </Button>
      </FlexDiv>
      <Container>
        <Column>
          <FieldEditor<GLEntry> field={fields.glType} {...commonProps} />
          <FieldEditor<GLEntry> field={fields.glAccount} {...commonProps} />
          <FieldEditor<GLEntry>
            field={fields.clientAccount}
            {...commonProps}
            accountOptions={glEntryClientAccountOptions}
          />
          <FieldEditor<GLEntry> field={fields.glAmount} {...commonProps} />
        </Column>
        <Column>
          <FieldEditor<GLEntry> field={fields.reason} {...commonProps} />
          <FieldEditor<GLEntry> field={fields.comment} {...commonProps} />
        </Column>
      </Container>
      <SaveButtonConatiner>
        <Button
          disabled={!(state.amount && state.amount > 0)}
          onClick={() => {
            const fromLabel =
              state.glType === "debit"
                ? `GL Account - ${
                    fields.glAccount.getOptionLabel
                      ? fields.glAccount.getOptionLabel(
                          state.glAccount ?? "",
                          fields.glAccount.options ?? []
                        )
                      : ""
                  }`
                : fields.clientAccount.getOptionLabel
                ? fields.clientAccount.getOptionLabel(
                    state.clientAccount ?? "",
                    glEntryClientAccountOptions
                  )
                : "";
            const toLabel =
              state.glType === "debit"
                ? fields.clientAccount.getOptionLabel
                  ? fields.clientAccount.getOptionLabel(
                      state.clientAccount ?? "",
                      glEntryClientAccountOptions
                    )
                  : ""
                : `GL Account - ${
                    fields.glAccount.getOptionLabel
                      ? fields.glAccount.getOptionLabel(
                          state.glAccount ?? "",
                          fields.glAccount.options ?? []
                        )
                      : ""
                  }`;
            const reasonLabel = fields.reason.getOptionLabel
              ? fields.reason.getOptionLabel(
                  state.reason ?? "",
                  fields.reason.options ?? []
                )
              : "";

            const updatedGLEntryTransaction: GLEntry = {
              ...state,
              from: fromLabel,
              to: toLabel,
              reason: reasonLabel,
              isApprovalPending:
                state.amount ??
                0 >= (globalSettings.generalLedgerOverrideDenomination ?? 0)
                  ? true
                  : false,
            };
            saveGLEntry(updatedGLEntryTransaction);
          }}
        >
          Save
        </Button>
      </SaveButtonConatiner>
    </div>
  );
}

const TransactionHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const Column = styled.div`
  width: 250px;
  padding: 0 10px;
`;
const Container = styled.div`
  display: flex;
  margin: 1em 0.75em 0 0.5em;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SaveButtonConatiner = styled.div`
  text-align: right;
  padding: 1em;
`;
