import { useMsal } from "@azure/msal-react";

export type LoginInfo = {
  userName: string;
  fullName: string;
  isInstructor: boolean;
};

type IdTokenClaims = undefined | { groups?: string[] };

export const getLoginInfo = (): LoginInfo | undefined => {
  const { accounts } = useMsal();
  const account = accounts[0];

  return account
    ? {
        userName: account.username,
        fullName: account.name ?? "Unknown",
        isInstructor:
          (account.idTokenClaims as IdTokenClaims)?.groups?.includes(
            process.env.REACT_APP_INSTRUCTOR_GROUP_ID ?? ""
          ) ?? false,
      }
    : undefined;
};
