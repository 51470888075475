import { Button, Divider, Paper } from "@mui/material";
import {
  checkIfUnauthorized,
  getPersonalClientName,
  requiredDateField,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import ClientPortfolioTemplate from "components/clientPortfolioTemplate/ClientPortfolioTemplate";
import MainTemplate from "components/mainTemplate/MainTemplate";
import { UnauthorizedRoute } from "components/paths";
import { openSnackbar } from "components/snackbar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useActions, useReduxState } from "store";
import styled from "styled-components";
import { FieldError, GeneralLedger, Investing, PersonalClient } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientInvesting/investingFieldDefinitions";
import { getPersonalClientById, getGeneralLedger } from "pages/lessons/helpers";
import AppBarButtons from "./components/AppBarButtons";
import GoalsSection from "./components/GoalsSection";
import InvestmentProfileSection from "./components/InvestmentProfileSection";
import { INVESTING_PROFILES } from "common/helpers";
import RiskResultSection from "./components/RiskResultSection";
import { getLoginInfo } from "common/azureAD";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { openConfirm } from "components/confirmBox";

export type Props = {
  clientId: string;
  lessonId: string;
  studentId?: string;
};

export default function PersonalClientInvesting(props: Props) {
  const {
    getLessonNameAndData,
    getDataforViewAsStudent,
    lessonId,
  } = useReduxState((e) => e.lesson);
  const lessonActions = useActions().lesson;
  const clientInfoActions = useActions().personalClientInformation;
  const [state, setState] = useState<PersonalClient>(
    getPersonalClientById(
      props.clientId,
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );

  const commonProps = {
    state: state,
    errors,
    setState: (state: PersonalClient) => {
      setState(state);
    },
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showRiskResult, setShowRiskResult] = useState(
    state.investing?.isSaveProfileAttempted
  );

  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }
  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            if (props.clientId) {
              let client: PersonalClient = getPersonalClientById(
                props.clientId,
                payload.lessonData
              );

              if (!!client.identification?.clientId) {
                setState(
                  client.investing?.estimateBy === undefined
                    ? {
                        ...client,
                        investing: { ...client.investing, estimateBy: "Date" },
                      }
                    : client
                );
                setShowRiskResult(client.investing?.isSaveProfileAttempted);
                setGLState(getGeneralLedger(payload.lessonData));
              } else history.push(UnauthorizedRoute);
            }
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          let client: PersonalClient = getPersonalClientById(
            props.clientId,
            getLessonNameAndData.data.lessonData
          );
          setState(
            client.investing?.estimateBy === undefined
              ? {
                  ...client,
                  investing: { ...client.investing, estimateBy: "Date" },
                }
              : client
          );

          setShowRiskResult(client.investing?.isSaveProfileAttempted);
          setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
          setIsLoading(false);
        }
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            if (props.clientId) {
              let client: PersonalClient = getPersonalClientById(
                props.clientId,
                payload.lessonData
              );
              setState(
                client.investing?.estimateBy === undefined
                  ? {
                      ...client,
                      investing: { ...client.investing, estimateBy: "Date" },
                    }
                  : client
              );

              setShowRiskResult(client.investing?.isSaveProfileAttempted);
              setGLState(getGeneralLedger(payload.lessonData));
            }
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          let client: PersonalClient = getPersonalClientById(
            props.clientId,
            getDataforViewAsStudent.data.lessonData
          );
          setState(
            client.investing?.estimateBy === undefined
              ? {
                  ...client,
                  investing: { ...client.investing, estimateBy: "Date" },
                }
              : client
          );
          setShowRiskResult(client.investing?.isSaveProfileAttempted);
          setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
          setIsLoading(false);
        }
      }
    }
  }, []);

  const clientName = getPersonalClientName(state);
  const loginInfo = getLoginInfo();
  var isInstructor = loginInfo?.isInstructor ?? false;

  function checkErrors() {
    if (!state) return;
    const errorsArray: FieldError[] = [
      ...requiredPickListField(fields.overallGoal, state),
      ...requiredTextField(fields.amount, state),
      ...requiredDateField(fields.projectionDate, state),
      ...requiredTextField(fields.savingsPerMonth, state),
      ...requiredPickListField(fields.accountType, state),
      ...requiredPickListField(fields.investmentKnowledge, state),
      ...requiredTextField(fields.initialDeposit, state),
      ...requiredPickListField(fields.investmentObjective, state),
      ...requiredPickListField(fields.timeHorizon, state),
      ...requiredPickListField(fields.annualIncome, state),
      ...requiredPickListField(fields.incomeSourcesStability, state),
      ...requiredPickListField(fields.overallFinancialSituation, state),
      ...requiredPickListField(fields.estimatedNetWorth, state),
      ...requiredPickListField(fields.investmentAccountPercentage, state),
      ...requiredPickListField(fields.ageGroup, state),
      ...requiredPickListField(fields.personType, state),
      ...requiredPickListField(fields.tolerateDecline, state),
      ...requiredPickListField(fields.concernedForLossesGains, state),
      ...requiredPickListField(fields.investmentExample, state),
      ...requiredPickListField(fields.lossInInvestmentAction, state),
      ...requiredPickListField(fields.investmentPortfolioExample, state),
      ...requiredTextField(fields.clientAuthorizationName, state),
    ];

    //Invalid projection date check: following code checks for invalid projection date. Adds error if date is in past

    if (
      state.investing?.projectionDate &&
      state.investing.projectionDate.toLowerCase() !== "invalid date" &&
      new Date(state.investing.projectionDate).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0)
    ) {
      let invalidDateErr: FieldError = {
        id: `projectionDate`,
        label: "Projection Date",
        error: "Invalid Date. Projection date cannot be in past.",
      };
      errorsArray.push(invalidDateErr);
    }

    setErrors(errorsArray);
    return errorsArray;
  }

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  function getTimeHorizonProfile(investing: Investing) {
    let timeHorizon = investing?.investmentProfile?.timeHorizon;
    if (timeHorizon === "lessthan1year") return INVESTING_PROFILES.SAFETY;
    else if (timeHorizon === "1-3years")
      return INVESTING_PROFILES.CONSERVATIVE_INCOME;
    else if (timeHorizon === "4-6years" || timeHorizon === "7-9years")
      return INVESTING_PROFILES.GROWTH;
    else if (timeHorizon === "10yearsOrMore")
      return INVESTING_PROFILES.AGGRESSIVE_GROWTH;
    else return INVESTING_PROFILES.UNKNOWN;
  }

  function getInvestmentKnowledgeProfile(investing: Investing) {
    let investmentKnowledge = investing?.investmentProfile?.investmentKnowledge;
    if (investmentKnowledge === "verylittle")
      return INVESTING_PROFILES.BALANCED;
    else if (investmentKnowledge === "moderate")
      return INVESTING_PROFILES.GROWTH;
    else if (investmentKnowledge === "extensive")
      return INVESTING_PROFILES.AGGRESSIVE_GROWTH;
    else return INVESTING_PROFILES.UNKNOWN;
  }

  function getInvestmentObjectiveProfile(investing: Investing) {
    let investmentObjective = investing?.investmentProfile?.investmentObjective;
    if (investmentObjective === "safety") return INVESTING_PROFILES.SAFETY;
    else if (investmentObjective === "income")
      return INVESTING_PROFILES.CONSERVATIVE_INCOME;
    else if (investmentObjective === "balanced")
      return INVESTING_PROFILES.BALANCED;
    else if (investmentObjective === "growth")
      return INVESTING_PROFILES.AGGRESSIVE_GROWTH;
    else return INVESTING_PROFILES.UNKNOWN;
  }

  function getRiskCapacityProfile(investing: Investing) {
    let investmentProfile = investing?.investmentProfile;
    let annualIncomeScore: number = parseInt(
      investmentProfile?.annualIncome ?? "0"
    );
    let incomeSourcesStabilityScore: number = parseInt(
      investmentProfile?.incomeSourcesStability ?? "0"
    );
    let overallFinancialSituationScore: number = parseInt(
      investmentProfile?.overallFinancialSituation ?? "0"
    );
    let estimatedNetWorthScore: number = parseInt(
      investmentProfile?.estimatedNetWorth ?? "0"
    );
    let investmentAccountPercentageScore: number = parseInt(
      investmentProfile?.investmentAccountPercentage ?? "0"
    );
    let ageGroupScore: number = parseInt(investmentProfile?.ageGroup ?? "0");

    let riskCapacityScore: number =
      annualIncomeScore +
      incomeSourcesStabilityScore +
      overallFinancialSituationScore +
      estimatedNetWorthScore +
      investmentAccountPercentageScore +
      ageGroupScore;

    if (riskCapacityScore < 15) {
      return INVESTING_PROFILES.CONSERVATIVE_INCOME;
    } else if (riskCapacityScore >= 15 && riskCapacityScore <= 25) {
      return INVESTING_PROFILES.BALANCED;
    } else if (riskCapacityScore >= 26 && riskCapacityScore <= 40) {
      return INVESTING_PROFILES.GROWTH;
    } else if (riskCapacityScore > 40) {
      return INVESTING_PROFILES.AGGRESSIVE_GROWTH;
    } else {
      return INVESTING_PROFILES.UNKNOWN;
    }
  }

  function getRiskAttitudeProfile(investing: Investing) {
    let investmentProfile = investing?.investmentProfile;
    let personTypeScore: number = parseInt(
      investmentProfile?.personType ?? "0"
    );
    let tolerateDeclineScore: number = parseInt(
      investmentProfile?.tolerateDecline ?? "0"
    );
    let concernedForLossesGainsScore: number = parseInt(
      investmentProfile?.concernedForLossesGains ?? "0"
    );
    let investmentExampleScore: number = parseInt(
      investmentProfile?.investmentExample ?? "0"
    );
    let lossInInvestmentActionScore: number = parseInt(
      investmentProfile?.lossInInvestmentAction ?? "0"
    );
    let investmentPortfolioExampleScore: number = parseInt(
      investmentProfile?.investmentPortfolioExample ?? "0"
    );

    let riskAttitudeScore: number =
      personTypeScore +
      tolerateDeclineScore +
      concernedForLossesGainsScore +
      investmentExampleScore +
      lossInInvestmentActionScore +
      investmentPortfolioExampleScore;

    if (riskAttitudeScore < 20) {
      return INVESTING_PROFILES.SAFETY;
    } else if (riskAttitudeScore >= 20 && riskAttitudeScore <= 24) {
      return INVESTING_PROFILES.CONSERVATIVE_INCOME;
    } else if (riskAttitudeScore >= 25 && riskAttitudeScore <= 30) {
      return INVESTING_PROFILES.BALANCED;
    } else if (riskAttitudeScore >= 31 && riskAttitudeScore <= 45) {
      return INVESTING_PROFILES.GROWTH;
    } else if (riskAttitudeScore > 45) {
      return INVESTING_PROFILES.AGGRESSIVE_GROWTH;
    } else {
      return INVESTING_PROFILES.UNKNOWN;
    }
  }

  function getOverallProfile(client: PersonalClient) {
    let investing = client?.investing ?? {};

    let timeHorizonProfile = getTimeHorizonProfile(investing);
    let investmentKnowledgeProfile = getInvestmentKnowledgeProfile(investing);
    let investmentObjectiveProfile = getInvestmentObjectiveProfile(investing);
    let riskCapacityProfile = getRiskCapacityProfile(investing);
    let riskAttitudeProfile = getRiskAttitudeProfile(investing);

    let allProfileArr = [
      timeHorizonProfile,
      investmentKnowledgeProfile,
      investmentObjectiveProfile,
      riskCapacityProfile,
      riskAttitudeProfile,
    ];

    if (allProfileArr.includes(INVESTING_PROFILES.SAFETY)) {
      return INVESTING_PROFILES.SAFETY;
    } else if (allProfileArr.includes(INVESTING_PROFILES.CONSERVATIVE_INCOME)) {
      return INVESTING_PROFILES.CONSERVATIVE_INCOME;
    } else if (allProfileArr.includes(INVESTING_PROFILES.BALANCED)) {
      return INVESTING_PROFILES.BALANCED;
    } else if (allProfileArr.includes(INVESTING_PROFILES.GROWTH)) {
      return INVESTING_PROFILES.GROWTH;
    } else if (allProfileArr.includes(INVESTING_PROFILES.AGGRESSIVE_GROWTH)) {
      return INVESTING_PROFILES.AGGRESSIVE_GROWTH;
    } else {
      return INVESTING_PROFILES.UNKNOWN;
    }
  }

  function onSaveClick(client: PersonalClient) {
    const errArray = checkErrors();
    if (errArray?.length === 0) {
      setIsSaving(true);
      let overallProfile = getOverallProfile(client);
      let updatedClient: PersonalClient = {
        ...client,
        investing: {
          ...client.investing,
          overallProfile: overallProfile.toString(),
          isSaveProfileAttempted: true,
          lastCompletedDate: new Date().toISOString(),
        },
      };
      clientInfoActions.savePersonalClient({
        client: updatedClient,
        lessonId,
        onComplete: (payload) => {
          if (!!payload.clientId) {
            openSnackbar({
              severity: "success",
              message: `Personal Client '${clientName}' saved successfully`,
            });
            setShowRiskResult(true);
            setState(updatedClient);

            lessonActions.savePersonalClient(updatedClient);
          } else {
            openSnackbar({
              severity: "error",
              message: `Error saving personal client '${clientName}'. Please refresh the page and try again!`,
            });
          }
          setIsSaving(false);
        },
      });
    }
  }

  function resetInvesting(client: PersonalClient) {
    setIsLoading(true);
    let updatedClient: PersonalClient = {
      ...client,
      investing: undefined,
    };
    setState(updatedClient);
    clientInfoActions.savePersonalClient({
      client: updatedClient,
      lessonId,
      onComplete: (payload) => {
        if (!!payload.clientId) {
          errors.length > 0 && setErrors([]);
          openSnackbar({
            severity: "success",
            message: `Investing Profile for Personal Client '${clientName}' reset successfully`,
          });
          lessonActions.savePersonalClient(updatedClient);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error resetting investing profile for '${clientName}'. Please refresh the page and try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }
  const isReadOnlyStudentAccess =
    getLessonNameAndData.data?.isReadOnly ?? false;

  return (
    <MainTemplate
      title={
        isLoading ? "Client Portfolio" : `Client Portfolio — ${clientName}`
      }
      isLoading={isLoading}
      studentId={props.studentId}
      cashDrawerBalance={glState.cashDrawer?.balance ?? 0}
      appBarRight={
        <AppBarButtons
          onSaveClick={() => onSaveClick(state)}
          isSaving={isSaving}
          isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
        />
      }
    >
      <ClientPortfolioTemplate selectedButton={4} studentId={props.studentId}>
        {!isLoading && (
          <Content>
            {isInstructor && (
              <ResetContainer>
                <Button
                  sx={{
                    marginRight: "2em",
                    marginTop: "1em",
                  }}
                  disabled={isSaving || isLoading || !!props.studentId}
                  onClick={() => {
                    openConfirm({
                      title: "Reset Investing?",
                      description: (
                        <div>
                          Are you sure you want to reset the investing profile
                          for {clientName}? <br /> This action cannot be undone.
                        </div>
                      ),
                      okBtnText: "Reset",
                      callback: () => {
                        resetInvesting(state);
                      },
                    });
                  }}
                >
                  <RotateLeftIcon style={{ marginRight: 5 }} />
                  Reset
                </Button>
              </ResetContainer>
            )}
            <GoalsSection
              {...commonProps}
              state={
                state.investing?.estimateBy === undefined
                  ? {
                      ...state,
                      investing: { ...state.investing, estimateBy: "Date" },
                    }
                  : state
              }
            />
            <Divider style={{ color: "darkgray", margin: "1em 0em" }} />
            <InvestmentProfileSection {...commonProps} />
            {showRiskResult && state.investing?.isSaveProfileAttempted && (
              <Divider style={{ color: "darkgray" }} />
            )}
            {showRiskResult && state.investing?.isSaveProfileAttempted && (
              <RiskResultSection overrallProfile={getOverallProfile(state)} />
            )}
          </Content>
        )}
      </ClientPortfolioTemplate>
    </MainTemplate>
  );
}

const Content = styled(Paper)`
  align-items: center;
  flex-wrap: wrap;

  margin: 2em auto;
  width: 1000px;
`;
const ResetContainer = styled.div`
  text-align: right;
  margin-bottom: -3em;
`;
