import { DateField, DisplayFieldProps, Entity } from "types";
import DisplayFieldTemplate from "./DisplayFieldTemplate";
import NotSetDisplay from "./NotSetDisplay";
export default function DateFieldDisplay<T extends Entity>({
  field,
  state,
}: DisplayFieldProps<T>) {
  const dateField = field as DateField<T>;
  const value =
    dateField.getValue(state) &&
    dateField.getValue(state)?.toLowerCase() !== "invalid date"
      ? dateField.getValue(state)
      : undefined;
  return (
    <DisplayFieldTemplate label={dateField.label}>
      {value || <NotSetDisplay />}
    </DisplayFieldTemplate>
  );
}
