import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";
import {
  add,
  checkIfUnauthorized,
  getBusinessClientOperatingAs,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import * as fields from "pages/clientPortfolio/businessClient/businessClientFieldDefinitions";
import styled from "styled-components";
import {
  AddressType,
  FieldError,
  GeneralLedger,
  BusinessClient,
  PersonalClient,
  SigningAuthority,
} from "types";
import ClientPortfolioTemplate from "components/clientPortfolioTemplate/ClientPortfolioTemplate";
import AppBarButtons from "pages/clientPortfolio/businessClient/businessClientInformation/components/AppBarButtons";
import MainTemplate from "components/mainTemplate/MainTemplate";
import InformationSection from "pages/clientPortfolio/businessClient/businessClientInformation/components/InformationSection";
import { defaultBusinessClient } from "common/helpers";
import { useLocation } from "react-router";
import { openSnackbar } from "components/snackbar";
import {
  getGeneralLedger,
  getBusinessClientById,
  getNewBusinessClientId,
  getPersonalClientList,
} from "pages/lessons/helpers";
import { useHistory } from "react-router-dom";
import {
  BusinessClientInformationRoute,
  PersonalClientSummaryRoute,
  UnauthorizedRoute,
} from "components/paths";
import { openConfirm } from "components/confirmBox";
import isEmail from "validator/lib/isEmail";
import ClientTypeTemplate from "components/clientTypeTemplate/ClientTypeTemplate";
import SigningAuthoritySection from "pages/clientPortfolio/businessClient/businessClientInformation/components/SigningAuthoritySection";

export type Props = {
  clientId?: string;
  lessonId: string;
  studentId?: string;
};

export default function BusinessClientInformation(props: Props) {
  const {
    getLessonNameAndData,
    getDataforViewAsStudent,
    lessonId,
  } = useReduxState((e) => e.lesson);
  const lessonActions = useActions().lesson;
  const clientInfoState = useReduxState((e) => e.businessClientInformation);
  const clientInfoActions = useActions().businessClientInformation;
  const newClientID = getNewBusinessClientId(
    getLessonNameAndData.data?.lessonData
  );

  const [state, setState] = useState<BusinessClient>(
    getBusinessClientById(
      props.clientId,
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewClient, setIsNewClient] = useState<boolean>(false);
  const [personalClientList, setPersonalClientList] = useState<
    PersonalClient[]
  >([]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: BusinessClient) => {
      setState(state);
    },
  };
  const location = useLocation();
  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            if (props.clientId) {
              let client: BusinessClient = getBusinessClientById(
                props.clientId,
                payload.lessonData
              );

              if (!!client.information?.clientId) setState(client);
              else history.push(UnauthorizedRoute);
            } else {
              setIsNewClient(true);
              setState({
                ...defaultBusinessClient,
                information: {
                  ...defaultBusinessClient.information,
                  clientId: newClientID?.toString(),
                  accountType: "Business",
                },
              });
            }
            setPersonalClientList(getPersonalClientList(payload.lessonData));
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getBusinessClientById(
              props.clientId,
              getLessonNameAndData.data.lessonData
            )
          );
          setIsLoading(false);
        } else {
          setIsNewClient(true);
          setState({
            ...defaultBusinessClient,
            information: {
              ...defaultBusinessClient.information,
              clientId: newClientID?.toString(),
              accountType: "Business",
            },
          });
          setIsLoading(false);
        }
        setPersonalClientList(
          getPersonalClientList(getLessonNameAndData.data.lessonData)
        );
        setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            if (props.clientId) {
              setState(
                getBusinessClientById(props.clientId, payload.lessonData)
              );
            }
            setPersonalClientList(getPersonalClientList(payload.lessonData));
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getBusinessClientById(
              props.clientId,
              getDataforViewAsStudent.data.lessonData
            )
          );
          setPersonalClientList(
            getPersonalClientList(getDataforViewAsStudent.data.lessonData)
          );
          setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
          setIsLoading(false);
        }
      }
    }
  }, [location]);

  const operatingAs = getBusinessClientOperatingAs(state);

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredPickListField(fields.bussinessType, state),
      ...requiredTextField(fields.operatingAs, state),
      ...requiredTextField(fields.incorporationRegistrationNumber, state),
      ...requiredTextField(fields.phoneNumber, state),
      ...requiredPickListField(fields.phoneNumberType, state),
      ...requiredTextField(fields.email, state),
    ];
    //following code checks for invalid phone number. Adds error if length is less than 10
    if (
      state.information?.phoneNumber &&
      state.information.phoneNumber.length < 10
    ) {
      let invalidPhoneNumberErr: FieldError = {
        id: `${fields.phoneNumber.id}`,
        label: `${fields.phoneNumber.label}`,
        error: "Invalid Phone Number.",
      };
      errorsArray.push(invalidPhoneNumberErr);
    }
    if (state.information?.email && !isEmail(state.information?.email)) {
      let invalidEmailErr: FieldError = {
        id: `${fields.email.id}`,
        label: `${fields.email.label}`,
        error: "Invalid Email.",
      };
      errorsArray.push(invalidEmailErr);
    }

    setErrors(errorsArray);
    return errorsArray;
  }

  function getTotalOwnership(client: BusinessClient) {
    let totalOwnership = 0;
    if (client.signingAuthorities && client.signingAuthorities.length > 0) {
      let percentOfOwnershipArr = client.signingAuthorities.map(
        (sAuth) => sAuth.percentOfOwnership ?? 0
      );
      totalOwnership = percentOfOwnershipArr.reduce(add, 0);
    }
    return totalOwnership;
  }

  function onSaveClick() {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      if (!state.information?.businessCurrentAddress) {
        openConfirm({
          title: "Error",
          description: `Client must have at least one address`,
          isAlert: true,
        });
      } else if (
        !state.signingAuthorities ||
        state.signingAuthorities?.length === 0
      ) {
        openConfirm({
          title: "Error",
          description: `Client must have at least one signing authority`,
          isAlert: true,
        });
      } else if (getTotalOwnership(state) > 100) {
        openConfirm({
          title: "Error",
          description: `Total ownership for all signing authorities must be less than or equal to 100%`,
          isAlert: true,
        });
      } else {
        clientInfoActions.saveBusinessClient({
          client: state,
          lessonId,
          onComplete: (payload) => {
            if (!!payload.clientId) {
              openSnackbar({
                severity: "success",
                message: `Business Client '${operatingAs}' saved successfully`,
              });
              lessonActions.saveBusinessClient(state);
              setIsNewClient(false);
              history.push(
                `/${lessonId}${BusinessClientInformationRoute}/${payload.clientId}`
              );
            } else {
              openSnackbar({
                severity: "error",
                message: `Error saving business client '${operatingAs}'. Please refresh the page and try again!`,
              });
            }
          },
        });
      }
    }
  }

  function onAddAddress(newAddress: AddressType) {
    const currentAdd = {
      ...state.information?.businessCurrentAddress,
      country: "Canada",
    };

    const isNewAddOlder =
      new Date(newAddress.startDate ?? 0).getTime() <
      new Date(currentAdd.startDate ?? 0).getTime();
    const addHistory = state.information?.businessAddressHistory ?? [];

    const newAddHistory = addHistory
      .concat(newAddress)
      .sort(
        (a, b) =>
          new Date(b.startDate ?? 0).getTime() -
          new Date(a.startDate ?? 0).getTime()
      );

    const updatedClient: BusinessClient = {
      ...state,
      information: {
        ...state.information,
        businessAddressHistory: newAddHistory,
        businessCurrentAddress: isNewAddOlder ? currentAdd : newAddress,
        businessNewAddress: {},
      },
    };

    setState(updatedClient);
  }

  function onEditAddress(oldAddress: AddressType, updatedAddress: AddressType) {
    const newAddressHist = state.information?.businessAddressHistory
      ?.filter((e) => e !== oldAddress)
      .concat(updatedAddress)
      .sort(
        (a, b) =>
          new Date(b.startDate ?? 0).getTime() -
          new Date(a.startDate ?? 0).getTime()
      );
    const updatedClient: BusinessClient = {
      ...state,
      information: {
        ...state.information,
        businessCurrentAddress: newAddressHist && newAddressHist[0],
        businessAddressHistory: newAddressHist,
      },
    };
    setState(updatedClient);
  }

  function onDeleteAddress(address: AddressType) {
    const newAddressHist = state.information?.businessAddressHistory?.filter(
      (e) => e !== address
    );
    const updatedClient: BusinessClient = {
      ...state,
      information: {
        ...state.information,
        businessCurrentAddress: newAddressHist && newAddressHist[0],
        businessAddressHistory: newAddressHist,
      },
    };
    setState(updatedClient);
  }

  function onEditSigningAuthority(updatedSigningAuthority: SigningAuthority) {
    let currentSigningAuthorities = state.signingAuthorities ?? [];
    let isExistingSigningAuthority: boolean = currentSigningAuthorities.some(
      (sAuth) =>
        sAuth.signingAuthorityId === updatedSigningAuthority.signingAuthorityId
    );

    const updatedClient: BusinessClient = {
      ...state,
      signingAuthorities: isExistingSigningAuthority
        ? state.signingAuthorities?.map((sAuth) =>
            sAuth.signingAuthorityId ===
            updatedSigningAuthority.signingAuthorityId
              ? updatedSigningAuthority
              : sAuth
          )
        : [...(state.signingAuthorities ?? []), updatedSigningAuthority],
    };

    setState(updatedClient);
  }

  function onDeleteSigningAuthority(signingAuthority: SigningAuthority) {
    let currentSigningAuthorities = state.signingAuthorities ?? [];
    const updatedClient: BusinessClient = {
      ...state,
      signingAuthorities: currentSigningAuthorities.filter(
        (sAuth) =>
          sAuth.signingAuthorityId !== signingAuthority.signingAuthorityId
      ),
    };
    setState(updatedClient);
  }

  function onSaveInstructorFeedback(feedback?: string) {
    if (!!props.studentId) {
      clientInfoActions.saveInformationInstructorFeedback({
        studentId: props.studentId,
        lessonId,
        clientId: state.information?.clientId ?? "",
        feedback: feedback,
        onComplete: (payload) => {
          if (payload.success) {
            setState({
              ...state,
              informationInstructorFeedback: feedback,
            });
            lessonActions.saveInformationInstFeedbackForStudentBusinessClient({
              clientId: state.information?.clientId ?? "",
              feedback: feedback,
            });

            openSnackbar({
              severity: "success",
              message: `Feedback saved successfully`,
            });
          } else {
            openSnackbar({
              severity: "error",
              message: `Error saving feedback. Please refresh the page and try again!`,
            });
          }
        },
      });
    }
  }

  const isReadOnlyStudentAccess =
    getLessonNameAndData.data?.isReadOnly ?? false;

  return (
    <MainTemplate
      title={
        isLoading ? "Client Portfolio" : `Client Portfolio — ${operatingAs}`
      }
      isLoading={isLoading}
      studentId={props.studentId}
      appBarRight={
        <AppBarButtons
          onSaveClick={onSaveClick}
          isSaving={clientInfoState.saveBusinessClient.isLoading}
          isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
        />
      }
      errorsArray={errors}
      onErrorClick={(fieldID) => {
        document.getElementById(fieldID)?.focus();
      }}
      cashDrawerBalance={glState.cashDrawer?.balance}
    >
      <ClientPortfolioTemplate
        selectedButton={1}
        isNewClient={isNewClient}
        studentId={props.studentId}
        isLoading={isLoading}
        onSaveFeedback={onSaveInstructorFeedback}
      >
        {isNewClient && <ClientTypeTemplate selectedButton={1} />}
        {!isLoading && (
          <Content>
            <Column>
              <InformationSection
                {...commonProps}
                onAddAddress={(newAddress) => {
                  onAddAddress(newAddress);
                }}
                onEditAddress={(oldAddress, updatedAddress) => {
                  onEditAddress(oldAddress, updatedAddress);
                }}
                onDeleteAddress={(address) => {
                  onDeleteAddress(address);
                }}
                isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
              />
            </Column>
            <Column>
              <SigningAuthoritySection
                {...commonProps}
                personalClientList={personalClientList}
                onEditSigningAuthority={(updatedSigningAuthority) => {
                  onEditSigningAuthority(updatedSigningAuthority);
                }}
                onDeleteSigningAuthority={(signingAuthority) => {
                  onDeleteSigningAuthority(signingAuthority);
                }}
                onPortfolioClick={(personalClientId) => {
                  !!props.studentId
                    ? history.push(
                        `/${lessonId}${PersonalClientSummaryRoute}/${personalClientId}/${props.studentId}`
                      )
                    : history.push(
                        `/${lessonId}${PersonalClientSummaryRoute}/${personalClientId}`
                      );
                }}
                isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
                isNewClient={isNewClient}
              />
            </Column>
          </Content>
        )}
        {/* </ClientTypeTemplate> */}
      </ClientPortfolioTemplate>
    </MainTemplate>
  );
}

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Column = styled.div`
  width: 600px;
  margin: 0.5em;
`;
