import { getPersonalClientName } from "common/functions";
import { PersonalClientSummaryRoute } from "components/paths";
import { useHistory } from "react-router-dom";
import { useReduxState } from "store";
import { PersonalClient } from "types";
import ClientRowTemplate from "pages/clientSearch/components/ClientRowTemplate";

export default function PersonalClientRow(props: {
  client: PersonalClient;
  studentId?: string;
  onDeletePersonalCLient(): void;
  isInstructor: boolean;
}) {
  const history = useHistory();

  const { lessonId } = useReduxState((e) => e.lesson);
  let clientId = props.client.identification?.clientId;
  let clientName = getPersonalClientName(props.client);
  return (
    <>
      <ClientRowTemplate
        clientId={clientId ?? ""}
        clientName={clientName}
        accountType={props.client.information?.accountType ?? ""}
        phoneNumber={props.client.information?.phoneNumber ?? ""}
        isInstructor={props.isInstructor}
        onPortfolioClick={() => {
          !!props.studentId
            ? history.push(
                `/${lessonId}${PersonalClientSummaryRoute}/${clientId}/${props.studentId}`
              )
            : history.push(
                `/${lessonId}${PersonalClientSummaryRoute}/${clientId}`
              );
        }}
        onDelete={props.onDeletePersonalCLient}
        itemToDelete="Personal Client"
        studentId={props.studentId}
      />
    </>
  );
}
