import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import {  SectionListItem, StudentListItem } from "types";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "studentsessions";
const baseURL = `${process.env.REACT_APP_API_BASE}/studentsessions`;

type State = {

  getsections: ReducerState<SectionListItem[]>;
  syncStudentList: ReducerState<SyncStudentListReturn>
};


let getsections = createAsyncAction<SectionListItem[], { courseCode: string, termId: string, onComplete?(retValue: SectionListItem[]): void }, State>({
  actionPrefix: controlName,
  actionName: "getsections",
  url: baseURL + "/getsections",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.getsections = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getsections.isLoading = false;
    state.getsections.data = action.payload;
  },
  rejected: (state, action) => {
    state.getsections.isLoading = false;
    state.getsections.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as SectionListItem[]))
},
});

type SyncStudentListReturn={
  success: boolean;
  studentList: StudentListItem[]
}

let syncStudentList = createAsyncAction<SyncStudentListReturn, { id: number, sectionIds: string[], onComplete?(retValue: SyncStudentListReturn): void }, State>({
  actionPrefix: controlName,
  actionName: "syncStudentList",
  url: baseURL + "/syncStudentList",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.syncStudentList = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.syncStudentList.isLoading = false;
    state.syncStudentList.data = action.payload;
  },
  rejected: (state, action) => {
    state.syncStudentList.isLoading = false;
    state.syncStudentList.error = action.payload;
  },
  onComplete: (payload) => {
   
    payload.params.onComplete && 
    payload.params.onComplete((payload.data as SyncStudentListReturn))
},
});

let slice = createSlice({
  name: controlName,
  initialState: {
    getsections: { isLoading: false },
    syncStudentList: {isLoading: false},
  
  } as State,
  reducers: {},
  extraReducers: {
    ...getsections.reducer,
    ...syncStudentList.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getsections: getsections.action,
      syncStudentList: syncStudentList.action,
    },
  },
};

export default ret;
