import { Button } from "@mui/material";
import { openConfirm } from "components/confirmBox";
import {
  BusinessClientTransactionsRoute,
  CashDrawerRoute,
  PersonalClientTransactionsRoute,
} from "components/paths";
import styled from "styled-components";
import { GLEntry, OutstandingOverridesTransaction, SellDraft } from "types";
import EndOfDayDetails from "pages/admin/outstandingOverrides/components/EndOfDayDetails";
import GLEntryDetails from "pages/admin/outstandingOverrides/components/GLEntryDetails";
import SellDraftDetails from "pages/admin/outstandingOverrides/components/SellDraftDetails";

export type Props = {
  overrideTransaction?: OutstandingOverridesTransaction;
  lessonId: string;
  approveOverride(): void;
  rejectOverride(): void;
};

export default function TransactionDetails(props: Props) {
  function onApprove() {
    openConfirm({
      title: "Approve Override?",
      description: `This will approve override for this ${txntypeLabel} transaction. Continue?`,
      okBtnText: "Approve",
      callback: () => {
        props.approveOverride();
      },
    });
  }

  function onReject() {
    openConfirm({
      title: "Reject Override?",
      description: `This will reject override for this ${txntypeLabel} transaction. Continue?`,
      okBtnText: "Reject",
      callback: () => {
        props.rejectOverride();
      },
    });
  }

  if (
    !props.overrideTransaction?.transactionAdditionalInfo &&
    !props.overrideTransaction?.cashDrawerAdditionalInfo
  )
    return <Content></Content>;
  let transactionType = props.overrideTransaction.transactionType;
  let txntypeLabel =
    transactionType === "GL_ENTRY"
      ? "GL Entry"
      : transactionType === "SELL_DRAFT"
      ? "Sell Draft"
      : transactionType === "END_OF_DAY"
      ? "End of Day"
      : "Unknown";
  return (
    <Content>
      <TopSection>
        <NameSection>
          <TwoColumnDisplay
            label="Student Name"
            value={props.overrideTransaction.fullName ?? ""}
          />
          <TwoColumnDisplay
            label="Client Name"
            value={props.overrideTransaction.clientName ?? ""}
          />
          {/* <TwoColumnDisplay
            label="Account Type"
            value={props.overrideTransaction.accountType}
          /> */}
        </NameSection>
        {["GL_ENTRY", "SELL_DRAFT"].includes(
          props.overrideTransaction.transactionType
        ) && (
          <Button
            href={
              props.overrideTransaction.accountType === "Personal"
                ? `/${props.lessonId}${PersonalClientTransactionsRoute}/${props.overrideTransaction.clientId}/${props.overrideTransaction.studentId}`
                : `/${props.lessonId}${BusinessClientTransactionsRoute}/${props.overrideTransaction.clientId}/${props.overrideTransaction.studentId}`
            }
          >
            View Transaction
          </Button>
        )}
        {props.overrideTransaction.transactionType === "END_OF_DAY" && (
          <Button
            href={`/${props.lessonId}${CashDrawerRoute}/${props.overrideTransaction.studentId}`}
          >
            View End of Day
          </Button>
        )}
        <Spacer />

        <Button variant="contained" onClick={onApprove}>
          Approve
        </Button>
        <Spacer />
        <Button variant="contained" onClick={onReject}>
          Reject
        </Button>
      </TopSection>
      {props.overrideTransaction.transactionAdditionalInfo?.transactionType ===
        "GL_ENTRY" && (
        <GLEntryDetails
          transaction={
            props.overrideTransaction.transactionAdditionalInfo as GLEntry
          }
        />
      )}
      {props.overrideTransaction.transactionAdditionalInfo?.transactionType ===
        "SELL_DRAFT" && (
        <SellDraftDetails
          transaction={
            props.overrideTransaction.transactionAdditionalInfo as SellDraft
          }
        />
      )}
      {props.overrideTransaction.transactionType === "END_OF_DAY" && (
        <EndOfDayDetails
          cashDrawer={props.overrideTransaction.cashDrawerAdditionalInfo}
        />
      )}
    </Content>
  );
}

function TwoColumnDisplay(props: { label: string; value: string }) {
  return (
    <RowContainer>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </RowContainer>
  );
}

const Content = styled.div`
  min-height: 300px;
  width: inherit;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 3em 1em;
`;

const Spacer = styled.div`
  margin: 1em;
`;

const Label = styled.label`
  color: #0000008a;
  padding: 0 10px;
  font-size: 16px;
`;
const Value = styled.div`
  font-size: 16px;
  padding: 0 10px 0 5px;
`;
const RowContainer = styled.div`
  display: flex;
  margin-right: 5em;
`;

const NameSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: left;
`;
