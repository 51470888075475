import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { AccountsPickListField, CommonFieldProps, Entity, IdName } from "types";
import { FieldContainer } from "./commonFieldStyles";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 250,
    },
  },
};

export default function AccountsPickListFieldEditor<T extends Entity>(
  props: CommonFieldProps<T> & { accountOptions?: IdName[] }
) {
  const { field, state, setState, errors, accountOptions } = props;
  const errObj = errors.find((e) => e.id === field.id);
  const accountPickListField = field as AccountsPickListField<T>;

  return (
    <FieldContainer>
      <FormControl
        fullWidth
        variant="filled"
        required={
          field.isRequired ||
          (field.checkIfRequired && field.checkIfRequired(state))
        }
        disabled={
          field.disabled ||
          (field.checkIfDisabled && field.checkIfDisabled(state))
        }
        size="small"
      >
        <InputLabel
          id="demo-simple-select-label"
          style={!!errObj ? { color: "#d32f2f" } : {}}
        >
          {field.label}
        </InputLabel>
        <Select
          labelId={`${field.id}_label`}
          id={field.id}
          defaultValue={accountPickListField.defaultValue}
          value={
            accountPickListField.getValue(state) ??
            accountPickListField.defaultValue ??
            ""
          }
          label={field.label}
          fullWidth
          error={!!errObj}
          onChange={(e) =>
            setState(accountPickListField.setValue(e.target.value, state))
          }
          MenuProps={MenuProps}
        >
          {accountOptions?.map((mi, n) => (
            <MenuItem key={`option_${n}`} value={mi.id}>
              {mi.name}
            </MenuItem>
          ))}
        </Select>
        {!!errObj && (
          <FormHelperText style={{ color: "#d32f2f" }}>
            {errObj.error}
          </FormHelperText>
        )}
      </FormControl>
    </FieldContainer>
  );
}
