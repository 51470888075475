import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as fields from "pages/admin/globalSettings/globalSettingsFieldDefinitions";
import { FieldError, GlobalSettings } from "types";
import { requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";
import { useActions, useReduxState } from "store";
import AppBarButtons from "pages/admin/globalSettings/components/AppBarButtons";
import { getLoginInfo } from "common/azureAD";
import { useHistory } from "react-router-dom";
import { UnauthorizedRoute } from "components/paths";
import { openSnackbar } from "components/snackbar";
import AdminTemplate from "components/adminTemplate/AdminTemplate";

export type Props = {};

export default function GlobalSettingsPage(props: Props) {
  const loginInfo = getLoginInfo();
  const history = useHistory();
  if (!loginInfo?.isInstructor) {
    history.push(UnauthorizedRoute);
  }

  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  const [state, setState] = useState<GlobalSettings>({});
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const globalSettingsActions = useActions().globalsettings;
  const commonProps = {
    state: state,
    errors,
    setState: (state: GlobalSettings) => {
      setState(state);
    },
  };

  useEffect(() => {
    if (!getGlobalSettings.data?.globalSettings) {
      setIsLoading(true);
      globalSettingsActions.getGlobalSettings({
        onComplete: (payload) => {
          setState(payload.globalSettings);
          setIsLoading(false);
        },
      });
    } else {
      setState(getGlobalSettings.data?.globalSettings);
    }
  }, []);

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.adminOverrideCode, state),
      ...requiredTextField(fields.primeInterestRate, state),
      ...requiredTextField(fields.usCashBuyRate, state),
      ...requiredTextField(fields.usCashSellRate, state),
      ...requiredTextField(fields.usChequeBuyRate, state),
      ...requiredTextField(fields.usChequeSellRate, state),
      ...requiredTextField(fields.cashDrawerStartingBalance, state),
      ...requiredTextField(fields.cashDrawerHighThreshold, state),
      ...requiredTextField(fields.cashDrawerLowThreshold, state),
      ...requiredTextField(fields.draftOverrideDenomination, state),
      ...requiredTextField(fields.generalLedgerOverrideDenomination, state),
      ...requiredTextField(fields.chequeWarningMessageDenomination, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  function onSaveClick() {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      setIsLoading(true);
      setIsSaving(true);
      globalSettingsActions.saveGlobalSettings({
        globalSettings: state,
        onComplete: (payload) => {
          if (payload.success) {
            globalSettingsActions.updateReduxGlobalSettings(state);
            openSnackbar({
              severity: "success",
              message: `Global Settings Saved successfully`,
            });
            setIsLoading(false);
            setIsSaving(false);
          } else {
            openSnackbar({
              severity: "error",
              message: `Error saving global settings. Please try again!`,
            });
            setIsLoading(false);
            setIsSaving(false);
          }
        },
      });
    }
  }

  return (
    <AdminTemplate
      title="Administration - Global Settings"
      isLoading={isLoading}
      appBarRight={
        <AppBarButtons onSaveClick={onSaveClick} isSaving={isSaving} />
      }
    >
      {getGlobalSettings.data?.globalSettings && (
        <Content>
          <Column>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SectionContainer>
                  <SectionHeading>Admin Override</SectionHeading>
                  <FieldEditor<GlobalSettings>
                    field={fields.adminOverrideCode}
                    {...commonProps}
                  />
                </SectionContainer>
                <SectionContainer>
                  <SectionHeading>Prime Interest Rate</SectionHeading>
                  <FieldEditor<GlobalSettings>
                    field={fields.primeInterestRate}
                    {...commonProps}
                  />
                </SectionContainer>
                <SectionContainer>
                  <SectionHeading>US Cash</SectionHeading>

                  <FieldEditor<GlobalSettings>
                    field={fields.usCashBuyRate}
                    {...commonProps}
                  />
                  <FieldEditor<GlobalSettings>
                    field={fields.usCashSellRate}
                    {...commonProps}
                  />
                </SectionContainer>

                <SectionContainer>
                  <SectionHeading>Cash Drawer</SectionHeading>
                  <FieldEditor<GlobalSettings>
                    field={fields.cashDrawerStartingBalance}
                    {...commonProps}
                  />
                  <FieldEditor<GlobalSettings>
                    field={fields.cashDrawerHighThreshold}
                    {...commonProps}
                  />
                  <FieldEditor<GlobalSettings>
                    field={fields.cashDrawerLowThreshold}
                    {...commonProps}
                  />
                </SectionContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <SectionContainer>
                  <SectionHeading>Drafts</SectionHeading>
                  <FieldEditor<GlobalSettings>
                    field={fields.draftOverrideDenomination}
                    {...commonProps}
                  />
                </SectionContainer>
                <SectionContainer>
                  <SectionHeading>General Ledger</SectionHeading>
                  <FieldEditor<GlobalSettings>
                    field={fields.generalLedgerOverrideDenomination}
                    {...commonProps}
                  />
                </SectionContainer>
                <SectionContainer>
                  <SectionHeading>US Cheque</SectionHeading>

                  <FieldEditor<GlobalSettings>
                    field={fields.usChequeBuyRate}
                    {...commonProps}
                  />
                  <FieldEditor<GlobalSettings>
                    field={fields.usChequeSellRate}
                    {...commonProps}
                  />
                </SectionContainer>
                <SectionContainer>
                  <SectionHeading>Cheques</SectionHeading>
                  {/* <FieldEditor<GlobalSettings>
                    field={fields.allowHoldsOnCheques}
                    {...commonProps}
                  /> */}
                  <FieldEditor<GlobalSettings>
                    field={fields.chequeWarningMessageDenomination}
                    {...commonProps}
                  />
                </SectionContainer>
              </Grid>
            </Grid>
          </Column>
        </Content>
      )}
    </AdminTemplate>
  );
}

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Column = styled(Paper)`
  width: 600px;
  margin: 4em;
  padding: 1em;
`;

const SectionHeading = styled.div`
  font-weight: 500;
  width: 200px;
  word-wrap: break-word;
  padding-bottom: 6px;
`;

const SectionContainer = styled.div`
  padding-bottom: 0.75em;
`;
