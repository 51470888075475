import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { AccountAdditionalInfo } from "types";
import styled from "styled-components";
import React from "react";
import {
  getBusinessOverdraftTypeLabel,
  getMaskedMoneyValue,
} from "common/functions";

export default function AdditionalInformation(props: {
  accountType: string;
  additionalInfo: AccountAdditionalInfo;
}) {
  return (
    <HtmlTooltip
      title={
        [
          "PERSONAL_SAVINGS",
          "CREDIT_CARD",
          "GIC",
          "MUTUAL_FUNDS",
          "LOAN",
          "LINE_OF_CREDIT",
          "MORTGAGE",
        ].includes(props.accountType) ? (
          <React.Fragment>
            <AdditionalInformationItem
              label="Type"
              value={props.additionalInfo.type ?? "Not Set"}
            />
          </React.Fragment>
        ) : props.accountType === "PERSONAL_CHEQUING" ? (
          <React.Fragment>
            <AdditionalInformationItem
              label="ODP"
              value={
                props.additionalInfo.hasOverdraftProtection === true
                  ? "Yes"
                  : "No"
              }
            />
            {!!props.additionalInfo.hasOverdraftProtection && (
              <AdditionalInformationItem
                label="ODP Limit"
                value={
                  props.additionalInfo.overdraftLimit
                    ? getMaskedMoneyValue(props.additionalInfo.overdraftLimit)
                    : ""
                }
              />
            )}
          </React.Fragment>
        ) : props.accountType === "BUSINESS_CHEQUING" ? (
          <React.Fragment>
            <AdditionalInformationItem
              label="ODP"
              value={
                props.additionalInfo.hasOverdraftProtection === true
                  ? "Yes"
                  : "No"
              }
            />
            {!!props.additionalInfo.hasOverdraftProtection && (
              <AdditionalInformationItem
                label="ODP Type"
                value={
                  props.additionalInfo.overdraftType
                    ? getBusinessOverdraftTypeLabel(
                        props.additionalInfo.overdraftType
                      ) || ""
                    : "Not Set"
                }
              />
            )}
            {!!props.additionalInfo.hasOverdraftProtection && (
              <AdditionalInformationItem
                label="ODP Limit"
                value={
                  props.additionalInfo.overdraftLimit
                    ? getMaskedMoneyValue(props.additionalInfo.overdraftLimit)
                    : ""
                }
              />
            )}
          </React.Fragment>
        ) : props.accountType === "BUSINESS_SAVINGS" ? (
          <React.Fragment>
            <AdditionalInformationItem label="Type" value="N/A" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <AdditionalInformationItem label="Unknown" value="" />
          </React.Fragment>
        )
      }
    >
      <InfoIcon sx={{ color: "#1976d2" }} />
      {/* "#0000008a" */}
    </HtmlTooltip>
  );
}

function AdditionalInformationItem(props: { label: string; value: string }) {
  return (
    <RowContainer>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </RowContainer>
  );
}

const Label = styled.label`
  color: #0000008a;
  width: 60px;
  padding: 0 5px;
  font-size: 12px;
`;
const Value = styled.div`
  font-size: 12px;
  padding-left: 5px;
  width: 60px;
`;
const RowContainer = styled.div`
  padding: 6px 0px;
  display: flex;
  text-align: left;
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #dadde9",
  },
  "& .MuiTooltip-arrow": {
    "&::before": {
      backgroundColor: "#f5f5f9",
      border: "1px solid #dadde9",
    },
  },
}));
