import { Button, TableCell, TableRow } from "@mui/material";
import MaskedValue from "common/MaskedValue";
import styled from "styled-components";
import { MASK_TYPE } from "types";
import PersonIcon from "@mui/icons-material/Person";
import DeleteMenu from "pages/clientSearch/components/DeleteMenu";
import DeleteControl from "components/deleteControl";
import { useState } from "react";

export default function ClientRowTemplate(props: {
  clientId: string;
  clientName: string;
  phoneNumber: string;
  accountType: string;
  itemToDelete: string;
  studentId?: string;
  onDelete(): void;
  onPortfolioClick(): void;
  isInstructor: boolean;
}) {
  const {
    clientId,
    clientName,
    phoneNumber,
    accountType,
    itemToDelete,
    studentId,
    onDelete,
    onPortfolioClick,
    isInstructor,
  } = props;
  const [openDeleteControl, setOpenDeleteControl] = useState<boolean>(false);
  return (
    <>
      <TableRow>
        <Cell>{clientId}</Cell>
        <Cell>{clientName}</Cell>
        <Cell>{MaskedValue(MASK_TYPE.PHONE_NUMBER, phoneNumber)}</Cell>
        <Cell>{accountType}</Cell>
        <Cell>
          <Button onClick={onPortfolioClick}>
            <PersonIcon style={{ marginRight: 5 }} />
            Portfolio
          </Button>
        </Cell>
        {isInstructor && (
          <Cell>
            <DeleteMenu
              isReadOnlyView={!!studentId}
              onDelete={() => {
                setOpenDeleteControl(true);
              }}
            />
          </Cell>
        )}
      </TableRow>
      {openDeleteControl && (
        <DeleteControl
          itemToDelete={itemToDelete}
          deleteItemName={clientName}
          onCancel={() => {
            setOpenDeleteControl(false);
          }}
          onDelete={() => {
            onDelete();
            setOpenDeleteControl(false);
          }}
          warningMessage="Doing so will permanently delete this client from this lesson template and if students reset their lesson, they will no longer see this client."
        />
      )}
    </>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
`;
