import {
  add,
  checkIfUnauthorized,
  getNewGLTransaction,
  subtract,
} from "common/functions";
import { UnauthorizedRoute } from "components/paths";
import { openSnackbar } from "components/snackbar";
import { getGeneralLedger, getLessonClientList } from "pages/lessons/helpers";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useActions, useReduxState } from "store";
import styled from "styled-components";
import {
  BusinessClient,
  GeneralLedger,
  GLAccount,
  GLTransaction,
  PersonalClient,
} from "types";
import CashTransferDialog from "pages/generalLedger/components/CashTransferDialog";
import CashTransferMenu from "pages/generalLedger/components/CashTransferMenu";
import GLAccountDetails from "pages/generalLedger/components/GLAccountDetails";
import GLInfoTemplate from "pages/generalLedger/components/GLInfoTemplate";

export type Props = {
  lessonId: string;
  studentId?: string;
};

export default function GeneralLedgerPage(props: Props) {
  const {
    getLessonNameAndData,
    getDataforViewAsStudent,
    lessonId,
  } = useReduxState((e) => e.lesson);
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [allClients, setAllClients] = useState<
    (PersonalClient | BusinessClient)[]
  >([]);
  const glActions = useActions().generalLedger;

  const lessonActions = useActions().lesson;
  const [activeGLTransaction, setActiveGLTransaction] = useState<
    GLTransaction | undefined
  >(undefined);

  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }

  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            const clientList = getLessonClientList(payload.lessonData);

            setAllClients(clientList);

            const gl = getGeneralLedger(payload.lessonData);
            setGLState(gl);
            setIsLoading(false);
          },
        });
      } else {
        const clientList = getLessonClientList(
          getLessonNameAndData.data.lessonData
        );
        setAllClients(clientList);

        const gl = getGeneralLedger(getLessonNameAndData.data.lessonData);
        setGLState(gl);
        setIsLoading(false);
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            const clientList = getLessonClientList(payload.lessonData);
            setAllClients(clientList);

            const gl = getGeneralLedger(payload.lessonData);
            setGLState(gl);
            setIsLoading(false);
          },
        });
      } else {
        const clientList = getLessonClientList(
          getDataforViewAsStudent.data.lessonData
        );
        setAllClients(clientList);

        const gl = getGeneralLedger(getDataforViewAsStudent.data.lessonData);
        setGLState(gl);
        setIsLoading(false);
      }
    }
  }, []);

  function saveGL(updatedGL: GeneralLedger) {
    setIsLoading(true);
    glActions.saveGeneralLedger({
      generalLedger: updatedGL,
      lessonId,
      onComplete: (retValue) => {
        setIsLoading(false);
        if (retValue.success) {
          lessonActions.saveGeneralLedger(updatedGL);
          setGLState(updatedGL);

          openSnackbar({
            severity: "success",
            message: `General Ledger updated successfully`,
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error updating General Ledger. Please refresh the page and try again!`,
          });
        }
      },
    });
  }

  function saveGLTransaction(transaction: GLTransaction) {
    let currentGLTxns = glState.glTransactions ?? [];
    let newGLState: GeneralLedger = {
      ...glState,
      glAccounts: glState.glAccounts?.map((gla) => {
        if (
          gla.glAccountId === transaction.from ||
          gla.glAccountId === transaction.to
        ) {
          let currentTxns = gla.transactionIds ?? [];
          let newAcc: GLAccount = {
            ...gla,
            balance:
              gla.glAccountId === transaction.to
                ? add(gla.balance ?? 0, transaction.amount ?? 0)
                : subtract(gla.balance ?? 0, transaction.amount ?? 0),
            transactionIds: [...currentTxns, transaction.transactionId],
          };
          return newAcc;
        } else return gla;
      }),
      glTransactions: [...currentGLTxns, transaction],
      cashDrawer: {
        ...glState.cashDrawer,
        balance:
          transaction.to === "cashdrawer"
            ? add(glState.cashDrawer?.balance ?? 0, transaction.amount ?? 0)
            : subtract(
                glState.cashDrawer?.balance ?? 0,
                transaction.amount ?? 0
              ),
        endOfDayTransactionIds: [
          ...(glState.cashDrawer?.endOfDayTransactionIds ?? []),
          transaction.transactionId,
        ],
      },
    };
    saveGL(newGLState);

    setActiveGLTransaction(undefined);
  }

  function onCashTransfer(from: string, to: string) {
    const newGLTransaction: GLTransaction = {
      ...getNewGLTransaction(from, to),
    };
    setActiveGLTransaction(newGLTransaction);
  }

  const isReadOnlyStudentAccess =
    getLessonNameAndData.data?.isReadOnly ?? false;

  return (
    <GLInfoTemplate
      generalLedger={glState}
      title="General Ledger"
      isLoading={isLoading}
      studentId={props.studentId}
    >
      <Column>
        <FlexDiv>
          <ColumnHeading>Account History</ColumnHeading>
          <CashTransferMenu
            isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
            onCashTransfer={(from, to) => onCashTransfer(from, to)}
          />
        </FlexDiv>
        <GLAccountDetails
          glAccounts={glState.glAccounts ?? []}
          glTransactions={glState.glTransactions ?? []}
          clients={allClients}
        />
      </Column>
      {!!activeGLTransaction && (
        <CashTransferDialog
          transaction={activeGLTransaction}
          onSave={(transaction) => {
            saveGLTransaction(transaction);
          }}
          onCancel={() => {
            setActiveGLTransaction(undefined);
          }}
        />
      )}
    </GLInfoTemplate>
  );
}

const Column = styled.div`
  min-width: 550px;
  margin: 0.5em;
  flex: 1;
`;
const ColumnHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
