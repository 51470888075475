import { Paper } from "@mui/material";
import MainTemplate from "components/mainTemplate/MainTemplate";
import { useEffect, useState } from "react";
import { useActions, useReduxState } from "store";
import styled from "styled-components";
import { BusinessClient, GeneralLedger, PersonalClient } from "types";
import ClientList from "pages/sessionHistory/components/ClientList";
import TransactionsSummary from "pages/sessionHistory/components/TransactionsSummary";
import { getGeneralLedger, getLessonClientList } from "pages/lessons/helpers";
import SearchTextField from "components/searchTextField";
import {
  checkIfUnauthorized,
  getClientFirstNameLastName,
  getPersonalClientName,
} from "common/functions";
import { useHistory } from "react-router-dom";
import { UnauthorizedRoute } from "components/paths";

export type Props = {
  lessonId: string;
  studentId?: string;
};
type State = {
  searchText: string;
};

export default function SessionHistory(props: Props) {
  const [state, setState] = useState<State>({ searchText: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<
    PersonalClient | BusinessClient
  >();
  const { getLessonNameAndData, getDataforViewAsStudent } = useReduxState(
    (e) => e.lesson
  );

  const [clientList, setClientList] = useState<
    (PersonalClient | BusinessClient)[]
  >(
    getLessonClientList(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );

  const history = useHistory();

  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }

  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            let cList = getLessonClientList(payload.lessonData);
            setClientList(cList);
            cList.length >= 0 && setSelectedClient(cList[0]);
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
        let cList = getLessonClientList(getLessonNameAndData.data.lessonData);
        setClientList(cList);
        cList.length >= 0 && setSelectedClient(cList[0]);
        setIsLoading(false);
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            let sCList = getLessonClientList(payload.lessonData);
            setClientList(sCList);
            sCList.length >= 0 && setSelectedClient(sCList[0]);
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
        let sCList = getLessonClientList(
          getDataforViewAsStudent.data.lessonData
        );
        setClientList(sCList);
        sCList.length >= 0 && setSelectedClient(sCList[0]);
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    let filteredList = getLessonClientList(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    ).filter((c) =>
      c.information?.accountType === "Personal"
        ? c.information?.legalFirstName
            ?.toLowerCase()
            .includes(state.searchText.toLowerCase()) ||
          c.information?.legalLastName
            ?.toLowerCase()
            .includes(state.searchText.toLowerCase()) ||
          c.information?.preferredFirstName
            ?.toLowerCase()
            .includes(state.searchText.toLowerCase()) ||
          getPersonalClientName(c as PersonalClient)
            .toLowerCase()
            .includes(state.searchText.toLowerCase()) ||
          getClientFirstNameLastName(c as PersonalClient)
            .toLowerCase()
            .includes(state.searchText.toLowerCase()) ||
          c.information.accountType
            .toLowerCase()
            .includes(state.searchText.toLowerCase())
        : (c as BusinessClient).information?.operatingAs
            ?.toLowerCase()
            .includes(state.searchText.toLowerCase()) ||
          (c as BusinessClient).information?.accountType
            ?.toLowerCase()
            .includes(state.searchText.toLowerCase())
    );
    setClientList(filteredList);
    filteredList.length >= 0 && setSelectedClient(filteredList[0]);
  }, [state.searchText]);

  const lessonActions = useActions().lesson;

  return (
    <MainTemplate
      studentId={props.studentId}
      isLoading={isLoading}
      title={"Session History"}
      isSessionHistory={true}
      cashDrawerBalance={glState.cashDrawer?.balance}
    >
      <Content>
        <ClientsColumn>
          <SearchFieldContainer>
            <SearchTextField
              value={state.searchText}
              label="Search client name"
              onChange={(text) => setState({ ...state, searchText: text })}
            />
          </SearchFieldContainer>
          <ClientList
            clientList={clientList}
            onClientSelect={(selectedClient) => {
              setSelectedClient(selectedClient);
            }}
          />
        </ClientsColumn>
        <TransactionDetailsColumn>
          <TransactionsSummary
            client={selectedClient}
            lessonId={props.lessonId}
            studentId={props.studentId}
          />
        </TransactionDetailsColumn>
      </Content>
    </MainTemplate>
  );
}

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ClientsColumn = styled.div`
  background-color: #efefef;
  min-height: 100vh;
  width: 370px;
`;

const TransactionDetailsColumn = styled(Paper)`
  height: 500px;
  flex: 1;
`;

const SearchFieldContainer = styled.div`
  padding: 1em 0.25em;
  margin: 2em 0em;
`;
