import { ReducerState } from "@nait-aits/redux";
import moment from "moment";
import { GetLessonNameAndDataReturn } from "pages/lessons/lessons.duck";
import {
  TextFieldType,
  FieldError,
  Entity,
  PickListField,
  IdentificationField,
  DateField,
  AddressField,
  RadioGroupField,
  Cash,
  PersonalClient,
  CashItem,
  PersonalSavings,
  LineOfCredit,
  PersonalChequing,
  IdName,
  Account,
  CreditCard,
  GIC,
  MutualFunds,
  Loan,
  Mortgage,
  Deposit,
  Receive,
  TransactionSession,
  Withdrawal,
  PayoutCash,
  BillPayment,
  CreditCardTransaction,
  Transfer,
  SellDraft,
  GLEntry,
  GLTransaction,
  ForeignExchangeConverter,
  GlobalSettings,
  Transaction,
  BusinessClient,
  BusinessChequing,
  BusinessSavings,
  CreditAppLoan,
  CreditAppLOC,
  CreditAppMortgage,
} from "types";
import { v4 } from "uuid";
import _orderBy from "lodash/orderBy";
import { getLoginInfo } from "./azureAD";
import {
  assetsAccountsTypes,
  liabilitiesAccountsTypes,
  residentialStatusOptions,
} from "./helpers";
import { useReduxState } from "store";

export function requiredTextField<T extends Entity>(
  field: TextFieldType<T>,
  obj: any,
  isDependentlyRequired?: boolean
): FieldError[] {
  const value = field.getValue(obj);
  if (
    (!value || (value as string).trim().length === 0) &&
    (field.isRequired ||
      (field.checkIfRequired && field.checkIfRequired(obj)) ||
      (isDependentlyRequired && isDependentlyRequired === true))
  ) {
    return [{ id: field.id, label: field.label, error: "Is Required." }];
  }
  return [];
}

export function requiredRadioGroupField<T extends Entity>(
  field: RadioGroupField<T>,
  obj: any
): FieldError[] {
  const value = field.getValue(obj);
  if (
    (!value || (value as string).trim().length === 0) &&
    (field.isRequired || (field.checkIfRequired && field.checkIfRequired(obj)))
  ) {
    return [{ id: field.id, label: field.label, error: "Is Required." }];
  }
  return [];
}

export function requiredPickListField<T extends Entity>(
  field: PickListField<T>,
  obj: any
): FieldError[] {
  const value = field.getValue(obj);
  if (
    !value &&
    (field.isRequired || (field.checkIfRequired && field.checkIfRequired(obj)))
  ) {
    return [{ id: field.id, label: field.label, error: "Is Required." }];
  }
  return [];
}

export function requiredDateField<T extends Entity>(
  field: DateField<T>,
  obj: any
): FieldError[] {
  const value = field.getValue(obj);
  if (
    (!value &&
      (field.isRequired ||
        (field.checkIfRequired && field.checkIfRequired(obj)))) ||
    (value && value.toLowerCase() === "invalid date")
  ) {
    return [{ id: field.id, label: field.label, error: "Is Required." }];
  }
  return [];
}

export function requiredDateTimeField<T extends Entity>(
  field: DateField<T>,
  obj: any
): FieldError[] {
  const value = field.getValue(obj);
  if (
    !value &&
    (field.isRequired || (field.checkIfRequired && field.checkIfRequired(obj)))
  ) {
    return [{ id: field.id, label: field.label, error: "Is Required." }];
  } else if (value && value.toLowerCase() === "invalid date") {
    return [{ id: field.id, label: field.label, error: "Is Invalid." }];
  }
  return [];
}

export function identificationErrors<T extends Entity>(
  field: IdentificationField<T>,
  obj: any
): FieldError[] {
  const errors: FieldError[] = [];
  const identification = field.getValue(obj);

  const type = identification?.type;
  const number = identification?.number;
  const otherType = identification?.otherType;

  if (!number || (number as string).trim().length === 0) {
    errors.push({
      id: `${field.id}_number`,
      label: `${field.label} Number`,
      error: "Is Required.",
    });
  }

  if (!type || (type as string).trim().length === 0) {
    errors.push({
      id: `${field.id}_name`,
      label: `${field.label} Type`,
      error: "Is Required.",
    });
  }

  if (
    type === "other" &&
    (!otherType || (otherType as string).trim().length === 0)
  ) {
    errors.push({
      id: `${field.id}_otherType`,
      label: `${field.label} Type - Other`,
      error: "Is Required.",
    });
  }

  return errors;
}

export function addressErrors<T extends Entity>(
  field: AddressField<T>,
  obj: any
): FieldError[] {
  const errors: FieldError[] = [];
  const fullAddress = field.getValue(obj);

  const address = fullAddress?.address;
  const city = fullAddress?.city;
  const province = fullAddress?.province;
  const postalCode = fullAddress?.postalCode;
  const startDate = fullAddress?.startDate;

  if (!address || (address as string).trim().length === 0) {
    errors.push({
      id: `${field.id}_address`,
      label: `Address`,
      error: "Is Required.",
    });
  }

  if (!city || (city as string).trim().length === 0) {
    errors.push({
      id: `${field.id}_city`,
      label: `City`,
      error: "Is Required.",
    });
  }
  if (!province || (province as string).trim().length === 0) {
    errors.push({
      id: `${field.id}_province`,
      label: `Province`,
      error: "Is Required.",
    });
  }
  if (!postalCode || (postalCode as string).trim().length === 0) {
    errors.push({
      id: `${field.id}_postalCode`,
      label: `Postal Code`,
      error: "Is Required.",
    });
  }
  if (
    field.isDated &&
    (!startDate ||
      (startDate as string).trim().length === 0 ||
      startDate.toLowerCase() === "invalid date")
  ) {
    errors.push({
      id: `${field.id}_startDate`,
      label: `Start Date`,
      error: "Is Required.",
    });
  }
  return errors;
}

export function creditAppPropertyAddressErrors<T extends Entity>(
  field: AddressField<T>,
  obj: any
): FieldError[] {
  const errors: FieldError[] = [];
  const fullAddress = field.getValue(obj);

  const address = fullAddress?.address;
  const city = fullAddress?.city;
  const province = fullAddress?.province;
  const postalCode = fullAddress?.postalCode;

  //following isRequired is used to cover the scenario where
  //fields for one state is required or not depends on fields value of another state.
  //For Example: property address fields are required only if locType is "heloc"

  const isRequired =
    (field.isRequired ||
      (field.checkIfRequired && field.checkIfRequired(obj))) ??
    false;

  if (isRequired && (!address || (address as string).trim().length === 0)) {
    errors.push({
      id: `${field.id}_address`,
      label: `Address`,
      error: "Is Required.",
    });
  }

  if (isRequired && (!city || (city as string).trim().length === 0)) {
    errors.push({
      id: `${field.id}_city`,
      label: `City`,
      error: "Is Required.",
    });
  }
  if (isRequired && (!province || (province as string).trim().length === 0)) {
    errors.push({
      id: `${field.id}_province`,
      label: `Province`,
      error: "Is Required.",
    });
  }
  if (
    isRequired &&
    (!postalCode || (postalCode as string).trim().length === 0)
  ) {
    errors.push({
      id: `${field.id}_postalCode`,
      label: `Postal Code`,
      error: "Is Required.",
    });
  }

  return errors;
}

export function employmentAddressErrors<T extends Entity>(
  field: AddressField<T>,
  obj: any,
  isDependentlyRequired?: boolean
): FieldError[] {
  const errors: FieldError[] = [];
  const fullAddress = field.getValue(obj);

  const address = fullAddress?.address;
  const city = fullAddress?.city;
  const province = fullAddress?.province;
  const postalCode = fullAddress?.postalCode;

  //following isRequired is used to cover the scenario where
  //fields for one state is required or not depends on fields value of another state.
  //For Example: while editing the employment under Employment section in Client Portfolio's Inormation tab,
  //the emplyer name and Address are required for specifici values of Employment Type.
  //Therefore, Employer fields depends on field value of Employment state

  const isRequired = isDependentlyRequired && isDependentlyRequired === true;

  if (isRequired && (!address || (address as string).trim().length === 0)) {
    errors.push({
      id: `${field.id}_address`,
      label: `Address`,
      error: "Is Required.",
    });
  }

  if (isRequired && (!city || (city as string).trim().length === 0)) {
    errors.push({
      id: `${field.id}_city`,
      label: `City`,
      error: "Is Required.",
    });
  }
  if (isRequired && (!province || (province as string).trim().length === 0)) {
    errors.push({
      id: `${field.id}_province`,
      label: `Province`,
      error: "Is Required.",
    });
  }
  if (
    isRequired &&
    (!postalCode || (postalCode as string).trim().length === 0)
  ) {
    errors.push({
      id: `${field.id}_postalCode`,
      label: `Postal Code`,
      error: "Is Required.",
    });
  }
  return errors;
}

function isLetter(x: string): boolean {
  const regexChar = new RegExp("[A-Z]");
  return regexChar.test(x);
}
function isNumber(x: string): boolean {
  const regexNum = new RegExp("[0-9]");
  return regexNum.test(x);
}

export function scrubPCValue(pc: string, prev?: string): string {
  const isBackSpace = !!prev && prev.length > pc.length && pc.length === 3;
  const chars: string[] = Array.from(pc).filter(
    (a) => isNumber(a) || isLetter(a)
  );
  const returnStr: string[] = [];
  for (let i = 0; i < chars.length; i++) {
    if ([0, 4].includes(i) && isLetter(chars[i])) {
      returnStr.push(chars[i]);
    } else if ([1, 3, 5].includes(i) && isNumber(chars[i])) {
      returnStr.push(chars[i]);
    } else if (i === 2 && !isBackSpace) {
      const char = chars[2];
      if (isLetter(char)) {
        returnStr.push(char);
        returnStr.push(" ");
      }
    } else {
      break;
    }
  }
  return returnStr.join("");
}

export function add(a: number | null, b: number | null) {
  let x: number = a ? a : 0;
  let y: number = b ? b : 0;
  const sum: number = x * 1 + y * 1;
  const roundOffSum: number = Math.round((sum + Number.EPSILON) * 100) / 100;
  return roundOffSum;
}

export function subtract(a: number | null, b: number | null) {
  let x: number = a ? a : 0;
  let y: number = b ? b : 0;
  const diff: number = x * 1 - y * 1;
  const roundOffDiff: number = Math.round((diff + Number.EPSILON) * 100) / 100;
  return roundOffDiff;
}

export function getMaturityDate(openDate: string, timePeriod: number) {
  let maturityDate = moment(openDate)
    .add(timePeriod, "years")
    .format("MM/DD/YYYY");
  return maturityDate;
}

export function getAccountTypeLabel(accountType: string) {
  var res: string = "";
  //  accountType: "PERSONAL_SAVINGS" | "PERSONAL_CHEQUING" | "CREDIT_CARD" | "GIC" | "MUTUAL_FUNDS" |"LOAN"| "LINE_OF_CREDIT" | "MORTGAGE";
  switch (accountType) {
    case "PERSONAL_SAVINGS":
      res = "Savings";
      break;

    case "BUSINESS_SAVINGS":
      res = "Savings";
      break;

    case "PERSONAL_CHEQUING":
      res = "Chequing";
      break;

    case "BUSINESS_CHEQUING":
      res = "Chequing";
      break;

    case "CREDIT_CARD":
      res = "Credit Card";
      break;

    case "GIC":
      res = "Guaranteed Investment Certificate";
      break;

    case "MUTUAL_FUNDS":
      res = "Mutual Funds";
      break;

    case "LOAN":
      res = "Loan";
      break;

    case "LINE_OF_CREDIT":
      res = "Line Of Credit";
      break;

    case "MORTGAGE":
      res = "Mortgage";
      break;

    default:
      res = accountType;
      break;
  }

  return res;
}

export function getPersonalCreditCardTypeLabel(creditCardType: string) {
  var res: string = "";
  switch (creditCardType) {
    case "cashback":
      res = "Cashback";
      break;

    case "student":
      res = "Student";
      break;

    case "travel":
      res = "Travel";
      break;

    case "nofee":
      res = "No Fee";
      break;

    default:
      res = creditCardType;
      break;
  }

  return res;
}

export function getExchangeRate(
  feConverter: ForeignExchangeConverter,
  globalSettings: GlobalSettings
) {
  let rate: number = 1;
  switch (feConverter.transactionType) {
    case "buycash":
      rate = globalSettings.usCashBuyRate ?? 1;
      break;

    case "buycheque":
      rate = globalSettings.usChequeBuyRate ?? 1;
      break;

    case "sellcash":
      rate = globalSettings.usCashSellRate ?? 1;
      break;

    case "sellcheque":
      rate = globalSettings.usChequeSellRate ?? 1;
      break;

    default:
      rate = 1;
      break;
  }

  return rate;
}

export function getTotalCashAmount(cash: Cash) {
  let coinValueArray = [
    cash.cent5Coins && cash.cashCurrencyType !== "usd"
      ? cash.cent5Coins * 0.05
      : 0,
    cash.cent10Coins && cash.cashCurrencyType !== "usd"
      ? cash.cent10Coins * 0.1
      : 0,
    cash.cent25Coins && cash.cashCurrencyType !== "usd"
      ? cash.cent25Coins * 0.25
      : 0,
    cash.dollar1Coins && cash.cashCurrencyType !== "usd"
      ? cash.dollar1Coins * 1
      : 0,
    cash.dollar2Coins && cash.cashCurrencyType !== "usd"
      ? cash.dollar2Coins * 2
      : 0,
  ];
  let totalCoinValue = coinValueArray.reduce(add, 0);
  let billValueArray = [
    cash.dollar1Bills && cash.cashCurrencyType === "usd"
      ? cash.dollar1Bills * 1
      : 0,
    cash.dollar2Bills && cash.cashCurrencyType === "usd"
      ? cash.dollar2Bills * 2
      : 0,
    cash.dollar5Bills ? cash.dollar5Bills * 5 : 0,
    cash.dollar10Bills ? cash.dollar10Bills * 10 : 0,
    cash.dollar20Bills ? cash.dollar20Bills * 20 : 0,
    cash.dollar50Bills ? cash.dollar50Bills * 50 : 0,
    cash.dollar100Bills ? cash.dollar100Bills * 100 : 0,
  ];
  let totalBillValue = billValueArray.reduce(add, 0);
  let totalAmount = add(totalCoinValue, totalBillValue);

  return totalAmount;
}

export function getTotalNotesCashAmount(cash: Cash) {
  let billValueArray = [
    !!cash.dollar5Bills ? cash.dollar5Bills * 5 : 0,
    !!cash.dollar10Bills ? cash.dollar10Bills * 10 : 0,
    !!cash.dollar20Bills ? cash.dollar20Bills * 20 : 0,
    !!cash.dollar50Bills ? cash.dollar50Bills * 50 : 0,
    !!cash.dollar100Bills ? cash.dollar100Bills * 100 : 0,
  ];
  let totalBillValue = billValueArray.reduce(add, 0);

  return totalBillValue;
}

export function getTotalCoinsCashAmount(cash: Cash) {
  let coinValueArray = [
    !!cash.cent5Coins ? cash.cent5Coins * 0.05 : 0,
    !!cash.cent10Coins ? cash.cent10Coins * 0.1 : 0,
    !!cash.cent25Coins ? cash.cent25Coins * 0.25 : 0,
    !!cash.dollar1Coins ? cash.dollar1Coins * 1 : 0,
    !!cash.dollar2Coins ? cash.dollar2Coins * 2 : 0,
  ];
  let totalCoinValue = coinValueArray.reduce(add, 0);

  return totalCoinValue;
}

export function getPersonalClientName(client: PersonalClient) {
  const clientName =
    client.information?.legalFirstName &&
    client.information.legalLastName &&
    client.information?.preferredFirstName
      ? `${client.information?.legalFirstName} (${client.information?.preferredFirstName}) ${client.information?.legalLastName}`
      : client.information?.legalFirstName && client.information.legalLastName
      ? `${client.information?.legalFirstName} ${client.information?.legalLastName}`
      : "New Client";

  return clientName;
}

export function getBusinessClientOperatingAs(client: BusinessClient) {
  if (!client) return "New Client";
  const operatingAs = client.information?.operatingAs ?? "New Client";
  return operatingAs;
}

export function getClientFirstNameLastName(client: PersonalClient) {
  const clientFirstLastName =
    client.information?.legalFirstName && client.information.legalLastName
      ? `${client.information?.legalFirstName} ${client.information?.legalLastName}`
      : "New Client";

  return clientFirstLastName;
}

export function getRemainingTimePeriod(
  date: string,
  yearsToSubtractFrom: number
) {
  var duration = moment.duration(moment().diff(date));

  let noOfYearsToSubtract = Math.round(duration.asYears() * 10) / 10;
  let remainingTimePeriod = yearsToSubtractFrom - noOfYearsToSubtract;
  return remainingTimePeriod;
}

export function getAccountBalance(client: PersonalClient, accountId: string) {
  let accountBalance = client.accounts?.find((a) => a.accountId === accountId)
    ?.balance;

  return accountBalance;
}

export function getCashDetails(cash: Cash) {
  let arr: CashItem[] = [
    { num: 100, quantity: cash.dollar100Bills },
    { num: 50, quantity: cash.dollar50Bills },
    { num: 20, quantity: cash.dollar20Bills },
    { num: 10, quantity: cash.dollar10Bills },
    { num: 5, quantity: cash.dollar5Bills },
    { num: 2, quantity: cash.dollar2Bills },
    { num: 1, quantity: cash.dollar1Bills },
    { num: 2, quantity: cash.dollar2Coins },
    { num: 1, quantity: cash.dollar1Coins },
    { num: 0.25, quantity: cash.cent25Coins },
    { num: 0.1, quantity: cash.cent10Coins },
    { num: 0.05, quantity: cash.cent5Coins },
  ];
  return arr;
}

export function getDepositWithdrawOptions(
  client: PersonalClient | BusinessClient
) {
  let validType = [
    "PERSONAL_SAVINGS",
    "PERSONAL_CHEQUING",
    "LINE_OF_CREDIT",
    "LOAN",
    "MORTGAGE",
    "BUSINESS_SAVINGS",
    "BUSINESS_CHEQUING",
  ];

  let activeAccArr:
    | PersonalChequing[]
    | PersonalSavings[]
    | LineOfCredit[]
    | Loan[]
    | Mortgage[]
    | BusinessChequing[]
    | BusinessSavings[] = client.accounts
    ? client.accounts.filter(
        (a) =>
          validType.includes(a.accountType) &&
          (!a.closeDate || a.closeDate === "Invalid date")
      )
    : [];

  let depositWithdrawOptions: IdName[] = [];

  for (let i = 0; i < activeAccArr.length; i++) {
    let displayName = getAccountTypeLabel(activeAccArr[i].accountType).concat(
      ` - ${activeAccArr[i].accountNumber}`
    );
    let item: IdName = {
      id: activeAccArr[i].accountId,
      name: displayName,
    };
    depositWithdrawOptions.push(item);
  }
  let sortedDepositWithdrawOptions = getSortedAccountOptionsByName(
    depositWithdrawOptions
  );
  return sortedDepositWithdrawOptions;
}

export function getGLEntryClientAccountOptionsForTxnSummary(
  client: PersonalClient | BusinessClient
) {
  let validType = [
    "PERSONAL_SAVINGS",
    "PERSONAL_CHEQUING",
    "BUSINESS_SAVINGS",
    "BUSINESS_CHEQUING",
  ];

  let activeAccArr:
    | PersonalChequing[]
    | PersonalSavings[]
    | BusinessChequing[]
    | BusinessSavings[] = client.accounts
    ? client.accounts.filter(
        (a) =>
          validType.includes(a.accountType) &&
          (!a.closeDate || a.closeDate === "Invalid date")
      )
    : [];

  let glEntryClientAccountOptions: IdName[] = [
    { id: "sessionbalance", name: "Session Balance" },
  ];

  for (let i = 0; i < activeAccArr.length; i++) {
    let displayName = getAccountTypeLabel(activeAccArr[i].accountType).concat(
      ` - ${activeAccArr[i].accountNumber}`
    );
    let item: IdName = {
      id: activeAccArr[i].accountId,
      name: displayName,
    };
    glEntryClientAccountOptions.push(item);
  }
  return glEntryClientAccountOptions;
}

export function getGLEntryClientAccountOptionsForSessionHistory(
  client: PersonalClient | BusinessClient
) {
  let validType = [
    "PERSONAL_SAVINGS",
    "PERSONAL_CHEQUING",
    "BUSINESS_SAVINGS",
    "BUSINESS_CHEQUING",
  ];

  let activeAccArr:
    | PersonalChequing[]
    | PersonalSavings[]
    | BusinessChequing[]
    | BusinessSavings[] = client.accounts
    ? client.accounts.filter((a) => validType.includes(a.accountType))
    : [];

  let glEntryClientAccountOptions: IdName[] = [
    { id: "sessionbalance", name: "Session Balance" },
  ];

  for (let i = 0; i < activeAccArr.length; i++) {
    let displayName = getAccountTypeLabel(activeAccArr[i].accountType).concat(
      ` - ${activeAccArr[i].accountNumber}`
    );
    let item: IdName = {
      id: activeAccArr[i].accountId,
      name: displayName,
    };
    glEntryClientAccountOptions.push(item);
  }
  return glEntryClientAccountOptions;
}

export function getGLEntryReasonOptions() {
  let options = [
    { id: "close_account", name: "Close Account" },
    { id: "bank_error", name: "Bank Error" },
    { id: "csr_outage", name: "CSR Outage" },
    { id: "client_retention", name: "Client Retention" },
    { id: "fee_reversal", name: "Fee Reversal" },
    { id: "vault_transfer", name: "Vault Transfer" },
    { id: "client_to_client_transfers", name: "Client to Client Transfers" },
    { id: "other", name: "Other" },
  ];

  return options;
}

export function getGLEntryReasonLabel(reasonId: string) {
  let options = getGLEntryReasonOptions();

  let label = options.find((o) => o.id === reasonId)?.name;
  return label;
}

export function getSigningAuthorityBusinessAffiliationOptions() {
  let options = [
    { id: "owner", name: "Owner" },
    { id: "treasurer", name: "Treasurer" },
    { id: "co-owner", name: "Co-Owner" },
    { id: "partner", name: "Partner" },
    { id: "controller", name: "Controller" },
    { id: "executive assistant", name: "Executive Assistant" },
  ];

  return options;
}

export function getSigningAuthorityBusinessAffiliationLabel(
  businessAffiliationId: string
) {
  let options = getSigningAuthorityBusinessAffiliationOptions();

  let label = options.find((o) => o.id === businessAffiliationId)?.name;
  return label;
}

export function getGLAccountOptions() {
  let glAccountOptions: IdName[] = [
    { id: "treasury", name: "Treasury" },
    { id: "csr_outage", name: "CSR Outage" },
    { id: "service_charges", name: "Service Charges" },
    { id: "drafts", name: "Drafts" },
    { id: "client_transfer", name: "Client Transfer" },
  ];

  return glAccountOptions;
}

export function getSavingsTypeOptions() {
  let options = [
    { id: "tfsa", name: "TFSA" },
    { id: "rsp", name: "RSP" },
  ];

  return options;
}

export function getSavingsTypeLabel(type: string) {
  let options = getSavingsTypeOptions();

  let label = options.find((o) => o.id === type)?.name;
  return label;
}
export function getPersonalMutualFundsTypeOptions() {
  let options = [
    { id: "rsp", name: "RSP" },
    { id: "nonrsp", name: "Non-RSP" },
    { id: "resp", name: "RESP" },
    { id: "tfsa", name: "TFSA" },
    { id: "rif", name: "RIF" },
    { id: "spousalrsp", name: "Spousal RSP" },
  ];

  return options;
}

export function getPersonalMutualFundsTypeLabel(type: string) {
  let options = getPersonalMutualFundsTypeOptions();

  let label = options.find((o) => o.id === type)?.name;
  return label;
}

export function getPersonalLOCTypeOptions() {
  let options = [
    { id: "uloc", name: "ULOC" },
    { id: "heloc", name: "HELOC" },
    { id: "sloc", name: "SLOC" },
    { id: "studentloc", name: "Student LOC" },
  ];

  return options;
}

export function getPersonalLOCTypeLabel(type: string) {
  let options = getPersonalLOCTypeOptions();

  let label = options.find((o) => o.id === type)?.name;
  return label;
}
export function getPersonalGICTypeOptions() {
  let options = [
    { id: "rsp", name: "RSP" },
    { id: "nonrsp", name: "Non-RSP" },
    { id: "resp", name: "RESP" },
    { id: "tfsa", name: "TFSA" },
    { id: "rif", name: "RIF" },
  ];

  return options;
}

export function getPersonalGICTypeLabel(type: string) {
  let options = getPersonalGICTypeOptions();

  let label = options.find((o) => o.id === type)?.name;
  return label;
}

export function getBusinessOverdraftTypeOptions() {
  let options = [
    { id: "bodp", name: "BODP" },
    { id: "bloc", name: "BLOC" },
  ];

  return options;
}

export function getBusinessOverdraftTypeLabel(type: string) {
  let options = getBusinessOverdraftTypeOptions();

  let label = options.find((o) => o.id === type)?.name;
  return label;
}

export function getBusinessCreditCardTypeOptions() {
  let options = [
    { id: "business", name: "Business" },
    { id: "travel", name: "Travel" },
    { id: "rewards", name: "Rewards" },
  ];
  return options;
}

export function getBusinessCreditCardTypeLabel(type: string) {
  let options = getBusinessCreditCardTypeOptions();

  let label = options.find((o) => o.id === type)?.name ?? "";
  return label;
}

export function getBusinessLOCTypeOptions() {
  let options = [{ id: "bloc", name: "BLOC" }];
  return options;
}

export function getBusinessLOCTypeLabel(type: string) {
  let options = getBusinessLOCTypeOptions();

  let label = options.find((o) => o.id === type)?.name;
  return label;
}
export function getGLTransactionOptions() {
  let options = [
    { id: "treasury", name: "Treasury" },
    { id: "csr_outage", name: "CSR Outage" },
    { id: "cashdrawer", name: "Cash Drawer" },
  ];

  return options;
}

export function getGLTransactionFromToLabel(value: string) {
  let options = getGLTransactionOptions();

  let label = options.find((o) => o.id === value)?.name;
  return label;
}

export function getMaskedCreditCardNumber(cardNumber: string) {
  let maskedCradNumber = `${cardNumber?.slice(0, 4)} ${cardNumber?.slice(
    4,
    8
  )} ${cardNumber?.slice(8, 12)} ${cardNumber?.slice(12, 16)}`;
  return maskedCradNumber;
}

export function getCreditCardOptionsForTransactionSummary(
  client: PersonalClient | BusinessClient
) {
  let activeAccArr: CreditCard[] = client.accounts
    ? client.accounts.filter(
        (a) =>
          a.accountType === "CREDIT_CARD" &&
          (!a.closeDate || a.closeDate === "Invalid date")
      )
    : [];

  let creditCardOptions: IdName[] = [];

  for (let i = 0; i < activeAccArr.length; i++) {
    let account = activeAccArr[i];
    let creditCardTypeLabel = isPersonalClient(client)
      ? getPersonalCreditCardTypeLabel(account.type ?? "")
      : getBusinessCreditCardTypeLabel(account.type ?? "") ?? "";
    let displayName = creditCardTypeLabel.concat(
      ` - ${account.cardNumber?.slice(12, 16)}`
    );
    let item: IdName = {
      id: activeAccArr[i].accountId,
      name: displayName,
    };
    creditCardOptions.push(item);
  }
  return creditCardOptions;
}

export function getCreditCardOptionsForSessionHistory(
  client: PersonalClient | BusinessClient
) {
  let activeAccArr: CreditCard[] = client.accounts
    ? client.accounts.filter((a) => a.accountType === "CREDIT_CARD")
    : [];

  let creditCardOptions: IdName[] = [];

  for (let i = 0; i < activeAccArr.length; i++) {
    let account = activeAccArr[i];
    let displayName = isPersonalClient(client)
      ? getPersonalCreditCardTypeLabel(account.type ?? "").concat(
          ` - ${account.cardNumber?.slice(12, 16)}`
        )
      : getBusinessCreditCardTypeLabel(account.type ?? "").concat(
          ` - ${account.cardNumber?.slice(12, 16)}`
        );
    let item: IdName = {
      id: activeAccArr[i].accountId,
      name: displayName,
    };
    creditCardOptions.push(item);
  }
  return creditCardOptions;
}

export function getAllAccountOptions(client: PersonalClient) {
  let accArr: Account[] = client.accounts ?? [];

  let accountOptions: IdName[] = [];

  for (let i = 0; i < accArr.length; i++) {
    let accountTypeLabel =
      accArr[i].accountType === "GIC"
        ? accArr[i].accountType
        : getAccountTypeLabel(accArr[i].accountType);
    let displayName = accountTypeLabel;
    if (accArr[i].accountType === "CREDIT_CARD") {
      let account = accArr[i] as CreditCard;
      displayName = accountTypeLabel.concat(
        ` - ${getMaskedCreditCardNumber(account.cardNumber ?? "")}`
      );
    } else {
      let account = accArr[i] as
        | PersonalSavings
        | PersonalChequing
        | GIC
        | MutualFunds
        | LineOfCredit
        | Loan
        | Mortgage;

      displayName = accountTypeLabel.concat(` - ${account.accountNumber}`);
    }
    let item: IdName = {
      id: accArr[i].accountId,
      name: displayName,
    };
    accountOptions.push(item);
  }
  let sortedAccountOptions = getSortedAccountOptionsByName(accountOptions);
  return sortedAccountOptions;
}

export function getSortedAccountOptionsByName(accountOptions: IdName[]) {
  let sortedAccountOptions = _orderBy(accountOptions, (o) => o.name);
  return sortedAccountOptions;
}

export function getOpenAccountOptionsForAccountHistory(
  client: PersonalClient | BusinessClient
) {
  let openAccArr: Account[] = getOpenAccounts(client)
    .filter((a) => a.accountType !== "GIC")
    .filter((a) => a.accountType !== "MUTUAL_FUNDS");

  let accountOptions: IdName[] = [];

  for (let i = 0; i < openAccArr.length; i++) {
    let accountTypeLabel = getAccountTypeLabel(openAccArr[i].accountType);
    let displayName = accountTypeLabel;
    if (openAccArr[i].accountType === "CREDIT_CARD") {
      let account = openAccArr[i] as CreditCard;
      displayName = accountTypeLabel.concat(
        ` - ${getMaskedCreditCardNumber(account.cardNumber ?? "")}`
      );
    } else {
      let account = openAccArr[i] as
        | PersonalSavings
        | PersonalChequing
        | LineOfCredit
        | Loan
        | Mortgage
        | BusinessChequing
        | BusinessSavings;

      displayName = accountTypeLabel.concat(` - ${account.accountNumber}`);
    }
    let item: IdName = {
      id: openAccArr[i].accountId,
      name: displayName,
    };
    accountOptions.push(item);
  }
  let sortedAccountOptions = getSortedAccountOptionsByName(accountOptions);
  return sortedAccountOptions;
}

export function getClosedAccountOptionsForAccountHistory(
  client: PersonalClient | BusinessClient
) {
  let closedAccArr: Account[] = getClosedAccounts(client)
    .filter((a) => a.accountType !== "GIC")
    .filter((a) => a.accountType !== "MUTUAL_FUNDS");

  let accountOptions: IdName[] = [];

  for (let i = 0; i < closedAccArr.length; i++) {
    let accountTypeLabel = getAccountTypeLabel(closedAccArr[i].accountType);
    let displayName = accountTypeLabel;
    if (closedAccArr[i].accountType === "CREDIT_CARD") {
      let account = closedAccArr[i] as CreditCard;
      displayName = accountTypeLabel.concat(
        ` - ${getMaskedCreditCardNumber(account.cardNumber ?? "")}`
      );
    } else {
      let account = closedAccArr[i] as
        | PersonalSavings
        | PersonalChequing
        | LineOfCredit
        | Loan
        | Mortgage
        | BusinessChequing
        | BusinessSavings;

      displayName = accountTypeLabel.concat(` - ${account.accountNumber}`);
    }
    let item: IdName = {
      id: closedAccArr[i].accountId,
      name: displayName,
    };
    accountOptions.push(item);
  }
  let sortedAccountOptions = getSortedAccountOptionsByName(accountOptions);
  return sortedAccountOptions;
}

export function getAccountOptionsForSessionHistory(
  client: PersonalClient | BusinessClient
) {
  let accArr: Account[] = client.accounts
    ? client.accounts
        .filter((a) => a.accountType !== "GIC")
        .filter((a) => a.accountType !== "MUTUAL_FUNDS")
    : [];

  let accountOptions: IdName[] = [];

  for (let i = 0; i < accArr.length; i++) {
    let accountTypeLabel = getAccountTypeLabel(accArr[i].accountType);
    let displayName = accountTypeLabel;
    if (accArr[i].accountType === "CREDIT_CARD") {
      let account = accArr[i] as CreditCard;
      displayName = isPersonalClient(client)
        ? getPersonalCreditCardTypeLabel(account.type ?? "").concat(
            ` - ${account.cardNumber?.slice(12, 16)}`
          )
        : getBusinessCreditCardTypeLabel(account.type ?? "").concat(
            ` - ${account.cardNumber?.slice(12, 16)}`
          );
    } else {
      let account = accArr[i] as
        | PersonalSavings
        | PersonalChequing
        | LineOfCredit
        | Loan
        | Mortgage
        | BusinessSavings
        | BusinessChequing;

      displayName = accountTypeLabel.concat(` - ${account.accountNumber}`);
    }
    let item: IdName = {
      id: accArr[i].accountId,
      name: displayName,
    };
    accountOptions.push(item);
  }
  let sortedAccountOptions = getSortedAccountOptionsByName(accountOptions);
  return sortedAccountOptions;
}

export function getOpenAccounts(client: PersonalClient | BusinessClient) {
  let activeAccArr: Account[] = client.accounts
    ? client.accounts.filter(
        (a) => !a.closeDate || a.closeDate === "Invalid date"
      )
    : [];
  return activeAccArr;
}

export function getClosedAccounts(client: PersonalClient | BusinessClient) {
  let closedAccArr: Account[] = client.accounts
    ? client.accounts.filter(
        (a) => a.closeDate && a.closeDate !== "Invalid date"
      )
    : [];
  return closedAccArr;
}

export function getNewGLTransaction(from: string, to: string) {
  const newGLTransaction: GLTransaction = {
    transactionType: "CASH_TRANSFER",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "Cash Transfer",
    from: from,
    to: to,
  };

  return newGLTransaction;
}

export function getNewDepositTransaction() {
  const newDepositTransaction: Deposit = {
    transactionType: "DEPOSIT",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "CSR Transaction",
    currencyType: "cad",
  };

  return newDepositTransaction;
}
export function getNewWithdrawalTransaction() {
  const newTransaction: Withdrawal = {
    transactionType: "WITHDRAWAL",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "CSR Transaction",
    currencyType: "cad",
  };

  return newTransaction;
}
export function getNewPayoutTransaction() {
  const newTransaction: PayoutCash = {
    transactionType: "PAYOUT_CASH",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "CSR Transaction",
    cash: { cashCurrencyType: "cad" },
  };

  return newTransaction;
}

export function getNewBillPaymentTransaction() {
  const newTransaction: BillPayment = {
    transactionType: "BILL_PAYMENT",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "CSR Transaction",
  };

  return newTransaction;
}

export function getNewCreditCardTransaction() {
  const newTransaction: CreditCardTransaction = {
    transactionType: "CREDIT_CARD",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "CSR Transaction",
  };

  return newTransaction;
}

export function getNewTransferTransaction() {
  const newTransaction: Transfer = {
    transactionType: "TRANSFER",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "CSR Transaction",
    currencyType: "cad",
  };

  return newTransaction;
}

export function getNewSellDraftTransaction() {
  const newTransaction: SellDraft = {
    transactionType: "SELL_DRAFT",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "CSR Transaction",
    currencyType: "cad",
    waiveFee: false,
  };

  return newTransaction;
}

export function getNewGLEntryTransaction() {
  const newTransaction: GLEntry = {
    transactionType: "GL_ENTRY",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "CSR Transaction",
  };

  return newTransaction;
}

export function getNewReceiveTransaction() {
  const newReceiveTransaction: Receive = {
    transactionType: "RECEIVE",
    transactionId: v4(),
    transactionDateTime: moment().format(),
    transactionDescription: "CSR Transaction",
    cash: { cashCurrencyType: "cad" },
    cheque: { chequeCurrencyType: "cad" },
  };
  return newReceiveTransaction;
}

export function getNewTransactionSession() {
  const newTransactionSession: TransactionSession = {
    sessionId: v4(),
    sessionBalance: 0,
    sessionCreated: moment().format(),
  };
  return newTransactionSession;
}

export function getMaskedMoneyValue(moneyValue: number) {
  let maskedMoneyValue =
    moneyValue >= 0
      ? ` $${Number(moneyValue).toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : ` -$${Number(moneyValue * -1).toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;

  return maskedMoneyValue;
}

export function checkIfUnauthorized(
  getLessonNameAndData: ReducerState<GetLessonNameAndDataReturn>,
  studentId: string | undefined
) {
  const loginInfo = getLoginInfo();

  if (
    (getLessonNameAndData.error &&
      (getLessonNameAndData.error.errorCode === 404 ||
        getLessonNameAndData.error.errorCode === 401)) ||
    (!loginInfo?.isInstructor && !!studentId)
  )
    return true;
  else return false;
}

export function highlightBackground(id: string) {
  let element = document.getElementById(id);
  if (!!element) {
    element.style.background = "rgba(25, 118, 210, 0.10)";
  }
}
export function unhighlightBackground(id: string) {
  let element = document.getElementById(id);
  if (!!element) {
    element.style.background = "";
  }
}

export function getTotalDebitAmount(txns: Transaction[]) {
  let totalDebit = txns
    .filter(
      (t) =>
        ["RECEIVE", "WITHDRAWAL"].includes(t.transactionType) ||
        (t.transactionType === "CREDIT_CARD" &&
          (t as CreditCardTransaction).type === "cashadvance") ||
        (t.transactionType === "GL_ENTRY" &&
          (t as GLEntry).glType === "debit" &&
          (t as GLEntry).clientAccount === "sessionbalance")
    )
    .map((t) => t.amount ?? 0)
    .reduce(add, 0);
  return totalDebit;
}
export function getTotalCreditAmount(txns: Transaction[]) {
  let totalCredit = txns
    .filter(
      (t) =>
        ["BILL_PAYMENT", "PAYOUT_CASH", "SELL_DRAFT", "DEPOSIT"].includes(
          t.transactionType
        ) ||
        (t.transactionType === "CREDIT_CARD" &&
          (t as CreditCardTransaction).type === "payment") ||
        (t.transactionType === "GL_ENTRY" &&
          (t as GLEntry).glType === "credit" &&
          (t as GLEntry).clientAccount === "sessionbalance")
    )
    .map((t) => t.amount ?? 0)
    .reduce(add, 0);

  return totalCredit;
}

export function getSortedClientList(
  clientList: (PersonalClient | BusinessClient)[]
) {
  return _orderBy(clientList, (l) =>
    l.information?.accountType === "Personal"
      ? l.information?.legalFirstName?.toLowerCase()
      : (l as BusinessClient).information?.operatingAs?.toLowerCase()
  );
}

export function getClientId(
  client: PersonalClient | BusinessClient | undefined
) {
  if (!client) return undefined;
  return isPersonalClient(client)
    ? (client as PersonalClient).identification?.clientId
    : (client as BusinessClient).information?.clientId;
}

export function isPersonalClient(client: PersonalClient | BusinessClient) {
  return client.information?.accountType === "Personal";
}

export function getNAITLABTotalAssets(client: PersonalClient) {
  let activeAccounts = client.accounts?.filter(
    (a) => !a.closeDate || a.closeDate.toLowerCase() === "invalid date"
  );

  const assetsAccounts = activeAccounts?.filter(
    (e) =>
      assetsAccountsTypes.includes(e.accountType) &&
      (!e.closeDate || e.closeDate.toLowerCase() === "invalid date")
  );

  const assetsBalances: number[] = assetsAccounts
    ? assetsAccounts.map((a) => {
        if (a.balance === undefined) return 0;
        else return a.balance;
      })
    : [];
  let assetsTotal: number = assetsBalances.reduce(add, 0);

  return assetsTotal;
}

export function getCreditAppTotalAssets(client: PersonalClient) {
  let naitlabAssets = getNAITLABTotalAssets(client);
  let customAssetsBalanceArr: number[] =
    client.financialProfile?.customAssets?.map((ca) => ca.balance ?? 0) ?? [];
  let customAssetsTotal = customAssetsBalanceArr.reduce(add, 0);

  let totalAssets = add(naitlabAssets, customAssetsTotal);

  return totalAssets;
}

export function getNAITLABTotalLiabilities(client: PersonalClient) {
  let activeAccounts = client.accounts?.filter(
    (a) => !a.closeDate || a.closeDate.toLowerCase() === "invalid date"
  );

  const liabilitiesAccounts = activeAccounts?.filter(
    (e) =>
      liabilitiesAccountsTypes.includes(e.accountType) &&
      (!e.closeDate || e.closeDate.toLowerCase() === "invalid date")
  );

  const liabilitiesBalances: number[] = liabilitiesAccounts
    ? liabilitiesAccounts.map((a) => {
        if (a.balance === undefined) return 0;
        else return a.balance;
      })
    : [];
  let liabilitiesTotal: number = liabilitiesBalances.reduce(add, 0);

  return liabilitiesTotal;
}

export function getCreditAppTotalLiabilities(client: PersonalClient) {
  let naitlabLiabilities = getNAITLABTotalLiabilities(client);

  let customLiabilitiesBalanceArr: number[] =
    //client.financialProfile?.customLiabilities?.map((cl) => cl.balance ?? 0) ??
    client.financialProfile?.customLiabilities
      ?.filter((cl) => !cl.alreadyIncludedInFinancialProfile)
      ?.map((cl) => cl.balance ?? 0) ?? [];
  let customLiabilitiesTotal = customLiabilitiesBalanceArr.reduce(add, 0);

  let totalLiabilities = add(naitlabLiabilities, customLiabilitiesTotal);

  return totalLiabilities;
}

export function getResidentialStatusOptionLabel(value: string) {
  return residentialStatusOptions.find((o) => o.id === value)?.name || "";
}

export function getCreditAppTDS(client: PersonalClient) {
  //TDS calculation here - TDS = (MTG or Rent + Heat + Taxes + 1/2 condo fee + consumer debt) / Monthly Income
  // as per discussion with business in Project check-in meeting dated 6Jan, 2023, need to multiply TDS calculation by 100 as it is a percentage value
  let mortgageRent = client.financialProfile?.monthlyMortgageRent ?? 0;
  let heat = client.financialProfile?.monthlyHeatingCost ?? 0;
  let taxes = client.financialProfile?.monthlyPropertyTax ?? 0;
  let condoFee = client.financialProfile?.monthlyCondoFee ?? 0;

  let consumerDebt = getMonthlyConsumerDebt(client);
  let monthlyIncome =
    !!client.financialProfile?.monthlyIncome &&
    ((client.financialProfile.monthlyIncome as unknown) as number) !== 0
      ? client.financialProfile?.monthlyIncome
      : 1;

  let jointAppMonthlyIncome = getJointAppMonthlyIncome(client) ?? 0;

  let arr = [mortgageRent, heat, taxes, condoFee / 2, consumerDebt];

  let arrSum = arr.reduce(add, 0);

  let totalMonthlyIncome = add(monthlyIncome, jointAppMonthlyIncome);

  let tds = arrSum / totalMonthlyIncome; //monthlyIncome;
  let tdsPercentage = tds * 100;
  return tdsPercentage;
}

export function getCreditAppGDS(client: PersonalClient) {
  //GDS calculation here - GDS = (MTG or Rent + Heat + Taxes + 1/2 condo fee) / Monthly Income
  // as per discussion with business in Project check-in meeting dated 6Jan, 2023, need to multiply GDS calculation by 100 as it is a percentage value
  let mortgageRent = client.financialProfile?.monthlyMortgageRent ?? 0;
  let heat = client.financialProfile?.monthlyHeatingCost ?? 0;
  let taxes = client.financialProfile?.monthlyPropertyTax ?? 0;
  let condoFee = client.financialProfile?.monthlyCondoFee ?? 0;
  let monthlyIncome =
    !!client.financialProfile?.monthlyIncome &&
    ((client.financialProfile.monthlyIncome as unknown) as number) !== 0
      ? client.financialProfile?.monthlyIncome
      : 1;
  let jointAppMonthlyIncome = getJointAppMonthlyIncome(client) ?? 0;

  let arr: number[] = [mortgageRent, heat, taxes, condoFee / 2];

  let arrSum = arr.reduce(add, 0);
  let totalMonthlyIncome = add(monthlyIncome, jointAppMonthlyIncome);

  let gds = arrSum / totalMonthlyIncome; // monthlyIncome;
  let gdsPercentage = gds * 100;
  return gdsPercentage;
}

export function creditAppHasJointApplicant(client: PersonalClient) {
  if (!!client.creditAppAccounts && client.creditAppAccounts.length > 0) {
    let creditAppAccount = client.creditAppAccounts[0];
    if (creditAppAccount.accountType === "CREDIT_CARD") return false;

    if (creditAppAccount.accountType === "LOAN") {
      let loan = creditAppAccount as CreditAppLoan;
      return !!loan.hasJointApplication;
    }
    if (creditAppAccount.accountType === "LINE_OF_CREDIT") {
      let lineOfCredit = creditAppAccount as CreditAppLOC;
      return !!lineOfCredit.hasJointApplication;
    }
    if (creditAppAccount.accountType === "MORTGAGE") {
      let mortgage = creditAppAccount as CreditAppMortgage;
      return !!mortgage.hasJointApplication;
    }
  }
  return false;
}

export function getJointAppMonthlyIncome(client: PersonalClient) {
  if (creditAppHasJointApplicant(client) === false) return 0;
  let creditAppAccount = client.creditAppAccounts?.[0];

  if (creditAppAccount?.accountType === "LOAN") {
    let loan = creditAppAccount as CreditAppLoan;
    return loan.jointApplication?.monthlyIncome;
  }
  if (creditAppAccount?.accountType === "LINE_OF_CREDIT") {
    let lineOfCredit = creditAppAccount as CreditAppLOC;
    return lineOfCredit.jointApplication?.monthlyIncome;
  }
  if (creditAppAccount?.accountType === "MORTGAGE") {
    let mortgage = creditAppAccount as CreditAppMortgage;
    return mortgage.jointApplication?.monthlyIncome;
  }

  return 0;
}

export function getMonthlyConsumerDebt(client: PersonalClient) {
  let customLiabilitiesMonthlyPaymentArrWithTDS =
    client.financialProfile?.customLiabilities
      ?.filter((c) => !c.alreadyIncludedInFinancialProfile && c.hasTDS)
      ?.map((cl) => cl.monthlyPayment ?? 0) ?? [];

  let monthlyConsumerDebt = customLiabilitiesMonthlyPaymentArrWithTDS.reduce(
    add,
    0
  );

  return monthlyConsumerDebt;
}

export function getRandomNumber(noOfDigits: number) {
  let n = Math.pow(10, noOfDigits - 1);
  let randomNumber = Math.floor(n + Math.random() * 9 * n).toString();
  return randomNumber;
}

export function getPrimeRate() {
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
  return globalSettings.primeInterestRate ?? "0";
}

export function convertUTCToLocal(utcDateString: string): string {
  return moment.utc(utcDateString).local().format("MM/DD/YYYY hh:mm A");
}

export function convertLocalToUTC(localDateString: string): string {
  return moment(localDateString).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
}

export function getSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}
