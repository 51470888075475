import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import TextFieldNonFieldEditor from "components/fields/TextFieldNonFieldEditor";
import { openSnackbar } from "components/snackbar";
import { useEffect, useState } from "react";
import { useActions } from "store";
import styled from "styled-components";
import { FieldError, SectionListItem } from "types";
import AddSectionList from "pages/admin/studentSessions/components/AddSectionList";

export type Props = {
  onAdd(selectedSections: SectionListItem[]): void;
  onCancel(): void;
};

export default function AddSectionsDialog(props: Props) {
  const [courseCode, setCourseCode] = useState<string>("");
  const [termId, setTermId] = useState<string>("");
  const [sectionList, setSectionList] = useState<SectionListItem[]>([]);
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [isLoading, setIsloading] = useState<boolean>(false);

  const studentSessionsActions = useActions().studentsessions;

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [courseCode]);

  function checkErrors() {
    let errArray: FieldError[] = [];

    if (!courseCode) {
      let err: FieldError = {
        id: `courseCode`,
        label: `Course Code`,
        error: "Is Required.",
      };
      errArray.push(err);
    }

    setErrors(errArray);
    return errArray;
  }

  function onGetSectionsClick() {
    let errorArray = checkErrors();

    if (errorArray.length === 0) {
      setIsloading(true);
      studentSessionsActions.getsections({
        courseCode: courseCode,
        termId: termId ?? "",
        onComplete: (payload) => {
          if (!!payload) {
            if (Object.keys(payload).some((k) => k === "errorCode")) {
              openSnackbar({
                severity: "error",
                message: `Error getting sections. Please try again!`,
              });
            } else {
              setSectionList(payload.length > 0 ? payload : []);
            }
          } else {
            openSnackbar({
              severity: "error",
              message: `Error getting sections. Please try again!`,
            });
          }
          setIsloading(false);
        },
      });
    }
  }

  return (
    <Dialog maxWidth="md" fullWidth open id="sectionList">
      <DialogTitle>
        <Typography>Add Sections</Typography>
      </DialogTitle>
      <DialogContent>
        <TopSection>
          <TextFieldNonFieldEditor
            id={`coursecode`}
            label={`Course Code`}
            value={courseCode}
            onChange={(v) => setCourseCode(v)}
            required={true}
            multiline={false}
            disabled={false}
            errObj={errors.find((e) => e.id === "courseCode")}
          />
          <Spacer />
          <TextFieldNonFieldEditor
            id={`termId`}
            label={`Term Id`}
            value={termId}
            onChange={(v) => setTermId(v)}
            required={false}
            multiline={false}
            disabled={false}
          />
          <Spacer />

          <Button
            style={{
              marginLeft: "2em",
              height: "36px",
              alignSelf: "center",
            }}
            disabled={isLoading}
            onClick={onGetSectionsClick}
          >
            Get Sections
          </Button>
        </TopSection>
        <AddSectionList
          sectionList={sectionList}
          onCheckboxClick={(updatedSectionListItem) => {
            let updatedSectionList = [
              ...sectionList.map((li) =>
                li.sectionId === updatedSectionListItem.sectionId
                  ? updatedSectionListItem
                  : li
              ),
            ];
            setSectionList(updatedSectionList);
          }}
          isLoading={isLoading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!sectionList.some((li) => li.isAdded === true)}
          onClick={() => {
            props.onAdd(sectionList.filter((li) => li.isAdded === true));
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const TopSection = styled.div`
  display: flex;
  //justify-content: space-between;
`;

const Spacer = styled.div`
  margin: 0 0.75em;
`;
