import FieldEditor from "components/FieldEditor";
import { CreditAppLoan, FieldError } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppLoanFieldDefinitions";
import { useEffect, useState } from "react";
import {
  requiredDateField,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import { Divider, Grid, Typography } from "@mui/material";
import AccountEditTemplate from "./AccountEditTemplate";
import WarningControl from "components/warningControl";
export type Props = {
  account: CreditAppLoan;
  isAnotherAccountExists: boolean;
  onCancel(): void;
  onAddUpdate(account: CreditAppLoan): void;
};

const CreditAppLoanForm = ({
  account,
  isAnotherAccountExists,
  onCancel,
  onAddUpdate,
}: Props) => {
  const [state, setState] = useState<CreditAppLoan>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  const [openWarningControl, setOpenWarningControl] = useState(false);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: CreditAppLoan) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.requestedAmount, state),
      ...requiredTextField(fields.paymentAmount, state),
      ...requiredPickListField(fields.loanTermLength, state),
      ...requiredTextField(fields.reason, state),
      ...requiredTextField(fields.loanSecurity, state),
      ...requiredTextField(fields.loanInterestRate, state),
      ...requiredTextField(fields.loanJointAppName, state),
      ...requiredTextField(fields.loanJointAppEmployerName, state),
      ...requiredTextField(fields.loanJointAppJobTitle, state),
      ...requiredTextField(fields.loanJointAppMonthlyIncome, state),
      ...requiredDateField(fields.loanJointAppEmploymentStartDate, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  function onAddUpdateClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (isAnotherAccountExists) {
        setOpenWarningControl(true);
      } else {
        onAddUpdate({
          ...state,
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`CREDIT_APP_LOAN_${account.accountId}_form`}
      isNew={account.requestedAmount ? false : true}
      isAnotherAccountExists={isAnotherAccountExists}
      type="Loan"
      requestedAmount={state.requestedAmount ?? 0}
      onAddUpdateClick={onAddUpdateClick}
      onCancelClick={onCancel}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppLoan>
            field={fields.requestedAmount}
            {...commonProps}
          />
          <FieldEditor<CreditAppLoan>
            field={fields.paymentAmount}
            {...commonProps}
          />

          <FieldEditor<CreditAppLoan>
            field={fields.loanTermLength}
            {...commonProps}
          />

          <FieldEditor<CreditAppLoan> field={fields.reason} {...commonProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppLoan>
            field={fields.loanType}
            {...commonProps}
          />
          {state.type === "Secured" && (
            <FieldEditor<CreditAppLoan>
              field={fields.loanSecurity}
              {...commonProps}
            />
          )}
          <FieldEditor<CreditAppLoan>
            field={fields.loanInterestType}
            {...commonProps}
          />
          {state.interestType === "Variable" && (
            <FieldEditor<CreditAppLoan>
              field={fields.loanPrimeRate}
              {...commonProps}
            />
          )}
          <FieldEditor<CreditAppLoan>
            field={fields.loanInterestRate}
            {...commonProps}
          />
          {state.interestType === "Variable" && (
            <FieldEditor<CreditAppLoan>
              field={fields.loanTotalInterestRate}
              {...commonProps}
            />
          )}

          <FieldEditor<CreditAppLoan>
            field={fields.hasJointApplication}
            {...commonProps}
          />
        </Grid>
      </Grid>
      {state.hasJointApplication && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "1em" }}>
            Joint Application Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppLoan>
                field={fields.loanJointAppName}
                {...commonProps}
              />
              <FieldEditor<CreditAppLoan>
                field={fields.loanJointAppEmployerName}
                {...commonProps}
              />
              <FieldEditor<CreditAppLoan>
                field={fields.loanJointAppJobTitle}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldEditor<CreditAppLoan>
                field={fields.loanJointAppEmploymentStartDate}
                {...commonProps}
              />
              <FieldEditor<CreditAppLoan>
                field={fields.loanJointAppMonthlyIncome}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
      {openWarningControl && (
        <WarningControl
          onCancel={() => {
            setOpenWarningControl(false);
            onCancel();
          }}
          onProceed={() => {
            onAddUpdate({
              ...state,
            });
          }}
          warningMessage={"This action will override the existing account"}
        />
      )}
    </AccountEditTemplate>
  );
};

export default CreditAppLoanForm;
