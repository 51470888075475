import {  
  FIELD_TYPE,  
  INPUT_TYPE,
  MASK_TYPE,
  PickListField,
  PayoutCash,
  TextFieldType,
  BoolField,
} from "types";

import { getTotalCashAmount } from "common/functions";
import { useReduxState } from "store";

export const cashCurrencyType: PickListField<PayoutCash> = {
  id: "cashCurrencyType",
  label: "Currency Type",
  defaultValue: "cad",
  type: FIELD_TYPE.PICKLIST,
  getValue: (transaction: PayoutCash) =>
    transaction.cash ? transaction.cash.cashCurrencyType : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, currencyType: value },
  }),
  isRequired: true,
  options: [
    { id: "cad", name: "Canadian Dollars - CAD" },
    { id: "usd", name: "US Dollars - USD" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const dollar5Bills: TextFieldType<PayoutCash> = {
  id: "dollar5Bills",
  label: "$5 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash ? (transaction.cash.dollar5Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar5Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const dollar10Bills: TextFieldType<PayoutCash> = {
  id: "dollar10Bills",
  label: "$10 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash ? (transaction.cash.dollar10Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar10Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const dollar20Bills: TextFieldType<PayoutCash> = {
  id: "dollar20Bills",
  label: "$20 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash ? (transaction.cash.dollar20Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar20Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const dollar50Bills: TextFieldType<PayoutCash> = {
  id: "dollar50Bills",
  label: "$50 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash ? (transaction.cash.dollar50Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar50Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const dollar100Bills: TextFieldType<PayoutCash> = {
  id: "dollar100Bills",
  label: "$100 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash ? (transaction.cash.dollar100Bills || "").toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar100Bills: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
};

export const cent5Coins: TextFieldType<PayoutCash> = {
  id: "cent5Coins",
  label: "$0.05 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.cent5Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, cent5Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: PayoutCash) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const cent10Coins: TextFieldType<PayoutCash> = {
  id: "cent10Coins",
  label: "$0.10 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.cent10Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, cent10Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: PayoutCash) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const cent25Coins: TextFieldType<PayoutCash> = {
  id: "cent25Coins",
  label: "$0.25 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.cent25Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, cent25Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: PayoutCash) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const dollar1Coins: TextFieldType<PayoutCash> = {
  id: "dollar1Coins",
  label: "$1.00 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.dollar1Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar1Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: PayoutCash) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const dollar2Coins: TextFieldType<PayoutCash> = {
  id: "dollar2Coins",
  label: "$2.00 x ",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    transaction.cash && transaction.cash.cashCurrencyType === "cad"
      ? (transaction.cash.dollar2Coins || "").toString()
      : "",
  setValue: (value, transaction) => ({
    ...transaction,
    cash: { ...transaction.cash, dollar2Coins: (value as unknown) as number },
  }),
  inputType: INPUT_TYPE.NUMBER,
  checkIfDisabled: (transaction: PayoutCash) => {
    return transaction.cash ? transaction.cash.cashCurrencyType === "usd" : false;
  },
};

export const totalCashAmount: TextFieldType<PayoutCash> = {
  id: "totalCashAmount",
  label: "Total",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) =>
    getTotalCashAmount(transaction.cash ?? {}).toString(),
  setValue: (value, transaction) => ({
    ...transaction,
    cash: {
      ...transaction.cash,
      totalCashAmount: (value as unknown) as number,
    },
  }),
  mask: MASK_TYPE.MONEY,
};

export const cashCADValue: TextFieldType<PayoutCash> = {
  id: "cashCADValue",
  label: "CAD Value",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) => {
    const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
   let ret =  transaction.cash?.cashCurrencyType === "usd" 
   // as per discussion with business team in testing session dated May 30, 2022, changing to usCashSellRate instead of usCashBuyRate
  ?  (getTotalCashAmount(transaction.cash ?? {}) * (globalSettings.usCashSellRate ?? 1)).toFixed(2)
  // (getTotalCashAmount(transaction.cash ?? {}) * (globalSettings.usCashBuyRate ?? 1)).toFixed(2)
  : getTotalCashAmount(transaction.cash ?? {}).toFixed(2)
return ret
},
  setValue: (value, transaction) => ({
    ...transaction,
    cash: {
      ...transaction.cash,
      cashCADValue: (value as unknown) as number,
    },
  }),
  mask: MASK_TYPE.MONEY,
};



export const totalPayoutAmount: TextFieldType<PayoutCash> = {
  id: "totalPayoutAmount",
  label: "Total",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) => transaction.cash?.cashCADValue ? transaction.cash?.cashCADValue.toFixed(2) : "",
  setValue: (value, transaction) => ({
    ...transaction,
    amount: (value as unknown) as number,
  }),
  mask: MASK_TYPE.MONEY,
}; 

export const payoutDescription: TextFieldType<PayoutCash> = {
  id: "payoutDescription",
  label: "Description",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: PayoutCash) => transaction.transactionDescription,
  setValue: (value, transaction) => ({
    ...transaction,
    transactionDescription: value,
  }),
};

export const utr: BoolField<PayoutCash> = {
  id: `utr`,
  label: "UTR",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (transaction: PayoutCash) => transaction.utr,
  setValue: (value, transaction ) => ({
    ...transaction,
    utr: value,
  }),  
};
