import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";
import TextFieldNonFieldEditor from "components/fields/TextFieldNonFieldEditor";
import { useState } from "react";
import { useActions } from "store";
import { FieldError, INPUT_TYPE } from "types";

export type Props = {
  onSubmit(overrideStatus: string): void;
  onCancel(): void;
};

export default function SupervisorOverrideDialog({
  onSubmit,
  onCancel,
}: Props) {
  const [overrideStatus, setOverrideStatus] = useState<string>("");
  const [supervisorCode, setSupervisorCode] = useState<string>("");
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const transactionsActions = useActions().transactions;

  function checkErrors() {
    let errArray: FieldError[] = [];

    if (!supervisorCode) {
      let err: FieldError = {
        id: `overrideCode`,
        label: `Instructor Override Code`,
        error: "Is Required.",
      };
      errArray.push(err);
    }

    setErrors(errArray);
    return errArray;
  }

  function validateSupervisorCode() {
    setIsValidating(true);
    transactionsActions.validateSupervisorOverride({
      overrideCode: supervisorCode,
      onComplete: (payload) => {
        if (payload.valid) {
          setIsValidating(false);
          onSubmit(overrideStatus);
        } else {
          let errArray: FieldError[] = [];
          let err: FieldError = {
            id: `overrideCode`,
            label: `Instructor Override Code`,
            error: "Incorrect Code.",
          };
          errArray.push(err);
          setErrors(errArray);
          setIsValidating(false);
        }
      },
    });
  }

  function onSubmitClick() {
    let errorArray = checkErrors();

    if (errorArray.length === 0) {
      validateSupervisorCode();
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open id="supervisorOverride_Transactions">
      <DialogTitle>
        <Typography>Supervisor Override</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PickListNonFieldEditor
              id="endofdaybalancing_supervisoroverride_overrideStatus"
              label="Override"
              value={overrideStatus}
              onChange={(v) => {
                setOverrideStatus(v);
              }}
              options={[
                { id: "approve", name: "Approve" },
                { id: "reject", name: "Reject" },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldNonFieldEditor
              id={`overrideCode`}
              label={`Supervisor Override Code`}
              value={supervisorCode}
              onChange={(v) => setSupervisorCode(v)}
              required={true}
              multiline={false}
              disabled={false}
              inputType={INPUT_TYPE.PASSWORD}
              errObj={errors.find((e) => e.id === "overrideCode")}
            />
          </Grid>
        </Grid>

        {/* <div style={{ width: 300, marginTop: "2em" }}>
            <TextFieldNonFieldEditor
              id={`overrideCode`}
              label={`Supervisor Override Code`}
              value={supervisorCode}
              onChange={(v) => setSupervisorCode(v)}
              required={true}
              multiline={false}
              disabled={false}
              inputType={INPUT_TYPE.PASSWORD}
              errObj={errors.find((e) => e.id === "overrideCode")}
            />
          </div> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          disabled={isValidating || overrideStatus === ""}
          variant="contained"
          onClick={() => {
            onSubmitClick();
          }}
        >
          {isValidating ? "Submitting" : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
