import { CSSProperties } from "@mui/material/styles/createTypography";
import { getMaskedMoneyValue } from "common/functions";
import styled from "styled-components";
import { CustomAsset } from "types";

export type Props = {
  customAsset: CustomAsset;
  style?: CSSProperties;
};

const CustomAssetDisplay = (props: Props) => {
  return (
    <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
      <AssetName>{props.customAsset.name}</AssetName>

      <AssetBalance>
        {getMaskedMoneyValue(props.customAsset.balance ?? 0)}
      </AssetBalance>
    </div>
  );
};

export default CustomAssetDisplay;

const AssetName = styled.div`
  font-weight: 500;
  margin-left: 1em;
  width: 250px;
`;

const AssetBalance = styled.div`
  color: #0000008a;
  width: 150px;
`;
