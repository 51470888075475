import MainTemplate from "components/mainTemplate/MainTemplate";
import styled from "styled-components";
import { ReactNode } from "react";
import { BusinessClient, PersonalClient } from "types";
import ClientInfoSection from "components/clientInfoTemplate/ClientInfoSection";

export type Props = {
  client: PersonalClient | BusinessClient;
  toggleMenu?: ReactNode;
  children?: ReactNode;
  title: string;
  isLoading: boolean;
  studentId?: string;
  lessonId: string;
  cashDrawerBalance?: number;
};

export default function ClientInfoTemplate(props: Props) {
  return (
    <MainTemplate
      title={props.title}
      isLoading={props.isLoading}
      studentId={props.studentId}
      cashDrawerBalance={props.cashDrawerBalance}
    >
      <div>
        {props.toggleMenu}
        <Content>
          <ClientInfoColumn>
            <ClientInfoSection
              client={props.client}
              lessonId={props.lessonId}
              studentId={props.studentId}
            />
          </ClientInfoColumn>
          {props.children}
        </Content>
      </div>
    </MainTemplate>
  );
}

const Content = styled.div`
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ClientInfoColumn = styled.div`
  min-height: 600px;
  width: 310px;
  margin: 0.5em;
`;
