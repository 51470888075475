import { FIELD_TYPE, GlobalSettings, INPUT_TYPE, MASK_TYPE, TextFieldType } from "types";

export const adminOverrideCode: TextFieldType<GlobalSettings> = {
    id: "adminOverrideCode",
    label: "Code",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) =>  settings?.adminOverrideCode,
    setValue: (value, settings) => ({
      ...settings,
      adminOverrideCode: value,
    }),
    isRequired: true, 
  };

  export const primeInterestRate: TextFieldType<GlobalSettings> = {
    id: "primeInterestRate",
    label: "Rate",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) => settings?.primeInterestRate,
    setValue: (value, settings) => ({
      ...settings,
      primeInterestRate: value,
    }),
    isRequired: true,
    mask: MASK_TYPE.PERCENTAGE 
  };
  export const usCashBuyRate: TextFieldType<GlobalSettings> = {
    id: "usCashBuyRate",
    label: "Buy Rate",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) => (settings.usCashBuyRate ?? 1).toString(),
    setValue: (value, settings) => ({
      ...settings,
      usCashBuyRate: value as unknown as number,
    }),
    isRequired: true,
    inputType: INPUT_TYPE.NUMBER,    
  };

  export const usCashSellRate: TextFieldType<GlobalSettings> = {
    id: "usCashSellRate",
    label: "Sell Rate",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) =>  (settings.usCashSellRate ?? 1).toString(),
   
    setValue: (value, settings) => ({
      ...settings,
      usCashSellRate: value as unknown as number,
    }),
    isRequired: true,
    inputType: INPUT_TYPE.NUMBER,
  };

  export const usChequeBuyRate: TextFieldType<GlobalSettings> = {
    id: "usChequeBuyRate",
    label: "Buy Rate",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) => (settings.usChequeBuyRate?? 1).toString(),    
    setValue: (value, settings) => ({
      ...settings,
      usChequeBuyRate: value as unknown as number,
    }),
    isRequired: true,
    inputType: INPUT_TYPE.NUMBER,
  };

  export const usChequeSellRate: TextFieldType<GlobalSettings> = {
    id: "usChequeSellRate",
    label: "Sell Rate",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) => (settings.usChequeSellRate ?? 1).toString(),    
    setValue: (value, settings) => ({
      ...settings,
      usChequeSellRate: value as unknown as number,
    }),
    isRequired: true,
    inputType: INPUT_TYPE.NUMBER,
  };

  export const cashDrawerStartingBalance: TextFieldType<GlobalSettings> = {
    id: "cashDrawerStartingBalance",
    label: "Starting Balance",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) => settings.cashDrawerStartingBalance?.toString(),
       
    setValue: (value, settings) => ({
      ...settings,
      cashDrawerStartingBalance: value as unknown as number,
    }),
    isRequired: true,
     mask: MASK_TYPE.MONEY    
  };

  export const cashDrawerHighThreshold: TextFieldType<GlobalSettings> = {
    id: "cashDrawerHighThreshold",
    label: "High Threshold",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) => settings?.cashDrawerHighThreshold?.toString(),
     
    setValue: (value, settings) => ({
      ...settings,
      cashDrawerHighThreshold: value as unknown as number,
    }),
    isRequired: true,
  
    mask: MASK_TYPE.MONEY    
  };

  export const cashDrawerLowThreshold: TextFieldType<GlobalSettings> = {
    id: "cashDrawerLowThreshold",
    label: "Low Threshold",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) => settings.cashDrawerLowThreshold?.toString(),
   
    
    setValue: (value, settings) => ({
      ...settings,
      cashDrawerLowThreshold: value as unknown as number,
    }),
    isRequired: true,
   
    mask: MASK_TYPE.MONEY    
  };

  export const draftOverrideDenomination: TextFieldType<GlobalSettings> = {
    id: "draftOverrideDenomination",
    label: "Override Denomination",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) => settings.draftOverrideDenomination?.toString(),
   
    
    setValue: (value, settings) => ({
      ...settings,
      draftOverrideDenomination: value as unknown as number,
    }),
    isRequired: true,
   
    mask: MASK_TYPE.MONEY    
  };

  export const generalLedgerOverrideDenomination: TextFieldType<GlobalSettings> = {
    id: "generalLedgerOverrideDenomination",
    label: "Override Denomination",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) =>  settings.generalLedgerOverrideDenomination?.toString(),
 
   
    setValue: (value, settings) => ({
      ...settings,
      generalLedgerOverrideDenomination: value as unknown as number,
    }),
    isRequired: true,
  
    mask: MASK_TYPE.MONEY  
  };



  export const chequeWarningMessageDenomination: TextFieldType<GlobalSettings> = {
    id: "chequeWarningMessageDenomination",
    label: "Hold Warning Denomination",
    type: FIELD_TYPE.TEXT,
    getValue: (settings: GlobalSettings) => settings.chequeWarningMessageDenomination?.toString(),
   
    setValue: (value, settings) => ({
      ...settings,
      chequeWarningMessageDenomination: value as unknown as number,
    }),
    isRequired: true,
    mask: MASK_TYPE.MONEY
  };