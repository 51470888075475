import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const controlName = "confirmBox";

export type ConfigProps = {
  title?: string;
  description?: string | JSX.Element;
  callback?: () => void;
  cancelCallback?: () => void;
  isAlert?: boolean;
  okBtnText?: string;
  cancelBtnText?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fullWidth?: boolean;
  overflowYVisible?: boolean;
};

export type State = {
  isOpen: boolean;
  config: ConfigProps;
};

const initialState:State = {
  isOpen: false,
  config: {
    title: undefined,
    description: undefined,
    callback: undefined,
    cancelCallback: undefined,
    isAlert: undefined,
    okBtnText: undefined,
    cancelBtnText: undefined,
    maxWidth: undefined,
    fullWidth: undefined,
    overflowYVisible: undefined,
  }
}

let slice = createSlice({
  name: controlName,
  initialState: {...initialState},
  reducers: {
    openConfirm: (
      state: State,
      action: PayloadAction<ConfigProps>
    ) => {
      let ret = {...state};

      ret.isOpen = true;
      ret.config = {...action.payload};

      return ret;

    },
    okConfirm: (
      state: State
    ) => {
      return initialState;
    },
    cancelConfirm: (
        state: State
      ) => {
        return initialState;
      },
  },
});

 const ret =  {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
    },
  },
};

export default ret;
