import { Paper } from "@mui/material";
import styled from "styled-components";
import { EndOfDayTransactionListItem } from "types";
import SummaryDetails from "pages/cashDrawer/components/SummaryDetails";

export type Props = {
  transactionsList: EndOfDayTransactionListItem[];
};

export default function TransactionsSummary(props: Props) {
  return (
    <Container>
      <Header>
        <SectionTitle> Transactions</SectionTitle>
      </Header>

      {props.transactionsList.length === 0 && (
        <NoTxnDiv>
          <div>There are no transactions</div>
        </NoTxnDiv>
      )}
      {props.transactionsList.length > 0 && (
        <SummaryDetails transactionsList={props.transactionsList} />
      )}
    </Container>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0em 0em 1em;
`;

const SectionTitle = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;
const Container = styled(Paper)`
  margin-top: 2em;
  min-height: 600px;
  min-width: inherit;
`;
const NoTxnDiv = styled.div`
  margin: 3em;
  display: flex;
  justify-content: center;
`;
