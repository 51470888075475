import { Button } from "@mui/material";
import { add } from "common/functions";
import { RejectFillIcon, WarningIcon } from "common/Icons";
import { useEffect, useState } from "react";
import { useReduxState } from "store";
import styled from "styled-components";
import {
  Account,
  BusinessChequing,
  CreditCard,
  CreditCardTransaction,
  GLEntry,
  IdName,
  LineOfCredit,
  PersonalChequing,
  SellDraft,
  TransactionSession,
  Transfer,
  Withdrawal,
} from "types";
import AccountHistory from "pages/transactions/components/AccountHistory";
import Summary from "pages/transactions/components/Summary";
import SupervisorOverrideDialog from "pages/transactions/components/SupervisorOverrideDialog";
import ToggleMenu from "pages/transactions/components/ToggleMenu";

export type Props = {
  activeTransactionSession: TransactionSession | undefined;
  updateTransactionSession(updatedSession: TransactionSession): void;
  onPostClick(): void;
  creditCardOptions: IdName[];
  activeAccounts: Account[];
  trasnactionSessions: TransactionSession[];
  accountOptions: IdName[];
  glEntryClientAccountOptions: IdName[];
  onDetailedHistoryClick(selectedAccountId: string): void;
  isReadOnlyView: boolean;
};

const SummaryAccountSection = (props: Props) => {
  useEffect(() => {
    let aIds = getAccountIdsWithOverLimitBal();
    setAccountIdsWithOverLimitBal(aIds);
    setIsAnyTxnForAccWithOverLimitBal(aIds.length > 0);
  }, [props.activeTransactionSession]);

  const depositWithdrawOptions = props.accountOptions.filter(
    (op) =>
      op.name.slice(0, 8) === "Chequing" ||
      op.name.slice(0, 7) === "Savings" ||
      op.name.slice(0, 14) === "Line Of Credit" ||
      op.name.slice(0, 4) === "Loan" ||
      op.name.slice(0, 8) === "Mortgage"
  );

  const [selectedButton, setSelectedButton] = useState<number>(0);
  const [showOverrideDialog, setShowOverrideDialog] = useState<boolean>(false);
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  const [
    isAnyTxnForAccWithOverLimitBal,
    setIsAnyTxnForAccWithOverLimitBal,
  ] = useState<boolean>(false);
  const [accountIdsWithOverLimitBal, setAccountIdsWithOverLimitBal] = useState<
    string[]
  >([]);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};

  let transactionsRequiringOverride: (SellDraft | GLEntry)[] =
    props.activeTransactionSession?.transactions?.filter(
      (t) =>
        (t.transactionType === "SELL_DRAFT" &&
          ((t as SellDraft).draftAmountCADValue ?? 0) >=
            (globalSettings.draftOverrideDenomination ?? 0) &&
          (t as SellDraft).isApprovalPending === true) ||
        (t.transactionType === "GL_ENTRY" &&
          ((t as GLEntry).amount ?? 0) >=
            (globalSettings.generalLedgerOverrideDenomination ?? 0) &&
          (t as GLEntry).isApprovalPending === true)
    ) ?? [];

  let isAnyTxnRequireOverride =
    transactionsRequiringOverride.length > 0 ? true : false;

  let overrideRejectedTxns: (SellDraft | GLEntry)[] =
    props.activeTransactionSession?.transactions?.filter(
      (t) =>
        (t.transactionType === "SELL_DRAFT" &&
          ((t as SellDraft).draftAmountCADValue ?? 0) >=
            (globalSettings.draftOverrideDenomination ?? 0) &&
          (t as SellDraft).isApprovalPending === false &&
          (t as SellDraft).isApproved === false) ||
        (t.transactionType === "GL_ENTRY" &&
          ((t as GLEntry).amount ?? 0) >=
            (globalSettings.generalLedgerOverrideDenomination ?? 0) &&
          (t as GLEntry).isApprovalPending === false &&
          (t as GLEntry).isApproved === false)
    ) ?? [];

  let isSupervisorOverrideRejected =
    overrideRejectedTxns?.length > 0 ? true : false;

  function handleSupervisorOverride(updatedSellDraftTransactions: SellDraft[]) {
    let currentTransactions = props.activeTransactionSession?.transactions;

    let updatedTransactions = currentTransactions?.map((t) => {
      let tempTxn = updatedSellDraftTransactions.find(
        (tx) => tx.transactionId === t.transactionId
      );

      if (!!tempTxn) {
        return tempTxn;
      } else return t;
    });

    let updatedSession: TransactionSession = {
      ...props.activeTransactionSession,
      transactions: updatedTransactions,
    };

    props.updateTransactionSession(updatedSession);
  }

  function getAccountIdsWithOverLimitBal() {
    let tempAccountIds: string[] = [];
    let activeCheqSavingsLOCAccounts = props.activeAccounts.filter(
      (a) =>
        a.accountType === "PERSONAL_CHEQUING" ||
        a.accountType === "PERSONAL_SAVINGS" ||
        a.accountType === "BUSINESS_CHEQUING" ||
        a.accountType === "BUSINESS_SAVINGS" ||
        a.accountType === "LINE_OF_CREDIT" ||
        a.accountType === "CREDIT_CARD"
    );

    for (let i = 0; i < activeCheqSavingsLOCAccounts.length; i++) {
      let a = activeCheqSavingsLOCAccounts[i];

      let txnAmountArr = props.activeTransactionSession?.transactions
        ? props.activeTransactionSession?.transactions
            ?.filter(
              (t) =>
                ((t.transactionType === "WITHDRAWAL" ||
                  t.transactionType === "TRANSFER") &&
                  (t as Withdrawal | Transfer).from === a.accountId) ||
                (t.transactionType === "GL_ENTRY" &&
                  (t as GLEntry).clientAccount === a.accountId &&
                  (t as GLEntry).glType === "credit") ||
                (t.transactionType === "CREDIT_CARD" &&
                  (t as CreditCardTransaction).creditCard === a.accountId &&
                  (t as CreditCardTransaction).type === "cashadvance")
            )
            .map((t) => t.amount ?? 0)
        : [];
      let txnAmountTotal = txnAmountArr?.reduce(add, 0);
      if (
        ((a.accountType === "PERSONAL_SAVINGS" ||
          a.accountType === "BUSINESS_SAVINGS" ||
          ((a.accountType === "PERSONAL_CHEQUING" ||
            a.accountType === "BUSINESS_CHEQUING") &&
            !(a as PersonalChequing | BusinessChequing).overdraftProtection)) &&
          txnAmountTotal > (a.balance ?? 0)) ||
        ((a.accountType === "PERSONAL_CHEQUING" ||
          a.accountType === "BUSINESS_CHEQUING") &&
          (a as PersonalChequing | BusinessChequing).overdraftProtection &&
          txnAmountTotal >
            add(
              a.balance ?? 0,
              (a as PersonalChequing | BusinessChequing).overdraftLimit ?? 0
            )) ||
        (a.accountType === "LINE_OF_CREDIT" &&
          add(txnAmountTotal, a.balance ?? 0) >
            ((a as LineOfCredit).limit ?? 0)) ||
        (a.accountType === "CREDIT_CARD" &&
          add(txnAmountTotal, a.balance ?? 0) > ((a as CreditCard).limit ?? 0))
      ) {
        tempAccountIds.push(a.accountId);
      }
    }

    return tempAccountIds;
  }

  return (
    <>
      <HeaderSection>
        <ColumnHeading>
          {selectedButton === 1 ? "Account History" : "Transactions Summary"}
        </ColumnHeading>
        <ToggleMenu
          onToggle={(selectedButton) => {
            setSelectedButton(selectedButton);
          }}
        />
      </HeaderSection>

      {selectedButton === 0 && (
        <>
          {isAnyTxnRequireOverride && (
            <OverrideDiv type="Warning">
              <LeftContent>
                <WarningIcon
                  fillColor="#000000"
                  style={{ paddingTop: "2px" }}
                />
                <Spacer /> <div>Supervisor Override Required</div>
              </LeftContent>
              <RightContent>
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowOverrideDialog(true);
                  }}
                  disabled={props.isReadOnlyView}
                >
                  Override
                </Button>
              </RightContent>
            </OverrideDiv>
          )}
          {!isAnyTxnRequireOverride && isSupervisorOverrideRejected && (
            <OverrideDiv type="Rejected">
              <RejectFillIcon
                fillColor="#ffffff"
                style={{ paddingTop: "3px" }}
              />
              <Spacer /> <div>Rejected Transactions</div>
            </OverrideDiv>
          )}
          {!isAnyTxnRequireOverride &&
            !isSupervisorOverrideRejected &&
            isAnyTxnForAccWithOverLimitBal && (
              <OverrideDiv type="Warning">
                <WarningIcon
                  fillColor="#000000"
                  style={{ paddingTop: "2px" }}
                />
                <Spacer /> <div>Insufficient account balance.</div>
              </OverrideDiv>
            )}
          <Summary
            activeTransactionSession={props.activeTransactionSession}
            accountIdsWithOverLimitBal={accountIdsWithOverLimitBal}
            updateTransactionSession={(updatedSession) => {
              props.updateTransactionSession(updatedSession);
            }}
            onPostClick={props.onPostClick}
            depositWithdrawTransferOptions={depositWithdrawOptions}
            creditCardOptions={props.creditCardOptions}
            glEntryClientAccountOptions={props.glEntryClientAccountOptions}
            isSupervisorOverrideRequired={isAnyTxnRequireOverride}
            isSupervisorOverrideRejected={isSupervisorOverrideRejected}
            isAnyTxnForAccWithOverLimitBal={isAnyTxnForAccWithOverLimitBal}
            isReadOnlyView={props.isReadOnlyView}
          />
          {showOverrideDialog && (
            <SupervisorOverrideDialog
              transactions={transactionsRequiringOverride ?? []}
              onCancel={() => {
                setShowOverrideDialog(false);
              }}
              onSubmit={(updatedSellDraftTransactions) => {
                handleSupervisorOverride(updatedSellDraftTransactions);
                setShowOverrideDialog(false);
              }}
            />
          )}
        </>
      )}
      {selectedButton === 1 && (
        <AccountHistory
          activeAccounts={props.activeAccounts}
          trasnactionSessions={props.trasnactionSessions}
          accountOptions={props.accountOptions}
          onDetailedHistoryClick={(selectedAccountId) => {
            props.onDetailedHistoryClick(selectedAccountId);
          }}
        />
      )}
    </>
  );
};

export default SummaryAccountSection;

const HeaderSection = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

const ColumnHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const OverrideDiv = styled.div<{ type: "Warning" | "Rejected" }>`
  display: flex;
  align-items: center;
  height: 50px;
  background-color: ${(p) => (p.type === "Warning" ? "#f2fa9b;" : "#dd0e0e;")};
  padding: 0 1em;
  margin: 1em 0 1.75em;
  color: ${(p) => (p.type === "Rejected" ? "#ffffff;" : "#000000;")};
  border-radius: 5px;
  font-weight: 500;
`;

const Spacer = styled.div`
  margin: 0.75em;
`;

const LeftContent = styled.div`
  display: flex;
  flex: 1;
`;

const RightContent = styled.div`
  text-align: right;
`;
