import { Divider } from "@mui/material";
import { getCashDetails, getMaskedMoneyValue } from "common/functions";
import { CashItem, MASK_TYPE, PayoutCash } from "types";
import SummaryRowTemplate from "pages/sessionHistory/components/SummaryRowTemplate";
import MaskedValue from "common/MaskedValue";
import styled from "styled-components";
import moment from "moment";

type SummaryRowPayoutCashProps = {
  transaction: PayoutCash;
};

export default function SummaryRowPayoutCash(props: SummaryRowPayoutCashProps) {
  const cashDetails = getCashDetails(props.transaction.cash ?? {}).filter(
    (c) => !!c.quantity && c.quantity > 0
  );

  const totalAmount = props.transaction.cash
    ? getMaskedMoneyValue(props.transaction.cash.totalCashAmount ?? 0)
    : 0;

  let details = props.transaction.utr ? "UTR" : "";

  const cadValue = props.transaction.cash
    ? getMaskedMoneyValue(props.transaction.cash.cashCADValue ?? 0)
    : 0;
  const currency = props.transaction.cash
    ? props.transaction.cash.cashCurrencyType ?? ""
    : "";

  return (
    <SummaryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transaction="Payout Cash"
      credit={`${cadValue}`}
      details={details}
    >
      {cashDetails.map((c, index) => {
        return <CashItemRow cashItem={c} key={index} currency={currency} />;
      })}
      <Divider />
      <DetailsRow isBold={true}>
        <TextSection>Total</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${totalAmount} ${currency.toUpperCase()}`}
        </TextSection>
      </DetailsRow>
      {currency === "usd" && (
        <DetailsRow style={{ marginTop: "-0.5em" }}>
          <TextSection>
            {" "}
            <Spacer />
          </TextSection>
          <TextSection style={{ textAlign: "right" }}>
            {`${cadValue} CAD`}
          </TextSection>
        </DetailsRow>
      )}
    </SummaryRowTemplate>
  );
}

function CashItemRow(props: { cashItem: CashItem; currency: string }) {
  let total = props.cashItem.num * (props.cashItem.quantity ?? 0);
  return (
    <div>
      <Divider />
      <DetailsRow>
        <TextSection>
          {MaskedValue(MASK_TYPE.MONEY, props.cashItem.num.toString())}{" "}
          {props.currency.toUpperCase()}
        </TextSection>
        <TextSection> {props.cashItem.quantity}</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {MaskedValue(MASK_TYPE.MONEY, total.toString())}{" "}
          {props.currency.toUpperCase()}
        </TextSection>
      </DetailsRow>
    </div>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
