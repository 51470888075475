import {
  getGLEntryReasonLabel,
  getGLEntryReasonOptions,
  getMaskedMoneyValue,
} from "common/functions";
import MaskedValue from "common/MaskedValue";
import moment from "moment";
import { GLEntry, GLTransaction, MASK_TYPE } from "types";
import GLAccountHistoryRowTemplate from "pages/generalLedger/components/GLAccountHistoryRowTemplate";

type GLAccountHistoryRowDebitProps = {
  balance: number;
  transaction: GLEntry | GLTransaction;
};

export default function GLAccountHistoryRowDebit(
  props: GLAccountHistoryRowDebitProps
) {
  const transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  let reason =
    props.transaction.transactionType === "CASH_TRANSFER"
      ? "Cash Drawer"
      : getGLEntryReasonOptions()
          .map((o) => o.id)
          .includes((props.transaction as GLEntry).reason ?? "")
      ? getGLEntryReasonLabel((props.transaction as GLEntry).reason ?? "")
      : (props.transaction as GLEntry).reason;

  return (
    <GLAccountHistoryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      reason={reason}
      transactionDescription={props.transaction.transactionDescription}
      debit={transactionAmount}
      balance={MaskedValue(MASK_TYPE.MONEY, props.balance.toString())}
    />
  );
}
