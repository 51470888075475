import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CustomLiabilityFieldDefinitions";
import { useEffect, useState } from "react";
import { CustomLiability, FieldError } from "types";

type Props = {
  customLiability: CustomLiability;
  onAddorUpdateClick(updatedCustomLiability: CustomLiability): void;
  onCancel(): void;
};

export default function CustomLiabilityEditor({
  customLiability,
  onAddorUpdateClick,
  onCancel,
}: Props) {
  const [state, setState] = useState<CustomLiability>(customLiability);
  const [errors, setErrors] = useState<FieldError[]>([]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: CustomLiability) => {
      setState(state);
    },
  };

  function checkNewLiabilityErrors() {
    const liabilityErrorsArray: FieldError[] = [
      ...requiredTextField(fields.name, state),
      ...requiredTextField(fields.balance, state),
      ...requiredTextField(fields.monthlyPayment, state),
    ];
    setErrors(liabilityErrorsArray);
    return liabilityErrorsArray;
  }

  useEffect(() => {
    errors.length > 0 && checkNewLiabilityErrors();
  }, [state]);

  return (
    <Dialog maxWidth={"md"} open>
      <DialogTitle>
        {!!customLiability.name ? "Edit Liability" : "Add Liability"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FieldEditor<CustomLiability>
              field={fields.name}
              {...commonProps}
            />
            <FieldEditor<CustomLiability>
              field={fields.limit}
              {...commonProps}
            />

            <FieldEditor<CustomLiability> field={fields.tds} {...commonProps} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldEditor<CustomLiability>
              field={fields.balance}
              {...commonProps}
            />
            <FieldEditor<CustomLiability>
              field={fields.monthlyPayment}
              {...commonProps}
            />
            <FieldEditor<CustomLiability>
              field={fields.alreadyIncludedInFinancialProfile}
              {...commonProps}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            const newLiabilityErrors = checkNewLiabilityErrors();
            if (newLiabilityErrors.length === 0) {
              onAddorUpdateClick({ ...state } || {});
            }
          }}
        >
          {!!customLiability.name ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
