import MainTemplate from "components/mainTemplate/MainTemplate";
import SearchTextField from "components/searchTextField/SearchTextField";
import { useEffect, useState } from "react";
import { useActions, useReduxState } from "store";
import styled from "styled-components";
import ClientList from "pages/clientSearch/components/ClientList";
import { getGeneralLedger, getLessonClientList } from "pages/lessons/helpers";
import { useHistory } from "react-router-dom";
import { UnauthorizedRoute } from "components/paths";
import {
  checkIfUnauthorized,
  getBusinessClientOperatingAs,
  getPersonalClientName,
} from "common/functions";
import { openSnackbar } from "components/snackbar";
import { BusinessClient, GeneralLedger, PersonalClient } from "types";

export type Props = {
  lessonId: string;
  studentId?: string;
};

type State = {
  searchText: string;
};

const ClientSearch = (props: Props) => {
  const {
    lessonId,
    getLessonNameAndData,
    getDataforViewAsStudent,
  } = useReduxState((e) => e.lesson);
  const lessonActions = useActions().lesson;
  const clientSearchActions = useActions().clientSearch;

  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }
  const [state, setState] = useState<State>({ searchText: "" });
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (
        !getLessonNameAndData.data?.lessonData ||
        lessonId !== ((props.lessonId as unknown) as number)
      ) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
        setIsLoading(false);
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
        setIsLoading(false);
      }
    }
  }, []);

  function deletePersonalClient(clientId: string) {
    setIsLoading(true);
    clientSearchActions.deletePersonalClient({
      clientId: clientId,
      lessonId: (props.lessonId as unknown) as number,
      onComplete: (payload) => {
        if (payload.success) {
          openSnackbar({
            severity: "success",
            message: `Personal Client deleted successfully`,
          });
          lessonActions.deletePersonalClient(clientId);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error deleting personal client. Please refresh the page and try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }

  function deleteBusinessClient(clientId: string) {
    setIsLoading(true);
    clientSearchActions.deleteBusinessClient({
      clientId: clientId,
      lessonId: (props.lessonId as unknown) as number,
      onComplete: (payload) => {
        if (payload.success) {
          openSnackbar({
            severity: "success",
            message: `Business Client deleted successfully`,
          });
          lessonActions.deleteBusinessClient(clientId);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error deleting business client. Please refresh the page and try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }

  let clientList = getLessonClientList(
    props.studentId
      ? getDataforViewAsStudent.data?.lessonData
      : getLessonNameAndData.data?.lessonData
  );

  const filteredList = clientList.filter(
    (c) =>
      (c.information?.accountType === "Personal"
        ? getPersonalClientName(c as PersonalClient)
            .toLowerCase()
            .includes(state.searchText.toLowerCase())
        : getBusinessClientOperatingAs(c as BusinessClient)
            .toLowerCase()
            .includes(state.searchText.toLowerCase())) ||
      c.information?.phoneNumber?.includes(state.searchText)
  );

  return (
    <MainTemplate
      studentId={props.studentId}
      isLoading={isLoading}
      title={"Client Search"}
      cashDrawerBalance={
        lessonId === ((props.lessonId as unknown) as number)
          ? glState.cashDrawer?.balance
          : undefined
      }
      // appBarRight={
      //   isReadOnlyStudentAccess ? (
      //     <ReadOnlyContainer>
      //       <InfoIcon />
      //       Read only mode
      //     </ReadOnlyContainer>
      //   ) : undefined
      // }
    >
      <SectionContainer>
        <SearchFieldContainer>
          <SearchTextField
            value={state.searchText}
            label="Search client name or phone number"
            onChange={(text) => setState({ ...state, searchText: text })}
          />
        </SearchFieldContainer>
        {!isLoading && (
          <ClientList
            clients={filteredList}
            studentId={props.studentId}
            onDeletePersonalClient={(clientId) => {
              deletePersonalClient(clientId);
            }}
            onDeleteBusinessClient={(clientId) => {
              deleteBusinessClient(clientId);
            }}
          />
        )}
      </SectionContainer>
    </MainTemplate>
  );
};

export default ClientSearch;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2em;
  min-height: 600px;
`;

const SearchFieldContainer = styled.div`
  padding: 1em 0.25em;
  width: 500px;
  margin: 2em 0em;
`;
// const ReadOnlyContainer = styled.div`
//   margin-right: 1em;
//   color: #ffa500;
//   display: flex;
//   gap: 0.5em;
// `;
