import { AddressField, CommonFieldProps, Entity } from "types";
import { FieldContainer } from "./commonFieldStyles";
import {
  //Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { DatePicker } from "@mui/lab";
import { scrubPCValue } from "common/functions";
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 250,
    },
  },
};

export default function AddressEditor<T extends Entity>(
  props: CommonFieldProps<T> & { isDependentlyRequired?: boolean }
) {
  if (props.field.multiColumnDisplay) {
    return (
      <FieldContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Column1Editor {...props} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Column2Editor {...props} />
          </Grid>
        </Grid>
      </FieldContainer>
    );
  }

  return (
    <FieldContainer>
      <Column1Editor {...props} />
      <Column2Editor {...props} />
    </FieldContainer>
  );
}

function Column1Editor<T extends Entity>(
  props: CommonFieldProps<T> & { isDependentlyRequired?: boolean }
) {
  const { field, state, setState, errors } = props;
  const addressField = field as AddressField<T>;

  const startDateErrObj = errors.find((e) => e.id === `${field.id}_startDate`);
  const addressErrObj = errors.find((e) => e.id === `${field.id}_address`);
  const cityErrObj = errors.find((e) => e.id === `${field.id}_city`);
  const provinceErrObj = errors.find((e) => e.id === `${field.id}_province`);

  return (
    <div>
      {field.isDated && (
        <DatePicker
          label="Address Start Date"
          value={addressField.getValue(state)?.startDate ?? null}
          disabled={field.disabled}
          onChange={(newValue) => {
            const myState = addressField.getValue(state);
            setState(
              addressField.setValue(
                {
                  ...myState,
                  startDate: moment(newValue).format("MM/DD/YYYY").toString(),
                },
                state
              )
            );
          }}
          renderInput={(params) => (
            <FieldContainer>
              <TextField
                {...params}
                id={`${field.id}_startDate`}
                variant="filled"
                fullWidth
                required={
                  field.isRequired ||
                  (field.checkIfRequired && field.checkIfRequired(state)) ||
                  (props.isDependentlyRequired &&
                    props.isDependentlyRequired === true)
                }
                error={!!startDateErrObj}
                helperText={!!startDateErrObj && startDateErrObj.error}
                size="small"
              />
            </FieldContainer>
          )}
        />
      )}
      <TextField
        id={`${field.id}_address`}
        label="Address"
        variant="filled"
        value={addressField.getValue(state)?.address ?? ""}
        onChange={(e) => {
          const myState = addressField.getValue(state);
          setState(
            addressField.setValue(
              { ...myState, address: e.target.value },
              state
            )
          );
        }}
        required={
          field.isRequired ||
          (field.checkIfRequired && field.checkIfRequired(state)) ||
          (props.isDependentlyRequired && props.isDependentlyRequired === true)
        }
        disabled={field.disabled}
        error={!!addressErrObj}
        helperText={!!addressErrObj && addressErrObj.error}
        fullWidth
        size="small"
      />
      <TextField
        id={`${field.id}_city`}
        style={{ marginTop: "1em" }}
        label="City"
        variant="filled"
        value={addressField.getValue(state)?.city ?? ""}
        onChange={(e) => {
          const myState = addressField.getValue(state);
          setState(
            addressField.setValue({ ...myState, city: e.target.value }, state)
          );
        }}
        required={
          field.isRequired ||
          (field.checkIfRequired && field.checkIfRequired(state)) ||
          (props.isDependentlyRequired && props.isDependentlyRequired === true)
        }
        disabled={field.disabled}
        error={!!cityErrObj}
        helperText={!!cityErrObj && cityErrObj.error}
        fullWidth
        size="small"
      />
      <FormControl
        fullWidth
        style={{ marginTop: "1em" }}
        variant="filled"
        required={
          field.isRequired ||
          (field.checkIfRequired && field.checkIfRequired(state)) ||
          (props.isDependentlyRequired && props.isDependentlyRequired === true)
        }
        disabled={field.disabled}
        size="small"
      >
        <InputLabel
          id="demo-simple-select-label"
          style={!!provinceErrObj ? { color: "#d32f2f" } : {}}
        >
          Province
        </InputLabel>
        <Select
          labelId={`${field.id}_label`}
          id={`${field.id}_province`}
          value={addressField.getValue(state)?.province ?? ""}
          label={field.label}
          error={!!provinceErrObj}
          onChange={(e) => {
            const myState = addressField.getValue(state);
            setState(
              addressField.setValue(
                { ...myState, province: e.target.value },
                state
              )
            );
          }}
          MenuProps={MenuProps}
        >
          {addressField.options?.map((province, n) => (
            <MenuItem key={`option_${n}`} value={province.id}>
              {province.name}
            </MenuItem>
          ))}
        </Select>
        {!!provinceErrObj && (
          <FormHelperText style={{ color: "#d32f2f" }}>
            {provinceErrObj.error}
          </FormHelperText>
        )}
      </FormControl>
      {/* following code is for autocomplete pickuplist. Its not 100% complete. Leaving it here for now, it might be implemented in future */}
      {/* <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={provinceOptions}
        ListboxProps={{ style: { maxHeight: 225 } }}
        //sx={{ width: 300 }}

        renderInput={(params) => (
          <FieldContainer>
            <TextField
              {...params}
              id={`${field.id}_province`}
              value={addressField.getValue(state)?.province ?? ""}
              variant="filled"
              label="Province"
              error={!!provinceErrObj}
              onChange={(e) => {
                const myState = addressField.getValue(state);
                setState(
                  addressField.setValue(
                    { ...myState, province: e.target.value },
                    state
                  )
                );
              }}
              required={
                field.isRequired ||
                (field.checkIfRequired && field.checkIfRequired(state)) ||
                (props.isDependentlyRequired &&
                  props.isDependentlyRequired === true)
              }
              helperText={!!provinceErrObj && provinceErrObj.error}
              disabled={field.disabled}
              size="small"
            />
          </FieldContainer>
        )}
      /> */}
    </div>
  );
}

function Column2Editor<T extends Entity>(
  props: CommonFieldProps<T> & { isDependentlyRequired?: boolean }
) {
  const { field, state, setState, errors } = props;
  const addressField = field as AddressField<T>;

  const postalCodeErrObj = errors.find(
    (e) => e.id === `${field.id}_postalCode`
  );

  return (
    <div>
      <TextField
        id={`${field.id}_postalCode`}
        style={{ marginTop: "1em" }}
        label="Postal Code"
        variant="filled"
        value={addressField.getValue(state)?.postalCode ?? ""}
        onChange={(e) => {
          const input = e.target.value.toUpperCase();
          const myState = addressField.getValue(state);
          const scrubed = scrubPCValue(input, myState?.postalCode);
          setState(
            addressField.setValue({ ...myState, postalCode: scrubed }, state)
          );
        }}
        required={
          field.isRequired ||
          (field.checkIfRequired && field.checkIfRequired(state)) ||
          (props.isDependentlyRequired && props.isDependentlyRequired === true)
        }
        disabled={field.disabled}
        error={!!postalCodeErrObj}
        helperText={!!postalCodeErrObj && postalCodeErrObj.error}
        fullWidth
        size="small"
      />
      <TextField
        id={`${field.id}_country`}
        style={{ marginTop: "1em" }}
        label="Country"
        variant="filled"
        value={addressField.getValue(state)?.country ?? "Canada"}
        onChange={(e) => {
          const myState = addressField.getValue(state);
          setState(
            addressField.setValue(
              { ...myState, country: e.target.value ?? "Canada" },
              state
            )
          );
        }}
        disabled={true}
        fullWidth
        size="small"
      />
    </div>
  );
}
