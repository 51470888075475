import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import {
  CreditAppAccount,
  CreditAppCreditCard,
  CreditAppLoan,
  CreditAppLOC,
  CreditAppMortgage,
  PersonalClient,
} from "types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";

import AccountDetails from "./AccountDetails";
import { useState } from "react";
import EditCreditAppAccountDialog from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/EditCreditAppAccountDialog";
import moment from "moment";
import { v4 } from "uuid";
import AddAccountsMenu from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/AddAccountsMenu";

type AccountsSectionProps = {
  client: PersonalClient;
  isLoading: boolean;
  isReadOnlyView: boolean;
  onSaveAccount: (creditAppAccount: CreditAppAccount) => void;
  onDeleteAccount: (creditAppAccount: CreditAppAccount) => void;
};

const AccountsSection = (props: AccountsSectionProps) => {
  const [accountInEdit, setAccountInEdit] = useState<CreditAppAccount>();

  function addCreditAppCreditCard() {
    const newAccount: CreditAppCreditCard = {
      accountId: v4(),
      accountType: "CREDIT_CARD",
      created: moment().format(),
    };
    setAccountInEdit(newAccount);
  }
  function addCreditAppLoan() {
    const newAccount: CreditAppLoan = {
      accountId: v4(),
      accountType: "LOAN",
      type: "Secured",
      interestType: "Fixed",
      created: moment().format(),
      hasJointApplication: false,
    };
    setAccountInEdit(newAccount);
  }

  function addCreditAppLOC() {
    const newAccount: CreditAppLOC = {
      accountId: v4(),
      accountType: "LINE_OF_CREDIT",
      interestType: "Variable",
      created: moment().format(),
      hasJointApplication: false,
    };
    setAccountInEdit(newAccount);
  }
  function addCreditAppMortgage() {
    const newAccount: CreditAppMortgage = {
      accountId: v4(),
      accountType: "MORTGAGE",
      type: "purchasing",
      mortgageType: "Open",
      interestType: "Fixed",
      title: "Freehold",
      created: moment().format(),
      cmhc: false,
      hasJointApplication: false,
    };
    setAccountInEdit(newAccount);
  }

  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="financialprofile-header"
        >
          <Typography variant={"h6"}>Account Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MenuContainer>
            <AddAccountsMenu
              onAddCreditCard={addCreditAppCreditCard}
              onAddLOC={addCreditAppLOC}
              onAddLoan={addCreditAppLoan}
              onAddMortgage={addCreditAppMortgage}
              isReadOnlyView={props.isReadOnlyView}
            />
          </MenuContainer>
          <AccountDetails
            creditApps={props.client.creditAppAccounts ?? []}
            onEdit={(account) => setAccountInEdit(account)}
            onDelete={(account) => {
              props.onDeleteAccount(account);
            }}
            isReadOnlyView={props.isReadOnlyView}
          />
        </AccordionDetails>
      </Accordion>
      {!!accountInEdit && (
        <EditCreditAppAccountDialog
          account={accountInEdit}
          isAnotherAccountExists={
            (props.client.creditAppAccounts &&
              props.client.creditAppAccounts?.filter(
                (cAcc) => cAcc.accountId !== accountInEdit.accountId
              ).length > 0) ??
            false
          }
          onSave={(account) => {
            props.onSaveAccount(account);
            setAccountInEdit(undefined);
          }}
          onCancel={() => {
            setAccountInEdit(undefined);
          }}
        />
      )}
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  margin-top: 2em;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-bottom: 1em;
`;

export default AccountsSection;
