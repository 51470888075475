//The commented items show how to include ducks
import businessClientInformation from "./businessClientInformation/businessClientInformation.duck";

const index = {
  reducer: {
    ...businessClientInformation.reducer,
  },
  actions: {
    ...businessClientInformation.actions,
  },
};

export default index;
