import { TextField } from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import { useEffect, useState } from "react";
import styled from "styled-components";

export type Props = {
  billType: BillType;
  initialQuantity: number;
  isDependentlyDisabled?: boolean;
  onValueChange(quantity: number): void;
};
export type BillType =
  | "CAD100Dollar"
  | "CAD50Dollar"
  | "CAD20Dollar"
  | "CAD10Dollar"
  | "CAD5Dollar"
  | "CAD2Coin"
  | "CAD1Coin"
  | "CAD25Coin"
  | "CAD10Coin"
  | "CAD5Coin"
  | "USD100Dollar"
  | "USD50Dollar"
  | "USD20Dollar"
  | "USD10Dollar"
  | "USD5Dollar"
  | "USD2Dollar"
  | "USD1Dollar";

export default function CashInputControl({
  billType,
  initialQuantity,
  isDependentlyDisabled,
  onValueChange,
}: Props) {
  useEffect(() => {
    setState(initialQuantity);
  }, [initialQuantity]);

  const [state, setState] = useState(initialQuantity);

  const denomination = BILL_INFO[billType].denomination;
  const isCoin = BILL_INFO[billType].isCoin;

  function textChange(sNum: string) {
    setNewValue(parseInt(sNum));
  }

  function setNewValue(value: number) {
    if (value === state) {
      return;
    }
    const newValue = value < 0 ? 0 : value;

    setState(newValue);
    onValueChange(newValue);
  }

  const total = getMaskedMoneyValue(denomination * state);
  return (
    <Container>
      {isCoin ? <Coin billType={billType} /> : <Bill billType={billType} />}
      <TextFieldHolder>
        <TextField
          hiddenLabel
          id={`textfield_${billType}`}
          value={state === 0 ? "" : state}
          onChange={(e) => textChange(e.target.value)}
          variant="outlined"
          size="small"
          type="number"
          fullWidth
          InputProps={{
            inputProps: {
              style: { textAlign: "right", backgroundColor: "#fff" },
            },
          }}
          disabled={isDependentlyDisabled && isDependentlyDisabled === true}
        />
      </TextFieldHolder>
      <TotalDiv>{state > 0 && total}</TotalDiv>
    </Container>
  );
}

function Bill({ billType }: { billType: BillType }) {
  let currencyType = BILL_INFO[billType].currency;
  return (
    <BillDiv color={BILL_INFO[billType].color}>
      {currencyType === "USD" && (
        <StarHolder>
          <USStar />
        </StarHolder>
      )}
      {currencyType === "CAD" && (
        <FlagHolder>
          <CanadaFlag />
        </FlagHolder>
      )}
      <AmountHolder>{BILL_INFO[billType].label}</AmountHolder>
    </BillDiv>
  );
}

function Coin({ billType }: { billType: BillType }) {
  const { denomination, color, label } = BILL_INFO[billType];
  const isLarge = denomination >= 1;
  const borderColor = denomination === 1 ? "#ffd54f" : "#ccc";
  return (
    <CoinDiv color={color} isLarge={isLarge} borderColor={borderColor}>
      {label}
    </CoinDiv>
  );
}
const BILL_INFO: Record<
  BillType,
  {
    color: string;
    denomination: number;
    isCoin: boolean;
    currency: "CAD" | "USD";
    label: string;
  }
> = {
  CAD5Coin: {
    color: "#b0bec5",
    denomination: 0.05,
    isCoin: true,
    currency: "CAD",
    label: "5",
  },
  CAD10Coin: {
    color: "#b0bec5",
    denomination: 0.1,
    isCoin: true,
    currency: "CAD",
    label: "10",
  },
  CAD25Coin: {
    color: "#b0bec5",
    denomination: 0.25,
    currency: "CAD",
    isCoin: true,
    label: "25",
  },
  CAD1Coin: {
    color: "#ffd54f",
    denomination: 1,
    isCoin: true,
    currency: "CAD",
    label: "$1",
  },
  CAD2Coin: {
    color: "#ffd54f",
    denomination: 2,
    isCoin: true,
    currency: "CAD",
    label: "$2",
  },
  CAD5Dollar: {
    color: "#1e88e5",
    denomination: 5,
    isCoin: false,
    currency: "CAD",
    label: "5",
  },
  CAD10Dollar: {
    color: "#673ab7",
    denomination: 10,
    isCoin: false,
    currency: "CAD",
    label: "10",
  },
  CAD20Dollar: {
    color: "#43a047",
    denomination: 20,
    isCoin: false,
    currency: "CAD",
    label: "20",
  },
  CAD50Dollar: {
    color: "#f44336",
    denomination: 50,
    isCoin: false,
    currency: "CAD",
    label: "50",
  },
  CAD100Dollar: {
    color: "#f9a825",
    denomination: 100,
    isCoin: false,
    currency: "CAD",
    label: "100",
  },
  USD100Dollar: {
    color: "#43a047",
    denomination: 100,
    isCoin: false,
    currency: "USD",
    label: "100",
  },
  USD50Dollar: {
    color: "#43a047",
    denomination: 50,
    isCoin: false,
    currency: "USD",
    label: "50",
  },
  USD20Dollar: {
    color: "#43a047",
    denomination: 20,
    isCoin: false,
    currency: "USD",
    label: "20",
  },
  USD10Dollar: {
    color: "#43a047",
    denomination: 10,
    isCoin: false,
    currency: "USD",
    label: "10",
  },
  USD5Dollar: {
    color: "#43a047",
    denomination: 5,
    isCoin: false,
    currency: "USD",
    label: "5",
  },
  USD2Dollar: {
    color: "#43a047",
    denomination: 2,
    isCoin: false,
    currency: "USD",
    label: "2",
  },
  USD1Dollar: {
    color: "#43a047",
    denomination: 1,
    isCoin: false,
    currency: "USD",
    label: "1",
  },
};

const TotalDiv = styled.div`
  padding-left: 20px;
  flex: 1;
  text-align: right;
  color: #555;
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  background-color: #f0f0f0;
  padding: 5px 10px;
  margin: 3px;
`;

const TextFieldHolder = styled.div`
  width: 80px;
  padding-left: 10px;
`;

const BillDiv = styled.div<{ color: string }>`
  background-color: ${(p) => p.color};
  padding: 2px;
  display: flex;
  width: 50px;
  align-items: center;
`;
const CoinDiv = styled.div<{
  color: string;
  isLarge: boolean;
  borderColor: string;
}>`
  background-color: ${(p) => p.color};
  padding: 0px;
  display: block;
  width: ${(p) => (p.isLarge ? "25px" : "23px")};
  height: ${(p) => (p.isLarge ? "25px" : "23px")};
  margin: ${(p) => (p.isLarge ? "0 15px 0 14px" : "0 16px 0 15px")};
  text-align: center;
  border-radius: 13px;
  box-sizing: border-box;
  color: #333;
  font-size: ${(p) => (p.isLarge ? "16px" : "13px")};
  font-weight: ${(p) => (p.isLarge ? "600" : "600")};
  padding-top: 2px;
  outline: 3px solid ${(p) => p.borderColor};
`;
const FlagHolder = styled.div`
  width: 20px;
`;
const StarHolder = styled.div`
  width: 20px;
  padding-top: 2px;
`;
const AmountHolder = styled.div`
  flex: 1;
  text-align: right;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding-right: 3px;
`;

function CanadaFlag() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 1133.000000 1280.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
        fill="#ffffff"
        stroke="none"
      >
        <path
          d="M5604 12679 c-33 -63 -258 -500 -500 -973 -321 -628 -450 -871 -481
  -906 -59 -66 -126 -94 -213 -87 -36 2 -91 14 -122 25 -41 15 -681 353 -832
  440 -14 8 -19 37 375 -2133 145 -798 162 -902 163 -1005 1 -104 -2 -120 -24
  -164 -14 -27 -43 -63 -64 -80 -33 -27 -45 -31 -96 -30 -67 1 -144 34 -200 86
  -19 18 -321 368 -670 777 -515 603 -636 740 -642 725 -4 -11 -53 -185 -108
  -389 -68 -247 -109 -382 -126 -407 -30 -47 -89 -85 -153 -99 -57 -12 -2 -23
  -974 191 -379 84 -690 150 -692 148 -3 -2 88 -355 201 -784 271 -1027 284
  -1083 284 -1166 0 -112 -31 -150 -184 -222 -50 -24 -194 -94 -319 -155 l-228
  -112 1338 -1135 c736 -625 1368 -1166 1404 -1203 82 -84 110 -150 112 -261 1
  -68 -10 -113 -121 -492 -67 -229 -122 -418 -122 -421 0 -3 15 -4 33 -1 17 2
  238 29 490 59 537 65 1206 145 1790 216 411 50 418 50 468 34 58 -20 134 -86
  144 -127 4 -15 -21 -690 -54 -1500 -34 -810 -61 -1485 -61 -1500 l0 -28 225 0
  225 0 0 78 c0 42 -16 720 -35 1505 l-35 1429 20 39 c24 46 87 95 141 109 30 8
  131 -1 496 -45 688 -83 1227 -148 1738 -209 253 -31 475 -58 493 -60 17 -3 32
  -2 32 1 0 3 -55 192 -122 421 -111 379 -122 424 -121 492 2 111 30 177 112
  261 36 37 668 578 1404 1203 l1338 1135 -228 112 c-125 61 -269 131 -319 155
  -153 72 -184 110 -184 222 0 83 13 139 284 1166 113 429 204 782 201 784 -2 2
  -313 -64 -692 -148 -972 -214 -917 -203 -974 -191 -64 14 -123 52 -153 99 -17
  25 -58 160 -126 407 -55 204 -104 378 -108 389 -6 15 -127 -122 -642 -725
  -349 -409 -651 -759 -670 -777 -56 -52 -133 -85 -200 -86 -51 -1 -63 3 -96 30
  -21 17 -50 53 -64 80 -22 44 -25 60 -24 164 1 103 18 207 163 1005 394 2170
  389 2141 375 2133 -151 -87 -791 -425 -832 -440 -31 -11 -86 -23 -122 -25 -87
  -7 -154 21 -213 87 -31 35 -171 300 -532 1005 -269 526 -495 964 -501 972 -9
  13 -22 -6 -70 -98z"
        />
      </g>
    </svg>
  );
}

function USStar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="#ffffff"
    >
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.326 18.266l-4.326-2.314-4.326 2.313.863-4.829-3.537-3.399 4.86-.671 2.14-4.415 2.14 4.415 4.86.671-3.537 3.4.863 4.829z" />
    </svg>
  );
}
