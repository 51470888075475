import { Grid } from "@mui/material";
import {
  getBusinessOverdraftTypeLabel,
  getMaskedMoneyValue,
} from "common/functions";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessChequingFieldDefinitions";
import { BusinessChequing } from "types";
import AccountDisplayTemplate from "pages/clientPortfolio/businessClient/businessClientAccounts/components/AccountDisplayTemplate";
export type Props = {
  account: BusinessChequing;
  onEditClick(): void;
  onDeleteClick(): void;
  onAccountHistoryClick(): void;
  isReadOnlyView: boolean;
};

const BusinessChequingDisplay = ({
  account,
  onEditClick,
  onDeleteClick,
  onAccountHistoryClick,
  isReadOnlyView,
}: Props) => {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`BUSINESS_CHEQUING_${account.accountId}_header`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      onAccountHistoryClick={onAccountHistoryClick}
      accountType="Chequing"
      type={
        account.overdraftProtection
          ? account.overdraftType
            ? `${getBusinessOverdraftTypeLabel(
                account.overdraftType
              )} - ${getMaskedMoneyValue(account.overdraftLimit ?? 0)}`
            : `ODP - ${getMaskedMoneyValue(account.overdraftLimit ?? 0)}`
          : ""
      }
      accountNumber={account.accountNumber || ""}
      balance={account.balance || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldDisplay<BusinessChequing>
            field={fields.businessChequingAccounNumber}
            {...commonProps}
          />
          <FieldDisplay<BusinessChequing>
            field={fields.businessChequingBalance}
            {...commonProps}
          />

          <FieldDisplay<BusinessChequing>
            field={fields.businessChequingAvailableFunds}
            {...commonProps}
          />
          <FieldDisplay<BusinessChequing>
            field={fields.businessChequingOverdraftProtection}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<BusinessChequing>
            field={fields.businessChequingOpenDate}
            {...commonProps}
          />
          <FieldDisplay<BusinessChequing>
            field={fields.businessChequingCloseDate}
            {...commonProps}
          />
          {account.overdraftProtection && (
            <FieldDisplay<BusinessChequing>
              field={fields.businessChequingOverdraftType}
              {...commonProps}
            />
          )}
          <FieldDisplay<BusinessChequing>
            field={fields.businessChequingODPLimit}
            {...commonProps}
          />
        </Grid>
      </Grid>
    </AccountDisplayTemplate>
  );
};

export default BusinessChequingDisplay;
