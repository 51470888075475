import styled from "styled-components";

export type Props = { whitehoverback?: boolean };

const IconButton = styled.button<{ whitehoverback?: boolean }>`
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  position: relative;
  align-items: center;
  user-select: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  color: inherit;
  font-weight: 400 !important;
  &:hover {
    background-color: ${(p) =>
      p.whitehoverback ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)"};
  }
`;

export default IconButton;
