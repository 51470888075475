import { getSavingsTypeOptions } from "common/functions";
import { DateField, MASK_TYPE, PickListField } from "types";
import { FIELD_TYPE, PersonalSavings, TextFieldType } from "types";
import { v4 } from "uuid"


export const personalSavingsOpenDate: DateField<PersonalSavings> = {
  id: `personalSavingsOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: PersonalSavings) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: PersonalSavings ) => {   
  return account.isAccountClosed ?? false
}
};

export const personalSavingsCloseDate: DateField<PersonalSavings> = {
  id: `personalSavingsCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: PersonalSavings) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const personalSavingsAccounNumber: TextFieldType<PersonalSavings> = {
  id: `personalSavingsAccounNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: PersonalSavings) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true
};

export const personalSavingsBalance: TextFieldType<PersonalSavings> = {
  id: `personalSavingsBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: PersonalSavings) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    accounts: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
};

export const personalSavingsType: PickListField<PersonalSavings> = {
  id: `personalSavingsType_${v4()}`,
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: PersonalSavings) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
  }),
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  options: getSavingsTypeOptions(),
  checkIfDisabled: (account: PersonalSavings) => {   
    return account.isAccountClosed ?? false
  }
};