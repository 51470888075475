import { Button, Typography } from "@mui/material";
import { AppBar } from "@nait-aits/ui";
import { getLoginInfo } from "common/azureAD";
import { LessonsRoute } from "components/paths";
import UserInfo from "components/user/UserInfo";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import HomeIcon from "@mui/icons-material/Home";

export type Props = {};

export default function NoContent(props: Props) {
  const loginInfo = getLoginInfo();
  const history = useHistory();
  return (
    <OuterContainer>
      <AppBar
        headingText="NAITLAB Financial"
        rightContent={<UserInfo {...loginInfo} />}
        isLoading={false}
      />
      <SectionContainer>
        <div style={{ marginTop: "15em" }}>
          <Typography variant="h4">
            Invalid request. No content found for requested resource
          </Typography>
        </div>
        <div style={{ marginTop: "5em" }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              history.push(LessonsRoute);
            }}
          >
            <HomeIcon style={{ marginRight: 5 }} />
            Go To Lessons
          </Button>
        </div>
      </SectionContainer>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  background-color: #f3f6f9;
  min-height: 100vh;
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2em;
  min-height: 600px;
`;
