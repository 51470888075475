import { DisplayFieldProps, Entity, PickListField } from "types";
import DisplayFieldTemplate from "./DisplayFieldTemplate";
import NotSetDisplay from "./NotSetDisplay";
export default function PickListDisplay<T extends Entity>({
  field,
  state,
}: DisplayFieldProps<T>) {
  const pickListField = field as PickListField<T>;
  const value = pickListField.getValue(state) || "";
  const options = pickListField.options || [];
  const optionLabel =
    pickListField.getOptionLabel &&
    pickListField.getOptionLabel(value, options);

  return (
    <DisplayFieldTemplate label={pickListField.label}>
      {optionLabel || <NotSetDisplay />}
    </DisplayFieldTemplate>
  );
}
