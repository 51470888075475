import { Dialog, DialogContent } from "@mui/material";
import {
  Account,
  CreditCardTransaction,
  GLEntry,
  Transaction,
  Transfer,
} from "types";

import DepositForm from "pages/accountHistory/components/DepositForm";
import WithdrawalForm from "pages/accountHistory/components/WithdrawalForm";

export type Props = {
  transaction?: Transaction;
  account: Account | undefined;
  onSave(transaction: Transaction, recurringMonths?: number): void;
  onCancel(): void;
};

export default function EditTransactionDialog({
  transaction,
  account,
  onSave,
  onCancel,
}: Props) {
  if (!transaction) {
    return <></>;
  }

  if (
    transaction.transactionType === "DEPOSIT" ||
    (transaction.transactionType === "CREDIT_CARD" &&
      (transaction as CreditCardTransaction).type === "payment") ||
    (transaction.transactionType === "TRANSFER" &&
      !!account &&
      (transaction as Transfer).to === account.accountId) ||
    (transaction.transactionType === "GL_ENTRY" &&
      (transaction as GLEntry).glType === "debit" &&
      (transaction as GLEntry).clientAccount === account?.accountId)
  ) {
    return (
      <DepositForm
        transaction={transaction}
        account={account}
        onSave={(transaction, recurringMonths) => {
          onSave(transaction, recurringMonths);
        }}
        onCancel={onCancel}
      />
    );
  }
  if (
    transaction.transactionType === "WITHDRAWAL" ||
    (transaction.transactionType === "CREDIT_CARD" &&
      (transaction as CreditCardTransaction).type === "cashadvance") ||
    (transaction.transactionType === "TRANSFER" &&
      !!account &&
      (transaction as Transfer).from === account.accountId) ||
    (transaction.transactionType === "GL_ENTRY" &&
      (transaction as GLEntry).glType === "credit" &&
      (transaction as GLEntry).clientAccount === account?.accountId)
  ) {
    return (
      <WithdrawalForm
        transaction={transaction}
        account={account}
        onSave={(transaction, recurringMonths) => {
          onSave(transaction, recurringMonths);
        }}
        onCancel={onCancel}
      />
    );
  }

  return (
    <Dialog open onClose={() => {}}>
      <DialogContent>Unknown Account Type</DialogContent>
    </Dialog>
  );
}
