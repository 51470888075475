import { provinceOptions } from "common/helpers";
import {AddressField, AddressType, FIELD_TYPE,} from "types";

export const address: AddressField<AddressType> = {
  id: "address",
  label: "Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (fullAddress: AddressType) => fullAddress,
     setValue: (value, fullAddress) => ({
       ...value,
     }),
  options: provinceOptions,
  isDated: true,
multiColumnDisplay: true,
  isRequired:true
};