import { add, getPrimeRate } from "common/functions";
import { provinceOptions } from "common/helpers";
import {
  AddressField,
  BoolField,
  CreditAppLOC,
  DateField,
  FIELD_TYPE,
  INPUT_TYPE,
  MASK_TYPE,
  PickListField,
  RadioGroupField,
  TextFieldType,
} from "types";
import { v4 } from "uuid";

export const locRequestedAmount: TextFieldType<CreditAppLOC> = {
  id: `locRequestedAmount_${v4()}`,
  label: "Requested Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.requestedAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    requestedAmount: (value as unknown) as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

//as per dscussion with business in Project  check-in meeting dated Nov04,2022,
//value for minimum payment needts to be calculated by the students

// function getPercentValue(percent: number, balance: number){
//   const val = (percent * balance)/100
//   return val
// }

// function getMinimumPayment(requestedAmount: number, type: string)
// {
//   let minPaymentValue = type.toLowerCase() === "uloc" ? getPercentValue(3, requestedAmount ?? 0) :
//   ["heloc","sloc","studentloc"].includes(type.toLowerCase() ?? "") ? ((0.030/365) * 30 * requestedAmount) : 0;
//   if(minPaymentValue > 0)
//     return minPaymentValue.toString()
//   else
//     return "0"

// }

export const locMinimumPayment: TextFieldType<CreditAppLOC> = {
  id: `locMinimumPayment_${v4()}`,
  label: "Minimum Payment",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.minimumPayment?.toString(),
  //getValue: (account: CreditAppLOC) => getMinimumPayment(account.requestedAmount ?? 0, account.type ?? ""),
  setValue: (value, account) => ({
    ...account,
    minimumPayment: (value as unknown) as number,
  }),
  //disabled: true,
  isRequired: true,
  mask: MASK_TYPE.MONEY,
};

export const locType: PickListField<CreditAppLOC> = {
  id: `locType_${v4()}`,
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppLOC) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
    //clearing out type dependent fields if type is changed
    security: "",
    school: "",
    program: "",
    studentId: "",
    expectedGraduationDate: "",
    appraisedValue: undefined,
    existingBalance: undefined,
    equityAmount: undefined,
    paymentAmount: undefined,
    propertyAddress: {
      country: "Canada",
    },
    marketValue: undefined,
    propertyTax: undefined,
    includedInMortgage: false,
    muncipality: "",
    legalDescription: "",
    propertyType: "",
    buildingType: "",
    propertyStyle: "",
    noOfBedrooms: "",
    noOfBathrooms: "",
    propertyAge: "",
    parkingType: "",
    squareFootage: "",
    lotSize: "",
  }),
  options: [
    { id: "uloc", name: "ULOC" },
    { id: "heloc", name: "HELOC" },
    { id: "sloc", name: "SLOC" },
    { id: "studentloc", name: "Student LOC" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const locSecurity: TextFieldType<CreditAppLOC> = {
  id: `locSecurity_${v4()}`,
  label: "Security",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.security,
  setValue: (value, account) => ({
    ...account,
    security: value,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "sloc" ?? false;
  },
};

export const locInterestType: RadioGroupField<CreditAppLOC> = {
  id: `locInterestType_${v4()}`,
  label: "Interest Type",
  trueLabel: "Fixed",
  falseLabel: "Variable",
  defaultValue: "Variable",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: CreditAppLOC) => account.interestType,
  setValue: (value, account) => ({
    ...account,
    interestType: value,
  }),
  isRequired: true,
  disabled: true,
};

export const locPrimeRate: TextFieldType<CreditAppLOC> = {
  id: `locPrimeRate_${v4()}`,
  label: "Prime Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => getPrimeRate(),
  setValue: (value, account) => ({
    ...account,
    primeRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE,
};

export const locInterestRate: TextFieldType<CreditAppLOC> = {
  id: `locInterestRate_${v4()}`,
  label: "Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.interestRate,
  setValue: (value, account) => ({
    ...account,
    interestRate: value,
  }),
  isRequired: true,
  mask: MASK_TYPE.PERCENTAGE,
  checkIfAllowNegative: (account: CreditAppLOC) => {
    return account.interestType === "Fixed" ? false : true;
  },
};

export const locTotalInterestRate: TextFieldType<CreditAppLOC> = {
  id: `locTotalInterestRate_${v4()}`,
  label: "Total Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => {
    let tir = add(
      ((account.interestRate ?? 0) as unknown) as number,
      ((getPrimeRate() ?? 0) as unknown) as number
    );
    if (tir > 0) return tir.toString();
    else return "0";
  },
  setValue: (value, account) => ({
    ...account,
    totalInterestRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE,
};

export const locSchool: TextFieldType<CreditAppLOC> = {
  id: `locSchool_${v4()}`,
  label: "School",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.school,
  setValue: (value, account) => ({
    ...account,
    school: value,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "studentloc" ?? false;
  },
};

export const locProgram: TextFieldType<CreditAppLOC> = {
  id: `locProgram_${v4()}`,
  label: "Program",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.program,
  setValue: (value, account) => ({
    ...account,
    program: value,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "studentloc" ?? false;
  },
};

export const locStudentId: TextFieldType<CreditAppLOC> = {
  id: `locStudentId_${v4()}`,
  label: "Student Id",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.studentId,
  setValue: (value, account) => ({
    ...account,
    studentId: value,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "studentloc" ?? false;
  },
};

export const locExpectedGradDate: DateField<CreditAppLOC> = {
  id: `locExpectedGradDate_${v4()}`,
  label: "Expected Graduation Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: CreditAppLOC) => account.expectedGraduationDate,
  setValue: (value, account) => ({
    ...account,
    expectedGraduationDate: value,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "studentloc" ?? false;
  },
  disablePastDates: true,
};

export const locHasJointApplication: BoolField<CreditAppLOC> = {
  id: `locHasJointApplication_${v4()}`,
  label: "Joint Application",
  type: FIELD_TYPE.CHECKBOX,
  //defaultValue: true,
  getValue: (account: CreditAppLOC) => account.hasJointApplication,
  setValue: (value, account) => ({
    ...account,
    hasJointApplication: value,
    jointApplication: value ? account.jointApplication : undefined,
  }),
};

export const locJointAppName: TextFieldType<CreditAppLOC> = {
  id: `locJointAppName_${v4()}`,
  label: "Name",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.jointApplication?.name,
  setValue: (value, account) => ({
    ...account,
    jointApplication: { ...account.jointApplication, name: value },
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.hasJointApplication ?? false;
  },
};

export const locJointAppEmployerName: TextFieldType<CreditAppLOC> = {
  id: `locJointAppEmployerName_${v4()}`,
  label: "Employer Name",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.jointApplication?.employerName,
  setValue: (value, account) => ({
    ...account,
    jointApplication: { ...account.jointApplication, employerName: value },
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.hasJointApplication ?? false;
  },
};

export const locJointAppJobTitle: TextFieldType<CreditAppLOC> = {
  id: `locJointAppJobTitle_${v4()}`,
  label: "Job Title",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.jointApplication?.jobTitle,
  setValue: (value, account) => ({
    ...account,
    jointApplication: { ...account.jointApplication, jobTitle: value },
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.hasJointApplication ?? false;
  },
};

export const locJointAppEmploymentStartDate: DateField<CreditAppLOC> = {
  id: "locJointAppEmploymentStartDate",
  label: "Employment Start Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: CreditAppLOC) =>
    account.jointApplication?.employmentStartDate,
  setValue: (value, account) => ({
    ...account,
    jointApplication: {
      ...account.jointApplication,
      employmentStartDate: value,
    },
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.hasJointApplication ?? false;
  },
};

export const locJointAppMonthlyIncome: TextFieldType<CreditAppLOC> = {
  id: `locJointAppMonthlyIncome_${v4()}`,
  label: "Monthly Income",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) =>
    account.jointApplication?.monthlyIncome?.toString(),
  setValue: (value, account) => ({
    ...account,
    jointApplication: {
      ...account.jointApplication,
      monthlyIncome: (value as unknown) as number,
    },
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.hasJointApplication ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

//----HELOC Calculation Details

export const locAppraisedValue: TextFieldType<CreditAppLOC> = {
  id: `locAppraisedValue_${v4()}`,
  label: "Appraised Value",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.appraisedValue?.toString(),
  setValue: (value, account) => ({
    ...account,
    appraisedValue: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const locExistingBalance: TextFieldType<CreditAppLOC> = {
  id: `locExistingBalance_${v4()}`,
  label: "Existing Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.existingBalance?.toString(),
  setValue: (value, account) => ({
    ...account,
    existingBalance: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const locEquityAmount: TextFieldType<CreditAppLOC> = {
  id: `locEquityAmount_${v4()}`,
  label: "Equity Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.equityAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    equityAmount: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const locPaymentAmount: TextFieldType<CreditAppLOC> = {
  id: `locPaymentAmount_${v4()}`,
  label: "Payment Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.paymentAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    paymentAmount: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

//----HELOC Calculations - Property Information

export const locPropertyAddress: AddressField<CreditAppLOC> = {
  id: "locPropertyAddress",
  label: "Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (account: CreditAppLOC) => account.propertyAddress,
  setValue: (value, account) => ({
    ...account,
    propertyAddress: value,
  }),
  isDated: false,
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  options: provinceOptions,
};

export const locMarketValue: TextFieldType<CreditAppLOC> = {
  id: `locMarketValue_${v4()}`,
  label: "Market Value",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.marketValue?.toString(),
  setValue: (value, account) => ({
    ...account,
    marketValue: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const locPropertyTax: TextFieldType<CreditAppLOC> = {
  id: `locPropertyTax_${v4()}`,
  label: "Annual Property Tax",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.propertyTax?.toString(),
  setValue: (value, account) => ({
    ...account,
    propertyTax: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const locIncludedInMortgage: BoolField<CreditAppLOC> = {
  id: `locIncludedInMortgage_${v4()}`,
  label: "Included In Mortgage",
  type: FIELD_TYPE.CHECKBOX,
  //defaultValue: true,
  getValue: (account: CreditAppLOC) => account.includedInMortgage,
  setValue: (value, account) => ({
    ...account,
    includedInMortgage: value,
  }),
};

export const locMuncipality: TextFieldType<CreditAppLOC> = {
  id: `locMuncipality_${v4()}`,
  label: "Muncipality",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.muncipality,
  setValue: (value, account) => ({
    ...account,
    muncipality: value,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
};

export const locLegalDescription: TextFieldType<CreditAppLOC> = {
  id: "locLegalDescription",
  label: "Legal Description",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.legalDescription,
  setValue: (value, account) => ({
    ...account,
    legalDescription: value,
  }),
  multiline: true,
  maxLength: 500,
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
};

export const locPropertyType: PickListField<CreditAppLOC> = {
  id: `locPropertyType_${v4()}`,
  label: "Property Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppLOC) => account.propertyType,
  setValue: (value, account) => ({
    ...account,
    propertyType: value,
  }),
  options: [
    { id: "singlefamily", name: "Single Family" },
    { id: "multifamily", name: "Multi Family" },
    { id: "townhouse", name: "Townhouse" },
    { id: "condominium", name: "Condominium" },
    { id: "leaseproperty", name: "Lease Property" },
    { id: "mobile", name: "Mobile" },
  ],
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const locBuildingType: PickListField<CreditAppLOC> = {
  id: `locBuildingType_${v4()}`,
  label: "Building Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppLOC) => account.buildingType,
  setValue: (value, account) => ({
    ...account,
    buildingType: value,
  }),
  options: [
    { id: "home", name: "Home" },
    { id: "duplex", name: "Duplex" },
    { id: "condo", name: "Condo" },
    { id: "townhouse", name: "Townhouse" },
    { id: "apartment", name: "Apartment" },
    { id: "mobile", name: "Mobile" },
  ],
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const locPropertyStyle: PickListField<CreditAppLOC> = {
  id: `locPropertyStyle_${v4()}`,
  label: "Style",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppLOC) => account.propertyStyle,
  setValue: (value, account) => ({
    ...account,
    propertyStyle: value,
  }),
  options: [
    { id: "bi-level", name: "Bi-level" },
    { id: "bungalow", name: "Bungalow" },
    { id: "twostorey", name: "Two Storey" },
    { id: "carriagehouse", name: "Carriage House" },
  ],
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const locNoOfBedrooms: PickListField<CreditAppLOC> = {
  id: `locNoOfBedrooms_${v4()}`,
  label: "No Of Bedrooms",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppLOC) => account.noOfBedrooms,
  setValue: (value, account) => ({
    ...account,
    noOfBedrooms: value,
  }),
  options: [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "5+", name: "5+" },
  ],
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const locNoOfBathrooms: PickListField<CreditAppLOC> = {
  id: `locNoOfBathrooms_${v4()}`,
  label: "No Of Bathrooms",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppLOC) => account.noOfBathrooms,
  setValue: (value, account) => ({
    ...account,
    noOfBathrooms: value,
  }),
  options: [
    { id: "1", name: "1" },
    { id: "1.5", name: "1.5" },
    { id: "2", name: "2" },
    { id: "2.5", name: "2.5" },
    { id: "3", name: "3" },
    { id: "3.5", name: "3.5" },
    { id: "4", name: "4" },
    { id: "4+", name: "4+" },
  ],
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const locPropertyAge: TextFieldType<CreditAppLOC> = {
  id: `locPropertyAge_${v4()}`,
  label: "Property Age (years)",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.propertyAge,
  setValue: (value, account) => ({
    ...account,
    propertyAge: value,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  inputType: INPUT_TYPE.NUMBER,
};

export const locParkingType: PickListField<CreditAppLOC> = {
  id: `locParkingType_${v4()}`,
  label: "Parking Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppLOC) => account.parkingType,
  setValue: (value, account) => ({
    ...account,
    parkingType: value,
  }),
  options: [
    { id: "attachedGarage", name: "Attached Garage" },
    { id: "detachedgarage", name: "Detached Garage" },
    { id: "parkingpad", name: "Parking Pad" },
    { id: "none", name: "None" },
  ],
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const locSquareFootage: TextFieldType<CreditAppLOC> = {
  id: `locSquareFootage_${v4()}`,
  label: "Square Footage",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.squareFootage,
  setValue: (value, account) => ({
    ...account,
    squareFootage: value,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  inputType: INPUT_TYPE.NUMBER,
};

export const locLotSize: TextFieldType<CreditAppLOC> = {
  id: `locLotSize_${v4()}`,
  label: "Lot Size (Sq.ft)",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppLOC) => account.lotSize,
  setValue: (value, account) => ({
    ...account,
    lotSize: value,
  }),
  checkIfRequired: (account: CreditAppLOC) => {
    return account.type === "heloc" ?? false;
  },
  inputType: INPUT_TYPE.NUMBER,
};
