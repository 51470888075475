import { Receive } from "types";
import SummaryRowReceiveCash from "pages/sessionHistory/components/SummaryRowReceiveCash";
import SummaryRowReceiveCheque from "pages/sessionHistory/components/SummaryRowReceiveCheque";

type SummaryRowReceiveProps = {
  transaction: Receive;
};

export default function SummaryRowReceive(props: SummaryRowReceiveProps) {
  return (
    <>
      {!!props.transaction.cash?.cashCADValue &&
        props.transaction.cash?.cashCADValue > 0 && (
          <SummaryRowReceiveCash
            cash={props.transaction.cash ?? {}}
            lctr={props.transaction.lctr}
            utr={props.transaction.utr}
            transactionDateTime={props.transaction.transactionDateTime ?? ""}
          />
        )}
      {!!props.transaction.cheque?.chequeCADValue &&
        props.transaction.cheque?.chequeCADValue > 0 && (
          <SummaryRowReceiveCheque
            cheque={props.transaction.cheque ?? {}}
            transactionDateTime={props.transaction.transactionDateTime ?? ""}
          />
        )}
    </>
  );
}
