import moment from "moment";
import {
  Account,
  BusinessClient,
  Employment,
  PersonalClient,
  SigningAuthority,
} from "types";

export const provinceOptions = [
  { id: "AB", name: "AB" },
  { id: "BC", name: "BC" },
  { id: "MB", name: "MB" },
  { id: "NB", name: "NB" },
  { id: "NL", name: "NL" },
  { id: "NS", name: "NS" },
  { id: "NT", name: "NT" },
  { id: "NU", name: "NU" },
  { id: "ON", name: "ON" },
  { id: "PE", name: "PE" },
  { id: "QC", name: "QC" },
  { id: "SK", name: "SK" },
  { id: "YT", name: "YT" },
];

export const employmentTypeOptions = [
  { id: "employed", name: "Employed" },
  { id: "selfEmployed", name: "Self-Employed" },
  { id: "unemployed", name: "Unemployed" },
  { id: "homemaker", name: "Homemaker" },
  { id: "longTermDisability", name: "Long Term Disability" },
  { id: "retired", name: "Retired" },
  { id: "student", name: "Student" },
];

export const residentialStatusOptions = [
  { id: "own", name: "Own" },
  { id: "rent", name: "Rent" },
  { id: "liveWithFamily", name: "Live with Family" },
  { id: "other", name: "Other" },
];

export const employerNotExistsForType = [
  "unemployed",
  "homemaker",
  "retired",
  "student",
];
export const employerNotRequiredForType = [
  "unemployed",
  "homemaker",
  "retired",
  "student",
  "longTermDisability",
];

export const assetsAccountsTypes = [
  "PERSONAL_SAVINGS",
  "PERSONAL_CHEQUING",
  "MUTUAL_FUNDS",
  "GIC",
  "BUSINESS_SAVINGS",
  "BUSINESS_CHEQUING",
];

export const liabilitiesAccountsTypes = [
  "MORTGAGE",
  "LINE_OF_CREDIT",
  "LOAN",
  "CREDIT_CARD",
];

export const defaultPersonalClient: PersonalClient = {};

export const defaultBusinessClient: BusinessClient = {};

export const defaultEmployment: Employment = { isCurrent: true };
export const defaultSigningAuthority: SigningAuthority = {};

export const desktopViewThreshold = 1500;

export const UnknownAccount: Account = {
  accountId: "Unknown",
  accountType: "PERSONAL_CHEQUING",
  created: moment().toString(),
};

export enum INVESTING_PROFILES {
  SAFETY = "Safety",
  CONSERVATIVE_INCOME = "Conservative Income",
  BALANCED = "Balanced",
  GROWTH = "Growth",
  AGGRESSIVE_GROWTH = "Aggressive Growth",

  UNKNOWN = "Unknown",
}
