import { getGLEntryReasonOptions } from "common/functions";
import {  
  FIELD_TYPE,  
  MASK_TYPE,
  PickListField,
  TextFieldType,
  GLEntry,
} from "types";


export const glType: PickListField<GLEntry> = {
  id: "glType",
  label: "GL Type",
  type: FIELD_TYPE.PICKLIST,
  isRequired: true,
  getValue: (transaction: GLEntry) =>  transaction.glType,
  setValue: (value, transaction) => ({...transaction,glType: value }),
  options: [
    { id: "debit", name: "Debit" },
    { id: "credit", name: "Credit" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};


export const glAccount: PickListField<GLEntry> = {
  id: "glAccount",
  label: "GL Account",
  type: FIELD_TYPE.PICKLIST,
  isRequired: true,
  getValue: (transaction: GLEntry) =>  transaction.glAccount,
  setValue: (value, transaction) => ({...transaction,glAccount: value }),
  options: [
    { id: "treasury", name: "Treasury" },
    { id: "csr_outage", name: "CSR Outage" },
    { id: "service_charges", name: "Service Charges" },
    { id: "drafts", name: "Drafts" },
    { id: "client_transfer", name: "Client Transfer" },
  ],
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const clientAccount: PickListField<GLEntry> = {
  id: "clientAccount",
  label: "Client Account",
  type: FIELD_TYPE.ACCOUNTSPICKLIST,
  isRequired: true,
  getValue: (transaction: GLEntry) =>  transaction.clientAccount,
  setValue: (value, transaction) => ({...transaction,clientAccount: value }),
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const glAmount: TextFieldType<GLEntry> = {
  id: "glAmount",
  label: "Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: GLEntry) => transaction.amount ? transaction.amount.toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    amount: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
};

export const reason: PickListField<GLEntry> = {
  id: "reason",
  label: "Reason",
  type: FIELD_TYPE.PICKLIST,
  isRequired: true,
  getValue: (transaction: GLEntry) =>  transaction.reason,
  setValue: (value, transaction) => ({...transaction,reason: value }),
  options: getGLEntryReasonOptions(),
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const comment: TextFieldType<GLEntry> = {
  id: "comment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: GLEntry) => transaction.comment,
  setValue: (value, transaction) => ({...transaction, comment: value }),
  multiline: true,
};