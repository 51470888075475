import { AddressField, DisplayFieldProps, Entity } from "types";
import DisplayFieldTemplate from "./DisplayFieldTemplate";

export default function AddressFieldDisplay<T extends Entity>(
  props: DisplayFieldProps<T>
) {
  const state = props.state;

  const addressField = props.field as AddressField<T>;

  return (
    <>
      <DisplayFieldTemplate label={"Address"}>
        {addressField.getValue(state)?.address ?? ""}
      </DisplayFieldTemplate>
      <DisplayFieldTemplate label={"City"}>
        {addressField.getValue(state)?.city ?? ""}
      </DisplayFieldTemplate>
      <DisplayFieldTemplate label={"Province"}>
        {addressField.getValue(state)?.province ?? ""}
      </DisplayFieldTemplate>
      <DisplayFieldTemplate label={"Postal Code"}>
        {addressField.getValue(state)?.postalCode ?? ""}
      </DisplayFieldTemplate>
      <DisplayFieldTemplate label={"Country"}>
        {addressField.getValue(state)?.country ?? "Canada"}
      </DisplayFieldTemplate>
    </>
  );
}
