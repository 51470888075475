import FieldEditor from "components/FieldEditor";
import { FieldError, PersonalChequing } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientAccounts/personalChequingFieldDefinitions";
import { useEffect, useState } from "react";
import { requiredDateField, requiredTextField } from "common/functions";
import AccountEditTemplate from "pages/clientPortfolio/personalClient/personalClientAccounts/components/AccountEditTemplate";
import { openConfirm } from "components/confirmBox";
export type Props = {
  account: PersonalChequing;
  isSaving: boolean;
  onCancel(): void;
  onSave(account: PersonalChequing): void;
};

function PersonalChequingForm({ account, onCancel, onSave, isSaving }: Props) {
  const [state, setState] = useState<PersonalChequing>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: PersonalChequing) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredDateField(fields.personalChequingOpenDate, state),
      ...requiredTextField(fields.personalChequingODPLimit, state),
    ];

    if (
      state.closeDate &&
      state.openDate &&
      state.closeDate.toLowerCase() !== "invalid date" &&
      new Date(state.closeDate) < new Date(state.openDate)
    ) {
      let invalidCloseDateErr: FieldError = {
        id: `${fields.personalChequingCloseDate.id}`,
        label: `${fields.personalChequingCloseDate.label}`,
        error: "Close Date should be after the Open Date",
      };
      errorsArray.push(invalidCloseDateErr);
    }

    setErrors(errorsArray);
    return errorsArray;
  }

  function onSaveClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (
        state.closeDate &&
        state.closeDate.toLowerCase() !== "invalid date" &&
        state.balance &&
        state.balance !== 0
      ) {
        openConfirm({
          title: "Error",
          description: `Account Balance should be $0.00 to close the account`,
          isAlert: true,
        });
      } else {
        onSave({
          ...state,
          isAccountClosed:
            state.closeDate && state.closeDate.toLowerCase() !== "invalid date"
              ? true
              : false,
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`PERSONAL_CHEQUING_${account.accountId}_form`}
      onSaveClick={onSaveClick}
      onCancelClick={onCancel}
      isSaving={isSaving}
      type="Chequing"
      accountNumber={account.accountNumber || ""}
      balance={account.balance || 0}
      column1Fields={
        <>
          <FieldEditor<PersonalChequing>
            field={fields.personalChequingOpenDate}
            {...commonProps}
          />
          <FieldEditor<PersonalChequing>
            field={fields.personalChequingAccounNumber}
            {...commonProps}
          />
          <FieldEditor<PersonalChequing>
            field={fields.personalChequingBalance}
            {...commonProps}
          />

          <FieldEditor<PersonalChequing>
            field={fields.personalChequingAvailableFunds}
            {...commonProps}
          />
        </>
      }
      column2Fields={
        <>
          <FieldEditor<PersonalChequing>
            field={fields.personalChequingCloseDate}
            {...commonProps}
          />
          <FieldEditor<PersonalChequing>
            field={fields.personalChequingOverdraftProtection}
            {...commonProps}
          />

          <FieldEditor<PersonalChequing>
            field={fields.personalChequingODPLimit}
            {...commonProps}
          />
        </>
      }
    />
  );
}

export default PersonalChequingForm;
