import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

type AddAccountMenuProps = {
  onAddBusinessChequing(): void;
  onAddBusinessSavings(): void;
  onAddCreditCard(): void;
  onAddGIC(): void;
  onAddMutualFunds(): void;
  onAddLoan(): void;
  onAddLineOfCredit(): void;
  onAddMortgage(): void;
  isReadOnlyView: boolean;
};

export default function AddAccountMenu(props: AddAccountMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        disabled={props.isReadOnlyView}
      >
        Add
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            props.onAddBusinessChequing();
            handleClose();
          }}
        >
          Chequing
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddBusinessSavings();
            handleClose();
          }}
        >
          Savings
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddGIC();
            handleClose();
          }}
        >
          Guaranteed Investment Certificate
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddMutualFunds();
            handleClose();
          }}
        >
          Mutual Funds
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddCreditCard();
            handleClose();
          }}
        >
          Credit Card
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddLoan();
            handleClose();
          }}
        >
          Loan
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddLineOfCredit();
            handleClose();
          }}
        >
          Line Of Credit
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onAddMortgage();
            handleClose();
          }}
        >
          Mortgage
        </MenuItem>
      </Menu>
    </div>
  );
}
