import { Dialog, DialogContent } from "@mui/material";
import { Account } from "types";
import CreditCardForm from "pages/clientPortfolio/personalClient/personalClientAccounts/components/CreditCardForm";
import GICForm from "pages/clientPortfolio/personalClient/personalClientAccounts/components/GICForm";
import LineOfCreditForm from "pages/clientPortfolio/personalClient/personalClientAccounts/components/LineOfCreditForm";
import LoanForm from "pages/clientPortfolio/personalClient/personalClientAccounts/components/LoanForm";
import MortgageForm from "pages/clientPortfolio/personalClient/personalClientAccounts/components/MortgageForm";
import MutualFundsForm from "pages/clientPortfolio/personalClient/personalClientAccounts/components/MutualFundsForm";
import PersonalChequingForm from "pages/clientPortfolio/personalClient/personalClientAccounts/components/PersonalChequingForm";
import PersonalSavingsForm from "pages/clientPortfolio/personalClient/personalClientAccounts/components/PersonalSavingsForm";

export type Props = {
  account?: Account;
  isSaving: boolean;
  onSave(account: Account): void;
  onCancel(): void;
};

export default function EditAccountDialog({
  account,
  isSaving,
  onSave,
  onCancel,
}: Props) {
  if (!account) {
    return <></>;
  }

  if (account.accountType === "PERSONAL_CHEQUING") {
    return (
      <PersonalChequingForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "PERSONAL_SAVINGS") {
    return (
      <PersonalSavingsForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "GIC") {
    return (
      <GICForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "MUTUAL_FUNDS") {
    return (
      <MutualFundsForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "CREDIT_CARD") {
    return (
      <CreditCardForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "LOAN") {
    return (
      <LoanForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "LINE_OF_CREDIT") {
    return (
      <LineOfCreditForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  if (account.accountType === "MORTGAGE") {
    return (
      <MortgageForm
        account={account}
        isSaving={isSaving}
        onSave={(account) => {
          onSave(account);
        }}
        onCancel={onCancel}
      />
    );
  }

  return (
    <Dialog open onClose={() => {}}>
      <DialogContent>Unknown Account Type</DialogContent>
    </Dialog>
  );
}
