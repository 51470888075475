import { Grid } from "@mui/material";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessMortgageFieldDefinitions";
import { Mortgage } from "types";
import AccountDisplayTemplate from "pages/clientPortfolio/businessClient/businessClientAccounts/components/AccountDisplayTemplate";
export type Props = {
  account: Mortgage;
  onEditClick(): void;
  onDeleteClick(): void;
  onAccountHistoryClick(): void;
  isReadOnlyView: boolean;
};

function MortgageDisplay({
  account,
  onEditClick,
  onDeleteClick,
  onAccountHistoryClick,
  isReadOnlyView,
}: Props) {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`Mortgage_${account.accountId}_Display`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      onAccountHistoryClick={onAccountHistoryClick}
      accountType="Mortgage"
      accountNumber={account.accountNumber || ""}
      type={account.type}
      balance={account.balance || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldDisplay<Mortgage>
            field={fields.mortgageOpenDate}
            {...commonProps}
          />
          <FieldDisplay<Mortgage>
            field={fields.mortgageAccountNumber}
            {...commonProps}
          />

          <FieldDisplay<Mortgage>
            field={fields.mortgageBalance}
            {...commonProps}
          />
          <FieldDisplay<Mortgage>
            field={fields.mortgageType}
            {...commonProps}
          />
          <FieldDisplay<Mortgage>
            field={fields.mortgageAmortization}
            {...commonProps}
          />
          <FieldDisplay<Mortgage>
            field={fields.mortgageRemainingAmortization}
            {...commonProps}
          />
          <FieldDisplay<Mortgage>
            field={fields.mortgageMaturityDate}
            {...commonProps}
          />
          <FieldDisplay<Mortgage>
            field={fields.mortgagePaymentAmount}
            {...commonProps}
          />
          <FieldDisplay<Mortgage>
            field={fields.mortgagePaymentFrequency}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<Mortgage>
            field={fields.mortgageCloseDate}
            {...commonProps}
          />

          <FieldDisplay<Mortgage>
            field={fields.mortgageInterestType}
            {...commonProps}
          />
          {account.interestType === "Variable" && (
            <FieldDisplay<Mortgage>
              field={fields.mortgagePrimeRate}
              {...commonProps}
            />
          )}
          <FieldDisplay<Mortgage>
            field={fields.mortgageInterestRate}
            {...commonProps}
          />
          {account.interestType === "Variable" && (
            <FieldDisplay<Mortgage>
              field={fields.mortgageTotalInterestRate}
              {...commonProps}
            />
          )}
          <FieldDisplay<Mortgage>
            field={fields.mortgageTermStartDate}
            {...commonProps}
          />
          <FieldDisplay<Mortgage>
            field={fields.mortgageTermLength}
            {...commonProps}
          />
          <FieldDisplay<Mortgage>
            field={fields.mortgageRemainingTermLength}
            {...commonProps}
          />
        </Grid>
      </Grid>
    </AccountDisplayTemplate>
  );
}

export default MortgageDisplay;
