import moment from "moment";

export enum FIELD_TYPE {
  CHECKBOX = "checkbox",
  RADIOGROUP = "radiogroup",
  RADIOBUTTON = "radiobutton",
  DATE = "date",

  DATETIME = "dateTime",
  PICKLIST = "picklist",
  TEXT = "text",
  IDENTIFICATION = "identification",
  ADDRESS = "address",
  PERSONALCHEQUING = "personalChequing",
  CHEQUESARRAY = "chequeamounts",
  ACCOUNTSPICKLIST = "accountpicklist",
}

export enum MASK_TYPE {
  SOCIAL_SECURITY_NUMBER = "SocialSecurityNumber",
  PHONE_NUMBER = "PhoneNumber",
  MONEY = "Money",
  CREDIT_CARD_NUMBER = "CreditCardNumber",
  PERCENTAGE = "Percentage",
  NUMBER = "Number",
}

export enum INPUT_TYPE {
  NUMBER = "number",
  PASSWORD = "password",
}
export interface IdName {
  id: string;
  name: string;
}

export type BoolField<T extends Entity> = Field<T> & {
  defaultValue?: boolean;
  trueLabel?: string;
  radio?: boolean;
  falseLabel?: string;
  labelPlacement?: "start" | "end" | "top" | "bottom";
  getValue(t: T): boolean | undefined;
  setValue(value: boolean, obj: T): T;
};

export type RadioGroupField<T extends Entity> = Field<T> & {
  defaultValue?: string;
  trueLabel?: string;
  falseLabel?: string;
  getValue(t: T): string | undefined;
  setValue(value: string, obj: T): T;
};

export type PickListField<T extends Entity> = Field<T> & {
  getOptions?(props: GetOptionsParams): IdName[];
  options?: IdName[];
  radio?: boolean;
  hideLabel?: boolean;
  autoWidth?: boolean;
  defaultValue?: string;
  useIdInsteadOfValueForValue?: boolean;
  getValue(t: T): string | undefined;
  setValue(value: string, obj: T): T;
  getOptionLabel?(value: string, options: IdName[]): string;
};

export type DateField<T extends Entity> = Field<T> & {
  disableFutureDates?: boolean;
  disablePastDates?: boolean;
  minDate?: moment.Moment;
  getValue(t: T): string | undefined;
  setValue(value: string, obj: T): T;
};

export type DateTimeField<T extends Entity> = Field<T> & {
  disableFutureDates?: boolean;
  disablePastDates?: boolean;
  minDate?: moment.Moment;
  getValue(t: T): string | undefined;
  setValue(value: string | undefined, obj: T): T;
};

export type IdentificationField<T extends Entity> = Field<T> & {
  options?: IdName[];
  getValue(t: T): IdentificationType | undefined;
  setValue(value: IdentificationType, obj: T): T;
};

export type AccountsPickListField<T extends Entity> = Field<T> & {
  getOptions?(props: GetOptionsParams): IdName[];
  defaultValue?: string;
  getValue(t: T): string | undefined;
  setValue(value: string, obj: T): T;
  getOptionLabel?(value: string, options: IdName[]): string;
};

export type AddressField<T extends Entity> = Field<T> & {
  getValue(t: T): AddressType | undefined;
  setValue(value: AddressType, obj: T): T;
  options?: IdName[];
};

export type PersonalChequingField<T extends Entity> = Field<T> & {
  getValue(t: T): PersonalChequing | undefined;
  setValue(value: PersonalChequing, obj: T): T;
  options?: IdName[];
};

export type MultiPickField<T extends Entity> = Field<T> & {
  getOptions?(props: GetOptionsParams): IdName[];
  options?: IdName[];
  defaultValue?: string[];
  useIdInsteadOfValueForValue?: boolean;
  getValue(t: T): string[] | undefined;
  setValue(value: string[], obj: T): T;
  getOptionLabel?(value: string[], options: IdName[]): string[];
};

export type ChequesArrayField<T extends Entity> = Field<T> & {
  getValue(t: T): (number | null)[];
  setValue(value: (number | null)[], obj: T): T;
};

type GetOptionsParams = {};

export type FieldError = {
  id: string;
  label: string;
  error: string;
};
export type Field<T> = {
  id: string;
  label: string;
  type: FIELD_TYPE;
  sm?: number;
  md?: number;
  lg?: number;
  isRequired?: boolean;
  checkIfRequired?(obj: T): boolean;
  checkIfDisabled?(obj: T): boolean;
  disabled?: boolean;
  readOnly?: boolean;
  isDated?: boolean; //to be used to render Address start date in AddressEditor
  multiColumnDisplay?: boolean;
};
export type TextFieldType<T extends Entity> = Field<T> & {
  inputType?: INPUT_TYPE;
  multiline?: boolean;
  mask?: MASK_TYPE;
  getValue(t: T): string | undefined;
  setValue(value: string, obj: T): T;
  minValue?: number;
  maxValue?: number;
  allowNegative?: boolean;
  checkIfAllowNegative?(obj: T): boolean;
  maxLength?: number;
};

export type Entity =
  | PersonalClient
  | Employer
  | Employment
  | AddressType
  | PersonalSavings
  | PersonalChequing
  | CreditCard
  | GIC
  | MutualFunds
  | LineOfCredit
  | Loan
  | Mortgage
  | Receive
  | Deposit
  | Withdrawal
  | PayoutCash
  | BillPayment
  | CreditCardTransaction
  | Transfer
  | SellDraft
  | GLEntry
  | GLTransaction
  | ForeignExchangeConverter
  | LessonData
  | LessonListItem
  | GlobalSettings
  | StudentListItem
  | CustomStudent
  | BusinessClient
  | SigningAuthority
  | CustomAsset
  | CustomLiability
  | CreditAppCreditCard
  | CreditAppLoan
  | CreditAppLOC
  | CreditAppMortgage
  | Investing
  | LessonSettings;

export type IdentificationType = {
  number?: string;
  expiry?: string;
  type?: string;
  otherType?: string;
};

export type AddressType = {
  address?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
  startDate?: string;
};

export type AddressHistoryItemObject = {
  addressHistoryItem: AddressType;
  index: number;
};

export type GLAccountType =
  | "TREASURY"
  | "CSR_OUTAGE"
  | "SERVICE_CHARGES"
  | "DRAFTS"
  | "CLIENT_TRANSFER";

export type GLTransaction = {
  transactionId: string;
  transactionType: "CASH_TRANSFER";
  transactionDateTime?: string;
  transactionDescription?: string;
  amount?: number;
  from?: string;
  to?: string;
};

export type GLAccount = {
  glAccountId: string;
  glAccountType: GLAccountType;
  created: string;
  balance?: number;
  transactionIds?: string[];
};

export type CashDrawer = {
  balance?: number;
  cash?: Cash;
  startingTotal?: number;
  overrideStatus?: string;
  endOfDayTransactionIds?: string[];
};

export type EndOfDayTransactionListItem = {
  clientName: string;
  transaction: Transaction | GLTransaction;
};

export type GeneralLedger = {
  glAccounts?: GLAccount[];
  glTransactions?: GLTransaction[];
  cashDrawer?: CashDrawer;
};

export type ForeignExchangeConverter = {
  currencyExchanging?: string;
  transactionType?: string;
  convertCurrency?: string;
  intoCurrency?: string;
  transactionProduct?: string;
  transactionTypeRadio?: string;
};

export type PersonalClient = {
  identification?: {
    clientId?: string;
    sin?: string;
    primaryIdentification?: IdentificationType;
    secondaryIdentification?: IdentificationType;
  };
  information?: {
    accountType?: "Personal";
    legalFirstName?: string;
    legalLastName?: string;
    preferredFirstName?: string;
    dateOfBirth?: string;
    maritalStatus?: string;
    preferredPronouns?: string;
    email?: string;
    comment?: string;
    phoneNumber?: string;
    phoneNumberType?: string;
    preferredContact?: string;
    clientNewAddress?: AddressType;
    clientCurrentAddress?: AddressType;
    clientAddressHistory?: AddressType[];
  };
  employment?: Employment[];
  employmentHistory?: Employment[];
  accounts?: Account[];
  transactionSessions?: TransactionSession[];
  activeSessionId?: string;
  financialProfile?: {
    monthlyIncome?: number;
    residentialStatus?: string;
    monthlyMortgageRent?: number;
    monthlyPropertyTax?: number;
    monthlyHeatingCost?: number;
    monthlyCondoFee?: number;
    monthlyConsumerDebt?: number;
    comment?: string;
    customAssets?: CustomAsset[];
    customLiabilities?: CustomLiability[];
  };
  creditAppAccounts?: CreditAppAccount[];
  comments?: {
    characterCheckbox?: boolean;
    characterComment?: string;
    capitalCheckbox?: boolean;
    capitalComment?: string;
    capacityCheckbox?: boolean;
    capacityComment?: string;
    creditHistoryCheckbox?: boolean;
    creditHistoryComment?: string;
    collateralCheckbox?: boolean;
    collateralComment?: string;
    submissionComment?: string;
    customerAuthorizationName?: string;
  };
  investing?: Investing;
  informationInstructorFeedback?: string;
  creditAppInstructorFeedback?: string;
};

export type CustomAsset = {
  id?: string;
  name?: string;
  balance?: number;
};

export type CustomLiability = {
  id?: string;
  name?: string;
  limit?: number;
  balance?: number;
  monthlyPayment?: number;
  hasTDS?: boolean;
  alreadyIncludedInFinancialProfile?: boolean;
};
export type CreditAppAccountType =
  | "CREDIT_CARD"
  | "LOAN"
  | "LINE_OF_CREDIT"
  | "MORTGAGE";

export type CreditAppAccount = {
  accountId: string;
  accountType: CreditAppAccountType;
  created: string;
};

export type CreditAppCreditCard = CreditAppAccount & {
  requestedAmount?: number;
  minimumPayment?: number;
  type?: string;
  hasSecurity?: boolean;
  security?: string;
};

export type CreditAppLoan = CreditAppAccount & {
  requestedAmount?: number;
  paymentAmount?: number;
  termLength?: string;
  type?: string;
  security?: string;
  interestType?: string;
  primeRate?: string;
  interestRate?: string;
  totalInterestRate?: string;
  reason?: string;
  hasJointApplication?: boolean;
  jointApplication?: JointApplication;
};

export type CreditAppLOC = CreditAppAccount & {
  requestedAmount?: number;
  minimumPayment?: number;
  type?: string;
  security?: string;
  interestType?: string;
  primeRate?: string;
  interestRate?: string;
  totalInterestRate?: string;
  school?: string;
  program?: string;
  studentId?: string;
  expectedGraduationDate?: string;

  hasJointApplication?: boolean;
  jointApplication?: JointApplication;

  //----HELOC Calculation Details
  appraisedValue?: number;
  existingBalance?: number;
  equityAmount?: number;
  paymentAmount?: number;

  //----HELOC Calculations - Property Information
  propertyAddress?: AddressType;
  marketValue?: number;
  propertyTax?: number;
  includedInMortgage?: boolean;
  muncipality?: string;
  legalDescription?: string;
  propertyType?: string;
  buildingType?: string;
  propertyStyle?: string;
  noOfBedrooms?: string;
  noOfBathrooms?: string;
  propertyAge?: string;
  parkingType?: string;
  squareFootage?: string;
  lotSize?: string;
};

export type CreditAppMortgage = CreditAppAccount & {
  appraisedValue?: number;
  requestedAmount?: number;
  downPayment?: number;
  termLength?: string;
  purchaseDate?: string;
  paymentAmount?: number;
  type?: string;
  existingBalance?: number;
  equityAmount?: number;
  sourceOfDownPayment?: string;
  mortgageType?: string;
  interestType?: string;
  primeRate?: string;
  interestRate?: string;
  totalInterestRate?: string;
  amortization?: string;
  cmhc?: boolean;
  cmhcFee?: number;

  hasJointApplication?: boolean;
  jointApplication?: JointApplication;

  //----Property Information
  propertyAddress?: AddressType;
  purchasePrice?: number;
  marketValue?: number;
  propertyTax?: number;
  includedInMortgage?: boolean;
  muncipality?: string;
  legalDescription?: string;
  propertyType?: string;
  buildingType?: string;
  propertyStyle?: string;
  noOfBedrooms?: string;
  noOfBathrooms?: string;
  propertyAge?: string;
  parkingType?: string;
  squareFootage?: string;
  lotSize?: string;
  title?: string;
  restricted?: boolean;
};

export type JointApplication = {
  name?: string;
  employerName?: string;
  jobTitle?: string;
  employmentStartDate?: string;
  monthlyIncome?: number;
};

export type Investing = {
  overallGoal?: string;
  amount?: number;
  estimateBy?: string;
  projectionDate?: string;
  savingsPerMonth?: number;
  investmentProfile?: {
    accountType?: string;
    investmentKnowledge?: string;
    initialDeposit?: number;
    investmentObjective?: string;

    timeHorizon?: string;

    //following is used to calculate risk Capacity
    annualIncome?: string;
    incomeSourcesStability?: string;
    overallFinancialSituation?: string;
    estimatedNetWorth?: string;
    investmentAccountPercentage?: string;
    ageGroup?: string;

    //following is used to calculate risk Attitude
    personType?: string;
    tolerateDecline?: string;
    concernedForLossesGains?: string;
    investmentExample?: string;
    lossInInvestmentAction?: string;
    investmentPortfolioExample?: string;
  };
  overallProfile?: string;
  isSaveProfileAttempted?: boolean;
  clientAuthorizationName?: string;
  lastCompletedDate?: string;
};

export type BusinessClient = {
  information?: {
    clientId?: string;
    accountType?: "Business";
    businessType?: string;
    legalBusinessName?: string;
    businessNumber?: string;
    incorporationRegistrationNumber?: string;
    operatingAs?: string;
    email?: string;
    comment?: string;
    phoneNumber?: string;
    phoneNumberType?: string;
    dateEstablished?: string;
    businessNewAddress?: AddressType;
    businessCurrentAddress?: AddressType;
    businessAddressHistory?: AddressType[];
  };
  signingAuthorities?: SigningAuthority[];
  accounts?: Account[];
  transactionSessions?: TransactionSession[];
  activeSessionId?: string;
  informationInstructorFeedback?: string;
};

export type SigningAuthority = {
  signingAuthorityId?: string;
  businessAffiliation?: string;
  percentOfOwnership?: number;
  personalClientId?: string;
};

export type TransactionSession = {
  sessionId?: string;
  sessionCreated?: string;
  transactions?: Transaction[];
  sessionBalance?: number;
};

export type Transaction = {
  transactionId: string;
  transactionType:
    | "RECEIVE"
    | "BILL_PAYMENT"
    | "CREDIT_CARD"
    | "PAYOUT_CASH"
    | "SELL_DRAFT"
    | "TRANSFER"
    | "DEPOSIT"
    | "WITHDRAWAL"
    | "GL_ENTRY"
    | "DEBIT_CREDIT_MEMO";
  transactionDateTime?: string;
  transactionDescription?: string;
  amount?: number;
};

export type PayoutCash = Transaction & {
  cash?: Cash;
  utr?: boolean;
};

export type Deposit = Transaction & {
  currencyType?: string;
  from?: string;
  to?: string;
};

export type Withdrawal = Transaction & {
  currencyType?: string;
  from?: string;
  to?: string;
};

export type BillPayment = Transaction & {
  payee?: string;
  payeeAccountNumber?: string;
};

export type CreditCardTransaction = Transaction & {
  creditCard?: string;
  type?: string;
};

export type Transfer = Transaction & {
  currencyType?: string;
  from?: string;
  to?: string;
};

export type SellDraft = Transaction & {
  currencyType?: string;
  payee?: string;
  draftAmount?: number;
  serialNumber?: string;
  fee?: number;
  waiveFee?: boolean;
  draftAmountCADValue?: number;
  isApprovalPending?: boolean;
  isApproved?: boolean;
};

export type GLEntry = Transaction & {
  glType?: string;
  glAccount?: string;
  clientAccount?: string;
  reason?: string;
  comment?: string;
  isApprovalPending?: boolean;
  isApproved?: boolean;
  from?: string;
  to?: string;
};

export type Receive = Transaction & {
  cash?: Cash;
  cheque?: Cheque;
  utr?: boolean;
  lctr?: boolean;
};

export type Cash = {
  cashCurrencyType?: string;
  cent5Coins?: number;
  cent10Coins?: number;
  cent25Coins?: number;
  dollar1Coins?: number;
  dollar2Coins?: number;
  dollar1Bills?: number;
  dollar2Bills?: number;
  dollar5Bills?: number;
  dollar10Bills?: number;
  dollar20Bills?: number;
  dollar50Bills?: number;
  dollar100Bills?: number;
  totalCashAmount?: number;
  cashCADValue?: number;
};
export type CashItem = {
  num: number;
  quantity: number | undefined;
};

export type Cheque = {
  chequeCurrencyType?: string;
  chequesArray?: (number | null)[];
  holdAmount?: number;
  duration?: string;
  reason?: string;
  totalChequeAmount?: number;
  chequeCADValue?: number;
};

export type Employment = {
  employmentId?: string;
  employmentType?: string;
  employmentStartDate?: string;
  employerId?: string;
  jobTitle?: string;
  isCurrent?: boolean;
};

export type EmploymentItemObject = {
  employmentItem: Employment;
  index: number;
};

export type Employer = {
  employerId?: string;
  employerName?: string;
  phoneNumber?: string;
  employerAddress?: AddressType;
};

export type AccountType =
  | "PERSONAL_SAVINGS"
  | "PERSONAL_CHEQUING"
  | "CREDIT_CARD"
  | "GIC"
  | "MUTUAL_FUNDS"
  | "LOAN"
  | "LINE_OF_CREDIT"
  | "MORTGAGE"
  | "BUSINESS_SAVINGS"
  | "BUSINESS_CHEQUING";

export type Account = {
  accountId: string;
  accountType: AccountType;
  created: string;
  openDate?: string;
  closeDate?: string;
  balance?: number;
  transactionIds?: string[];
  isAccountClosed?: boolean;
};

export type PersonalChequing = Account & {
  accountNumber?: string;
  overdraftProtection?: boolean;
  overdraftLimit?: number;
  availableFunds?: number;
};
export type PersonalSavings = Account & {
  accountNumber?: string;
  type?: string;
};

export type BusinessChequing = Account & {
  accountNumber?: string;
  overdraftProtection?: boolean;
  overdraftType?: string;
  overdraftLimit?: number;
  availableFunds?: number;
};
export type BusinessSavings = Account & {
  accountNumber?: string;
};

export type CreditCard = Account & {
  cardNumber?: string;
  expiryMonth?: string;
  expiryYear?: string;
  limit?: number;
  type?: string;
  minimumPayment?: number;
};
export type GIC = Account & {
  accountNumber?: string;
  type?: string;
  investmentCategory?: string;
  termLength?: string;
  maturityDate?: string;
  investmentRate?: string;
};

export type Loan = Account & {
  accountNumber?: string;
  interestType?: string;
  primeRate?: string;
  interestRate?: string;
  type?: string;
  security?: string;
  paymentAmount?: number;
  paymentFrequency?: string;
  totalInterestRate?: string;
  termLength?: string;
  remainingTermLength?: string;
  termStartDate?: string;
};

export type LineOfCredit = Account & {
  accountNumber?: string;
  limit?: number;
  interestRate?: string;
  minimumPayment?: number;
  type?: string;
  security?: string;
  primeRate?: string;
  interestType?: string;
  totalInterestRate?: string;
};

export type MutualFunds = Account & {
  accountNumber?: string;
  type?: string;
  investmentCategory?: string;
  autoDeposit?: boolean;
  preAuthPaymentAmount?: number;
  preAuthPaymentFrequency?: string;
};

export type Mortgage = Account & {
  accountNumber?: string;
  interestType?: string;
  primeRate?: string;
  totalInterestRate?: string;
  type?: string;
  interestRate?: string;
  maturityDate?: string;
  amortization?: string;
  remainingAmortization?: string;
  termLength?: string;
  remainingTermLength?: string;
  termStartDate?: string;
  paymentAmount?: number;
  paymentFrequency?: string;
};

export type CommonFieldProps<T extends Entity> = {
  field: Field<T>;
  state: T;
  setState(state: T): void;
  errors: FieldError[];
};
export type DisplayFieldProps<T extends Entity> = {
  field: Field<T>;
  state: T;
};

export type StudentSessionLessonInfo = {
  id: number;
  lessonName: string;
  sectionList: SectionListItem[];
  studentList: StudentListItem[];
  outstandingOverrideTransactionList: OutstandingOverridesTransaction[];
  lessonSettings: LessonSettings;
};

export type LessonSettings = {
  isTimeBasedAccess: boolean;
  startDateTime?: string;
  endDateTime?: string;
};
export type LessonData = {
  personalClients?: PersonalClient[];
  businessClients?: BusinessClient[];
  employers?: Employer[];
  generalLedger?: GeneralLedger;
};

export type LessonListItem = {
  id: number;
  lessonName: string;
  numberOfClients: number;
  numberOfEmployers: number;
  numberOfStudentsHavingAccess: number;
  lastModified: string;
  isDataModifiedByStudent: boolean;
  startDateTime?: string;
  endDateTime?: string;
  isReadOnly?: boolean;
};

export type CustomStudent = {
  studentId?: string;
  fullName?: string;
  userName?: string;
};

export type StudentListItem = {
  sectionId: string;
  section: string;
  session: string;
  courseCode: string;
  term: string;
  studentId: string;
  fullName: string;
  userName: string;
  haveAccess: boolean;
  isReadOnlyAccess: boolean;
};

export type SectionListItem = {
  sectionId: string;
  courseCode: string;
  section: string;
  term: string;
  isAdded?: boolean;
};

export type OutstandingOverridesTransaction = {
  studentId: string;
  fullName: string;
  clientId: string;
  clientName: string;
  accountType: string;
  transactionId: string;
  transactionType: string;
  transactionAdditionalInfo: GLEntry | SellDraft;
  cashDrawerAdditionalInfo: CashDrawer;
};

export type TransactionAdditionalInfo = {
  serialNo?: string;
  payee?: string;
  currencyType?: string;
  amount?: string;
  fee?: string;
  from?: string;
  to?: string;
  reason?: string;
  comment?: string;
};

export type AccountAdditionalInfo = {
  hasOverdraftProtection?: boolean;
  overdraftType?: string;
  overdraftLimit?: number;
  type?: string;
};

export type GlobalSettings = {
  adminOverrideCode?: string;
  primeInterestRate?: string;
  usCashBuyRate?: number;
  usCashSellRate?: number;
  usChequeBuyRate?: number;
  usChequeSellRate?: number;
  cashDrawerStartingBalance?: number;
  cashDrawerHighThreshold?: number;
  cashDrawerLowThreshold?: number;
  draftOverrideDenomination?: number;
  generalLedgerOverrideDenomination?: number;
  chequeWarningMessageDenomination?: number;
};
