import { Divider, IconButton } from "@mui/material";
import { CreditCardTransaction, IdName } from "types";
import SummaryRowTemplate from "./SummaryRowTemplate";
import { openConfirm } from "components/confirmBox";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";

import * as fields from "pages/transactions/creditCardTransactionFieldDefinitions";
import { getMaskedMoneyValue } from "common/functions";

type SummaryRowCreditCardProps = {
  transaction: CreditCardTransaction;
  onDeleteClick(): void;
  isTxnForAccWithOverLimitBal: boolean;
  creditCardOptions: IdName[];
  isReadOnlyView: boolean;
};

export default function SummaryRowCreditCard(props: SummaryRowCreditCardProps) {
  const fromToLabel = fields.creditCard.getOptionLabel
    ? fields.creditCard.getOptionLabel(
        props.transaction.creditCard ?? "",
        props.creditCardOptions
      )
    : "";
  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  return (
    <SummaryRowTemplate
      transaction={
        props.transaction.type === "payment"
          ? "Credit Card - Payment"
          : "Credit Card - Cash Advance"
      }
      credit={props.transaction.type === "payment" ? transactionAmount : ""}
      debit={props.transaction.type === "cashadvance" ? transactionAmount : ""}
      isTxnForAccWithOverLimitBal={props.isTxnForAccWithOverLimitBal}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection style={{ textAlign: "center" }}>From</TextSection>
        <TextSection style={{ textAlign: "center" }}> To</TextSection>
        <TextSection style={{ textAlign: "center" }}>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>
          {props.transaction.type === "payment"
            ? "Session Balance"
            : fromToLabel}
        </TextSection>
        <TextSection>
          {props.transaction.type === "payment"
            ? fromToLabel
            : "Session Balance"}
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${transactionAmount} CAD`}
        </TextSection>
      </DetailsRow>
      <DetailsRow isBold={true}>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          <IconButton
            style={{ margin: "-1em 0" }}
            aria-label="delete"
            onClick={() => {
              openConfirm({
                title: "Delete Transaction?",
                description:
                  props.transaction.type === "payment"
                    ? `Credit Card - Payment of ${transactionAmount} to ${fromToLabel}`
                    : `Credit Card - Cash Advance of ${transactionAmount} from ${fromToLabel}`,
                callback: () => {
                  props.onDeleteClick();
                },
              });
            }}
            disabled={props.isReadOnlyView}
          >
            <DeleteIcon />
          </IconButton>
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
