import {
  CashDrawerRoute,
  PersonalClientInformationRoute,
  CreateEmployerRoute,
  GeneralLedgerRoute,
  LessonsRoute,
  SearchClientsRoute,
  SessionHistoryRoute,
  StudentSessionsRoute,
} from "components/paths";
import { useHistory } from "react-router";

import { ReactNode, useState } from "react";
import styled from "styled-components";
import { FieldError } from "types";
import { CircularProgress, useScrollTrigger } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import UserInfo from "components/user/UserInfo";
import { getLoginInfo } from "common/azureAD";
import { useActions, useReduxState } from "store";
import GroupIcon from "@mui/icons-material/Group";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SideMenu from "components/sidemenu/SideMenu";
import { convertUTCToLocal, getMaskedMoneyValue } from "common/functions";
import { useEffect } from "react";
import AppBarWithMenuTemplate from "components/mainTemplate/AppBarWithMenuTemplate";
import useEventListener from "components/hooks/useEventListener";
import { desktopViewThreshold } from "common/helpers";
import WarningIcon from "@mui/icons-material/Warning";

type Props = {
  title?: string;
  studentId?: string;
  lessonName?: string;
  children: ReactNode;
  appBarRight?: ReactNode;
  errorsArray?: FieldError[];
  isSessionHistory?: boolean;
  onErrorClick?(fieldID: string): void;
  isLoading: boolean;
  cashDrawerBalance?: number;
};

export default function MainTemplate(props: Props) {
  const history = useHistory();

  useEffect(() => {
    if (!getGlobalSettings.data?.globalSettings) {
      globalSettingsActions.getGlobalSettings({});
    }
  }, []);

  const {
    lessonId,
    getLessonNameAndData,
    getDataforViewAsStudent,
  } = useReduxState((e) => e.lesson);
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  const globalSettingsActions = useActions().globalsettings;
  const loginInfo = getLoginInfo();
  const [windowSize, setWindowSize] = useState(getSize());
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  useEventListener("resize", () => setWindowSize(getSize()));
  useEventListener("scroll", () => {
    setIsScrolled(window.scrollY > 0);
  });

  let cashDrawerBalance = getMaskedMoneyValue(props.cashDrawerBalance ?? 0);

  let cashDrawerLowThreshold =
    getGlobalSettings.data?.globalSettings.cashDrawerLowThreshold;

  let cashDrawerHighThreshold =
    getGlobalSettings.data?.globalSettings.cashDrawerHighThreshold;

  const instructorMenuGroups = [
    {
      label: "",
      items: [
        {
          label: "Client Search",
          icon: <PersonIcon />,
          onClick: () => {
            !!props.studentId
              ? history.push(
                  `/${lessonId}${SearchClientsRoute}/${props.studentId}`
                )
              : history.push(`/${lessonId}${SearchClientsRoute}`);
          },
        },
        {
          label: "General Ledger",
          icon: <AccountBalanceIcon />,
          onClick: () => {
            !!props.studentId
              ? history.push(
                  `/${lessonId}${GeneralLedgerRoute}/${props.studentId}`
                )
              : history.push(`/${lessonId}${GeneralLedgerRoute}`);
          },
        },
        {
          label: "Session History",
          icon: <ReceiptLongIcon />,
          onClick: () => {
            !!props.studentId
              ? history.push(
                  `/${lessonId}${SessionHistoryRoute}/${props.studentId}`
                )
              : history.push(`/${lessonId}${SessionHistoryRoute}`);
          },
        },
      ],
    },
    {
      label: "",
      items: !!props.studentId
        ? [
            {
              label: "Employers",
              icon: <GroupIcon />,
              onClick: () => {
                history.push(
                  `/${lessonId}${CreateEmployerRoute}/${props.studentId}`
                );
              },
            },
          ]
        : [
            {
              label: "Create Client",
              icon: <PersonAddIcon />,
              onClick: () => {
                history.push(`/${lessonId}${PersonalClientInformationRoute}`);
              },
            },
            {
              label: "Employers",
              icon: <GroupIcon />,
              onClick: () => {
                history.push(`/${lessonId}${CreateEmployerRoute}`);
              },
            },
          ],
    },
    {
      label: "",
      items: [
        {
          label: `Cash Drawer`,
          label2:
            !props.cashDrawerBalance ||
            !getGlobalSettings.data?.globalSettings ? (
              <CircularProgress
                size={15}
                style={{ marginLeft: 20, color: "white" }}
              />
            ) : (
              `${cashDrawerBalance}`
            ),
          icon: <AttachMoneyIcon />,
          label2Color:
            props.cashDrawerBalance &&
            props.cashDrawerBalance >= (cashDrawerLowThreshold ?? 0) &&
            props.cashDrawerBalance <= (cashDrawerHighThreshold ?? 0)
              ? "#66ff00"
              : "#ffa500",
          onClick: () => {
            !!props.studentId
              ? history.push(
                  `/${lessonId}${CashDrawerRoute}/${props.studentId}`
                )
              : history.push(`/${lessonId}${CashDrawerRoute}`);
          },
        },
      ],
    },
    {
      label: "Global",
      items: [
        {
          label: "Administration",
          icon: <AdminPanelSettingsIcon />,
          onClick: () => {
            history.push(StudentSessionsRoute);
          },
        },
      ],
    },
  ];

  const studentMenuGroups = [
    {
      label: "",
      items: [
        {
          label: "Client Search",
          icon: <PersonIcon />,
          onClick: () => {
            history.push(`/${lessonId}${SearchClientsRoute}`);
          },
        },
        {
          label: "General Ledger",
          icon: <AccountBalanceIcon />,
          onClick: () => {
            history.push(`/${lessonId}${GeneralLedgerRoute}`);
          },
        },
        {
          label: "Session History",
          icon: <ReceiptLongIcon />,
          onClick: () => {
            !!props.studentId
              ? history.push(
                  `/${lessonId}${SessionHistoryRoute}/${props.studentId}`
                )
              : history.push(`/${lessonId}${SessionHistoryRoute}`);
          },
        },
      ],
    },
    {
      label: "",
      items: [
        {
          label: "Create Client",
          icon: <PersonAddIcon />,
          onClick: () => {
            history.push(`/${lessonId}${PersonalClientInformationRoute}`);
          },
        },
        {
          label: "Employers",
          icon: <GroupIcon />,
          onClick: () => {
            history.push(`/${lessonId}${CreateEmployerRoute}`);
          },
        },
      ],
    },
    {
      label: "",
      items: [
        {
          label: `Cash Drawer`,
          label2:
            !props.cashDrawerBalance ||
            !getGlobalSettings.data?.globalSettings ? (
              <CircularProgress
                size={15}
                style={{ marginLeft: 20, color: "white" }}
              />
            ) : (
              `${cashDrawerBalance}`
            ),
          icon: <AttachMoneyIcon />,
          label2Color:
            props.cashDrawerBalance &&
            props.cashDrawerBalance >= (cashDrawerLowThreshold ?? 0) &&
            props.cashDrawerBalance <= (cashDrawerHighThreshold ?? 0)
              ? "#66ff00"
              : "#ffa500",
          onClick: () => {
            history.push(`/${lessonId}${CashDrawerRoute}`);
          },
        },
      ],
    },
  ];

  const isReadOnlyStudentAccess = getLessonNameAndData.data?.isReadOnly;
  const startDateTime = getLessonNameAndData.data?.startDateTime;
  const endDateTime = getLessonNameAndData.data?.endDateTime;

  var infoMessage = isReadOnlyStudentAccess
    ? "Read only mode. No changes can be made."
    : startDateTime && endDateTime
    ? "Time based access to Lesson. Changes can only be made until " +
      convertUTCToLocal(endDateTime) +
      "."
    : "";

  var showInfoMessage =
    !loginInfo?.isInstructor &&
    (isReadOnlyStudentAccess || (!!startDateTime && !!endDateTime));

  const isDesktopView = windowSize.width >= desktopViewThreshold;

  return (
    <OuterContainer>
      <AppBarWithMenuTemplate
        menu={
          <SideMenu
            hideTitleBar={false}
            label={"NAITLab Financial"}
            menuGroups={
              loginInfo?.isInstructor ? instructorMenuGroups : studentMenuGroups
            }
            subTitleLabel={
              !!props.studentId ? (
                getDataforViewAsStudent.data?.lessonName ? (
                  `${getDataforViewAsStudent.data?.lessonName} - ${getDataforViewAsStudent.data?.studentName}`
                ) : getDataforViewAsStudent.error ? (
                  "No Lesson"
                ) : (
                  <CircularProgress
                    size={15}
                    style={{ marginLeft: 20, color: "white" }}
                  />
                )
              ) : (
                getLessonNameAndData.data?.lessonName ?? (
                  <CircularProgress
                    size={15}
                    style={{ marginLeft: 20, color: "white" }}
                  />
                )
              )
            }
            onClickSubtitle={() => {
              history.push(LessonsRoute);
            }}
          />
        }
        appBarTitle={props.title ?? ""}
        appBarRightContent={
          <>
            {props.appBarRight}
            <UserInfo {...loginInfo} />
          </>
        }
        contentBackgroundColor={"#F3F6F9"}
        isLoading={props.isLoading}
        isDesktopView={isDesktopView}
        isScrolled={isScrolled}
      >
        <ContentContainer
          errorExists={
            props.errorsArray ? props.errorsArray?.length > 0 : false
          }
          isSessionHistory={props.isSessionHistory ?? false}
          infoMessageDisplayed={showInfoMessage}
        >
          {props.errorsArray && props.errorsArray?.length > 0 && (
            <ErrorSummary
              errorsArray={props.errorsArray ?? []}
              onErrorClick={props.onErrorClick ? props.onErrorClick : () => {}}
              infoMessageDisplayed={showInfoMessage}
              isDesktopView={isDesktopView}
            />
          )}
          {showInfoMessage && (
            <InfoMessage message={infoMessage} isDesktopView={isDesktopView} />
          )}

          {props.children}
        </ContentContainer>
      </AppBarWithMenuTemplate>
    </OuterContainer>
  );
}

type ErrorSummaryProps = {
  errorsArray: FieldError[];
  onErrorClick(errorID: string): void;
  infoMessageDisplayed?: boolean;
  isDesktopView: boolean;
};
function getSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}
const ErrorSummary = (props: ErrorSummaryProps) => {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
  // const [windowSize, setWindowSize] = useState(getSize());
  // useEventListener("resize", () => setWindowSize(getSize()));

  // const isDesktopView = windowSize.width >= desktopViewThreshold;
  return (
    <StyledDiv
      $scrolled={trigger}
      $isDesktopView={props.isDesktopView}
      $isInfoMessageDisplayed={props.infoMessageDisplayed ?? false}
    >
      <ErrorSummaryInnerDiv>
        <BoldLabel>Fix the following errors:</BoldLabel>{" "}
        {props.errorsArray.map((e, index) => {
          if (index < 5)
            return (
              <CustomLabel
                key={index}
                onClick={() => {
                  props.onErrorClick(e.id);
                }}
              >
                {" "}
                {e.label}
                {props.errorsArray.length - index !== 1 && ","}
              </CustomLabel>
            );
          else if (index === 5)
            return (
              <BoldLabel key={index}>
                {" + "}
                {props.errorsArray.length - index}
                {" more"}
              </BoldLabel>
            );
          else return "";
        })}
      </ErrorSummaryInnerDiv>
    </StyledDiv>
  );
};

const InfoMessage = (props: { message: string; isDesktopView: boolean }) => {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
  // const [windowSize, setWindowSize] = useState(getSize());
  // useEventListener("resize", () => setWindowSize(getSize()));

  // const isDesktopView = windowSize.width >= desktopViewThreshold;
  return (
    <InfoMessageContainer
      $scrolled={trigger}
      $isDesktopView={props.isDesktopView}
    >
      <div style={{ color: "#facc15" }}>
        <WarningIcon />
      </div>
      <div style={{ color: "#a16207" }}>
        {/* Read only mode. No changes can be made. */}
        {props.message}
      </div>
    </InfoMessageContainer>
  );
};

const StyledDiv = styled.div<{
  $scrolled: boolean;
  $isDesktopView: boolean;
  $isInfoMessageDisplayed: boolean;
}>`
  position: fixed !important;
  color: #d32f2f;
  width: ${(props) => (props.$isDesktopView ? "calc(100% - 250px)" : "100%")};
  top: ${(props) => (props.$isInfoMessageDisplayed ? "108px" : "64px")};
  left: ${(props) => (props.$isDesktopView ? "300px" : "0px")};

  background-color: #fdd1cc;
  padding: 20px 0px;
  z-index: 1000;
  box-shadow: ${(props) =>
    props.$scrolled
      ? "0px 1px 2px 0px rgba(60, 64, 67, 0.3),0px 1px 3px 1px rgba(60, 64, 67, 0.15)"
      : "none"};
`;

const CustomLabel = styled.label`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ErrorSummaryInnerDiv = styled.div`
  width: 800px;
  margin: auto;
`;

const BoldLabel = styled.label`
  font-weight: bold;
`;
const OuterContainer = styled.div`
  background-color: #f3f6f9;
  min-height: 100vh;
`;

const ContentContainer = styled.div<{
  errorExists: boolean;
  isSessionHistory: boolean;
  infoMessageDisplayed: boolean;
}>`
  margin-top: ${(props) =>
    props.isSessionHistory
      ? "0px"
      : props.errorExists && props.infoMessageDisplayed
      ? "140px"
      : props.errorExists
      ? "100px"
      : props.infoMessageDisplayed
      ? "60px"
      : "30px"};
  padding: ${(props) => (props.isSessionHistory ? "0px" : "0 1em 2em 1em")};
`;

const InfoMessageContainer = styled.div<{
  $scrolled: boolean;
  $isDesktopView: boolean;
}>`
  position: fixed !important;
  color: #d32f2f;
  width: ${(props) => (props.$isDesktopView ? "calc(100% - 250px)" : "100%")};
  top: 64px;
  left: ${(props) => (props.$isDesktopView ? "300px" : "0px")};
  background-color: #fefce8;
  padding: 8px;
  z-index: 1000;
  box-shadow: ${(props) =>
    props.$scrolled
      ? "0px 1px 2px 0px rgba(60, 64, 67, 0.3),0px 1px 3px 1px rgba(60, 64, 67, 0.15)"
      : "none"};
  display: flex;
  gap: 0.5em;
  color: #facc15;
`;
