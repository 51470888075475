import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Entity, CommonFieldProps, RadioGroupField } from "types";
import { FieldContainer } from "./commonFieldStyles";

export default function RadioGroupEditor<T extends Entity>({
  field,
  state,
  setState,
  errors,
  customStyle,
}: CommonFieldProps<T> & {
  customStyle?: React.CSSProperties;
}) {
  const errObj = errors.find((e) => e.id === field.id);

  const radioButton = field as RadioGroupField<T>;

  return (
    <FieldContainer>
      <FormControl
        error={!!errObj}
        required={
          field.isRequired ||
          (field.checkIfRequired && field.checkIfRequired(state))
        }
        size="small"
        disabled={
          field.disabled ||
          (field.checkIfDisabled && field.checkIfDisabled(state))
        }
        style={customStyle}
      >
        <FormLabel component="legend">{radioButton.label}</FormLabel>
        <RadioGroup
          row
          aria-label={radioButton.label.toLowerCase()}
          name="controlled-radio-buttons-group"
          value={radioButton.getValue(state) ?? radioButton.defaultValue}
          onChange={(e) =>
            setState(radioButton.setValue(e.target.value, state))
          }
        >
          <FormControlLabel
            value={radioButton.trueLabel}
            control={<Radio size="small" required />}
            label={radioButton.trueLabel}
          />
          <FormControlLabel
            value={radioButton.falseLabel}
            control={<Radio size="small" required />}
            label={radioButton.falseLabel}
          />
        </RadioGroup>
        {!!errObj && (
          <FormHelperText style={{ color: "#d32f2f" }}>
            {errObj.error}
          </FormHelperText>
        )}
      </FormControl>
    </FieldContainer>
  );
}
