import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import FieldDisplay from "components/FieldDisplay";
import styled from "styled-components";
import { PersonalClient } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientFieldDefinitions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function InformationSection(props: { client: PersonalClient }) {
  const commonProps = {
    state: props.client,
  };
  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="informationSection-header"
        >
          <Typography variant={"h6"}>Client Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldDisplay<PersonalClient>
                field={fields.clientId}
                {...commonProps}
              />
              <FieldDisplay<PersonalClient>
                field={fields.legalFirstName}
                {...commonProps}
              />
              <FieldDisplay<PersonalClient>
                field={fields.legalLastName}
                {...commonProps}
              />
              <FieldDisplay<PersonalClient>
                field={fields.preferredFirstName}
                {...commonProps}
              />
              <FieldDisplay<PersonalClient>
                field={fields.dateOfBirth}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldDisplay<PersonalClient>
                field={fields.accountType}
                {...commonProps}
              />
              <FieldDisplay<PersonalClient>
                field={fields.preferredPronouns}
                {...commonProps}
              />
              <FieldDisplay<PersonalClient>
                field={fields.comment}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  margin-top: 2em;
`;
