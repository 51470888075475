import { Button } from "@mui/material";
import { getMaskedMoneyValue, subtract } from "common/functions";
import styled from "styled-components";

export default function StartingBalanceAndOverride(props: {
  calculatedTotal: number;
  cashDrawerBalance: number;
  startingTotal: number;
  overrideStatus: string;
  isReadOnlyView: boolean;
  onEndOfDay(): void;
  onOverride(): void;
}) {
  let difference = subtract(props.calculatedTotal, props.cashDrawerBalance);
  return (
    <Container>
      <LeftSection>
        <FlexDiv>
          <ItemContainer>
            <LightBold>Calculated Total</LightBold>
            {getMaskedMoneyValue(props.calculatedTotal)}
          </ItemContainer>
          <Spacer />
          <ItemContainer>
            <LightBold>Cash Drawer</LightBold>
            {getMaskedMoneyValue(props.cashDrawerBalance)}
          </ItemContainer>
        </FlexDiv>
        <FlexDiv>
          <ItemContainer>
            <LightBold>Starting Total</LightBold>
            {getMaskedMoneyValue(props.startingTotal)}
          </ItemContainer>
          <Spacer />
          <ItemContainer>
            <LightBold>Difference</LightBold>
            <span style={{ color: difference === 0 ? "#17a110" : "#dd0e0e" }}>
              {getMaskedMoneyValue(difference)}
            </span>
          </ItemContainer>
        </FlexDiv>
      </LeftSection>
      <RightSection>
        <ItemContainer>
          <LightBold>Override Status</LightBold>
          {props.overrideStatus}
        </ItemContainer>

        <FlexDiv>
          <ItemContainer>
            <Button
              variant="contained"
              disabled={
                difference !== 0 ||
                props.overrideStatus.toLowerCase() === "under review" ||
                props.isReadOnlyView
              }
              onClick={props.onEndOfDay}
            >
              End Of Day
            </Button>
            <Spacer />
            <Button
              variant="contained"
              disabled={
                props.overrideStatus.toLowerCase() !== "under review" ||
                props.isReadOnlyView
              }
              onClick={props.onOverride}
            >
              Override
            </Button>
          </ItemContainer>
        </FlexDiv>
      </RightSection>
    </Container>
  );
}

const LightBold = styled.div`
  font-weight: 500;
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const LeftSection = styled.div`
  flex: 1;
  text-align: left;
  padding: 1em;
  border-right: solid thin rgba(0, 0, 0, 0.12); ;
`;
const RightSection = styled.div`
  text-align: left;
  padding: 1em;
`;

const ItemContainer = styled.div`
  display: flex;
  width: 270px;
  padding: 0.5em 0.75em;
`;
const FlexDiv = styled.div`
  display: flex;
`;
const Spacer = styled.div`
  margin: 0em 1em;
`;
