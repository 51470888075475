import { getExchangeRate } from "common/functions";
import { useReduxState } from "store";
import {  
  FIELD_TYPE,  
  MASK_TYPE,
  PickListField,
  TextFieldType,
  ForeignExchangeConverter,
} from "types";

export const currencyExchanging: PickListField<ForeignExchangeConverter> = {
  id: "currencyExchanging",
  label: "Currency Exchanging",
  defaultValue: "usd",
  type: FIELD_TYPE.PICKLIST,
  getValue: (feConverter: ForeignExchangeConverter) =>  feConverter.currencyExchanging,
  setValue: (value, feConverter) => ({...feConverter,currencyExchanging: value }),
  options: [
    { id: "usd", name: "US Dollars - USD" },
  ],
  disabled: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const transactionType: PickListField<ForeignExchangeConverter> = {
  id: "transactionType",
  label: "Transaction Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (feConverter: ForeignExchangeConverter) =>  feConverter.transactionType,
  setValue: (value, feConverter) => ({...feConverter,transactionType: value }),
  options: [
    { id: "buycash", name: "Buy Cash" },
    { id: "buycheque", name: "Buy Cheque" },
    { id: "sellcash", name: "Sell Cash" },
    { id: "sellcheque", name: "Sell Cheque" },
  ],
 
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};


export const convertCurrency: TextFieldType<ForeignExchangeConverter> = {
  id: "convertCurrency",
  label: "Convert",
  type: FIELD_TYPE.TEXT,
  getValue: (feConverter: ForeignExchangeConverter) => feConverter.convertCurrency,
  setValue: (value, feConverter) => ({
    ...feConverter,
    convertCurrency: value,
  }),
  mask: MASK_TYPE.MONEY
};

export const intoCurrency: TextFieldType<ForeignExchangeConverter> = {
  id: "intoCurrency",
  label: "Into",
  type: FIELD_TYPE.TEXT,
  getValue: (feConverter: ForeignExchangeConverter) => {
    let convertedValue = getConvertedValue(feConverter)
    return !!feConverter.convertCurrency ? convertedValue.toString() : "" },
  setValue: (value, feConverter) => ({
    ...feConverter,
    intoCurrency: value,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
};

function getConvertedValue(feConverter: ForeignExchangeConverter)
{
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
  let rate: number = getExchangeRate(feConverter, globalSettings)

  return (feConverter.convertCurrency ?? 0) as unknown as number * rate
 
}
