import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { Employer } from "types";

//uncomment if you need msal auth
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "employers";
const baseURL = `${process.env.REACT_APP_API_BASE}/employer`;

type State = {
  saveEmployer: ReducerState<SaveEmployerReturn>;
  deleteEmployer: ReducerState<DeleteEmployerReturn>;
  getAllEmployers: ReducerState<Employer[]>;
  getNewEmployerId: ReducerState<GetNewEmployerIdReturn>;
};

type SaveEmployerReturn = {
  employerId: number;
};

let saveEmployer = createAsyncAction<
  SaveEmployerReturn, //return type
  { employer: Employer, lessonId: number, onComplete?(retValue: SaveEmployerReturn): void  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "saveEmployer",
  url: baseURL + "/saveEmployer",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.saveEmployer = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveEmployer.isLoading = false;
    state.saveEmployer.data = action.payload;
  },
  rejected: (state, action) => {
    state.saveEmployer.isLoading = false;
    state.saveEmployer.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as SaveEmployerReturn))
},
});

type DeleteEmployerReturn = {
  success: boolean;
};
let deleteEmployer = createAsyncAction<
DeleteEmployerReturn, //return type
  { employerId: string, lessonId: number, onComplete?(retValue: DeleteEmployerReturn): void  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "deleteEmployer",
  url: baseURL + "/deleteEmployer",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.deleteEmployer = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.deleteEmployer.isLoading = false;
    state.deleteEmployer.data = action.payload;
  },
  rejected: (state, action) => {
    state.deleteEmployer.isLoading = false;
    state.deleteEmployer.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as DeleteEmployerReturn))
},
});


function EmployerListSorter(a: Employer, b: Employer)
{
  if(a.employerName && b.employerName)
  {
  if(a.employerName < b.employerName) { return -1; }
    if(a.employerName > b.employerName) { return 1; }
  }
    return 0;
}

let getAllEmployers = createAsyncAction<
  Employer[], //return type
  { onComplete?(retVal: Employer[]): void }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getAllEmployers",
  url: baseURL + "/getAllEmployers",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.getAllEmployers = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getAllEmployers.isLoading = false;
    const employersList = action.payload
    state.getAllEmployers.data = employersList.sort(EmployerListSorter);
  },
  rejected: (state, action) => {
    state.getAllEmployers.isLoading = false;
    state.getAllEmployers.error = action.payload;
  },
  onComplete: (payload) => {
      payload.params.onComplete && 
        payload.params.onComplete((payload.data as Employer[]))
  },
});


type GetNewEmployerIdReturn = {
  employerId: number
};


let getNewEmployerId = createAsyncAction<
GetNewEmployerIdReturn,//return type
  {onComplete?(retVal: GetNewEmployerIdReturn): void },//params
  State
>({
  actionPrefix: controlName,
  actionName: "getNewEmployerId",
  url: baseURL+ "/getNewEmployerId",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
     state.getNewEmployerId.isLoading = true;
  },
  fulfilled: (state, action) => {
     state.getNewEmployerId.isLoading=false;
     state.getNewEmployerId.data = action.payload;
  },
  rejected: (state, action) => {
     state.getNewEmployerId.isLoading=false;
     state.getNewEmployerId.error=action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as GetNewEmployerIdReturn))
}
});

let slice = createSlice({
  name: controlName,
  initialState: {
      saveEmployer: {isLoading:false},
      deleteEmployer:{isLoading:false},
      getNewEmployerId: {isLoading:false},
      getAllEmployers: {isLoading: false}
  } as State,
  reducers: {},
  extraReducers:{
    ...saveEmployer.reducer,
    ...deleteEmployer.reducer,
    ...getNewEmployerId.reducer,
    ...getAllEmployers.reducer
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
        ...slice.actions,
        saveEmployer: saveEmployer.action,
        deleteEmployer: deleteEmployer.action,
        getNewEmployerId: getNewEmployerId.action,
        getAllEmployers: getAllEmployers.action
    },
  },
};

export default ret;