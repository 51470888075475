import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  Account,
  BillPayment,
  CreditCardTransaction,
  Deposit,
  GeneralLedger,
  GLAccount,
  GLEntry,
  PayoutCash,
  PersonalClient,
  Receive,
  SellDraft,
  Transfer,
  Withdrawal,
} from "types";
import { Transaction, TransactionSession } from "types";
import { useActions, useReduxState } from "store";
import { useHistory } from "react-router-dom";
import {
  PersonalClientAccountHistoryRoute,
  UnauthorizedRoute,
} from "components/paths";
import AddTransactionSection from "pages/transactions/components/AddTransactionSection";
import SummaryAccountSection from "pages/transactions/components/SummaryAccountSection";
import AddTransactionsMenu from "pages/transactions/components/AddTransactionsMenu";
import {
  add,
  getOpenAccountOptionsForAccountHistory,
  getOpenAccounts,
  getPersonalClientName,
  getDepositWithdrawOptions,
  getNewDepositTransaction,
  getNewReceiveTransaction,
  getNewTransactionSession,
  subtract,
  getNewWithdrawalTransaction,
  getNewPayoutTransaction,
  getNewBillPaymentTransaction,
  getNewCreditCardTransaction,
  getCreditCardOptionsForTransactionSummary,
  getNewTransferTransaction,
  getNewSellDraftTransaction,
  getGLEntryClientAccountOptionsForTxnSummary,
  getNewGLEntryTransaction,
  checkIfUnauthorized,
} from "common/functions";
import ClientInfoTemplate from "components/clientInfoTemplate/ClientInfoTemplate";
import { openSnackbar } from "components/snackbar";
import { assetsAccountsTypes } from "common/helpers";
import { getPersonalClientById, getGeneralLedger } from "pages/lessons/helpers";

export type Props = {
  clientId: string;
  lessonId: string;
  studentId?: string;
};

export default function PersonalClientTransactions(props: Props) {
  const [state, setState] = useState<PersonalClient>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    getLessonNameAndData,
    lessonId,
    getDataforViewAsStudent,
  } = useReduxState((e) => e.lesson);
  const lessonActions = useActions().lesson;
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );

  const transactionsActions = useActions().transactions;

  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }
  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            if (props.clientId) {
              let client = getPersonalClientById(
                props.clientId,
                payload.lessonData
              );

              if (!!client.identification?.clientId) setState(client);
              else history.push(UnauthorizedRoute);
              setActiveSession(
                client.transactionSessions?.find(
                  (s) => s.sessionId === client.activeSessionId
                )
              );
            }
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getLessonNameAndData.data?.lessonData));
        if (props.clientId) {
          const client = getPersonalClientById(
            props.clientId,
            getLessonNameAndData.data.lessonData
          );
          setState(client);
          setActiveSession(
            client.transactionSessions?.find(
              (s) => s.sessionId === client.activeSessionId
            )
          );
          setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
          setIsLoading(false);
        }
      }
    }
    if (!!props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            if (props.clientId) {
              const client = getPersonalClientById(
                props.clientId,
                payload.lessonData
              );
              setState(client);
              setActiveSession(
                client.transactionSessions?.find(
                  (s) => s.sessionId === client.activeSessionId
                )
              );
            }
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getDataforViewAsStudent.data?.lessonData));
        if (props.clientId) {
          const client = getPersonalClientById(
            props.clientId,
            getDataforViewAsStudent.data.lessonData
          );
          setState(client);
          setActiveSession(
            client.transactionSessions?.find(
              (s) => s.sessionId === client.activeSessionId
            )
          );
          setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
          setIsLoading(false);
        }
      }
    }
  }, []);

  const clientName = getPersonalClientName(state);

  const [activeTransaction, setActiveTransaction] = useState<
    Transaction | undefined
  >(undefined);
  const [activeSession, setActiveSession] = useState<
    TransactionSession | undefined
  >(undefined);

  function startTransactionSession() {
    if (!!activeSession) return;
    const newTransactionSession: TransactionSession = {
      ...getNewTransactionSession(),
    };
    setActiveSession(newTransactionSession);
    setState({ ...state, activeSessionId: newTransactionSession.sessionId });
  }

  function savePersonalClientAndGL(
    updatedClient: PersonalClient,
    updatedGL: GeneralLedger
  ) {
    transactionsActions.savePersonalClientAndGL({
      client: updatedClient,
      gl: updatedGL,
      lessonId,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.savePersonalClientAndGL({
            updatedClient: updatedClient,
            updatedGL: updatedGL,
          });
          openSnackbar({
            severity: "success",
            message: `Transactions posted successfully`,
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error posting transactions. Please refresh the page and try again!`,
          });
        }
      },
    });
  }

  function savePersonalClient(
    updatedClient: PersonalClient,
    isPostTransaction: boolean
  ) {
    transactionsActions.savePersonalClient({
      client: updatedClient,
      lessonId,
      onComplete: (payload) => {
        if (!!payload.clientId) {
          lessonActions.savePersonalClient(updatedClient);
          isPostTransaction &&
            openSnackbar({
              severity: "success",
              message: `Transactions posted successfully`,
            });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error ${
              isPostTransaction ? "posting" : "updating"
            } transactions. Please refresh the page and try again!`,
          });
        }
      },
    });
  }

  function updateClientAndGL(
    updatedClient: PersonalClient,
    glEntryTransactions: GLEntry[],
    sellDraftTxnsWithFee: SellDraft[],
    receiveCashTransactions: Receive[],
    payoutCashTransactions: PayoutCash[]
  ) {
    let glEntryDepositGLAccountIds = glEntryTransactions
      .filter((t) => t.glType === "credit")
      .map((t) => t.glAccount);
    let glEntryWithdrawGLAccountIds = glEntryTransactions
      .filter((t) => t.glType === "debit")
      .map((t) => t.glAccount);

    let sellDraftFeeTotal = sellDraftTxnsWithFee
      .map((sdt) => sdt.fee ?? 0)
      .reduce(add, 0);
    let sellDraftTxnIds = sellDraftTxnsWithFee.map((sdt) => sdt.transactionId);

    let receiveCashTxnIds: string[] = receiveCashTransactions.map(
      (t) => t.transactionId
    );

    let receiveCashAmountArr: number[] = receiveCashTransactions.map(
      (t) => t.cash?.cashCADValue ?? 0
    );
    let totalReceivedCash: number = receiveCashAmountArr.reduce(add, 0);

    let payoutCashTxnIds: string[] = payoutCashTransactions.map(
      (t) => t.transactionId
    );
    let payoutCashAmountArr: number[] = payoutCashTransactions.map(
      (t) => t.amount ?? 0
    );
    let totalPayoutCash: number = payoutCashAmountArr.reduce(add, 0);

    let netcashInOutAmount = subtract(totalReceivedCash, totalPayoutCash);

    let newGLState: GeneralLedger = {
      ...glState,
      glAccounts: glState.glAccounts?.map((gla) => {
        if (
          glEntryDepositGLAccountIds.includes(gla.glAccountId) ||
          glEntryWithdrawGLAccountIds.includes(gla.glAccountId) ||
          (sellDraftFeeTotal > 0 && gla.glAccountId === "service_charges")
        ) {
          let glDepositTxns = glEntryTransactions.filter(
            (t) => t.glType === "credit" && t.glAccount === gla.glAccountId
          );
          let glDepositAmount = glDepositTxns
            .map((t) => t.amount ?? 0)
            .reduce(add, 0);
          let glDepositTxnIds = glDepositTxns.map((t) => t.transactionId);

          let glWithdrawTxns = glEntryTransactions.filter(
            (t) => t.glType === "debit" && t.glAccount === gla.glAccountId
          );
          let glWithdrawAmount = glWithdrawTxns
            .map((t) => t.amount ?? 0)
            .reduce(add, 0);
          let glWithdrawTxnIds = glWithdrawTxns.map((t) => t.transactionId);

          let netAmount = subtract(glDepositAmount, glWithdrawAmount);

          let currentGLTxnIds = gla.transactionIds ?? [];

          let serviceChargesUpdatedAccBalance =
            gla.glAccountId === "service_charges"
              ? add(gla.balance ?? 0, sellDraftFeeTotal)
              : 0;

          let newGLAccount: GLAccount = {
            ...gla,
            balance:
              gla.glAccountId === "service_charges"
                ? add(serviceChargesUpdatedAccBalance, netAmount)
                : add(gla.balance ?? 0, netAmount),
            transactionIds:
              gla.glAccountId === "service_charges"
                ? [
                    ...currentGLTxnIds,
                    ...glDepositTxnIds,
                    ...glWithdrawTxnIds,
                    ...sellDraftTxnIds,
                  ]
                : [...currentGLTxnIds, ...glDepositTxnIds, ...glWithdrawTxnIds],
          };
          return newGLAccount;
        } else return gla;
      }),
      cashDrawer: {
        ...glState.cashDrawer,
        balance: add(glState.cashDrawer?.balance ?? 0, netcashInOutAmount),
        endOfDayTransactionIds: [
          ...(glState.cashDrawer?.endOfDayTransactionIds ?? []),
          ...receiveCashTxnIds,
          ...payoutCashTxnIds,
        ],
      },
    };
    setGLState(newGLState);
    savePersonalClientAndGL(updatedClient, newGLState);
  }

  function addReceive() {
    if (activeSession === undefined) {
      startTransactionSession();
    }
    const newTransaction: Receive = { ...getNewReceiveTransaction() };
    setActiveTransaction(newTransaction);
  }

  function addDeposit() {
    if (activeSession === undefined) {
      startTransactionSession();
    }
    const newTransaction: Deposit = { ...getNewDepositTransaction() };
    setActiveTransaction(newTransaction);
  }

  function addWithdrawal() {
    if (activeSession === undefined) {
      startTransactionSession();
    }
    const newTransaction: Withdrawal = { ...getNewWithdrawalTransaction() };
    setActiveTransaction(newTransaction);
  }

  function addPayoutCash() {
    if (activeSession === undefined) {
      startTransactionSession();
    }
    const newTransaction: PayoutCash = { ...getNewPayoutTransaction() };
    setActiveTransaction(newTransaction);
  }

  function addBillPayment() {
    if (activeSession === undefined) {
      startTransactionSession();
    }
    const newTransaction: BillPayment = { ...getNewBillPaymentTransaction() };
    setActiveTransaction(newTransaction);
  }

  function addCreditCard() {
    if (activeSession === undefined) {
      startTransactionSession();
    }
    const newTransaction: CreditCardTransaction = {
      ...getNewCreditCardTransaction(),
    };
    setActiveTransaction(newTransaction);
  }

  function addTransfer() {
    if (activeSession === undefined) {
      startTransactionSession();
    }
    const newTransaction: Transfer = {
      ...getNewTransferTransaction(),
    };
    setActiveTransaction(newTransaction);
  }

  function addSellDraft() {
    if (activeSession === undefined) {
      startTransactionSession();
    }
    const newTransaction: SellDraft = {
      ...getNewSellDraftTransaction(),
    };
    setActiveTransaction(newTransaction);
  }

  function addGLEntry() {
    if (activeSession === undefined) {
      startTransactionSession();
    }
    const newTransaction: GLEntry = {
      ...getNewGLEntryTransaction(),
    };
    setActiveTransaction(newTransaction);
  }

  function saveReceive(transaction: Receive) {
    let currentTransactions: Transaction[] = activeSession?.transactions ?? [];
    let updatedActiveSession = {
      ...activeSession,
      transactions: [...currentTransactions, transaction],
      sessionBalance: add(
        activeSession?.sessionBalance ?? 0,
        transaction.amount ?? 0
      ),
    };
    updateActiveTransactionSession(updatedActiveSession);
    setActiveTransaction(undefined);
  }

  function savePayoutCashBillPaymentOrSellDraft(
    transaction: PayoutCash | BillPayment | SellDraft
  ) {
    let currentTransactions: Transaction[] = activeSession?.transactions ?? [];
    let updatedActiveSession = {
      ...activeSession,
      transactions: [...currentTransactions, transaction],
      sessionBalance: subtract(
        activeSession?.sessionBalance ?? 0,
        transaction.amount ?? 0
      ),
    };
    updateActiveTransactionSession(updatedActiveSession);
    setActiveTransaction(undefined);
  }

  function saveDeposit(transaction: Deposit) {
    let currentTransactions: Transaction[] = activeSession?.transactions ?? [];

    let updatedActiveSession: TransactionSession = {
      ...activeSession,
      transactions: [...currentTransactions, { ...transaction }],

      sessionBalance: subtract(
        activeSession?.sessionBalance ?? 0,
        transaction.amount ?? 0
      ),
    };
    updateActiveTransactionSession(updatedActiveSession);
    setActiveTransaction(undefined);
  }

  function saveWithdrawal(transaction: Withdrawal) {
    let currentTransactions: Transaction[] = activeSession?.transactions ?? [];

    let updatedActiveSession: TransactionSession = {
      ...activeSession,
      transactions: [...currentTransactions, { ...transaction }],

      sessionBalance: add(
        activeSession?.sessionBalance ?? 0,
        transaction.amount ?? 0
      ),
    };
    updateActiveTransactionSession(updatedActiveSession);
    setActiveTransaction(undefined);
  }

  function saveCreditCard(transaction: CreditCardTransaction) {
    let currentTransactions: Transaction[] = activeSession?.transactions ?? [];

    let updatedActiveSession: TransactionSession = { ...activeSession };

    if (transaction.type === "payment") {
      updatedActiveSession = {
        ...activeSession,
        transactions: [...currentTransactions, { ...transaction }],

        sessionBalance: subtract(
          activeSession?.sessionBalance ?? 0,
          transaction.amount ?? 0
        ),
      };
    } else {
      updatedActiveSession = {
        ...activeSession,
        transactions: [...currentTransactions, { ...transaction }],

        sessionBalance: add(
          activeSession?.sessionBalance ?? 0,
          transaction.amount ?? 0
        ),
      };
    }

    updateActiveTransactionSession(updatedActiveSession);
    setActiveTransaction(undefined);
  }

  function saveTransfer(transaction: Transfer) {
    let currentTransactions: Transaction[] = activeSession?.transactions ?? [];

    let updatedActiveSession: TransactionSession = {
      ...activeSession,
      transactions: [...currentTransactions, { ...transaction }],
    };
    updateActiveTransactionSession(updatedActiveSession);
    setActiveTransaction(undefined);
  }

  function saveGLEntry(transaction: GLEntry) {
    let currentTransactions: Transaction[] = activeSession?.transactions ?? [];

    let isTxnToClientAcc: boolean = state.accounts?.some(
      (a) => a.accountId === transaction.clientAccount
    )
      ? true
      : false;
    let aType = isTxnToClientAcc
      ? state.accounts?.find((a) => a.accountId === transaction.clientAccount)
          ?.accountType === "PERSONAL_CHEQUING"
        ? "Chequing"
        : "Savings"
      : "Session Balance";

    let txnDescription = `Transfer - ${aType}`;

    let updatedActiveSession: TransactionSession = {
      ...activeSession,
      transactions: [
        ...currentTransactions,
        { ...transaction, transactionDescription: txnDescription },
      ],
      sessionBalance:
        transaction.glType === "debit" &&
        transaction.clientAccount === "sessionbalance"
          ? add(activeSession?.sessionBalance ?? 0, transaction.amount ?? 0)
          : transaction.glType === "credit" &&
            transaction.clientAccount === "sessionbalance"
          ? subtract(
              activeSession?.sessionBalance ?? 0,
              transaction.amount ?? 0
            )
          : activeSession?.sessionBalance,
    };
    updateActiveTransactionSession(updatedActiveSession);
    setActiveTransaction(undefined);
  }

  function updateActiveTransactionSession(updatedSession: TransactionSession) {
    let updatedActiveSession = updatedSession;
    let isSessionFound = state.transactionSessions?.find(
      (a) => a.sessionId === updatedActiveSession?.sessionId
    );
    let currentTransactionSessions = state.transactionSessions ?? [];

    let updatedSessions: TransactionSession[] =
      updatedActiveSession.transactions &&
      updatedActiveSession.transactions?.length > 0
        ? !!isSessionFound
          ? currentTransactionSessions.map((s) => {
              if (s.sessionId === updatedActiveSession.sessionId)
                return updatedActiveSession;
              else return s;
            })
          : [...currentTransactionSessions, updatedActiveSession]
        : currentTransactionSessions.filter(
            (s) => s.sessionId !== updatedActiveSession.sessionId
          );
    let updatedActiveSessionId =
      updatedActiveSession.transactions &&
      updatedActiveSession.transactions?.length > 0
        ? updatedActiveSession.sessionId
        : "";
    let updatedClient: PersonalClient = {
      ...state,
      transactionSessions: updatedSessions,
      activeSessionId: updatedActiveSessionId,
    };
    setState(updatedClient);
    savePersonalClient(updatedClient, false);
    setActiveSession(
      updatedActiveSession.transactions &&
        updatedActiveSession.transactions?.length > 0
        ? updatedActiveSession
        : undefined
    );
  }

  function onPostTransaction() {
    let receiveTransactions: Receive[] =
      activeSession?.transactions?.filter(
        (t) => t.transactionType === "RECEIVE"
      ) ?? [];
    let receiveCashTransactions: Receive[] = receiveTransactions.filter(
      (t) => t.cash && t.cash.totalCashAmount && t.cash.totalCashAmount > 0
    );

    let payoutCashTransactions: PayoutCash[] =
      activeSession?.transactions?.filter(
        (t) => t.transactionType === "PAYOUT_CASH"
      ) ?? [];

    let depositTransactions: Deposit[] =
      activeSession?.transactions?.filter(
        (t) => t.transactionType === "DEPOSIT"
      ) ?? [];

    let depositAccountIds = depositTransactions?.map((t) => t.to);

    let withdrawalTransactions: Withdrawal[] =
      activeSession?.transactions?.filter(
        (t) => t.transactionType === "WITHDRAWAL"
      ) ?? [];

    let withdrawalAccountIds = withdrawalTransactions?.map((t) => t.from);

    let creditCardTransactions: CreditCardTransaction[] =
      activeSession?.transactions?.filter(
        (t) => t.transactionType === "CREDIT_CARD"
      ) ?? [];

    let creditCardAccountIds = creditCardTransactions?.map((t) => t.creditCard);

    let transferTransactions: Transfer[] =
      activeSession?.transactions?.filter(
        (t) => t.transactionType === "TRANSFER"
      ) ?? [];

    let transferDepositAccountIds = transferTransactions.map((t) => t.to);
    let transferWithdrawalAccountIds = transferTransactions.map((t) => t.from);

    let glEntryTransactions: GLEntry[] =
      activeSession?.transactions?.filter(
        (t) => t.transactionType === "GL_ENTRY"
      ) ?? [];

    let glEntryDepositClientAccountIds = glEntryTransactions
      .filter(
        (t) => t.glType === "debit" && t.clientAccount !== "sessionbalance"
      )
      .map((t) => t.clientAccount);
    let glEntryWithdrawClientAccountIds = glEntryTransactions
      .filter(
        (t) => t.glType === "credit" && t.clientAccount !== "sessionbalance"
      )
      .map((t) => t.clientAccount);

    let sellDraftTxnsWithFee: SellDraft[] =
      activeSession?.transactions?.filter(
        (t) =>
          t.transactionType === "SELL_DRAFT" &&
          (t as SellDraft).waiveFee !== true
      ) ?? [];

    let newState: PersonalClient = {
      ...state,
      accounts: state.accounts?.map((a) => {
        if (
          depositAccountIds?.includes(a.accountId) ||
          withdrawalAccountIds?.includes(a.accountId) ||
          transferDepositAccountIds?.includes(a.accountId) ||
          transferWithdrawalAccountIds?.includes(a.accountId) ||
          glEntryDepositClientAccountIds.includes(a.accountId) ||
          glEntryWithdrawClientAccountIds.includes(a.accountId)
        ) {
          let accountDepositTxns = depositTransactions.filter(
            (t) => t.to === a.accountId
          );

          let depositAmount = accountDepositTxns
            ?.map((t) => t.amount ?? 0)
            .reduce(add, 0);
          let depositTxnIds = accountDepositTxns?.map((t) => t.transactionId);

          let accountWithdrawalTxns = withdrawalTransactions.filter(
            (t) => t.from === a.accountId
          );
          let withdrawalAmount = accountWithdrawalTxns
            ?.map((t) => t.amount ?? 0)
            .reduce(add, 0);

          let withdrawalTxnIds = accountWithdrawalTxns?.map(
            (t) => t.transactionId
          );

          let transferDepositTxns = transferTransactions.filter(
            (t) => t.to === a.accountId
          );
          let transferDepositAmount = transferDepositTxns
            .map((t) => t.amount ?? 0)
            .reduce(add, 0);
          let transferDepositTxnIds = transferDepositTxns.map(
            (t) => t.transactionId
          );

          let transferWithdrawTxns = transferTransactions.filter(
            (t) => t.from === a.accountId
          );
          let transferWithdrawAmount = transferWithdrawTxns
            .map((t) => t.amount ?? 0)
            .reduce(add, 0);
          let transferWithdrawalTxnIds = transferWithdrawTxns.map(
            (t) => t.transactionId
          );

          let glEntryDepositTxns = glEntryTransactions.filter(
            (t) => t.glType === "debit" && t.clientAccount === a.accountId
          );
          let glEntryDepositAmount = glEntryDepositTxns
            .map((t) => t.amount ?? 0)
            .reduce(add, 0);
          let glEntryDepositTxnIds = glEntryDepositTxns.map(
            (t) => t.transactionId
          );

          let glEntryWithdrawTxns = glEntryTransactions.filter(
            (t) => t.glType === "credit" && t.clientAccount === a.accountId
          );
          let glEntryWithdrawAmount = glEntryWithdrawTxns
            .map((t) => t.amount ?? 0)
            .reduce(add, 0);
          let glEntryWithdrawTxnIds = glEntryWithdrawTxns.map(
            (t) => t.transactionId
          );

          let totalDepositAmount = [
            depositAmount,
            transferDepositAmount,
            glEntryDepositAmount,
          ].reduce(add, 0);

          let totalWithdrawAmount = [
            withdrawalAmount,
            transferWithdrawAmount,
            glEntryWithdrawAmount,
          ].reduce(add, 0);

          let netAmount = assetsAccountsTypes.includes(a.accountType)
            ? subtract(totalDepositAmount, totalWithdrawAmount)
            : subtract(totalWithdrawAmount, totalDepositAmount);
          let currentTxnIds = a.transactionIds ?? [];

          let updatedTxnIds = [
            ...currentTxnIds,
            ...depositTxnIds,
            ...withdrawalTxnIds,
            ...transferDepositTxnIds,
            ...transferWithdrawalTxnIds,
            ...glEntryDepositTxnIds,
            ...glEntryWithdrawTxnIds,
          ];

          let newAccount: Account = {
            ...a,
            balance: add(a.balance ?? 0, netAmount),
            transactionIds: updatedTxnIds,
          };
          return newAccount;
        } else if (creditCardAccountIds?.includes(a.accountId)) {
          let accountCreditCardPaymentTxn = creditCardTransactions.find(
            (t) => t.creditCard === a.accountId && t.type === "payment"
          );

          let paymentAmount = accountCreditCardPaymentTxn?.amount ?? 0;
          let paymentTxnId = accountCreditCardPaymentTxn?.transactionId;

          let accountCreditCardCashAdvanceTxn = creditCardTransactions.find(
            (t) => t.creditCard === a.accountId && t.type === "cashadvance"
          );

          let cashAdvanceAmount = accountCreditCardCashAdvanceTxn?.amount ?? 0;
          let cashAdvanceTxnId = accountCreditCardCashAdvanceTxn?.transactionId;

          let netAmount = subtract(cashAdvanceAmount, paymentAmount);

          let currentTxnIds = a.transactionIds ?? [];
          let updatedTxnIds =
            paymentTxnId === undefined && !!cashAdvanceTxnId
              ? [...currentTxnIds, cashAdvanceTxnId]
              : cashAdvanceTxnId === undefined && !!paymentTxnId
              ? [...currentTxnIds, paymentTxnId]
              : !!paymentTxnId && !!cashAdvanceTxnId
              ? [...currentTxnIds, paymentTxnId, cashAdvanceTxnId]
              : [...currentTxnIds];

          let newAccount: Account = {
            ...a,
            balance: add(a.balance ?? 0, netAmount),
            transactionIds: updatedTxnIds,
          };
          return newAccount;
        } else return a;
      }),
      activeSessionId: "",
    };
    setState(newState);

    if (
      glEntryTransactions.length > 0 ||
      sellDraftTxnsWithFee.length > 0 ||
      receiveCashTransactions.length > 0 ||
      payoutCashTransactions.length > 0
    ) {
      updateClientAndGL(
        newState,
        glEntryTransactions,
        sellDraftTxnsWithFee,
        receiveCashTransactions,
        payoutCashTransactions
      );
    } else savePersonalClient(newState, true);
    setActiveSession(undefined);
  }

  const isReadOnlyStudentAccess =
    getLessonNameAndData.data?.isReadOnly ?? false;
  return (
    <>
      <ClientInfoTemplate
        client={state}
        title={
          state.information?.legalFirstName
            ? `Transactions — ${clientName}`
            : "Transactions"
        }
        isLoading={isLoading}
        studentId={props.studentId}
        lessonId={props.lessonId}
        cashDrawerBalance={glState.cashDrawer?.balance}
      >
        <>
          <Column>
            <FlexDiv>
              <ColumnHeading>Transactions</ColumnHeading>
              <AddTransactionsMenu
                onAddReceive={addReceive}
                onAddDeposit={addDeposit}
                onAddWithdrawal={addWithdrawal}
                onAddPayoutCash={addPayoutCash}
                onAddBillPayment={addBillPayment}
                onAddCreditCard={addCreditCard}
                onAddTransfer={addTransfer}
                onAddSellDraft={addSellDraft}
                onAddGLEntry={addGLEntry}
                isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
                isLoading={isLoading}
              />
            </FlexDiv>
            <AddTransactionSection
              activeTransaction={activeTransaction}
              depositWithdrawTransferOptions={getDepositWithdrawOptions(state)}
              creditCardOptions={getCreditCardOptionsForTransactionSummary(
                state
              )}
              glEntryClientAccountOptions={getGLEntryClientAccountOptionsForTxnSummary(
                state
              )}
              onSaveTransaction={(transaction) => {
                if (transaction.transactionType === "RECEIVE") {
                  saveReceive(transaction);
                }
                if (transaction.transactionType === "DEPOSIT") {
                  saveDeposit(transaction);
                }
                if (transaction.transactionType === "WITHDRAWAL") {
                  saveWithdrawal(transaction);
                }
                if (
                  ["PAYOUT_CASH", "BILL_PAYMENT", "SELL_DRAFT"].includes(
                    transaction.transactionType
                  )
                ) {
                  savePayoutCashBillPaymentOrSellDraft(transaction);
                }
                if (transaction.transactionType === "CREDIT_CARD") {
                  saveCreditCard(transaction);
                }
                if (transaction.transactionType === "TRANSFER") {
                  saveTransfer(transaction);
                }
                if (transaction.transactionType === "GL_ENTRY") {
                  saveGLEntry(transaction);
                }
              }}
            />
          </Column>
          <Column>
            <SummaryAccountSection
              activeTransactionSession={activeSession}
              updateTransactionSession={(updatedSession) =>
                updateActiveTransactionSession(updatedSession)
              }
              onPostClick={() => {
                onPostTransaction();
              }}
              activeAccounts={getOpenAccounts(state)}
              trasnactionSessions={state.transactionSessions ?? []}
              accountOptions={getOpenAccountOptionsForAccountHistory(state)}
              creditCardOptions={getCreditCardOptionsForTransactionSummary(
                state
              )}
              glEntryClientAccountOptions={getGLEntryClientAccountOptionsForTxnSummary(
                state
              )}
              onDetailedHistoryClick={(selectedAccountId) => {
                !!props.studentId
                  ? history.push(
                      `/${lessonId}${PersonalClientAccountHistoryRoute}/${state.identification?.clientId}/${selectedAccountId}/${props.studentId}`
                    )
                  : history.push(
                      `/${lessonId}${PersonalClientAccountHistoryRoute}/${state.identification?.clientId}/${selectedAccountId}`
                    );
              }}
              isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
            />
          </Column>
        </>
      </ClientInfoTemplate>
    </>
  );
}

const Column = styled.div`
  min-width: 550px;
  margin: 0.5em;
  flex: 1;
`;
const ColumnHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
