import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SectionListItem } from "types";
import styled from "styled-components";
import _orderBy from "lodash/orderBy";
import { openConfirm } from "components/confirmBox";

export type Props = {
  selectedLessonName: string;
  sectionList: SectionListItem[];
  onRemoveClick(sectionId: string): void;
};

export default function SectionList(props: Props) {
  //setting page to 0(1st page) if the filtered list is changed
  //to have correct filtering user experience
  useEffect(() => {
    setPage(0);
  }, [props.sectionList]);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  let sortedSectionList = _orderBy(props.sectionList, (l) => l.sectionId);

  return (
    <div style={{ marginTop: "1em", width: 950, minHeight: 300 }}>
      {/* <div style={{ minHeight: 400 }}> */}
      {(!sortedSectionList || sortedSectionList.length === 0) && (
        <Content>
          <div>There are no sections.</div>
        </Content>
      )}
      {sortedSectionList && sortedSectionList.length > 0 && (
        <Table>
          <Header />
          <TableBody>
            {sortedSectionList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((l, index) => {
                return (
                  <Row
                    sectionListItem={l}
                    key={index}
                    onRemoveClick={() => {
                      openConfirm({
                        title: "Remove Section?",
                        description: `Students from course ${l.courseCode}, section ${l.section} and term ${l.term} will no longer be able to access ${props.selectedLessonName}. Continue?`,
                        okBtnText: "Ok",
                        callback: () => {
                          props.onRemoveClick(l.sectionId);
                        },
                      });
                    }}
                  />
                );
              })}
          </TableBody>
        </Table>
      )}
      {props.sectionList && props.sectionList.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={props.sectionList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => {
            setPage(p);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
          }}
        />
      )}
    </div>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell>Course Code</Cell>
        <Cell>Class Section</Cell>
        <Cell>Course Term</Cell>
        <Cell></Cell>
      </TableRow>
    </TableHead>
  );
}

function Row(props: {
  sectionListItem: SectionListItem;
  onRemoveClick(): void;
}) {
  return (
    <TableRow>
      {/* <Cell>{props.index}</Cell> */}
      <Cell>{props.sectionListItem.courseCode}</Cell>
      <Cell>{props.sectionListItem.section}</Cell>
      <Cell>{props.sectionListItem.term}</Cell>

      <Cell>
        <Button onClick={props.onRemoveClick}>Remove</Button>
      </Cell>
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  padding: 10px 16px 10px 16px !important;
  text-align: center !important;
`;

const Content = styled.div`
  margin-top: 3em;
  display: flex;
  justify-content: center;
`;
