import { add, getPrimeRate } from "common/functions";
import { provinceOptions } from "common/helpers";
import {
  AddressField,
  BoolField,
  CreditAppMortgage,
  DateField,
  FIELD_TYPE,
  INPUT_TYPE,
  MASK_TYPE,
  PickListField,
  RadioGroupField,
  TextFieldType,
} from "types";
import { v4 } from "uuid";

export const mortgageAppraisedValue: TextFieldType<CreditAppMortgage> = {
  id: `mortgageAppraisedValue_${v4()}`,
  label: "Appraised Value",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.appraisedValue?.toString(),
  setValue: (value, account) => ({
    ...account,
    appraisedValue: (value as unknown) as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const mortgageRequestedAmount: TextFieldType<CreditAppMortgage> = {
  id: `mortgageRequestedAmount_${v4()}`,
  label: "Requested Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.requestedAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    requestedAmount: (value as unknown) as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const mortgageExistingBalance: TextFieldType<CreditAppMortgage> = {
  id: `mortgageExistingBalance_${v4()}`,
  label: "Existing Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.existingBalance?.toString(),
  setValue: (value, account) => ({
    ...account,
    existingBalance: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.type === "refinance" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const mortgageEquityAmount: TextFieldType<CreditAppMortgage> = {
  id: `mortgageEquityAmount_${v4()}`,
  label: "Equity Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.equityAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    equityAmount: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.type === "refinance" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const mortgageTermLength: PickListField<CreditAppMortgage> = {
  id: `mortgageTermLength_${v4()}`,
  label: "Term Length (years)",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppMortgage) => account.termLength,
  setValue: (value, account) => ({
    ...account,
    termLength: value,
  }),
  options: [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const mortgagePaymentAmount: TextFieldType<CreditAppMortgage> = {
  id: `mortgagePaymentAmount_${v4()}`,
  label: "Payment Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.paymentAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    paymentAmount: (value as unknown) as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const mortgageDownPayment: TextFieldType<CreditAppMortgage> = {
  id: `mortgageDownPayment_${v4()}`,
  label: "Down Payment",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.downPayment?.toString(),
  setValue: (value, account) => ({
    ...account,
    downPayment: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.type === "purchasing" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};
export const mortgageSourceOfDownPayment: TextFieldType<CreditAppMortgage> = {
  id: `mortgageSourceOfDownPayment_${v4()}`,
  label: "Source of Down Payment",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.sourceOfDownPayment,
  setValue: (value, account) => ({
    ...account,
    sourceOfDownPayment: value,
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.type === "purchasing" ?? false;
  },
};

export const mortgagePurchaseDate: DateField<CreditAppMortgage> = {
  id: `mortgagePurchaseDate_${v4()}`,
  label: "Purchase Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: CreditAppMortgage) => account.purchaseDate,
  setValue: (value, account) => ({
    ...account,
    purchaseDate: value,
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.type === "purchasing" ?? false;
  },
};

export const mortgageAppType: PickListField<CreditAppMortgage> = {
  id: `mortgageAppType_${v4()}`,
  label: "Application Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppMortgage) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
    //clearing out type dependent fields if type is changed
    downPayment: undefined,
    existingBalance: undefined,
    equityAmount: undefined,
    purchaseDate: "",
    sourceOfDownPayment: "",
    marketValue: undefined,
    purchasePrice: undefined,
  }),
  options: [
    { id: "purchasing", name: "Purchasing" },
    { id: "refinance", name: "Re-Finance" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const mortgageType: RadioGroupField<CreditAppMortgage> = {
  id: `mortgageType_${v4()}`,
  label: "Mortgage Type",
  trueLabel: "Open",
  falseLabel: "Closed",
  defaultValue: "Open",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: CreditAppMortgage) => account.mortgageType,
  setValue: (value, account) => ({
    ...account,
    mortgageType: value,
  }),
  isRequired: true,
};

export const mortgageInterestType: RadioGroupField<CreditAppMortgage> = {
  id: `mortgageInterestType_${v4()}`,
  label: "Interest Type",
  trueLabel: "Fixed",
  falseLabel: "Variable",
  defaultValue: "Fixed",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: CreditAppMortgage) => account.interestType,
  setValue: (value, account) => ({
    ...account,
    interestType: value,
  }),
  isRequired: true,
};

export const mortgagePrimeRate: TextFieldType<CreditAppMortgage> = {
  id: `mortgagePrimeRate_${v4()}`,
  label: "Prime Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => getPrimeRate(),
  setValue: (value, account) => ({
    ...account,
    primeRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE,
};

export const mortgageInterestRate: TextFieldType<CreditAppMortgage> = {
  id: `mortgageInterestRate_${v4()}`,
  label: "Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.interestRate,
  setValue: (value, account) => ({
    ...account,
    interestRate: value,
  }),
  isRequired: true,
  mask: MASK_TYPE.PERCENTAGE,
  checkIfAllowNegative: (account: CreditAppMortgage) => {
    return account.interestType === "Fixed" ? false : true;
  },
};

export const mortgageTotalInterestRate: TextFieldType<CreditAppMortgage> = {
  id: `mortgageTotalInterestRate_${v4()}`,
  label: "Total Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => {
    let tir = add(
      ((account.interestRate ?? 0) as unknown) as number,
      ((getPrimeRate() ?? 0) as unknown) as number
    );
    if (tir > 0) return tir.toString();
    else return "0";
  },
  setValue: (value, account) => ({
    ...account,
    totalInterestRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE,
};

function mortgageAmortizationOptions() {
  let a = [];
  for (let i = 1; i <= 25; i++) {
    a.push({ id: i.toString(), name: i.toString() });
  }
  return a;
}

export const mortgageAmortization: PickListField<CreditAppMortgage> = {
  id: `mortgageAmortization_${v4()}`,
  label: "Amortization (years)",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppMortgage) => account.amortization,
  setValue: (value, account) => ({
    ...account,
    amortization: value,
  }),
  options: mortgageAmortizationOptions(),
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const mortgageHasJointApplication: BoolField<CreditAppMortgage> = {
  id: `mortgageHasJointApplication_${v4()}`,
  label: "Joint Application",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (account: CreditAppMortgage) => account.hasJointApplication,
  setValue: (value, account) => ({
    ...account,
    hasJointApplication: value,
    jointApplication: value ? account.jointApplication : undefined,
  }),
};

export const mortgageJointAppName: TextFieldType<CreditAppMortgage> = {
  id: `mortgageJointAppName_${v4()}`,
  label: "Name",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.jointApplication?.name,
  setValue: (value, account) => ({
    ...account,
    jointApplication: { ...account.jointApplication, name: value },
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.hasJointApplication ?? false;
  },
};

export const mortgageJointAppEmployerName: TextFieldType<CreditAppMortgage> = {
  id: `mortgageJointAppEmployerName_${v4()}`,
  label: "Employer Name",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) =>
    account.jointApplication?.employerName,
  setValue: (value, account) => ({
    ...account,
    jointApplication: { ...account.jointApplication, employerName: value },
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.hasJointApplication ?? false;
  },
};

export const mortgageJointAppJobTitle: TextFieldType<CreditAppMortgage> = {
  id: `mortgageJointAppJobTitle_${v4()}`,
  label: "Job Title",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.jointApplication?.jobTitle,
  setValue: (value, account) => ({
    ...account,
    jointApplication: { ...account.jointApplication, jobTitle: value },
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.hasJointApplication ?? false;
  },
};

export const mortgageJointAppEmploymentStartDate: DateField<CreditAppMortgage> = {
  id: "mortgageJointAppEmploymentStartDate",
  label: "Employment Start Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: CreditAppMortgage) =>
    account.jointApplication?.employmentStartDate,
  setValue: (value, account) => ({
    ...account,
    jointApplication: {
      ...account.jointApplication,
      employmentStartDate: value,
    },
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.hasJointApplication ?? false;
  },
};

export const mortgageJointAppMonthlyIncome: TextFieldType<CreditAppMortgage> = {
  id: `mortgageJointAppMonthlyIncome_${v4()}`,
  label: "Monthly Income",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) =>
    account.jointApplication?.monthlyIncome?.toString(),
  setValue: (value, account) => ({
    ...account,
    jointApplication: {
      ...account.jointApplication,
      monthlyIncome: (value as unknown) as number,
    },
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.hasJointApplication ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const mortgageCMHC: BoolField<CreditAppMortgage> = {
  id: `mortgageCMHC_${v4()}`,
  label: "CMHC",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (account: CreditAppMortgage) => account.cmhc,
  setValue: (value, account) => ({
    ...account,
    cmhc: value,
  }),
};

export const mortgageCMHCFee: TextFieldType<CreditAppMortgage> = {
  id: `mortgageCMHCFee_${v4()}`,
  label: "CMHC Fee",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.cmhcFee?.toString(),
  setValue: (value, account) => ({
    ...account,
    cmhcFee: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.cmhc ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

//----Mortgage - Property Information

export const mortgagePropertyAddress: AddressField<CreditAppMortgage> = {
  id: "mortgagePropertyAddress",
  label: "Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (account: CreditAppMortgage) => account.propertyAddress,
  setValue: (value, account) => ({
    ...account,
    propertyAddress: value,
  }),
  isDated: false,
  isRequired: true,
  options: provinceOptions,
};

export const mortgagePurchasePrice: TextFieldType<CreditAppMortgage> = {
  id: `mortgagePurchasePrice_${v4()}`,
  label: "Purchase Price",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.purchasePrice?.toString(),
  setValue: (value, account) => ({
    ...account,
    purchasePrice: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.type === "purchasing" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const mortgageMarketValue: TextFieldType<CreditAppMortgage> = {
  id: `mortgageMarketValue_${v4()}`,
  label: "Market Value",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.marketValue?.toString(),
  setValue: (value, account) => ({
    ...account,
    marketValue: (value as unknown) as number,
  }),
  checkIfRequired: (account: CreditAppMortgage) => {
    return account.type === "refinance" ?? false;
  },
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const mortgagePropertyTax: TextFieldType<CreditAppMortgage> = {
  id: `mortgagePropertyTax_${v4()}`,
  label: "Annual Property Tax",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.propertyTax?.toString(),
  setValue: (value, account) => ({
    ...account,
    propertyTax: (value as unknown) as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
};

export const mortgageIncludedInMortgage: BoolField<CreditAppMortgage> = {
  id: `mortgageIncludedInMortgage_${v4()}`,
  label: "Included In Mortgage",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (account: CreditAppMortgage) => account.includedInMortgage,
  setValue: (value, account) => ({
    ...account,
    includedInMortgage: value,
  }),
};

export const mortgageMuncipality: TextFieldType<CreditAppMortgage> = {
  id: `mortgageMuncipality_${v4()}`,
  label: "Muncipality",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.muncipality,
  setValue: (value, account) => ({
    ...account,
    muncipality: value,
  }),
  isRequired: true,
};

export const mortgageLegalDescription: TextFieldType<CreditAppMortgage> = {
  id: "mortgageLegalDescription",
  label: "Legal Description",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.legalDescription,
  setValue: (value, account) => ({
    ...account,
    legalDescription: value,
  }),
  multiline: true,
  maxLength: 500,
  isRequired: true,
};

export const mortgagePropertyType: PickListField<CreditAppMortgage> = {
  id: `mortgagePropertyType_${v4()}`,
  label: "Property Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppMortgage) => account.propertyType,
  setValue: (value, account) => ({
    ...account,
    propertyType: value,
  }),
  options: [
    { id: "singlefamily", name: "Single Family" },
    { id: "multifamily", name: "Multi Family" },
    { id: "townhouse", name: "Townhouse" },
    { id: "condominium", name: "Condominium" },
    { id: "leaseproperty", name: "Lease Property" },
    { id: "mobile", name: "Mobile" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const mortgageBuildingType: PickListField<CreditAppMortgage> = {
  id: `mortgageBuildingType_${v4()}`,
  label: "Building Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppMortgage) => account.buildingType,
  setValue: (value, account) => ({
    ...account,
    buildingType: value,
  }),
  options: [
    { id: "home", name: "Home" },
    { id: "duplex", name: "Duplex" },
    { id: "condo", name: "Condo" },
    { id: "townhouse", name: "Townhouse" },
    { id: "apartment", name: "Apartment" },
    { id: "mobile", name: "Mobile" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const mortgagePropertyStyle: PickListField<CreditAppMortgage> = {
  id: `mortgagePropertyStyle_${v4()}`,
  label: "Style",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppMortgage) => account.propertyStyle,
  setValue: (value, account) => ({
    ...account,
    propertyStyle: value,
  }),
  options: [
    { id: "bi-level", name: "Bi-level" },
    { id: "bungalow", name: "Bungalow" },
    { id: "twostorey", name: "Two Storey" },
    { id: "carriagehouse", name: "Carriage House" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const mortgageNoOfBedrooms: PickListField<CreditAppMortgage> = {
  id: `mortgageNoOfBedrooms_${v4()}`,
  label: "No Of Bedrooms",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppMortgage) => account.noOfBedrooms,
  setValue: (value, account) => ({
    ...account,
    noOfBedrooms: value,
  }),
  options: [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "5+", name: "5+" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const mortgageNoOfBathrooms: PickListField<CreditAppMortgage> = {
  id: `mortgageNoOfBathrooms_${v4()}`,
  label: "No Of Bathrooms",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppMortgage) => account.noOfBathrooms,
  setValue: (value, account) => ({
    ...account,
    noOfBathrooms: value,
  }),
  options: [
    { id: "1", name: "1" },
    { id: "1.5", name: "1.5" },
    { id: "2", name: "2" },
    { id: "2.5", name: "2.5" },
    { id: "3", name: "3" },
    { id: "3.5", name: "3.5" },
    { id: "4", name: "4" },
    { id: "4+", name: "4+" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const mortgagePropertyAge: TextFieldType<CreditAppMortgage> = {
  id: `mortgagePropertyAge_${v4()}`,
  label: "Property Age (years)",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.propertyAge,
  setValue: (value, account) => ({
    ...account,
    propertyAge: value,
  }),
  isRequired: true,
  inputType: INPUT_TYPE.NUMBER,
};

export const mortgageParkingType: PickListField<CreditAppMortgage> = {
  id: `mortgageParkingType_${v4()}`,
  label: "Parking Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppMortgage) => account.parkingType,
  setValue: (value, account) => ({
    ...account,
    parkingType: value,
  }),
  options: [
    { id: "attachedGarage", name: "Attached Garage" },
    { id: "detachedgarage", name: "Detached Garage" },
    { id: "parkingpad", name: "Parking Pad" },
    { id: "none", name: "None" },
  ],
  isRequired: true,
  getOptionLabel: (value, options) =>
    options.find((o) => o.id === value)?.name || "",
};

export const mortgageSquareFootage: TextFieldType<CreditAppMortgage> = {
  id: `mortgageSquareFootage_${v4()}`,
  label: "Square Footage",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.squareFootage,
  setValue: (value, account) => ({
    ...account,
    squareFootage: value,
  }),
  isRequired: true,
  inputType: INPUT_TYPE.NUMBER,
};

export const mortgageLotSize: TextFieldType<CreditAppMortgage> = {
  id: `mortgageLotSize_${v4()}`,
  label: "Lot Size (Sq.ft)",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppMortgage) => account.lotSize,
  setValue: (value, account) => ({
    ...account,
    lotSize: value,
  }),
  isRequired: true,
  inputType: INPUT_TYPE.NUMBER,
};

export const mortgageTitle: RadioGroupField<CreditAppMortgage> = {
  id: `mortgageTitle_${v4()}`,
  label: "Title",
  trueLabel: "Freehold",
  falseLabel: "Leasehold",
  defaultValue: "Freehold",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: CreditAppMortgage) => account.title,
  setValue: (value, account) => ({
    ...account,
    title: value,
  }),
  isRequired: true,
};

export const mortgageRestricted: BoolField<CreditAppMortgage> = {
  id: `mortgageRestricted_${v4()}`,
  label: "Restricted",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (account: CreditAppMortgage) => account.restricted,
  setValue: (value, account) => ({
    ...account,
    restricted: value,
  }),
};
