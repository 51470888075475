import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { addressErrors } from "common/functions";
import FieldEditor from "components/FieldEditor";
import * as fields from "pages/clientPortfolio/personalClient/personalClientFieldDefinitions";
import { useEffect, useState } from "react";
import { AddressType, FieldError, PersonalClient } from "types";

type Props = {
  onAdd(newAddress: AddressType): void;
  onCancel(): void;
};

export default function AddAddressHstoryItem({ onAdd, onCancel }: Props) {
  const [state, setState] = useState<PersonalClient>({});
  const [errors, setErrors] = useState<FieldError[]>([]);

  function checkNewAddressErrors() {
    const addressErrorsArray: FieldError[] = [
      ...addressErrors(fields.clientNewAddress, state),
    ];
    //Invalid postal code fix: following code checks for invalid postal code. Adds error if length is less than 7
    if (
      state.information?.clientNewAddress?.postalCode &&
      state.information.clientNewAddress.postalCode?.length < 7
    ) {
      let invalidPostalCodeErr: FieldError = {
        id: `${fields.clientNewAddress.id}_postalCode`,
        label: "Postal Code",
        error: "Invalid Postal Code.",
      };
      addressErrorsArray.push(invalidPostalCodeErr);
    }
    setErrors(addressErrorsArray);
    return addressErrorsArray;
  }

  useEffect(() => {
    errors.length > 0 && checkNewAddressErrors();
  }, [state]);

  return (
    <Dialog maxWidth={"md"} open>
      <DialogTitle>Add Address</DialogTitle>
      <DialogContent>
        <FieldEditor<PersonalClient>
          field={fields.clientNewAddress}
          state={state}
          setState={setState}
          errors={errors}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            const newAddressErrors = checkNewAddressErrors();
            if (newAddressErrors.length === 0) {
              onAdd(
                { ...state.information?.clientNewAddress, country: "Canada" } ||
                  {}
              );
            }
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
