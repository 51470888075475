import { Typography } from "@mui/material";
import { getPersonalClientName } from "common/functions";
import SearchTextField from "components/searchTextField/SearchTextField";
import React, { useState } from "react";
import styled from "styled-components";
import { PersonalClient } from "types";
import PersonalClientList from "pages/clientPortfolio/businessClient/businessClientInformation/components/PersonalClientList";

export type PersonalCLientSearchProps = {
  personalClients: PersonalClient[];
  onPersonalClientSelected?(e: PersonalClient): void;
};

const PersonalClientSearch = (props: PersonalCLientSearchProps) => {
  const [searchText, setSearchText] = useState<string>("");

  const filteredList = props.personalClients.filter(
    (c) =>
      getPersonalClientName(c)
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      c.information?.phoneNumber?.includes(searchText)
  );

  return (
    <SectionContainer>
      <Typography variant={"h6"}>Existing Cients</Typography>
      <div style={{ padding: "1em 0.25em" }}>
        <SearchTextField
          value={searchText}
          label="Search client name or phone number"
          onChange={(text) => setSearchText(text)}
        />
      </div>
      <ListHeader>
        <HeaderItem width={"small"}>Client Id</HeaderItem>
        <HeaderItem width={"wide"}>Client Name</HeaderItem>
        <HeaderItem width={"med"}>Phone Number</HeaderItem>
      </ListHeader>
      <PersonalClientList
        personalClients={filteredList}
        onPersonalClientSelected={props.onPersonalClientSelected}
      />
    </SectionContainer>
  );
};

export default PersonalClientSearch;

const SectionContainer = styled.div`
  margin-top: 2em;
  min-height: 600px;
`;

const ListHeader = styled.div`
  font-weight: bold;
  display: flex;
  padding-top: 10px;
  padding-left: 30px;
`;

const HeaderItem = styled.div<{ width: "small" | "med" | "wide" }>`
  width: ${(p) =>
    p.width === "med" ? "125px" : p.width === "wide" ? "160px" : "100px"};
  padding: 0px 10px;
  word-wrap: break-word;
`;
