import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const controlName = "snackbar";

export type ConfigProps = {
  message?: string;
  severity?: "error" | "warning" | "info" | "success";
};

export type State = {
  isOpen: boolean;
  config: ConfigProps;
};

const initialState:State = {
  isOpen: false,
  config: {
    message: undefined,
    severity: undefined
  }
}

let slice = createSlice({
  name: controlName,
  initialState: {...initialState},
  reducers: {
    openSnackbar: (
      state: State,
      action: PayloadAction<ConfigProps>
    ) => {
      let ret = {...state};

      ret.isOpen = true;
      ret.config = {...action.payload};

      return ret;

    },
    
    closeSnackbar: (
        state: State
      ) => {
        return initialState;
      },
  },
});

 const ret =  {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
    },
  },
};

export default ret;
