import MainTemplate from "components/mainTemplate/MainTemplate";
import { openSnackbar } from "components/snackbar";
import { useEffect, useState } from "react";
import { useActions, useReduxState } from "store";
import { Employer, GeneralLedger } from "types";
import EmployerEditor from "pages/createEmployer/components/EmployerEditor";
import EmployerSearchList from "pages/createEmployer/components/EmployerSearchList";
import {
  getEmployerList,
  getGeneralLedger,
  getNewEmployerId,
} from "pages/lessons/helpers";
import { useHistory } from "react-router-dom";
import { UnauthorizedRoute } from "components/paths";
import { checkIfUnauthorized } from "common/functions";

export type Props = {
  lessonId: string;
  studentId?: string;
};

export default function Employers(props: Props) {
  const {
    getLessonNameAndData,
    lessonId,
    getDataforViewAsStudent,
  } = useReduxState((e) => e.lesson);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [employerInEdit, setEmployerInEdit] = useState<Employer | undefined>(
    undefined
  );
  const globalSettingsActions = useActions().globalsettings;
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);

  const lessonActions = useActions().lesson;
  const employerActions = useActions().employers;
  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }
  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
        setIsLoading(false);
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
        setIsLoading(false);
      }
    }
    if (!getGlobalSettings.data?.globalSettings) {
      globalSettingsActions.getGlobalSettings({});
    }
  }, []);

  function onSaveClick(employer: Employer) {
    employerActions.saveEmployer({
      employer: employer,
      lessonId,
      onComplete: (payload) => {
        if (!!payload.employerId) {
          openSnackbar({
            severity: "success",
            message: `Employer '${employer.employerName}' saved successfully`,
          });
          lessonActions.saveEmployer(employer);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error saving employer '${employer.employerName}'. Please refresh the page and try again!`,
          });
        }
        setEmployerInEdit(undefined);
      },
    });
  }

  function onAddClick() {
    setEmployerInEdit({
      employerId: getNewEmployerId(
        getLessonNameAndData.data?.lessonData
      ).toString(),
      employerAddress: { country: "Canada" },
    });
  }

  function onDeleteClick(employerId: string) {
    employerActions.deleteEmployer({
      employerId: employerId,
      lessonId,
      onComplete: (payload) => {
        if (payload.success) {
          openSnackbar({
            severity: "success",
            message: `Employer deleted successfully`,
          });
          lessonActions.deleteEmployer(employerId);
        } else {
          openSnackbar({
            severity: "error",
            message: `Employer not deleted. Please refresh the page and try again`,
          });
        }
        setEmployerInEdit(undefined);
      },
    });
  }
  const isReadOnlyStudentAccess =
    getLessonNameAndData.data?.isReadOnly ?? false;
  return (
    <div>
      <MainTemplate
        title="Employers"
        isLoading={isLoading}
        studentId={props.studentId}
        cashDrawerBalance={glState.cashDrawer?.balance}
      >
        <div>
          <ExistingEmployersSection
            employers={
              props.studentId
                ? getEmployerList(getDataforViewAsStudent.data?.lessonData)
                : getEmployerList(getLessonNameAndData.data?.lessonData)
            }
            onAddClick={() => {
              onAddClick();
            }}
            onEditClick={(employer) => {
              setEmployerInEdit(employer);
            }}
            onDeleteClick={(employerId) => {
              onDeleteClick(employerId);
            }}
            isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
          />

          {!!employerInEdit && (
            <EmployerEditor
              employer={employerInEdit}
              saveClick={(employer) => {
                onSaveClick(employer);
              }}
              cancelClick={() => {
                setEmployerInEdit(undefined);
              }}
            />
          )}
        </div>
      </MainTemplate>
    </div>
  );
}

type ExistingEmployersProps = {
  employers: Employer[];
  onAddClick(): void;
  onEditClick(employer: Employer): void;
  onDeleteClick(employerId: string): void;
  isReadOnlyView: boolean;
};

const ExistingEmployersSection = (props: ExistingEmployersProps) => {
  return (
    <EmployerSearchList
      employers={props.employers}
      onAddClick={props.onAddClick}
      onEditClick={(employer) => {
        props.onEditClick(employer);
      }}
      onDeleteClick={(employerId) => {
        props.onDeleteClick(employerId);
      }}
      isReadOnlyView={props.isReadOnlyView}
    />
  );
};
