import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import styled from "styled-components";

import { LessonListItem } from "types";

export type Props = {
  onReset(): void;
  onCancel(): void;
  lessonItem: LessonListItem;
};

export default function ResetLesson(props: Props) {
  return (
    <Dialog maxWidth="md" fullWidth open id="addLesson">
      <DialogTitle>
        <Typography>Reset Lesson</Typography>
      </DialogTitle>
      <DialogContent>
        <Table>
          <Header />
          <TableBody>
            <Row lessonListItem={props.lessonItem} key={1} />
          </TableBody>
        </Table>
        <MessageContainer>
          Are you sure you would like to reset this lesson?
          <br />
          Note: This action cannot be reversed once submitted, all your progress
          will be lost in the lesson.
        </MessageContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button variant="contained" onClick={props.onReset}>
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell>Lesson Name</Cell>
        <Cell>Clients</Cell>
        <Cell>Employers</Cell>
        <Cell>Last Modified</Cell>
      </TableRow>
    </TableHead>
  );
}

function Row(props: { lessonListItem: LessonListItem }) {
  return (
    <TableRow>
      <Cell>{props.lessonListItem.lessonName}</Cell>
      <Cell>{props.lessonListItem.numberOfClients}</Cell>
      <Cell>{props.lessonListItem.numberOfEmployers}</Cell>
      <Cell>
        {props.lessonListItem.lastModified === "0001-01-01 12:00:00 AM"
          ? "N/A"
          : moment
              .utc(props.lessonListItem.lastModified)
              .local()
              .format("MM/DD/YYYY hh:mm:ss A")
              .toString()}
      </Cell>
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  padding: 10px 16px 10px 16px !important;
  text-align: center !important;
`;

const MessageContainer = styled.div`
  margin-top: 2em;
  color: #d32f2f;
  font-weight: 500;
`;
