import FieldDisplay from "components/FieldDisplay";
import { CreditAppMortgage } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CreditAppMortgageFieldDefinitions";
import { Divider, Grid, Typography } from "@mui/material";
import AccountDisplayTemplate from "./AccountDisplayTemplate";
import AddressFieldDisplay from "components/fields/AddressFieldDisplay";
import DisplayFieldTemplate from "components/fields/DisplayFieldTemplate";
export type Props = {
  account: CreditAppMortgage;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

const CreditAppMortgageDisplay = ({
  account,
  onEditClick,
  onDeleteClick,
  isReadOnlyView,
}: Props) => {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`Mortgage_${account.accountId}_header`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      accountType="Mortgage"
      accountId={account.accountId || ""}
      type={account.type === "purchasing" ? "Purchasing" : "Re-Financing"}
      requestedAmount={account.requestedAmount || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DisplayFieldTemplate label="Account Type">
            Mortgage
          </DisplayFieldTemplate>
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageAppraisedValue}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageRequestedAmount}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageAppType}
            {...commonProps}
          />

          {account.type === "purchasing" && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgageDownPayment}
              {...commonProps}
            />
          )}
          {account.type === "refinance" && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgageExistingBalance}
              {...commonProps}
            />
          )}
          {account.type === "refinance" && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgageEquityAmount}
              {...commonProps}
            />
          )}
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageTermLength}
            {...commonProps}
          />
          {account.type === "purchasing" && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgagePurchaseDate}
              {...commonProps}
            />
          )}

          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgagePaymentAmount}
            {...commonProps}
          />
          {account.type === "purchasing" && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgageSourceOfDownPayment}
              {...commonProps}
            />
          )}
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageAmortization}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageType}
            {...commonProps}
          />

          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageInterestType}
            {...commonProps}
          />

          {account.interestType === "Variable" && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgagePrimeRate}
              {...commonProps}
            />
          )}

          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageInterestRate}
            {...commonProps}
          />

          {account.interestType === "Variable" && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgageTotalInterestRate}
              {...commonProps}
            />
          )}

          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageHasJointApplication}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageCMHC}
            {...commonProps}
          />
          {account.cmhc && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgageCMHCFee}
              {...commonProps}
            />
          )}
        </Grid>
      </Grid>

      <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
      <Typography style={{ marginBottom: "1em" }}>
        Property Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <AddressFieldDisplay<CreditAppMortgage>
            field={fields.mortgagePropertyAddress}
            {...commonProps}
          />
          {account.type === "purchasing" && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgagePurchasePrice}
              {...commonProps}
            />
          )}
          {account.type === "refinance" && (
            <FieldDisplay<CreditAppMortgage>
              field={fields.mortgageMarketValue}
              {...commonProps}
            />
          )}
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgagePropertyTax}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageIncludedInMortgage}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageMuncipality}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageLegalDescription}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgagePropertyType}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageBuildingType}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgagePropertyStyle}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageNoOfBedrooms}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageNoOfBathrooms}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgagePropertyAge}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageParkingType}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageSquareFootage}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageLotSize}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageTitle}
            {...commonProps}
          />
          <FieldDisplay<CreditAppMortgage>
            field={fields.mortgageRestricted}
            {...commonProps}
          />
        </Grid>
      </Grid>

      {account.hasJointApplication && (
        <>
          <Divider style={{ margin: "0.75em 0em 1.5em 0em" }} />
          <Typography style={{ marginBottom: "1em" }}>
            Joint Application Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CreditAppMortgage>
                field={fields.mortgageJointAppName}
                {...commonProps}
              />
              <FieldDisplay<CreditAppMortgage>
                field={fields.mortgageJointAppEmployerName}
                {...commonProps}
              />
              <FieldDisplay<CreditAppMortgage>
                field={fields.mortgageJointAppJobTitle}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldDisplay<CreditAppMortgage>
                field={fields.mortgageJointAppEmploymentStartDate}
                {...commonProps}
              />
              <FieldDisplay<CreditAppMortgage>
                field={fields.mortgageJointAppMonthlyIncome}
                {...commonProps}
              />
            </Grid>
          </Grid>
        </>
      )}
    </AccountDisplayTemplate>
  );
};

export default CreditAppMortgageDisplay;
