import FieldEditor from "components/FieldEditor";
import { FieldError, GIC } from "types";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessGICFieldDefinitions";
import { useEffect, useState } from "react";
import {
  requiredDateField,
  requiredPickListField,
  requiredRadioGroupField,
  requiredTextField,
} from "common/functions";
import AccountEditTemplate from "./AccountEditTemplate";
import { openConfirm } from "components/confirmBox";
export type Props = {
  account: GIC;
  isSaving: boolean;
  onCancel(): void;
  onSave(account: GIC): void;
};

function GICForm({ account, onCancel, onSave, isSaving }: Props) {
  const [state, setState] = useState<GIC>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: GIC) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredDateField(fields.gicOpenDate, state),
      ...requiredTextField(fields.gicInvestmentRate, state),
      ...requiredTextField(fields.gicBalance, state),
      ...requiredPickListField(fields.gicTermLength, state),
      ...requiredPickListField(fields.gicType, state),
      ...requiredRadioGroupField(fields.gicInvestmentCategory, state),
    ];
    if (
      state.closeDate &&
      state.openDate &&
      state.closeDate.toLowerCase() !== "invalid date" &&
      new Date(state.closeDate) < new Date(state.openDate)
    ) {
      let invalidCloseDateErr: FieldError = {
        id: `${fields.gicCloseDate.id}`,
        label: `${fields.gicCloseDate.label}`,
        error: "Close Date should be after the Open Date",
      };
      errorsArray.push(invalidCloseDateErr);
    }
    setErrors(errorsArray);
    return errorsArray;
  }

  function onSaveClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (
        state.closeDate &&
        state.closeDate.toLowerCase() !== "invalid date" &&
        state.balance &&
        parseFloat(state.balance.toString()) !== parseFloat("0")
      ) {
        openConfirm({
          title: "Error",
          description: `Account Balance should be $0.00 to close the account`,
          isAlert: true,
        });
      } else {
        onSave({
          ...state,
          type: "Non-RSP",
          isAccountClosed:
            state.closeDate && state.closeDate.toLowerCase() !== "invalid date"
              ? true
              : false,
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`GIC_${account.accountId}_form`}
      onSaveClick={onSaveClick}
      onCancelClick={onCancel}
      isSaving={isSaving}
      type="Guaranteed Investment Certificate (GIC)"
      accountNumber={account.accountNumber || ""}
      balance={account.balance || 0}
      column1Fields={
        <>
          <FieldEditor<GIC> field={fields.gicOpenDate} {...commonProps} />
          <FieldEditor<GIC> field={fields.gicAccountNumber} {...commonProps} />

          <FieldEditor<GIC> field={fields.gicBalance} {...commonProps} />

          <FieldEditor<GIC> field={fields.gicType} {...commonProps} />

          <FieldEditor<GIC> field={fields.gicInvestmentRate} {...commonProps} />
        </>
      }
      column2Fields={
        <>
          <FieldEditor<GIC> field={fields.gicCloseDate} {...commonProps} />
          <FieldEditor<GIC> field={fields.gicTermLength} {...commonProps} />
          <FieldEditor<GIC> field={fields.gicMaturityDate} {...commonProps} />
          <FieldEditor<GIC>
            field={fields.gicInvestmentCategory}
            {...commonProps}
          />
        </>
      }
    />
  );
}

export default GICForm;
