import FieldEditor from "components/FieldEditor";
import { CreditCard, FieldError, MASK_TYPE } from "types";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessCreditCardFieldDefinitions";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  requiredDateField,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import AccountEditTemplate from "./AccountEditTemplate";
import { openConfirm } from "components/confirmBox";
export type Props = {
  account: CreditCard;
  isSaving: boolean;
  onCancel(): void;
  onSave(account: CreditCard): void;
};

const CreditCardForm = ({ account, onCancel, onSave, isSaving }: Props) => {
  const [state, setState] = useState<CreditCard>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: CreditCard) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredDateField(fields.creditCardOpenDate, state),
      ...requiredTextField(fields.creditCardNumber, state),
      ...requiredPickListField(fields.creditCardExpiryMonth, state),
      ...requiredPickListField(fields.creditCardExpiryYear, state),
      ...requiredPickListField(fields.creditCardType, state),
      ...requiredTextField(fields.creditCardLimit, state),
    ];
    if (
      state.closeDate &&
      state.openDate &&
      state.closeDate.toLowerCase() !== "invalid date" &&
      new Date(state.closeDate) < new Date(state.openDate)
    ) {
      let invalidCloseDateErr: FieldError = {
        id: `${fields.creditCardCloseDate.id}`,
        label: `${fields.creditCardCloseDate.label}`,
        error: "Close Date should be after the Open Date",
      };
      errorsArray.push(invalidCloseDateErr);
    }

    //following code checks for invalid credit card number. Adds error if length is less than 16
    if (state.cardNumber && state.cardNumber.length < 16) {
      let invalidCardNumberErr: FieldError = {
        id: `${fields.creditCardNumber.id}`,
        label: `${fields.creditCardNumber.label}`,
        error: "Invalid Card Number.",
      };
      errorsArray.push(invalidCardNumberErr);
    }
    setErrors(errorsArray);
    return errorsArray;
  }

  function onSaveClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (
        state.closeDate &&
        state.closeDate.toLowerCase() !== "invalid date" &&
        state.balance &&
        state.balance !== 0
      ) {
        openConfirm({
          title: "Error",
          description: `Account Balance should be $0.00 to close the account`,
          isAlert: true,
        });
      } else {
        onSave({
          ...state,
          isAccountClosed:
            state.closeDate && state.closeDate.toLowerCase() !== "invalid date"
              ? true
              : false,
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`CREDIT_CARD_${account.accountId}_form`}
      onSaveClick={onSaveClick}
      onCancelClick={onCancel}
      isSaving={isSaving}
      type="Credit Card"
      mask={MASK_TYPE.CREDIT_CARD_NUMBER}
      accountNumber={account.cardNumber || ""}
      balance={account.balance || 0}
      column1Fields={
        <>
          <FieldEditor<CreditCard>
            field={fields.creditCardOpenDate}
            {...commonProps}
          />
          <FieldEditor<CreditCard>
            field={fields.creditCardNumber}
            {...commonProps}
          />
          <TwoFieldsRow>
            <Column1MarginRight>
              <FieldEditor<CreditCard>
                field={fields.creditCardExpiryMonth}
                {...commonProps}
              />
            </Column1MarginRight>
            <Column2>
              <FieldEditor<CreditCard>
                field={fields.creditCardExpiryYear}
                {...commonProps}
              />
            </Column2>
          </TwoFieldsRow>
          <FieldEditor<CreditCard>
            field={fields.creditCardBalance}
            {...commonProps}
          />
          <FieldEditor<CreditCard>
            field={fields.creditCardType}
            {...commonProps}
          />
        </>
      }
      column2Fields={
        <>
          <FieldEditor<CreditCard>
            field={fields.creditCardCloseDate}
            {...commonProps}
          />

          <FieldEditor<CreditCard>
            field={fields.creditCardLimit}
            {...commonProps}
          />
          <FieldEditor<CreditCard>
            field={fields.creditCardMinimumPayment}
            {...commonProps}
          />
        </>
      }
    />
  );
};

export default CreditCardForm;

const TwoFieldsRow = styled.div`
  display: flex;
  margin: -1em 0;
`;
const Column1MarginRight = styled.div`
  flex: 12;
  margin-right: 0.5em;
`;
const Column2 = styled.div`
  flex: 11;
`;
