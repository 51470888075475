import { getLoginInfo } from "common/azureAD";
import AdminTemplate from "components/adminTemplate/AdminTemplate";

import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";
import {
  SearchClientsRoute,
  StudentSessionsRoute,
  UnauthorizedRoute,
} from "components/paths";
import { openSnackbar } from "components/snackbar";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useActions, useReduxState } from "store";
import styled from "styled-components";
import {
  CustomStudent,
  IdName,
  LessonSettings,
  SectionListItem,
  StudentListItem,
} from "types";
import Sections from "pages/admin/studentSessions/components/Sections";
import Students from "pages/admin/studentSessions/components/Students";
import LessonSettingsSection from "./components/LessonSettings";

export type Props = {
  lessonId?: string;
};

export default function StudentSessionsPage(props: Props) {
  const loginInfo = getLoginInfo();
  const history = useHistory();
  const location = useLocation();
  if (!loginInfo?.isInstructor) {
    history.push(UnauthorizedRoute);
  }

  const lessonActions = useActions().lesson;
  const studentSessionActions = useActions().studentsessions;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getLessonList, getStudentSessionLessonInfo } = useReduxState(
    (e) => e.lesson
  );
  const [selectedLessonOption, setSelectedLessonOption] = useState<string>("");
  const [lessonOptions, setLessonOptions] = useState<IdName[]>(
    getLessonList.data?.map((l) => {
      return { id: l.id.toString(), name: l.lessonName };
    }) ?? []
  );
  const [sectionList, setSectionList] = useState<SectionListItem[]>(
    selectedLessonOption !== ""
      ? getStudentSessionLessonInfo.data?.lessonInfo?.sectionList ?? []
      : []
  );
  const [studentList, setStudentList] = useState<StudentListItem[]>(
    selectedLessonOption !== ""
      ? getStudentSessionLessonInfo.data?.lessonInfo.studentList ?? []
      : []
  );

  const [isAccessUpdated, setIsAccessUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (!getLessonList.data) {
      setIsLoading(true);
      lessonActions.getLessonList({
        onComplete: (payload) => {
          setIsLoading(false);
          setLessonOptions(
            payload.map((l) => {
              return { id: l.id.toString(), name: l.lessonName };
            })
          );
        },
      });
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (!!props.lessonId) {
      if (isMounted) setSelectedLessonOption(props.lessonId);
      setIsLoading(true);
      lessonActions.getStudentSessionLessonInfo({
        id: (props.lessonId as unknown) as number,
        onComplete: (payload) => {
          if (isMounted) {
            setSectionList(payload.lessonInfo.sectionList);
            setStudentList(payload.lessonInfo.studentList);
            setIsAccessUpdated(false);
            setIsLoading(false);
          }
        },
      });
    }
    if (props.lessonId === undefined) {
      setSelectedLessonOption("");
    }
    return () => {
      isMounted = false;
    };
  }, [location]);

  function addSectionsToAllLessons(sectionList: SectionListItem[]) {
    setIsLoading(true);
    lessonActions.addSectionsToAllLessons({
      sectionList: sectionList,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.getSectionAndStudentList({
            id: (selectedLessonOption as unknown) as number,
            onComplete: (payload) => {
              setSectionList(payload.sectionList);
              openSnackbar({
                severity: "success",
                message: `Sections added successfully`,
              });
              setIsLoading(false);
            },
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error adding sections. Please try again!`,
          });
          setIsLoading(false);
        }
      },
    });
  }

  function removeSection(sectionId: string) {
    setIsLoading(true);
    lessonActions.removeSection({
      sectionId: sectionId,
      id: (selectedLessonOption as unknown) as number,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.getSectionAndStudentList({
            id: (selectedLessonOption as unknown) as number,
            onComplete: (payload) => {
              setSectionList(payload.sectionList);
              lessonActions.updateReduxStudentList(payload.studentList);
              setStudentList(payload.studentList);

              openSnackbar({
                severity: "success",
                message: `Section removed successfully`,
              });
              setIsLoading(false);
            },
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error removing section. Please try again!`,
          });
          setIsLoading(false);
        }
      },
    });
  }

  function syncStudentList() {
    setIsLoading(true);
    studentSessionActions.syncStudentList({
      sectionIds: sectionList.map((sl) => sl.sectionId),
      id: (selectedLessonOption as unknown) as number,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.updateReduxStudentList(payload.studentList);
          setStudentList(payload.studentList);

          setIsAccessUpdated(false);
          openSnackbar({
            severity: "success",
            message: `Student synced successfully`,
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error syncing students. Please try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }

  function updateStudentAccess() {
    setIsLoading(true);
    lessonActions.updateStudentAccess({
      id: (selectedLessonOption as unknown) as number,

      updatedStudentList: studentList,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.updateReduxStudentList(studentList);
          openSnackbar({
            severity: "success",
            message: `Student access updated successfully`,
          });

          setIsAccessUpdated(false);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error updating student access. Please try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }

  function addCustomStudent(newCustomStudent: CustomStudent) {
    setIsLoading(true);
    lessonActions.addCustomStudent({
      lessonId: (selectedLessonOption as unknown) as number,
      student: newCustomStudent,
      onComplete: (payload) => {
        if (payload.studentList) {
          lessonActions.updateReduxStudentList(payload.studentList);
          openSnackbar({
            severity: "success",
            message: `Student added successfully`,
          });
          setStudentList(payload.studentList);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error adding student. Please try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }

  function checkIfStudentListAccessUpdated(studentList: StudentListItem[]) {
    let reduxStudentList =
      getStudentSessionLessonInfo.data?.lessonInfo.studentList ?? [];

    for (let i = 0; i < studentList.length; i++) {
      let sl = studentList[i];
      if (
        reduxStudentList.some(
          (rsl) =>
            rsl.sectionId === sl.sectionId &&
            rsl.userName === sl.userName &&
            (rsl.haveAccess !== sl.haveAccess ||
              rsl.isReadOnlyAccess !== sl.isReadOnlyAccess)
        )
      ) {
        setIsAccessUpdated(true);
        break;
      } else {
        setIsAccessUpdated(false);
      }
    }
  }
  function resetLessonForStudent(
    lessonId: number,
    studentId: string,
    studentName: string
  ) {
    setIsLoading(true);
    lessonActions.resetStudentLessonByInstructor({
      studentId,
      lessonId,
      onComplete: (payload) => {
        if (payload.success) {
          openSnackbar({
            severity: "success",
            message: `${selectedLessonName} reset successfully for ${studentName}`,
          });
          setIsLoading(false);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error resetting the lesson. ${studentName} has no data for ${selectedLessonName}`,
          });
          setIsLoading(false);
        }
      },
    });
  }

  function saveLessonSettings(lessonSettings: LessonSettings) {
    setIsLoading(true);
    const st = lessonSettings.startDateTime
      ? lessonSettings.startDateTime.endsWith("Z")
        ? lessonSettings.startDateTime
        : lessonSettings.startDateTime.concat("Z")
      : lessonSettings.startDateTime;

    const et = lessonSettings.endDateTime
      ? lessonSettings.endDateTime.endsWith("Z")
        ? lessonSettings.endDateTime
        : lessonSettings.endDateTime.concat("Z")
      : lessonSettings.endDateTime;

    lessonActions.saveLessonSettings({
      id: (selectedLessonOption as unknown) as number,
      isTimeBasedAccess: lessonSettings.isTimeBasedAccess,
      startDateTime: st,
      endDateTime: et,
      // ? moment(lessonSettings.endDateTime).toISOString()
      // : undefined,
      onComplete: (payload) => {
        if (payload.success) {
          lessonActions.updateReduxLessonSettings(lessonSettings);
          openSnackbar({
            severity: "success",
            message: `Lesson settings saved successfully`,
          });
        } else {
          openSnackbar({
            severity: "error",
            message: `Error saving lesson settings. Please try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }

  let selectedLessonName =
    lessonOptions.find((lo) => lo.id === selectedLessonOption)?.name ??
    "this lesson";

  const lessonSettings =
    getStudentSessionLessonInfo.data?.lessonInfo.lessonSettings;

  return (
    <AdminTemplate
      title="Administration - Student Sessions"
      isLoading={isLoading}
      noLessonExists={getLessonList.data && getLessonList.data.length === 0}
    >
      <Content>
        <TopSection>
          <PicklistContainer>
            <PickListNonFieldEditor
              id="lessonlist"
              label="Lesson"
              value={selectedLessonOption}
              onChange={(v) => {
                history.push(`${StudentSessionsRoute}/${v}`);
              }}
              options={lessonOptions?.sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
            />
          </PicklistContainer>
        </TopSection>
        <LessonSettingsSection
          selectedLessonName={selectedLessonName}
          lessonSettings={lessonSettings}
          onSave={saveLessonSettings}
          isLoading={isLoading}
        />
        <Sections
          selectedLessonName={selectedLessonName}
          sectionList={sectionList}
          onRemoveClick={(sectionId) => {
            removeSection(sectionId);
          }}
          onAddSections={(selectedSections) => {
            addSectionsToAllLessons(selectedSections);
          }}
        />
        <Students
          studentList={studentList}
          lessonName={selectedLessonName}
          onUpdateAccessClick={updateStudentAccess}
          onSyncClick={syncStudentList}
          onViewClick={(studentId, studentName) => {
            setIsLoading(true);
            lessonActions.getDataforViewAsStudent({
              id: (selectedLessonOption as unknown) as number,
              studentId: studentId,
              onComplete: (payload) => {
                if (!!payload.lessonName) {
                  window.open(
                    `/${selectedLessonOption}${SearchClientsRoute}/${studentId}`
                  );
                } else {
                  openSnackbar({
                    severity: "error",
                    message: `${studentName} has no data for ${selectedLessonName}`,
                  });
                }
                setIsLoading(false);
              },
            });
          }}
          onCheckboxClick={(updatedStudent) => {
            let newStudentList = studentList.map((s) =>
              s.sectionId === updatedStudent.sectionId &&
              s.userName === updatedStudent.userName
                ? updatedStudent
                : s
            );
            setStudentList(newStudentList);
            checkIfStudentListAccessUpdated(newStudentList);
          }}
          onSelectAllClick={(selectedStudentList) => {
            let newStudentList = studentList.map((s) => {
              let isStudentAccessUpdated = selectedStudentList.some(
                (ss) =>
                  ss.sectionId === s.sectionId && ss.userName === s.userName
              );

              if (isStudentAccessUpdated) {
                let UpdatedStudent: StudentListItem = {
                  ...s,
                  haveAccess: true,
                  isReadOnlyAccess: false,
                };
                return UpdatedStudent;
              } else {
                return s;
              }
            });
            setStudentList(newStudentList);
            checkIfStudentListAccessUpdated(newStudentList);
          }}
          onDeselectAllClick={(selectedStudentList) => {
            let newStudentList = studentList.map((s) => {
              let isStudentAccessUpdated = selectedStudentList.some(
                (ss) =>
                  ss.sectionId === s.sectionId && ss.userName === s.userName
              );

              if (isStudentAccessUpdated) {
                let UpdatedStudent: StudentListItem = {
                  ...s,
                  haveAccess: false,
                };
                return UpdatedStudent;
              } else {
                return s;
              }
            });

            setStudentList(newStudentList);
            checkIfStudentListAccessUpdated(newStudentList);
          }}
          onReadOnlySelectAllClick={(selectedStudentList) => {
            let newStudentList = studentList.map((s) => {
              let isStudentAccessUpdated = selectedStudentList.some(
                (ss) =>
                  ss.sectionId === s.sectionId && ss.userName === s.userName
              );

              if (isStudentAccessUpdated) {
                let UpdatedStudent: StudentListItem = {
                  ...s,
                  isReadOnlyAccess: true,
                  haveAccess: false,
                };
                return UpdatedStudent;
              } else {
                return s;
              }
            });
            setStudentList(newStudentList);
            checkIfStudentListAccessUpdated(newStudentList);
          }}
          onReadOnlyDeselectAllClick={(selectedStudentList) => {
            let newStudentList = studentList.map((s) => {
              let isStudentAccessUpdated = selectedStudentList.some(
                (ss) =>
                  ss.sectionId === s.sectionId && ss.userName === s.userName
              );

              if (isStudentAccessUpdated) {
                let UpdatedStudent: StudentListItem = {
                  ...s,
                  isReadOnlyAccess: false,
                };
                return UpdatedStudent;
              } else {
                return s;
              }
            });
            setStudentList(newStudentList);
            checkIfStudentListAccessUpdated(newStudentList);
          }}
          isAccessUpdated={isAccessUpdated}
          isSyncDisabled={!sectionList || sectionList.length === 0}
          isAddStudentDisabled={
            !lessonOptions.some((op) => op.id === selectedLessonOption)
          }
          onAddStudent={(newCustomStudent) => {
            addCustomStudent(newCustomStudent);
          }}
          onResetClick={(studentId, studentName) => {
            resetLessonForStudent(
              (selectedLessonOption as unknown) as number,
              studentId,
              studentName
            );
          }}
          isTimeBasedAccess={lessonSettings?.isTimeBasedAccess ?? false}
        />
      </Content>
    </AdminTemplate>
  );
}

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 2em auto;
  min-width: 1000px;
`;

const PicklistContainer = styled.div`
  width: 300px;
`;

const TopSection = styled.div`
  flex: 1;
  min-width: inherit;
`;
