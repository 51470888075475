import { CommonFieldProps, Entity, PickListField } from "types";
import PickListNonFieldEditor from "./PickListNonFieldEditor";

export default function PickListFieldEditor<T extends Entity>({
  field,
  state,
  setState,
  errors,
  menuMaxHeight,
}: CommonFieldProps<T> & {
  menuMaxHeight?: number;
}) {
  const errObj = errors.find((e) => e.id === field.id);
  const pickListField = field as PickListField<T>;

  return (
    <PickListNonFieldEditor
      id={field.id}
      label={field.label}
      required={
        field.isRequired ||
        (field.checkIfRequired && field.checkIfRequired(state))
      }
      disabled={
        field.disabled ||
        (field.checkIfDisabled && field.checkIfDisabled(state))
      }
      defaultValue={pickListField.defaultValue}
      value={pickListField.getValue(state) ?? pickListField.defaultValue ?? ""}
      onChange={(value) => setState(pickListField.setValue(value, state))}
      options={pickListField.options ?? []}
      errObj={errObj}
      menuMaxHeight={menuMaxHeight}
      autoWidth={pickListField.autoWidth}
      hideLabel={pickListField.hideLabel}
    />
  );
}
