import styled from "styled-components";
import { MASK_TYPE } from "types";
import MaskedValue from "common/MaskedValue";

export default function NetWorthSection(props: { netWorth: number }) {
  return (
    <SectionContainer>
      <TotalBalanceRow>
        <LeftContent>Net Worth </LeftContent>
        <RightContent>
          {MaskedValue(MASK_TYPE.MONEY, props.netWorth.toString())}
        </RightContent>
      </TotalBalanceRow>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  padding: 0em 0.5em 0.5em 0.5em;
`;

const LeftContent = styled.div`
  font-weight: 500;
  font-size: 18px;
  width: 100px;
  flex: 1;
`;
const TotalBalanceRow = styled.div`
  display: flex;
  margin: 1em 0em -0.5em 0em;
  justify-content: space-between;
  align-items: center;
`;

const RightContent = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 18px;
`;
