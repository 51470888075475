import { add } from "common/functions";
import { FIELD_TYPE, TextFieldType,DateField, MASK_TYPE, PersonalChequing, BoolField } from "types";
import { v4 } from "uuid"



export const personalChequingOpenDate: DateField<PersonalChequing> = {
  id: `personalChequingOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: PersonalChequing) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
checkIfDisabled: (account: PersonalChequing) => {   
  return account.isAccountClosed ?? false
}
};

export const personalChequingCloseDate: DateField<PersonalChequing> = {
  id: `personalChequingCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: PersonalChequing) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const personalChequingAccounNumber: TextFieldType<PersonalChequing> = {
  id: `personalChequingAccounNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: PersonalChequing) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true
};

export const personalChequingOverdraftProtection: BoolField<PersonalChequing> = {
  id: `personalChequingOverdraftProtection_${v4()}`,
  label: "Overdraft Protection",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (account: PersonalChequing) => account.overdraftProtection,
  setValue: (value, account) => ({
    ...account,
    overdraftProtection: value,
  }),
  checkIfDisabled: (account: PersonalChequing) => {   
    return account.isAccountClosed ?? false
  }
};

export const personalChequingODPLimit: TextFieldType<PersonalChequing> = {
  id: `personalChequingODPLimit_${v4()}`,
  label: "ODP Limit",
  type: FIELD_TYPE.TEXT,
  getValue: (account: PersonalChequing) => account.overdraftProtection? account.overdraftLimit?.toString() : "0",
  setValue: (value, account) => ({
    ...account,
    overdraftLimit: value as unknown as number,
  }),
  mask: MASK_TYPE.MONEY,
  checkIfRequired: (account: PersonalChequing) => {   
    return account.overdraftProtection?? false
  },
  checkIfDisabled: (account: PersonalChequing) => {   
    return !account.overdraftProtection || (account.isAccountClosed ?? false)
  }
};

export const personalChequingBalance: TextFieldType<PersonalChequing> = {
  id: `personalChequingBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: PersonalChequing) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    balance: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
};


export const personalChequingAvailableFunds: TextFieldType<PersonalChequing> = {
  id: `personalChequingAvailableFunds_${v4()}`,
  label: "Available Funds",
  type: FIELD_TYPE.TEXT,
  getValue: (account: PersonalChequing) => add(account.overdraftLimit?? 0, account.balance ?? 0).toString(),
  setValue: (value, account) => ({
    ...account,
    availableFunds: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
};

