import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { Entity, CommonFieldProps, BoolField } from "types";
import { FieldContainer } from "./commonFieldStyles";

export default function CheckboxEditor<T extends Entity>({
  field,
  state,
  setState,
  errors,
  isDependentlyDisabled,
}: CommonFieldProps<T> & {
  isDependentlyDisabled?: boolean;
}) {
  const errObj = errors.find((e) => e.id === field.id);

  const checkbox = field as BoolField<T>;

  return (
    <FieldContainer>
      <FormControlLabel
        style={{ marginLeft: "0px" }}
        control={
          <Checkbox
            id={field.id}
            value={checkbox.getValue(state)}
            checked={checkbox.getValue(state)}
            onChange={(e) =>
              setState(checkbox.setValue(!checkbox.getValue(state), state))
            }
            disabled={
              field.disabled ||
              (field.checkIfDisabled && field.checkIfDisabled(state)) ||
              (isDependentlyDisabled && isDependentlyDisabled === true)
            }
            size="small"
          />
        }
        label={field.label}
        labelPlacement={checkbox.labelPlacement ?? "start"}
      />

      {!!errObj && (
        <FormHelperText style={{ color: "#d32f2f" }}>
          {errObj.error}
        </FormHelperText>
      )}
    </FieldContainer>
  );
}
