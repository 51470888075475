import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import {
  employmentAddressErrors,
  requiredDateField,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import { useEffect, useState } from "react";
import { Employer, Employment, FieldError } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientInformation/components/employmentFieldDefinitions";
import * as employerFields from "pages/clientPortfolio/personalClient/personalClientInformation/components/employerFieldDefinitions";
import FieldEditor from "components/FieldEditor";
import {
  employerNotExistsForType,
  employerNotRequiredForType,
} from "common/helpers";
import { useReduxState } from "store";
import EmployerSearchList from "./EmployerSearchList";
import { getEmployerList } from "pages/lessons/helpers";

export type Props = {
  employment: Employment;
  employer?: Employer;
  onSave(updatedEmployment: Employment): void;
  onCancel(): void;
};

export default function EmploymentEditor(props: Props) {
  const [state, setState] = useState<Employment>(props.employment);
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [employer, setEmployer] = useState<Employer>(props.employer ?? {});

  const commonProps = {
    state: state,
    errors,
    setState: (state: Employment) => {
      setState(state);
    },
  };

  const employerProps = {
    state: employer,
    errors,
    setState: (employer: Employer) => {
      setState(employer);
    },
  };

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const [openEmployerDialog, setOpenEmployerDialog] = useState<boolean>(false);

  const employmentType = state.employmentType;
  const isEmployerFieldsRequired =
    !!employmentType && !employerNotRequiredForType.includes(employmentType);

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredPickListField(fields.employmentType, state),
      ...requiredDateField(fields.employmentStartDate, state),
      ...requiredTextField(fields.jobTitle, state),
      ...requiredTextField(
        employerFields.employerName,
        employer,
        isEmployerFieldsRequired
      ),
      ...employmentAddressErrors(
        employerFields.employerAddress,
        employer,
        isEmployerFieldsRequired
      ),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  function attachEmployer(employer: Employer) {
    setEmployer(employer);
    setState({
      ...state,
      employerId: employer.employerId,
    });
  }

  return (
    <>
      <Dialog maxWidth={"md"} fullWidth open>
        <DialogTitle>
          {!!props.employment.employmentType
            ? "Edit Employment"
            : "Add Employment"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<Employment>
                field={fields.employmentType}
                {...commonProps}
              />
              <FieldEditor<Employment>
                field={fields.employmentStartDate}
                {...commonProps}
              />
              <FieldEditor<Employer>
                field={employerFields.employerName}
                isDependentlyRequired={isEmployerFieldsRequired}
                {...employerProps}
              />
              <FieldEditor<Employment>
                field={fields.jobTitle}
                {...commonProps}
              />
              <FieldEditor<Employment>
                field={fields.isCurrent}
                {...commonProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                style={{ margin: "1.25em 0" }}
                disabled={
                  employmentType
                    ? employerNotExistsForType.includes(employmentType)
                    : true
                }
                onClick={() => {
                  setOpenEmployerDialog(true);
                }}
              >
                Attach Employer
              </Button>
              <FieldEditor<Employer>
                field={employerFields.employerAddress}
                isDependentlyRequired={isEmployerFieldsRequired}
                {...employerProps}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              const errors = checkErrors();
              if (errors.length === 0) {
                props.onSave(state);
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <AttachEmployerDialog
        open={openEmployerDialog}
        onClose={() => {
          setOpenEmployerDialog(false);
        }}
        onSelected={(e) => {
          attachEmployer(e);
          setOpenEmployerDialog(false);
        }}
      />
    </>
  );
}

const AttachEmployerDialog = (props: {
  open: boolean;
  onClose?(): void;
  onSelected(e: Employer): void;
}) => {
  const { getLessonNameAndData } = useReduxState((e) => e.lesson);

  if (!props.open) return <></>;

  return (
    // used Dialog from "@mui/material" instead of SimpleDialog from @nait-aits/ui because
    // SimpleDialog has z-index of 3000 due to which pagination drop down fails to display on top

    <Dialog maxWidth={"md"} open onClose={props.onClose}>
      <div style={{ padding: "1em" }}>
        <EmployerSearchList
          employers={getEmployerList(getLessonNameAndData.data?.lessonData)}
          onEmployerSelected={props.onSelected}
        />
      </div>
    </Dialog>
  );
};
