import FieldEditor from "components/FieldEditor";
import { FieldError, Loan } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientAccounts/personalLoanFieldDefinitions";
import { useEffect, useState } from "react";
import {
  requiredDateField,
  requiredPickListField,
  requiredRadioGroupField,
  requiredTextField,
} from "common/functions";
import AccountEditTemplate from "pages/clientPortfolio/personalClient/personalClientAccounts/components/AccountEditTemplate";
import { openConfirm } from "components/confirmBox";
export type Props = {
  account: Loan;
  isSaving: boolean;
  onCancel(): void;
  onSave(account: Loan): void;
};

function LoanForm({ account, onCancel, onSave, isSaving }: Props) {
  const [state, setState] = useState<Loan>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: Loan) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredDateField(fields.loanOpenDate, state),
      ...requiredTextField(fields.loanPaymentAmount, state),
      ...requiredPickListField(fields.loanPaymentFrequency, state),
      ...requiredRadioGroupField(fields.loanInterestType, state),
      ...requiredRadioGroupField(fields.loanType, state),
      ...requiredTextField(fields.loanInterestRate, state),
      ...requiredTextField(fields.loanSecurity, state),
      ...requiredPickListField(fields.loanTermLength, state),
      ...requiredDateField(fields.loanTermStartDate, state),
    ];
    if (
      state.closeDate &&
      state.openDate &&
      state.closeDate.toLowerCase() !== "invalid date" &&
      new Date(state.closeDate) < new Date(state.openDate)
    ) {
      let invalidCloseDateErr: FieldError = {
        id: `${fields.loanCloseDate.id}`,
        label: `${fields.loanCloseDate.label}`,
        error: "Close Date should be after the Open Date",
      };
      errorsArray.push(invalidCloseDateErr);
    }

    if (
      state.termStartDate &&
      state.openDate &&
      state.termStartDate.toLowerCase() !== "invalid date" &&
      new Date(state.termStartDate) < new Date(state.openDate)
    ) {
      let invalidTermStartDateErr: FieldError = {
        id: `${fields.loanTermStartDate.id}`,
        label: `${fields.loanTermStartDate.label}`,
        error: "Term Start Date should be after the Open Date",
      };
      errorsArray.push(invalidTermStartDateErr);
    }
    setErrors(errorsArray);
    return errorsArray;
  }

  function onSaveClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (
        state.closeDate &&
        state.closeDate.toLowerCase() !== "invalid date" &&
        state.balance &&
        state.balance !== 0
      ) {
        openConfirm({
          title: "Error",
          description: `Account Balance should be $0.00 to close the account`,
          isAlert: true,
        });
      } else {
        onSave({
          ...state,
          isAccountClosed:
            state.closeDate && state.closeDate.toLowerCase() !== "invalid date"
              ? true
              : false,
          interestRate:
            state.interestType === "Fixed" &&
            ((state.interestRate as unknown) as number) < 0
              ? (((state.interestRate as unknown) as number) * -1).toString()
              : state.interestRate,
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`Loan_${account.accountId}_form`}
      onSaveClick={onSaveClick}
      onCancelClick={onCancel}
      isSaving={isSaving}
      type="Loan"
      accountNumber={account.accountNumber || ""}
      balance={state.balance || 0}
      column1Fields={
        <>
          <FieldEditor<Loan> field={fields.loanOpenDate} {...commonProps} />
          <FieldEditor<Loan>
            field={fields.loanAccountNumber}
            {...commonProps}
          />

          <FieldEditor<Loan> field={fields.loanBalance} {...commonProps} />

          <FieldEditor<Loan>
            field={fields.loanPaymentAmount}
            {...commonProps}
          />

          <FieldEditor<Loan>
            field={fields.loanPaymentFrequency}
            {...commonProps}
          />
          <FieldEditor<Loan>
            field={fields.loanTermStartDate}
            {...commonProps}
          />
          <FieldEditor<Loan> field={fields.loanTermLength} {...commonProps} />
          <FieldEditor<Loan>
            field={fields.loanRemainingTermLength}
            {...commonProps}
          />
        </>
      }
      column2Fields={
        <>
          <FieldEditor<Loan> field={fields.loanCloseDate} {...commonProps} />
          <FieldEditor<Loan> field={fields.loanInterestType} {...commonProps} />
          {state.interestType === "Variable" && (
            <FieldEditor<Loan> field={fields.loanPrimeRate} {...commonProps} />
          )}
          <FieldEditor<Loan> field={fields.loanInterestRate} {...commonProps} />
          {state.interestType === "Variable" && (
            <FieldEditor<Loan>
              field={fields.loanTotalInterestRate}
              {...commonProps}
            />
          )}
          <FieldEditor<Loan> field={fields.loanType} {...commonProps} />
          {state.type === "Secured" && (
            <FieldEditor<Loan> field={fields.loanSecurity} {...commonProps} />
          )}
        </>
      }
    />
  );
}

export default LoanForm;
