import { Divider, Paper } from "@mui/material";
import {
  add,
  getTotalCoinsCashAmount,
  getTotalNotesCashAmount,
} from "common/functions";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CashDrawer } from "types";
import CashSection from "./CashSection";
import CashTotal from "./CashTotal";
import StartingBalanceAndOverride from "pages/cashDrawer/components/StartingBalanceAndOverride";
import SupervisorOverrideDialog from "pages/cashDrawer/components/SupervisorOverrideDialog";

export type Props = {
  cashDrawer: CashDrawer;
  studentId?: string;
  onEndOfDay(updatedCashDrawer: CashDrawer): void;
  onSupervisorOverrideSubmit(updatedCashDrawer: CashDrawer): void;
  isReadOnlyView: boolean;
};

export default function EndofDayBalancing(props: Props) {
  useEffect(() => {
    setCashDrawer(props.cashDrawer ?? {});
  }, [props.cashDrawer]);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [cashDrawer, setCashDrawer] = useState<CashDrawer>({});

  let cash = cashDrawer.cash;
  let notesTotal = getTotalNotesCashAmount(cash ?? {});
  let coinsTotal = getTotalCoinsCashAmount(cash ?? {});
  return (
    <Container>
      <Header>
        <SectionTitle>End of Day Balancing</SectionTitle>
      </Header>
      <SectionContainer>
        <CashSection
          state={cashDrawer}
          setState={setCashDrawer}
          isReadOnlyView={props.isReadOnlyView}
        />
      </SectionContainer>
      <Divider style={{ marginTop: "16px" }} />
      <SectionContainer>
        <CashTotal notesTotal={notesTotal} coinsTotal={coinsTotal} />
      </SectionContainer>
      <Divider />
      {/* <SectionContainer> */}
      <StartingBalanceAndOverride
        calculatedTotal={add(notesTotal, coinsTotal)}
        cashDrawerBalance={cashDrawer.balance ?? 0}
        startingTotal={cashDrawer.startingTotal ?? 0}
        overrideStatus={cashDrawer.overrideStatus ?? "Not Submitted"}
        isReadOnlyView={props.isReadOnlyView}
        onEndOfDay={() => {
          props.onEndOfDay({ ...cashDrawer, overrideStatus: "Under Review" });
        }}
        onOverride={() => setOpenDialog(true)}
      />
      {/* </SectionContainer> */}
      {openDialog && (
        <SupervisorOverrideDialog
          onSubmit={(overrideStatus) => {
            let updatedCashDrawer: CashDrawer = {
              ...cashDrawer,
              overrideStatus:
                overrideStatus === "approve" ? "Not Submitted" : "Rejected",
              cash:
                overrideStatus === "approve"
                  ? undefined
                  : { ...cashDrawer.cash },
              endOfDayTransactionIds:
                overrideStatus === "approve"
                  ? []
                  : [...(cashDrawer.endOfDayTransactionIds ?? [])],
              startingTotal:
                overrideStatus === "approve"
                  ? cashDrawer.balance
                  : cashDrawer.startingTotal,
            };
            props.onSupervisorOverrideSubmit(updatedCashDrawer);
            setOpenDialog(false);
          }}
          onCancel={() => setOpenDialog(false)}
        />
      )}
    </Container>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
`;

const SectionTitle = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Container = styled(Paper)`
  margin-top: 2em;
  min-height: 500px;
  min-width: inherit;
`;
