
import { getGLTransactionFromToLabel, getGLTransactionOptions } from "common/functions";
import {  
  FIELD_TYPE,  
  MASK_TYPE,
  PickListField,
  TextFieldType,
  GLTransaction,
  } from "types";


export const from: PickListField<GLTransaction> = {
  id: "from",
  label: "From",
  type: FIELD_TYPE.PICKLIST,
  getValue: (transaction: GLTransaction) =>  transaction.from,
  setValue: (value, transaction) => ({...transaction,from: value }),
  options: getGLTransactionOptions(),
  disabled: true,
  isRequired: true,
  getOptionLabel: (value, options) => getGLTransactionFromToLabel(value) ?? "",
};


export const to: PickListField<GLTransaction> = {
  id: "to",
  label: "To",
  type: FIELD_TYPE.PICKLIST,
  getValue: (transaction: GLTransaction) =>  transaction.to,
  setValue: (value, transaction) => ({...transaction,to: value }),
  options: getGLTransactionOptions(),
  disabled: true,
  isRequired: true,
  getOptionLabel: (value, options) => getGLTransactionFromToLabel(value) ?? "",
};

export const amount: TextFieldType<GLTransaction> = {
  id: "amount",
  label: "Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: GLTransaction) => transaction.amount ? transaction.amount.toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    amount: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
};