import { Divider, IconButton } from "@mui/material";
import { Cheque } from "types";
import styled from "styled-components";
import { openConfirm } from "components/confirmBox";
import DeleteIcon from "@mui/icons-material/Delete";
import SummaryRowTemplate from "pages/transactions/components/SummaryRowTemplate";
import { getMaskedMoneyValue } from "common/functions";

type SummaryRowReceiveChequProps = {
  cheque: Cheque;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

export default function SummaryRowReceiveCheque(
  props: SummaryRowReceiveChequProps
) {
  const totalAmount = getMaskedMoneyValue(props.cheque.totalChequeAmount ?? 0);
  const cadValue = getMaskedMoneyValue(props.cheque.chequeCADValue ?? 0);
  const currency = props.cheque.chequeCurrencyType ?? "";
  const holdAmount = props.cheque.holdAmount ?? 0;
  // let holdText =
  //   holdAmount > 0
  //     ? `${getMaskedMoneyValue(holdAmount)} ${currency.toUpperCase()} for ${
  //         props.cheque.duration
  //       } Days`
  //     : `${getMaskedMoneyValue(holdAmount)} ${currency.toUpperCase()}`;
  //as requested by business team in project check-in meeting dated June 3, displaying CAD with hold amount instead of selected currency
  let holdText =
    holdAmount > 0
      ? `${getMaskedMoneyValue(holdAmount)} CAD for ${
          props.cheque.duration
        } Days`
      : `${getMaskedMoneyValue(holdAmount)} CAD`;
  return (
    <SummaryRowTemplate
      transaction="Receive Cheque"
      debit={`${cadValue}`}
      details={holdAmount >= 0 ? `Hold ${getMaskedMoneyValue(holdAmount)}` : ""}
    >
      {props.cheque.chequesArray &&
        props.cheque.chequesArray.map((c, index) => {
          return (
            <ChequeItemRow
              chequeAmount={c}
              key={index}
              chequeNumber={index + 1}
              currency={currency}
            />
          );
        })}
      <Divider />
      <DetailsRow isBold={true}>
        <TextSection>Total</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${totalAmount} ${currency.toUpperCase()}`}
        </TextSection>
      </DetailsRow>
      {currency === "usd" && (
        <DetailsRow style={{ marginTop: "-0.5em" }}>
          <TextSection>
            {" "}
            <Spacer />
          </TextSection>
          <TextSection style={{ textAlign: "right" }}>
            {`${cadValue} CAD`}
          </TextSection>
        </DetailsRow>
      )}
      {holdAmount >= 0 && (
        <DetailsRow isBold={true}>
          <TextSection>Hold</TextSection>
          <div style={{ minWidth: 120 }}>
            <TextSection>{holdText}</TextSection>
            <TextSection
              style={{
                fontWeight: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.cheque.reason}
            </TextSection>
          </div>
          <TextSection>
            {" "}
            <Spacer />
          </TextSection>
        </DetailsRow>
      )}
      <DetailsRow isBold={true}>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          <IconButton
            style={{ margin: "-1em 0" }}
            aria-label="delete"
            onClick={() => {
              openConfirm({
                title: "Delete Transaction",
                description: `Receive Cheque - ${cadValue}`,
                callback: () => {
                  props.onDeleteClick();
                },
              });
            }}
            disabled={props.isReadOnlyView}
          >
            <DeleteIcon />
          </IconButton>
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

function ChequeItemRow(props: {
  chequeAmount: number | null;
  chequeNumber: number;
  currency: string;
}) {
  return (
    <>
      <Divider />
      <DetailsRow>
        <TextSection>{`Cheque ${props.chequeNumber}`}</TextSection>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${getMaskedMoneyValue(
            props.chequeAmount ?? 0
          )} ${props.currency.toUpperCase()}`}
        </TextSection>
      </DetailsRow>
    </>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
