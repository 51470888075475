import MaskedValue from "common/MaskedValue";
import { DisplayFieldProps, Entity, TextFieldType } from "types";
import DisplayFieldTemplate from "./DisplayFieldTemplate";
import NotSetDisplay from "./NotSetDisplay";
export default function TextFieldDisplay<T extends Entity>({
  field,
  state,
}: DisplayFieldProps<T>) {
  const textField = field as TextFieldType<T>;

  const value = textField.getValue(state) || "";
  return (
    <DisplayFieldTemplate label={textField.label}>
      {/* {textField.getValue(state) || <NotSetDisplay />} */}
      {MaskedValue(textField.mask, value) || <NotSetDisplay />}
    </DisplayFieldTemplate>
  );
}
