import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import styled from "styled-components";
import { SellDraft } from "types";

export type Props = {
  transaction: SellDraft;
};

export default function SellDraftDetails(props: Props) {
  return (
    <div>
      <Table>
        <Header />
        <TableBody>
          <Row transaction={props.transaction} />
        </TableBody>
      </Table>
    </div>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell>Transaction</Cell>
        <Cell>Serial#</Cell>
        <Cell>Payee</Cell>
        <Cell>Amount</Cell>
        <Cell>Fee</Cell>
        <Cell>Total Amount</Cell>
      </TableRow>
    </TableHead>
  );
}

function Row(props: { transaction: SellDraft }) {
  return (
    <TableRow>
      <Cell>Sell Draft</Cell>
      <Cell>{props.transaction.serialNumber}</Cell>
      <Cell>{props.transaction.payee}</Cell>
      <Cell>
        {`${getMaskedMoneyValue(
          props.transaction.draftAmount ?? 0
        )} ${props.transaction.currencyType?.toUpperCase()}`}
      </Cell>
      <Cell>{`${getMaskedMoneyValue(props.transaction.fee ?? 0)} CAD`}</Cell>
      <Cell>{`${getMaskedMoneyValue(props.transaction.amount ?? 0)} CAD`}</Cell>
      {/* <Cell>
          <Button onClick={props.onViewTransactionClick}>View Transaction</Button>
        </Cell>
        <Cell>
          <Button onClick={props.onApproveClick}>Approve</Button>
        </Cell>
        <Cell>
          <Button onClick={props.onRejectClick}>Reject</Button>
        </Cell> */}
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  padding: 10px 16px 10px 16px !important;
  text-align: center !important;
`;
