import { Button } from "@mui/material";
import {
  checkIfUnauthorized,
  getBusinessClientOperatingAs,
} from "common/functions";
import ClientPortfolioTemplate from "components/clientPortfolioTemplate/ClientPortfolioTemplate";
import MainTemplate from "components/mainTemplate/MainTemplate";
import {
  BusinessClientAccountHistoryRoute,
  UnauthorizedRoute,
} from "components/paths";
import { openSnackbar } from "components/snackbar";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useActions, useReduxState } from "store";
import styled from "styled-components";
import {
  Account,
  CreditCard,
  GeneralLedger,
  GIC,
  LineOfCredit,
  Loan,
  Mortgage,
  MutualFunds,
  BusinessChequing,
  BusinessClient,
  BusinessSavings,
} from "types";
import { v4 } from "uuid";
import AddAccountMenu from "./components/AddAccountMenu";
import CreditCardDisplay from "./components/CreditCardDisplay";
import EditAccountDialog from "./components/EditAccountDialog";
import GICDisplay from "./components/GICDisplay";
import LineOfCreditDisplay from "./components/LineOfCreditDisplay";
import LoanDisplay from "./components/LoanDisplay";
import MortgageDisplay from "./components/MortgageDisplay";
import MutualFundsDisplay from "./components/MutualFundsDisplay";
import BusinessChequingDisplay from "./components/BusinessChequingDisplay";
import BusinessSavingsDisplay from "./components/BusinessSavingsDisplay";
import { getBusinessClientById, getGeneralLedger } from "pages/lessons/helpers";

export type Props = {
  clientId: string;
  lessonId: string;
  studentId?: string;
};

export default function BusinessClientAccounts(props: Props) {
  const {
    getLessonNameAndData,
    getDataforViewAsStudent,
    lessonId,
  } = useReduxState((e) => e.lesson);
  const lessonActions = useActions().lesson;
  const clientInfoActions = useActions().businessClientInformation;
  const [state, setState] = useState<BusinessClient>(
    getBusinessClientById(
      props.clientId,
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [accountInEdit, setAccountInEdit] = useState<Account | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }
  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            if (props.clientId) {
              let client: BusinessClient = getBusinessClientById(
                props.clientId,
                payload.lessonData
              );

              if (!!client.information?.clientId) {
                setState(client);
                setGLState(getGeneralLedger(payload.lessonData));
              } else history.push(UnauthorizedRoute);
            }
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getBusinessClientById(
              props.clientId,
              getLessonNameAndData.data.lessonData
            )
          );
          setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
          setIsLoading(false);
        }
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            if (props.clientId) {
              setState(
                getBusinessClientById(props.clientId, payload.lessonData)
              );
              setGLState(getGeneralLedger(payload.lessonData));
            }
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getBusinessClientById(
              props.clientId,
              getDataforViewAsStudent.data.lessonData
            )
          );
          setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
          setIsLoading(false);
        }
      }
    }
  }, []);

  const clientName = getBusinessClientOperatingAs(state);

  function getupdatedClient(account: Account) {
    const isNew = state.accounts
      ? !state.accounts.some((a) => a.accountId === account.accountId)
      : "true";
    const updatedClient: BusinessClient = {
      ...state,
      accounts: state.accounts
        ? isNew
          ? [...state.accounts, account]
          : state.accounts.map((a) =>
              account.accountId === a.accountId ? { ...account } : a
            )
        : [account],
    };
    setState(updatedClient);
    return updatedClient;
  }

  function onDeleteClick(accountId: string) {
    const accounts = state.accounts || [];
    const updatedClient = {
      ...state,
      accounts: accounts.filter((a) => a.accountId !== accountId),
    };
    setState(updatedClient);
    setAccountInEdit(undefined);
    onSave(updatedClient);
  }

  function getRandomNumber(noOfDigits: number) {
    let n = Math.pow(10, noOfDigits - 1);
    let randomNumber = Math.floor(n + Math.random() * 9 * n).toString();
    return randomNumber;
  }
  // BusinessSavings starts with 5
  function addBusinessSavings() {
    const newAccount: BusinessSavings = {
      accountId: v4(),
      accountType: "BUSINESS_SAVINGS",
      accountNumber: 5 + getRandomNumber(6), // as per business req business savings should start with 5
      balance: 0.0,
      created: moment().format(),
    };
    setAccountInEdit(newAccount);
  }

  // BusinessChequing starts with 1
  function addBusinessChequing() {
    const newAccount: BusinessChequing = {
      accountId: v4(),
      accountType: "BUSINESS_CHEQUING",
      accountNumber: 4 + getRandomNumber(6), // as per business req business chequing should start with 4
      balance: 0.0,
      created: moment().format(),
    };
    setAccountInEdit(newAccount);
  }

  function addCreditCard() {
    const newAccount: CreditCard = {
      accountId: v4(),
      accountType: "CREDIT_CARD",
      cardNumber: 8520 + getRandomNumber(12), // as per business req credit card number should be randomized and start with 8520
      balance: 0.0,
      created: moment().format(),
    };
    setAccountInEdit(newAccount);
  }

  function addGIC() {
    const newAccount: GIC = {
      accountId: v4(),
      accountType: "GIC",
      accountNumber: getRandomNumber(8),
      created: moment().format(),
      investmentCategory: "Cashable",
    };
    setAccountInEdit(newAccount);
  }

  function addMutualFunds() {
    const newAccount: MutualFunds = {
      accountId: v4(),
      accountType: "MUTUAL_FUNDS",
      accountNumber: getRandomNumber(9),
      created: moment().format(),
    };
    setAccountInEdit(newAccount);
  }

  function addLoan() {
    const newAccount: Loan = {
      accountId: v4(),
      accountType: "LOAN",
      accountNumber: getRandomNumber(10),
      balance: 0.0,
      created: moment().format(),
      interestType: "Fixed",
      type: "Secured",
    };
    setAccountInEdit(newAccount);
  }

  //Business LOC should start with 6
  function addLineOfCredit() {
    const newAccount: LineOfCredit = {
      accountId: v4(),
      accountType: "LINE_OF_CREDIT",
      accountNumber: 6 + getRandomNumber(6), // as per business req Business LOC should start with 6
      balance: 0.0,
      created: moment().format(),
      interestType: "Variable",
    };
    setAccountInEdit(newAccount);
  }

  function addMortgage() {
    const newAccount: Mortgage = {
      accountId: v4(),
      accountType: "MORTGAGE",
      accountNumber: getRandomNumber(6),
      balance: 0.0,
      created: moment().format(),
      interestType: "Fixed",
      type: "Open",
    };
    setAccountInEdit(newAccount);
  }

  function onSave(updatedClient: BusinessClient) {
    setIsLoading(true);
    clientInfoActions.saveBusinessClient({
      client: updatedClient,
      lessonId,
      onComplete: (payload) => {
        if (!!payload.clientId) {
          openSnackbar({
            severity: "success",
            message: `Business Client '${clientName}' saved successfully`,
          });

          lessonActions.saveBusinessClient(updatedClient);
          setAccountInEdit(undefined);
        } else {
          openSnackbar({
            severity: "error",
            message: `Error saving business client '${clientName}'. Please refresh the page and try again!`,
          });
        }
        setIsLoading(false);
      },
    });
  }

  function onEditClick(account: Account) {
    setAccountInEdit(account);
  }

  function onAccountHistoryClick(accountId: string) {
    !!props.studentId
      ? history.push(
          `/${lessonId}${BusinessClientAccountHistoryRoute}/${state.information?.clientId}/${accountId}/${props.studentId}`
        )
      : history.push(
          `/${lessonId}${BusinessClientAccountHistoryRoute}/${state.information?.clientId}/${accountId}`
        );
  }
  const isReadOnlyStudentAccess =
    getLessonNameAndData.data?.isReadOnly ?? false;
  return (
    <MainTemplate
      title={
        isLoading ? "Client Portfolio" : `Client Portfolio — ${clientName}`
      }
      isLoading={isLoading}
      studentId={props.studentId}
      cashDrawerBalance={glState.cashDrawer?.balance ?? 0}
    >
      <ClientPortfolioTemplate selectedButton={2} studentId={props.studentId}>
        <Content>
          {!isLoading && (
            <>
              <FlexDiv>
                <ListingHeading>Open</ListingHeading>
                <AddAccountMenu
                  onAddBusinessChequing={() => {
                    addBusinessChequing();
                  }}
                  onAddBusinessSavings={() => {
                    addBusinessSavings();
                  }}
                  onAddGIC={() => {
                    addGIC();
                  }}
                  onAddMutualFunds={() => {
                    addMutualFunds();
                  }}
                  onAddCreditCard={() => {
                    addCreditCard();
                  }}
                  onAddLoan={() => {
                    addLoan();
                  }}
                  onAddLineOfCredit={() => {
                    addLineOfCredit();
                  }}
                  onAddMortgage={() => {
                    addMortgage();
                  }}
                  isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
                />
              </FlexDiv>

              <AccountList
                accounts={state.accounts}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                onAccountHistoryClick={onAccountHistoryClick}
                show={"open"}
                isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
              />
              <ClosedAccountSection>
                <ListingHeading>Closed</ListingHeading>
                <AccountList
                  accounts={state.accounts}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                  onAccountHistoryClick={onAccountHistoryClick}
                  show={"close"}
                  isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
                />
              </ClosedAccountSection>
            </>
          )}
        </Content>
      </ClientPortfolioTemplate>
      {!!accountInEdit && (
        <EditAccountDialog
          account={accountInEdit}
          isSaving={isLoading}
          onSave={(account) => {
            const updatedClient = getupdatedClient(account);
            onSave(updatedClient);
          }}
          onCancel={() => {
            setAccountInEdit(undefined);
          }}
        />
      )}
    </MainTemplate>
  );
}

function accountSorter(a: Account, b: Account): number {
  //Account Sort Order
  // - Business Chequing
  // - Business Savings
  // - GIC
  // - Mutual Fund
  // - Credit Card
  // - Loan
  // - Line of Credit
  // - Mortgage

  const sortOrder = [
    "BUSINESS_CHEQUING",
    "BUSINESS_SAVINGS",
    "GIC",
    "MUTUAL_FUNDS",
    "CREDIT_CARD",
    "LOAN",
    "LINE_OF_CREDIT",
    "MORTGAGE",
  ];

  if (a.accountType === b.accountType) {
    if (!!a.openDate && !!b.openDate) {
      return b.created.localeCompare(a.created);
    }
    if (!a.openDate && !b.openDate) {
      return b.created.localeCompare(a.created);
    }
    if (!a.openDate) {
      return 1;
    }
    return -1;
  }

  const aOrder = sortOrder.indexOf(a.accountType);
  const bOrder = sortOrder.indexOf(b.accountType);
  return aOrder - bOrder;
}

type AccountListProps = {
  accounts?: Account[];
  onEditClick(account: Account): void;
  onDeleteClick(accountId: string): void;
  onAccountHistoryClick(accountId: string): void;
  isReadOnlyView: boolean;
  show: "open" | "close";
};

function AccountList({
  accounts,
  onEditClick,
  onDeleteClick,
  onAccountHistoryClick,
  isReadOnlyView,
  show,
}: AccountListProps) {
  const [displayClosedAcc, setDisplayClosedAcc] = useState<boolean>();

  const list = accounts
    ? accounts
        .filter(
          (a) =>
            (show === "close" &&
              !!a.closeDate &&
              a.closeDate !== "Invalid date") ||
            (show === "open" &&
              (!a.closeDate || a.closeDate === "Invalid date"))
        )
        .sort(accountSorter)
    : [];

  if (list.length === 0) {
    return (
      <FlexDiv>
        <ListMessage>
          There are no {show === "open" ? "open" : "closed"} accounts yet.
        </ListMessage>
      </FlexDiv>
    );
  }

  return (
    <>
      {show === "close" && (
        <FlexDiv>
          <ListMessage>
            {list.length === 1
              ? `There is 1 closed account.`
              : `There are ${list.length} closed accounts.`}
          </ListMessage>

          <Button
            onClick={() => {
              setDisplayClosedAcc(!displayClosedAcc);
            }}
          >
            {displayClosedAcc ? "Hide" : "View"}
          </Button>
        </FlexDiv>
      )}
      {(show === "open" || (show === "close" && displayClosedAcc)) &&
        list.map((account, index) => {
          if (account.accountType === "BUSINESS_SAVINGS") {
            return (
              <BusinessSavingsDisplay
                key={account.accountId}
                onEditClick={() => onEditClick(account)}
                onDeleteClick={() => {
                  onDeleteClick(account.accountId);
                }}
                onAccountHistoryClick={() =>
                  onAccountHistoryClick(account.accountId)
                }
                account={account}
                isReadOnlyView={isReadOnlyView}
              />
            );
          }
          if (account.accountType === "BUSINESS_CHEQUING") {
            return (
              <BusinessChequingDisplay
                key={account.accountId}
                onEditClick={() => onEditClick(account)}
                onDeleteClick={() => {
                  onDeleteClick(account.accountId);
                }}
                onAccountHistoryClick={() =>
                  onAccountHistoryClick(account.accountId)
                }
                account={account}
                isReadOnlyView={isReadOnlyView}
              />
            );
          }
          if (account.accountType === "GIC") {
            return (
              <GICDisplay
                key={account.accountId}
                onEditClick={() => onEditClick(account)}
                onDeleteClick={() => {
                  onDeleteClick(account.accountId);
                }}
                account={account}
                isReadOnlyView={isReadOnlyView}
              />
            );
          }
          if (account.accountType === "MUTUAL_FUNDS") {
            return (
              <MutualFundsDisplay
                key={account.accountId}
                onEditClick={() => onEditClick(account)}
                onDeleteClick={() => {
                  onDeleteClick(account.accountId);
                }}
                account={account}
                isReadOnlyView={isReadOnlyView}
              />
            );
          }
          if (account.accountType === "CREDIT_CARD") {
            return (
              <CreditCardDisplay
                key={account.accountId}
                onEditClick={() => onEditClick(account)}
                onDeleteClick={() => {
                  onDeleteClick(account.accountId);
                }}
                onAccountHistoryClick={() =>
                  onAccountHistoryClick(account.accountId)
                }
                account={account}
                isReadOnlyView={isReadOnlyView}
              />
            );
          }
          if (account.accountType === "LOAN") {
            return (
              <LoanDisplay
                key={account.accountId}
                onEditClick={() => onEditClick(account)}
                onDeleteClick={() => {
                  onDeleteClick(account.accountId);
                }}
                onAccountHistoryClick={() =>
                  onAccountHistoryClick(account.accountId)
                }
                account={account}
                isReadOnlyView={isReadOnlyView}
              />
            );
          }
          if (account.accountType === "LINE_OF_CREDIT") {
            return (
              <LineOfCreditDisplay
                key={account.accountId}
                onEditClick={() => onEditClick(account)}
                onDeleteClick={() => {
                  onDeleteClick(account.accountId);
                }}
                onAccountHistoryClick={() =>
                  onAccountHistoryClick(account.accountId)
                }
                account={account}
                isReadOnlyView={isReadOnlyView}
              />
            );
          }
          if (account.accountType === "MORTGAGE") {
            return (
              <MortgageDisplay
                key={account.accountId}
                onEditClick={() => onEditClick(account)}
                onDeleteClick={() => {
                  onDeleteClick(account.accountId);
                }}
                onAccountHistoryClick={() =>
                  onAccountHistoryClick(account.accountId)
                }
                account={account}
                isReadOnlyView={isReadOnlyView}
              />
            );
          }
          return <div key={index}>unknown account</div>;
        })}
    </>
  );
}

const ListingHeading = styled.div`
  margin: 16px 0;
  font-size: 20px;
  color: #333;
  flex: 1;
`;

const FlexDiv = styled.div`
  display: flex;
  width: 730px;
  margin-bottom: 5px;
  align-items: center;
`;

const ListMessage = styled.div`
  color: #777;
  padding-bottom: 5px;
  margin-right: 10px;
`;

const Content = styled.div`
  margin: 2em auto;
  width: max-content;
`;

const ClosedAccountSection = styled.div`
  margin-top: 2em;
`;
