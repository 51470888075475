import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { FieldError, CustomStudent, StudentListItem } from "types";
import * as fields from "pages/admin/studentSessions/addStudentFieldDefinitions";
import { requiredTextField } from "common/functions";
import FieldEditor from "components/FieldEditor";

export type Props = {
  studentList: StudentListItem[];
  onAdd(newCustomStudent: CustomStudent): void;
  onCancel(): void;
};

export default function AddStudentDialog(props: Props) {
  const [state, setState] = useState<CustomStudent>({});
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: CustomStudent) => {
      setState(state);
    },
  };

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.fullName, state),
      ...requiredTextField(fields.studentId, state),
      ...requiredTextField(fields.username, state),
    ];
    if (
      !!props.studentList &&
      props.studentList.some((st) => st.studentId === state.studentId)
    ) {
      let studentIdAlreadyExistsErr: FieldError = {
        id: `${fields.studentId.id}`,
        label: `${fields.studentId.label}`,
        error: "Student Id already exists",
      };
      errorsArray.push(studentIdAlreadyExistsErr);
    }
    setErrors(errorsArray);
    return errorsArray;
  }

  function onAddClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      props.onAdd(state);
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open id={`add_custom_student`}>
      <DialogTitle style={{ display: "flex" }}>
        <Typography sx={{ width: "33%", flex: 5 }}>
          Add Custom Student
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FieldEditor<CustomStudent>
              field={fields.studentId}
              {...commonProps}
            />
            <FieldEditor<CustomStudent>
              field={fields.username}
              {...commonProps}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldEditor<CustomStudent>
              field={fields.fullName}
              {...commonProps}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button variant="contained" onClick={onAddClick}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
