import {
  getCreditAppGDS,
  getCreditAppTDS,
  getCreditAppTotalAssets,
  getCreditAppTotalLiabilities,
  getJointAppMonthlyIncome,
  getMonthlyConsumerDebt,
  subtract,
} from "common/functions";
import {
  employmentTypeOptions,
  residentialStatusOptions,
} from "common/helpers";
import {
  DateField,
  Employer,
  Employment,
  FIELD_TYPE,
  MASK_TYPE,
  PersonalClient,
  PickListField,
  TextFieldType,
} from "types";

export const employmentType: PickListField<Employment> = {
  id: "employmentType",
  label: "Employment Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (employment: Employment) => employment.employmentType,
  setValue: (value, employment) => ({
    ...employment,
    employmentType: value,
  }),
  disabled: true,
  options: employmentTypeOptions,
};

export const employerName: TextFieldType<Employer> = {
  id: "employerName",
  label: "Employer Name",
  type: FIELD_TYPE.TEXT,
  getValue: (employer: Employer) => employer.employerName,
  setValue: (value, employer) => ({
    ...employer,
    employerName: value,
  }),
  disabled: true,
};

export const employmentStartDate: DateField<Employment> = {
  id: "employmentStartDate",
  label: "Employment Start Date",
  type: FIELD_TYPE.DATE,
  getValue: (employment: Employment) => employment.employmentStartDate,
  setValue: (value, employment) => ({
    ...employment,
    employmentStartDate: value,
  }),
  disabled: true,
};

export const jobTitle: TextFieldType<Employment> = {
  id: "jobTitle",
  label: "Job Title",
  type: FIELD_TYPE.TEXT,
  getValue: (employment: Employment) => employment.jobTitle,
  setValue: (value, employment) => ({
    ...employment,
    jobTitle: value,
  }),
  disabled: true,
};

export const monthlyIncome: TextFieldType<PersonalClient> = {
  id: `monthlyIncome`,
  label: "Monthly Income",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    client.financialProfile?.monthlyIncome?.toString(),
  setValue: (value, client) => ({
    ...client,
    financialProfile: {
      ...client.financialProfile,
      monthlyIncome:
        !!value && value !== "" ? ((value as unknown) as number) : undefined,
    },
  }),
  mask: MASK_TYPE.MONEY,
  isRequired: true,
  allowNegative: false,
};

export const residentialStatus: PickListField<PersonalClient> = {
  id: "residentialStatus",
  label: "Residential Status",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) =>
    client.financialProfile?.residentialStatus,
  setValue: (value, client) => ({
    ...client,
    financialProfile: {
      ...client.financialProfile,
      residentialStatus: value,
      // clearing out dependent fields when residential status changes
      // monthlyPropertyTax: value === "own" ? client.financialProfile?.monthlyPropertyTax as unknown as number : undefined,
      // monthlyHeatingCost: value === "own" ? client.financialProfile?.monthlyHeatingCost as unknown as number : undefined,
      monthlyPropertyTax: undefined,
      monthlyHeatingCost: undefined,
      monthlyCondoFee: undefined,
      // for now not clearing out the comment to avoid the scenario where
      // user have to retype everything in case they accidentally change the residential status
      //comment: ""
    },
  }),
  options: residentialStatusOptions,
  isRequired: true,
};

export const comment: TextFieldType<PersonalClient> = {
  id: "comment",
  label: "Comment",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.financialProfile?.comment,
  setValue: (value, client) => ({
    ...client,
    financialProfile: { ...client.financialProfile, comment: value },
  }),
  multiline: true,
};

export const totalAssets: TextFieldType<PersonalClient> = {
  id: `totalAssets`,
  label: "Total Assets",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    getCreditAppTotalAssets(client).toString(),
  setValue: (value, client) => ({
    ...client,
  }),
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
  disabled: true,
};

export const totalLiabilities: TextFieldType<PersonalClient> = {
  id: `totalLiabilities`,
  label: "Total Liabilities",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    getCreditAppTotalLiabilities(client).toString(),
  setValue: (value, client) => ({
    ...client,
  }),
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
  disabled: true,
};

export const netWorth: TextFieldType<PersonalClient> = {
  id: `netWorth`,
  label: "Net Worth",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    subtract(
      getCreditAppTotalAssets(client),
      getCreditAppTotalLiabilities(client)
    ).toString(),
  setValue: (value, client) => ({
    ...client,
  }),
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
  disabled: true,
};

export const monthlyMortgageRent: TextFieldType<PersonalClient> = {
  id: `monthlyMortgageRent`,
  label: "Monthly Mortgage / Rent",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    client.financialProfile?.monthlyMortgageRent?.toString(),
  setValue: (value, client) => ({
    ...client,
    financialProfile: {
      ...client.financialProfile,
      monthlyMortgageRent:
        !!value && value !== "" ? ((value as unknown) as number) : undefined,
    },
  }),
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
  checkIfRequired: (client: PersonalClient) =>
    ["rent", "own", "liveWithFamily"].includes(
      client.financialProfile?.residentialStatus ?? ""
    ),
};

export const monthlyPropertyTax: TextFieldType<PersonalClient> = {
  id: `monthlyPropertyTax`,
  label: "Monthly Property Tax",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    client.financialProfile?.monthlyPropertyTax?.toString(),
  setValue: (value, client) => ({
    ...client,
    financialProfile: {
      ...client.financialProfile,
      monthlyPropertyTax: (value as unknown) as number,
    },
  }),
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
  checkIfRequired: (client: PersonalClient) =>
    client.financialProfile?.residentialStatus === "own",
};

export const monthlyHeatingCost: TextFieldType<PersonalClient> = {
  id: `monthlyHeatingCost`,
  label: "Monthly Heating Cost",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    client.financialProfile?.monthlyHeatingCost?.toString(),
  setValue: (value, client) => ({
    ...client,
    financialProfile: {
      ...client.financialProfile,
      monthlyHeatingCost: (value as unknown) as number,
    },
  }),
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
  checkIfRequired: (client: PersonalClient) =>
    client.financialProfile?.residentialStatus === "own",
};

export const monthlyCondoFee: TextFieldType<PersonalClient> = {
  id: `monthlyCondoFee`,
  label: "Monthly Condo Fee",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    client.financialProfile?.monthlyCondoFee?.toString(),
  setValue: (value, client) => ({
    ...client,
    financialProfile: {
      ...client.financialProfile,
      monthlyCondoFee:
        !!value && value !== "" ? ((value as unknown) as number) : undefined,
    },
  }),
  mask: MASK_TYPE.MONEY,

  allowNegative: false,
};

export const monthlyConsumerDebt: TextFieldType<PersonalClient> = {
  id: `monthlyConsumerDebt`,
  label: "Monthly Consumer Debt",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    getMonthlyConsumerDebt(client).toString(),
  setValue: (value, client) => ({
    ...client,
    financialProfile: {
      ...client.financialProfile,
      monthlyConsumerDebt: (value as unknown) as number,
    },
  }),
  mask: MASK_TYPE.MONEY,
  allowNegative: false,
  disabled: true,
};

export const tds: TextFieldType<PersonalClient> = {
  id: `tds`,
  label: "TDS",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => getCreditAppTDS(client).toString(),
  setValue: (value, client) => ({
    ...client,
  }),
  mask: MASK_TYPE.PERCENTAGE,
  disabled: true,
};

export const gds: TextFieldType<PersonalClient> = {
  id: `gds`,
  label: "GDS",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => getCreditAppGDS(client).toString(),
  setValue: (value, client) => ({
    ...client,
  }),
  mask: MASK_TYPE.PERCENTAGE,
  disabled: true,
};

export const jointAppMonthlyIncome: TextFieldType<PersonalClient> = {
  id: `jointAppmonthlyIncome`,
  label: "Joint Applicant Monthly Income",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) =>
    getJointAppMonthlyIncome(client)?.toString(),
  setValue: (value, client) => ({
    ...client,
  }),
  mask: MASK_TYPE.MONEY,
  disabled: true,
  allowNegative: false,
};
