import FieldEditor from "components/FieldEditor";
import { GLTransaction, FieldError } from "types";
import * as fields from "pages/generalLedger/glTransactionFieldDefinitions";
import { useEffect, useState } from "react";
import { requiredPickListField, requiredTextField } from "common/functions";
import TransactionEditorTemplate from "pages/generalLedger/components/TransactionEditorTemplate";

export type Props = {
  transaction: GLTransaction;
  onCancel(): void;
  onSave(transaction: GLTransaction): void;
};

export default function CashTransferDialog({
  transaction,
  onCancel,
  onSave,
}: Props) {
  const [state, setState] = useState<GLTransaction>(transaction);
  const [errors, setErrors] = useState<FieldError[]>([]);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: GLTransaction) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!transaction) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredPickListField(fields.from, state),
      ...requiredPickListField(fields.to, state),
      ...requiredTextField(fields.amount, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  return (
    <TransactionEditorTemplate
      id={`GLTransaction_${transaction.transactionId}_form`}
      onSaveClick={() => {
        const err = checkErrors();
        if (err?.length === 0) {
          onSave(state);
        }
      }}
      onCancelClick={onCancel}
      type={"Cash Transfer"}
      column1Fields={
        <>
          <FieldEditor<GLTransaction> field={fields.from} {...commonProps} />
          <FieldEditor<GLTransaction> field={fields.to} {...commonProps} />
        </>
      }
      column2Fields={
        <>
          <FieldEditor<GLTransaction> field={fields.amount} {...commonProps} />
        </>
      }
    />
  );
}
