import { Paper } from "@mui/material";
import styled from "styled-components";
import {
  BillPayment,
  CreditCardTransaction,
  Deposit,
  GLEntry,
  IdName,
  PayoutCash,
  Receive,
  SellDraft,
  Transaction,
  Transfer,
  Withdrawal,
} from "types";
import SummaryRowBillPayment from "pages/sessionHistory/components/SummaryRowBillPayment";
import SummaryRowCreditCard from "pages/sessionHistory/components/SummaryRowCreditCard";
import SummaryRowDeposit from "pages/sessionHistory/components/SummaryRowDeposit";
import SummaryRowGLEntry from "pages/sessionHistory/components/SummaryRowGLEntry";
import SummaryRowPayoutCash from "pages/sessionHistory/components/SummaryRowPayoutCash";
import SummaryRowReceive from "pages/sessionHistory/components/SummaryRowReceive";
import SummaryRowSellDraft from "pages/sessionHistory/components/SummaryRowSellDraft";
import SummaryRowTransfer from "pages/sessionHistory/components/SummaryRowTransfer";
import SummaryRowWithdrawal from "pages/sessionHistory/components/SummaryRowWithdrawal";

export type SummaryProps = {
  transactions: Transaction[];
  depositWithdrawTransferOptions: IdName[];
  glEntryClientAccountOptions: IdName[];
  creditCardOptions: IdName[];
};

export default function SummaryDetails(props: SummaryProps) {
  //const [page, setPage] = useState<number>(0);
  //const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  let sortedTxns = props.transactions.sort(
    (a, b) =>
      new Date(b.transactionDateTime ?? 0).getTime() -
      new Date(a.transactionDateTime ?? 0).getTime()
  );

  return (
    <ColumnContent>
      <div>
        <Header />

        <div>
          {sortedTxns
            //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((t, index) => {
              return (
                <Row
                  transaction={t}
                  key={index}
                  depositWithdarwTransferOptions={
                    props.depositWithdrawTransferOptions
                  }
                  creditCardOptions={props.creditCardOptions}
                  glEntryClientAccountOptions={
                    props.glEntryClientAccountOptions
                  }
                />
              );
            })}
        </div>
      </div>

      {/* {!!sortedTxns && (
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={!!sortedTxns ? sortedTxns.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => {
            setPage(p);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
          }}
          sx={{ marginTop: "2em" }}
        />
      )} */}
    </ColumnContent>
  );
}

function Header() {
  return (
    <div>
      <SummaryHeader>
        <Column>Date</Column>
        <Column>Transaction</Column>
        <Column>Debit</Column>
        <Column>Credit</Column>
        <Column>Details</Column>
      </SummaryHeader>
    </div>
  );
}

function Row(props: {
  transaction: Transaction;
  depositWithdarwTransferOptions: IdName[];
  creditCardOptions: IdName[];
  glEntryClientAccountOptions: IdName[];
}) {
  if (props.transaction.transactionType === "RECEIVE") {
    return <SummaryRowReceive transaction={props.transaction as Receive} />;
  }
  if (props.transaction.transactionType === "DEPOSIT") {
    let depositTransaction: Deposit = props.transaction as Deposit;
    return (
      <SummaryRowDeposit
        transaction={depositTransaction}
        depositToOptions={props.depositWithdarwTransferOptions}
      />
    );
  }
  if (props.transaction.transactionType === "WITHDRAWAL") {
    let withdrawalTransaction: Withdrawal = props.transaction as Withdrawal;
    return (
      <SummaryRowWithdrawal
        transaction={withdrawalTransaction}
        withdrawFromOptions={props.depositWithdarwTransferOptions}
      />
    );
  }
  if (props.transaction.transactionType === "PAYOUT_CASH") {
    let payoutTransaction: PayoutCash = props.transaction as PayoutCash;
    return <SummaryRowPayoutCash transaction={payoutTransaction} />;
  }
  if (props.transaction.transactionType === "BILL_PAYMENT") {
    let billPaymentTransaction: BillPayment = props.transaction as BillPayment;
    return <SummaryRowBillPayment transaction={billPaymentTransaction} />;
  }

  if (props.transaction.transactionType === "CREDIT_CARD") {
    let creditCardTransaction: CreditCardTransaction = props.transaction as CreditCardTransaction;
    return (
      <SummaryRowCreditCard
        transaction={creditCardTransaction}
        creditCardOptions={props.creditCardOptions}
      />
    );
  }

  if (props.transaction.transactionType === "TRANSFER") {
    let transferTransaction: Transfer = props.transaction as Transfer;
    return (
      <SummaryRowTransfer
        transaction={transferTransaction}
        transferOptions={props.depositWithdarwTransferOptions}
      />
    );
  }
  if (props.transaction.transactionType === "SELL_DRAFT") {
    let sellDraftTransaction: SellDraft = props.transaction as SellDraft;
    return <SummaryRowSellDraft transaction={sellDraftTransaction} />;
  }

  if (props.transaction.transactionType === "GL_ENTRY") {
    let glEntryTransaction: GLEntry = props.transaction as GLEntry;
    return (
      <SummaryRowGLEntry
        transaction={glEntryTransaction}
        glEntryClientAccountOptions={props.glEntryClientAccountOptions}
      />
    );
  }

  return <></>;
}

const ColumnContent = styled(Paper)`
  min-height: 700px;
`;

const SummaryHeader = styled.div`
  display: flex;
  padding: 1em 0;
  font-weight: 500;
  padding-left: 18px;
  padding-right: 40px;
`;

const Column = styled.div`
  flex: 1;
  padding: 0 1em 0 1.75em;
`;
