import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";
import {
  checkIfUnauthorized,
  getPersonalClientName,
  identificationErrors,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import * as fields from "pages/clientPortfolio/personalClient/personalClientFieldDefinitions";
import styled from "styled-components";
import {
  AddressType,
  Employment,
  FieldError,
  GeneralLedger,
  PersonalClient,
} from "types";
import ClientPortfolioTemplate from "components/clientPortfolioTemplate/ClientPortfolioTemplate";
import AppBarButtons from "pages/clientPortfolio/personalClient/personalClientInformation/components/AppBarButtons";
import MainTemplate from "components/mainTemplate/MainTemplate";
import IdentificationSection from "pages/clientPortfolio/personalClient/personalClientInformation/components/IdentificationSection";
import InformationSection from "pages/clientPortfolio/personalClient/personalClientInformation/components/InformationSection";
import EmploymentSection from "pages/clientPortfolio/personalClient/personalClientInformation/components/EmploymentSection";
import EmploymentHistorySection from "pages/clientPortfolio/personalClient/personalClientInformation/components/EmploymentHistorySection";
import { defaultPersonalClient } from "common/helpers";
import { useLocation } from "react-router";
import { openSnackbar } from "components/snackbar";
import {
  getEmployerList,
  getNewPersonalClientId,
  getPersonalClientById,
  getGeneralLedger,
} from "pages/lessons/helpers";
import { useHistory } from "react-router-dom";
import {
  PersonalClientInformationRoute,
  UnauthorizedRoute,
} from "components/paths";
import { openConfirm } from "components/confirmBox";
import isEmail from "validator/lib/isEmail";
import ClientTypeTemplate from "components/clientTypeTemplate/ClientTypeTemplate";

export type Props = {
  clientId?: string;
  lessonId: string;
  studentId?: string;
};

export default function PersonalClientInformation(props: Props) {
  const {
    getLessonNameAndData,
    getDataforViewAsStudent,
    lessonId,
  } = useReduxState((e) => e.lesson);
  const lessonActions = useActions().lesson;
  const clientInfoState = useReduxState((e) => e.personalClientInformation);
  const clientInfoActions = useActions().personalClientInformation;
  const newClientID = getNewPersonalClientId(
    getLessonNameAndData.data?.lessonData
  );

  const [state, setState] = useState<PersonalClient>(
    getPersonalClientById(
      props.clientId,
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewClient, setIsNewClient] = useState<boolean>(false);

  const commonProps = {
    state: state,
    errors,
    setState: (state: PersonalClient) => {
      setState(state);
    },
  };
  const location = useLocation();
  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            if (props.clientId) {
              let client: PersonalClient = getPersonalClientById(
                props.clientId,
                payload.lessonData
              );

              if (!!client.identification?.clientId) setState(client);
              else history.push(UnauthorizedRoute);
            } else {
              setIsNewClient(true);
              setState({
                ...defaultPersonalClient,
                identification: {
                  ...defaultPersonalClient.identification,
                  clientId: newClientID?.toString(),
                },
                information: {
                  ...defaultPersonalClient.information,
                  accountType: "Personal",
                },
              });
            }
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getPersonalClientById(
              props.clientId,
              getLessonNameAndData.data.lessonData
            )
          );
          setIsLoading(false);
        } else {
          setIsNewClient(true);
          setState({
            ...defaultPersonalClient,
            identification: {
              ...defaultPersonalClient.identification,
              clientId: newClientID?.toString(),
            },
            information: {
              ...defaultPersonalClient.information,
              accountType: "Personal",
            },
          });
          setIsLoading(false);
        }
        setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            if (props.clientId) {
              setState(
                getPersonalClientById(props.clientId, payload.lessonData)
              );
            }
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getPersonalClientById(
              props.clientId,
              getDataforViewAsStudent.data.lessonData
            )
          );
          setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
          setIsLoading(false);
        }
      }
    }
  }, [location]);

  const clientName = getPersonalClientName(state);

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...identificationErrors(fields.primaryIdentification, state),
      ...identificationErrors(fields.secondaryIdentification, state),
      ...requiredTextField(fields.sin, state),
      ...requiredTextField(fields.accountType, state),
      ...requiredTextField(fields.legalLastName, state),
      ...requiredTextField(fields.legalFirstName, state),
      ...requiredPickListField(fields.preferredPronouns, state),
      ...requiredTextField(fields.dateOfBirth, state),
      ...requiredTextField(fields.phoneNumber, state),
      ...requiredPickListField(fields.phoneNumberType, state),
      ...requiredTextField(fields.email, state),
      ...requiredPickListField(fields.preferredContact, state),
    ];
    //following code checks for invalid phone number. Adds error if length is less than 10
    if (
      state.information?.phoneNumber &&
      state.information.phoneNumber.length < 10
    ) {
      let invalidPhoneNumberErr: FieldError = {
        id: `${fields.phoneNumber.id}`,
        label: `${fields.phoneNumber.label}`,
        error: "Invalid Phone Number.",
      };
      errorsArray.push(invalidPhoneNumberErr);
    }
    //following code checks for invalid SIN number. Adds error if length is less than 9
    if (state.identification?.sin && state.identification.sin.length < 9) {
      let invalidSinNumberErr: FieldError = {
        id: `${fields.sin.id}`,
        label: `${fields.sin.label}`,
        error: "Invalid SIN Number.",
      };
      errorsArray.push(invalidSinNumberErr);
    }
    //following code checks for invalid email. Adds error if email is not in correct format
    if (state.information?.email && !isEmail(state.information?.email)) {
      let invalidEmailErr: FieldError = {
        id: `${fields.email.id}`,
        label: `${fields.email.label}`,
        error: "Invalid Email.",
      };
      errorsArray.push(invalidEmailErr);
    }

    setErrors(errorsArray);
    return errorsArray;
  }

  function onSaveClick() {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      if (!state.information?.clientCurrentAddress) {
        openConfirm({
          title: "Error",
          description: `Client must have at least one address`,
          isAlert: true,
        });
      } else if (!state.employment || state.employment?.length === 0) {
        openConfirm({
          title: "Error",
          description: `Client must have at least one active employment record`,
          isAlert: true,
        });
      } else {
        clientInfoActions.savePersonalClient({
          client: state,
          lessonId,
          onComplete: (payload) => {
            if (!!payload.clientId) {
              openSnackbar({
                severity: "success",
                message: `Personal Client '${clientName}' saved successfully`,
              });
              lessonActions.savePersonalClient(state);
              setIsNewClient(false);
              history.push(
                `/${lessonId}${PersonalClientInformationRoute}/${payload.clientId}`
              );
            } else {
              openSnackbar({
                severity: "error",
                message: `Error saving personal client '${clientName}'. Please refresh the page and try again!`,
              });
            }
          },
        });
      }
    }
  }

  function onSaveInstructorFeedback(feedback?: string) {
    if (!!props.studentId) {
      clientInfoActions.saveInformationInstructorFeedback({
        studentId: props.studentId,
        lessonId,
        clientId: state.identification?.clientId ?? "",
        feedback: feedback,
        onComplete: (payload) => {
          if (payload.success) {
            setState({
              ...state,
              informationInstructorFeedback: feedback,
            });
            lessonActions.saveInformationInstFeedbackForStudentPersonalClient({
              clientId: state.identification?.clientId ?? "",
              feedback: feedback,
            });

            openSnackbar({
              severity: "success",
              message: `Feedback saved successfully`,
            });
          } else {
            openSnackbar({
              severity: "error",
              message: `Error saving feedback. Please refresh the page and try again!`,
            });
          }
        },
      });
    }
  }

  function onAddAddress(newAddress: AddressType) {
    const currentAdd = {
      ...state.information?.clientCurrentAddress,
      country: "Canada",
    };

    const isNewAddOlder =
      new Date(newAddress.startDate ?? 0).getTime() <
      new Date(currentAdd.startDate ?? 0).getTime();
    const addHistory = state.information?.clientAddressHistory ?? [];

    const newAddHistory = addHistory
      .concat(newAddress)
      .sort(
        (a, b) =>
          new Date(b.startDate ?? 0).getTime() -
          new Date(a.startDate ?? 0).getTime()
      );

    const updatedClient = {
      ...state,
      information: {
        ...state.information,
        clientAddressHistory: newAddHistory,
        clientCurrentAddress: isNewAddOlder ? currentAdd : newAddress,
        clientNewAddress: {},
      },
    };

    setState(updatedClient);
  }

  function onEditAddress(oldAddress: AddressType, updatedAddress: AddressType) {
    const newAddressHist = state.information?.clientAddressHistory
      ?.filter((e) => e !== oldAddress)
      .concat(updatedAddress)
      .sort(
        (a, b) =>
          new Date(b.startDate ?? 0).getTime() -
          new Date(a.startDate ?? 0).getTime()
      );
    const updatedClient = {
      ...state,
      information: {
        ...state.information,
        clientCurrentAddress: newAddressHist && newAddressHist[0],
        clientAddressHistory: newAddressHist,
      },
    };
    setState(updatedClient);
  }

  function onDeleteAddress(address: AddressType) {
    const newAddressHist = state.information?.clientAddressHistory?.filter(
      (e) => e !== address
    );
    const updatedClient = {
      ...state,
      information: {
        ...state.information,
        clientCurrentAddress: newAddressHist && newAddressHist[0],
        clientAddressHistory: newAddressHist,
      },
    };
    setState(updatedClient);
  }

  function employmentSorter(a: Employment, b: Employment) {
    return (
      new Date(b.employmentStartDate ?? 0).getTime() -
      new Date(a.employmentStartDate ?? 0).getTime()
    );
  }

  function onEditEmployment(employment: Employment) {
    const currentEmployment = state.employment
      ? state.employment.filter(
          (e) => e.employmentId !== employment.employmentId
        )
      : [];
    const updatedEmployment =
      employment.isCurrent === true
        ? currentEmployment.concat(employment).sort(employmentSorter)
        : currentEmployment.sort(employmentSorter);
    const hist = state.employmentHistory
      ? state.employmentHistory.filter(
          (e) => e.employmentId !== employment.employmentId
        )
      : [];
    const newHist =
      employment.isCurrent === true
        ? hist.sort(employmentSorter)
        : hist.concat(employment).sort(employmentSorter);
    setState({
      ...state,
      employment: updatedEmployment,
      employmentHistory: newHist,
    });
  }

  const isReadOnlyStudentAccess =
    getLessonNameAndData.data?.isReadOnly ?? false;

  return (
    <MainTemplate
      title={
        isLoading ? "Client Portfolio" : `Client Portfolio — ${clientName}`
      }
      isLoading={isLoading}
      studentId={props.studentId}
      appBarRight={
        <AppBarButtons
          onSaveClick={onSaveClick}
          isSaving={clientInfoState.savePersonalClient.isLoading}
          isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess} //need to disable save button if student view or read only
        />
      }
      errorsArray={errors}
      onErrorClick={(fieldID) => {
        document.getElementById(fieldID)?.focus();
      }}
      cashDrawerBalance={glState.cashDrawer?.balance}
    >
      <ClientPortfolioTemplate
        selectedButton={1}
        isNewClient={isNewClient}
        studentId={props.studentId}
        isLoading={isLoading}
        onSaveFeedback={onSaveInstructorFeedback}
      >
        {isNewClient && <ClientTypeTemplate selectedButton={0} />}
        {!isLoading && (
          <Content>
            <Column>
              <IdentificationSection {...commonProps} />
            </Column>
            <Column>
              <InformationSection
                {...commonProps}
                onAddAddress={(newAddress) => {
                  onAddAddress(newAddress);
                }}
                onEditAddress={(oldAddress, updatedAddress) => {
                  onEditAddress(oldAddress, updatedAddress);
                }}
                onDeleteAddress={(address) => {
                  onDeleteAddress(address);
                }}
                isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess} //need to disable actions if student view or read only
              />
            </Column>
            <Column>
              <EmploymentSection
                {...commonProps}
                employers={
                  !!props.studentId
                    ? getEmployerList(getDataforViewAsStudent.data?.lessonData)
                    : getEmployerList(getLessonNameAndData.data?.lessonData)
                }
                onEditEmployment={(updatedEmployment) => {
                  onEditEmployment(updatedEmployment);
                }}
                onDeleteEmployment={(employment) => {
                  const currentEmployment = state.employment ?? [];
                  setState({
                    ...state,
                    employment: currentEmployment.filter(
                      (e) => e.employmentId !== employment.employmentId
                    ),
                  });
                }}
                isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess} //need to disable actions if student view or read only
              />
            </Column>
            <Column>
              <EmploymentHistorySection
                {...commonProps}
                employers={
                  !!props.studentId
                    ? getEmployerList(getDataforViewAsStudent.data?.lessonData)
                    : getEmployerList(getLessonNameAndData.data?.lessonData)
                }
                onEditEmployment={(updatedEmployment) => {
                  onEditEmployment(updatedEmployment);
                }}
                onDeleteEmployment={(employment) => {
                  const currentEmploymentHist = state.employmentHistory ?? [];
                  setState({
                    ...state,
                    employmentHistory: currentEmploymentHist.filter(
                      (e) => e.employmentId !== employment.employmentId
                    ),
                  });
                }}
                isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess} //need to disable actions if student view or read only
              />
            </Column>
          </Content>
        )}
        {/* </ClientTypeTemplate> */}
      </ClientPortfolioTemplate>
    </MainTemplate>
  );
}

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Column = styled.div`
  width: 600px;
  margin: 0.5em;
`;
