import { Divider } from "@mui/material";
import MaskedValue from "common/MaskedValue";
import { Cash, CashItem, MASK_TYPE } from "types";
import styled from "styled-components";
import { getCashDetails, getMaskedMoneyValue } from "common/functions";
import SummaryRowTemplate from "pages/cashDrawer/components/SummaryRowTemplate";
import moment from "moment";

type SummaryRowReceiveCashProps = {
  clientName: string;
  cash: Cash;
  lctr?: boolean;
  utr?: boolean;
  transactionDateTime: string;
};

export default function SummaryRowReceiveCash(
  props: SummaryRowReceiveCashProps
) {
  const cashDetails = getCashDetails(props.cash).filter(
    (c) => !!c.quantity && c.quantity > 0
  );

  const totalAmount = getMaskedMoneyValue(props.cash.totalCashAmount ?? 0);

  const cadValue = getMaskedMoneyValue(props.cash.cashCADValue ?? 0);
  const currency = props.cash.cashCurrencyType ?? "";
  let details =
    props.utr && props.lctr
      ? "UTR, LCTR"
      : props.utr && !props.lctr
      ? "UTR"
      : !props.utr && props.lctr
      ? "LCTR"
      : "";
  return (
    <SummaryRowTemplate
      client={props.clientName}
      date={moment(props.transactionDateTime).format("MM/DD/YYYY").toString()}
      transaction="Receive Cash"
      cashIn={`${cadValue}`}
      details={details}
    >
      {cashDetails.map((c, index) => {
        return <CashItemRow cashItem={c} key={index} currency={currency} />;
      })}
      <Divider />
      <DetailsRow isBold={true}>
        <TextSection>Total</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${totalAmount} ${currency.toUpperCase()}`}
        </TextSection>
      </DetailsRow>
      {currency === "usd" && (
        <DetailsRow style={{ marginTop: "-0.5em" }}>
          <TextSection>
            {" "}
            <Spacer />
          </TextSection>
          <TextSection style={{ textAlign: "right" }}>
            {`${cadValue} CAD`}
          </TextSection>
        </DetailsRow>
      )}
    </SummaryRowTemplate>
  );
}

function CashItemRow(props: { cashItem: CashItem; currency: string }) {
  let total = props.cashItem.num * (props.cashItem.quantity ?? 0);
  return (
    <div>
      <Divider />
      <DetailsRow>
        <TextSection>
          {MaskedValue(MASK_TYPE.MONEY, props.cashItem.num.toString())}{" "}
          {props.currency.toUpperCase()}
        </TextSection>
        <TextSection> {props.cashItem.quantity}</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {MaskedValue(MASK_TYPE.MONEY, total.toString())}{" "}
          {props.currency.toUpperCase()}
        </TextSection>
      </DetailsRow>
    </div>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
