import { Grid } from "@mui/material";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessMutualFundsFieldDefinitions";
import { MutualFunds } from "types";
import AccountDisplayTemplate from "pages/clientPortfolio/businessClient/businessClientAccounts/components/AccountDisplayTemplate";
export type Props = {
  account: MutualFunds;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

function MutualFundsDisplay({
  account,
  onEditClick,
  onDeleteClick,
  isReadOnlyView,
}: Props) {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`MutualFunds_${account.accountId}_Display`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      accountType="Mutual Funds"
      accountNumber={account.accountNumber || ""}
      type={account.type}
      balance={account.balance || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldDisplay<MutualFunds>
            field={fields.mutualFundsOpenDate}
            {...commonProps}
          />
          <FieldDisplay<MutualFunds>
            field={fields.mutualFundsAccountNumber}
            {...commonProps}
          />

          <FieldDisplay<MutualFunds>
            field={fields.mutualFundsBalance}
            {...commonProps}
          />

          <FieldDisplay<MutualFunds>
            field={fields.mutualFundsType}
            {...commonProps}
          />

          <FieldDisplay<MutualFunds>
            field={fields.mutualFundsInvestmentCategory}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<MutualFunds>
            field={fields.mutualFundsCloseDate}
            {...commonProps}
          />
          <FieldDisplay<MutualFunds>
            field={fields.mutualFundsAutoDeposit}
            {...commonProps}
          />
          <FieldDisplay<MutualFunds>
            field={fields.mutualFundsPreAuthPaymentFrequency}
            {...commonProps}
          />
          <FieldDisplay<MutualFunds>
            field={fields.mutualFundsPreAuthPaymentAmount}
            {...commonProps}
          />
        </Grid>
      </Grid>
    </AccountDisplayTemplate>
  );
}

export default MutualFundsDisplay;
