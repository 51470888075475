import { FIELD_TYPE, TextFieldType, MASK_TYPE, PickListField, CreditAppCreditCard, BoolField } from "types";
import { v4 } from "uuid"

export const requestedAmount: TextFieldType<CreditAppCreditCard> = {
  id: `requestedAmount_${v4()}`,
  label: "Requested Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppCreditCard) => account.requestedAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    requestedAmount: value as unknown as number,
  }),
  mask: MASK_TYPE.MONEY,
 isRequired: true, 
  allowNegative: false,
};


export const type: PickListField<CreditAppCreditCard> = {
  id: `type_${v4()}`,
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: CreditAppCreditCard) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
  }),
  options: [
    { id: "cashback", name: "Cashback" },
    { id: "student", name: "Student" },
    { id: "travel", name: "Travel" },
    { id: "nofee", name: "No Fee" },
  ],
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 
};


// function getPercentValue(percent: number, requestedAmount: number){
//   const val = (percent * requestedAmount)/100
//   return val
// }

// 16Nov, 2022: Business would like students to calculate minimum payment for credit app
export const minimumPayment: TextFieldType<CreditAppCreditCard> = {
  id: `minimumPayment_${v4()}`,
  label: "Minimum Payment",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppCreditCard) => account.minimumPayment?.toString(),
  // {let minPaymentValue = getPercentValue(5, account.requestedAmount ?? 0);
  // if(minPaymentValue > 0)
  // return minPaymentValue.toString()
  // else 
  // return "0"
  // },
  setValue: (value, account) => ({
    ...account,
    minimumPayment: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,  
};

export const hasSecurity: BoolField<CreditAppCreditCard> = {
  id: `hasSecurity_${v4()}`,
  label: "Security",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (account: CreditAppCreditCard) => account.hasSecurity,
  setValue: (value, account) => ({
    ...account,
    hasSecurity: value,
    security: value ? account.security : undefined,
  }), 
};

export const security: TextFieldType<CreditAppCreditCard> = {
  id: `security_${v4()}`,
  label: "Security",
  type: FIELD_TYPE.TEXT,
  getValue: (account: CreditAppCreditCard) => account.security,
  setValue: (value, account) => ({
    ...account,
    security: value ,
  }),
 checkIfRequired: (account: CreditAppCreditCard) => account.hasSecurity ?? false,
};


