import { TextFieldType, Entity, CommonFieldProps } from "types";
import TextFieldNonFieldEditor from "./TextFieldNonFieldEditor";

export default function TextFieldEditor<T extends Entity>(
  props: CommonFieldProps<T> & {
    isDependentlyRequired?: boolean;
    isDependentlyDisabled?: boolean;
    customStyle?: React.CSSProperties;
  }
) {
  const { field, state, setState, errors } = props;
  const errObj = errors.find((e) => e.id === field.id);

  const textField = field as TextFieldType<T>;

  return (
    <TextFieldNonFieldEditor
      id={field.id}
      label={field.label}
      value={textField.getValue(state) || ""}
      onChange={(v) => setState(textField.setValue(v, state))}
      required={
        field.isRequired ||
        (field.checkIfRequired && field.checkIfRequired(state)) ||
        (props.isDependentlyRequired && props.isDependentlyRequired === true)
      }
      multiline={textField.multiline}
      inputType={textField.inputType}
      mask={textField.mask}
      disabled={
        field.disabled ||
        (field.checkIfDisabled && field.checkIfDisabled(state)) ||
        (props.isDependentlyDisabled && props.isDependentlyDisabled === true)
      }
      errObj={errObj}
      allowNegative={
        textField.allowNegative ||
        (textField.checkIfAllowNegative &&
          textField.checkIfAllowNegative(state))
      }
      maxLength={textField.maxLength}
      customStyle={props.customStyle}
    />
  );
}
