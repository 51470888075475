import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";
import {
  checkIfUnauthorized,
  getPersonalClientName,
  requiredPickListField,
  requiredTextField,
} from "common/functions";
import styled from "styled-components";
import {
  CreditAppAccount,
  CustomAsset,
  CustomLiability,
  FieldError,
  GeneralLedger,
  PersonalClient,
} from "types";
import ClientPortfolioTemplate from "components/clientPortfolioTemplate/ClientPortfolioTemplate";
import AppBarButtons from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/AppBarButtons";
import MainTemplate from "components/mainTemplate/MainTemplate";
import IdentificationSection from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/IdentificationSection";
import FinancialProfileSection from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/FinancialProfileSection";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/creditAppFinancialFieldDefinitions";
import { defaultPersonalClient } from "common/helpers";
import { useLocation } from "react-router";

import {
  getEmployerList,
  getNewPersonalClientId,
  getPersonalClientById,
  getGeneralLedger,
} from "pages/lessons/helpers";
import { useHistory } from "react-router-dom";
import {
  PersonalClientCreditAppRoute,
  UnauthorizedRoute,
} from "components/paths";
import ClientTypeTemplate from "components/clientTypeTemplate/ClientTypeTemplate";
import { openSnackbar } from "components/snackbar";
import CommentsSection from "./components/CommentsSection";
import AccountsSection from "./components/AccountsSection";

export type Props = {
  clientId: string;
  lessonId: string;
  studentId?: string;
};

export default function PersonalClientInformation(props: Props) {
  const {
    getLessonNameAndData,
    getDataforViewAsStudent,
    lessonId,
  } = useReduxState((e) => e.lesson);
  const lessonActions = useActions().lesson;
  const clientInfoState = useReduxState((e) => e.personalClientInformation);
  const clientInfoActions = useActions().personalClientInformation;
  const newClientID = getNewPersonalClientId(
    getLessonNameAndData.data?.lessonData
  );

  const [state, setState] = useState<PersonalClient>(
    getPersonalClientById(
      props.clientId,
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [glState, setGLState] = useState<GeneralLedger>(
    getGeneralLedger(
      props.studentId
        ? getDataforViewAsStudent.data?.lessonData
        : getLessonNameAndData.data?.lessonData
    )
  );
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewClient, setIsNewClient] = useState<boolean>(false);

  const commonProps = {
    state: state,
    errors,
    setState: (state: PersonalClient) => {
      setState(state);
    },
  };
  const location = useLocation();
  const history = useHistory();
  if (checkIfUnauthorized(getLessonNameAndData, props.studentId)) {
    history.push(UnauthorizedRoute);
  }

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  useEffect(() => {
    if (props.studentId === undefined) {
      setIsLoading(true);
      if (!getLessonNameAndData.data?.lessonData) {
        lessonActions.getLessonNameAndData({
          id: (props.lessonId as unknown) as number,
          onComplete: (payload) => {
            if (props.clientId) {
              let client: PersonalClient = getPersonalClientById(
                props.clientId,
                payload.lessonData
              );

              if (!!client.identification?.clientId) setState(client);
              else history.push(UnauthorizedRoute);
            } else {
              setIsNewClient(true);
              setState({
                ...defaultPersonalClient,
                identification: {
                  ...defaultPersonalClient.identification,
                  clientId: newClientID?.toString(),
                },
                information: {
                  ...defaultPersonalClient.information,
                  accountType: "Personal",
                },
              });
            }
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getPersonalClientById(
              props.clientId,
              getLessonNameAndData.data.lessonData
            )
          );
          setIsLoading(false);
        } else {
          setIsNewClient(true);
          setState({
            ...defaultPersonalClient,
            identification: {
              ...defaultPersonalClient.identification,
              clientId: newClientID?.toString(),
            },
            information: {
              ...defaultPersonalClient.information,
              accountType: "Personal",
            },
          });
          setIsLoading(false);
        }
        setGLState(getGeneralLedger(getLessonNameAndData.data.lessonData));
      }
    }
    if (props.studentId) {
      setIsLoading(true);
      if (!getDataforViewAsStudent.data?.lessonData) {
        lessonActions.getDataforViewAsStudent({
          id: (props.lessonId as unknown) as number,
          studentId: props.studentId,
          onComplete: (payload) => {
            if (props.clientId) {
              setState(
                getPersonalClientById(props.clientId, payload.lessonData)
              );
            }
            setGLState(getGeneralLedger(payload.lessonData));
            setIsLoading(false);
          },
        });
      } else {
        if (props.clientId) {
          setState(
            getPersonalClientById(
              props.clientId,
              getDataforViewAsStudent.data.lessonData
            )
          );
          setGLState(getGeneralLedger(getDataforViewAsStudent.data.lessonData));
          setIsLoading(false);
        }
      }
    }
  }, [location]);

  const clientName = getPersonalClientName(state);

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.monthlyMortgageRent, state),
      ...requiredTextField(fields.monthlyIncome, state),
      ...requiredTextField(fields.monthlyPropertyTax, state),
      ...requiredTextField(fields.monthlyHeatingCost, state),
      ...requiredTextField(fields.monthlyConsumerDebt, state),
      ...requiredPickListField(fields.residentialStatus, state),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  function onSaveClick() {
    const errArray = checkErrors();
    if (errArray.length === 0) {
      clientInfoActions.savePersonalClient({
        client: state,
        lessonId,
        onComplete: (payload) => {
          if (!!payload.clientId) {
            openSnackbar({
              severity: "success",
              message: `Personal Client '${clientName}' saved successfully`,
            });
            lessonActions.savePersonalClient(state);
            setIsNewClient(false);
            history.push(
              `/${lessonId}${PersonalClientCreditAppRoute}/${payload.clientId}`
            );
          } else {
            openSnackbar({
              severity: "error",
              message: `Error saving personal client '${clientName}'. Please refresh the page and try again!`,
            });
          }
        },
      });
    }
  }

  function onAddEditCutomAsset(updatedCustomAsset: CustomAsset) {
    let currentCustomAssets = state.financialProfile?.customAssets ?? [];
    let isExistingAsset = currentCustomAssets.some(
      (a) => a.id === updatedCustomAsset.id
    );

    let updatedCustomAssets = isExistingAsset
      ? currentCustomAssets.map((a) =>
          a.id === updatedCustomAsset.id ? updatedCustomAsset : a
        )
      : [...currentCustomAssets, updatedCustomAsset];

    let updatedPersonalClient: PersonalClient = {
      ...state,
      financialProfile: {
        ...state.financialProfile,
        customAssets: updatedCustomAssets,
      },
    };
    setState(updatedPersonalClient);
  }

  function onDeleteCustomAsset(customAsset: CustomAsset) {
    let currentCustomAssets = state.financialProfile?.customAssets ?? [];
    let updatedCustomAssets = currentCustomAssets.filter(
      (a) => a.id !== customAsset.id
    );
    let updatedPersonalClient: PersonalClient = {
      ...state,
      financialProfile: {
        ...state.financialProfile,
        customAssets: updatedCustomAssets,
      },
    };
    setState(updatedPersonalClient);
  }

  function onAddEditCutomLiability(updatedCustomLiability: CustomAsset) {
    let currentCustomLiabilities =
      state.financialProfile?.customLiabilities ?? [];
    let isExistingLiability = currentCustomLiabilities.some(
      (l) => l.id === updatedCustomLiability.id
    );

    let updatedCustomLiabilities = isExistingLiability
      ? currentCustomLiabilities.map((l) =>
          l.id === updatedCustomLiability.id ? updatedCustomLiability : l
        )
      : [...currentCustomLiabilities, updatedCustomLiability];

    let updatedPersonalClient: PersonalClient = {
      ...state,
      financialProfile: {
        ...state.financialProfile,
        customLiabilities: updatedCustomLiabilities,
      },
    };
    setState(updatedPersonalClient);
  }

  function onDeleteCustomLiability(customcustomLiability: CustomLiability) {
    let currentCustomcustomLiabilities =
      state.financialProfile?.customLiabilities ?? [];
    let updatedCustomcustomLiabilities = currentCustomcustomLiabilities.filter(
      (l) => l.id !== customcustomLiability.id
    );
    let updatedPersonalClient: PersonalClient = {
      ...state,
      financialProfile: {
        ...state.financialProfile,
        customLiabilities: updatedCustomcustomLiabilities,
      },
    };
    setState(updatedPersonalClient);
  }

  function onSaveAccount(account: CreditAppAccount) {
    const updatedClient: PersonalClient = {
      ...state,
      creditAppAccounts: [account],
    };
    setState(updatedClient);
  }

  function onDeleteAccount(account: CreditAppAccount) {
    //because we are displaying just one account for now, therefore setting the accounts array to empty array.
    //In future, if business needs the ability to display a list of account then this delete function
    //can be updated to delete the specific account from the list

    const updatedClient: PersonalClient = {
      ...state,
      creditAppAccounts: [],
    };
    setState(updatedClient);
  }

  function onSaveInstructorFeedback(feedback?: string) {
    if (!!props.studentId) {
      clientInfoActions.saveCreditAppInstructorFeedback({
        studentId: props.studentId,
        lessonId,
        clientId: state.identification?.clientId ?? "",
        feedback: feedback,
        onComplete: (payload) => {
          if (payload.success) {
            setState({
              ...state,
              creditAppInstructorFeedback: feedback,
            });
            lessonActions.saveCreditAppInstFeedbackForStudentPersonalClient({
              clientId: state.identification?.clientId ?? "",
              feedback: feedback,
            });
            openSnackbar({
              severity: "success",
              message: `Feedback saved successfully`,
            });
          } else {
            openSnackbar({
              severity: "error",
              message: `Error saving feedback. Please refresh the page and try again!`,
            });
          }
        },
      });
    }
  }

  const isReadOnlyStudentAccess =
    getLessonNameAndData.data?.isReadOnly ?? false;

  return (
    <MainTemplate
      title={
        isLoading ? "Client Portfolio" : `Client Portfolio — ${clientName}`
      }
      isLoading={isLoading}
      studentId={props.studentId}
      appBarRight={
        <AppBarButtons
          onSaveClick={onSaveClick}
          isSaving={clientInfoState.savePersonalClient.isLoading}
          isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
        />
      }
      errorsArray={errors}
      onErrorClick={(fieldID) => {
        document.getElementById(fieldID)?.focus();
      }}
      cashDrawerBalance={glState.cashDrawer?.balance}
    >
      <ClientPortfolioTemplate
        selectedButton={3}
        isNewClient={isNewClient}
        studentId={props.studentId}
        isLoading={isLoading}
        onSaveFeedback={onSaveInstructorFeedback}
      >
        {isNewClient && <ClientTypeTemplate selectedButton={0} />}
        {!isLoading && (
          <Content>
            <Column>
              <IdentificationSection {...commonProps} />
            </Column>
            <Column>
              <FinancialProfileSection
                {...commonProps}
                employers={
                  !!props.studentId
                    ? getEmployerList(getDataforViewAsStudent.data?.lessonData)
                    : getEmployerList(getLessonNameAndData.data?.lessonData)
                }
                onAddEditCustomAsset={(updatedCustomAsset) => {
                  onAddEditCutomAsset(updatedCustomAsset);
                }}
                onDeleteCustomAsset={(customAsset) => {
                  onDeleteCustomAsset(customAsset);
                }}
                onAddEditCustomLiability={(updatedCustomLiability) => {
                  onAddEditCutomLiability(updatedCustomLiability);
                }}
                onDeleteCustomLiability={(updatedCustomLiability) => {
                  onDeleteCustomLiability(updatedCustomLiability);
                }}
                isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
              />
            </Column>
            <Column>
              {/* <div>Details Section</div> */}
              <AccountsSection
                client={commonProps.state}
                isLoading={isLoading}
                isReadOnlyView={!!props.studentId || isReadOnlyStudentAccess}
                onSaveAccount={(account) => {
                  onSaveAccount(account);
                }}
                onDeleteAccount={(account) => {
                  onDeleteAccount(account);
                }}
              />
            </Column>
            <Column>
              {/* <div>Comments Section</div> */}
              <CommentsSection {...commonProps} />
            </Column>
          </Content>
        )}
      </ClientPortfolioTemplate>
    </MainTemplate>
  );
}

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Column = styled.div`
  width: 600px;
  margin: 0.5em;
`;
