import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useActions, useReduxState } from "store";
import { ConfigProps } from "./ConfirmBox.duck";

const dangerWords = ["delete", "remove", "cancel", "reset"];

let openConfirmMethod: (props: ConfigProps) => void;

const Confirm = () => {
  const confirmBox = useReduxState((e) => e.confirmBox);
  const actions = useActions().confirmBox;
  const { isOpen, config } = confirmBox;

  React.useEffect(() => {
    if (!openConfirmMethod) {
      openConfirmMethod = (props: ConfigProps) => {
        actions.openConfirm(props);
      };
    }
  }, []);

  if (!confirmBox || !isOpen) {
    return <div />;
  }
  // let isDanger = false;
  let okText = !config.okBtnText ? "OK" : config.okBtnText;
  let cancelText = !config.cancelBtnText ? "Cancel" : config.cancelBtnText;

  for (let i = 0; i < dangerWords.length; i++) {
    if (
      (config.title &&
        config.title.toLowerCase().indexOf(dangerWords[i]) !== -1) ||
      (config.description &&
        htmlStrip(config.description).toLowerCase().indexOf(dangerWords[i]) !==
          -1)
    ) {
      // isDanger = true;
      break;
    }
  }

  const okClick = () => {
    confirmBox.config.callback?.();
    actions.okConfirm();
  };
  const cancelClick = () => {
    confirmBox.config.cancelCallback?.();
    actions.cancelConfirm();
  };
  return (
    isOpen && (
      <Dialog
        open
        style={{ zIndex: 10000 }}
        maxWidth={config.maxWidth}
        fullWidth={config.fullWidth}
        PaperProps={
          config.overflowYVisible ? { style: { overflowY: "visible" } } : {}
        }
      >
        {config.title && <DialogTitle>{config.title}</DialogTitle>}
        <DialogContent style={{ paddingTop: 10, paddingBottom: 20 }}>
          <Typography>{config.description}</Typography>
        </DialogContent>
        <DialogActions>
          {!config.isAlert && (
            <Button
              variant="text"
              color="primary"
              //onClick={() => actions.cancelConfirm()}
              onClick={cancelClick}
            >
              {cancelText}
            </Button>
          )}
          {/* {isDanger ? (
            <div>
              <DangerButton variant="contained" onClick={okClick} autoFocus>
                {okText}
              </DangerButton>
            </div>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={okClick}
              autoFocus
            >
              {okText}
            </Button>
          )} */}
          <Button
            variant="contained"
            color="primary"
            onClick={okClick}
            autoFocus
          >
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

function htmlStrip(html: string | JSX.Element) {
  let div = document.createElement("div");
  div.innerHTML = html.toString();
  return div.textContent || div.innerText || "";
}

export const openConfirm = (props: ConfigProps) => {
  if (openConfirmMethod) {
    openConfirmMethod(props);
  }
};

// const DangerButton = styled(Button)`
//   color: white;
//   background-color: ${red[700]};
//   :hover {
//     background-color: ${red[900]};
//   }
// `;

export type Props = ConfigProps;

export default Confirm;
