import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { requiredPickListField, requiredTextField } from "common/functions";
import { useEffect, useState } from "react";
import { FieldError, SigningAuthority, PersonalClient } from "types";
import * as fields from "pages/clientPortfolio/businessClient/businessClientInformation/components/signingAuthorityFieldDefinitions";
import FieldEditor from "components/FieldEditor";
import { useReduxState } from "store";
import { getPersonalClientList } from "pages/lessons/helpers";
import PersonalClientSearch from "pages/clientPortfolio/businessClient/businessClientInformation/components/PersonalClientSearch";

export type Props = {
  signingAuthority: SigningAuthority;
  personalClient?: PersonalClient;
  onAdd(updatedSigningAuthority: SigningAuthority): void;
  onCancel(): void;
};

export default function SigningAuthorityEditor(props: Props) {
  const [state, setState] = useState<SigningAuthority>(props.signingAuthority);
  const [errors, setErrors] = useState<FieldError[]>([]);
  const [personalClient, setPersonalClient] = useState<PersonalClient>(
    props.personalClient ?? {}
  );

  const commonProps = {
    state: state,
    errors,
    setState: (state: SigningAuthority) => {
      setState(state);
    },
  };

  const personalClientProps = {
    state: personalClient,
    errors,
    setState: (personalClient: PersonalClient) => {
      setPersonalClient(personalClient);
    },
  };

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const [openPCDialog, setOpenPCDialog] = useState<boolean>(false);

  function checkErrors() {
    const errorsArray: FieldError[] = [
      ...requiredPickListField(fields.businessAffiliation, state),
      ...requiredTextField(fields.percentOfOwnership, state),
      ...requiredTextField(fields.name, personalClient),
      ...requiredTextField(fields.phoneNumber, personalClient),
      ...requiredTextField(fields.phoneNumberType, personalClient),
    ];
    setErrors(errorsArray);
    return errorsArray;
  }

  function attachPersonalClient(personalClient: PersonalClient) {
    setPersonalClient(personalClient);
    setState({
      ...state,
      personalClientId: personalClient.identification?.clientId,
    });
  }

  return (
    <>
      <Dialog maxWidth={"md"} fullWidth open>
        <DialogTitle>
          {!!props.signingAuthority.businessAffiliation
            ? "Edit Signing Authority"
            : "Add Signing Authority"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<SigningAuthority>
                field={fields.businessAffiliation}
                {...commonProps}
              />
              <FieldEditor<SigningAuthority>
                field={fields.percentOfOwnership}
                {...commonProps}
              />
              <FieldEditor<PersonalClient>
                field={fields.name}
                {...personalClientProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                style={{ margin: "1.25em 0 0.7em 0" }}
                onClick={() => {
                  setOpenPCDialog(true);
                }}
              >
                Attach Client
              </Button>
              {/* <TwoFieldsRow>
                <Column1MarginRight>
                  <FieldEditor<PersonalClient>
                    field={fields.phoneNumber}
                    {...personalClientProps}
                  />
                </Column1MarginRight>
                <Column2>
                  <FieldEditor<PersonalClient>
                    field={fields.phoneNumberType}
                    {...personalClientProps}
                  />
                </Column2>
              </TwoFieldsRow> */}
              <FieldEditor<PersonalClient>
                field={fields.phoneNumber}
                {...personalClientProps}
              />
              <FieldEditor<PersonalClient>
                field={fields.phoneNumberType}
                {...personalClientProps}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              const errors = checkErrors();
              if (errors.length === 0) {
                props.onAdd(state);
              }
            }}
          >
            {!!props.signingAuthority.businessAffiliation ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <AttachPersonalClientDialog
        open={openPCDialog}
        onClose={() => {
          setOpenPCDialog(false);
        }}
        onSelected={(e) => {
          attachPersonalClient(e);
          setOpenPCDialog(false);
        }}
      />
    </>
  );
}

const AttachPersonalClientDialog = (props: {
  open: boolean;
  onClose?(): void;
  onSelected(e: PersonalClient): void;
}) => {
  const { getLessonNameAndData } = useReduxState((e) => e.lesson);

  if (!props.open) return <></>;

  return (
    <Dialog maxWidth={"md"} open onClose={props.onClose}>
      <div style={{ padding: "1em" }}>
        <PersonalClientSearch
          personalClients={getPersonalClientList(
            getLessonNameAndData.data?.lessonData
          )}
          onPersonalClientSelected={props.onSelected}
        />
      </div>
    </Dialog>
  );
};
