import { getPersonalClientName, getSigningAuthorityBusinessAffiliationOptions } from "common/functions";
import { FIELD_TYPE, MASK_TYPE, PersonalClient, PickListField, SigningAuthority, TextFieldType,} from "types";

export const businessAffiliation: PickListField<SigningAuthority> = {
  id: "businessAffiliation",
  label: "Business Affiliation",
  type: FIELD_TYPE.PICKLIST,
  getValue: (sAuth: SigningAuthority) => sAuth.businessAffiliation,
  setValue: (value, sAuth) => ({
    ...sAuth,
    businessAffiliation: value,
  }),
  options: getSigningAuthorityBusinessAffiliationOptions(),
  getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  isRequired: true,  
};

export const percentOfOwnership: TextFieldType<SigningAuthority> = {
  id: "percentOfOwnership",
  label: "Percent Of Ownership",
  type: FIELD_TYPE.TEXT,
  getValue: (sAuth: SigningAuthority) => sAuth.percentOfOwnership ?sAuth.percentOfOwnership.toString() : "",
  setValue: (value, sAuth) => ({
    ...sAuth,
    percentOfOwnership: value as unknown as number,
  }),
  mask: MASK_TYPE.PERCENTAGE,
  allowNegative: false,
  isRequired: true, 
};

export const name: TextFieldType<PersonalClient> = {
  id: "name",
  label: "Name",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => getPersonalClientName(client) !== "New Client" ? getPersonalClientName(client) : "",
  setValue: (value, client) => ({
    ...client,
    //this field will always be disabled and just being used to display value, so we don't set any value and just return client
  }),
  isRequired: true,
  disabled: true,  
};



export const phoneNumber: TextFieldType<PersonalClient> = {
  id: "phoneNumber",
  label: "Phone Number",
  type: FIELD_TYPE.TEXT,
  getValue: (client: PersonalClient) => client.information?.phoneNumber,
  setValue: (value, client) => ({
    ...client,
    //this field will always be disabled and just being used to display value, so we don't set any value and just return client
  }),
  mask: MASK_TYPE.PHONE_NUMBER,
  isRequired: true,
  disabled: true, 
};


export const phoneNumberType: PickListField<PersonalClient> = {
  id: "phoneNumberType",
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (client: PersonalClient) => client.information?.phoneNumberType,
  setValue: (value, client) => ({
    ...client
    //this field will always be disabled and just being used to display value, so we don't set any value and just return client
  }),
  options: [
    { id: "home", name: "Home" },
    { id: "mobile", name: "Mobile" },
    { id: "work", name: "Work" },
  ],
  getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
  isRequired: true,
  disabled: true,  
};