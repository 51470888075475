import MainTemplate from "components/mainTemplate/MainTemplate";
import styled from "styled-components";
import { ReactNode } from "react";
import { GeneralLedger } from "types";
import GLInfoSection from "pages/generalLedger/components/GLInfoSection";

export type Props = {
  generalLedger: GeneralLedger;
  children?: ReactNode;
  title: string;
  isLoading: boolean;
  studentId?: string;
};

export default function GLInfoTemplate(props: Props) {
  return (
    <MainTemplate
      title={props.title}
      isLoading={props.isLoading}
      studentId={props.studentId}
      cashDrawerBalance={props.generalLedger.cashDrawer?.balance}
    >
      <div>
        <Content>
          <GLInfoColumn>
            <GLInfoSection generalLedger={props.generalLedger} />
          </GLInfoColumn>
          {props.children}
        </Content>
      </div>
    </MainTemplate>
  );
}

const Content = styled.div`
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const GLInfoColumn = styled.div`
  min-height: 600px;
  width: 250px;
  margin: 0.5em;
`;
