import styled from "styled-components";
import { CustomLiability } from "types";
import CustomLiabilityDisplay from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/CustomLiabilityDisplay";

type Props = {
  customLiabilities: CustomLiability[];
  onDeleteCustomLiability(customLiability: CustomLiability): void;
  onEditCustomLiability(customLiability: CustomLiability): void;
  isReadOnlyView: boolean;
};

export default function CustomLiabilitiesList({
  customLiabilities,
  onDeleteCustomLiability,
  onEditCustomLiability,
  isReadOnlyView,
}: Props) {
  return (
    <>
      {(!customLiabilities ||
        (customLiabilities && customLiabilities?.length === 0)) && (
        <Message>
          No Liabilities available. Click <b>Add</b> to Add a Liability
        </Message>
      )}

      {customLiabilities && customLiabilities.length > 0 && (
        <div style={{ marginTop: "1.5em" }}>
          {customLiabilities.map((cl, index) => {
            return (
              <div
                style={{ marginTop: "0.25em", padding: "0 1em" }}
                key={index}
              >
                <CustomLiabilityDisplay
                  key={index}
                  customLiability={cl}
                  onEditClick={() => {
                    onEditCustomLiability(cl);
                  }}
                  onDeleteClick={() => {
                    onDeleteCustomLiability(cl);
                  }}
                  isReadOnlyView={isReadOnlyView}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

const Message = styled.div`
  margin: 3em auto 1em auto;
`;
