import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import styled from "styled-components";
import PortfolioAImage from "./images/PortfolioA.png";
import PortfolioBImage from "./images/PortfolioB.png";
import PortfolioCImage from "./images/PortfolioC.png";
import PortfolioDImage from "./images/PortfolioD.png";

export default function PortfolioExamplesChart() {
  return (
    <CustomTooltip
      placement="top"
      title={
        <>
          <Row>
            <div>
              <img
                src={PortfolioAImage}
                alt="Graph showing Sample Portfolio A"
                width="343px"
                height="299px"
              />
              <p>
                Reference:
                https://mfda.ca/wp-content/uploads/2022/08/PortfolioA.png
              </p>
            </div>
            <div>
              <img
                src={PortfolioBImage}
                alt="Graph showing Sample Portfolio B"
                width="343px"
                height="299px"
              />
              <p>
                Reference:
                https://mfda.ca/wp-content/uploads/2022/08/PortfolioB.png
              </p>
            </div>
          </Row>
          <Row>
            <div>
              <img
                src={PortfolioCImage}
                alt="Graph showing Sample Portfolio C"
                width="343px"
                height="299px"
              />
              <p>
                Reference:
                https://mfda.ca/wp-content/uploads/2022/08/PortfolioC.png
              </p>
            </div>
            <div>
              <img
                src={PortfolioDImage}
                alt="Graph showing Sample Portfolio D"
                width="343px"
                height="299px"
              />
              <p>
                Reference:
                https://mfda.ca/wp-content/uploads/2022/08/PortfolioD.png
              </p>
            </div>
          </Row>
        </>
      }
    >
      <InfoIcon sx={{ color: "#1976d2" }} />
    </CustomTooltip>
  );
}

const Row = styled.div`
  display: flex;
  width: 750px;
  justify-content: space-between;
  margin: 20px;
`;

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    maxWidth: 775,
    color: "black",
  },
}));
