import { Divider, IconButton } from "@mui/material";
import { IdName, GLEntry } from "types";
import SummaryRowTemplate from "./SummaryRowTemplate";
import { openConfirm } from "components/confirmBox";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";

import * as fields from "pages/transactions/glEntryFieldDefinitions";
import { getMaskedMoneyValue } from "common/functions";
import { useReduxState } from "store";

type SummaryRowGLEntryProps = {
  transaction: GLEntry;
  onDeleteClick(): void;
  glEntryClientAccountOptions: IdName[];
  isTxnForAccWithOverLimitBal: boolean;
  isReadOnlyView: boolean;
};

export default function SummaryRowGLEntry(props: SummaryRowGLEntryProps) {
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
  const fromLabel =
    props.transaction.glType === "debit"
      ? `GL Account - ${
          fields.glAccount.getOptionLabel
            ? fields.glAccount.getOptionLabel(
                props.transaction.glAccount ?? "",
                fields.glAccount.options ?? []
              )
            : ""
        }`
      : fields.clientAccount.getOptionLabel
      ? fields.clientAccount.getOptionLabel(
          props.transaction.clientAccount ?? "",
          props.glEntryClientAccountOptions
        )
      : "";
  const toLabel =
    props.transaction.glType === "debit"
      ? fields.clientAccount.getOptionLabel
        ? fields.clientAccount.getOptionLabel(
            props.transaction.clientAccount ?? "",
            props.glEntryClientAccountOptions
          )
        : ""
      : `GL Account - ${
          fields.glAccount.getOptionLabel
            ? fields.glAccount.getOptionLabel(
                props.transaction.glAccount ?? "",
                fields.glAccount.options ?? []
              )
            : ""
        }`;

  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  let isTransactionRequireOverride: boolean =
    !!props.transaction.amount &&
    props.transaction.amount >=
      (globalSettings.generalLedgerOverrideDenomination ?? 0) &&
    props.transaction.isApprovalPending
      ? true
      : false;

  let isTransactionRejected =
    !!props.transaction.amount &&
    props.transaction.amount >=
      (globalSettings.generalLedgerOverrideDenomination ?? 0) &&
    props.transaction.isApprovalPending === false &&
    props.transaction.isApproved === false
      ? true
      : false;
  return (
    <SummaryRowTemplate
      transaction="GL Entry"
      debit={
        props.transaction.glType === "debit" &&
        props.transaction.clientAccount === "sessionbalance"
          ? transactionAmount
          : ""
      }
      credit={
        props.transaction.glType === "credit" &&
        props.transaction.clientAccount === "sessionbalance"
          ? transactionAmount
          : ""
      }
      details={
        props.transaction.clientAccount !== "sessionbalance"
          ? transactionAmount
          : ""
      }
      isTransactionRequireOverride={isTransactionRequireOverride}
      isTransactionRejected={isTransactionRejected}
      isTxnForAccWithOverLimitBal={props.isTxnForAccWithOverLimitBal}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection style={{ textAlign: "center" }}>From</TextSection>
        <TextSection style={{ textAlign: "center" }}> To</TextSection>
        <TextSection style={{ textAlign: "center" }}>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>{fromLabel}</TextSection>
        <TextSection>{toLabel}</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${transactionAmount} CAD`}
        </TextSection>
      </DetailsRow>
      <DetailsRow
        isBold={true}
        style={{
          alignItems: "normal",
          marginTop: "1em",
        }}
      >
        <TextSection>Reason</TextSection>
        <div style={{ minWidth: 120 }}>
          <TextSection>{props.transaction.reason}</TextSection>
          <TextSection
            style={{
              fontWeight: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.transaction.comment}
          </TextSection>
        </div>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
      </DetailsRow>
      <DetailsRow isBold={true}>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          <IconButton
            style={{ margin: "-1em 0" }}
            aria-label="delete"
            onClick={() => {
              openConfirm({
                title: "Delete Transaction?",
                description: `GL Entry ${transactionAmount} from ${fromLabel} to ${toLabel}`,
                callback: () => {
                  props.onDeleteClick();
                },
              });
            }}
            disabled={props.isReadOnlyView}
          >
            <DeleteIcon />
          </IconButton>
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
