import { Divider, Typography } from "@mui/material";
import styled from "styled-components";
import LiabilitiesDisplay from "pages/clientPortfolio/businessClient/businessClientSummary/components/LiabilitiesDisplay";
import { Account, MASK_TYPE } from "types";
import MaskedValue from "common/MaskedValue";

export default function LiabilitiesSection(props: {
  liabilitiesAccounts: Account[];
  liabilitiesTotal: number;
}) {
  return (
    <div>
      <SectionHeading>
        <Typography variant={"h6"}>Liabilities</Typography>
      </SectionHeading>

      {props.liabilitiesAccounts.length === 0 && <>No Liability Accounts</>}
      {props.liabilitiesAccounts.length > 0 && (
        <>
          <LiabilitiesDisplay accounts={props.liabilitiesAccounts} />
          <Divider style={{ margin: "0.25em 0em 1.25em 0em" }} />
          <TotalBalanceRow>
            <LeftContent>Liabilities Total: </LeftContent>{" "}
            <RightContent>
              {MaskedValue(MASK_TYPE.MONEY, props.liabilitiesTotal.toString())}
            </RightContent>
          </TotalBalanceRow>
        </>
      )}
    </div>
  );
}
const SectionHeading = styled.div`
  padding-bottom: 1em;
`;

const LeftContent = styled.div`
  font-weight: 500;
  width: 300px;
  flex: 1;
`;
const TotalBalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0em;
`;

const RightContent = styled.div`
  text-align: right;
  font-weight: 500;
`;
