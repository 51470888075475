import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { addressErrors } from "common/functions";

import { useEffect, useState } from "react";
import { AddressType, FieldError } from "types";
import * as fields from "pages/clientPortfolio/businessClient/businessClientInformation/components/manageAddressFieldDefinitions";
import FieldEditor from "components/FieldEditor";

export type Props = {
  addressHistoryItem: AddressType;
  onEditAddressHistoryItem(
    oldAddress: AddressType,
    updatedAddress: AddressType
  ): void;
  onCancel(): void;
};

const EditAddressHistoryItem = (props: Props) => {
  const [state, setState] = useState<AddressType>(props.addressHistoryItem);
  const [errors, setErrors] = useState<FieldError[]>([]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: AddressType) => {
      setState(state);
    },
  };

  function checkErrors() {
    const errorsArray: FieldError[] = [...addressErrors(fields.address, state)];
    //Invalid postal code fix: following code checks for invalid postal code. Adds error if length is less than 7
    if (state.postalCode && state.postalCode?.length < 7) {
      let invalidPostalCodeErr: FieldError = {
        id: `${fields.address.id}_postalCode`,
        label: "Postal Code",
        error: "Invalid Postal Code.",
      };
      errorsArray.push(invalidPostalCodeErr);
    }
    setErrors(errorsArray);
    return errorsArray;
  }
  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  return (
    <Dialog maxWidth={"md"} fullWidth open>
      <DialogTitle>Edit Address</DialogTitle>
      <DialogContent>
        <FieldEditor<AddressType> field={fields.address} {...commonProps} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            const errors = checkErrors();
            if (errors.length === 0) {
              props.onEditAddressHistoryItem(props.addressHistoryItem, {
                ...state,
                country: "Canada",
              });
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAddressHistoryItem;
