import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { ReactNode } from "react";
import { RejectFillIcon, WarningIcon } from "common/Icons";

type SummaryRowRTemplateProps = {
  transaction?: JSX.Element | string;
  debit?: JSX.Element | string;
  credit?: JSX.Element | string;
  details?: JSX.Element | string;
  isTransactionRequireOverride?: boolean;
  isTransactionRejected?: boolean;
  isTxnForAccWithOverLimitBal?: boolean;
  children?: ReactNode;
};

export default function SummaryRowTemplate(props: SummaryRowRTemplateProps) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={`${props.transaction}_Header`}
      >
        <Summary>
          {(props.isTransactionRequireOverride ||
            props.isTxnForAccWithOverLimitBal) && <WarningIcon />}
          {!props.isTransactionRequireOverride &&
            props.isTransactionRejected && <RejectFillIcon />}
          <Column
            isIconPresent={
              props.isTransactionRequireOverride || props.isTransactionRejected
            }
          >
            {props.transaction}
          </Column>
          <Column>{props.debit}</Column>
          <Column>{props.credit}</Column>
          <Column>{props.details}</Column>
        </Summary>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}
const Column = styled.div<{ isIconPresent?: boolean }>`
  flex: 1;
  padding: ${(p) =>
    p.isIconPresent && p.isIconPresent === true
      ? "0 1em 0 0.75em "
      : "0 1em 0 1.75em"};
`;
const Summary = styled.div`
  display: flex;
  width: 100%;
`;
