import { Receive } from "types";
import SummaryRowReceiveCash from "pages/transactions/components/SummaryRowReceiveCash";
import SummaryRowReceiveCheque from "pages/transactions/components/SummaryRowReceiveCheque";

type SummaryRowReceiveProps = {
  transaction: Receive;
  onDelete(updatedTransaction: Receive, deleteAmount: number): void;
  isReadOnlyView: boolean;
};

export default function SummaryRowReceive(props: SummaryRowReceiveProps) {
  function handleCashDelete() {
    let deleteAmount = props.transaction.cash?.cashCADValue ?? 0;
    let updatedTransaction: Receive = {
      ...props.transaction,
      cash: undefined,
      amount: props.transaction.cheque?.chequeCADValue,
    };
    props.onDelete(updatedTransaction, deleteAmount);
  }

  function handleChequeDelete() {
    let deleteAmount = props.transaction.cheque?.chequeCADValue ?? 0;
    let updatedTransaction: Receive = {
      ...props.transaction,
      cheque: undefined,
      amount: props.transaction.cash?.cashCADValue,
    };
    props.onDelete(updatedTransaction, deleteAmount);
  }
  return (
    <>
      {!!props.transaction.cash?.cashCADValue &&
        props.transaction.cash?.cashCADValue > 0 && (
          <SummaryRowReceiveCash
            cash={props.transaction.cash ?? {}}
            lctr={props.transaction.lctr}
            utr={props.transaction.utr}
            onDeleteClick={handleCashDelete}
            isReadOnlyView={props.isReadOnlyView}
          />
        )}
      {!!props.transaction.cheque?.chequeCADValue &&
        props.transaction.cheque?.chequeCADValue > 0 && (
          <SummaryRowReceiveCheque
            cheque={props.transaction.cheque ?? {}}
            onDeleteClick={handleChequeDelete}
            isReadOnlyView={props.isReadOnlyView}
          />
        )}
    </>
  );
}
