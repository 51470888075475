import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";

type CashTransferMenuProps = {
  onCashTransfer(from: string, to: string): void;
  isReadOnlyView: boolean;
};

export default function CashTransferMenu(props: CashTransferMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuContainer>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        disabled={props.isReadOnlyView}
      >
        Cash Transfer
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            props.onCashTransfer("treasury", "cashdrawer");
            handleClose();
          }}
        >
          Treasury To Cash Drawer
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onCashTransfer("cashdrawer", "treasury");
            handleClose();
          }}
        >
          Cash Drawer To Treasury
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onCashTransfer("csr_outage", "cashdrawer");
            handleClose();
          }}
        >
          CSR Outage To Cash Drawer
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onCashTransfer("cashdrawer", "csr_outage");
            handleClose();
          }}
        >
          Cash Drawer To CSR Outage
        </MenuItem>
      </Menu>
    </MenuContainer>
  );
}

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
`;
