import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Employer, MASK_TYPE } from "types";
import styled from "styled-components";
import { EmployerSearchListProps } from "./EmployerSearchList";
import { useEffect, useState } from "react";
import AddressDisplayer from "components/fields/AddressDisplay";
import MaskedValue from "common/MaskedValue";

const EmployerList = (props: EmployerSearchListProps) => {
  //setting page to 0(1st page) if the filtered list is changed
  //to have correct filtering user experience
  useEffect(() => {
    setPage(0);
  }, [props.employers]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  if (!props.employers || props.employers.length === 0) {
    return <Content>There are no employers.</Content>;
  }

  return (
    <div>
      <List>
        {props.employers
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((e, index) => {
            return (
              <ListItem key={index} style={{ alignItems: "center" }}>
                <ListItemText primary={<PrimaryText employer={e} />} />
                <Button
                  variant="contained"
                  style={{ margin: "0 0 0 1em" }}
                  onClick={() => {
                    props.onEmployerSelected && props.onEmployerSelected(e);
                  }}
                >
                  Select
                </Button>
                {/* )} */}
              </ListItem>
            );
          })}
      </List>
      <TablePagination
        sx={{ zIndex: 200000 }}
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={props.employers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => {
          setPage(p);
        }}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(+e.target.value);
        }}
      />
    </div>
  );
};

const PrimaryText = (props: { employer: Employer }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        style={{ marginRight: "0.5em" }}
        id={props.employer.employerId + " - header"}
      >
        <RowSection>
          <PrimaryTextSection width={"small"}>
            {props.employer.employerId}{" "}
          </PrimaryTextSection>
          <PrimaryTextSection width={"wide"}>
            {props.employer.employerName}
          </PrimaryTextSection>
          <PrimaryTextSection width={"med"}>
            {MaskedValue(
              MASK_TYPE.PHONE_NUMBER,
              props.employer.phoneNumber ?? ""
            )}
          </PrimaryTextSection>
        </RowSection>
      </AccordionSummary>
      <AccordionDetails>
        <AddressDisplayer
          address={props.employer.employerAddress ?? {}}
          displayAddressLabel={true}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default EmployerList;

const PrimaryTextSection = styled.div<{ width: "small" | "med" | "wide" }>`
  width: ${(p) =>
    p.width === "med" ? "125px" : p.width === "wide" ? "160px" : "100px"};
  padding: 0px 10px;
  word-wrap: break-word;
`;

const Content = styled.div`
  margin-left: 1.5em;
`;

const RowSection = styled.div`
  display: flex;
  align-items: center;
`;
