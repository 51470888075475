import MaskedValue from "common/MaskedValue";
import moment from "moment";
import { MASK_TYPE, Withdrawal } from "types";
import AccountHistoryRowTemplate from "pages/transactions/components/AccountHistoryRowTemplate";

type AccountHostoryRowWithdrawalProps = {
  transaction: Withdrawal;
  balance: number;
};

export default function AccountHistoryRowWithdrawal(
  props: AccountHostoryRowWithdrawalProps
) {
  return (
    <AccountHistoryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      debit={MaskedValue(
        MASK_TYPE.MONEY,
        props.transaction.amount?.toString() ?? ""
      )}
      balance={MaskedValue(MASK_TYPE.MONEY, props.balance.toString() ?? "")}
    />
  );
}
