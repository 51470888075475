import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { add, subtract } from "common/functions";
import { assetsAccountsTypes } from "common/helpers";
import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";
import { useState } from "react";
import styled from "styled-components";
import {
  Account,
  CreditCardTransaction,
  GLEntry,
  IdName,
  Transaction,
  TransactionSession,
  Transfer,
} from "types";
import AccountHistoryRowDeposit from "pages/transactions/components/AccountHistoryRowDeposit";
import AccountHistoryRowWithdrawal from "pages/transactions/components/AccountHistoryRowWithdrawal";

type AccountHistoryProps = {
  activeAccounts: Account[];
  trasnactionSessions: TransactionSession[];
  accountOptions: IdName[];
  onDetailedHistoryClick(selectedAccountId: string): void;
};

export default function AccountHistory(props: AccountHistoryProps) {
  const [accountOption, setAccountOption] = useState<string>("");
  const selectedAccount = props.activeAccounts.find(
    (a) => a.accountId === accountOption
  );

  const transactionIds: string[] = selectedAccount?.transactionIds ?? [];

  let transactions: Transaction[] = [];

  for (let i = 0; i < props.trasnactionSessions.length; i++) {
    let tempTxns =
      props.trasnactionSessions[i].transactions?.filter((t) =>
        transactionIds.includes(t.transactionId)
      ) ?? [];

    transactions = [...transactions, ...tempTxns];
  }

  let sortedTxns = transactions.sort(
    (a, b) =>
      new Date(b.transactionDateTime ?? 0).getTime() -
      new Date(a.transactionDateTime ?? 0).getTime()
  );
  let balance = selectedAccount?.balance;
  let txnAmount = 0;

  if (props.activeAccounts === undefined || props.activeAccounts.length < 1)
    return (
      <ColumnContent>
        <FlexCenter>There are no Active Accounts</FlexCenter>
      </ColumnContent>
    );
  return (
    <ColumnContent>
      <Container>
        <PickListNonFieldEditor
          id="activeaccounts"
          label="Account"
          value={accountOption}
          onChange={(v) => {
            setAccountOption(v);
          }}
          options={props.accountOptions}
        />
      </Container>
      {accountOption === "" && (
        <FlexCenter>Select Account to see the account history</FlexCenter>
      )}
      {!!accountOption &&
        (sortedTxns === undefined || sortedTxns.length === 0) && (
          <FlexCenter>No Transactions yet</FlexCenter>
        )}
      {!!accountOption && sortedTxns.length > 0 && (
        <>
          <Table>
            <Header />
            <TableBody>
              <>
                {sortedTxns.map((t, index) => {
                  balance =
                    index === 0
                      ? balance
                      : assetsAccountsTypes.includes(
                          selectedAccount?.accountType ?? ""
                        )
                      ? sortedTxns[index - 1].transactionType === "DEPOSIT" ||
                        (sortedTxns[index - 1].transactionType === "TRANSFER" &&
                          (sortedTxns[index - 1] as Transfer).to ===
                            selectedAccount?.accountId) ||
                        (sortedTxns[index - 1].transactionType === "GL_ENTRY" &&
                          (sortedTxns[index - 1] as GLEntry).glType ===
                            "debit" &&
                          (sortedTxns[index - 1] as GLEntry).clientAccount ===
                            selectedAccount?.accountId)
                        ? subtract(balance ?? 0, txnAmount)
                        : sortedTxns[index - 1].transactionType ===
                            "WITHDRAWAL" ||
                          (sortedTxns[index - 1].transactionType ===
                            "TRANSFER" &&
                            (sortedTxns[index - 1] as Transfer).from ===
                              selectedAccount?.accountId) ||
                          (sortedTxns[index - 1].transactionType ===
                            "GL_ENTRY" &&
                            (sortedTxns[index - 1] as GLEntry).glType ===
                              "credit" &&
                            (sortedTxns[index - 1] as GLEntry).clientAccount ===
                              selectedAccount?.accountId)
                        ? add(balance ?? 0, txnAmount)
                        : balance
                      : sortedTxns[index - 1].transactionType === "DEPOSIT" ||
                        (sortedTxns[index - 1].transactionType ===
                          "CREDIT_CARD" &&
                          (sortedTxns[index - 1] as CreditCardTransaction)
                            .type === "payment") ||
                        (sortedTxns[index - 1].transactionType === "TRANSFER" &&
                          (sortedTxns[index - 1] as Transfer).to ===
                            selectedAccount?.accountId)
                      ? add(balance ?? 0, txnAmount)
                      : sortedTxns[index - 1].transactionType ===
                          "WITHDRAWAL" ||
                        (sortedTxns[index - 1].transactionType ===
                          "CREDIT_CARD" &&
                          (sortedTxns[index - 1] as CreditCardTransaction)
                            .type === "cashadvance") ||
                        (sortedTxns[index - 1].transactionType === "TRANSFER" &&
                          (sortedTxns[index - 1] as Transfer).from ===
                            selectedAccount?.accountId)
                      ? subtract(balance ?? 0, txnAmount)
                      : balance;
                  txnAmount = t.amount ?? 0;
                  if (
                    t.transactionType === "DEPOSIT" ||
                    (t.transactionType === "CREDIT_CARD" &&
                      (t as CreditCardTransaction).type === "payment") ||
                    (t.transactionType === "TRANSFER" &&
                      (t as Transfer).to === selectedAccount?.accountId) ||
                    (t.transactionType === "GL_ENTRY" &&
                      (t as GLEntry).glType === "debit" &&
                      (t as GLEntry).clientAccount ===
                        selectedAccount?.accountId)
                  ) {
                    return (
                      <AccountHistoryRowDeposit
                        key={index}
                        transaction={t}
                        balance={balance ?? 0}
                      />
                    );
                  }
                  if (
                    t.transactionType === "WITHDRAWAL" ||
                    (t.transactionType === "CREDIT_CARD" &&
                      (t as CreditCardTransaction).type === "cashadvance") ||
                    (t.transactionType === "TRANSFER" &&
                      (t as Transfer).from === selectedAccount?.accountId) ||
                    (t.transactionType === "GL_ENTRY" &&
                      (t as GLEntry).glType === "credit" &&
                      (t as GLEntry).clientAccount ===
                        selectedAccount?.accountId)
                  ) {
                    return (
                      <AccountHistoryRowWithdrawal
                        key={index}
                        transaction={t}
                        balance={balance ?? 0}
                      />
                    );
                  }
                  return <></>;
                })}
              </>
            </TableBody>
          </Table>
          <ButtonConatiner>
            <Button
              onClick={() => {
                props.onDetailedHistoryClick(accountOption);
              }}
            >
              Detailed History
            </Button>
          </ButtonConatiner>
        </>
      )}
    </ColumnContent>
  );
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <Cell>Date</Cell>
        <Cell>Debit</Cell>
        <Cell>Credit</Cell>
        <Cell>Balance</Cell>
      </TableRow>
    </TableHead>
  );
}

const ColumnContent = styled(Paper)`
  min-height: 600px;
`;

const FlexCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2em;
`;

const Container = styled.div`
  width: 300px;
  margin: auto;
  padding-top: 2em;
`;

const Cell = styled(TableCell)`
  font-size: 16px !important;
  text-align: center !important;
`;

const ButtonConatiner = styled.div`
  text-align: right;
  padding: 1em;
  margin-top: 1em;
`;
