import { useReduxState } from "store";
import {  
  FIELD_TYPE,  
  MASK_TYPE,
  TextFieldType,
  SellDraft,
  BoolField,
} from "types";


export const draftPayee: TextFieldType<SellDraft> = {
  id: "draftPayee",
  label: "Payee",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: SellDraft) => transaction.payee,
  setValue: (value, transaction) => ({
    ...transaction,
    payee: value,
  }),
  isRequired: true,
  maxLength: 50
};

export const draftAmount: TextFieldType<SellDraft> = {
  id: "draftAmount",
  label: "Draft Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: SellDraft) => transaction.draftAmount ? transaction.draftAmount.toString() : "",
  setValue: (value, transaction) => ({
    ...transaction,
    draftAmount: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
};

export const draftSerialNumber: TextFieldType<SellDraft> = {
  id: "draftSerialNumber",
  label: "Serial Number",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: SellDraft) => transaction.serialNumber,
  setValue: (value, transaction) => ({
    ...transaction,
    serialNumber: value,
  }),
  
  isRequired: true
};

export const draftWaiveFee: BoolField<SellDraft> = {
  id: `draftWaiveFee`,
  label: "Waive Fee?",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (transaction: SellDraft) => transaction.waiveFee,
  setValue: (value, transaction) => ({
    ...transaction,
    waiveFee: value,
  }),
  
};




export const draftAmountCADValue: TextFieldType<SellDraft> = {
  id: "draftAmountCADValue",
  label: "CAD Value",
  type: FIELD_TYPE.TEXT,
  getValue: (transaction: SellDraft) => {
    const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};
let ret =  transaction.currencyType === "usd" 
// as mentioned by business team in PCM dated May 27, changing usChequeSellRate instead of usCashBuyRate in following code 
  ? ((transaction.draftAmount ?? 0) * (globalSettings.usChequeSellRate ?? 1)).toFixed(2) 
  //((transaction.draftAmount ?? 0) * (globalSettings.usCashBuyRate ?? 1)).toFixed(2)
  : (transaction.draftAmount ?? 0).toString()
return ret
},
  setValue: (value, transaction) => ({
    ...transaction,
    draftAmountCADValue: value as unknown as number
  }),
  mask: MASK_TYPE.MONEY,
};
