//The commented items show how to include ducks
import personalClient from "./clientPortfolio/personalClient/reducer";
import businessClient from "./clientPortfolio/businessClient/reducer";
import clientSearch from "./clientSearch/clientSearch.duck";
import employers from "./createEmployer/employers.duck";
import transactions from "./transactions/transactions.duck";
import accountHistory from "./accountHistory/accountHistory.duck";
import generalLedger from "./generalLedger/generalLedger.duck"
import lesson from "./lessons/lessons.duck"
import studentSessions from "./admin/studentSessions/StudentSessions.duck"
import globalSettings from "./admin/globalSettings/GlobalSettings.duck";

const index = {
  reducer: {
    ...personalClient.reducer,
    ...clientSearch.reducer,
    ...employers.reducer,
    ...transactions.reducer,
    ...accountHistory.reducer,
    ...generalLedger.reducer,
    ...lesson.reducer,
    ...studentSessions.reducer,
    ...globalSettings.reducer,
    ...businessClient.reducer,
  },
  actions: {
    ...personalClient.actions,
    ...clientSearch.actions,
    ...employers.actions,
    ...transactions.actions,
    ...accountHistory.actions,
    ...generalLedger.actions,
    ...lesson.actions,
    ...studentSessions.actions,
    ...globalSettings.actions,
    ...businessClient.actions,
  },
};

export default index;
