import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import FieldEditor from "components/FieldEditor";
import { AddressType, FieldError, PersonalClient } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientFieldDefinitions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useState } from "react";
import ManageAddress from "pages/clientPortfolio/personalClient/personalClientInformation/components/ManageAddress";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};

const InformationSection = (
  props: CommonProps & {
    onAddAddress(newAddress: AddressType): void;
    onEditAddress(oldAddress: AddressType, updatedAddress: AddressType): void;
    onDeleteAddress(address: AddressType): void;
    isReadOnlyView: boolean;
  }
) => {
  const [
    openManageAddressDialog,
    setOpenManageAddressDialog,
  ] = useState<boolean>(false);

  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="informationSection-header"
        >
          <Typography variant={"h6"}>Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FieldEditor<PersonalClient>
                field={fields.accountType}
                {...props}
              />
              <FieldEditor<PersonalClient>
                field={fields.legalFirstName}
                {...props}
              />
              <FieldEditor<PersonalClient>
                field={fields.legalLastName}
                {...props}
              />
              <FieldEditor<PersonalClient>
                field={fields.preferredFirstName}
                {...props}
              />
              <FieldEditor<PersonalClient>
                field={fields.dateOfBirth}
                {...props}
              />
              <FieldEditor<PersonalClient>
                field={fields.maritalStatus}
                {...props}
              />
              <FieldEditor<PersonalClient>
                field={fields.preferredPronouns}
                {...props}
              />
              <FieldEditor<PersonalClient> field={fields.comment} {...props} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                style={{ margin: "1.25em 0 0.7em 0" }}
                onClick={() => {
                  setOpenManageAddressDialog(true);
                }}
                //disabled={props.isReadOnlyView}
              >
                Manage Address
              </Button>
              <FieldEditor<PersonalClient>
                field={fields.clientCurrentAddress}
                {...props}
              />
              <TwoFieldsRow>
                <Column1MarginRight>
                  <FieldEditor<PersonalClient>
                    field={fields.phoneNumber}
                    {...props}
                  />
                </Column1MarginRight>
                <Column2>
                  <FieldEditor<PersonalClient>
                    field={fields.phoneNumberType}
                    {...props}
                  />
                </Column2>
              </TwoFieldsRow>
              <FieldEditor<PersonalClient> field={fields.email} {...props} />
              <FieldEditor<PersonalClient>
                field={fields.preferredContact}
                {...props}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <ManageAddressDialog
        open={openManageAddressDialog}
        onClose={() => {
          setOpenManageAddressDialog(false);
        }}
        client={props.state}
        onAddAddress={(pc) => {
          props.onAddAddress(pc);
        }}
        onEditAddress={(oldAddress, updatedAddress) => {
          props.onEditAddress(oldAddress, updatedAddress);
        }}
        onDeleteAddress={(address) => {
          props.onDeleteAddress(address);
        }}
        isReadOnlyView={props.isReadOnlyView}
      />
    </SectionContainer>
  );
};

const ManageAddressDialog = (props: {
  open: boolean;
  onClose(): void;
  client: PersonalClient;
  onEditAddress(oldAddress: AddressType, updatedAddress: AddressType): void;
  onAddAddress(newAddress: AddressType): void;
  onDeleteAddress(address: AddressType): void;
  isReadOnlyView: boolean;
}) => {
  // used Dialog from "@mui/material" instead of SimpleDialog from @nait-aits/ui because
  // SimpleDialog has z-inex of 3000 due to which datepicker and drop down fails to display
  return (
    <Dialog maxWidth={"md"} open={props.open}>
      <DialogContent>
        <ManageAddress {...props} />
      </DialogContent>
    </Dialog>
  );
};

export default InformationSection;

const SectionContainer = styled.div`
  margin-top: 2em;
`;

const TwoFieldsRow = styled.div`
  display: flex;
  margin: -1em 0;
`;
const Column1MarginRight = styled.div`
  flex: 3;
  margin-right: 0.5em;
`;
const Column2 = styled.div`
  flex: 2;
`;
