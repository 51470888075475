import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Account } from "types";
import AssetsSection from "pages/clientPortfolio/personalClient/personalClientSummary/components/AssetsSection";
import { assetsAccountsTypes, liabilitiesAccountsTypes } from "common/helpers";
import { add } from "common/functions";
import LiabilitiesSection from "pages/clientPortfolio/personalClient/personalClientSummary/components/LiabilitiesSection";
import NetWorthSection from "pages/clientPortfolio/personalClient/personalClientSummary/components/NetWorthSection";

export default function AccountInfoSection(props: {
  activeAccounts: Account[];
}) {
  const assetsAccounts = props.activeAccounts?.filter(
    (e) =>
      assetsAccountsTypes.includes(e.accountType) &&
      (!e.closeDate || e.closeDate.toLowerCase() === "invalid date")
  );

  const liabilitiesAccounts = props.activeAccounts?.filter(
    (e) =>
      liabilitiesAccountsTypes.includes(e.accountType) &&
      (!e.closeDate || e.closeDate.toLowerCase() === "invalid date")
  );

  const liabilitiesBalances: number[] = liabilitiesAccounts
    ? liabilitiesAccounts.map((a) => {
        if (a.balance === undefined) return 0;
        else return a.balance;
      })
    : [];
  let liabilitiesTotal: number = liabilitiesBalances.reduce(add, 0);

  const assetsBalances: number[] = assetsAccounts
    ? assetsAccounts.map((a) => {
        if (a.balance === undefined) return 0;
        else return a.balance;
      })
    : [];
  let assetsTotal: number = assetsBalances.reduce(add, 0);
  return (
    <SectionContainer>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="informationSection-header"
        >
          <Typography variant={"h6"}>Account Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {props.activeAccounts.length === 0 && (
            <>No Accounts Information Available</>
          )}
          {props.activeAccounts.length > 0 && (
            <>
              <AssetsSection
                assetsAccounts={assetsAccounts ?? []}
                assetsTotal={assetsTotal}
              />
              <Divider style={{ color: "darkgray", margin: "1.5em 0em" }} />
              <LiabilitiesSection
                liabilitiesAccounts={liabilitiesAccounts ?? []}
                liabilitiesTotal={liabilitiesTotal}
              />
              <Divider style={{ color: "darkgray", margin: "1.5em 0em" }} />
              <NetWorthSection netWorth={assetsTotal - liabilitiesTotal} />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </SectionContainer>
  );
}
const SectionContainer = styled.div`
  margin-top: 2em;
`;
