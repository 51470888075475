import { createSlice } from "@reduxjs/toolkit";
import {createAsyncAction, ReducerState} from "@nait-aits/redux";

//uncomment if you need msal auth
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "clientSearch";
const baseURL = `${process.env.REACT_APP_API_BASE}/client`

type State = {
    deletePersonalClient: ReducerState<DeleteClientReturn>;
    deleteBusinessClient: ReducerState<DeleteClientReturn>;
};

type DeleteClientReturn = {
  success: boolean;
  message: string;
};

let deletePersonalClient = createAsyncAction<
DeleteClientReturn, //return type
  { clientId: string, lessonId: number, onComplete?(retValue: DeleteClientReturn): void  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "deletePersonalClient",
  url: baseURL + "/deletePersonalClient",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.deletePersonalClient = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.deletePersonalClient.isLoading = false;
    state.deletePersonalClient.data = action.payload;
    
  },
  rejected: (state, action) => {
    state.deletePersonalClient.isLoading = false;
    state.deletePersonalClient.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as DeleteClientReturn))
}
});

let deleteBusinessClient = createAsyncAction<
DeleteClientReturn, //return type
  { clientId: string, lessonId: number, onComplete?(retValue: DeleteClientReturn): void  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "deleteBusinessClient",
  url: baseURL + "/deleteBusinessClient",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.deleteBusinessClient = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.deleteBusinessClient.isLoading = false;
    state.deleteBusinessClient.data = action.payload;
    
  },
  rejected: (state, action) => {
    state.deleteBusinessClient.isLoading = false;
    state.deleteBusinessClient.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as DeleteClientReturn))
}
});

let slice = createSlice({
  name: controlName,
  initialState: {
      deletePersonalClient: {isLoading: false},
      deleteBusinessClient: {isLoading: false}
  } as State,
  reducers: {},
  extraReducers:{
    ...deletePersonalClient.reducer,
    ...deleteBusinessClient.reducer,
  }
});





const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
        ...slice.actions,
        deletePersonalClient: deletePersonalClient.action,
        deleteBusinessClient: deleteBusinessClient.action,
    },
  },
};

export default ret
