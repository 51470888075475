import { Divider, IconButton } from "@mui/material";
import { IdName, Transfer } from "types";
import SummaryRowTemplate from "pages/transactions/components/SummaryRowTemplate";
import { openConfirm } from "components/confirmBox";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";

import * as fields from "pages/transactions/transferFieldDefinitions";
import { getMaskedMoneyValue } from "common/functions";

type SummaryRowTransferProps = {
  transaction: Transfer;
  onDeleteClick(): void;
  transferOptions: IdName[];
  isTxnForAccWithOverLimitBal: boolean;
  isReadOnlyView: boolean;
};

export default function SummaryRowTransfer(props: SummaryRowTransferProps) {
  const currencyType = props.transaction.currencyType ?? "";
  const fromLabel = fields.transferFrom.getOptionLabel
    ? fields.transferFrom.getOptionLabel(
        props.transaction.from ?? "",
        props.transferOptions
      )
    : "";
  const toLabel = fields.transferTo.getOptionLabel
    ? fields.transferTo.getOptionLabel(
        props.transaction.to ?? "",
        props.transferOptions
      )
    : "";

  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);
  return (
    <SummaryRowTemplate
      transaction="Transfer"
      details={transactionAmount}
      isTxnForAccWithOverLimitBal={props.isTxnForAccWithOverLimitBal}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection style={{ textAlign: "center" }}>From</TextSection>
        <TextSection style={{ textAlign: "center" }}> To</TextSection>
        <TextSection style={{ textAlign: "center" }}>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>{fromLabel}</TextSection>
        {/* <TextSection>{props.transaction.to}</TextSection> */}
        <TextSection>{toLabel}</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${transactionAmount} ${currencyType.toUpperCase()}`}
        </TextSection>
      </DetailsRow>
      <DetailsRow isBold={true}>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          <IconButton
            style={{ margin: "-1em 0" }}
            aria-label="delete"
            onClick={() => {
              openConfirm({
                title: "Delete Transaction?",
                description: `Transfer ${transactionAmount} from ${fromLabel} to ${toLabel}`,
                callback: () => {
                  props.onDeleteClick();
                },
              });
            }}
            disabled={props.isReadOnlyView}
          >
            <DeleteIcon />
          </IconButton>
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
