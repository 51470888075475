import { TableCell, TableRow } from "@mui/material";
import styled from "styled-components";

type GLAccountHistoryRowRTemplateProps = {
  date?: string;
  transactionDescription?: string;
  reason?: string;
  debit?: JSX.Element | string;
  credit?: JSX.Element | string;
  balance?: JSX.Element | string;
};

export default function GLAccountHistoryRowTemplate(
  props: GLAccountHistoryRowRTemplateProps
) {
  return (
    <TableRow>
      <Cell>{props.date}</Cell>
      <Cell>{props.transactionDescription}</Cell>
      <Cell>{props.reason}</Cell>
      <Cell>{props.debit}</Cell>
      <Cell>{props.credit}</Cell>
      <Cell>{props.balance}</Cell>
    </TableRow>
  );
}

const Cell = styled(TableCell)`
  font-size: 16px !important;
  text-align: center !important;
`;
