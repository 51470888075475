import { getMaskedMoneyValue } from "common/functions";
import styled from "styled-components";

export default function CashTotal(props: {
  notesTotal: number;
  coinsTotal: number;
}) {
  return (
    <TotalSection>
      <Column>
        <LightBold>Notes Total</LightBold>
        {getMaskedMoneyValue(props.notesTotal)}
      </Column>
      <Column>
        <LightBold>Coins Total </LightBold>{" "}
        {getMaskedMoneyValue(props.coinsTotal)}
      </Column>
    </TotalSection>
  );
}

const LightBold = styled.div`
  font-weight: 500;
  margin-right: 10px;
`;

const TotalSection = styled.div`
  margin: 1em;
  display: flex;
  justify-content: space-between;
`;
const Column = styled.div`
  display: flex;
  min-width: 250px;
  justify-content: center;
`;
