import { Divider, List, ListItem, ListItemText } from "@mui/material";
import { BusinessClient, PersonalClient } from "types";
import styled from "styled-components";
import _orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import {
  getBusinessClientOperatingAs,
  getClientId,
  getPersonalClientName,
  highlightBackground,
  isPersonalClient,
  unhighlightBackground,
} from "common/functions";

export type Props = {
  clientList: (PersonalClient | BusinessClient)[];
  onClientSelect(client: PersonalClient | BusinessClient): void;
};

export default function ClientList(props: Props) {
  function getSortedClientList(
    clientList: (PersonalClient | BusinessClient)[]
  ) {
    return _orderBy(clientList, (l) =>
      l.information?.accountType === "Personal"
        ? l.information?.legalFirstName?.toLowerCase()
        : (l as BusinessClient).information?.operatingAs?.toLowerCase()
    );
  }
  useEffect(() => {
    setSelectedClient(getSortedClientList(props.clientList)[0]);
  }, [props.clientList]);

  const [selectedClient, setSelectedClient] = useState<
    PersonalClient | BusinessClient
  >();

  let sortedClientList = getSortedClientList(props.clientList);

  return (
    <div style={{ marginTop: "1em" }}>
      {/* <div style={{ minHeight: 400 }}> */}
      {(!sortedClientList || sortedClientList.length === 0) && (
        <NoClients>
          <div>There are no clients.</div>
        </NoClients>
      )}
      {sortedClientList && sortedClientList.length > 0 && (
        <StyledList>
          {sortedClientList.map((l, index) => {
            return (
              <div key={index} id={"client_div_" + getClientId(l)}>
                <ListItem
                  key={index + "_listItem"}
                  onClick={() => {
                    setSelectedClient(l);
                    props.onClientSelect(l);
                  }}
                  sx={{
                    backgroundColor:
                      getClientId(l) === getClientId(selectedClient ?? {})
                        ? "rgba(25, 118, 210, 0.40)"
                        : "",
                  }}
                  onMouseOver={() => {
                    highlightBackground("client_div_" + getClientId(l));
                  }}
                  onMouseOut={() => {
                    unhighlightBackground("client_div_" + getClientId(l));
                  }}
                >
                  <Client client={l} key={index} />
                </ListItem>
                <Divider component="li" />
              </div>
            );
          })}
        </StyledList>
      )}
    </div>
  );
}

function Client(props: { client: PersonalClient | BusinessClient }) {
  return (
    <ListItemText>
      <Row label="Client Id" value={getClientId(props.client) ?? ""} />
      <Row
        label="Client Name"
        value={
          isPersonalClient(props.client)
            ? getPersonalClientName(props.client as PersonalClient)
            : getBusinessClientOperatingAs(props.client as BusinessClient)
        }
      />
      <Row
        label="Account Type"
        value={props.client.information?.accountType ?? ""}
      />
      {/* <Row label="Debit" value={props.overrideTxnListItem.clientName} /> */}
      {/* <Row label="Credit" value={""} /> */}
    </ListItemText>
  );
}

function Row(props: { label: string; value: string }) {
  return (
    <RowContainer>
      <Label>{props.label}</Label>
      <Value> {props.value}</Value>
    </RowContainer>
  );
}

const Label = styled.label`
  color: #0000008a;
  width: 120px;
  padding: 0 0.5em;
  font-size: 16px;
`;
const Value = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  width: 130px;
  padding: 0 0.75em;
`;
const RowContainer = styled.div`
  display: flex;
`;

const NoClients = styled.div`
  font-size: 16px !important;
  margin: 4em 2em;
`;

const StyledList = styled(List)`
  max-height: 800px;
  overflow: auto;
  background-color: white;
`;
