import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import {  GlobalSettings } from "types";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "globalsettings";
const baseURL = `${process.env.REACT_APP_API_BASE}/globalsettings`;

type State = {

  getGlobalSettings: ReducerState<GetGlobalSettingsReturn>;
  saveGlobalSettings: ReducerState<SaveGlobalSettingsReturn>;
};

type GetGlobalSettingsReturn = {
    globalSettings: GlobalSettings
}

let getGlobalSettings = createAsyncAction<GetGlobalSettingsReturn, { onComplete?(retValue: GetGlobalSettingsReturn): void }, State>({
  actionPrefix: controlName,
  actionName: "getGlobalSettings",
  url: baseURL + "/getGlobalSettings",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.getGlobalSettings = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.getGlobalSettings.isLoading = false;
    state.getGlobalSettings.data = action.payload;
  },
  rejected: (state, action) => {
    state.getGlobalSettings.isLoading = false;
    state.getGlobalSettings.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as GetGlobalSettingsReturn))
},
});

type SaveGlobalSettingsReturn = {
    success: boolean;
    message: string
}

let saveGlobalSettings = createAsyncAction<SaveGlobalSettingsReturn, {globalSettings: GlobalSettings, onComplete?(retValue: SaveGlobalSettingsReturn): void }, State>({
  actionPrefix: controlName,
  actionName: "saveGlobalSettings",
  url: baseURL + "/saveGlobalSettings",
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.saveGlobalSettings = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.saveGlobalSettings.isLoading = false;
    state.saveGlobalSettings.data = action.payload;
  },
  rejected: (state, action) => {
    state.saveGlobalSettings.isLoading = false;
    state.saveGlobalSettings.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && 
      payload.params.onComplete((payload.data as SaveGlobalSettingsReturn))
},
});



let slice = createSlice({
  name: controlName,
  initialState: {
    getGlobalSettings: { isLoading: false },
    saveGlobalSettings: {isLoading: false},
  
  } as State,
  reducers: {
    updateReduxGlobalSettings: (state, data: { payload: GlobalSettings }) => {      
    if (state.getGlobalSettings.data)
    {state.getGlobalSettings.data.globalSettings = data.payload;}       
}},
  extraReducers: {
    ...getGlobalSettings.reducer,
    ...saveGlobalSettings.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getGlobalSettings: getGlobalSettings.action,
      saveGlobalSettings: saveGlobalSettings.action,
    },
  },
};

export default ret;
