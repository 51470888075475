import { Divider } from "@mui/material";
import styled from "styled-components";
import { FieldError, PersonalClient } from "types";
import InvestmentSection from "./InvestmentSection";
import TimeHorizonSection from "./TimeHorizonSection";
import RiskCapacitySection from "./RiskCapacitySection";
import RiskAttitudeSection from "./RiskAttitudeSection";
import ClientAuthorizationSection from "./ClientAuthorizationSection";

type CommonProps = {
  state: PersonalClient;
  setState(state: PersonalClient): void;
  errors: FieldError[];
};
export default function InvestmentProfileSection(props: CommonProps) {
  return (
    <Container>
      <Header>
        <SectionTitle>Investment Profile</SectionTitle>
      </Header>
      <InvestmentSection {...props} />
      <Divider style={{ color: "darkgray", margin: "1em 0em" }} />
      <TimeHorizonSection {...props} />
      <Divider style={{ color: "darkgray", margin: "1em 0em" }} />
      <RiskCapacitySection {...props} />
      <Divider style={{ color: "darkgray", margin: "1em 0em" }} />
      <RiskAttitudeSection {...props} />
      <Divider style={{ color: "darkgray", margin: "2.5em 0em 1.5em 0em" }} />
      <ClientAuthorizationSection {...props} />
    </Container>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0em 0em 1em;
  margin: 0em 1em;
`;

const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #333;
  flex: 1;
`;

const Container = styled.div`
  width: inherit;
  padding-bottom: 2em;
`;
