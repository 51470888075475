import { Paper } from "@mui/material";
import styled from "styled-components";
import { IdName, Transaction } from "types";
import BillPaymentForm from "pages/transactions/components/BillPaymentForm";
import CreditCardTransactionForm from "pages/transactions/components/CreditCardTransactionForm";
import DepositForm from "pages/transactions/components/DepositForm";
import GLEntryForm from "pages/transactions/components/GLEntryForm";
import PayoutCashForm from "pages/transactions/components/PayoutCashForm";
import ReceiveForm from "pages/transactions/components/ReceiveForm";
import SellDraftForm from "pages/transactions/components/SellDraftForm";
import TransferForm from "pages/transactions/components/TransferForm";
import WithdrawalForm from "pages/transactions/components/WithdrawalForm";

export type Props = {
  activeTransaction: Transaction | undefined;
  depositWithdrawTransferOptions: IdName[];
  creditCardOptions: IdName[];
  glEntryClientAccountOptions: IdName[];
  onSaveTransaction(transaction: Transaction): void;
};

export default function AddTransactionSection(props: Props) {
  if (props.activeTransaction === undefined)
    return (
      <ColumnContent>
        <FlexCenter>No Active Transaction</FlexCenter>
      </ColumnContent>
    );

  if (props.activeTransaction.transactionType === "RECEIVE")
    return (
      <>
        <ColumnContent>
          <ReceiveForm
            transaction={props.activeTransaction}
            onSaveClick={(receiveTransaction) => {
              props.onSaveTransaction(receiveTransaction);
            }}
          />
        </ColumnContent>
      </>
    );
  if (props.activeTransaction.transactionType === "DEPOSIT")
    return (
      <>
        <ColumnContent>
          <DepositForm
            transaction={props.activeTransaction}
            depositToOptions={props.depositWithdrawTransferOptions}
            onSaveClick={(depositTransaction) => {
              props.onSaveTransaction(depositTransaction);
            }}
          />
        </ColumnContent>
      </>
    );
  if (props.activeTransaction.transactionType === "WITHDRAWAL")
    return (
      <>
        <ColumnContent>
          <WithdrawalForm
            transaction={props.activeTransaction}
            withdrawalFromOptions={props.depositWithdrawTransferOptions}
            onSaveClick={(withdrawalTransaction) => {
              props.onSaveTransaction(withdrawalTransaction);
            }}
          />
        </ColumnContent>
      </>
    );
  if (props.activeTransaction.transactionType === "PAYOUT_CASH")
    return (
      <>
        <ColumnContent>
          <PayoutCashForm
            transaction={props.activeTransaction}
            onSaveClick={(payoutTransaction) => {
              props.onSaveTransaction(payoutTransaction);
            }}
          />
        </ColumnContent>
      </>
    );
  if (props.activeTransaction.transactionType === "BILL_PAYMENT")
    return (
      <>
        <ColumnContent>
          <BillPaymentForm
            transaction={props.activeTransaction}
            onSaveClick={(billPaymentTransaction) => {
              props.onSaveTransaction(billPaymentTransaction);
            }}
          />
        </ColumnContent>
      </>
    );
  if (props.activeTransaction.transactionType === "CREDIT_CARD")
    return (
      <>
        <ColumnContent>
          <CreditCardTransactionForm
            transaction={props.activeTransaction}
            creditCardOptions={props.creditCardOptions}
            onSaveClick={(billPaymentTransaction) => {
              props.onSaveTransaction(billPaymentTransaction);
            }}
          />
        </ColumnContent>
      </>
    );
  if (props.activeTransaction.transactionType === "TRANSFER")
    return (
      <>
        <ColumnContent>
          <TransferForm
            transaction={props.activeTransaction}
            transferOptions={props.depositWithdrawTransferOptions}
            onSaveClick={(transferTransaction) => {
              props.onSaveTransaction(transferTransaction);
            }}
          />
        </ColumnContent>
      </>
    );
  if (props.activeTransaction.transactionType === "SELL_DRAFT")
    return (
      <>
        <ColumnContent>
          <SellDraftForm
            transaction={props.activeTransaction}
            onSaveClick={(transferTransaction) => {
              props.onSaveTransaction(transferTransaction);
            }}
          />
        </ColumnContent>
      </>
    );
  if (props.activeTransaction.transactionType === "GL_ENTRY")
    return (
      <>
        <ColumnContent>
          <GLEntryForm
            transaction={props.activeTransaction}
            glEntryClientAccountOptions={props.glEntryClientAccountOptions}
            onSaveClick={(transferTransaction) => {
              props.onSaveTransaction(transferTransaction);
            }}
          />
        </ColumnContent>
      </>
    );
  return <div></div>;
}

const ColumnContent = styled(Paper)`
  min-height: 600px;
`;

const FlexCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2em;
`;
