import { add, getPrimeRate, getRemainingTimePeriod } from "common/functions";
import { FIELD_TYPE, TextFieldType,DateField, MASK_TYPE, PickListField, Loan, RadioGroupField } from "types";
import { v4 } from "uuid"

export const loanOpenDate: DateField<Loan> = {
  id: `loanOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: Loan) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: Loan) => {   
  return account.isAccountClosed ?? false
}
};

export const loanCloseDate: DateField<Loan> = {
  id: `loanCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: Loan) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const loanAccountNumber: TextFieldType<Loan> = {
  id: `loanAccountNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Loan) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true
};

export const loanBalance: TextFieldType<Loan> = {
  id: `loanBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Loan) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    balance: value as unknown as number,
  }),
  disabled: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
};

export const loanInterestType: RadioGroupField<Loan> = {
  id: `loanInterestType_${v4()}`,
  label: "Interest Type",
  trueLabel: "Fixed",
  falseLabel: "Variable",
  defaultValue: "Fixed",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: Loan) => account.interestType,
  setValue: (value, account) => ({
    ...account,
    interestType: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: Loan) => {   
  return account.isAccountClosed ?? false
}
};


export const loanPrimeRate: TextFieldType<Loan> = {
  id: `loanPrimeRate_${v4()}`,
  label: "Prime Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Loan) => getPrimeRate(),
  setValue: (value, account) => ({
    ...account,
    primeRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE
};

export const loanInterestRate: TextFieldType<Loan> = {
  id: `loanInterestRate_${v4()}`,
  label: "Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Loan) => account.interestRate,
  setValue: (value, account) => ({
    ...account,
    interestRate: value,
  }),
  isRequired: true,
  mask: MASK_TYPE.PERCENTAGE,
  checkIfDisabled: (account: Loan) => {   
    return account.isAccountClosed ?? false
  },
  checkIfAllowNegative: (account : Loan) =>{
    return account.interestType === "Fixed"? false : true
  }
  
};

export const loanTotalInterestRate: TextFieldType<Loan> = {
  id: `loanTotalInterestRate_${v4()}`,
  label: "Total Interest Rate",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Loan) => {
  let tir = add((account.interestRate?? 0) as unknown as number, (getPrimeRate() ?? 0) as unknown as number);
  if(tir > 0)
    return tir.toString();
  else
    return "0"
} ,
  setValue: (value, account) => ({
    ...account,
    totalInterestRate: value,
  }),
  disabled: true,
  mask: MASK_TYPE.PERCENTAGE
};

export const loanType: RadioGroupField<Loan> = {
  id: `loanType_${v4()}`,
  label: "Loan Type",
  trueLabel: "Secured",
  falseLabel: "Unsecured",
  defaultValue: "Secured",
  type: FIELD_TYPE.RADIOGROUP,
  getValue: (account: Loan) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
    security: "",
  }),
 isRequired: true,
 checkIfDisabled: (account: Loan) => {   
  return account.isAccountClosed ?? false
}
};



export const loanSecurity: TextFieldType<Loan> = {
  id: `loanSecurity_${v4()}`,
  label: "Security",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Loan) => account.security,
  setValue: (value, account) => ({
    ...account,
    security: value,
  }),
  checkIfRequired: (account: Loan) => {   
    return account.type === "Secured"?? false
  },
  checkIfDisabled: (account: Loan) => {   
    return account.isAccountClosed ?? false
  }
};

export const loanPaymentAmount: TextFieldType<Loan> = {
  id: `loanPaymentAmount_${v4()}`,
  label: "Payment Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Loan) => account.paymentAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    paymentAmount: value as unknown as number,
  }),
  mask: MASK_TYPE.MONEY,
  isRequired: true,
  checkIfDisabled: (account: Loan) => {   
    return account.isAccountClosed ?? false
  }
};

export const loanPaymentFrequency: PickListField<Loan> = {
  id: `loanPaymentFrequency_${v4()}`,
  label: "Payment Frequency",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: Loan) => account.paymentFrequency,
  setValue: (value, account) => ({
    ...account,
    paymentFrequency: value,
  }),
  options: [
    { id: "monthly", name: "Monthly" },
    { id: "semimonthly", name: "Semi-Monthly" },
    { id: "biweekly", name: "Bi-Weekly" },
    { id: "weekly", name: "Weekly" }
  ],
  isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: Loan) => {   
  return account.isAccountClosed ?? false
}
};

export const loanTermStartDate: DateField<Loan> = {
  id: `loanTermStartDate_${v4()}`,
  label: "Term Start Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: Loan) => account.termStartDate,
  setValue: (value, account) => ({
    ...account,
    termStartDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: Loan) => {   
  return account.isAccountClosed ?? false
}
};


export const loanTermLength: PickListField<Loan> = {
  id: `loanTermLength_${v4()}`,
  label: "Term Length (years)",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: Loan) => account.termLength,
  setValue: (value, account) => ({
    ...account,
    termLength: value,
  }),
  options: [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "10", name: "10" },
    { id: "15", name: "15" },
    { id: "20", name: "20" },
    { id: "25", name: "25" },
  ],
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: Loan) => {   
  return account.isAccountClosed ?? false
}
};

export const loanRemainingTermLength: TextFieldType<Loan> = {
  id: `loanRemainingTermLength_${v4()}`,
  label: "Remaining Term Length (years)",
  type: FIELD_TYPE.TEXT,
  getValue: (account: Loan) => account.termStartDate && account.termLength? getRemainingTimePeriod(account.termStartDate, account.termLength as unknown as number).toFixed(1) : "",
  setValue: (value, account) => ({
    ...account,
    remainingTermLength: value,
  }),
  disabled: true,
};