import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import DisplayFieldTemplate from "components/fields/DisplayFieldTemplate";

import PickListNonFieldEditor from "components/fields/PickListNonFieldEditor";
import TextFieldNonFieldEditor from "components/fields/TextFieldNonFieldEditor";
import { useEffect, useState } from "react";
import { FieldError, IdName, LessonListItem } from "types";

export type Props = {
  onSave(lessonName: string, copyFromLessonId: number): void;
  onCancel(): void;
  lessonList: LessonListItem[];
};

const AddLesson = (props: Props) => {
  const [lessonName, setLessonName] = useState<string>("");

  const [errors, setErrors] = useState<FieldError[]>([]);

  const [
    selectedLessonItem,
    setSelectedLessonItem,
  ] = useState<LessonListItem>();

  const copyFromLessonOptions: IdName[] =
    props.lessonList.length > 0
      ? [
          { id: "0", name: "None" },
          ...props.lessonList
            .map((l) => {
              return { id: l.id.toString(), name: l.lessonName };
            })
            ?.sort((a, b) => a.name.localeCompare(b.name)),
        ]
      : [{ id: "0", name: "None" }];

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [lessonName]);

  function checkErrors() {
    let errArray: FieldError[] = [];

    if (!lessonName) {
      let err: FieldError = {
        id: `lessonName`,
        label: `Lesson Name`,
        error: "Is Required.",
      };
      errArray.push(err);
    } else if (
      props.lessonList.some(
        (l) =>
          l.lessonName.toLocaleLowerCase() ===
          lessonName.trim().toLocaleLowerCase()
      )
    ) {
      let err: FieldError = {
        id: `lessonName`,
        label: `Lesson Name`,
        error: "Lesson Name already exists.",
      };
      errArray.push(err);
    }

    setErrors(errArray);
    return errArray;
  }

  function onSaveClick() {
    let errorArray = checkErrors();

    if (errorArray.length === 0) {
      props.onSave(lessonName, selectedLessonItem?.id ?? 0);
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open id="addLesson">
      <DialogTitle>
        <Typography>New Lesson</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextFieldNonFieldEditor
              id={`lessonName`}
              label={`Lesson Name`}
              value={lessonName}
              onChange={(v) => setLessonName(v)}
              required={true}
              multiline={false}
              disabled={false}
              errObj={errors.find((e) => e.id === "lessonName")}
            />
            <PickListNonFieldEditor
              id="copyLessonFrom"
              label="Copy From"
              value={(selectedLessonItem?.id ?? 0).toString()}
              onChange={(v) => {
                let lName =
                  copyFromLessonOptions.find((o) => o.id === v)?.name || "";

                setSelectedLessonItem(
                  props.lessonList.find((l) => l.lessonName === lName)
                );
              }}
              options={copyFromLessonOptions}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* following styling is to display field tempalte next to field editor with proper alignment */}
            <div style={{ margin: "16px 0px -5px 0px" }}>
              <DisplayFieldTemplate label={"Number Of Clients : "}>
                {selectedLessonItem?.numberOfClients ?? 0}
              </DisplayFieldTemplate>
            </div>

            <DisplayFieldTemplate label={"Number Of Employers : "}>
              {selectedLessonItem?.numberOfEmployers ?? 0}
            </DisplayFieldTemplate>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            onSaveClick();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLesson;
