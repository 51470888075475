import { Divider } from "@mui/material";
import { getMaskedMoneyValue } from "common/functions";
import { BillPayment } from "types";
import SummaryRowTemplate from "pages/sessionHistory/components/SummaryRowTemplate";
import styled from "styled-components";
import moment from "moment";

type SummaryRowBillPaymentProps = {
  transaction: BillPayment;
};

export default function SummaryRowBillPayment(
  props: SummaryRowBillPaymentProps
) {
  const totalAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  return (
    <SummaryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transaction="Bill Payment"
      credit={`${totalAmount}`}
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection style={{ textAlign: "center" }}>Payee</TextSection>
        <TextSection style={{ textAlign: "center" }}>
          Account Number
        </TextSection>
        <TextSection style={{ textAlign: "center" }}>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection
          style={{ width: 150, overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {props.transaction.payee}
        </TextSection>
        <TextSection>{props.transaction.payeeAccountNumber}</TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${totalAmount} CAD`}
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;
