import { provinceOptions } from "common/helpers";
import {

  FIELD_TYPE,
  MASK_TYPE,
  TextFieldType,
  AddressField,
  Employer
} from "types";

export const employerId: TextFieldType<Employer> = {
  id: "employerId",
  label: "Employer ID",
  type: FIELD_TYPE.TEXT,
  getValue: (employer: Employer) => employer.employerId,
  setValue: (value, employer) => ({
    ...employer,
    employerId: value,
  }),
 disabled:true
};

export const employerName: TextFieldType<Employer> = {
  id: "employerName",
  label: "Employer Name",
  type: FIELD_TYPE.TEXT,
  getValue: (employer: Employer) => employer.employerName,
  setValue: (value, employer) => ({
    ...employer,
    employerName: value,
  }),
  isRequired: true,
  maxLength: 50
};

export const phoneNumber: TextFieldType<Employer> = {
  id: "phoneNumber",
  label: "Phone Number",
  type: FIELD_TYPE.TEXT,
  getValue: (employer: Employer) => employer.phoneNumber,
  setValue: (value, employer) => ({
    ...employer,
    phoneNumber: value,
  }),
  mask: MASK_TYPE.PHONE_NUMBER,
  isRequired:true
};



export const employerAddress: AddressField<Employer> = {
  id: "employerAddress",
  label: "Employer Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (employer: Employer) => employer.employerAddress,
  setValue: (value, employer) => ({
    ...employer,
    employerAddress: value,
  }),
  options: provinceOptions,
  isRequired:true
};