import { provinceOptions } from "common/helpers";
import {

  FIELD_TYPE,
  TextFieldType,
  AddressField,
  Employer,
} from "types";


export const employerName: TextFieldType<Employer> = {
  id: "employerName",
  label: "Employer Name",
  type: FIELD_TYPE.TEXT,
  getValue: (employer: Employer) => employer.employerName,
  setValue: (value, employer) => ({
    ...employer,
    employerName: value,
  }),
  disabled: true,
};


export const employerAddress: AddressField<Employer> = {
  id: "employerAddress",
  label: "Employer Address",
  type: FIELD_TYPE.ADDRESS,
  getValue: (employer: Employer) => employer.employerAddress,
  setValue: (value, employer) => ({
    ...employer,
    employerAddress: value,
  }),
  options: provinceOptions,
  disabled: true,
};