import { SellDraft } from "types";
import { getMaskedMoneyValue } from "common/functions";
import SupervisorOverrideRowTemplate from "pages/transactions/components/SupervisorOverrideRowTemplate";
import { useReduxState } from "store";

type SupervisorOverrideRowSellDraftProps = {
  transaction: SellDraft;
  onApproveReject(updatedTransaction: SellDraft): void;
};

export default function SupervisorOverrideRowSellDraft(
  props: SupervisorOverrideRowSellDraftProps
) {
  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);
  const { getGlobalSettings } = useReduxState((e) => e.globalsettings);
  let globalSettings = getGlobalSettings.data?.globalSettings ?? {};

  return (
    <SupervisorOverrideRowTemplate
      transaction="Sell Draft"
      credit={transactionAmount}
      reason={`Sell Draft ${getMaskedMoneyValue(
        globalSettings.draftOverrideDenomination ?? 0
      )} or over`}
      status={
        props.transaction.isApprovalPending === true
          ? "Pending"
          : props.transaction.isApproved === true
          ? "Approved"
          : "Rejected"
      }
      onApprove={() => {
        let updatedTxn: SellDraft = {
          ...props.transaction,
          isApprovalPending: false,
          isApproved: true,
        };

        props.onApproveReject(updatedTxn);
      }}
      onReject={() => {
        let updatedTxn: SellDraft = {
          ...props.transaction,
          isApprovalPending: false,
          isApproved: false,
        };
        props.onApproveReject(updatedTxn);
      }}
    />
  );
}
