import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";

export type Props = {
  id: string;
  type: string;
  column1Fields: ReactNode;
  column2Fields: ReactNode;
  onSaveClick(): void;
  onCancelClick(): void;
};

export default function TransactionEditorTemplate({
  id,
  type,
  column1Fields,
  column2Fields,
  onSaveClick,
  onCancelClick,
}: Props) {
  return (
    <Dialog maxWidth="sm" fullWidth open id={id}>
      <DialogTitle>
        <Typography>{type}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {column1Fields}
          </Grid>
          <Grid item xs={12} md={6}>
            {column2Fields}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button variant="contained" onClick={onSaveClick}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
