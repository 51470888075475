import { Grid } from "@mui/material";
import { getBusinessCreditCardTypeLabel } from "common/functions";
import FieldDisplay from "components/FieldDisplay";
import * as fields from "pages/clientPortfolio/businessClient/businessClientAccounts/businessCreditCardFieldDefinitions";
import { CreditCard, MASK_TYPE } from "types";
import AccountDisplayTemplate from "pages/clientPortfolio/businessClient/businessClientAccounts/components/AccountDisplayTemplate";
export type Props = {
  account: CreditCard;
  onEditClick(): void;
  onDeleteClick(): void;
  onAccountHistoryClick(): void;
  isReadOnlyView: boolean;
};

function CreditCardDisplay({
  account,
  onEditClick,
  onDeleteClick,
  onAccountHistoryClick,
  isReadOnlyView,
}: Props) {
  const commonProps = {
    state: account,
  };

  return (
    <AccountDisplayTemplate
      id={`CREDIT_CARD_${account.accountId}_header`}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      onAccountHistoryClick={onAccountHistoryClick}
      accountType="Credit Card"
      mask={MASK_TYPE.CREDIT_CARD_NUMBER}
      accountNumber={account.cardNumber || ""}
      type={getBusinessCreditCardTypeLabel(account.type ?? "")}
      balance={account.balance || 0}
      isReadOnlyView={isReadOnlyView}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldDisplay<CreditCard>
            field={fields.creditCardNumber}
            {...commonProps}
          />
          <FieldDisplay<CreditCard>
            field={fields.creditCardExpiryMonth}
            {...commonProps}
          />

          <FieldDisplay<CreditCard>
            field={fields.creditCardExpiryYear}
            {...commonProps}
          />
          <FieldDisplay<CreditCard>
            field={fields.creditCardBalance}
            {...commonProps}
          />
          <FieldDisplay<CreditCard>
            field={fields.creditCardType}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDisplay<CreditCard>
            field={fields.creditCardOpenDate}
            {...commonProps}
          />
          <FieldDisplay<CreditCard>
            field={fields.creditCardCloseDate}
            {...commonProps}
          />
          <FieldDisplay<CreditCard>
            field={fields.creditCardLimit}
            {...commonProps}
          />
          <FieldDisplay<CreditCard>
            field={fields.creditCardMinimumPayment}
            {...commonProps}
          />
        </Grid>
      </Grid>
    </AccountDisplayTemplate>
  );
}

export default CreditCardDisplay;
