import styled from "styled-components";
import { INVESTING_PROFILES } from "common/helpers";
import SafetyProfile from "./images/SafetyProfile.png";
import ConservativeIncomeProfile from "./images/ConservativeIncomeProfile.png";
import BalancedProfile from "./images/BalancedProfile.png";
import GrowthProfile from "./images/GrowthProfile.png";
import AggressiveGrowthProfile from "./images/AggressiveGrowthProfile.png";

type RiskResultSectionProps = {
  overrallProfile: INVESTING_PROFILES;
};

export default function RiskResultSection(props: RiskResultSectionProps) {
  return (
    <Container>
      <Header>
        <SectionTitle>Risk Result</SectionTitle>
      </Header>
      <ResultDiv>
        Suggested profile based on your responses:{" "}
        <b>{props.overrallProfile.toString()}</b>
      </ResultDiv>
      {props.overrallProfile === INVESTING_PROFILES.SAFETY && (
        <ResultDescription
          description="You have a very low tolerance for risk and are unable to tolerate any investment losses or you have a very short investment time horizon. You prefer knowing that your capital is safe and are willing to accept lower returns to protect your capital."
          graph={SafetyProfile}
          reference="https://mfda.ca/wp-content/uploads/2022/08/image002.png"
          altText="Safety Asset Allocation - 100% Money Market/GICs"
          width="181"
          height="70"
        />
      )}
      {props.overrallProfile === INVESTING_PROFILES.CONSERVATIVE_INCOME && (
        <ResultDescription
          description="You have a low tolerance for risk and potential loss of capital or a short investment time horizon. You are willing to accept some short term fluctuations and small losses in your investment portfolio in exchange for modest returns. The primary objective of your investment portfolio will be to provide income by investing primarily in funds that invest in fixed-income securities. While capital appreciation is not a priority, a small portion of the portfolio may be invested in equity funds to provide the potential for some growth to offset the impact of inflation."
          graph={ConservativeIncomeProfile}
          reference="https://mfda.ca/wp-content/uploads/2022/08/image004.png"
          altText="Conservative Income Asset Allocation - 75% Fixed Income, 25% Equity"
        />
      )}
      {props.overrallProfile === INVESTING_PROFILES.BALANCED && (
        <ResultDescription
          description="You have a moderate tolerance for risk and loss of capital. You are willing to tolerate some fluctuations in your investment returns and moderate losses of capital. You have at least a medium term investment time horizon. The objective of your portfolio will be to provide a combination of income and long term capital growth and therefore the portfolio will include at least 40% in fixed income investments."
          graph={BalancedProfile}
          reference="https://mfda.ca/wp-content/uploads/2022/08/image006.png"
          altText="Balanced Asset Allocation - 40% Fixed Income, 60% Equity"
        />
      )}
      {props.overrallProfile === INVESTING_PROFILES.GROWTH && (
        <ResultDescription
          description="You have a high tolerance for risk and loss of capital. You are willing to tolerate large fluctuations in your investment returns and moderate to large losses of capital in exchange for potential long-term capital appreciation. You do not have any significant income requirements from your investments. You have at least a medium term investment time horizon."
          graph={GrowthProfile}
          reference="https://mfda.ca/wp-content/uploads/2022/08/image008.png"
          altText="Growth Asset Allocation - 20% Fixed Income, 80% Equity"
        />
      )}
      {props.overrallProfile === INVESTING_PROFILES.AGGRESSIVE_GROWTH && (
        <ResultDescription
          description="Your tolerance for risk, portfolio volatility and investment losses is very high. You are willing to tolerate potentially significant and sustained price fluctuations and large losses of capital. You have extensive investment knowledge. You have no income requirements from your investments and have a long investment time horizon."
          graph={AggressiveGrowthProfile}
          reference="https://mfda.ca/wp-content/uploads/2022/08/image010.png"
          altText="Aggressive Growth Asset Allocation - 100% Equity"
          height="89"
        />
      )}
    </Container>
  );
}

function ResultDescription(props: {
  description: string;
  graph: string;
  reference: string;
  altText: string;
  width?: string;
  height?: string;
}) {
  return (
    <DescriptionContainer>
      <DescriptionDiv>{props.description}</DescriptionDiv>
      <GraphDiv>
        <b>Asset Allocation</b>
        <img
          src={props.graph}
          alt={props.altText}
          width={props.width ?? "185"}
          height={props.height ?? "78"}
        />
        <ReferenceDiv>Reference: {props.reference}</ReferenceDiv>
      </GraphDiv>
    </DescriptionContainer>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0em 0em 1em;
`;

const SectionTitle = styled.div`
  margin: 16px 0;
  font-size: 20px;
  font-weight: 500;
  color: #333;
  flex: 1;
`;
const ResultDiv = styled.div`
  padding-left: 10px;
  margin: 20px 0px 15px 30px;
`;

const Container = styled.div`
  width: inherit;
  padding-bottom: 2em;
  padding-left: 1em;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DescriptionDiv = styled.div`
  width: 625px;
  margin: 0px 10px 0px 0px;
  align-items: center;
`;

const GraphDiv = styled.div`
  width: 250px;
  margin: 0px 17px;
  align-items: center;
`;

const ReferenceDiv = styled.div`
  font-size: 10px;
`;
