import { Divider } from "@mui/material";
import { IdName, GLEntry } from "types";
import SummaryRowTemplate from "pages/sessionHistory/components/SummaryRowTemplate";
import styled from "styled-components";
import * as fields from "pages/transactions/glEntryFieldDefinitions";
import { getMaskedMoneyValue } from "common/functions";
import moment from "moment";

type SummaryRowGLEntryProps = {
  transaction: GLEntry;
  glEntryClientAccountOptions: IdName[];
};

export default function SummaryRowGLEntry(props: SummaryRowGLEntryProps) {
  const fromLabel =
    props.transaction.glType === "debit"
      ? `GL Account - ${
          fields.glAccount.getOptionLabel
            ? fields.glAccount.getOptionLabel(
                props.transaction.glAccount ?? "",
                fields.glAccount.options ?? []
              )
            : ""
        }`
      : fields.clientAccount.getOptionLabel
      ? fields.clientAccount.getOptionLabel(
          props.transaction.clientAccount ?? "",
          props.glEntryClientAccountOptions
        )
      : "";
  const toLabel =
    props.transaction.glType === "debit"
      ? fields.clientAccount.getOptionLabel
        ? fields.clientAccount.getOptionLabel(
            props.transaction.clientAccount ?? "",
            props.glEntryClientAccountOptions
          )
        : ""
      : `GL Account - ${
          fields.glAccount.getOptionLabel
            ? fields.glAccount.getOptionLabel(
                props.transaction.glAccount ?? "",
                fields.glAccount.options ?? []
              )
            : ""
        }`;

  let transactionAmount = getMaskedMoneyValue(props.transaction.amount ?? 0);

  return (
    <SummaryRowTemplate
      date={moment(props.transaction.transactionDateTime)
        .format("MM/DD/YYYY")
        .toString()}
      transaction="GL Entry"
      debit={
        props.transaction.glType === "debit" &&
        props.transaction.clientAccount === "sessionbalance"
          ? transactionAmount
          : ""
      }
      credit={
        props.transaction.glType === "credit" &&
        props.transaction.clientAccount === "sessionbalance"
          ? transactionAmount
          : ""
      }
      details={
        props.transaction.clientAccount !== "sessionbalance"
          ? transactionAmount
          : ""
      }
    >
      <Divider />
      <DetailsRow isBold>
        <TextSection style={{ textAlign: "center" }}>From</TextSection>
        <TextSection style={{ textAlign: "center" }}> To</TextSection>
        <TextSection style={{ textAlign: "center" }}>Amount</TextSection>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <TextSection>
          {fromLabel === "" ? "Deleted Account" : fromLabel}
        </TextSection>
        <TextSection>
          {toLabel === "" ? "Deleted Account" : toLabel}
        </TextSection>
        <TextSection style={{ textAlign: "right" }}>
          {`${transactionAmount} CAD`}
        </TextSection>
      </DetailsRow>
      <DetailsRow
        isBold={true}
        style={{
          alignItems: "normal",
          marginTop: "1em",
        }}
      >
        <TextSection>Reason</TextSection>
        <div style={{ minWidth: 120 }}>
          <TextSection>{props.transaction.reason}</TextSection>
          <TextSection
            style={{
              fontWeight: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.transaction.comment}
          </TextSection>
        </div>
        <TextSection>
          {" "}
          <Spacer />
        </TextSection>
      </DetailsRow>
    </SummaryRowTemplate>
  );
}

const TextSection = styled.div`
  min-width: 120px;
  padding: 0px 10px;
`;

const DetailsRow = styled.div<{ isBold?: boolean }>`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => (p.isBold === true ? "500" : "")};
`;

const Spacer = styled.div`
  flex: 1;
`;
