import { CSSProperties } from "styled-components";

export function WarningIcon(props: {
  fillColor?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill={props.fillColor ?? "#eed027"}
      className="bi bi-exclamation-triangle-fill"
      viewBox="0 0 16 16"
      style={props.style}
    >
      <path
        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 
        1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 
        0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 
        0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
      />
    </svg>
  );
}

export function RejectFillIcon(props: {
  fillColor?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill={props.fillColor ?? "#dd0e0e"}
      className="bi b1i-exclamation-circle-fill"
      viewBox="0 0 16 16"
      style={props.style}
    >
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35
   3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0
    0 2 1 1 0 0 0 0-2z"
      />
    </svg>
  );
}

export function RejectIcon(props: {
  fillColor?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={props.fillColor ?? "currentColor"}
      className="bi bi-exclamation-circle"
      viewBox="0 0 16 16"
      style={props.style}
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path
        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35
     3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
      />
    </svg>
  );
}
