import personalClientInformation from "./personalClientInformation/personalClientInformation.duck";

const index = {
  reducer: {
    ...personalClientInformation.reducer,
  },
  actions: {
    ...personalClientInformation.actions,
  },
};

export default index;
