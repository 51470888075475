
import { getPersonalMutualFundsTypeOptions } from "common/functions";
import { FIELD_TYPE, TextFieldType,DateField, MASK_TYPE, PickListField,  MutualFunds, BoolField } from "types";
import { v4 } from "uuid"

export const mutualFundsOpenDate: DateField<MutualFunds> = {
  id: `gicOpenDate_${v4()}`,
  label: "Open Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: MutualFunds) => account.openDate,
  setValue: (value, account) => ({
    ...account,
    openDate: value,
  }),
 isRequired: true,
 checkIfDisabled: (account: MutualFunds) => {   
  return account.isAccountClosed ?? false
}
};

export const mutualFundsCloseDate: DateField<MutualFunds> = {
  id: `mutualFundsCloseDate_${v4()}`,
  label: "Close Date",
  type: FIELD_TYPE.DATE,
  getValue: (account: MutualFunds) => account.closeDate,
  setValue: (value, account) => ({
    ...account,
    closeDate:value,
  }),
};

export const mutualFundsAccountNumber: TextFieldType<MutualFunds> = {
  id: `mutualFundsAccountNumber_${v4()}`,
  label: "Account Number",
  type: FIELD_TYPE.TEXT,
  getValue: (account: MutualFunds) => account.accountNumber,
  setValue: (value, account) => ({
    ...account,
    accountNumber: value,
  }),
  disabled: true
};

export const mutualFundsBalance: TextFieldType<MutualFunds> = {
  id: `mutualFundsBalance_${v4()}`,
  label: "Balance",
  type: FIELD_TYPE.TEXT,
  getValue: (account: MutualFunds) => account.balance?.toString(),
  setValue: (value, account) => ({
    ...account,
    balance: value as unknown as number,
  }),
  isRequired: true,
  mask: MASK_TYPE.MONEY,
  allowNegative: true,
  checkIfDisabled: (account: MutualFunds) => {   
    return account.isAccountClosed ?? false
  }
};

export const mutualFundsType: PickListField<MutualFunds> = {
  id: `mutualFundsType_${v4()}`,
  label: "Type",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: MutualFunds) => account.type,
  setValue: (value, account) => ({
    ...account,
    type: value,
  }),
  options: getPersonalMutualFundsTypeOptions(),
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: MutualFunds) => {   
  return account.isAccountClosed ?? false
}
};

export const mutualFundsInvestmentCategory: PickListField<MutualFunds> = {
  id: `mutualFundsInvestmentCategory_${v4()}`,
  label: "Investment Objective",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: MutualFunds) => account.investmentCategory,
  setValue: (value, account) => ({
    ...account,
    investmentCategory: value,
  }),
  options: [
    { id: "veryconservative", name: "Very Conservative" },
    { id: "conservativeincome", name: "Conservative Income" },
    { id: "balanced", name: "Balanced" },
    { id: "growth", name: "Growth" },
    { id: "aggressivegrowth", name: "Aggressive Growth" },
  ],
 isRequired: true,
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
 checkIfDisabled: (account: MutualFunds) => {   
  return account.isAccountClosed ?? false
}
};

export const mutualFundsAutoDeposit: BoolField<MutualFunds> = {
  id: `mutualFundsAutoDeposit_${v4()}`,
  label: "Auto Deposit",
  type: FIELD_TYPE.CHECKBOX,
  getValue: (account: MutualFunds) => account.autoDeposit,
  setValue: (value, account) => ({
    ...account,
    autoDeposit: value,
  }),
  checkIfDisabled: (account: MutualFunds) => {   
    return account.isAccountClosed ?? false
  }
};

export const mutualFundsPreAuthPaymentAmount: TextFieldType<MutualFunds> = {
  id: `mutualFundsPreAuthPaymentAmount_${v4()}`,
  label: "Payment Amount",
  type: FIELD_TYPE.TEXT,
  getValue: (account: MutualFunds) => account.preAuthPaymentAmount?.toString(),
  setValue: (value, account) => ({
    ...account,
    preAuthPaymentAmount: value as unknown as number,
  }),
  mask: MASK_TYPE.MONEY,
  checkIfRequired: (account: MutualFunds) => {   
    return account.autoDeposit?? false
  },
  checkIfDisabled: (account: MutualFunds) => {   
    return (!account.autoDeposit || account.isAccountClosed )? true : false
  }
};

export const mutualFundsPreAuthPaymentFrequency: PickListField<MutualFunds> = {
  id: `mutualFundsPreAuthPaymentFrequency_${v4()}`,
  label: "Payment Frequency",
  type: FIELD_TYPE.PICKLIST,
  getValue: (account: MutualFunds) => account.preAuthPaymentFrequency,
  setValue: (value, account) => ({
    ...account,
    preAuthPaymentFrequency: value,
  }),
  options: [
    { id: "monthly", name: "Monthly" },
    { id: "semimonthly", name: "Semi-Monthly" },
    { id: "biweekly", name: "Bi-Weekly" },
    { id: "weekly", name: "Weekly" }
  ],
  checkIfRequired: (account: MutualFunds) => {   
    return account.autoDeposit?? false
  },
  checkIfDisabled: (account: MutualFunds) => {   
    return (!account.autoDeposit || account.isAccountClosed )? true : false
  },
 getOptionLabel: (value, options) => options.find( o => o.id === value)?.name || "",
};


