import { IconButton } from "@mui/material";
import AddressDisplay from "components/fields/AddressDisplay";
import styled from "styled-components";
import { Employer, Employment } from "types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { openConfirm } from "components/confirmBox";
import {
  employerNotExistsForType,
  employmentTypeOptions,
} from "common/helpers";

export type Props = {
  employment: Employment;
  employer: Employer;
  onEditClick(): void;
  onDeleteClick(): void;
  isReadOnlyView: boolean;
};

export default function EmploymentDisplayTemplate(props: Props) {
  const { employment, employer } = props;

  const deleteDescription = !employerNotExistsForType.includes(
    employment.employmentType ?? ""
  )
    ? `${getEmploymentTypeValue(employment.employmentType ?? "")} as ${
        employment.jobTitle
      } at ${employer.employerName} since ${
        employment.employmentStartDate
      }. Continue?`
    : `${getEmploymentTypeValue(employment.employmentType ?? "")}. Continue?`;

  return (
    <EmploymentContainer>
      <FlexRight>
        <LeftContent>
          <LightBold>
            {getEmploymentTypeValue(employment.employmentType ?? "")}
          </LightBold>
          {employment.jobTitle}
        </LeftContent>

        <IconContainer>
          <IconButton
            aria-label="edit"
            disabled={props.isReadOnlyView}
            onClick={() => {
              props.onEditClick();
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            disabled={props.isReadOnlyView}
            onClick={() => {
              openConfirm({
                title: "Delete Employement",
                description: deleteDescription,
                callback: () => {
                  props.onDeleteClick();
                },
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </IconContainer>
      </FlexRight>
      <Spacer />
      <FlexRight>
        {!employerNotExistsForType.includes(
          employment.employmentType ?? ""
        ) && (
          <LeftContent>
            <BoldLabel> {employer.employerName}</BoldLabel>
            <AddressDisplay
              address={employer.employerAddress ?? {}}
              style={{ marginLeft: "0em" }}
            />
          </LeftContent>
        )}

        <RightContent>
          {employment.employmentStartDate && (
            <DateDiv>
              <BoldLabel>Start Date : </BoldLabel>{" "}
              {employment.employmentStartDate}
            </DateDiv>
          )}
        </RightContent>
      </FlexRight>
    </EmploymentContainer>
  );
}

function getEmploymentTypeValue(type: string) {
  const value = employmentTypeOptions.find((t) => t.id === type)?.name;
  return value;
}

const EmploymentContainer = styled.div`
  min-width: 580px;
`;
const FlexRight = styled.div`
  display: flex;
  justify-content: right;
`;

const IconContainer = styled.div`
  display: flex;
  height: min-content;
`;

const DateDiv = styled.div`
  margin-top: 1em;
  display: flex;
  margin-right: 1em;
  text-align: end;
`;
const BoldLabel = styled.div`
  font-weight: bold;
  margin-right: 0.5em;
`;

const LightBold = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

const Spacer = styled.div`
  margin-bottom: 1em;
`;

const LeftContent = styled.div`
  flex: 1;
  line-height: 1.75em;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
