import FieldEditor from "components/FieldEditor";
import { CreditAppCreditCard, FieldError } from "types";
import * as fields from "pages/clientPortfolio/personalClient/personalClientCreditApp/components/creditAppCreditCardFieldDefinitions";
import { useEffect, useState } from "react";
import { requiredPickListField, requiredTextField } from "common/functions";
import { Grid } from "@mui/material";
import AccountEditTemplate from "./AccountEditTemplate";
import WarningControl from "components/warningControl";
export type Props = {
  account: CreditAppCreditCard;
  isAnotherAccountExists: boolean;
  onCancel(): void;
  onAddUpdate(account: CreditAppCreditCard): void;
};

const CreditCardForm = ({
  account,
  isAnotherAccountExists,
  onCancel,
  onAddUpdate,
}: Props) => {
  const [state, setState] = useState<CreditAppCreditCard>(account);
  const [errors, setErrors] = useState<FieldError[]>([]);

  const [openWarningControl, setOpenWarningControl] = useState(false);

  useEffect(() => {
    errors.length > 0 && checkErrors();
  }, [state]);

  const commonProps = {
    state: state,
    errors,
    setState: (state: CreditAppCreditCard) => {
      setState(state);
    },
  };

  function checkErrors() {
    if (!account) {
      return;
    }
    const errorsArray: FieldError[] = [
      ...requiredTextField(fields.requestedAmount, state),
      ...requiredPickListField(fields.type, state),
      ...requiredTextField(fields.security, state),
      ...requiredTextField(fields.minimumPayment, state),
    ];

    setErrors(errorsArray);
    return errorsArray;
  }

  function onAddUpdateClick() {
    const err = checkErrors();
    if (err?.length === 0) {
      if (isAnotherAccountExists) {
        setOpenWarningControl(true);
      } else {
        onAddUpdate({
          ...state,
        });
      }
    }
  }

  return (
    <AccountEditTemplate
      id={`CREDIT_APP_CREDIT_CARD_${account.accountId}_form`}
      isNew={account.requestedAmount ? false : true}
      isAnotherAccountExists={isAnotherAccountExists}
      requestedAmount={state.requestedAmount ?? 0}
      type={"Credit Card"}
      onAddUpdateClick={onAddUpdateClick}
      onCancelClick={onCancel}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppCreditCard>
            field={fields.requestedAmount}
            {...commonProps}
          />
          <FieldEditor<CreditAppCreditCard>
            field={fields.minimumPayment}
            {...commonProps}
          />

          <FieldEditor<CreditAppCreditCard>
            field={fields.hasSecurity}
            {...commonProps}
          />
          {state.hasSecurity && (
            <FieldEditor<CreditAppCreditCard>
              field={fields.security}
              {...commonProps}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldEditor<CreditAppCreditCard>
            field={fields.type}
            {...commonProps}
          />
        </Grid>
      </Grid>
      {openWarningControl && (
        <WarningControl
          onCancel={() => {
            setOpenWarningControl(false);
            onCancel();
          }}
          onProceed={() => {
            onAddUpdate({
              ...state,
            });
          }}
          warningMessage={"This action will override the existing account"}
        />
      )}
    </AccountEditTemplate>
  );
};

export default CreditCardForm;
